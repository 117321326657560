import React from 'react';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import './Manual.css';
import Instructions1 from '../../../files/Інструкція_Гум_допомога.pdf';
import Instructions2 from '../../../files/Інструкція отримувача.pdf';
import Instructions4 from '../../../files/Instruction_4.pdf';
import Instructions5 from '../../../files/Instructions5.pdf';

// import Instructions2 from '../../../files/Інструкція_уповноваженої_особи_Мінсоцполітики.pdf';
// import Instructions3 from '../../../files/Інструкція члена робочої групи.pdf';
import { List, Divider, Typography, Card } from '@material-ui/core';

const InstructionList = [
  { id: 1, text: 'Інструкція користувача', link: Instructions1 },
  { id: 2, text: 'Інструкція отримувача', link: Instructions2 },
  { id: 4, text: 'Інструкція формування Звіту', link: Instructions4 },
  { id: 5, text: 'Інструкція про завантаження та вивантаження файлів', link: Instructions5 },
  // { id: 2, text: 'Інструкція уповноваженої особи Мінсоцполітики', link: Instructions2 },
  // { id: 3, text: 'Інструкція члена робочої групи', link: Instructions3 },
];

const Manual = () => {
  return (
    <Card>
      <List>
        {InstructionList.map((value) => {
          return (
            <>
              <ListItem
                key={value.id}
                component={Link}
                href={value.link}
                rel="noopener"
                color="primary"
                target="_blank"
                button
                role={undefined}
              >
                <ListItemText primary={<Typography component="h5">{value.text}</Typography>} />
              </ListItem>
              <Divider />
            </>
          );
        })}
      </List>
    </Card>
  );
};

export default Manual;

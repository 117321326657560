/* eslint-disable react/destructuring-assignment */
import React from 'react';
// bootstrap
import { Row, Col } from 'reactstrap';
// material
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Print from '@material-ui/icons/Print';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TopBarProgress from 'react-topbar-progress-indicator';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { CardHeader, CardContent, Card, Button } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from '@material-ui/core/TableContainer';
import Swal from 'sweetalert2';
import { getNewReport, deleteReport, cargoReportGetPDF, unsignReport, updateReport } from '../../../api/api';
import { getReportAction, cargoIDPdf } from '../../../redux/actions/cargo.action';
import { setReportParams, setReportId, setReportTab } from '../../../redux/actions/report.action';
import BootstrapTooltip from '../../elements/MaterialBootstrap';

import CalendarModal from './report/CalendarModal';

TopBarProgress.config({
  barColors: {
    0: '#2D5CA6',
    '1.0': '#2D5CA6',
  },
  shadowBlur: 5,
});

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#141414',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    backgroundColor: theme.palette.common.white,
  },
}))(TableCell);

const styles = (theme) => ({
  card: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '32px',
    },
  },
  subheader: {
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    maxWidth: '690px',
  },
  action: {
    alignSelf: 'center',
    margin: 0,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'start',
    },
  },
  newReport: {
    padding: '18px 36px',
    borderRadius: '30px',
    backgroundColor: '#141414',
    color: 'white',
    fontFamily: 'e-Ukraine',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    textAlign: 'center',
  },
  pagination: {
    backgroundColor: '#fff',
  },
});

// eslint-disable-next-line no-shadow
function Child({ onMouseEnter, onMouseLeave, text, orederView, editReport, handleDeleteReport, handleUnsignReport }) {
  return (
    <>
      <TableRow
        hover
        component="tr"
        dense="true"
        style={{
          minHeight: '20px',
          position: 'relative',
          cursor: 'pointer',
          zIndex: '1',
          boxShadow: 'inset 0 -1px 0 0 rgba(100,121,143,0.122)',
        }}
        key={text.cargo_id}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <StyledTableCell width="5%">{text.n_edrpou}</StyledTableCell>
        <StyledTableCell>{text.d_rep}</StyledTableCell>
        <StyledTableCell>{text.d_cng}</StyledTableCell>
        <StyledTableCell>{text.n_weight_bal}</StyledTableCell>
        <StyledTableCell>{text.n_count_bal}</StyledTableCell>
        <StyledTableCell width="10%">{text.c_state}</StyledTableCell>
        <StyledTableCell
          style={{
            padding: '0',
            alignItems: 'center',
            flex: '0 0 auto',
            lineHeight: '20px',
            order: '1',
            emptyCells: 'show',
            fontSize: '.875rem',
            height: '50px',
            outline: 'none',
            verticalAlign: 'middle',
            whiteSpace: 'nowrap',
            position: 'relative',
            minWidth: '160px',
            width: '10%',
          }}
        >
          <ul
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              padding: 0,
              margin: 0,
              listStyle: 'none',
            }}
          >
            {text.l_blocked === 0 && (
              <li>
                <BootstrapTooltip title="Редагувати">
                  <IconButton
                    id={`edit${text.rep_id}`}
                    color="primary"
                    aria-label="Edit"
                    onClick={(e) => {
                      e.stopPropagation();
                      editReport(text);
                    }}
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                </BootstrapTooltip>
              </li>
            )}
            {text.l_blocked === 0 && (
              <li>
                <BootstrapTooltip title="Видалити">
                  <IconButton
                    id={`delete${text.rep_id}`}
                    color="primary"
                    aria-label="Delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteReport(text.rep_id);
                    }}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </BootstrapTooltip>
              </li>
            )}
            {text.l_blocked !== 0 && (
              <li>
                <BootstrapTooltip title="Друк">
                  <IconButton
                    id={`print${text.rep_id}`}
                    color="primary"
                    aria-label="Print"
                    onClick={() => orederView(text)}
                  >
                    <Print fontSize="inherit" />
                  </IconButton>
                </BootstrapTooltip>
              </li>
            )}
            {text.l_blocked !== 0 && (
              <li>
                <BootstrapTooltip title="Зняти підпис">
                  <IconButton
                    id={`unsign${text.rep_id}`}
                    color="primary"
                    aria-label="unsign"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUnsignReport(text.rep_id);
                    }}
                  >
                    <CancelIcon fontSize="inherit" />
                  </IconButton>
                </BootstrapTooltip>
              </li>
            )}
          </ul>
        </StyledTableCell>
      </TableRow>
    </>
  );
}

const TablePgination = withStyles(styles)(
  ({ report, handleChangePage, handleChangeRowsPerPage, rowsPerPage, page, classes }) => {
    return (
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={report.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} з ${count}`}
        labelRowsPerPage="Рядків на сторінці:"
      />
    );
  }
);

class ReportDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: {},
      currentCount: 0,
      intervalId: 0,
      page: 0,
      rowsPerPage: 10,
      isReport: false,
      openCalendar: false,
    };
  }

  handleMouseEnter = (index) => {
    this.setState((prevState) => {
      return { isHovered: { ...prevState.isHovered, [index]: true } };
    });
  };

  handleMouseLeave = (index) => {
    this.setState((prevState) => {
      return { isHovered: { ...prevState.isHovered, [index]: false } };
    });
  };

  async componentDidMount() {
    // eslint-disable-next-line no-shadow
    const { getReportAction } = this.props;
    await getReportAction();
    this.setState({ isReport: true });
  }

  handleOpenCalendar = () => {
    this.setState({ openCalendar: true });
  };

  handleCloseCalendar = () => {
    this.setState({ openCalendar: false });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  };

  // eslint-disable-next-line class-methods-use-this
  editReport = async (report) => {
    // eslint-disable-next-line no-shadow
    const { setReportId } = this.props;
    try {
      // eslint-disable-next-line no-shadow
      const { history, pi_recipient, setReportTab, setReportParams, cargoIDPdf } = this.props;

      if (pi_recipient) {
        const { rep_id, d_rep } = report;
        // eslint-disable-next-line react/destructuring-assignment
        await updateReport({ pi_recipient, pd_rep: d_rep });
        await setReportParams({ i_recip: pi_recipient, curr_date: d_rep, min_date: '', i_report: rep_id });
        cargoIDPdf(rep_id);
        await setReportId(rep_id);
        setReportTab(0);
        history.push('/create-report');
      }
      // eslint-disable-next-line no-empty
    } catch (error) { }
  };

  createReport = async () => {
    // eslint-disable-next-line no-shadow
    const { pi_recipient, setReportParams } = this.props;
    try {
      if (pi_recipient) {
        const resp = await getNewReport(pi_recipient);
        const { curr_date, min_date } = resp.data[0];
        await setReportParams({ i_recip: pi_recipient, curr_date: curr_date || '', min_date: min_date || '' });
        this.handleOpenCalendar();
      }
      // eslint-disable-next-line no-empty
    } catch (error) { }
  };

  handleDeleteReport = (reportId) => {
    swalWithBootstrapButtons
      .fire({
        text: 'Запис буде видалено',
        showCancelButton: true,
        confirmButtonText: 'Так, видалити',
        cancelButtonText: 'Відміна',
      })
      .then(async (result) => {
        if (result.value) {
          try {
            // eslint-disable-next-line no-shadow
            const { getReportAction } = this.props;
            deleteReport(reportId)
              .then((res) => {
                if (res.status === 200) {
                  getReportAction();
                }
              })
              // eslint-disable-next-line no-unused-vars
              .catch((err) => { });
          } catch (error) {
            swalWithBootstrapButtons.fire({
              title: 'Помилка',
              text: error.response.data,
              type: 'error',
            });
          }
        }
      });
  };

  handleUnsignReport = async (reportId) => {
    swalWithBootstrapButtons
      .fire({
        text: 'Підпис звіту буде знято',
        showCancelButton: true,
        confirmButtonText: 'Так, зняти',
        cancelButtonText: 'Відміна',
      })
      .then(async (result) => {
        if (result.value) {
          try {
            const { getReportAction } = this.props;
            const res = await unsignReport(reportId);
            if (res.status === 200) {
              getReportAction();
            }
          } catch (error) {
            // console.log(error);
          }
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error) => {
        // console.log(error);
      });
  };

  async orederView(report) {
    try {
      const data = {
        pd_rep: report.d_rep,
        id: report.rep_id,
      };
      const response = await cargoReportGetPDF(data);

      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      // link.setAttribute('download', `${data[index].c_name}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) { }
  }

  render() {
    const { classes } = this.props;
    const emptyRows =
      this.state.isReport === true &&
      this.state.rowsPerPage -
      Math.min(this.state.rowsPerPage, this.props.report.length - this.state.page * this.state.rowsPerPage);

    const { openCalendar } = this.state;

    return (
      <>
        <Row>
          <Col xs={12}>
            <Card className={classes.card}>
              <CardHeader
                classes={{ subheader: classes.subheader, root: classes.root, action: classes.action }}
                subheader="Якщо є розбіжності між даними товарів в Декларації і фактично отриманою допомогою, то зміни вносяться в ІНВЕНТАРІЗАЦІЙНИЙ ОПИС."
                action={
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.newReport}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.createReport();
                    }}
                  >
                    СТВОРИТИ ЗВІТ
                  </Button>
                }
              />
              <CardContent>
                <TableContainer>
                  <Table style={{ fontSize: '12px' }} hover="true" size="small">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>ЄДРПОУ отримувача</StyledTableCell>
                        <StyledTableCell>Станом на</StyledTableCell>
                        <StyledTableCell>Дата останніх змін</StyledTableCell>
                        <StyledTableCell>Залишок загалом Вага, кг/Об’єм,л </StyledTableCell>
                        <StyledTableCell>Залишок загалом Кількість, шт</StyledTableCell>
                        <StyledTableCell>Статус</StyledTableCell>
                        <StyledTableCell>Функції</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.isReport === true &&
                        this.props.report
                          .slice(
                            this.state.page * this.state.rowsPerPage,
                            this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
                          )
                          .map((item, index) => (
                            <Child
                              onMouseEnter={() => this.handleMouseEnter(index)}
                              onMouseLeave={() => this.handleMouseLeave(index)}
                              text={item}
                              isHovering={this.state.isHovered[index]}
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              index={index}
                              report={this.props.report}
                              orederView={this.orederView}
                              handleDeleteReport={this.handleDeleteReport}
                              handleUnsignReport={this.handleUnsignReport}
                              editReport={this.editReport}
                              createReport={this.createReport}
                              history={this.props.history}
                            />
                          ))}

                      {emptyRows > 0 && (
                        <TableRow style={{ height: 48 * emptyRows }}>
                          <StyledTableCell colSpan={8} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* eslint-disable-next-line react/destructuring-assignment */}
                {this.state.isReport && (
                  <TablePgination
                    report={this.props.report}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                  />
                )}
              </CardContent>
            </Card>
          </Col>
        </Row>
        <div>
          <CalendarModal
            open={openCalendar}
            handleOpen={this.handleOpenCalendar}
            handleClose={this.handleCloseCalendar}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    investory: state.cargo.investory,
    report: state.cargo.report.cursor,
    pi_recipient: state.cargo.report.pi_recipient,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getReportAction,
    setReportParams,
    cargoIDPdf,
    setReportId,
    setReportTab,
  })(withStyles(styles)(ReportDescription))
);

//packages
import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Button, CardFooter } from 'reactstrap';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router';
//сomponents
import ControlsDetailInfoView from './ControlsDetailInfoView';
import Box from '@material-ui/core/Box';
import * as Feather from 'react-feather';
//utils
import isEmpty from '../../../../vibe/components/utilities/MainUtils/is-empty';
import { IconButton } from '@material-ui/core';
import BootstrapTooltip from '../../../elements/MaterialBootstrap';
import OldDataAid from './OldDataAid';
import NewDataAid from './NewDataAid';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const directoryParse = (arr, val) => {
  if (!isEmpty(arr)) {
    const dir = arr.filter(({ value }) => value == val);
    return dir.length > 0 ? dir[0].label : '';
  }
  return;
};
function DetaiInfoView(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(props.type === 3 ? 1 : 0);

  function handleChange(event, newValue) {
    if (props.type === 3) {
      setValue(1);
    } else {
      setValue(newValue);
    }
  }

  const handleRedirect = () => {
    // props.history.push('/vouting');
    // props.history.push('/received-mspu');
    props.history.goBack();
  };

  const oldData = (
    <CardBody>
      <p>№ в Реєстрі: {(props.old || {}).p_number}</p>
      <p>Область: {(props.old || {}).p_region_name}</p>
      <p>Населений пункт: {(props.old || {}).p_locality_name}</p>
      <p>Місцезнаходження: {(props.old || {}).p_adress}</p>
      <p>Код ЄДРПОУ: {(props.old || {}).p_edrpou}</p>
      <p>Назва отримувача: {(props.old || {}).p_orgname}</p>
      <p>
        Тип отримувача:{' '}
        {props.catalog.recipientTypes.find((item) => Number(item.value) === Number((props.old || {}).p_recipient_type))
          ?.label || ''}
      </p>
      <p>
        ПІБ керівника: {(props.old || {}).p_surname} {(props.old || {}).p_name} {(props.old || {}).p_patronymic}
      </p>
      <p>Телефон: {(props.old || {}).p_phone}</p>
      <p>Телефон 2: {(props.old || {}).p_phone2}</p>
      <p>Електронна адреса: {(props.old || {}).p_email}</p>
      <p>Дата включення в Реєстр: {(props.old || {}).p_d_include}</p>
      <p>Дата виключення з Реєстру: {(props.old || {}).p_d_delete}</p>
    </CardBody>
  );

  const newData = (
    <CardBody>
      <p style={{ color: props.newData.p_number ? 'green' : '' }}>№ в Реєстрі: {(props.newData || {}).p_number}</p>
      <p style={{ color: props.newData.p_region_name ? 'green' : '' }}>
        Область: {(props.newData || {}).p_region_name}
      </p>
      <p style={{ color: props.newData.p_locality_name ? 'green' : '' }}>
        Населений пункт: {(props.newData || {}).p_locality_name}
      </p>
      <p style={{ color: props.newData.p_adress ? 'green' : '' }}>Місцезнаходження: {(props.newData || {}).p_adress}</p>
      <p style={{ color: props.newData.p_edrpou ? 'green' : '' }}>Код ЄДРПОУ: {(props.newData || {}).p_edrpou}</p>
      <p style={{ color: props.newData.p_orgname ? 'green' : '' }}>
        Назва отримувача: {(props.newData || {}).p_orgname}
      </p>
      <p style={{ color: props.newData.p_recipient_type ? 'green' : '' }}>
        Тип отримувача:{' '}
        {props.catalog.recipientTypes.find(
          (item) => Number(item.value) === Number((props.newData || {}).p_recipient_type)
        )?.label || ''}
      </p>
      <p
        style={{ color: props.newData.p_surname || props.newData.p_name || props.newData.p_patronymic ? 'green' : '' }}
      >
        ПІБ керівника: {(props.newData || {}).p_surname} {(props.newData || {}).p_name}{' '}
        {(props.newData || {}).p_patronymic}
      </p>
      <p style={{ color: props.newData.p_phone ? 'green' : '' }}>Телефон: {(props.newData || {}).p_phone}</p>
      <p style={{ color: props.newData.p_phone2 ? 'green' : '' }}>Телефон 2: {(props.newData || {}).p_phone2}</p>
      <p style={{ color: props.newData.p_email ? 'green' : '' }}>Електронна адреса: {(props.newData || {}).p_email}</p>
      <p style={{ color: props.newData.p_d_include ? 'green' : '' }}>
        Дата включення в Реєстр: {(props.newData || {}).p_d_include}
      </p>
      <p style={{ color: props.newData.p_d_delete ? 'green' : '' }}>
        Дата виключення з Реєстру: {(props.newData || {}).p_d_delete}
      </p>
    </CardBody>
  );

  return (
    <div>
      <Card>
        <CardHeader>
          <ControlsDetailInfoView oldDataRecipient={props.old} oldDataCargo={props.oldDataCargo} type={props.type} />
          <Divider />
        </CardHeader>
        <Paper className={classes.root}>
          <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
            <Tab disabled={props.type === 3} label="Отримувач" {...a11yProps(0)} />
            <Tab disabled={props.type === 2} label="Допомога" {...a11yProps(1)} />
          </Tabs>
        </Paper>
        <TabPanel value={value} index={0}>
          <div className="row">
            <div className="col-md-6">
              <h4>Дані в реєстрі</h4>
              {oldData}
            </div>
            {!isEmpty(props.newData) && (
              <div className="col-md-6">
                <h4>Змінені дані</h4>
                {newData}
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="row">
            <div className="col-md-12">
              <h4>Дані в реєстрі</h4>
              <OldDataAid oldDataCargo={props.oldDataCargo} uktzed={props.catalog.uktzedFull} />
            </div>
            {/* {!isEmpty(props.newDataCargo) && <div className="col-md-6">
              <h4>Змінені дані</h4>
                <NewDataAid newDataCargo={props.newDataCargo}/>
               </div> } */}
          </div>
        </TabPanel>
        <CardFooter>
          <BootstrapTooltip title="вихід">
            <IconButton onClick={handleRedirect}>
              <Feather.LogOut style={{ transform: 'scaleX(-1)' }} size="25" />
            </IconButton>
          </BootstrapTooltip>
        </CardFooter>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => ({
  recipient: state.recipient.recipient,
  recipientID: state.recipient.tabsFlag.recipietnSaveID,
  catalog: state.catalogs,
  rescipientAppHistory: state.recipient.recipientAppHistory,
  user: state.auth.user,
  old: state.recipient.oldData,
  newData: state.recipient.newData,
  oldDataCargo: state.cargo.oldData,
  newDataCargo: state.cargo.newData,
  type: state.commissionCreate.type,
});

export default withRouter(connect(mapStateToProps)(DetaiInfoView));

// packages
import React from 'react';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import Functions from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
// table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles } from '@material-ui/core/styles';
import { UncontrolledTooltip } from 'reactstrap';
import ModalDocument from '../../Commission/DetailInfoView/ModalDocument';
import { getScanApi } from '../../../../api/api';

// import { StyledTableSortLabel } from '../StyledTableComponents';
// import { getComparator, stableSort } from '../sortFunctions';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const tableHeads = [
  {
    id: 'a_type',
    numeric: false,
    label: 'Тип заяви',
  },
  {
    id: 'n_number',
    numeric: false,
    label: '№ заяви',
  },
  {
    id: 'c_surname',
    numeric: false,
    label: 'Заявник',
  },
  {
    id: 'd_create',
    numeric: false,
    label: 'Дата реєстрації',
  },
  {
    id: 'a_state',
    numeric: false,
    label: 'Стан',
  },
  {
    id: 'text',
    numeric: false,
    label: 'Документи',
  },
];

export default function RegisterHistoryFullScreen({ rescipientAppHistory }) {
  const [scanView, getScanView] = React.useState(false);
  const [modal, openModal] = React.useState(false);
  const [scan, getScan] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function scanDocs(row) {
    const { appl_id } = row.original;
    openModal((modal) => !modal);
    getScanView(true);

    try {
      const response = await getScanApi(appl_id);
      getScan(response.data);
    } catch (error) {}
  }

  // React.useEffect(() => {
  //   console.log('rescipientAppHistory', rescipientAppHistory);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const tableRows = rescipientAppHistory || [];

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, tableRows.length - page * rowsPerPage);

  return (
    <div>
      <div className="table-responsive">
        <TableContainer>
          <Table style={{ fontSize: '12px' }} hover="true" size="small">
            <TableHead>
              <TableRow>
                {tableHeads.map(({ id, label }) => (
                  <StyledTableCell key={id}>{label}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows.length > 0 ? (
                tableRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => {
                  const { a_type, n_number, c_surname, c_name, c_patronymic, d_create, a_state } = item;
                  return (
                    <TableRow key={n_number}>
                      <StyledTableCell>{a_type}</StyledTableCell>
                      <StyledTableCell>{n_number}</StyledTableCell>
                      <StyledTableCell>
                        {c_surname}
                        {c_name && <br />}
                        {c_name || null}
                        {c_patronymic && <br />}
                        {c_patronymic || null}
                      </StyledTableCell>
                      <StyledTableCell>{d_create}</StyledTableCell>
                      <StyledTableCell>{a_state}</StyledTableCell>
                      <StyledTableCell>
                        <UncontrolledTooltip placement="top" target="functionCommissionRegister">
                          Документи
                        </UncontrolledTooltip>
                        <IconButton
                          id="functionCommissionRegister"
                          aria-label="Order"
                          color="primary"
                          onClick={() => scanDocs({ original: item })}
                        >
                          <Functions />
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <StyledTableCell colSpan={6} style={{ textAlign: 'center' }}>
                    Відсутні значення
                  </StyledTableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <StyledTableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={tableRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} з ${count}`}
          labelRowsPerPage="Рядків на сторінці:"
        />
      </div>

      {scanView && <ModalDocument openModal={openModal} modal={modal} scanView={scanView} scan={scan} />}
    </div>
  );
}

RegisterHistoryFullScreen.propTypes = {
  rescipientAppHistory: PropTypes.array.isRequired,
};

import React from 'react';

import ReactTable from 'react-table';

import { Button } from 'reactstrap';

import Checkbox from '@material-ui/core/Checkbox';

import { withRouter } from 'react-router-dom';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function CommissionResults({ result }) {
  return (
    <Table style={{ fontSize: '12px' }} hover>
      <TableHead>
        <TableRow>
          <StyledTableCell>№ заяви</StyledTableCell>
          <StyledTableCell>Тип заяви</StyledTableCell>
          <StyledTableCell>Назва отримувача</StyledTableCell>
          <StyledTableCell>Код ЄДРПОУ</StyledTableCell>
          <StyledTableCell>Вага кг.</StyledTableCell>
          <StyledTableCell>ПІБ члена робочої групи</StyledTableCell>
          <StyledTableCell>Посада члена робочої групи</StyledTableCell>
          <StyledTableCell>Рішення</StyledTableCell>
          <StyledTableCell>Коментар</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {result.map((text, index) => (
          <TableRow key={text.n_number}>
            <StyledTableCell>{text.n_number}</StyledTableCell>
            <StyledTableCell>{text.app_type}</StyledTableCell>
            <StyledTableCell>{text.c_orgname}</StyledTableCell>
            <StyledTableCell>{text.n_edrpou}</StyledTableCell>
            <StyledTableCell>{text.weight}</StyledTableCell>
            <StyledTableCell>{text.user_name}</StyledTableCell>
            <StyledTableCell>{text.c_position}</StyledTableCell>
            <StyledTableCell>{text.decision}</StyledTableCell>
            <StyledTableCell>{text.c_comment}</StyledTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default withRouter(CommissionResults);

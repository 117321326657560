import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from 'react-datepicker';
import uk from 'date-fns/locale/uk';
import * as Feather from 'react-feather';
import { IconButton, TextField, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CommissionResult from './CommissionResults';
import { useSelector } from 'react-redux';
import { meetingResult } from '../../../../api/api';
import Select from 'react-select';
import { customTheme, customStyles } from '../../../../customStyles/reactSelect';
export default function ModalCommisionView({ result, modal, openModal, id }) {
  const [stateInput, setInput] = React.useState({
    number: '',
    type: '',
    orgname: '',
    edrpou: '',
    user_name: '',
    decision: '',
  });
  const [dataFilter, setDataFilter] = React.useState({});
  const [flagFilter, setFlagFilter] = React.useState(false);
  const {
    catalogs: { applicationType, decisonType },
  } = useSelector((state) => state);

  const handleInput = (event) => {
    const { name } = event.target;
    setInput({ ...stateInput, [name]: event.target.value });
  };

  const handleSelectChange = (name) => (select, { action }) => {
    let objDelete = {};
    objDelete[name] = '';
    switch (action) {
      case 'select-option':
        let obj = {};
        obj[name] = select;
        setInput(obj);
        break;
      case 'clear':
        setInput(objDelete);
        break;
    }
  };

  const handleSearchFilter = async () => {
    const params = {
      meeting_id: id,
      n_number: stateInput.number,
      app_type: stateInput.type ? stateInput.type.value : stateInput.type,
      c_orgname: stateInput.orgname,
      n_edrpou: stateInput.edrpou,
      user_name: stateInput.user_name,
      decision: stateInput.decision ? stateInput.decision.value : stateInput.decision,
    };
    try {
      const response = await meetingResult(params);
      setDataFilter(response.data);
      setFlagFilter(true);
    } catch (error) {}
  };
  if (flagFilter === true) {
    result = dataFilter;
  }

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        setInput({
          number: '',
          type: '',
          orgname: '',
          edrpou: '',
          user_name: '',
          decision: '',
        });
        setFlagFilter(false);
        openModal();
      }}
      className="modal-xl"
    >
      <ModalHeader
        toggle={() => {
          setInput({
            number: '',
            type: '',
            orgname: '',
            edrpou: '',
            user_name: '',
            decision: '',
          });
          setFlagFilter(false);
          openModal();
        }}
      >
        <h4>Результати голосування</h4>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <TextField
              className="form-control"
              label="№ заяви"
              autocomplete="off"
              name="number"
              onChange={handleInput}
              value={stateInput.number}
            />
          </Grid>
          <Grid item xs={5}>
            <Select
              value={stateInput.type}
              onChange={handleSelectChange('type')}
              options={applicationType}
              isSearchable={true}
              isClearable={true}
              placeholder="Тип заяви"
              styles={customStyles}
              noOptionsMessage={() => 'помилка серверу'}
              menuPortalTarget={document.body}
              theme={customTheme}
              name="type"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              className="form-control"
              label="Назва отримувача"
              autocomplete="off"
              name="orgname"
              onChange={handleInput}
              value={stateInput.orgname}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              className="form-control"
              label="Код ЄДРПОУ"
              autocomplete="off"
              name="edrpou"
              onChange={handleInput}
              value={stateInput.edrpou}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              className="form-control"
              label="ПІБ члена робочої групи"
              autocomplete="off"
              name="user_name"
              onChange={handleInput}
              value={stateInput.user_name}
            />
          </Grid>
          <Grid item xs={3}>
            <Select
              value={stateInput.decision}
              onChange={handleSelectChange('decision')}
              options={decisonType}
              isSearchable={true}
              isClearable={true}
              placeholder="Рішення"
              styles={customStyles}
              noOptionsMessage={() => 'помилка серверу'}
              menuPortalTarget={document.body}
              theme={customTheme}
              name="decision"
            />
          </Grid>
          <Grid item xs={3}>
            <Button color="primary" onClick={handleSearchFilter}>
              Пошук
            </Button>
          </Grid>
        </Grid>
      </ModalHeader>
      <ModalBody>
        <div className="table-responsive">
          <CommissionResult result={result} />
        </div>
      </ModalBody>
      {/* <ModalFooter> */}
      {/* <IconButton disabled>
                    <Feather.Save />
                </IconButton> */}
      {/* <IconButton onClick={() => openModal()}>
                    <Feather.X />
                </IconButton> */}

      {/* </ModalFooter> */}
    </Modal>
  );
}

import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from 'react-datepicker';
import uk from 'date-fns/locale/uk';
import * as Feather from 'react-feather';
import { IconButton, TextField } from '@material-ui/core';
import TableModalReportView from './RegistrationsForms/TableModalReportView';
import TableModalReportCreate from './RegistrationsForms/TableModalReportCreate';

export default function ModalReportPeriod({
  date,
  edit,
  view,
  modal,
  openModal,
  distributions,
  distributionView,
  handleChange,
  handleSaveDitribution,
  dateChane,
}) {
  return (
    <Modal isOpen={modal} toggle={() => openModal()} className="modal-xl">
      <ModalHeader toggle={() => openModal()}>
        {!view && (
          <DatePicker
            selected={date}
            onChange={dateChane}
            dateFormat="dd.MM.yyyy"
            maxDate={new Date()}
            locale={uk}
            className="datePicker"
            icon={<Feather.Calendar />}
            customInput={<TextField />}
          />
        )}
      </ModalHeader>
      <ModalBody>
        <div className="table-responsive">
          {edit && (
            <TableModalReportCreate
              distributions={distributions}
              handleChange={handleChange}
              handleSaveDitribution={handleSaveDitribution}
            />
          )}
          {view && (
            <TableModalReportView
              distributions={distributionView}
              handleChange={handleChange}
              handleSaveDitribution={handleSaveDitribution}
            />
          )}
        </div>
      </ModalBody>
      {/* <ModalFooter> */}
      {/* <IconButton disabled>
                    <Feather.Save />
                </IconButton> */}
      {/* <IconButton onClick={() => openModal()}>
                    <Feather.X />
                </IconButton> */}

      {/* </ModalFooter> */}
    </Modal>
  );
}

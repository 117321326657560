export const transformDistribution = (distrib, acquirer, regionArray) => {
  if (!distrib) return [];

  const distribArray = distrib.map((item) => {
    const {
      // new params
      c_aid_descript,
      i_act,
      c_address,
      i_locality,
      c_locality_name,
      i_region,
      n_weight,
      c_description,
      i_acquirertype,
      n_count,
      c_orgname,
      n_acqcnt,
      d_distribution,
      n_edrpou,
      id: piId,
      c_document,
    } = item;
    // get locality obj

    const distribObj = {
      pi_id: piId,
      // new params
      i_act,
      c_aid_descript,
      //
      pi_acq_type: i_acquirertype === 1 ? '1' : '2',
      //
      pc_orgname: c_orgname,
      //
      pn_edrpou: n_edrpou || '',
      edrpouOption: acquirer.find((elem) => elem?.n_edrpou === n_edrpou) || null,
      pc_description: c_description,
      pn_weight: n_weight,
      pn_count: n_count,
      pc_document: c_document,
      //
      pn_acqcnt: n_acqcnt,
      pd_distribut: d_distribution,
      //
      pi_region: i_region,
      pi_region_obj: regionArray.find(({ value }) => value === i_region),
      pi_koatuu_obj: null,
      pi_koatuu: i_locality,
      c_locality_name,
      pc_address: c_address,
    };

    return distribObj;
  });
  return distribArray;
};

export const transformBalance = (balance, cargo) => {
  if (balance) {
    return balance.map((item) => {
      const {
        n_count_bal,
        n_count_in,
        n_count_out,
        i_aidtype,
        n_weight_out,
        n_weight_in,
        n_weight_rej,
        n_count_rej,
        id: piId,
        n_weight_bal,
        c_description,
      } = item;

      return {
        pi_id: piId,
        pi_aidtype_obj: cargo.find(({ id }) => Number(id) === Number(i_aidtype)),
        pc_descript: c_description,
        pn_weight_in: n_weight_in,
        pn_count_in: n_count_in,
        pn_weight_out: n_weight_out,
        pn_count_out: n_count_out,
        pn_weight_rej: n_weight_rej,
        pn_count_rej: n_count_rej,
        pn_weight_bal: n_weight_bal,
        pn_count_bal: n_count_bal,
      };
    });
  }

  return [];
};

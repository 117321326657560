import React, { useState } from 'react';
import axios from 'axios';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import IconButton from '@material-ui/core/IconButton';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import JsZip from 'jszip';
import FileSaver from 'file-saver';

const MakeZip = ({ text }) => {
  const [data, setData] = React.useState(null);

  const fetchData = (id) => {
    axios
      .get(`aid/getAttachmentTable/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handlerClickDownloadButton = async (e, id) => {
    e.preventDefault();
    fetchData(id);
  };

  const findCodes = (data) => {
    return {
      recipients: data.some((item) => item.code === 11),
      cargos: data.some((item) => item.code === 51),
      funds: data.some((item) => item.code === 52),
      services: data.some((item) => item.code === 53),
      er: data.some((item) => item.code === 31),
      exclude: data.some((item) => item.code === 41),
      ch_cargo: data.some((item) => item.code === 61),
      ch_recipient: data.some((item) => item.code === 71),
    };
  };

  const getBlob = async (pdf) => {
    return await fetch(pdf)
      .then((rez) => rez.blob())
      .catch((err) => console.log(err));
  };

  const saveZip = (list) => {
    const zip = new JsZip();
    let blob;
    let nakaz;

    if (list.recipients) {
      nakaz = require('../../../assets/nakaz/order_recipients.pdf');
      blob = getBlob(nakaz);
      zip.file('order_recipients.pdf', blob);
    }
    if (list.cargos) {
      nakaz = require('../../../assets/nakaz/order_cargo.pdf');
      blob = getBlob(nakaz);
      zip.file('order_cargo.pdf', blob);
    }
    if (list.funds) {
      nakaz = require('../../../assets/nakaz/order_funds.pdf');
      blob = getBlob(nakaz);
      zip.file('order_funds.pdf', blob);
    }
    if (list.services) {
      nakaz = require('../../../assets/nakaz/order_services.pdf');
      blob = getBlob(nakaz);
      zip.file('order_services.pdf', blob);
    }
    if (list.er) {
      nakaz = require('../../../assets/nakaz/ch_ER.pdf');
      blob = getBlob(nakaz);
      zip.file('ch_ER.pdf', blob);
    }
    if (list.exclude) {
      nakaz = require('../../../assets/nakaz/exclude.pdf');
      blob = getBlob(nakaz);
      zip.file('exclude.pdf', blob);
    }
    if (list.ch_cargo) {
      nakaz = require('../../../assets/nakaz/change.pdf');
      blob = getBlob(nakaz);
      zip.file('ch_cargo.pdf', blob);
    }
    if (list.ch_recipient) {
      nakaz = require('../../../assets/nakaz/ch_recipient.pdf');
      blob = getBlob(nakaz);
      zip.file('ch_recipient.pdf', blob);
    }

    zip
      .generateAsync({
        type: 'blob',
      })
      .then((content) => {
        FileSaver.saveAs(content, 'nakaz.zip');
      })
      .catch((err) => console.log(err));
  };

  React.useEffect(() => {
    if (data) {
      const codes = findCodes(data);
      saveZip(codes);
    }
  }, [data]);

  return (
    <>
      <IconButton
        disabled={text.i_state !== 30}
        id={`pdf${text.id}`}
        color="primary"
        aria-label="PDF"
        onClick={(e) => {
          e.stopPropagation();
          handlerClickDownloadButton(e, text.id);
        }}
      >
        <PictureAsPdfIcon fontSize="inherit" />
      </IconButton>
      {text.i_state === 30 && (
        <UncontrolledTooltip placement="bottom" target={`pdf${text.id}`}>
          Проекти наказів
        </UncontrolledTooltip>
      )}
    </>
  );
};

export default MakeZip;

// packages
import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import uk from 'date-fns/locale/uk';
import Swal from 'sweetalert2';
// styles
import { Container, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, Button } from 'reactstrap';
import ButtonMaterial from '@material-ui/core/Button';
import { Icon, TextField } from '@material-ui/core';
import { customTheme, customStyles } from '../../../customStyles/reactSelect';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-table/react-table.css';
// component
// api
import { meetingState, getCommissionsByFilters } from '../../../api/api';
import {
  setCurrentCommission,
  clearCurrentCommission,
  setMeetingAction,
  commisionID,
} from '../../../redux/actions/commissionCreate.action';
import BootstrapTooltip from '../../elements/MaterialBootstrap';

import logo from '../../../assets/images/gov.ua.white.png';
import CommisionTable from './PublicCommision/CommisionTable';
import { Loader } from '../../../vibe';
import { getUTCDate } from '../utils/utc';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});
const ALL_COMMISSIONS = 0;

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      date_from: '',
      date_till: '',
      applicationState: '',
      visible: false,
      meetingState: [],
      number: '',
    };
  }

  // datapicker
  handleChangeData = (name) => (data) => {
    this.setState({ [name]: data });
  };

  // select
  handleSelectChange = (name) => (select, { action }) => {
    const objDelete = {};
    objDelete[name] = '';
    switch (action) {
      case 'select-option':
        const obj = {};
        obj[name] = select.value;
        this.setState(obj);
        break;
      case 'clear':
        this.setState(objDelete);
        break;
    }
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.setState({
      submitted: true,
      loading: true,
      data: [],
    });
    const { date_from, date_till, number } = this.state;
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    const data = {
      p_user_id: '',
      p_only_my: ALL_COMMISSIONS,
      numb: number,
      p_dt_beg: date_from ? date_from.toLocaleDateString('uk', options) : '',
      p_dt_end: date_till ? date_till.toLocaleDateString('uk', options) : '',
    };

    getCommissionsByFilters(data)
      .then((response) => {
        this.setState({ data: response.data, visible: true, loading: false });
        localStorage.setItem('commision', JSON.stringify(response.data));
        localStorage.setItem('commisionObject', JSON.stringify(this.state));
      })
      .catch((error) => {
        this.setState({ data: [], loading: false });
        // if (error.response && error.response.status === 400) {
        //   swalWithBootstrapButtons.fire({
        //       title: 'Помилка',
        //       text: error.response.data,
        //       type: 'error',
        //   })
        // } else {
        //   swalWithBootstrapButtons.fire({
        //       title: 'Помилка серверу',
        //       type: 'error',
        //   })
        // }
      });
  };

  componentDidMount() {
    if (localStorage.commision) {
      this.setState({ data: JSON.parse(localStorage.commision) });
    }

    if (localStorage.commisionObject) {
      const { date_from, date_till, number } = JSON.parse(localStorage.commisionObject);

      this.setState({
        date_from: date_from ? getUTCDate(date_from) : '',
        date_till: date_till ? getUTCDate(date_till) : '',
        number,
      });
    }

    if (localStorage.meetingState) {
      const meetingState = JSON.parse(localStorage.meetingState);
      this.setState({ meetingState });
    } else if (!localStorage.meetingState) {
      async function getMeetingState() {
        try {
          const response = await meetingState();
          localStorage.setItem('meetingState', JSON.stringify(response.data));
        } catch (error) {
          localStorage.setItem('meetingState', []);
        }
      }

      getMeetingState();
    }
  }

  render() {
    const { selectedOption } = this.state;

    return (
      <div>
        <header>
          <Navbar
            color="light"
            dark
            expand="md"
            className="bg-gradient text-white"
            style={{ paddingBottom: '1.5rem', paddingTop: '1.5rem' }}
          >
            <Container>
              <NavbarBrand href="https://www.gov.ua/">
                <img src={logo} alt="buttonGovUa" />
              </NavbarBrand>
              <NavbarToggler onClick={this.toggle} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  <NavItem>
                    {/* <NavNavLink className="btn-outline" href="/register-recipients-public">Реєстр отримувачів</NavNavLink> */}
                    <NavLink
                      activeStyle={{
                        outline: '1px solid #fff',
                        fontFamily: 'dia_bold',
                      }}
                      className="nav-link text-white-btn"
                      to="/register-recipients-public"
                    >
                      Єдиний реєстр отримувачів
                    </NavLink>
                    {/* <ButtonMaterial variant="outlined" component={Link} to='/register-recipients-public'>Реєстр отримувачів</ButtonMaterial> */}
                  </NavItem>
                  <NavItem>
                    {/* <NavNavLink className="btn-outline" href="/register-public">Реєстр гуманітарної допомоги</NavNavLink> */}
                    <NavLink
                      activeStyle={{
                        outline: '1px solid #fff',
                        fontFamily: 'dia_bold',
                      }}
                      className="nav-link text-white-btn"
                      to="/register-public"
                    >
                      Реєстр гуманітарної допомоги
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      activeStyle={{
                        outline: '1px solid #fff',
                        fontFamily: 'dia_bold',
                      }}
                      className="nav-link text-white-btn"
                      to="/commission"
                    >
                      Засідання робочої групи
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <Button className="btn-warning text-dark ml-2" onClick={(e) => this.props.history.push('/')}>
                      Вхід
                    </Button>
                  </NavItem>
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
        </header>
        <div className="mainRegister" style={{ width: '90%', margin: '50px auto' }}>
          <section className="searchCommissionMeeting">
            <form onSubmit={this.handleSearch}>
              <div className="row justify-content-md-center">
                <div className="col-12 col-sm-6  col-md-4 col-xl-3">
                  {/* <label htmlFor="">Період з:</label> */}

                  <DatePicker
                    selected={this.state.date_from}
                    onChange={this.handleChangeData('date_from')}
                    dateFormat="dd.MM.yyyy"
                    // maxDate={this.state.date_till}
                    selectsStart
                    startDate={this.state.date_from}
                    endDate={this.state.date_till}
                    locale={uk}
                    className="datePicker"
                    icon={<i className="cal-icon" />}
                    customInput={<TextField label="Період з:" />}
                  />
                </div>

                <div className="col-12 col-sm-6  col-md-4 col-xl-3">
                  {/* <label htmlFor="">по:</label> */}
                  <DatePicker
                    selectsEnd
                    selected={this.state.date_till}
                    endDate={this.state.date_till}
                    minDate={this.state.date_from}
                    onChange={this.handleChangeData('date_till')}
                    dateFormat="dd.MM.yyyy"
                    // maxDate={new Date()}
                    className="datePicker"
                    locale={uk}
                    customInput={<TextField label="по:" />}
                  />
                </div>

                <div className="col-12 col-sm-6  col-md-4 col-xl-3">
                  {/* <label htmlFor="">№ Наказу та Протоколу:</label> */}
                  {/* <BootstrapTooltip title="Оберіть стан зі списку"> */}
                  {/* <Select
                //value={selectedOption}
                value={this.state.meetingState.filter(({ value }) => parseInt(value) === parseInt(this.state.applicationState))}
                onChange={this.handleSelectChange('applicationState')}
                options={this.state.meetingState}
                isSearchable={true}
                isClearable={true}
                placeholder=""
                styles={customStyles}
                placeholder=""
                noOptionsMessage={() => 'помилка серверу'}
                menuPortalTarget={document.body}
                theme={customTheme}
                name="applicationState"
              /> */}
                  <TextField
                    type="text"
                    label="№ Наказу та Протоколу:"
                    value={this.state.number}
                    onChange={(e) => {
                      this.setState({ number: e.target.value });
                    }}
                  />
                  {/* </BootstrapTooltip> */}
                </div>
                <div className="col-12 mt-3 mb-4 text-center">
                  <ButtonMaterial
                    type="submit"
                    // variant="contained"
                    color="primary"
                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                  >
                    <Icon className="fa fa-search mr-2" /> Пошук
                  </ButtonMaterial>
                </div>
                {this.state.loading && <Loader type="spin" small />}
              </div>
            </form>
          </section>
          <section className="dataMainRegister mt-3">
            <CommisionTable
              loading={this.state.loading}
              handleVouting={this.handleVouting}
              commisionID={this.props.commisionID}
              dataTable={this.state.data}
            />
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile,
  };
};

export default connect(mapStateToProps, null)(Register);

import {
    SET_CHECK_ROWS,
    CLEAR_CHECK_ROWS,
    FETCH_CHECK_ROWS_START,
    FETCH_CHECK_ROWS_SUCCESS,
    FETCH_CHECK_ROWS_FALIURE,
    SET_CHECK_ROW_COMMENT,
    SET_CHECK_ROW_ACT,
} from '../actions/action-types';

const initialState = {
    rows: [],
    isLoading: false,
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CHECK_ROWS:
            return {
                ...state,
                rows: [...action.payload?.map((item, index) => ({ ...item, index, act: null, comment: '' }))],
            };
        case CLEAR_CHECK_ROWS:
            return {
                ...state,
                rows: [],
            };
        case SET_CHECK_ROW_COMMENT: {
            const newArray = state.rows.map((item, i) => {
                const { index, text } = action.payload;
                if (index === i) return { ...item, comment: text };
                return item;
            });
            return {
                ...state,
                rows: [...newArray],
            };
        }
        case SET_CHECK_ROW_ACT: {
            const { index, value } = action.payload;
            const { rows } = state;
            const newRows = [...rows];
            newRows[index].act = value;
            return {
                ...state,
                rows: [...newRows],
            }
        };

        case FETCH_CHECK_ROWS_START:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case FETCH_CHECK_ROWS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                rows: [...action.payload?.map((item, index) => ({ ...item, index, act: null, comment: '' }))],
            };
        case FETCH_CHECK_ROWS_FALIURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

//packages
import React, { useState } from 'react';
import { connect } from 'react-redux';
//reactstrap
import { UncontrolledTooltip } from 'reactstrap';
//MUI
import IconButton from '@material-ui/core/IconButton';
import RecipientIcon from '@material-ui/icons/AssignmentInd';
import OrderIcon from '@material-ui/icons/Assignment';
import ReportIcon from '@material-ui/icons/ListAlt';
import ArchiveIcon from '@material-ui/icons/Archive';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import HistoryIcon from '@material-ui/icons/AssignmentTurnedIn';
import ProtocolIcon from '@material-ui/icons/Assignment';
import LawIcon from '@material-ui/icons/AccountBalance';
import ResVoutingIcon from '@material-ui/icons/ThumbsUpDown';
import { withStyles } from '@material-ui/core/styles';
import ModalCommisionView from './ModalCommisionView';
import {
  meetingResult,
  getOrderDoc,
  getProtocolDoc,
  getTakeHtml,
  openModalInfoPDF,
  endMeeting,
} from '../../../../api/api';
import ViewDocModal from '../../Dialogs/ViewDocModal';
import NumberOrderModal from '../../Dialogs/NumberOrderModal';
import ExcelExport from '../ExcelExport';

import { withRouter } from 'react-router';
import { numberOrderAction } from '../../../../redux/actions/addIDhtml.action';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import { textAlign } from '@material-ui/system';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const styles = {
  root: {
    fontSize: '1rem',
    borderRadius: '0px',
  },
};

const Controls = ({ id, history, idHtml, text, numberOrderAction }) => {
  const [modal, openModal] = useState(false);
  const [dialog, toggle] = useState(false);
  const [data, getMeetingResult] = useState([]);
  const [htmlDoc, getDoc] = useState('');
  const [PDFDoc, getPDFDoc] = useState('');
  const [modalNumber, openModalNumber] = useState(false);
  const [dataNumber, getDataNumber] = useState('');

  async function handleView() {
    openModal((modal) => !modal);
    try {
      const params = {
        meeting_id: id,
      };
      const response = await meetingResult(params);
      getMeetingResult(response.data);
    } catch (error) {}
  }

  async function orederView() {
    try {
      for (let i = 0; i < idHtml.length; i++) {
        const testid = idHtml[i].id;
        const response = await getTakeHtml(id, testid);
        getPDFDoc(response.data);
        toggle(!dialog);

        const blob = new Blob(['\ufeff', response.data], {
          type: 'application/msword',
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('A');
        link.href = url;
        link.download = 'Document.doc';
        document.body.appendChild(link);
        if (navigator.msSaveOrOpenBlob) navigator.msSaveOrOpenBlob(blob, 'Document.doc');
        // IE10-11
        else link.click(); // other browsers
        document.body.removeChild(link);
      }
      // const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', `${idHtml[i].c_name}.pdf`);
      // document.body.appendChild(link);
      // link.click();
      // link.remove();
    } catch (error) {
      swalWithBootstrapButtons.fire({
        title: 'Помилка',
        text: 'Засідання ще не закрите. Перегляд неможливий.',
        type: 'error',
      });
    }
  }

  async function protocolView() {
    try {
      const response = await getProtocolDoc(id);
      getDoc(response.data);
      toggle(!dialog);
    } catch (error) {
      console.log('error', error.response);
    }
  }

  function toggleModal() {
    toggle(!dialog);
    getDoc('');
  }

  async function NumberDataModal() {
    try {
      const response = await openModalInfoPDF(id);
      numberOrderAction(response.data);
      getDataNumber(response.data);
      openModalNumber(true);
    } catch (error) {}
  }
  function closeNumberModal() {
    openModalNumber(false);
  }

  function exportToWord() {
    const blob = new Blob(['\ufeff', htmlDoc], {
      type: 'application/msword',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('A');
    link.href = url;
    link.download = 'Document.doc';
    document.body.appendChild(link);
    if (navigator.msSaveOrOpenBlob) navigator.msSaveOrOpenBlob(blob, 'Document.doc');
    // IE10-11
    else link.click(); // other browsers
    document.body.removeChild(link);
  }

  function endMeetingNotOrder() {
    swalWithBootstrapButtons
      .fire({
        text: 'Після завершення засідання не буде можливості редагувати дані. Завершити?',
        showCancelButton: true,
        confirmButtonText: 'Так',
        cancelButtonText: 'Відміна',
      })
      .then(async (result) => {
        if (result.value) {
          try {
            const id_meeting = text.id;
            const id_user = text.i_user;
            const response = await endMeeting(id_user, id_meeting);
            swalWithBootstrapButtons.fire({
              title: 'Засідання завершено!',
              type: 'success',
            });
            // this.props.closeNumberModal();
            // try {
            //   const success = await getMeetingList(this.props.id, this.props.user.id)
            //   this.props.setMeetingAction(success.data)
            // } catch (error) {
            //   swalWithBootstrapButtons.fire({
            //     title: error.response.data,
            //     type: 'error',
            //   })
            // }
          } catch (error) {
            swalWithBootstrapButtons.fire({
              title: error.response.data,
              type: 'error',
            });
          }
        }
      });
  }

  return (
    <div>
      <UncontrolledTooltip placement="top" target="orderCardСommission">
        Наказ
      </UncontrolledTooltip>
      {idHtml.length > 0 ? (
        <IconButton
          id="orderCardСommission"
          aria-label="orderCardСommission"
          color="primary"
          onClick={() => {
            orederView();
          }}
        >
          <LawIcon />
        </IconButton>
      ) : (
        <IconButton
          id="orderCardСommission"
          aria-label="orderCardСommission"
          color="primary"
          onClick={() => {
            swalWithBootstrapButtons.fire({
              title: 'НАКАЗИ ВІДСУТНІ',
              text: 'По засіданню немає позитивних рішень.',
              type: 'error',
            });
          }}
        >
          <LawIcon />
        </IconButton>
      )}

      {/* <UncontrolledTooltip placement="top" target="appendixorderCardСommission">
        Додатки до каназу
      </UncontrolledTooltip>

      <IconButton id="appendixorderCardСommission" aria-label="appendixorderCardСommission" color="primary">
        <NoteAddIcon />
      </IconButton> */}
      <ExcelExport text={text} type="nakaz" status="ended" />

      <UncontrolledTooltip placement="top" target="protocolCardСommission">
        Протокол
      </UncontrolledTooltip>

      <IconButton
        id="protocolCardСommission"
        aria-label="protocolCardСommission"
        color="primary"
        onClick={() => {
          protocolView();
        }}
      >
        <ReportIcon />
      </IconButton>

      {/* <UncontrolledTooltip placement="top" target="appendixProtocolCardСommission ">
        Додатки до протоколу
      </UncontrolledTooltip>

      <IconButton
        id="appendixProtocolCardСommission"
        aria-label="appendixProtocolCardСommission"
        color="primary"
        disabled={status !== 40 && status !== 50}
        onClick={() => { }}
      >
        <ArchiveIcon />
      </IconButton> */}
      <ExcelExport text={text} type="protocol" status="ended" />

      <UncontrolledTooltip placement="top" target="commissionRes">
        Результати голосування
      </UncontrolledTooltip>

      <IconButton id="commissionRes" aria-label="commissionRes" color="primary" onClick={handleView}>
        <ResVoutingIcon />
      </IconButton>
      {/* <UncontrolledTooltip placement="top" target="NumberOrder">
        Ввести № наказу
      </UncontrolledTooltip> */}

      {idHtml.length <= 0 && text.i_state != 50 ? (
        <Button variant="contained" color="primary" onClick={() => endMeetingNotOrder()}>
          Завершити засідання
        </Button>
      ) : idHtml.length > 0 ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            NumberDataModal();
          }}
        >
          Введення номерів наказів
        </Button>
      ) : null}
      {/* <IconButton
        id="NumberOrder"
        aria-label="NumberOrder"
        color="primary"
        onClick={() => {NumberDataModal()}}
      > 
        <ArchiveIcon />
        Введення дати та номера наказів
      </IconButton> */}

      {data.length > 0 && <ModalCommisionView modal={modal} openModal={openModal} result={data} id={id} />}
      {htmlDoc && (
        <ViewDocModal
          modal={dialog}
          htmlDoc={htmlDoc}
          toggle={() => toggleModal()}
          exportToWord={() => exportToWord()}
        />
      )}
      {/* {dataNumber && <NumberOrderModal open={modalNumber} dataNumber={dataNumber} closeNumberModal={() => closeNumberModal()} />} */}
      {dataNumber && (
        <NumberOrderModal open={modalNumber} dataNumber={dataNumber} closeNumberModal={() => closeNumberModal()} />
      )}

      <UncontrolledTooltip placement="top" target="orderUpload">
        Завантаження відсканованого наказу
      </UncontrolledTooltip>

      <IconButton
        disabled={text.i_state != 50}
        id="orderUpload"
        aria-label="orderUpload"
        color="primary"
        onClick={() => {
          history.push('/order-upload');
        }}
      >
        <i className="fa fa-cloud mr-1" />
        {/* <i className="fa fa-file-excel mr-1" /> */}
      </IconButton>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    id: state.commissionCreate.id,
    idHtml: state.idHtml.IdHtml,
  };
};

export default withRouter(connect(mapStateToProps, { numberOrderAction })(withStyles(styles)(Controls)));

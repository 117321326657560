import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import startReportExcel from './startReportExcel';

function ExportIcon(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <path d="M0 17H14V15H0V17ZM14 6H10V0H4V6H0L7 13L14 6Z" fill="#141414" />
    </SvgIcon>
  );
}

const useStyles = makeStyles((theme) => ({
  excel: {
    backgroundColor: '#D5D8E0',
    color: '#141414',
    padding: '12px 24px',
    margin: 0,
    fontFamily: 'e-Ukraine Head',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
  },
}));

const ReportExcel = () => {
  const classes = useStyles();
  const {
    report: {
      balance: { array: balanceArray },
      distribution: { array: distrArray },
    },
  } = useSelector((state) => state);

  const prepareData1 = (data) => {
    return data.map((item) => {
      const {
        pi_aidtype_obj,
        pc_descript,
        pn_weight_in,
        pn_count_in,
        pn_weight_out,
        pn_count_out,
        pn_weight_rej,
        pn_count_rej,
        pn_weight_bal,
        pn_count_bal,
      } = item;

      return {
        i_aidtype: pi_aidtype_obj?.c_name,
        c_description: pc_descript,
        n_weight_in: pn_weight_in,
        n_count_in: pn_count_in,
        n_weight_out: pn_weight_out,
        n_count_out: pn_count_out,
        n_weight_rej: pn_weight_rej,
        n_count_rej: pn_count_rej,
        n_weight_bal: pn_weight_bal,
        n_count_bal: pn_count_bal,
      };
    });
  };

  const prepareData2 = (data) => {
    return data.map((item) => {
      const {
        pc_orgname,
        pn_edrpou,
        c_aid_descript,
        pc_description,
        pn_weight,
        pn_count,
        pc_document,
        pn_acqcnt,
        pd_distribut,
        pi_region_obj,
        c_locality_name,
        pc_address,
      } = item;

      return {
        c_orgname: pc_orgname,
        n_edrpou: pn_edrpou,
        i_aidtype: c_aid_descript,
        c_description: pc_description,
        n_weight: pn_weight,
        n_count: pn_count,
        c_document: pc_document,
        n_acqcnt: pn_acqcnt,
        d_distribution: pd_distribut,
        i_region: `${pi_region_obj?.label}, ${c_locality_name}, ${pc_address}`,
      };
    });
  };

  const downloadExcel = () => {
    const data1 = balanceArray ? prepareData1(balanceArray) : [];
    const data2 = distrArray ? prepareData2(distrArray) : [];
    startReportExcel(data1, data2);
  };

  return (
    <>
      <Button
        type="button"
        className={classes.excel}
        startIcon={<ExportIcon />}
        onClick={downloadExcel}
        disabled={balanceArray && balanceArray.length === 0}
      >
        Excel
      </Button>
    </>
  );
};

export default ReportExcel;

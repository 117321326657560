import React from 'react';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import exportExcel from './exportUktzedExcel';

const ExportExcelButton = () => {
  const {
    catalogs: { uktzed },
  } = useSelector((state) => state);

  const handleExport = () => {
    exportExcel(uktzed);
  };

  return (
    <Button variant="contained" color="primary" onClick={handleExport}>
      Excel
    </Button>
  );
};

export default ExportExcelButton;

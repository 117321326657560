// packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import InputMask from 'react-input-mask';
// styles
import { Button, TextField, withStyles, Grid } from '@material-ui/core';
// import { withStyles, Grid } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { Autocomplete } from '@material-ui/lab';
import { customTheme, customStyles } from '../../../../customStyles/reactSelect';
import BootstrapTooltip from '../../../elements/MaterialBootstrap';
import { getNaisData } from '../../../../api/api';
import NumberFormatCustom from '../../../../vibe/components/inputs/NumberFormatCustom.component';

const style = (theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  rowBottomLine: { width: '100%', display: 'flex', alignItems: 'flex-end', flexWrap: 'wrap' },
});

class AqcuirerInputs extends Component {
  filterOptions = (candidate, input) => {
    if (candidate.data.label.toLowerCase().includes(input.toLowerCase())) {
      return true;
    }
    return false;
  };

  prepareData = (json) => {
    // address
    const atu = json.address.parts.atu.split(', ');
    const region = atu[0].trim();
    let locality = '';
    if (atu.length >= 2) {
      locality = atu[atu.length - 1].trim();
    }

    const atuCode = json.address.parts.atu_code.trim();
    const street = json.address.parts.street.trim();

    const houseType = json.address.parts.house_type;
    const house = `${json.address.parts.house}`;
    const addr1 = houseType ? `, ${houseType} ${house}` : '';

    const buildingType = json.address.parts.building_type;
    const { building } = json.address.parts;
    const addr2 = buildingType ? `, ${buildingType} ${building}` : '';

    const { num_type } = json.address.parts;
    const { num } = json.address.parts;
    const addr3 = num_type ? `, ${num_type} ${num}` : '';
    // final code for street address
    const streetAddress = `${street}${addr1}${addr2}${addr3}`.trim();
    // orgName
    const orgname = json.names.display || '';
    // name
    const nameObj = json.heads.find((el) => el.role_text === 'керівник');
    const lastName = nameObj.last_name;
    const fmn = nameObj.first_middle_name.split(' ');
    const firstName = fmn[0];
    const middleName = fmn[1];

    // phone
    let tel1 = '';
    let tel2 = '';
    const tels = json.contacts.tel;
    if (tels.length === 1) {
      tel1 = tels[0].trim();
    } else if (tels.length >= 2) {
      tel1 = tels[0].trim();
      tel2 = tels[1].trim();
    }

    // email
    const email = json.contacts.email || '';

    const phone_max = tel2 ? 2 : 1;

    return {
      region_nais: region,
      koatuu_nais: locality,
      address_nais: streetAddress,
      orgname_nais: orgname,
      surname_nais: lastName,
      name_nais: firstName,
      patronymic_nais: middleName,
    };
  };

  triggerNais = async () => {
    const { n_edrpou } = this.props.cargoAquirer;
    const { cargoAction } = this.props;
    //
    try {
      if (n_edrpou.toString().length === 8) {
        const res = await getNaisData(n_edrpou);
        const json = res.data;
        const nais = this.prepareData(json);
        cargoAction('c_orgname', nais.orgname_nais);
        cargoAction('c_surname', nais.surname_nais);
        cargoAction('c_name', nais.name_nais);
        cargoAction('c_patronymic', nais.patronymic_nais);
        // cargoAction('i_region',)
        // cargoAction('i_locality',)
        cargoAction('c_adress', nais.address_nais);
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <section className="planForm">
        <div className="row">
          <div className="w-100" />
          <div className="col-12 col-sm-6 col-md-3 col-xl-3 mb-2">
            {/* <label htmlFor="">Тип набувача</label> */}
            <BootstrapTooltip title=" Оберіть  тип зі списку." tooltipPlacemenTop>
              <div>
                {/* <Select
                  value={this.props.catalog.acquirerType.filter(
                    ({ value }) => parseInt(value) === parseInt(this.props.cargoAquirer.i_acquirertype)
                  )}
                  onChange={this.props.handleSelectChange('i_acquirertype')}
                  options={this.props.catalog.acquirerType}
                  styles={customStyles}
                  placeholder=""
                  // isMulti
                  noOptionsMessage={() => 'помилка серверу'}
                  menuPortalTarget={document.body}
                  theme={customTheme}
                /> */}
                <Autocomplete
                  value={
                    this.props.catalog.acquirerType.filter(
                      ({ value }) => parseInt(value) === parseInt(this.props.cargoAquirer.i_acquirertype)
                    )[0]
                  }
                  onChange={this.props.handleSelectChange('i_acquirertype')}
                  autoHighlight
                  getOptionSelected={(option) => option.value === this.props.cargoAquirer.i_acquirertype}
                  getOptionLabel={(option) => option.label}
                  options={this.props.catalog.acquirerType}
                  renderInput={(params) => <TextField {...params} label="Тип набувача" />}
                />
              </div>
            </BootstrapTooltip>
          </div>
          {parseInt(this.props.cargoAquirer.i_acquirertype) === 1 && (
            <div className="col-12 col-sm-6  col-md-4 col-xl-4 mb-2">
              {/* <label htmlFor="">Найменування набувача</label> */}
              <BootstrapTooltip title=" Введіть найменування набувача. Дозволено ведення цифр, літер українською мовою, символів Ʌ№””.’,-/">
                <TextField
                  label="Найменування набувача"
                  required
                  value={this.props.cargoAquirer.c_orgname}
                  onChange={this.props.handleChangeRedux}
                  name="c_orgname"
                  type="text"
                  // maxLength="128"
                  inputProps={{
                    maxLength: 128,
                  }}
                />
              </BootstrapTooltip>
            </div>
          )}
          {parseInt(this.props.cargoAquirer.i_acquirertype) === 1 && (
            <div className="col-12 col-sm-6  col-md-3 col-xl-3 mb-2">
              {/* <label htmlFor="p_adress">Код ЄДРПОУ набувача</label> */}
              <BootstrapTooltip title=" Введіть код ЄДРПОУ набувача.">
                <TextField
                  label="Код ЄДРПОУ набувача"
                  required
                  value={this.props.cargoAquirer.n_edrpou}
                  // onValueChange={(values) => {
                  //   const { formattedValue } = values;
                  //   this.props.handleChangeNumberFormat('n_edrpou', formattedValue);
                  // }}
                  onChange={this.props.handleChangeRedux}
                  // decimalSeparator="."
                  // decimalScale={0}
                  name="n_edrpou"
                  type="text"
                  // maxLength="8"
                  inputProps={{
                    maxLength: 8,
                  }}
                  // allowNegative={false}
                />
              </BootstrapTooltip>
            </div>
          )}
          {parseInt(this.props.cargoAquirer.i_acquirertype) === 1 && (
            <div className="col-12 col-sm-6  col-md-2 col-xl-2 mb-2">
              <label htmlFor="">Пошук за ЄДРПОУ</label>
              <Button
                type="button"
                variant="outlined"
                // color="primary"
                style={{ marginLeft: 'auto', marginRight: 'auto', fontSize: '12px' }}
                onClick={this.triggerNais}
              >
                <i className="fa fa-search mr-1" /> система НАІС
              </Button>
            </div>
          )}
          <div className="w-100" />
          {parseInt(this.props.cargoAquirer.i_acquirertype) === 1 && (
            <div className="ml-3" style={{ color: '#7f8fa4', fontWeight: 'bold' }}>
              Керівник:
            </div>
          )}
          <div className="w-100" />
          {parseInt(this.props.cargoAquirer.i_acquirertype) === 1 && (
            <>
              <div className="col-12 col-sm-6  col-md-4 col-xl-4 mb-2">
                {/* <label htmlFor="">Прізвище </label> */}
                <BootstrapTooltip title=" Введіть прізвище керівника юр. особи набувача/прізвище фіз. особи набувача. Дозволено ведення літер українською, англійською мовами та символів -’">
                  <TextField
                    label="Прізвище"
                    required
                    value={this.props.cargoAquirer.c_surname}
                    onChange={this.props.handleChangeRedux}
                    name="c_surname"
                    type="text"
                    // maxLength="64"
                    inputProps={{
                      maxLength: 64,
                    }}
                  />
                </BootstrapTooltip>
              </div>

              <div className="col-12 col-sm-6  col-md-4 col-xl-4 mb-2">
                {/* <label htmlFor="">Iм&rsquo;я </label> */}
                <BootstrapTooltip title=" Введіть ім’я керівника юр. особи набувача/ ім’я фіз. особи набувача. Дозволено ведення літер українською, англійською мовами та символів -’">
                  <TextField
                    label="Iм&rsquo;я"
                    required
                    value={this.props.cargoAquirer.c_name}
                    onChange={this.props.handleChangeRedux}
                    name="c_name"
                    type="text"
                    // maxLength="64"
                    inputProps={{
                      maxLength: 64,
                    }}
                  />
                </BootstrapTooltip>
              </div>
              <div className="col-12 col-sm-6  col-md-4 col-xl-4 mb-2">
                {/* <label htmlFor="">По батькові</label> */}
                <BootstrapTooltip title=" Введіть по батькові керівника юр. особи набувача/ по батькові фіз. особи набувача Дозволено ведення літер українською мовою та символів -’">
                  <TextField
                    label="По батькові"
                    required
                    value={this.props.cargoAquirer.c_patronymic}
                    onChange={this.props.handleChangeRedux}
                    name="c_patronymic"
                    type="text"
                    // maxLength="64"
                    inputProps={{
                      maxLength: 64,
                    }}
                  />
                </BootstrapTooltip>
              </div>
            </>
          )}

          <div className="w-100" />
          {/* {(Number(this.props.cargoAquirer.i_acquirertype) === 2) && <React.Fragment>
            <div className="col-12 col-sm-6  col-md-4 col-xl-4 mb-2">
              <label htmlFor="">Соціальна категорія</label>
              <BootstrapTooltip title=" Введіть соціальну категорію набувача. Дозволено ведення цифр, літер українською мовою, символів Ʌ №””.’,-/">
                <input
                  required
                  value={this.props.cargoAquirer.c_category}
                  onChange={this.props.handleChangeRedux}
                  name="c_category"
                  type="text"
                  maxLength="250"
                />
              </BootstrapTooltip>
            </div>



            <div className="col-12 col-sm-6  col-md-4 col-xl-4 mb-2">
              <label htmlFor="">Телефон набувача</label>
              <BootstrapTooltip title=" Введіть телефон набувача відповідно до формату вказаного у полі нижче. ">
                <InputMask
                  mask="+38(999)999-99-99"
                  placeholder="+38(XXX)XXX-XX-XX"
                  maskChar={null}
                  type="text"
                  value={this.props.cargoAquirer.n_phone}
                  onChange={this.props.handleChangeRedux}
                  name="n_phone"
                />
              </BootstrapTooltip>
            </div>
          </React.Fragment>
          } */}
          <div className="w-100" />
          {(parseInt(this.props.cargoAquirer.i_acquirertype) === 1 ||
            parseInt(this.props.cargoAquirer.i_acquirertype) === 2) && (
            <>
              <div className="col-12 col-sm-6 col-md-4 col-xl-4 mb-2">
                {/* <label htmlFor="">Область</label> */}
                <BootstrapTooltip title=" Оберіть область зі списку." placement="top-start">
                  <div>
                    {/* <Select
                      value={this.props.catalog.region.filter(
                        ({ value }) => value === this.props.cargoAquirer.i_region
                      )}
                      onChange={this.props.handleSelectChange('i_region')}
                      options={this.props.catalog.region}
                      styles={customStyles}
                      // isLoading={this.props.loading.CATALOG}
                      placeholder=""
                      // isMulti
                      noOptionsMessage={() => 'помилка серверу'}
                      menuPortalTarget={document.body}
                      theme={customTheme}

                      // className={submitted && !this.props.recipient.p_region ? 'error' : ''}
                    /> */}
                    <Autocomplete
                      value={
                        this.props.catalog.region.filter(({ value }) => value === this.props.cargoAquirer.i_region)[0]
                      }
                      onChange={this.props.handleSelectChange('i_region')}
                      options={this.props.catalog.region}
                      autoHighlight
                      getOptionSelected={(option) => option.value === this.props.cargoAquirer.i_region}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => <TextField {...params} label="Область" />}
                    />
                  </div>
                </BootstrapTooltip>
                {/* {submitted && !this.props.recipient.p_region && (
                      <FormHelperText error id="name-error-text">
                        Поле обов'язкове
                      </FormHelperText>
                    )} */}
              </div>

              <div className="col-12 col-sm-6 col-md-4 col-xl-4 mb-2">
                {/* <label htmlFor="">Населений пункт</label> */}
                <BootstrapTooltip title=" Оберіть населений пункт зі списку." placement="top-start">
                  <div>
                    {/* <Select
                      value={this.props.catalog.locality.filter(
                        ({ value }) => value === this.props.cargoAquirer.i_locality
                      )}
                      onChange={this.props.handleSelectChange('i_locality')}
                      options={this.props.catalog.locality}
                      styles={customStyles}
                      isSearchable
                      getOptionLabel={({ label, district }) => (
                        <div>
                          <span>{label}</span> <span style={{ color: '#999' }}>{district}</span>
                        </div>
                      )}
                      placeholder=""
                      // isLoading={this.props.loading.LOCALITY}
                      // isMulti
                      noOptionsMessage={() => 'помилка серверу'}
                      menuPortalTarget={document.body}
                      theme={customTheme}
                      filterOption={this.filterOptions}
                      // className={submitted && !this.props.recipient.p_koatuu ? 'error' : ''}
                    /> */}
                    <Autocomplete
                      value={
                        this.props.catalog.locality.filter(
                          ({ value }) => value === this.props.cargoAquirer.i_locality
                        )[0]
                      }
                      onChange={this.props.handleSelectChange('i_locality')}
                      options={this.props.catalog.locality}
                      autoHighlight
                      getOptionSelected={(option) => option.value === this.props.cargoAquirer.i_locality}
                      getOptionLabel={(option) => option.label}
                      options={this.props.catalog.locality}
                      renderInput={(params) => <TextField {...params} label="Населений пункт" />}
                      filterOption={this.filterOptions}
                      renderOption={({ label, district }) => (
                        <Grid container direction="column">
                          <span style={{ fontSize: '14px' }}>{label}</span>
                          <span style={{ color: '#999', fontSize: '12px' }}>{district}</span>
                        </Grid>
                      )}
                    />
                  </div>
                </BootstrapTooltip>
                {/* {submitted && !this.props.recipient.p_koatuu && (
                      <FormHelperText error id="name-error-text">
                        Поле обов'язкове
                      </FormHelperText>
                    )} */}
              </div>

              {parseInt(this.props.cargoAquirer.i_acquirertype) === 1 && (
                <div className="col-12 col-sm-6  col-md-4 col-xl-4 mb-2">
                  {/* <label htmlFor="p_adress">Місцезнаходження набувача</label> */}
                  <BootstrapTooltip title=" Введіть місцезнаходження набувача. Дозволено ведення цифр, літер українською мовою та символів Ʌ№””.’,-/">
                    <TextField
                      label="Місцезнаходження набувача"
                      required
                      value={this.props.cargoAquirer.c_adress}
                      onChange={this.props.handleChangeRedux}
                      name="c_adress"
                      type="text"
                      // maxLength="128"
                      inputProps={{
                        maxLength: 128,
                      }}
                      // className={submitted && !this.props.recipient.p_adress ? 'error' : ''}
                    />
                  </BootstrapTooltip>
                  {/* {submitted && !this.props.recipient.p_adress && (
                      <FormHelperText error id="name-error-text">
                        Поле обов'язкове
                      </FormHelperText>
                    )} */}
                </div>
              )}
            </>
          )}

          {parseInt(this.props.cargoAquirer.i_acquirertype) === 2 && (
            <div className="col-12 col-sm-6  col-md-4 col-xl-4 mb-2">
              {/* <label htmlFor="">Кількість набувачів</label> */}
              <BootstrapTooltip title=" Введіть заплановану кількість набувачів.">
                {/* <NumberFormat
                  required
                  value={this.props.cargoAquirer.n_acqcnt}
                  onValueChange={(values) => {
                    const { formattedValue } = values;
                    this.props.handleChangeNumberFormat('n_acqcnt', formattedValue);
                  }}
                  decimalSeparator="."
                  decimalScale={0}
                  // name="n_acqcnt"
                  // type="text"
                  maxLength="50"
                  // className={this.props.errorText ? 'error' : ''}
                /> */}
                <TextField
                  label="Кількість набувачів"
                  value={this.props.cargoAquirer.n_acqcnt}
                  onChange={(e) => this.props.handleChangeNumberFormat('n_acqcnt', e.target.value)}
                  name="n_acqcnt"
                  // inputProps={{ maxLength: 12 }}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    inputProps: {
                      decimalSeparator: '.',
                      decimalScale: 0,
                      maxLength: 50,
                    },
                  }}
                />
              </BootstrapTooltip>
            </div>
          )}

          {!isNaN(parseInt(this.props.cargoAquirer.i_acquirertype)) && (
            <>
              <div className={classes.rowBottomLine}>
                <div className="col-12 col-sm-6  col-md-4 col-xl-4 mb-2">
                  {/* <label htmlFor="p_adress">Кількість допомоги</label> */}
                  <BootstrapTooltip title=" Введіть заплановану кількість допомоги для даного набувача. Дозволено введення цифр та символу .">
                    {/* <NumberFormat
                      required
                      value={this.props.cargoAquirer.n_countplan}
                      onValueChange={(values) => {
                        const { formattedValue } = values;
                        this.props.handleChangeNumberFormat('n_countplan', formattedValue);
                      }}
                      decimalSeparator="."
                      decimalScale={2}
                      // name="n_countplan"
                      // type="text"
                      maxLength="250"
                      // className={this.props.errorText ? 'error' : ''}
                    /> */}
                    <TextField
                      label="ількість допомоги"
                      value={this.props.cargoAquirer.n_countplan}
                      onChange={(e) => this.props.handleChangeNumberFormat('n_countplan', e.target.value)}
                      name="n_countplan"
                      // inputProps={{ maxLength: 12 }}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        inputProps: {
                          decimalSeparator: '.',
                          decimalScale: 2,
                          maxLength: 250,
                        },
                      }}
                    />
                  </BootstrapTooltip>
                  {/* {submitted && !this.props.recipient.p_adress && (
                    <FormHelperText error id="name-error-text">
                      Поле обов'язкове
                    </FormHelperText>
                  )} */}
                </div>
                <div className="col-12 col-sm-6  col-md-4 col-xl-4 mb-2">
                  {/* <label htmlFor="c_reason">Обґрунтування</label> */}
                  <BootstrapTooltip title=" Введіть обставини, що підтверджують необхідність отримання ГД набувачем. Дозволено ведення цифр, літер українською мовою та символів Ʌ№””.’,-/">
                    <TextField
                      label="Обґрунтування"
                      required
                      value={this.props.cargoAquirer.c_reason}
                      onChange={this.props.handleChangeRedux}
                      name="c_reason"
                      type="text"
                      // maxLength="500"
                      inputProps={{
                        maxLength: 500,
                      }}
                    />
                  </BootstrapTooltip>
                </div>
                <div className="col-12 col-sm-6  col-md-4 col-xl-4 mb-2">
                  {/* <label htmlFor="c_reason">Вага допомоги, кг</label> */}
                  <BootstrapTooltip title=" Введіть вагу допомоги.">
                    {/* <NumberFormat
                      value={this.props.cargoAquirer.p_weight}
                      onValueChange={(values) => {
                        const { formattedValue } = values;
                        this.props.handleChangeNumberFormat('p_weight', formattedValue);
                      }}
                      decimalSeparator="."
                      decimalScale={2}
                      // name="p_weight"
                      // type="text"
                      maxLength="10"
                    /> */}
                    <TextField
                      label="Вага допомоги, кг"
                      value={this.props.cargoAquirer.p_weight}
                      onChange={(e) => this.props.handleChangeNumberFormat('p_weight', e.target.value)}
                      name="p_weight"
                      // inputProps={{ maxLength: 12 }}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        inputProps: {
                          decimalSeparator: '.',
                          decimalScale: 2,
                          maxLength: 10,
                        },
                      }}
                    />
                  </BootstrapTooltip>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    );
  }
}

AqcuirerInputs.proptTypes = {
  catalog: PropTypes.object.isRequired,
  cargo: PropTypes.object.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleChangeRedux: PropTypes.func.isRequired,
};

export default withStyles(style)(AqcuirerInputs);

import React from 'react';
import { NavLink } from 'react-router-dom';
import telegram from '../../../src/assets/images/telegram.png';
import facebook from '../../../src/assets/images/facebook.png';
import flag from '../../../src/assets/images/ASRGD_Logo.png';
import { AppBar, Toolbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import './InfoUrPerson.css';

const ErrorFizicalPerson = () => {
  return (
    <div className="page">
      <Grid item>
        <Grid container>
          <AppBar position="sticky" elevation={0} style={{ backgroundColor: 'transparent' }}>
            <Toolbar>
              {/* <Container> */}
              <Button href="https://www.msp.gov.ua">
                <img src={flag} alt="buttonGovUa" width='210px' style={{ margin: '25px auto', display: 'block', objectFit: 'cover' }} />
              </Button>
            </Toolbar>
          </AppBar>
        </Grid>
      </Grid>

      <div className="questionContent">
        <h2 className="title_error">
          Автоматизована система реєстрації гуманітарної допомоги доступна тільки з сертифікатом, виданим на юридичну
          особу, і якщо Ви є керівником або уповноваженим представником юридичної особи.
        </h2>
        <div className="buttonBlocks">
          <NavLink className="button_save" to="/">
            Авторизуватися
          </NavLink>
          {/* <NavLink className="button_save" to={'/error-page'}>
       Ні
     </NavLink> */}
        </div>
      </div>
      <div className='footer1'>
    <div className='footerContact'>
    <p className='footerContactText'>© 2023 Мінсоцполітики</p>
            <p className='footerContactText'>
              Розробник:<a target='blank' href="https://www.ioc.gov.ua"> ДП ІОЦ Мінсоцполітики  </a></p>
              <p className='footerContactText'>(044) 494-19-10</p>
              <p className='footerContactText'>callcenter@ioc.gov.ua</p>
    </div>
 
            <div className='socialIcons'>
              <Typography style={{fontSize: '15px'}}>Слідкуй за нами тут:</Typography>
              <a href="https://m.facebook.com/MLSP.gov.ua">
                {' '}
                <img className="icon" src={facebook} />{' '}
              </a>
              <a href="https://t.me/MinSocUA">
                {' '}
                <img className="icon" src={telegram} />{' '}
              </a>
            </div>
  </div>
      </div>
  );
};

export default ErrorFizicalPerson;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { Grid, CardContent, Card, IconButton, InputAdornment } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DatePicker from 'react-datepicker';
import uk from 'date-fns/locale/uk';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { Autocomplete } from '@material-ui/lab';
import { getMyCargoAction, setCargoSearch } from '../../../redux/actions/cargo.action';

const DeliveryPanel = ({ setSearch, handleSearch, clearPages }) => {
  const {
    auth: {
      user: { id: userId },
    },
    catalogs: { applicationState },
    cargo: { cargoSearch },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  // code: '', startdate: null, enddate: null, status: null

  const handleChange = (event) => {
    const { value } = event.target;
    setSearch('code', value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const localHandleSearch = (params) => {
    clearPages();
    dispatch(getMyCargoAction(params));
  };

  const handleClear = async () => {
    await setSearch('code', '');
    const { search } = cargoSearch;
    const { code, startdate, enddate, status } = search;
    localHandleSearch({
      id: userId,
      p_cargo: null,
      p_reg_date: startdate || null,
      p_passed_date: enddate || null,
      p_state: status?.value === 0 ? 0 : status?.value || null,
    });
  };

  const dateToObject = (strDate) => {
    if (strDate) {
      const [day, month, year] = strDate.split('.');
      return new Date(+year, +month - 1, +day);
    }
    return null;
  };

  const handleChangeDate1 = async (dateObj) => {
    const newDate = dateObj ? format(dateObj, 'dd.MM.yyyy') : '';
    await setSearch('startdate', newDate);
    const { search } = cargoSearch;
    const { code, startdate, enddate, status } = search;
    localHandleSearch({
      id: userId,
      p_cargo: code || null,
      p_reg_date: dateObj ? format(dateObj, 'dd.MM.yyyy') : null,
      p_passed_date: enddate || null,
      p_state: status?.value === 0 ? 0 : status?.value || null,
    });
  };

  const handleChangeDate2 = async (dateObj) => {
    const newDate = dateObj ? format(dateObj, 'dd.MM.yyyy') : '';
    await setSearch('enddate', newDate);
    const { search } = cargoSearch;
    const { code, startdate, enddate, status } = search;
    localHandleSearch({
      id: userId,
      p_cargo: code || null,
      p_reg_date: startdate || null,
      p_passed_date: dateObj ? format(dateObj, 'dd.MM.yyyy') : null,
      p_state: status?.value === 0 ? 0 : status?.value || null,
    });
  };

  const handleSelect = async (option) => {
    await setSearch('status', option);
    const { search } = cargoSearch;
    const { code, startdate, enddate, status } = search;
    localHandleSearch({
      id: userId,
      p_cargo: code || null,
      p_reg_date: startdate || null,
      p_passed_date: enddate || null,
      p_state: option?.value === 0 ? 0 : option?.value || null,
    });
  };

  const { search } = cargoSearch;
  const { code, startdate, enddate, status } = search;

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6} lg={3}>
              <TextField
                label="Код вантажу:"
                value={code}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                // name="p_orgname"
                type="text"
                // maxLength="250"
                // autocomplete="off"
                inputProps={{
                  maxLength: 20,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {code && (
                        <IconButton
                          aria-label="clear button"
                          size="small"
                          color="primary"
                          onClick={handleClear}
                          edge="end"
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={3}>
              <DatePicker
                selected={dateToObject(startdate)}
                onChange={handleChangeDate1}
                dateFormat="dd.MM.yyyy"
                // maxDate={this.state.date_till}
                // selectsStart
                // startDate={this.state.date_from}
                // endDate={this.state.date_till}
                locale={uk}
                className="datePicker"
                icon={<i className="cal-icon" />}
                customInput={<TextField label="Дата реєстрації:" />}
                isClearable
              />
            </Grid>
            <Grid item sm={12} md={6} lg={3}>
              <DatePicker
                selected={dateToObject(enddate)}
                onChange={handleChangeDate2}
                dateFormat="dd.MM.yyyy"
                // maxDate={this.state.date_till}
                // selectsEnd
                // startDate={this.state.date_from}
                // endDate={this.state.date_till}
                locale={uk}
                className="datePicker"
                icon={<i className="cal-icon" />}
                customInput={<TextField label="Дата митного оформлення:" />}
                isClearable
              />
            </Grid>
            <Grid item sm={12} md={6} lg={3}>
              <Autocomplete
                // eslint-disable-next-line radix
                value={applicationState.filter(({ value }) => parseInt(value) === parseInt(status?.value))[0]}
                onChange={(event, option) => handleSelect(option)}
                autoHighlight
                getOptionLabel={(option) => option.label}
                options={applicationState}
                renderInput={(params) => (
                  <TextField
                    InputLabelProps={{ style: { fontSize: '12px' } }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    label="Статус"
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default DeliveryPanel;

import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import * as Feather from 'react-feather';
import { Card, CardHeader, CardBody, Button, CardFooter } from 'reactstrap';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import BootstrapTooltip from '../../../elements/MaterialBootstrap';

import OrderUploadForm from './OrderUploadForm';

const OrderUpload = ({ history }) => {
  const {
    commissionCreate: {
      currentCommission: { id: meetingId },
    },
    auth: {
      user: { id: userId },
    },
  } = useSelector((state) => state);

  return (
    <>
      <Card>
        <CardBody>
          <OrderUploadForm catalog={{ documentTypes2: [], orderTypes: [] }} id={meetingId} userId={userId} />
        </CardBody>

        <CardFooter>
          <BootstrapTooltip title="вихід">
            <IconButton onClick={(e) => history.goBack()}>
              <Feather.LogOut style={{ transform: 'scaleX(-1)' }} size="25" />
            </IconButton>
          </BootstrapTooltip>
        </CardFooter>
      </Card>
    </>
  );
};

export default withRouter(OrderUpload);

/* eslint-disable radix */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, CardContent, Card, CardActions, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import uk from 'date-fns/locale/uk';
import format from 'date-fns/format';
import { IconButton, InputAdornment } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { competencyRequests } from '../../../api/api';

const useStyles = makeStyles((theme) => ({
  button: {
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
  },
}));

const CompetencyPanel = ({ setTable, setNewSubmit, pn_page, pn_rows }) => {
  const [currentDate, setCurrentDate] = React.useState();
  const [type, setType] = React.useState();
  const [status, setStatus] = React.useState();
  const [organ, setOrgan] = React.useState('');
  const [edrpou, setEdrpou] = React.useState('');

  const {
    catalogs: { requestType, requestStatus },
  } = useSelector((state) => state);

  const classes = useStyles();

  const dateToObject = (strDate) => {
    if (strDate) {
      const [day, month, year] = strDate.split('.');
      return new Date(+year, +month - 1, +day);
    }

    return null;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'organisation':
        setOrgan(value);
        break;
      case 'edrpou':
        setEdrpou(value);
        break;
      default:
        break;
    }
  };

  // datapicker
  const handleChangeDate = (dateObj) => {
    setCurrentDate(dateObj);
  };

  const handleSelect = (name, option) => {
    if (name === 'type') {
      setType(option);
    } else if (name === 'status') {
      setStatus(option);
    }
  };

  const handleSearch = async () => {
    const params = {
      pd_create: currentDate ? format(currentDate, 'dd.MM.yyyy') : null,
      pi_type: type ? type?.value : null,
      pc_orgname: organ || null,
      pn_edrpou: edrpou || null,
      pi_state: status ? status?.value : null,
      pn_page,
      pn_rows,
    };

    const { data } = await competencyRequests(params);
    setTable(data);
    setNewSubmit(data.pl_submit === 1);
  };

  useEffect(() => {
    handleSearch();
  }, [pn_page, pn_rows]);

  // React.useEffect(() => {
  //   handleSearch();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6} lg={2}>
              <DatePicker
                selected={currentDate}
                onChange={handleChangeDate}
                dateFormat="dd.MM.yyyy"
                // maxDate={this.state.date_till}
                // minDate={minDate}
                locale={uk}
                className="datePicker"
                // icon={<i className="cal-icon" />}
                customInput={
                  <TextField
                    label="Дата запиту:"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {currentDate && (
                            <IconButton
                              aria-label="clear button"
                              size="small"
                              color="primary"
                              onClick={() => setCurrentDate('')}
                              edge="end"
                              style={{ outline: 'none' }}
                            >
                              <ClearIcon style={{ color: 'grey' }} />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                }
              />
            </Grid>
            <Grid item sm={12} md={6} lg={2} xs={12}>
              <Autocomplete
                value={requestType.filter(({ value }) => parseInt(value) === parseInt(type?.value))[0]}
                onChange={(event, option) => handleSelect('type', option)}
                autoHighlight
                getOptionLabel={(option) => option.label}
                options={requestType}
                renderInput={(params) => (
                  <TextField
                    InputLabelProps={{ style: { fontSize: '12px' } }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    label="Тип запиту"
                  />
                )}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={3}>
              <TextField
                value={organ}
                onChange={handleChange}
                name="organisation"
                type="text"
                label="Організація"
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {organ && (
                        <IconButton
                          aria-label="clear button"
                          size="small"
                          color="primary"
                          onClick={() => setOrgan('')}
                          edge="end"
                          style={{ outline: 'none' }}
                        >
                          <ClearIcon style={{ color: 'grey' }} />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={2}>
              <TextField
                value={edrpou}
                onChange={handleChange}
                name="edrpou"
                type="text"
                label="Код ЄДРПОУ"
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {edrpou && (
                        <IconButton
                          aria-label="clear button"
                          size="small"
                          color="primary"
                          onClick={() => setEdrpou('')}
                          edge="end"
                          style={{ outline: 'none' }}
                        >
                          <ClearIcon style={{ color: 'grey' }} />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={2} xs={12}>
              <Autocomplete
                // eslint-disable-next-line radix
                value={requestStatus.filter(({ value }) => parseInt(value) === parseInt(status?.value))[0]}
                onChange={(event, option) => handleSelect('status', option)}
                autoHighlight
                getOptionLabel={(option) => option.label}
                options={requestStatus}
                renderInput={(params) => (
                  <TextField
                    InputLabelProps={{ style: { fontSize: '12px' } }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    label="Стан"
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            type="button"
            variant="contained"
            color="primary"
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
            className={classes.button}
            onClick={handleSearch}
          >
            <i className="fa fa-search mr-1" /> Пошук
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default CompetencyPanel;

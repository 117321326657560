import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import './RegisterJson.css';
import { getRecipientsForJSON } from '../../../../api/api';

const RegisterJson = () => {
  const [loading, setLoading] = useState(false);

  const writeJsonFile = (data, fileName) => {
    const jsonStr = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonStr], { type: 'application/json' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const exportJasonFile = () => {
    setLoading(true);
    getRecipientsForJSON()
      .then((response) => {
        setLoading(false);
        writeJsonFile(response.data, 'recipients.json');
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error fetching recipients:', error);
      });
  };

  return (
    <Button
      type="button"
      variant="contained"
      color="primary"
      className="register-json-button"
      onClick={exportJasonFile}
      disabled={loading}
    >
      {loading ? 'Загрузка...' : 'Вивантажити в json'}
    </Button>
  );
};

export default RegisterJson;

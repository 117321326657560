import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import ReactTable from 'react-table';
import { Card, CardContent, IconButton, TextareaAutosize } from '@material-ui/core';
import { Visibility, Done } from '@material-ui/icons';
import Select from 'react-select';
import Swal from 'sweetalert2';
import BootstrapTooltip from '../../elements/MaterialBootstrap';
import { setCheckResult, getRecipientOldNew, getCargoOldNew } from '../../../api/api';
import { fetchRows, setCheckRows, setRowComment, setRowAct } from '../../../redux/actions/check.action';
import { recipientNew, recipientOld } from '../../../redux/actions/recipient.action';
import { cargoNew, cargoOld } from '../../../redux/actions/cargo.action';

import { customStyles } from '../../../customStyles/reactSelect';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const options = [
  { value: '1', label: 'Прийняти та передати на розгляд' },
  { value: '2', label: 'Повернути без розгляду' },
];

const ReceivedApplicationsForCheck = ({
  recipientNew,
  recipientOld,
  cargoOld,
  history,
  fetchRows,
  setCheckRows,
  setRowComment,
  setRowAct,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    auth: {
      user: { id: userId },
    },
    check: { rows },
  } = useSelector((state) => state);

  const getData = async () => {
    await fetchRows();
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setData([...rows]);
  }, [rows]);

  // const data = useMemo(() => {
  //   return rows ? [...rows] : [];
  // }, [rows]);

  const handleSelect = (index) => (opt) => {
    // changing by action
    setRowAct(index, opt);
  };

  const StartCellSelect = (row) => {
    const { index } = row.original;
    const act = rows.length > 0 ? rows[index]?.act : null;
    return (
      <div>
        <Select
          placeholder="Оберіть дію"
          styles={customStyles}
          options={options}
          value={act}
          onChange={handleSelect(index)}
        />
      </div>
    );
  };

  const StartCellInput = (row) => {
    const { comment } = row.original;
    const [value, setValue] = useState(comment);

    useEffect(() => {
      setValue(comment);
    }, [comment]);

    const onChange = (event) => {
      setValue(event.target.value);
    };

    const onBlur = (index) => (event) => {
      event.preventDefault();
      setTimeout(() => {
        setRowComment(index, value);
      }, 100);
    };

    return (
      <textarea
        rows="3"
        style={{ maxWidth: '90%' }}
        value={value}
        onChange={onChange}
        onBlur={onBlur(row.original.index)}
      />
    );
  };

  const actionForCheckResult = (appId, rez, comment) => (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!rez) {
      swalWithBootstrapButtons.fire({
        title: 'Оберіть дію зі списку',
      });
    } else {
      swalWithBootstrapButtons
        .fire({
          text: 'Ви дійсно бажаєте зберегти заяву',
          showCancelButton: true,
          confirmButtonText: 'Так, зберегти заяву',
          cancelButtonText: 'Відміна',
        })
        .then((result) => {
          if (result.value) {
            setLoading(true);
            setCheckResult({
              p_application: appId,
              p_rez: rez,
              p_user_id: userId,
              p_comment: comment,
            })
              .then(() => {
                setLoading(false);
                getData();
              })
              .catch((err) => {
                setLoading(false);
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const StartCheckFunction = (row) => {
    const { apl_id, act, comment } = row.original;
    const rez = act ? act.value : null;
    return (
      <div>
        <BootstrapTooltip title="Зберегти">
          <IconButton aria-label="done" onClick={actionForCheckResult(apl_id, rez, comment)}>
            <Done fontSize="small" color="primary" />
          </IconButton>
        </BootstrapTooltip>
      </div>
    );
  };

  const detailedView = async (id) => {
    try {
      const [response, success] = await axios.all([getRecipientOldNew(id), getCargoOldNew(id)]);
      recipientNew(response.data.new);
      recipientOld(response.data.old);
      cargoOld(success.data);

      setTimeout(() => {
        history.push(`/detail-info-view`);
      }, 100);
    } catch (error) {
      swalWithBootstrapButtons.fire({
        title: error.response.data,
        type: 'error',
      });
    }
  };

  return (
    <div>
      <Card>
        <CardContent>
          <ReactTable
            style={{ fontSize: '0.85rem', textAlign: 'center', overflowWrap: 'break-word' }}
            data={data}
            columns={[
              {
                Header: (
                  <div>
                    Дата подання
                    <br />
                    заяви
                  </div>
                ),
                accessor: 'apl_date',
                style: { whiteSpace: 'unset', textAlign: 'left' },
              },
              {
                Header: <div>№ в реєстрі</div>,
                accessor: 'recipient_numb',
                style: { whiteSpace: 'unset', textAlign: 'left' },
              },
              {
                Header: (
                  <div>
                    Тип
                    <br />
                    заяви
                  </div>
                ),
                style: { whiteSpace: 'unset', textAlign: 'left' },
                accessor: 'applicationtype',
              },
              {
                Header: (
                  <div>
                    Назва
                    <br />
                    отримувача
                  </div>
                ),
                accessor: 'c_orgname',
                style: { whiteSpace: 'unset', textAlign: 'left' },
              },
              {
                Header: (
                  <div>
                    Код
                    <br />
                    ЄДРПОУ
                  </div>
                ),
                accessor: 'n_edrpou',
                style: { whiteSpace: 'unset', textAlign: 'left' },
              },
              {
                Header: (
                  <div>
                    Адреса
                    <br />
                    отримувача
                  </div>
                ),
                accessor: 'adr',
                style: { whiteSpace: 'unset', textAlign: 'left' },
              },
              {
                Header: (
                  <div>
                    Детальний
                    <br />
                    перегляд
                  </div>
                ),
                Cell: (row) => (
                  <div>
                    <BootstrapTooltip title="Детальний перегляд">
                      <IconButton
                        aria-label="visibility"
                        onClick={() => {
                          detailedView(Number(row.original.apl_id));
                        }}
                      >
                        <Visibility fontSize="small" color="primary" />
                      </IconButton>
                    </BootstrapTooltip>
                  </div>
                ),
                style: { whiteSpace: 'unset', textAlign: 'left' },
              },
              {
                Header: (
                  <div>
                    Прийняти /
                    <br />
                    Повернути
                  </div>
                ),
                Cell: StartCellSelect,
                style: { whiteSpace: 'unset', textAlign: 'left' },
              },
              {
                Header: <div>Коментар</div>,
                Cell: StartCellInput,
                style: { whiteSpace: 'unset', textAlign: 'left' },
              },
              {
                Header: <div>Функції</div>,
                Cell: StartCheckFunction,
                style: { whiteSpace: 'unset', textAlign: 'left' },
                sortable: false,
                filterable: false,
              },
            ]}
            defaultPageSize={5}
            showPaginationTop
            showPaginationBottom={false}
            noDataText="Інформація відсутня!"
            className="-striped -highlight"
            previousText="Попередня"
            nextText="Наступна"
            pageText="Сторінка"
            ofText="з"
            rowsText="рядків"
            loading={loading}
            loadingText="Збереження даних..."
          />
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    // meeting: state.commissionCreate.meeting,
    user: state.auth.user,
    // id: state.commissionCreate.id,
    decisonType: state.catalogs.decisonType,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    recipientNew,
    recipientOld,
    cargoNew,
    cargoOld,
    fetchRows,
    setCheckRows,
    setRowComment,
    setRowAct,
  })(ReceivedApplicationsForCheck)
);

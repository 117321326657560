import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import uk from 'date-fns/locale/uk';
import DatePicker from 'react-datepicker';
import format from 'date-fns/format';

const ModalContent = ({ data, modalType, id, onClose, getViewData }) => {
  const [currentDate, setCurrentDate] = React.useState();
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [valueText, setValue] = useState('');
  const [docName, setDocName] = useState('');
  const [options, setOptions] = useState([]);
  const [successRejected, setSuccessRejected] = useState(false);

  const handleAutocompleteChange = (event, value) => {
    setSelectedValue(value);
  };
  const handleTextFieldChange = (event) => {
    const { value } = event.target;
    setValue(value);
  };

  const handleDocChange = (event) => {
    const { value } = event.target;
    setDocName(value);
  };

  const handleChangeDate = (dateObj) => {
    setCurrentDate(dateObj);
  };

  const fetchData = async () => {
    try {
      setLoading(true); // Set loading to true before making the request
      const response = await axios.get(`/aid/cancelReason`);
      // Handle the response data as needed
      setOptions(response.data);
    } catch (error) {
      // Handle errors
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Set loading to false after the request completes (whether successful or not)
    }
  };

  const setData = async (action) => {
    setSuccessRejected(false);
    const actionType = {
      pl_take_on: 1,
      pl_register: 2,
      pl_reject: 3,
      pl_unlock: 4,
    };
    try {
      setLoading(true); // Set loading to true before making the request
      await axios.post(`/aid/setPackageAction`, {
        pi_act: id,
        pi_action: actionType[action],
        pi_reject_reason: selectedValue ? selectedValue.value : null,
        pc_reject: valueText.trim().length === 0 ? null : valueText,
        pd_order: currentDate ? format(currentDate, 'dd.MM.yyyy') : null,
        pc_order_number: docName.trim().length === 0 ? null : docName,
      });
      // Handle the response data as needed
      if (action === 'pl_reject') {
        setSuccessRejected(true);
      } else {
        onClose();
      }

      getViewData(id);
    } catch (error) {
      // Handle errors
      console.error('Error fetching data:', error);
      setSuccessRejected(false);
    } finally {
      setLoading(false); // Set loading to false after the request completes (whether successful or not)
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (modalType === 'pl_take_on') {
    return (
      <Grid container justifyContent="center" alignItems="center" spacing={4}>
        <Grid container item justifyContent="center" alignItems="center" spacing={4} direction="column">
          <Typography variant="subtitle1" id="transition-modal-title">
            Взято на розгляд!
          </Typography>
          <Typography variant="subtitle2" id="transition-modal-description" align="center">
            Залишилось {data.main.length > 0 && data.main[0]?.days_left} днів на затвердження документу
          </Typography>
        </Grid>

        <Grid container item justifyContent="center" alignItems="flex-end" spacing={4}>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={onClose}>
              ВІДМІНИТИ
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => setData(modalType)}>
              ВСЕ ВІРНО
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (modalType === 'pl_register') {
    return (
      <Grid container justifyContent="center" alignItems="center" spacing={4}>
        <Grid container item justifyContent="center" alignItems="center" spacing={4} direction="column">
          <Typography variant="subtitle1" id="transition-modal-title">
            Зареєстровано!
          </Typography>
          <Typography variant="subtitle2" id="transition-modal-description" align="center">
            Повідомлення про реєстрацію ТЗ будуть направлені на пошту Військовому формуванню, Набувачу та Отримувачу.
          </Typography>
        </Grid>

        <Grid container item justifyContent="center" alignItems="flex-end" spacing={4}>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={onClose}>
              ВІДМІНИТИ
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => setData(modalType)}>
              ВСЕ ВІРНО
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (modalType === 'pl_reject') {
    if (successRejected) {
      return (
        <Grid container justifyContent="center" alignItems="center" spacing={4}>
          <Grid container item justifyContent="center" alignItems="center" spacing={4} direction="column">
            <Typography variant="subtitle1" id="transition-modal-title">
              Відхилено!
            </Typography>
            <Typography variant="subtitle2" id="transition-modal-description" align="center">
              Повідомлення про рішення будуть направлені на пошту Військовому формуванню, Набувачу та Отримувачу.
            </Typography>
          </Grid>

          <Grid container item justifyContent="center" alignItems="flex-end" spacing={4}>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={onClose}>
                ЗРОЗУМІЛО
              </Button>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container justifyContent="center" alignItems="center" spacing={4}>
        <Grid container item justifyContent="center" alignItems="center" spacing={4} direction="column">
          <Typography variant="subtitle1" id="transition-modal-title">
            Відхилено!
          </Typography>
          <Typography variant="subtitle2" id="transition-modal-description" align="center">
            Повідомте причини за якими рішення відхилено:
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            loadingText="Завантаження..."
            disablePortal
            fullWidth
            size="small"
            value={selectedValue}
            onChange={handleAutocompleteChange}
            autoHighlight
            getOptionLabel={(option) => option.label}
            options={options}
            loading={loading}
            renderOption={({ label }) => <span style={{ fontSize: '14px' }}>{label}</span>}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                InputLabelProps={{
                  ...params.InputProps,
                  style: { fontSize: '12px' },
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                label="Обрати зі списку"
              />
            )}
          />
        </Grid>

        {selectedValue?.value === 6 && (
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{ style: { fontSize: '12px' } }}
              value={valueText}
              onChange={handleTextFieldChange}
              name="c_description"
              type="text"
              id="pc_filter"
              label="Вказати причину"
              fullWidth
              inputProps={{
                maxLength: 250,
              }}
            />
          </Grid>
        )}

        <Grid container item justifyContent="center" alignItems="flex-end" spacing={4}>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={onClose}>
              ВІДМІНИТИ
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => setData(modalType)}>
              ВСЕ ВІРНО
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (modalType === 'pl_unlock') {
    return (
      <Grid container justifyContent="center" alignItems="center" spacing={4}>
        <Grid container item justifyContent="center" alignItems="center" spacing={4} direction="column">
          <Typography variant="subtitle2" id="transition-modal-description" align="center">
            Щоб продовжити термін відомчої реєстрації введіть номер і дату документу
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            InputLabelProps={{ style: { fontSize: '12px' } }}
            value={docName}
            onChange={handleDocChange}
            name="c_description"
            type="text"
            id="pc_filter"
            label="Номер документу"
            fullWidth
            inputProps={{
              maxLength: 250,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <DatePicker
            selected={currentDate}
            onChange={handleChangeDate}
            dateFormat="dd.MM.yyyy"
            // maxDate={this.state.date_till}
            // minDate={minDate}
            locale={uk}
            className="datePicker"
            // icon={<i className="cal-icon" />}
            isClearable
            customInput={<TextField label="Дата запиту:" fullWidth />}
          />
        </Grid>

        <Grid container item justifyContent="center" alignItems="flex-end" spacing={4}>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={onClose}>
              ВІДМІНИТИ
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => setData(modalType)}>
              ВСЕ ВІРНО
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return null;
};

export default ModalContent;

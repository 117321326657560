import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, CardContent, Card, CardActions, Button, TextField, Typography, Icon } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Swal from 'sweetalert2';
import FormHelperText from '@material-ui/core/FormHelperText';
import clsx from 'clsx';
import { ArrowDownwardOutlined, Check } from '@material-ui/icons';
import rules from '../../../vibe/components/utilities/MainUtils/rules';
import vector from '../../../assets/images/Vector.png';
import save from '../../../assets/images/save.png';
import {
  editRequest,
  getDocRequest,
  saveDocRequest,
  getRecipientId,
  deleteRecipientDocumentsApi,
  saveRequest,
} from '../../../api/api';

import useStyles from './uploadStyles';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
});

const CompetencyRequest = (props) => {
  const classes = useStyles();
  const { disableChanges } = props;
  const {
    catalogs: { requestType, recipientTypes },
  } = useSelector((state) => state);

  const [state, setState] = React.useState({
    // start
    recipientId: '',
    requestId: '',
    name: '',
    sri: '',
    orgname: '',
    edrpou: '',
    edrpouError: false,
    type: null,
    // new
    pc_email: '',
    pc_org_cod_edrpou: '',
    pc_org_cod_pod: '',
    pn_org_type_obj: null,
    // save
    documentTypes1: [],
    documentTypes2: [],
    docTypes: [],
    docType: null,
    docTypeText: '',
    comment: '',
    fileName: '',
    fileSize: '',
    submitted: false,
    table: [],
    loading: false,
  });
  const fileInput = React.useRef(null);

  const handleInput = (event) => {
    const { name, value } = event.target;

    if (name === 'edrpou') {
      const codeLength = `${value}`.length;
      if (codeLength === 0) {
        if (rules.edrpou.test(value)) setState({ ...state, edrpouError: false, [name]: value });
      } else if (codeLength === 8 || codeLength === 10) {
        if (rules.edrpou.test(value)) setState({ ...state, edrpouError: false, [name]: value });
      } else if (rules.edrpou.test(value) && codeLength < 11) {
        setState({ ...state, edrpouError: true, [name]: value });
      }
    } else if (name === 'pc_email') {
      // eslint-disable-next-line no-unused-expressions
      rules.email.test(value) && setState({ ...state, edrpouError: false, [name]: value });
    } else if (name === 'pc_org_cod_edrpou') {
      // eslint-disable-next-line no-unused-expressions
      rules.edrpou.test(value) && setState({ ...state, edrpouError: false, [name]: value });
    } else if (name === 'pc_org_cod_pod') {
      // eslint-disable-next-line no-unused-expressions
      rules.edrpou.test(value) && setState({ ...state, edrpouError: false, [name]: value });
    } else {
      setState({ ...state, [name]: value });
    }
  };

  const handleChangeFileInput = (e) => {
    setState({ ...state, fileName: '' });
    // eslint-disable-next-line no-unused-expressions
    e.target.files[0] &&
      setState({
        ...state,
        fileName: e.target.files[0].name,
        fileSize: e.target.files[0].size,
      });
  };

  const updateTable = async (id) => {
    try {
      const updated = await getDocRequest(id);
      setState({
        ...state,
        table: updated.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const updateRequest = async () => {
    try {
      const res = await editRequest();

      const {
        p_doc_1_cursor,
        p_doc_2_cursor,
        p_request_cursor: {
          0: {
            c_orgname,
            user_name,
            sri,
            id,
            d_create,
            i_recipient,
            i_type,
            i_state,
            i_user,
            // new
            c_email,
            c_org_cod,
            i_org_type,
          },
        },
      } = res.data;

      const updated = await getDocRequest(id);

      const codeLength = c_org_cod ? c_org_cod.toString().length : 0;

      setState({
        ...state,
        documentTypes1: [...p_doc_1_cursor],
        documentTypes2: [...p_doc_2_cursor],
        name: user_name,
        sri,
        recipientId: i_recipient,
        requestId: id,
        orgname: c_orgname || '',
        type: requestType.find((value) => Number(value) === Number(i_type)) || null,
        table: updated.data,
        // new fields
        pc_email: c_email || '',
        pc_org_cod_edrpou: codeLength === 8 ? c_org_cod : '',
        pc_org_cod_pod: codeLength === 10 ? c_org_cod : '',
        // eslint-disable-next-line radix
        pn_org_type_obj: recipientTypes.find(({ value }) => parseInt(value) === parseInt(i_org_type)) || null,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelect = (name, option) => {
    // eslint-disable-next-line no-shadow
    const { documentTypes1, documentTypes2 } = state;
    if (name === 'type' && option?.value === 1) {
      setState({ ...state, [name]: option, docTypes: [...documentTypes1], docType: null, docTypeText: '' });
    } else if (name === 'type' && option?.value === 2) {
      setState({ ...state, [name]: option, docTypes: [...documentTypes2], docType: null, docTypeText: '' });
    } else if (name === 'type' && option?.value === 3) {
      setState({ ...state, [name]: option, docTypes: [...documentTypes2], docType: null, docTypeText: '' });
    } else if (name === 'type' && !option) {
      setState({ ...state, [name]: option, docTypes: [], docType: null, docTypeText: '', orgname: '' });
    } else if (name === 'docType') {
      setState({ ...state, [name]: option });
    } else if (name === 'pn_org_type_obj') {
      setState({ ...state, [name]: option });
    }
  };

  const fetchOrgname = async () => {
    const { edrpou } = state;
    const codeLength = `${edrpou}`.length;

    try {
      if (codeLength === 8 || codeLength === 10) {
        setState({ ...state, loading: true });
        const res = await getRecipientId(edrpou);

        const { pc_orgname, pi_recipient_id } = res.data;
        setState({ ...state, loading: false, orgname: pc_orgname || '', recipientId: pi_recipient_id });
      }
    } catch (err) {
      setState({ ...state, loading: false, orgname: '' });
    }
  };

  const saveDocument = async (data) => {
    try {
      const { recipientId, requestId } = state;
      await saveDocRequest(data);
      fileInput.current.value = '';
      setState({
        ...state,
        fileName: '',
        fileSize: '',
        comment: '',
        // gg
      });
      await updateTable(requestId);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteDocument = async (id) => {
    const { requestId } = state;
    try {
      await deleteRecipientDocumentsApi(id);
      await updateTable(requestId);
    } catch (err) {
      console.log(err);
    }
  };

  const findFileExt = (filename) => {
    const res = filename.match(/\.[0-9a-z]+$/i);
    const ext = res ? res[0].slice(1) : '';
    console.log(ext);
    return ext;
  };

  const submitDocument = (e) => {
    e.preventDefault();
    setState({ ...state, submitted: true });

    if (fileInput.current.files[0]) {
      setState({ ...state, submitted: false });
      // eslint-disable-next-line no-shadow
      const { type, name } = fileInput.current.files[0];
      const fileExt = findFileExt(name);
      const { comment, recipientId, requestId, docType } = state;
      const data = new FormData();

      data.append('file', fileInput.current.files[0]);
      data.append('pi_request', requestId);
      data.append('pi_doc_type', docType?.value);
      data.append('pc_doc_name', comment);
      data.append('pc_file_ext', fileExt);

      // save document
      saveDocument(data);
    } else if (!fileInput.current.files) {
      setState({ ...state, validationText: 'Ви не додали файл' });
    }
  };

  const submitRequest = async () => {
    const {
      recipientId,
      requestId,
      type,
      orgname,
      pc_email,
      pc_org_cod_edrpou,
      pc_org_cod_pod,
      pn_org_type_obj,
    } = state;
    const { history } = props;

    // eslint-disable-next-line no-nested-ternary
    const pc_org_cod = type?.value === 2 ? pc_org_cod_edrpou : type?.value === 3 ? pc_org_cod_pod : null;

    const data = {
      pi_id: requestId,
      pi_type: type?.value,
      pc_orgname: orgname,
      pi_recipient_id: recipientId,
      pc_email,
      pc_org_cod,
      pn_org_type: pn_org_type_obj?.value || null,
    };
    try {
      await saveRequest(data);
      Toast.fire({
        type: 'success',
        title: 'Дані збережено!',
      });
      history.push('/competency');
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    updateRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTableRows = () => {
    return state.table.length > 0 ? (
      state.table.map((item, i) => {
        const { c_filename, i_request, id, doc_type_txt, i_doctype, c_comment } = item;
        return (
          <tr key={id}>
            <td>{doc_type_txt}</td>
            <td>{c_comment}</td>
            <td>
              <a href={`/aid/file/${c_filename}`} target="blank">
                {c_filename}
              </a>
            </td>
            <td>
              <Button variant="outlined" type="button" disabled={disableChanges} onClick={() => deleteDocument(id)}>
                Видалити
              </Button>
            </td>
          </tr>
        );
      })
    ) : (
      <tr>
        <td colSpan="5" className="text-center">
          <h4>
            <strong>Не додано жодного документу</strong>
          </h4>
        </td>
      </tr>
    );
  };

  const {
    edrpouError,
    type,
    name,
    sri,
    orgname,
    docType,
    docTypeText,
    docTypes,
    loading,
    edrpou,
    recipientId,
    pn_org_type_obj,
  } = state;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid container item spacing={3} justifyContent="center">
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Typography>Я: {name}</Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={3} justifyContent="center">
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Typography>РНОКПП (ПАСПОРТ): {sri}</Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={3} justifyContent="center">
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Autocomplete
                // eslint-disable-next-line radix
                value={requestType.find(({ value }) => parseInt(value) === parseInt(type?.value)) || null}
                onChange={(event, option) => handleSelect('type', option)}
                // autoHighlight
                getOptionLabel={(option) => option.label}
                options={requestType}
                renderInput={(params) => (
                  <TextField
                    InputLabelProps={{ style: { fontSize: '12px' } }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    label="Тип запиту"
                  />
                )}
              />
            </Grid>
            {type?.value === 1 && (
              <Grid container item spacing={3} justifyContent="center">
                <Grid item>
                  <TextField
                    label="ЄДРПОУ:"
                    type="text"
                    name="edrpou"
                    value={state.edrpou}
                    onChange={handleInput}
                    error={edrpouError}
                    helperText={edrpouError && 'Код з 8 або 10 знаків'}
                  />
                </Grid>
                <Grid item>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    // className={classes.button_save}
                    // color="primary"
                    // style={{ marginLeft: 'auto', marginRight: 'auto' }}
                    onClick={fetchOrgname}
                    disabled={loading || !(edrpou.length === 8 || edrpou.length === 10)}
                  >
                    <Icon className="fa fa-search mr-1" />
                    Знайти у ЄР <img className={classes.button_loading_img} src={vector} alt="loading btn" />
                  </Button>
                </Grid>
              </Grid>
            )}
            {type?.value === 1 && (
              <Grid container item spacing={3} justifyContent="center">
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Typography>{orgname}</Typography>
                </Grid>
              </Grid>
            )}
            {(type?.value === 2 || type?.value === 3) && (
              <Grid container item spacing={3} justifyContent="center">
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <TextField
                    label="Назва організації:"
                    type="text"
                    name="orgname"
                    value={state.orgname}
                    onChange={handleInput}
                    inputProps={{ maxLength: 240 }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container item spacing={3} justifyContent="center">
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <TextField
                  label="Електронна адреса для повідомлення результату"
                  type="text"
                  name="pc_email"
                  value={state.pc_email}
                  onChange={handleInput}
                  inputProps={{ maxLength: 240 }}
                />
              </Grid>
            </Grid>
            {type?.value === 2 && (
              <Grid container item spacing={3} justifyContent="center">
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <TextField
                    label="ЄДРПОУ"
                    type="text"
                    name="pc_org_cod_edrpou"
                    value={state.pc_org_cod_edrpou}
                    onChange={handleInput}
                    inputProps={{ maxLength: 8 }}
                  />
                </Grid>
              </Grid>
            )}
            {type?.value === 3 && (
              <Grid container item spacing={3} justifyContent="center">
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <TextField
                    label="Податковий номер"
                    type="text"
                    name="pc_org_cod_pod"
                    value={state.pc_org_cod_pod}
                    onChange={handleInput}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
              </Grid>
            )}
            {(type?.value === 2 || type?.value === 3) && (
              <Grid container item spacing={3} justifyContent="center">
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <Autocomplete
                    // eslint-disable-next-line radix
                    value={
                      // eslint-disable-next-line radix
                      recipientTypes.find(({ value }) => parseInt(value) === parseInt(pn_org_type_obj?.value)) || null
                    }
                    onChange={(event, option) => handleSelect('pn_org_type_obj', option)}
                    // disabled={!type}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    options={recipientTypes}
                    renderInput={(params) => (
                      <TextField
                        InputLabelProps={{ style: { fontSize: '12px' } }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Організаційно-правова форма"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <section className="documentsLoadingForm">
          <form onSubmit={submitDocument} className={classes.test_section_dowload}>
            <Grid container spacing={1}>
              <Grid container item spacing={3} justifyContent="center">
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography variant="h6" align="center" style={{ fontSize: '32px' }}>
                    Скани
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item spacing={3} justifyContent="center">
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Autocomplete
                    // eslint-disable-next-line radix
                    value={docTypes.find(({ value }) => parseInt(value) === parseInt(docType?.value)) || null}
                    onChange={(event, option) => handleSelect('docType', option)}
                    inputValue={docTypeText}
                    onInputChange={(event, newInputValue) => {
                      setState({ ...state, docTypeText: newInputValue });
                    }}
                    disabled={!type}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    options={docTypes}
                    renderInput={(params) => (
                      <TextField
                        InputLabelProps={{ style: { fontSize: '12px' } }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Тип документу"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    label="Коментар:"
                    type="text"
                    name="comment"
                    value={state.comment}
                    onChange={handleInput}
                  />
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.test_border_dowload}>
              <input
                id="file-upload-cargo"
                className="upload"
                type="file"
                accept=".jpeg,.jpg,.png,.pdf,.p7s"
                disabled={disableChanges}
                onChange={handleChangeFileInput}
                ref={fileInput}
              />
              <label
                htmlFor="file-upload-cargo"
                // className={classes.test_button_loading}
                className={clsx({
                  [classes.disabledChanges]: disableChanges,
                })}
              >
                {/* <div
                  className={clsx(classes.test_button_loading_text, {
                    [classes.disabledChanges]: disableChanges,
                  })}
                  // className={classes.test_button_loading_text}
                > */}
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  disabled={disableChanges}
                  startIcon={<ArrowDownwardOutlined />}
                >
                  ОБЕРІТЬ ФАЙЛ
                </Button>

                {/* </div> */}
              </label>
              <div className={classes.test_textFile}>
                <div className={classes.test_textFile} style={{ fontWeight: 800 }}>
                  Увага! Розмір документу для завантаження не повинен перевищувати 10 Мб
                </div>
                {state.fileSize > parseInt(100000000) && (
                  <div className="text-danger">Завантаження неможливе! Розмір файла перевищує 10 мб</div>
                )}
              </div>
            </div>
            <div className={classes.test_file_sections}>
              <div className={classes.testdowload_file}>
                {state.submitted && !fileInput.current.files[0] && (
                  <FormHelperText error id="name-error-text">
                    Поле обов&apos;язкове
                  </FormHelperText>
                )}
                {state.fileName && <div style={{ textAlign: 'center' }}> {state.fileName}</div>}
              </div>
            </div>
            <Grid container justifyContent="center" alignItems="center">
              <Button
                type="submit"
                color="primary"
                variant="contained"
                // className={classes.test_button_save}
                // eslint-disable-next-line radix
                disabled={state.fileSize > parseInt(100000000) || disableChanges}
                startIcon={<Check />}
              >
                Зберегти
              </Button>
            </Grid>
          </form>

          <div className="table-responsive zebra">
            <table>
              <thead>
                <tr>
                  <th style={{ width: '25%' }}>Тип документу</th>
                  <th style={{ width: '25%' }}>Коментар</th>
                  <th style={{ width: '25%' }}>Скан документу</th>
                  <th style={{ width: '25%' }}>Видалити</th>
                </tr>
              </thead>
              <tbody>{renderTableRows()}</tbody>
            </table>
          </div>
        </section>
        <Grid container justifyContent="center" alignItems="center" style={{ marginTop: 16 }}>
          <Button
            type="button"
            color="primary"
            variant="contained"
            // eslint-disable-next-line radix
            disabled={disableChanges || !orgname || !type}
            onClick={submitRequest}
            startIcon={<Check />}
          >
            Подати запит
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CompetencyRequest;

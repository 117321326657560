import React from 'react';
import { Grid, Card, CardContent, CardActions } from '@material-ui/core';
import AsyncSelect from 'react-select/async';
import { useSelector } from 'react-redux';
import { customTheme, customStyles } from '../../../../customStyles/reactSelect';
import ExportExcelButton from './ExportExcelButton';
import TableButton from './TableButton';
import TableWindow from './TableWindow';

const TopSelect = ({ currentOption, handleSelect }) => {
  const {
    catalogs: { uktzed },
    // reference: { uktzed },
  } = useSelector((state) => state);

  const [view, setView] = React.useState(false);

  const handleView = () => {
    setView(true);
  };

  const filterUktzed = (inputValue) => {
    return uktzed.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const filterCode = (inputValue) => {
    return uktzed.filter((i) => i.value.toString().startsWith(inputValue));
  };

  const promiseCode = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterCode(inputValue));
      }, 1000);
    });

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterUktzed(inputValue));
      }, 1000);
    });

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <AsyncSelect
                // cacheOptions
                value={uktzed.filter(({ value }) => value === currentOption?.value)}
                onChange={(option) => handleSelect(option)}
                loadOptions={promiseCode}
                getOptionLabel={(option) => option?.value || ''}
                options={uktzed}
                placeholder="Код УКТ ЗЕД"
                noOptionsMessage={() => 'Почніть вводити код УКТЗЕД ...'}
                theme={customTheme}
                styles={customStyles}
                menuPortalTarget={document.body}
              />
            </Grid>
            <Grid item xs={8}>
              <AsyncSelect
                // cacheOptions
                value={uktzed.filter(({ value }) => value === currentOption?.value)}
                onChange={(option) => handleSelect(option)}
                loadOptions={promiseOptions}
                getOptionLabel={(option) => option?.label || ''}
                placeholder="Опис з довідника УКТ ЗЕД"
                noOptionsMessage={() => 'Почніть вводити опис вантажу...'}
                theme={customTheme}
                styles={customStyles}
                menuPortalTarget={document.body}
              />
            </Grid>
          </Grid>
          <TableWindow view={view} setView={setView} />
        </CardContent>
        <CardActions>
          <TableButton handleView={handleView} />
          <ExportExcelButton />
        </CardActions>
      </Card>
    </>
  );
};

export default TopSelect;

import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TableContainer from '@material-ui/core/TableContainer';
import { Button, Icon, Box } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { saveReportBalance, deleteReportBalance, downloadBalance } from '../../../../api/api';

import {
  addBalanceItem,
  setBalanceCurrent,
  setBalanceInput,
  clearBalanceCurrent,
  removeBalanceItem,
} from '../../../../redux/actions/report.action';

import { StyledTableCell } from './StyledTableCell';
import BalanceRow from './BalanceRow';
import { fetchActs } from '../../../../redux/actions/acts.action';

import ReportExcel from './ReportExcel';
import popup from './reportHelpers/Popup.module.css';

function ExportIcon(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <path d="M0 17H14V15H0V17ZM14 6H10V0H4V6H0L7 13L14 6Z" fill="#141414" />
    </SvgIcon>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  footerCell: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 400,
    backgroundColor: theme.palette.common.white,
  },
  input: {
    width: '100%',
  },
  addButton: {
    padding: '12px 24px',
    background: '#141414',
    borderRadius: '30px',
    fontFamily: 'e-Ukraine',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#FFFFFF',
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
  },
  excel: {
    backgroundColor: '#D5D8E0',
    color: '#141414',
    padding: '12px 24px',
    fontFamily: 'e-Ukraine',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
  },
  pagination: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    width: '100%',
  },
  box: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  twoBtns: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
}));

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
});

const swalWithBootstrapButtons = Swal.mixin({
  buttonsStyling: false,
});

const Table1 = ({ updateBothTables, currentDate }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const { acts, loading, error } = useSelector((state) => state.acts);
  const i_cargo = useSelector((state) => state.cargo.cargoApplication.i_cargo);
  const i_report = useSelector((state) => state.report.params.i_report);
  const i_recip = useSelector((state) => state.report.params.i_recip);
  const current = useSelector((state) => state.report.balance.current);
  const array = useSelector((state) => state.report.balance.array);

  const [currentIndex, setCurrentIndex] = React.useState(-1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? <span>&uarr;</span> : <span>&darr;</span>;
    }
    return null;
  };

  const getValue = (obj, path) => {
    const keys = path.split('.');
    let value = obj;
    for (const key of keys) {
      value = value[key];
    }
    return value;
  };

  const sortedArray = useMemo(() => {
    if (sortConfig.key !== null) {
      return array.slice().sort((a, b) => {
        const valueA = getValue(a, sortConfig.key);
        const valueB = getValue(b, sortConfig.key);

        if (valueA < valueB) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return array;
  }, [array, sortConfig]);

  const handleInput = (event) => {
    const { value, name } = event.target;
    dispatch(setBalanceInput(name, value));
  };

  const handleSelect = (name) => (option) => {
    if (option) {
      dispatch(setBalanceInput(name, option));
    }
  };

  const addRow = () => {
    setCurrentIndex(0);
    dispatch(addBalanceItem());
    dispatch(clearBalanceCurrent());
  };

  const saveBalanceRow = () => {
    const {
      pi_id,
      pi_aidtype_obj,
      pc_descript,
      pn_weight_in,
      pn_count_in,
      pn_weight_out,
      pn_count_out,
      pn_weight_rej,
      pn_count_rej,
      pn_weight_bal,
      pn_count_bal,
    } = current;

    const data = {
      pi_id,
      pi_recip: i_recip,
      pd_rep: currentDate ? format(currentDate, 'dd.MM.yyyy') : null,
      pi_cargo: i_cargo,
      pi_aidtype: pi_aidtype_obj?.id,
      pc_descript,
      pn_weight_in,
      pn_weight_out,
      pn_weight_rej,
      pn_weight_bal,
      pn_count_in,
      pn_count_out,
      pn_count_rej,
      pn_count_bal,
    };

    saveReportBalance(data)
      .then((res) => {
        Toast.fire({
          type: 'success',
          title: 'Дані збережено!',
        });
        updateBothTables();
        dispatch(clearBalanceCurrent());
        setCurrentIndex(-1);
      })
      .catch((err) => {});
  };

  const deleteBalanceRow = (id) => {
    swalWithBootstrapButtons
      .fire({
        customClass: {
          popup: popup['swal-popup'],
          container: popup['swal-container'],
          title: [popup['swal-title'], popup['swal-title-m1']],
          confirmButton: popup['swal-custom-btn'],
          cancelButton: [popup['swal-custom-btn'], popup['swal-custom-btn-lt']],
          actions: popup['swal-actions'],
        },
        title: 'Ви впевнені, що бажаєте видалити надходження?',
        showConfirmButton: true,
        showCancelButton: true,
        focusConfirm: true,
        focusCancel: false,
        reverseButtons: true,
        confirmButtonText: 'ТАК, ВИДАЛИТИ',
        cancelButtonText: 'НІ, ПОВЕРНУТИСЯ',
      })
      .then((result) => {
        if (result.value) {
          if (id) {
            deleteReportBalance(id)
              .then(() => {
                updateBothTables();
              })
              // eslint-disable-next-line no-unused-vars
              .catch((err) => {});
          } else {
            dispatch(removeBalanceItem(0));
          }
          dispatch(clearBalanceCurrent());
          setCurrentIndex(-1);
        }
      })
      .catch((err) => {});
  };

  const editBalanceRow = (ind, id) => {
    dispatch(setBalanceCurrent(array.find((element) => element.pi_id === id)));
    setCurrentIndex(ind);
  };

  const downloadExcel = async () => {
    try {
      const res = await downloadBalance(i_report);
      const blob = new Blob([res.data], { type: 'application/octet-binary' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Report.xlsx';
      a.click();
      a.remove();
    } catch (err) {}
  };

  const sum_n_weight_in = array
    .reduce((accumulator, item) => {
      const { pn_weight_in } = item;
      const currentValue = pn_weight_in ? Number(pn_weight_in) : 0;
      return accumulator + currentValue;
    }, 0)
    .toFixed(3);

  const sum_n_count_in = array
    .reduce((accumulator, item) => {
      const { pn_count_in } = item;
      const currentValue = pn_count_in ? Number(pn_count_in) : 0;
      return accumulator + currentValue;
    }, 0)
    .toFixed(2);

  const sum_n_weight_out = array
    .reduce((accumulator, item) => {
      const { pn_weight_out } = item;
      const currentValue = pn_weight_out ? Number(pn_weight_out) : 0;
      return accumulator + currentValue;
    }, 0)
    .toFixed(3);

  const sum_n_count_out = array
    .reduce((accumulator, item) => {
      const { pn_count_out } = item;
      const currentValue = pn_count_out ? Number(pn_count_out) : 0;
      return accumulator + currentValue;
    }, 0)
    .toFixed(2);

  const sum_n_weight_rej = array
    .reduce((accumulator, item) => {
      const { pn_weight_rej } = item;
      const currentValue = pn_weight_rej ? Number(pn_weight_rej) : 0;
      return accumulator + currentValue;
    }, 0)
    .toFixed(3);

  const sum_n_count_rej = array
    .reduce((accumulator, item) => {
      const { pn_count_rej } = item;
      const currentValue = pn_count_rej ? Number(pn_count_rej) : 0;
      return accumulator + currentValue;
    }, 0)
    .toFixed(2);

  const sum_n_weight_bal = array
    .reduce((accumulator, item) => {
      const { pn_weight_bal } = item;
      const currentValue = pn_weight_bal ? Number(pn_weight_bal) : 0;
      return accumulator + currentValue;
    }, 0)
    .toFixed(3);

  const sum_n_count_bal = array
    .reduce((accumulator, item) => {
      const { pn_count_bal } = item;
      const currentValue = pn_count_bal ? Number(pn_count_bal) : 0;
      return accumulator + currentValue;
    }, 0)
    .toFixed(2);

  return (
    <>
      <Grid container spacing={0}>
        <Grid container item sx={12} style={{ margin: '16px 0' }}>
          <Box className={classes.box}>
            <div className={classes.twoBtns}>
              <ReportExcel />
              <Button type="button" color="primary" variant="contained" className={classes.addButton} onClick={addRow}>
                Додати надходження
              </Button>
            </div>
          </Box>
        </Grid>
        <Grid container item sx={12}>
          <TableContainer>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableCell rowSpan={2}>Номер за порядком</StyledTableCell>
                  <StyledTableCell rowSpan={2} onClick={() => handleSort('pi_aidtype_obj.c_name')}>
                    Товар по коду ГД {getSortIcon('pi_aidtype_obj.c_name')}
                  </StyledTableCell>
                  <StyledTableCell rowSpan={2} onClick={() => handleSort('pc_descript')}>
                    Найменування товарів {getSortIcon('pc_descript')}
                  </StyledTableCell>
                  <StyledTableCell colSpan={2}>Отримано у звітному місяці</StyledTableCell>
                  <StyledTableCell colSpan={2}>Розподілено у звітному місяці</StyledTableCell>
                  <StyledTableCell colSpan={2}>Втрати загалом</StyledTableCell>
                  <StyledTableCell colSpan={2}>Залишок загалом</StyledTableCell>
                  <StyledTableCell rowSpan={2}>Функції</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell onClick={() => handleSort('pn_weight_in')}>
                    Вага, кг/об’єм, л {getSortIcon('pn_weight_in')}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => handleSort('pn_count_in')}>
                    Кількість, шт. {getSortIcon('pn_count_in')}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => handleSort('pn_weight_out')}>
                    Вага, кг/об’єм, л {getSortIcon('pn_weight_out')}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => handleSort('pn_count_out')}>
                    Кількість, шт. {getSortIcon('pn_count_out')}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => handleSort('pn_weight_rej')}>
                    Вага, кг/об’єм, л {getSortIcon('pn_weight_rej')}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => handleSort('pn_count_rej')}>
                    Кількість, шт. {getSortIcon('pn_count_rej')}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => handleSort('pn_weight_bal')}>
                    Вага, кг/об’єм, л {getSortIcon('pn_weight_bal')}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => handleSort('pn_count_bal')}>
                    Кількість, шт. {getSortIcon('pn_count_bal')}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                  <BalanceRow
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    index={index}
                    currentIndex={currentIndex}
                    item={item}
                    handleInput={handleInput}
                    handleSelect={handleSelect}
                    saveBalanceRow={saveBalanceRow}
                    deleteBalanceRow={deleteBalanceRow}
                    editBalanceRow={editBalanceRow}
                  />
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <StyledTableCell className={classes.footerCell}>
                    <Typography style={{ fontSize: 16, fontWeight: 400 }}>ВСЬОГО</Typography>
                  </StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>—</StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>—</StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>
                    <strong>{sum_n_weight_in}</strong>
                  </StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>
                    <strong>{sum_n_count_in}</strong>
                  </StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>
                    <strong>{sum_n_weight_out}</strong>
                  </StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>
                    <strong>{sum_n_count_out}</strong>
                  </StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>
                    <strong>{sum_n_weight_rej}</strong>
                  </StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>
                    <strong>{sum_n_count_rej}</strong>
                  </StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>
                    <strong>{sum_n_weight_bal}</strong>
                  </StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>
                    <strong>{sum_n_count_bal}</strong>
                  </StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>—</StyledTableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <TablePagination
            classes={{ root: classes.pagination }}
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={sortedArray.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} з ${count}`}
            labelRowsPerPage="Рядків на сторінці:"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Table1;

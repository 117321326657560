import React from 'react';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { history } from '../../../../vibe/components/utilities/MainUtils/hist';
import { cargoReportGetPDF, signReport } from '../../../../api/api';
import popup from './SigningNotification.module.css';

import ReportSignatureList from './ReportSignatureList';
import ReportSignatureNote from './ReportSignatureNote';

const useStyles = makeStyles((theme) => ({
  button_img: {
    marginLeft: '15px',
  },
  btnBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: '32px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  button_print: {
    alignItems: 'center',
    padding: '20px 70px',
    width: '300px',
    height: '66px',
    background: 'transparent',
    borderRadius: '40px',
    fontFamily: 'e-Ukraine',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#141414',
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      width: '260px',
      marginRight: 0,
    },
  },
  button_sign: {
    marginRight: 0,
    alignItems: 'center',
    padding: '20px 70px',
    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',
    fontFamily: 'e-Ukraine',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#FFFFFF',
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      width: '260px',
    },
  },
  test_button_loading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 70px',
    gap: '4px',
    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',
    cursor: 'pointer',
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '180px',
      padding: '0',
    },
  },
  test_button_loading_text: {
    fontFamily: 'e-Ukraine',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    '&:hover': {
      color: '#141414',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  bottomTitle: {
    color: '#000',
    fontFamily: 'e-Ukraine',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '32px',
    marginBottom: 0,
  },
  bottomText: {
    color: '#000',
    fontFamily: 'e-Ukraine',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '24px',
    margin: 0,
    padding: 0,
  },
  test_button_loading_img: {
    marginLeft: '15px',
  },
  control: {
    display: 'flex',
    justifyItems: 'space-between',
    alignItems: 'flex-start',
    gap: '8px',
    padding: 0,
    margin: 0,
  },
  checkbox: {
    padding: 0,
  },
}));

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const TheList = () => {
  const classes = useStyles();
  return (
    <Box>
      <Typography>Не відображати дані у публічному доступі згідно чинного законодавства*</Typography>
    </Box>
  );
};

const ReportSignature = () => {
  const classes = useStyles();
  const i_recip = useSelector((state) => state.report.params.i_recip);
  const curr_date = useSelector((state) => state.report.params.curr_date);
  const [state, setState] = React.useState({
    checkedA: false,
  });

  const handleCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  async function orederView() {
    try {
      const data = {
        id: i_recip,
        pd_rep: curr_date || '',
      };
      const response = await cargoReportGetPDF(data);

      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      link.remove();
      // eslint-disable-next-line no-empty
    } catch (error) { }
  }

  async function signOrederView() {
    swalWithBootstrapButtons
      .fire({
        customClass: {
          popup: popup['swal-popup'],
          container: popup['swal-container'],
          title: [popup['swal-title'], popup['swal-title-m1']],
          confirmButton: popup['swal-custom-btn'],
          cancelButton: [popup['swal-custom-btn'], popup['swal-custom-btn-lt']],
          actions: popup['swal-actions'],
        },
        title: 'Ви готові підписати звіт?',
        text:
          'Впевніться що документ повністю сформований та перевірений за звітний місяць.Після підписання редагувати звіт НЕ МОЖЛИВО!',
        showConfirmButton: true,
        showCancelButton: true,
        focusConfirm: true,
        focusCancel: false,
        reverseButtons: true,
        confirmButtonText: 'ПІДПИСАТИ',
        cancelButtonText: 'ВІДМІНИТИ',
      })
      .then(async (result) => {
        if (result.value) {
          try {
            const { checkedA } = state;
            const dat = {
              pi_recipient: i_recip,
              pd_rep: curr_date || '',
              pl_private: checkedA ? 1 : 0,
            };
            await signReport(dat);

            swalWithBootstrapButtons
              .fire({
                title: 'Звіт підписано!',
                text: 'Наразі редагування документу НЕ МОЖЛИВО',
                customClass: {
                  popup: [popup['swal-popup'], popup['swal-popup-2']],
                  container: popup['swal-container'],
                  title: [popup['swal-title'], popup['swal-title-m2']],
                  content: popup['swal-text'],
                  confirmButton: popup['swal-custom-btn'],
                  actions: popup['swal-actions'],
                },
                buttonsStyling: false,
                showConfirmButton: true,
                confirmButtonText: 'ЗРОЗУМІЛО',
              })
              .then(async (res) => {
                if (res.value) {
                  try {
                    const data = {
                      id: i_recip,
                      pd_rep: curr_date || '',
                    };
                    const response = await cargoReportGetPDF(data);
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('target', '_blank');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    history.push(`/report_description`);
                  } catch (error) { }
                }
              });
          } catch (error) {
            swalWithBootstrapButtons.fire({
              icon: 'error',
              text: error.response?.data,
            });
          }
        }
      });
  }

  return (
    <div>
      <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px', margin: '16px 0' }}>
        <p className={classes.bottomTitle}>Підписати Звіт</p>
        <p className={classes.bottomText}>Документ має бути повністю сформований та перевірений за звітний місяць.</p>
        <p className={classes.bottomText}>Після підписання редагувати НЕ МОЖЛИВО!</p>
      </Box>

      <div>
        <FormControlLabel
          className={classes.control}
          labelPlacement="end"
          control={
            <Checkbox
              color="primary"
              checked={state.checkedA}
              onChange={handleCheck}
              name="checkedA"
              className={classes.checkbox}
            />
          }
          label={<ReportSignatureList />}
        />
        <ReportSignatureNote />
      </div>

      <div className="row" style={{ margin: '50px 0 0 0' }}>
        <div className="col-12 pl-0 pr-0">
          <Box className={classes.btnBox}>
            <Button
              type="button"
              disabled={!curr_date || !i_recip}
              className={classes.button_print}
              onClick={orederView}
              variant="outlined"
            >
              Роздрукувати
            </Button>

            <Button
              disabled={!curr_date}
              type="button"
              className={classes.button_sign}
              onClick={signOrederView}
              variant="outlined"
            >
              Підписати
            </Button>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ReportSignature);

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

// eslint-disable-next-line import/prefer-default-export
export const NextIcon = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_2052_10492)">
        <path d="M4.25 12H20.75" stroke="#141414" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M14 5.25L20.75 12L14 18.75"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2052_10492">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const ExcelDwnIcon = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_2798_3719)">
        <path d="M12 14.8164V4.31641" stroke="#141414" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M20.25 14.8164V20.0664C20.25 20.2653 20.171 20.4561 20.0303 20.5967C19.8897 20.7374 19.6989 20.8164 19.5 20.8164H4.5C4.30109 20.8164 4.11032 20.7374 3.96967 20.5967C3.82902 20.4561 3.75 20.2653 3.75 20.0664V14.8164"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.75 11.0664L12 14.8164L8.25 11.0664"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2798_3719">
          <rect width="24" height="24" fill="white" transform="translate(0 0.566406)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export function CloseIcon(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_2339_11368)">
        <path
          d="M15.625 4.375L4.375 15.625"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.625 15.625L4.375 4.375"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2339_11368">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export function PopperIcon(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_2998_10606)">
        <path
          d="M16.8278 7.26094C16.7806 7.14673 16.7005 7.0491 16.5977 6.9804C16.4949 6.91171 16.3741 6.87503 16.2505 6.875H3.75049C3.62681 6.8749 3.50587 6.91151 3.403 6.98017C3.30013 7.04884 3.21995 7.14649 3.1726 7.26076C3.12526 7.37502 3.11288 7.50076 3.13704 7.62207C3.16119 7.74337 3.2208 7.85478 3.3083 7.94219L9.55831 14.1922C9.61635 14.2503 9.68528 14.2964 9.76116 14.3279C9.83703 14.3593 9.91836 14.3755 10.0005 14.3755C10.0826 14.3755 10.164 14.3593 10.2398 14.3279C10.3157 14.2964 10.3846 14.2503 10.4427 14.1922L16.6927 7.94219C16.7801 7.85473 16.8395 7.74333 16.8636 7.62206C16.8877 7.5008 16.8752 7.37513 16.8278 7.26094Z"
          fill="#141414"
        />
      </g>
      <defs>
        <clipPath id="clip0_2998_10606">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export function CopyIcon(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_2339_11423)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 20.25V6.75C18 6.55109 17.921 6.36032 17.7803 6.21967C17.6397 6.07902 17.4489 6 17.25 6H3.75C3.55109 6 3.36032 6.07902 3.21967 6.21967C3.07902 6.36032 3 6.55109 3 6.75V20.25C3 20.4489 3.07902 20.6397 3.21967 20.7803C3.36032 20.921 3.55109 21 3.75 21H17.25C17.4489 21 17.6397 20.921 17.7803 20.7803C17.921 20.6397 18 20.4489 18 20.25ZM6.75 3H20.25C20.4489 3 20.6397 3.07902 20.7803 3.21967C20.921 3.36032 21 3.55109 21 3.75V17.25C21 17.4489 20.921 17.6397 20.7803 17.7803C20.6397 17.921 20.4489 18 20.25 18C20.0511 18 19.8603 17.921 19.7197 17.7803C19.579 17.6397 19.5 17.4489 19.5 17.25V4.5H6.75C6.55109 4.5 6.36032 4.42098 6.21967 4.28033C6.07902 4.13968 6 3.94891 6 3.75C6 3.55109 6.07902 3.36032 6.21967 3.21967C6.36032 3.07902 6.55109 3 6.75 3ZM9.89594 10.7798C9.89594 10.3656 10.2317 10.0298 10.6459 10.0298C11.0601 10.0298 11.3959 10.3656 11.3959 10.7798V13.0298H13.6459C14.0601 13.0298 14.3959 13.3656 14.3959 13.7798C14.3959 14.194 14.0601 14.5298 13.6459 14.5298H11.3959V16.7798C11.3959 17.194 11.0601 17.5298 10.6459 17.5298C10.2317 17.5298 9.89594 17.194 9.89594 16.7798V14.5298H7.64594C7.23172 14.5298 6.89594 14.194 6.89594 13.7798C6.89594 13.3656 7.23172 13.0298 7.64594 13.0298H9.89594V10.7798Z"
          fill="#141414"
        />
      </g>
      <defs>
        <clipPath id="clip0_2339_11423">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export function DocIcon(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_2980_12617)">
        <path
          d="M28.125 31.5H7.875C7.57663 31.5 7.29048 31.3815 7.07951 31.1705C6.86853 30.9595 6.75 30.6734 6.75 30.375V5.625C6.75 5.32663 6.86853 5.04048 7.07951 4.82951C7.29048 4.61853 7.57663 4.5 7.875 4.5H21.375L29.25 12.375V30.375C29.25 30.6734 29.1315 30.9595 28.9205 31.1705C28.7095 31.3815 28.4234 31.5 28.125 31.5Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.375 4.5V12.375H29.25"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M13.5 19.125H22.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.5 23.625H22.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_2980_12617">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export function DeleteIcon(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_2980_12620)">
        <path
          d="M21 5.25C21 5.44891 20.921 5.63968 20.7803 5.78033C20.6397 5.92098 20.4489 6 20.25 6H19.5V19.5C19.5 19.8978 19.342 20.2794 19.0607 20.5607C18.7794 20.842 18.3978 21 18 21H6C5.60218 21 5.22064 20.842 4.93934 20.5607C4.65804 20.2794 4.5 19.8978 4.5 19.5V6H3.75C3.55109 6 3.36032 5.92098 3.21967 5.78033C3.07902 5.63968 3 5.44891 3 5.25C3 5.05109 3.07902 4.86032 3.21967 4.71967C3.36032 4.57902 3.55109 4.5 3.75 4.5H20.25C20.4489 4.5 20.6397 4.57902 20.7803 4.71967C20.921 4.86032 21 5.05109 21 5.25ZM8.25 3H15.75C15.9489 3 16.1397 2.92098 16.2803 2.78033C16.421 2.63968 16.5 2.44891 16.5 2.25C16.5 2.05109 16.421 1.86032 16.2803 1.71967C16.1397 1.57902 15.9489 1.5 15.75 1.5H8.25C8.05109 1.5 7.86032 1.57902 7.71967 1.71967C7.57902 1.86032 7.5 2.05109 7.5 2.25C7.5 2.44891 7.57902 2.63968 7.71967 2.78033C7.86032 2.92098 8.05109 3 8.25 3Z"
          fill="#141414"
        />
      </g>
      <defs>
        <clipPath id="clip0_2980_12620">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

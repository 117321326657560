import React from 'react';
import { Button } from '@material-ui/core';

const TableButton = ({ handleView }) => {
  return (
    <Button color="primary" variant="contained" onClick={handleView}>
      Перегляд
    </Button>
  );
};

export default TableButton;

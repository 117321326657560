import React from 'react';
import clsx from 'clsx';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StyledTableCell } from './StyledTableCell';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
  },
  blackCell: { backgroundColor: 'black', color: 'white', padding: 5 },
}));

const DistributionRow = ({
  currentIndex,
  index,
  item,
  deleteDistributionRow,
  gotoDistributionEdit,
  editDistributionRow,
}) => {
  const classes = useStyles();

  const {
    pi_acq_type,
    pc_orgname,
    pn_edrpou,
    c_aid_descript,
    pc_description,
    pn_weight,
    pn_count,
    pc_document,
    pn_acqcnt,
    pd_distribut,
    pi_region_obj,
    c_locality_name,
    pc_address,
  } = item;

  return (
    <TableRow>
      <StyledTableCell>{index + 1}</StyledTableCell>
      <StyledTableCell>
        <Typography>{pc_orgname}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography className={clsx([pi_acq_type === '2' && classes.blackCell])}>
          {pi_acq_type === '2' ? 'не зазначається' : pn_edrpou}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography style={{ minWidth: '200px' }}>{c_aid_descript}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography>{pc_description}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography>{pn_weight}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography>{pn_count}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography>{pc_document}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography className={clsx([pi_acq_type === '1' && classes.blackCell])}>
          {pi_acq_type === '1' ? 'не зазначається' : pn_acqcnt}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography>{pd_distribut}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <div>
          <Typography>{pi_region_obj?.label}</Typography>
          <Typography>{c_locality_name}</Typography>
          <Typography>{pc_address}</Typography>
        </div>
      </StyledTableCell>
      <StyledTableCell style={{ minWidth: '200px' }}>
        <IconButton
          disabled={currentIndex !== -1}
          color="primary"
          aria-label="Edit"
          onClick={(e) => {
            e.stopPropagation();
            gotoDistributionEdit(index, item.pi_id);
          }}
        >
          <EditIcon fontSize="inherit" />
        </IconButton>
        <IconButton
          color="primary"
          aria-label="Delete"
          onClick={(e) => {
            e.stopPropagation();
            deleteDistributionRow(item.pi_id);
          }}
        >
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </StyledTableCell>
    </TableRow>
  );
};

export default DistributionRow;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { IconButton, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { localityAction, setLocality } from '../../../../redux/actions/catalog.action';
import { ReactComponent as CloseIcon } from './reportHelpers/assets/CloseIcon.svg';
import { CopyIcon, PopperIcon } from './reportHelpers/CustomIcon';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: '0px',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    transition: theme.transitions.create('transform'),
    transform: 'translate(+50%, -50%)',
  },
  titleText: {
    textAlign: 'center',
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '28px',
    padding: 0,
  },
});

const ButtonTooltip = withStyles((theme) => ({
  tooltipPlacementBottom: {
    margin: '0px 0px',
  },
  tooltip: {
    backgroundColor: '#141414',
    color: '#ffffff',
    fontSize: '10px',
    fontWeight: '300',
    lineHeight: '14px',
    padding: '4px 8px',
    borderRadius: '2px',
  },
}))(Tooltip);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiDialogTitle disableTypography classes={{ root: classes.root }} {...other}>
      <Typography className={classes.titleText} variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  dialog: {
    zIndex: '10001 !important',
  },
  paper: {
    padding: '36px',
    margin: 0,
  },
  dialogContent: {
    padding: '8px 0 0',
  },
  input: {
    borderBottom: '2px solid #141414',
    '& input.Mui-disabled': {
      backgroundColor: 'transparent',
    },
    '& label.Mui-disabled': {
      color: 'transparent',
    },
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  button: {
    padding: 10,
  },
  settl: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  settlLabel: {
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
  },
  settlCopy: {
    borderBottom: '2px solid rgba(20, 20, 20, 1)',
    padding: '13px 8px 13px 8px',
    gap: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '367px',
    minHeight: '70px',
  },
  settlValue: {
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '8px 0 0',
  },
  box: {
    padding: 36,
  },
  autoEnd: {
    color: '#141414',
  },
  autoCross: {
    color: '#141414',
  },
}));

const AddressDialog = ({ view, handleClose }) => {
  const region = useSelector((state) => state.catalogs.region);
  const locality = useSelector((state) => state.catalogs.locality);
  const [regionOption, setRegionOption] = React.useState(null);
  const [localityOption, setLocalityOption] = React.useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSelect = (name, option) => {
    if (name === 'region') {
      setRegionOption(option);
      if (option?.value) dispatch(localityAction(option?.value));
      dispatch(setLocality([]));
      setLocalityOption(null);
    } else if (name === 'locality') {
      setLocalityOption(option);
    }
  };

  const copytoClipboard = () => {
    navigator.clipboard.writeText(localityOption?.value || '');
  };

  return (
    <Dialog
      open={view}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
      className={classes.box}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="scroll-dialog-title" className={classes.title} onClose={handleClose}>
        Щоб дізнатися код населеного пункту, оберіть область та населений пункт
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <div className={classes.content}>
          <div>
            <Autocomplete
              value={regionOption}
              onChange={(event, option) => handleSelect('region', option)}
              getOptionLabel={(option) => option.label || ''}
              options={region}
              popupIcon={<PopperIcon />}
              classes={{ clearIndicator: classes.autoCross }}
              renderInput={(params) => (
                <TextField
                  InputLabelProps={{ style: { fontSize: '12px' } }}
                  InputProps={{ ...params.InputProps, disableUnderline: true }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Область"
                  className={classes.input}
                  classes={{ root: classes.underline }}
                />
              )}
            />
          </div>
          <div>
            <Autocomplete
              value={localityOption}
              onChange={(event, option) => handleSelect('locality', option)}
              getOptionLabel={(option) => option.label || ''}
              options={locality}
              popupIcon={<PopperIcon />}
              classes={{ clearIndicator: classes.autoCross }}
              renderInput={(params) => (
                <TextField
                  InputLabelProps={{ style: { fontSize: '12px' } }}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: false,
                    classes: { underline: classes.underline },
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Населений пункт"
                  className={classes.input}
                />
              )}
            />
          </div>
          <div className={classes.settl}>
            <span className={classes.settlLabel}>Код населеного пункту:</span>
            <span
              className={classes.settlCopy}
              style={{ backgroundColor: localityOption?.value ? 'rgba(20, 20, 20, 0.1)' : '#fff' }}
            >
              <span className={classes.settlValue}>{localityOption?.value}</span>
              {localityOption?.value && (
                <ButtonTooltip title="Копіювати" placement="bottom-start" className={classes.button}>
                  <IconButton onClick={copytoClipboard}>
                    <CopyIcon />
                  </IconButton>
                </ButtonTooltip>
              )}
            </span>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddressDialog;

import {
  SET_CARGO_SEARCH,
  SET_CARGO_REGISTER,
  SET_CARGO_LOADING,
  SUCCESS_CARGO_BY_ID,
  SET_CARGO_MINUS_ID,
  CARGO_RECIPIENT_NAME_SELECT,
  CARGO_DONOR_NAME,
  CARGO_DONOR_DETAILS,
  CARGO_DONOR_COUNTRY_SELECT,
  CARGO_SENDING_COUNTRY_SELECT,
  CARGO_ACQUIRER_TYPE_SELECT,
  CARGO_DONOR_REGNUMBER,
  CARGO_HEAD_SURNAME,
  CARGO_HEAD_FIRSTNAME,
  CARGO_HEAD_PATRONYMIC,
  CARGO_WRITTEN_PROPOSAL,
  CARGO_INVOICE,
  CARGO_PACKAGE_LETTER,
  CARGO_OTHER_ACCOMPANY_DOCS,
  CARGO_AGREEMENT_TO_RECEIVE,
  CARGO_L_PRIVATE,
  CARGO_WEAR_PERCENTAGE,
  CARGO_WEIGHT,
  CARGO_EXPIRATION_TERM,
  CARGO_EXPLOITATION_TERM,
  CARGO_PAIRS_NUM,
  CARGO_ACQUIRER_MAIN_NAME,
  CARGO_ACQUIRER_SURNAME,
  CARGO_ACQUIRER_NAME,
  CARGO_ACQUIRER_LASTNAME,
  CARGO_ACQUIRER_SOCIAL_CATEGORY,
  CARGO_ACQUIRER_REGION,
  CARGO_ACQUIRER_LOCALITY,
  CARGO_ACQUIRER_LOCATION,
  CARGO_ACQUIRER_PHONE,
  CARGO_AID_TYPE_SELECT,
  CARGO_ACQUIRER_EDRPOU_CODE,
  CARGO_ACQUIRER_ACQUIRES_NUMBER,
  CARGO_ACQUIRER_SUPPORTING_CIRCUMSTANCES,
  CARGO_ACQUIRER_WEIGHT,
  CARGO_DESCRIPTION,
  CARGO_DESCRIPTION_SELECT,
  CARGO_QUONTITY,
  CARGO_UNIT,
  CARGO_CONDITION,
  CARGO_ADD,
  CARGO_DELETE,
  CARGO_SET_ARRAY,
  SUCCESS_CARGO_DOCUMENTS,
  SET_CURGO_BY_RECIPIENT,
  APPLICATION_CARGO_ID,
  CARGO_CLEAR,
  SUCCESS_MY_CARGO,
  REQUEST_MY_CARGO,
  ERROR_MY_CARGO,
  AQUIRERE_ADD,
  CARGO_ACQUIRER_COUNT_PLAN,
  CARGO_AID_COLLECTION,
  SUCCESS_AQUIRER_BY_ID,
  CLEAR_AQCUIRER,
  EDIT_AID_ACTION,
  REQUEST_CARGO_REPORT,
  SUCCESS_CARGO_REPORT,
  ERROR_CARGO_REPORT,
  DISTRIBUTIONS,
  EDIT_UNIT,
  CARGO_NEW,
  CARGO_OLD,
  DATA_TO_PDF,
  ID_TO_PDF,
  DATE_TO_PDF,
  SUCCESS_INVESTORY,
  SUCCESS_REPORT,
} from '../actions/action-types';
import { awaitExpression } from '@babel/types';

const initialState = {
  cargoLoading: false,
  cargoMinusId: null,
  cargoApplication: {
    id: '',
    i_donor: null,
    donor_country: '',
    donor_name: '',
    c_details: '',
    i_country: '',
    i_recipient: '',
    i_aidform: '',
    c_donor_regnumber: '',

    c_head_surname: '',
    c_head_firstname: '',
    c_head_patronymic: '',

    c_written_proposal: '',
    c_invoice: '',
    c_package_letter: '',
    c_other_accompany_docs: '',

    agreement_to_receive: 0,
    l_private: '',

    i_acquiretype: '',
    c_orgname: '',
    c_surname: '',
    c_name: '',
    c_patronymic: '',
    c_category: '',
    n_phone: '',
    i_region: '',
    i_locality: '',
    c_adress: '',
    i_cargo: null,
    i_acquire: 0,
    i_cargo_app: 0,
    n_number: '',
    l_full: '',
    alloved: '',
    n_countplan: '',
    i_aidplan: 0,
  },
  cargoAquirer: {
    i_aid: 0,

    //i_acquire: ire, this for edit
    //this for new row with 0
    i_acquire: 0,
    i_acquirertype: '',
    c_category: '',
    c_surname: '',
    c_name: '',
    c_patronymic: '',
    n_phone: '',
    i_locality: '',
    c_adress: '',
    c_orgname: '',
    i_region: '',
    n_countplan: '',
    i_aidplan: '',
    i_cargo: '',
    n_edrpou: '',
    n_acqcnt: '',
    c_reason: '',
    p_weight: '',
  },
  cargo_acquirer: [],
  documentsCargo: [],
  applicationCargoId: '',
  cargos: [],
  acquirer: [],
  reports: [],
  distributions: [],
  newData: [],
  oldData: [],
  dataPDF: '',
  IdPDF: '',
  datePDF: '',
  investory: [],
  report: [],
  cargoSearch: {
    currentCount: 0,
    page: 0,
    rowsPerPage: 10,
    order: 'asc',
    orderBy: 'code',
    search: { code: '', startdate: '', enddate: '', status: null },
  },
  cargoRegister: {
    p_page: 1,
    p_rows: 10,
    order: 'asc',
    orderBy: 'p_numb',
    filter: {
      p_numb: '',
      p_edrpou: '',
      p_cargo: '',
      p_state: '',
      p_reg_date: '',
      p_customs_date: '',
      i_country: '',
      donor_name: '',
    },
    dataTable: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    //CARGO_AID_COLLECTION
    //CLEAR_AQCUIRER
    case CARGO_NEW:
      return {
        ...state,
        newData: action.payload,
      };
    case CARGO_OLD:
      return {
        ...state,
        oldData: action.payload,
      };
    case SET_CARGO_SEARCH:
      return {
        ...state,
        cargoSearch: action.payload,
      };
    case SET_CARGO_REGISTER:
      return {
        ...state,
        cargoRegister: action.payload,
      };
    case EDIT_AID_ACTION:
      return {
        ...state,
        cargo_acquirer: state.cargo_acquirer.map((item, index) => {
          if (index === action.payload.index) {
            return {
              ...state.cargo_acquirer[action.payload.index],
              isEdit: action.payload.bool,
            };
          }
          return {
            ...item,
            isEdit: false,
          };
        }),
      };
    case EDIT_UNIT:
      return {
        ...state,
        distributions: state.distributions.map((item, index) => {
          if (index === action.payload.indexDistribution) {
            return {
              ...state.distributions[action.payload.indexDistribution],
              plans: item.plans.map((datum, indexDatum) => {
                if (indexDatum === action.payload.indexPlans) {
                  return {
                    ...state.distributions[action.payload.indexDistribution].plans[action.payload.indexPlans],
                    distrib_p: action.payload.value,
                  };
                }
                return {
                  ...datum,
                };
              }),
            };
          }

          return {
            ...item,
          };
        }),
      };
    case DISTRIBUTIONS:
      return {
        ...state,
        distributions: action.payload,
      };
    case CLEAR_AQCUIRER:
      return {
        ...state,
        cargoAquirer: initialState.cargoAquirer,
      };
    case SUCCESS_AQUIRER_BY_ID:
      return {
        ...state,
        cargoAquirer: action.payload,
      };

    case SUCCESS_MY_CARGO:
      return {
        ...state,
        cargos: action.payload,
      };

    case SUCCESS_INVESTORY:
      return {
        ...state,
        investory: action.payload,
      };

    case SUCCESS_REPORT:
      return {
        ...state,
        report: action.payload,
      };

    case SUCCESS_CARGO_REPORT:
      return {
        ...state,
        reports: action.payload,
      };
    case SET_CARGO_MINUS_ID:
      return {
        ...state,
        cargoMinusId: action.payload,
      };
    case SET_CARGO_LOADING:
      return {
        ...state,
        cargoLoading: action.payload,
      };
    case CARGO_CLEAR:
      return {
        ...state,
        cargoApplication: initialState.cargoApplication,
        cargo_acquirer: initialState.cargo_acquirer,
        documentsCargo: initialState.documentsCargo,
        applicationCargoId: initialState.applicationCargoId,
      };
    case SET_CURGO_BY_RECIPIENT:
      return {
        ...state,
        cargoApplication: action.payload,
      };
    case CARGO_SET_ARRAY:
      return {
        ...state,
        cargo_acquirer: action.payload,
      };
    case CARGO_ADD:
      return {
        ...state,
        cargo_acquirer: [...state.cargo_acquirer, action.payload],
      };
    case CARGO_AID_COLLECTION:
      const { i_aidform: basic } = state.cargoApplication;
      return {
        ...state,
        cargo_acquirer: action.payload.map((item) => {
          const {
            // acquire array
            acquirer,
            // both
            i_aidtype, // знову потрібен
            i_aidform,
            // n_sum,
            c_category,
            // v1
            i_uktzed,
            n_weight,
            c_expiration_date,
            c_service_life,
            i_fuel_type,
            // v4
            // n_comm_year,
            // n_reg_year,
            // c_vehicle_type,
            // c_category,
            // c_brand,
            c_model,
            // c_reg,
            c_engine,
            // c_vin,
            // c_body_numb,
            // c_ch,
            // n_sum,
            // c_owners,
            ...oldData
          } = item;
          return {
            // розкладається на елементи тут
            // acquirer array
            acquirer: [...acquirer],
            // vantazh 1
            c_category: Number(basic) === 1 ? c_category : '',
            n_weight: n_weight || '',
            c_service_life_term: c_service_life || '',
            c_exploitation_term: c_expiration_date || '',
            // fuel type
            c_engine: i_fuel_type || '',
            // vantazh 4
            // n_year_use_start: n_comm_year || '',
            // c_first_registration: n_reg_year || '',
            // c_vehicle_type: c_vehicle_type || '',
            // c_vehicle_category: Number(basic) === 4 ? c_category : '',
            // c_vehicle_brand: c_brand || '',
            c_vehicle_model: c_model || '',
            // c_vehicle_num: c_reg || '',
            // c_vehicle_engine_num: c_engine || '',
            // c_vehicle_VIN_code: c_vin || '',
            // c_truckbody_num: c_body_numb || '',
            // c_chassis_num: c_ch || '',
            // n_value: n_sum || '',
            // c_owners: c_owners || '',
            i_aidtype: i_aidtype || '', // get back
            ...oldData,
            isEdit: false,
          };
        }),
      };

    case AQUIRERE_ADD:
      return {
        ...state,
        cargo_acquirer: state.cargo_acquirer.map((item, i) => {
          if (i === action.payload.index) {
            return {
              ...item,
              acquirer: [...state.cargo_acquirer[action.payload.index].acquirer, action.payload],
            };
          }
          return item;
        }),
      };
    case CARGO_DELETE:
      return {
        ...state,
        cargo_acquirer: state.cargo_acquirer.filter((item, index) => index !== action.payload.index),
      };
    case CARGO_RECIPIENT_NAME_SELECT:
      return {
        ...state,
        cargoApplication: {
          ...state.cargoApplication,
          i_recipient: action.payload,
        },
      };

    case CARGO_DONOR_NAME:
      return {
        ...state,
        cargoApplication: {
          ...state.cargoApplication,
          donor_name: action.payload,
        },
      };
    case CARGO_DONOR_DETAILS:
      return {
        ...state,
        cargoApplication: {
          ...state.cargoApplication,
          c_details: action.payload,
        },
      };
    case CARGO_DONOR_COUNTRY_SELECT:
      return {
        ...state,
        cargoApplication: {
          ...state.cargoApplication,
          donor_country: action.payload,
        },
      };
    case CARGO_SENDING_COUNTRY_SELECT:
      return {
        ...state,
        cargoApplication: {
          ...state.cargoApplication,
          i_country: action.payload,
        },
      };
    case CARGO_AID_TYPE_SELECT:
      return {
        ...state,
        cargoApplication: {
          ...state.cargoApplication,
          i_aidform: action.payload,
        },
      };
    case CARGO_DONOR_REGNUMBER:
      return {
        ...state,
        cargoApplication: {
          ...state.cargoApplication,
          c_donor_regnumber: action.payload,
        },
      };
    case CARGO_HEAD_SURNAME:
      return {
        ...state,
        cargoApplication: {
          ...state.cargoApplication,
          c_head_surname: action.payload,
        },
      };
    case CARGO_HEAD_FIRSTNAME:
      return {
        ...state,
        cargoApplication: {
          ...state.cargoApplication,
          c_head_firstname: action.payload,
        },
      };
    case CARGO_HEAD_PATRONYMIC:
      return {
        ...state,
        cargoApplication: {
          ...state.cargoApplication,
          c_head_patronymic: action.payload,
        },
      };
    case CARGO_WRITTEN_PROPOSAL:
      return {
        ...state,
        cargoApplication: {
          ...state.cargoApplication,
          c_written_proposal: action.payload,
        },
      };
    case CARGO_INVOICE:
      return {
        ...state,
        cargoApplication: {
          ...state.cargoApplication,
          c_invoice: action.payload,
        },
      };
    case CARGO_PACKAGE_LETTER:
      return {
        ...state,
        cargoApplication: {
          ...state.cargoApplication,
          c_package_letter: action.payload,
        },
      };
    case CARGO_OTHER_ACCOMPANY_DOCS:
      return {
        ...state,
        cargoApplication: {
          ...state.cargoApplication,
          c_other_accompany_docs: action.payload,
        },
      };
    case CARGO_AGREEMENT_TO_RECEIVE:
      return {
        ...state,
        cargoApplication: {
          ...state.cargoApplication,
          agreement_to_receive: action.payload,
        },
      };
    case CARGO_L_PRIVATE:
      return {
        ...state,
        cargoApplication: {
          ...state.cargoApplication,
          l_private: action.payload,
        },
      };
    case CARGO_WEAR_PERCENTAGE:
      return {
        ...state,
        cargoApplication: {
          ...state.cargoApplication,
          c_wear_percentage: action.payload,
        },
      };
    case CARGO_WEIGHT:
      return {
        ...state,
        cargoApplication: {
          ...state.cargoApplication,
          c_weight: action.payload,
        },
      };
    case CARGO_EXPIRATION_TERM:
      return {
        ...state,
        cargoApplication: {
          ...state.cargoApplication,
          c_expiration_term: action.payload,
        },
      };
    case CARGO_EXPLOITATION_TERM:
      return {
        ...state,
        cargoApplication: {
          ...state.cargoApplication,
          c_exploitation_term: action.payload,
        },
      };
    case CARGO_PAIRS_NUM:
      return {
        ...state,
        cargoApplication: {
          ...state.cargoApplication,
          c_pairs_num: action.payload,
        },
      };
    case CARGO_ACQUIRER_TYPE_SELECT:
      return {
        ...state,
        cargoAquirer: {
          ...state.cargoAquirer,
          i_acquirertype: action.payload,
        },
      };
    case CARGO_ACQUIRER_MAIN_NAME:
      return {
        ...state,
        cargoAquirer: {
          ...state.cargoAquirer,
          c_orgname: action.payload,
        },
      };
    case CARGO_ACQUIRER_EDRPOU_CODE:
      return {
        ...state,
        cargoAquirer: {
          ...state.cargoAquirer,
          n_edrpou: action.payload,
        },
      };
    case CARGO_ACQUIRER_SURNAME:
      return {
        ...state,
        cargoAquirer: {
          ...state.cargoAquirer,
          c_surname: action.payload,
        },
      };
    case CARGO_ACQUIRER_NAME:
      return {
        ...state,
        cargoAquirer: {
          ...state.cargoAquirer,
          c_name: action.payload,
        },
      };
    case CARGO_ACQUIRER_LASTNAME:
      return {
        ...state,
        cargoAquirer: {
          ...state.cargoAquirer,
          c_patronymic: action.payload,
        },
      };
    case CARGO_ACQUIRER_SOCIAL_CATEGORY:
      return {
        ...state,
        cargoAquirer: {
          ...state.cargoAquirer,
          c_category: action.payload,
        },
      };
    case CARGO_ACQUIRER_PHONE:
      return {
        ...state,
        cargoAquirer: {
          ...state.cargoAquirer,
          n_phone: action.payload,
        },
      };
    case CARGO_ACQUIRER_REGION:
      return {
        ...state,
        cargoAquirer: {
          ...state.cargoAquirer,
          i_region: action.payload,
        },
      };
    case CARGO_ACQUIRER_LOCALITY:
      return {
        ...state,
        cargoAquirer: {
          ...state.cargoAquirer,
          i_locality: action.payload,
        },
      };
    case CARGO_ACQUIRER_LOCATION:
      return {
        ...state,
        cargoAquirer: {
          ...state.cargoAquirer,
          c_adress: action.payload,
        },
      };
    case CARGO_ACQUIRER_COUNT_PLAN:
      return {
        ...state,
        cargoAquirer: {
          ...state.cargoAquirer,
          n_countplan: action.payload,
        },
      };
    case CARGO_ACQUIRER_ACQUIRES_NUMBER:
      return {
        ...state,
        cargoAquirer: {
          ...state.cargoAquirer,
          n_acqcnt: action.payload,
        },
      };
    case CARGO_ACQUIRER_SUPPORTING_CIRCUMSTANCES:
      return {
        ...state,
        cargoAquirer: {
          ...state.cargoAquirer,
          c_reason: action.payload,
        },
      };
    case CARGO_ACQUIRER_WEIGHT:
      return {
        ...state,
        cargoAquirer: {
          ...state.cargoAquirer,
          p_weight: action.payload,
        },
      };

    case SUCCESS_CARGO_DOCUMENTS:
      return {
        ...state,
        documentsCargo: action.payload,
      };
    case APPLICATION_CARGO_ID:
      return {
        ...state,
        applicationCargoId: action.payload,
      };
    case DATA_TO_PDF:
      return {
        ...state,
        dataPDF: action.payload,
      };
    case ID_TO_PDF:
      return {
        ...state,
        IdPDF: action.payload,
      };
    case DATE_TO_PDF:
      return {
        ...state,
        datePDF: action.payload,
      };
    default:
      return state;
  }
};

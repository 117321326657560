import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const TransportDataSkeleton = () => (
  <Grid container direction="column">
    <Grid item>
      <Typography variant="subtitle2" gutterBottom>
        <Skeleton variant="text" width={200} />
      </Typography>
    </Grid>
    <Grid container item>
      <Grid container item direction="column" spacing={1}>
        <Grid item>
          <Typography variant="body2" color="textSecondary">
            <Skeleton variant="text" width={100} />
          </Typography>
          <Typography variant="body1" color="textPrimary">
            <Skeleton variant="text" width={150} />
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="body2" color="textSecondary">
            <Skeleton variant="text" width={100} />
          </Typography>
          <Typography variant="body1" color="textPrimary">
            <Skeleton variant="text" width={150} />
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textSecondary">
            <Skeleton variant="text" width={100} />
          </Typography>
          <Typography variant="body1" color="textPrimary">
            <Skeleton variant="text" width={150} />
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textSecondary">
            <Skeleton variant="text" width={100} />
          </Typography>
          <Typography variant="body1" color="textPrimary">
            <Skeleton variant="text" width={150} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default TransportDataSkeleton;

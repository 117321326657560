import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Profile.css';
import { Button, TextField } from '@material-ui/core';
import rules from '../../vibe/components/utilities/MainUtils/rules';
import { changePositionProfile, changeEmailProfile } from '../../redux/actions/auth.action';

class Profile extends Component {
  handleSubmit = (evt) => {
    evt.preventDefault();
  };

  request = () => {
    const profile = {
      id: this.props.id,
      c_position: this.props.c_position,
      c_email: this.props.c_email,
    };

    fetch('/aid/userupdate', {
      method: 'post',
      body: JSON.stringify(profile),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((error) => console.log(`ERROR${error}`));
  };

  render() {
    return (
      <form className="profile-form" onSubmit={this.handleSubmit}>
        <label className="profile-items">
          {this.props.surname} {this.props.name} {this.props.patronymic}
        </label>
        <label className="profile-items">{this.props.organization}</label>

        {/* <label className="profile-items">
          Email: */}
        <TextField
          label="Email:"
          className="profile-input"
          type="text"
          placeholder="Введіть адресу електронної пошти"
          value={this.props.c_email}
          name="c_email"
          onChange={(e) => {
            rules.email.test(e.target.value) && this.props.changeEmailProfile(e.target.value);
          }}
        />
        {/* </label> */}

        {/* <label className="profile-items">
          Посада: */}
        <TextField
          label=" Посада:"
          className="profile-input"
          type="text"
          placeholder="Введіть посаду"
          value={this.props.c_position}
          name="c_position"
          onChange={(e) => {
            const valuetoUpperCase = e.target.value.toUpperCase();
            rules.orgName.test(valuetoUpperCase) && this.props.changePositionProfile(valuetoUpperCase);
          }}
        />
        {/* </label> */}

        <Button color="primary" type="submit" onClick={this.request}>
          Зберегти
        </Button>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    name: state.auth.user.c_name,
    surname: state.auth.user.c_surname,
    patronymic: state.auth.user.c_patronymic,
    organization: state.auth.user.c_organization,
    id: state.auth.user.id,
    c_position: state.auth.user.c_position,
    c_email: state.auth.user.c_email,
  };
}

export default connect(mapStateToProps, { changePositionProfile, changeEmailProfile })(Profile);

import React, { useMemo, useState } from 'react';
import { withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Button, Box } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import Grid from '@material-ui/core/Grid';
import TableBody from '@material-ui/core/TableBody';
import Swal from 'sweetalert2';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TableContainer from '@material-ui/core/TableContainer';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import { StyledTableCell } from '../inventory/StyledTableCell';
import DistributionRow from './DistributionRow';
import {
  setDistributionInput,
  clearDistributionCurrent,
  removeDistributionItem,
  setDistributionCurrent,
  setDistributionSaver,
  setReportAidtype,
  setReportAcquirer,
  setDistributionCards,
} from '../../../../redux/actions/report.action';
import { saveReportDistribution, deleteReportDistribution, getDistribByAct } from '../../../../api/api';

import MenuRefer from './MenuRefer';
import MenuImport from './MenuImport';
import AddressDialog from './AddressDialog';
import popup from './reportHelpers/Popup.module.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    // minHeight: 400,
  },
  footerCell: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 400,
    backgroundColor: theme.palette.common.white,
  },
  input: {
    width: '100%',
  },
  addButton: {
    margin: '20px 30px 0 30px',
    alignItems: 'center',
    padding: '20px 20px',
    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',

    fontFamily: 'e-Ukraine Head',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#FFFFFF',
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
    },
  },
  pagination: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    width: '100%',
  },
  codeBtn: {
    backgroundColor: '#D5D8E0',
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    textAlign: 'center',
    padding: '14px 24px',
    borderRadius: '30px',
  },
  threeBtns: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  addBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '16px',
    },
  },
}));

const swalWithBootstrapButtons = Swal.mixin({
  buttonsStyling: false,
});

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
});

const Table2 = ({ updateBothTables, history }) => {
  const classes = useStyles();
  const [view, setView] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [currentIndex, setCurrentIndex] = React.useState(-1);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const i_cargo = useSelector((state) => state.cargo.cargoApplication.i_cargo);
  const i_recip = useSelector((state) => state.report.params.i_recip);
  const i_report = useSelector((state) => state.report.params.i_report);
  const curr_date = useSelector((state) => state.report.params.curr_date);
  const array = useSelector((state) => state.report.distribution.array);
  const current = useSelector((state) => state.report.distribution.current);
  const region = useSelector((state) => state.catalogs.region);
  const acquirer = useSelector((state) => state.report.acquirer);

  const dispatch = useDispatch();

  const handleCloseView = () => {
    setView(false);
  };

  const handleOpenView = () => {
    setView(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? <span>&uarr;</span> : <span>&darr;</span>;
    }
    return null;
  };

  const getValue = (obj, path) => {
    const keys = path.split('.');
    let value = obj;
    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys) {
      value = value[key];
    }
    if (path === 'pd_distribut') {
      const [day, month, year] = value.split('.').map(Number);
      return new Date(year, month - 1, day);
    }
    return value;
  };

  const sortedArray = useMemo(() => {
    if (sortConfig.key !== null) {
      return array.slice().sort((a, b) => {
        const valueA = getValue(a, sortConfig.key);
        const valueB = getValue(b, sortConfig.key);

        if (valueA < valueB) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return array;
  }, [array, sortConfig]);

  const handleInput = (event) => {
    const { value, name } = event.target;
    dispatch(setDistributionInput(name, value));
  };

  const handleSelect = (name) => (option) => {
    if (option) {
      dispatch(setDistributionInput(name, option));
    }
  };

  const dateToObject = (strDate) => {
    if (strDate) {
      const [day, month, year] = strDate.split('.');
      return new Date(+year, +month - 1, +day);
    }

    return null;
  };

  // datapicker
  const handleChangeDate = (dateObj) => {
    const dateForSet = dateObj ? format(dateObj, 'dd.MM.yyyy') : '';
    dispatch(setDistributionInput('pd_distribut', dateForSet));
  };

  const saveDistributionRow = (localCloseFunction) => {
    const {
      pi_id,
      pi_acq_type,
      //
      pc_orgname,
      //
      pn_edrpou,
      pi_aidtype_obj,
      pc_description,
      pn_weight,
      pn_count,
      pc_document,
      //
      pn_acqcnt,
      pd_distribut,
      //
      // pi_region_obj,
      pi_koatuu_obj,
      pi_koatuu,
      pc_address,
    } = current;

    const data = {
      // id
      pi_id,
      pi_cargo: i_cargo,
      // eslint-disable-next-line radix
      pi_recipient: parseInt(i_recip),
      // основні
      pi_acq_type,
      pd_rep: curr_date,
      pd_distribut,
      pc_document,
      //
      pn_edrpou: pi_acq_type === '1' ? pn_edrpou : null,
      pc_orgname: pi_acq_type === '2' ? 'Фізичні особи' : pc_orgname,
      // address
      pi_koatuu: pi_koatuu_obj ? pi_koatuu_obj?.value : pi_koatuu,
      pc_address,
      // товар
      pi_aid_type: pi_aidtype_obj?.id,
      pc_description,
      pn_weight,
      pn_count,
      pn_acqcnt: pi_acq_type === '2' ? pn_acqcnt : '',
    };

    saveReportDistribution(data)
      // eslint-disable-next-line no-unused-vars
      .then((res) => {
        Toast.fire({
          type: 'success',
          title: 'Дані збережено!',
        });
        updateBothTables();
        dispatch(clearDistributionCurrent());
        setCurrentIndex(-1);
        localCloseFunction();
      })
      // eslint-disable-next-line no-unused-vars
      .catch((err) => {});
  };

  const deleteDistributionRow = (id) => {
    swalWithBootstrapButtons
      .fire({
        customClass: {
          popup: popup['swal-popup'],
          container: popup['swal-container'],
          title: [popup['swal-title'], popup['swal-title-m1']],
          confirmButton: popup['swal-custom-btn'],
          cancelButton: [popup['swal-custom-btn'], popup['swal-custom-btn-lt']],
          actions: popup['swal-actions'],
        },
        title: 'Ви впевнені, що бажаєте видалити розподіл?',
        showConfirmButton: true,
        showCancelButton: true,
        focusConfirm: true,
        focusCancel: false,
        reverseButtons: true,
        confirmButtonText: 'ТАК, ВИДАЛИТИ',
        cancelButtonText: 'НІ, ПОВЕРНУТИСЯ',
      })
      .then((result) => {
        if (result.value) {
          if (id) {
            deleteReportDistribution(id)
              .then(() => {
                updateBothTables();
              })
              // eslint-disable-next-line no-unused-vars
              .catch((err) => {});
          } else {
            dispatch(removeDistributionItem(currentIndex));
          }

          dispatch(clearDistributionCurrent());
          setCurrentIndex(-1);
        }
      })
      .catch((err) => {});
  };

  const getDistributionData = async (someid) => {
    try {
      const found = array.find(({ pi_id }) => pi_id === someid);
      const res = await getDistribByAct({ pi_report: i_report, pi_act: found?.i_act });
      const { act, aid_type, distribution, acquirer: acquirerData } = res.data;
      const {
        i_act,
        i_acq_type,
        d_distribution,
        c_document,
        l_losses,
        n_edrpou,
        c_orgname,
        i_region,
        i_locality,
        c_address,
      } = act[0];

      const data = {
        pi_act: i_act,
        // act
        pi_acq_type: Number(i_acq_type).toString(),
        pd_distribut: d_distribution || '',
        pc_document: c_document || '',
        pl_losses: l_losses === 1 || l_losses === '1',
        edrpouOption: acquirer.find((item) => item.n_edrpou === n_edrpou) || null,
        pc_edrpou: n_edrpou || '',
        pc_orgname: i_acq_type === 2 ? 'Фізичні особи' : c_orgname,
        //  address
        pi_region_obj: region.find(({ value }) => value === i_region),
        pi_atu: i_locality || '',
        pi_atu_obj: null,
        c_locality_name: '',
        pc_address: c_address || '',
        // card
        aidtypeCatalogOption: null,
        pi_balance_obj: null,
        pi_balance: '',
        pc_description: '',
        pn_weight: '',
        pn_count: '',
        pn_acqcnt: '',
      };

      const distrArray = distribution || [];
      const acqurArray = acquirerData || [];
      await dispatch(setDistributionCurrent(data));
      await dispatch(setDistributionCards(distrArray));
      await dispatch(setReportAidtype(aid_type));
      await dispatch(setReportAcquirer(acqurArray));
      await dispatch(setDistributionSaver(true));
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  const gotoDistribution = async () => {
    dispatch(setDistributionSaver(false));
    dispatch(clearDistributionCurrent());
    try {
      const res = await getDistribByAct({ pi_report: i_report });
      const { aid_type, acquirer: acquirerData } = res.data;
      const acqurArray = acquirerData || [];
      dispatch(setReportAidtype([...aid_type]));
      dispatch(setReportAcquirer(acqurArray));
      // eslint-disable-next-line no-empty
    } catch (err) {}
    history.push('/report-distribution');
  };

  const gotoDistributionEdit = async (ind, someid) => {
    await dispatch(clearDistributionCurrent());
    setCurrentIndex(ind);
    await getDistributionData(someid);
    history.push('/report-distribution');
  };

  const sumWeight = array
    .reduce((accumulator, item) => {
      const { pn_weight } = item;
      const currentValue = pn_weight ? Number(pn_weight) : 0;
      return accumulator + currentValue;
    }, 0)
    .toFixed(3);

  const sumCount = array
    .reduce((accumulator, item) => {
      const { pn_count } = item;
      const currentValue = pn_count ? Number(pn_count) : 0;
      return accumulator + currentValue;
    }, 0)
    .toFixed(2);

  const sumAcqcnt = array
    .reduce((accumulator, item) => {
      const { pn_acqcnt } = item;
      const currentValue = pn_acqcnt ? Number(pn_acqcnt) : 0;
      return accumulator + currentValue;
    }, 0)
    .toFixed(2);

  return (
    <>
      <Grid container spacing={0}>
        <Grid container item justifyContent="flex-end" alignItems="center" spacing={0} style={{ margin: '16px 0' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button type="button" color="primary" variant="contained" onClick={() => gotoDistribution()}>
              Додати розподіл
            </Button>
          </div>
        </Grid>
        <Grid container item sx={12} spacing={0}>
          <TableContainer>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Номер за порядком</StyledTableCell>
                  <StyledTableCell onClick={() => handleSort('pc_orgname')}>
                    Набувачі {getSortIcon('pc_orgname')}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => handleSort('pn_edrpou')}>
                    Код ЄДРПОУ {getSortIcon('pn_edrpou')}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => handleSort('c_aid_descript')}>
                    Товар по коду ГД {getSortIcon('c_aid_descript')}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => handleSort('pc_description')}>
                    Найменування товарів {getSortIcon('pc_description')}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => handleSort('pn_weight')}>
                    Вага, кг/ Об'єм, л {getSortIcon('pn_weight')}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => handleSort('pn_count')}>
                    Кількість, шт. {getSortIcon('pn_count')}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => handleSort('pc_document')}>
                    Підтверджуючі документи {getSortIcon('pc_document')}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => handleSort('pn_acqcnt')}>
                    Кількість осіб {getSortIcon('pn_acqcnt')}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => handleSort('pd_distribut')}>
                    Дата розподілу {getSortIcon('pd_distribut')}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => handleSort('c_locality_name')}>
                    Адреса {getSortIcon('c_locality_name')}
                  </StyledTableCell>
                  <StyledTableCell>Функції</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                  <DistributionRow
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    index={index}
                    currentIndex={currentIndex}
                    item={item}
                    handleInput={handleInput}
                    handleSelect={handleSelect}
                    dateToObject={dateToObject}
                    handleChangeDate={handleChangeDate}
                    saveDistributionRow={saveDistributionRow}
                    deleteDistributionRow={deleteDistributionRow}
                    gotoDistributionEdit={gotoDistributionEdit}
                  />
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <StyledTableCell className={classes.footerCell}>
                    <Typography style={{ fontSize: 16, fontWeight: 400 }}>ВСЬОГО</Typography>
                  </StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>—</StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>—</StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>—</StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>—</StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>
                    <strong>{sumWeight}</strong>
                  </StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>
                    <strong>{sumCount}</strong>
                  </StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>—</StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>
                    <strong>{sumAcqcnt}</strong>
                  </StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>—</StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>—</StyledTableCell>
                  <StyledTableCell className={classes.footerCell}>—</StyledTableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <TablePagination
            classes={{ root: classes.pagination }}
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={sortedArray.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} з ${count}`}
            labelRowsPerPage="Рядків на сторінці:"
          />
        </Grid>
      </Grid>
      <AddressDialog view={view} handleClose={handleCloseView} />
    </>
  );
};

export default withRouter(Table2);

//packages
import React, { Component } from 'react';
import { Card, CardBody, CardFooter } from 'reactstrap';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import * as Feather from 'react-feather';
import BootstrapTooltip from '../../elements/MaterialBootstrap';
//assets
import isEmpty from '../../../vibe/components/utilities/MainUtils/is-empty';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { makeStyles, withStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

class ViewAllDocumensCargo extends Component {
  render() {
    return (
      <div>
        <Card>
          <CardBody>
            <section className="documentsAllCargo">
              <div>
                <h4 className="mb-4">Документи по гуманітарній допомозі:</h4>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Назва документу</StyledTableCell>
                      <StyledTableCell>Скан документу</StyledTableCell>
                      <StyledTableCell>Детальний опис документу</StyledTableCell>
                      <StyledTableCell>Номер і дата документу</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {!isEmpty(this.props.documents) && this.props.documents.length !== 0 ? (
                    <TableBody>
                      {this.props.documents.map((row, indexPlans) => (
                        <TableRow>
                          <StyledTableCell width="25%">
                            {/* {documentTypes2.find(({ value }) => value === row.n_doctype)?.label} */}
                            {row.doc_type_txt}
                          </StyledTableCell>
                          <StyledTableCell width="25%">
                            <a href={`/aid/file/${row.c_filename}`} rel="noopener noreferrer" target="_blank">
                              {row.c_filename}
                            </a>
                          </StyledTableCell>
                          <StyledTableCell width="25%">{row.c_docname}</StyledTableCell>
                          <StyledTableCell width="25%">{row.c_dt_numb}</StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <StyledTableCell width="30%"></StyledTableCell>
                        <StyledTableCell width="40%" style={{ fontSize: '25px', textAlign: 'center' }}>
                          Не додано жодного документу
                        </StyledTableCell>
                        <StyledTableCell width="15%"></StyledTableCell>
                        <StyledTableCell width="15%"></StyledTableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </div>
            </section>
          </CardBody>
          <CardFooter>
            <BootstrapTooltip title="вихід">
              <IconButton onClick={() => this.props.history.push('/view-delivery')}>
                <Feather.LogOut style={{ transform: 'scaleX(-1)' }} size="25" />
              </IconButton>
            </BootstrapTooltip>
          </CardFooter>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    recipient: state.recipient.recipient,
    documents: state.cargo.documentsCargo,
    cargoApplicationId: state.cargo.applicationCargoId,
  };
};
export default withRouter(connect(mapStateToProps)(ViewAllDocumensCargo));

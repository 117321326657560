import React from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { StyledTableCell, CustomTablePagination, EnhancedTableHead } from '../../elements/CustomTable';

const Soldiers = ({ soldiers }) => (
  <Grid container>
    <Grid item>
      <Typography variant="subtitle2" component="h2" gutterBottom>
        Військовослужбовці, які мають право на відомчу перереєстрацію
      </Typography>
    </Grid>
    <Grid item>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>№ п/п</StyledTableCell>
            <StyledTableCell>ПІБ військовослужбовця</StyledTableCell>
            <StyledTableCell>№ військового квитка</StyledTableCell>
            <StyledTableCell>E-mail військовослужбовця</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {soldiers.map((soldier, index) => (
            <TableRow key={index}>
              <StyledTableCell>{soldier.rownum}</StyledTableCell>
              <StyledTableCell>{soldier.name}</StyledTableCell>
              <StyledTableCell>{soldier.mrn}</StyledTableCell>
              <StyledTableCell>{soldier.email}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  </Grid>
);

export default Soldiers;

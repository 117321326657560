//packages
import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { Row, Col } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import BootstrapTooltip from '../../elements/MaterialBootstrap';
import { getMyMeetingInfo, deleteMeeting, finishMeeting } from '../../../api/api';
import { getApplicationInfo, commisionID, setCurrentCommission } from '../../../redux/actions/commissionCreate.action';
import { CardHeader, CardContent, Card, Button } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import ExcelExport from './ExcelExport';
import MakeZip from './MakeZip';
import nakaz from '../../../assets/nakaz/nakaz.zip';

import { getTakePDFSData } from '../../../redux/actions/addIDhtml.action';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function Child({
  commisionID,
  endMeeting,
  handleDeleteMeeting,
  onMouseEnter,
  onMouseLeave,
  text,
  isHovering,
  history,
  getApplicationInfo,
  setCurrentCommission,
  handleExcelAttachment,
  handlePDForder,
  getTakePDFSData,
}) {
  return (
    <TableRow
      hover={true}
      component="tr"
      dense="true"
      style={{
        minHeight: '20px',
        position: 'relative',
        cursor: 'pointer',
        zIndex: '1',
        boxShadow: 'inset 0 -1px 0 0 rgba(100,121,143,0.122)',
      }}
      onClick={(e) => {
        commisionID(text.id);
        setCurrentCommission(text);
        getTakePDFSData(text.id);
        history.push('/view-commission');
      }}
      key={text.id}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <StyledTableCell>{text.d_meeting}</StyledTableCell>
      <StyledTableCell>{text.c_name}</StyledTableCell>
      <StyledTableCell>{text.pib}</StyledTableCell>
      <StyledTableCell
        style={{
          padding: '0',
          //position: "absolute",
          //border: 'none',
          alignItems: 'center',
          flex: '0 0 auto',
          lineHeight: '20px',
          order: '1',
          emptyCells: 'show',
          fontSize: '.875rem',
          height: '50px',
          outline: 'none',
          verticalAlign: 'middle',
          whiteSpace: 'nowrap',
          position: 'relative',
          minWidth: '160px',
          width: '20%',
        }}
      >
        {isHovering && (
          <ul
            style={{
              display: 'flex',
              marginRight: '6px',
              padding: '0',
              position: 'absolute',
              top: '0%', //right:"5%"
              //float: "left"
            }}
          >
            <li className="bqX">
              {/* <UncontrolledTooltip 
              placement="top"
               
              target={`edit${text.cargo_id}`}>
                Редагувати
              </UncontrolledTooltip> */}
              <BootstrapTooltip title="Редагувати">
                <IconButton
                  disabled={text.i_state === 30 || text.i_state === 40 || text.i_state === 50}
                  id={`edit${text.cargo_id}`}
                  color="primary"
                  aria-label="Edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    getApplicationInfo(text.id);
                    history.push('/edit-сommission-meeting');
                  }}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </BootstrapTooltip>
            </li>
            <li className="bqX">
              {/* <UncontrolledTooltip placement="top" target={`delete${text.id}`}>
                Видалити
              </UncontrolledTooltip> */}
              <BootstrapTooltip title="Видалити">
                <IconButton
                  disabled={text.i_state !== 0}
                  id={`delete${text.id}`}
                  color="primary"
                  aria-label="Delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteMeeting(text.id);
                  }}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </BootstrapTooltip>
            </li>
            <li className="bqX">
              {/* <UncontrolledTooltip placement="top" target={`apply${text.id}`}>
                Закрити засідання
             </UncontrolledTooltip> */}
              <BootstrapTooltip title="Закрити засідання">
                <IconButton
                  disabled={text.i_state !== 30}
                  id={`apply${text.id}`}
                  color="primary"
                  aria-label="apply"
                  onClick={(e) => {
                    e.stopPropagation();
                    endMeeting(text.id);
                  }}
                >
                  <DoneIcon fontSize="inherit" />
                </IconButton>
              </BootstrapTooltip>
            </li>
            <li className="bqX">
              <MakeZip text={text} />
            </li>
            <li className="bqX">
              <ExcelExport text={text} type="nakaz" status="going" />
            </li>
          </ul>
        )}
      </StyledTableCell>
    </TableRow>
  );
}

function TablePgination({ data, handleChangePage, handleChangeRowsPerPage, rowsPerPage, page }) {
  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={data.length}
      rowsPerPage={rowsPerPage}
      page={page}
      backIconButtonProps={{
        'aria-label': 'previous page',
      }}
      nextIconButtonProps={{
        'aria-label': 'next page',
      }}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} з ${count}`}
      labelRowsPerPage="Рядків на сторінці:"
    />
  );
}
class RegisterСommissionPersonal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isMouseInside: false,
      isHovered: {},
      data: [],
      page: 0,
      rowsPerPage: 5,
    };
    this.init = this.init.bind(this);
    this.handleDeleteMeeting = this.handleDeleteMeeting.bind(this);
    this.endMeeting = this.endMeeting.bind(this);
  }

  handleMouseEnter = (index) => {
    this.setState((prevState) => {
      return { isHovered: { ...prevState.isHovered, [index]: true } };
    });
  };
  handleMouseLeave = (index) => {
    this.setState((prevState) => {
      return { isHovered: { ...prevState.isHovered, [index]: false } };
    });
  };

  addMeeting = () => {
    this.props.history.push(`/create-сommission-meeting`);
  };

  componentDidMount() {
    this.init();
  }

  async init() {
    try {
      const response = await getMyMeetingInfo(this.props.user.id);
      const sortByDate = response.data.sort((a, b) => {
        var aa = a.d_meeting.split('.').reverse().join(),
          bb = b.d_meeting.split('.').reverse().join();
        return aa > bb ? -1 : aa < bb ? 1 : 0;
      });
      this.setState({ data: sortByDate, visible: true, loading: false });
    } catch (error) {
      this.setState({ data: [], loading: false });
      // if (error.response.status === 400) {
      //   swalWithBootstrapButtons.fire({
      //       title: 'Помилка',
      //       text: error.response.data,
      //       type: 'error',
      //   })
      // } else {
      //   swalWithBootstrapButtons.fire({
      //       title: 'Помилка серверу',
      //       type: 'error',
      //   })
      // }
    }
  }

  handleDeleteMeeting(id) {
    swalWithBootstrapButtons
      .fire({
        text: 'Ви дійсно бажаєте видалити?',
        showCancelButton: true,
        confirmButtonText: 'Так, видалити',
        cancelButtonText: 'Відміна',
      })
      .then(async (result) => {
        if (result.value) {
          try {
            const response = await deleteMeeting(id);
            this.init();
          } catch (error) {
            swalWithBootstrapButtons.fire({
              title: 'Помилка',
              text: error.response.data,
              type: 'error',
            });
          }
        }
      });
  }

  endMeeting(id) {
    swalWithBootstrapButtons
      .fire({
        text: 'Ви дійсно бажаєте закінчити засідання?',
        showCancelButton: true,
        confirmButtonText: 'Так, закінчити',
        cancelButtonText: 'Відміна',
      })
      .then(async (result) => {
        if (result.value) {
          try {
            await finishMeeting(this.props.user.id, id);
            swalWithBootstrapButtons
              .fire({
                title: 'Засідання закрито успішно',
                text: 'З наказом та протоколом можна ознайомитись в перегляді детальної інформації по засіданню.',
                type: 'success',
              })
              .then((successResult) => {
                if (successResult.value) {
                  this.init();
                }
              });
          } catch (error) {
            swalWithBootstrapButtons.fire({
              title: 'Помилка',
              text: error.response.data,
              type: 'error',
            });
          }
        }
      });
  }

  getPDForder = () => {
    alert('pdf');
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  };

  render() {
    const emptyRows =
      this.state.rowsPerPage -
      Math.min(this.state.rowsPerPage, this.state.data.length - this.state.page * this.state.rowsPerPage);
    return (
      <Row>
        <Col xs={12}>
          <Card plain="true">
            <CardHeader
              action={
                <Route
                  render={({ history }) => (
                    <Button
                      variant="contained"
                      color="primary"
                      className="pull-right"
                      onClick={(e) => this.addMeeting()}
                    >
                      <i className="fa fa-plus mr-3"></i>Додати засідання
                    </Button>
                  )}
                />
              }
            />
            <CardContent>
              {/* <div className="table-responsive"> */}
              <Table style={{ fontSize: '12px' }} hover="true" size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Дата</StyledTableCell>
                    <StyledTableCell>Стан</StyledTableCell>
                    <StyledTableCell>Користувач</StyledTableCell>
                    <StyledTableCell>Функції</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.data
                    .slice(
                      this.state.page * this.state.rowsPerPage,
                      this.state.page * this.state.rowsPerPage + this.state.rowsPerPage
                    )
                    .map((item, index) => (
                      <Child
                        onMouseEnter={() => this.handleMouseEnter(index)}
                        onMouseLeave={() => this.handleMouseLeave(index)}
                        text={item}
                        isHovering={this.state.isHovered[index]}
                        key={index}
                        index={index}
                        history={this.props.history}
                        getApplicationInfo={this.props.getApplicationInfo}
                        handleDeleteMeeting={this.handleDeleteMeeting}
                        endMeeting={this.endMeeting}
                        getTakePDFSData={this.props.getTakePDFSData}
                        commisionID={this.props.commisionID}
                        setCurrentCommission={this.props.setCurrentCommission}
                        handlePDForder={this.getPDForder}
                        handleExcelAttachment={this.getExcelAttachment}
                      />
                    ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 48 * emptyRows }}>
                      <StyledTableCell colSpan={4} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePgination
                data={this.state.data}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
              />
            </CardContent>
            {/* <CardFooter> 
          <Route render={({ history}) => (
            <Button color="primary" className="pull-right" 
              onClick={e => this.addMeeting()}><i className="fa fa-plus mr-3"></i>Додати засідання
            </Button>
          )} />
          </CardFooter> */}
          </Card>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    recipients: state.recipient.recipients,
    loading: state.isFatching,
    errMessage: state.error,
    user: state.auth.user,
  };
};

RegisterСommissionPersonal.propTypes = {};

export default withRouter(
  connect(mapStateToProps, { getApplicationInfo, commisionID, setCurrentCommission, getTakePDFSData })(
    RegisterСommissionPersonal
  )
);

import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TableRecipientDocs from './TableRecipientDocs';
import TableMCargoDocs from './TableMCargoDocs';
import TableScanDocs from './TableScanDocs';
import TableReportDocs from './TableReportDocs';

export default function ModalReportPeriod({
  cargoView,
  recipientView,
  modal,
  openModal,
  docsR,
  docsC,
  scanView,
  scan,
  docsView,
  docsSelect,
}) {
  return (
    <Modal isOpen={modal} toggle={() => openModal()} className="modal-xl" zIndex="1500">
      <ModalHeader toggle={() => openModal()}>
        {recipientView && <h5>Документи по отримувачу</h5>}
        {cargoView && <h5>Документи по гуманітарній допомозі</h5>}
        {scanView && <h5>Документи по заяві</h5>}
        {docsView && <h5>Документи звітності</h5>}
      </ModalHeader>
      <ModalBody>
        <div className="table-responsive">
          {recipientView && <TableRecipientDocs docsR={docsR} />}
          {cargoView && <TableMCargoDocs docsC={docsC} />}
          {scanView && <TableScanDocs scan={scan} />}
          {docsView && <TableReportDocs docsSelect={docsSelect} />}
        </div>
      </ModalBody>
    </Modal>
  );
}

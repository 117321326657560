// packages
import React, { Component } from 'react';
import { Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux';

import Swal from 'sweetalert2';
import { IconButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Feather from 'react-feather';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Controls from './Controls';
import isEmpty from '../../../vibe/components/utilities/MainUtils/is-empty';
import { getOrderCargoDoc, getProtocolCargoDoc, setNewApplicationCargo, changeCargoRecipient } from '../../../api/api';
import { cargoReport } from '../../../redux/actions/cargo.action';
import ViewDocModal from '../Dialogs/ViewDocModal';
import BootstrapTooltip from '../../elements/MaterialBootstrap';

const styles = {
  fontWeight: {
    fontWeight: 800,
    paddingLeft: '10px',
  },
  onLine: {
    display: 'flex',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60vh',
  },
};

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});
const directoryParse = (arr, val) => {
  if (!isEmpty(arr)) {
    const dir = arr.filter(({ value }) => value === val);
    return dir.length > 0 ? dir[0].label : '';
  }
};

const mapStateToProps = (state) => ({
  cargo: state.cargo.cargoApplication,
  recipient: state.recipient.recipient,
  catalogs: state.catalogs,
  user: state.auth.user,
  loading: state.cargo.cargoLoading,
});

export default withRouter(
  connect(mapStateToProps, { cargoReport })(
    withStyles(styles)(
      class ViewDeliver extends Component {
        constructor(props) {
          super(props);
          this.state = {
            htmlDoc: '',
            modal: false,
          };
          this.viewProtocol = this.viewProtocol.bind(this);
          this.viewOrder = this.viewOrder.bind(this);
        }

        changeRecipientStatement = () => {
          changeCargoRecipient(this.props.user.id, this.props.cargo.i_cargo)
            .then((response) => {
              this.props.history.push('/сhange-recipient');
            })
            .catch((error) => {
              // if (error.response && error.response.status === 400) {
              //   swalWithBootstrapButtons.fire({
              //       title: 'Помилка',
              //       text: error.response.data,
              //       type: 'error',
              //   })
              // } else {
              //   swalWithBootstrapButtons.fire({
              //       title: 'Помилка серверу',
              //       type: 'error',
              //   })
              // }
            });
        };

        handleNewState = () => {
          const data = {
            p_id: this.props.cargo.i_cargo,
            application_type: 6,
            p_user_id: this.props.user.id,
          };
          setNewApplicationCargo(data)
            .then((response) => {
              this.props.history.push('/edit-cargo');
            })
            .catch((error) => {
              swalWithBootstrapButtons.fire({
                type: 'error',
                text: error.response.data,
              });
            });
        };

        fetchReports = () => {
          this.props.cargoReport(this.props.cargo.i_cargo);
        };

        async viewProtocol() {
          try {
            const response = await getProtocolCargoDoc(this.props.cargo.i_cargo);
            this.setState({ htmlDoc: response.data, modal: true });
          } catch (error) {}
        }

        async viewOrder() {
          try {
            const response = await getOrderCargoDoc(this.props.cargo.i_cargo);
            this.setState({ htmlDoc: response.data, modal: true });
          } catch (error) {}
        }

        toggle = () => {
          this.setState((prevState) => ({ modal: !prevState.modal, htmlDoc: '' }));
        };

        render() {
          const { classes } = this.props;
          const {
            loading,
            cargo: { l_private, i_cargo },
          } = this.props;
          return (
            <div>
              {loading && (
                <div className={classes.loading}>
                  <CircularProgress color="primary" size={50} />
                </div>
              )}
              {i_cargo && !loading && (
                <Card>
                  <CardHeader>
                    <Controls
                      handleNewState={this.handleNewState}
                      viewOrder={this.viewOrder}
                      viewProtocol={this.viewProtocol}
                      fetchReports={this.fetchReports}
                      changeRecipientStatement={this.changeRecipientStatement}
                    />
                    <Divider />
                  </CardHeader>

                  <CardBody>
                    {/* <p>№:</p> */}
                    {/* <p className={classes.fontWeight}>Форма допомоги: {this.props.cargo.aid_name}</p> */}
                    <p className={classes.onLine}>
                      Код гуманітарної допомоги: <b className={classes.fontWeight}>{this.props.cargo.i_cargo}</b>
                    </p>
                    {/* <p className={classes.fontWeight}>Форма допомоги: {directoryParse(this.props.catalogs.aidForm, this.props.cargo.i_aidform)}</p> */}
                    <p className={classes.onLine}>
                      Країна відправлення: <b className={classes.fontWeight}>{this.props.cargo.country_name}</b>
                    </p>
                    {/* <p className={classes.fontWeight}>Країна відправлення: {directoryParse(this.props.catalogs.country, this.props.cargo.i_country)}</p> */}
                    <p className={classes.onLine}>
                      Відправник:{' '}
                      <b className={classes.fontWeight}>
                        {this.props.cargo.donor_name ? this.props.cargo.donor_name : ''}
                      </b>
                    </p>
                    <p className={classes.onLine}>
                      Реквізити відправника:{' '}
                      <b className={classes.fontWeight}>
                        {this.props.cargo.c_details ? this.props.cargo.c_details : ''}
                      </b>
                    </p>
                    <p className={classes.onLine}>
                      Отримувач:{' '}
                      <b className={classes.fontWeight}>
                        {this.props.recipient.p_orgname ? this.props.recipient.p_orgname : ''}
                      </b>
                    </p>
                    <p className={classes.onLine}>
                      ЄДРПОУ:{' '}
                      <b className={classes.fontWeight}>
                        {this.props.recipient.p_edrpou ? this.props.recipient.p_edrpou : ''}
                      </b>
                    </p>
                    <p className={classes.onLine}>
                      Статус: <b className={classes.fontWeight}>{this.props.cargo.cargo_state_name}</b>
                    </p>
                    <p className={classes.onLine}>
                      Інформація відображається назагал:{' '}
                      <b className={classes.fontWeight}>{this.props.cargo.l_private === 'yes' ? 'Ні' : 'Так'}</b>
                    </p>
                  </CardBody>
                  <CardFooter>
                    <BootstrapTooltip title="вихід">
                      <IconButton onClick={() => this.props.history.goBack()}>
                        <Feather.LogOut style={{ transform: 'scaleX(-1)' }} size="25" />
                      </IconButton>
                    </BootstrapTooltip>
                  </CardFooter>
                </Card>
              )}
              {this.state.htmlDoc && (
                <ViewDocModal modal={this.state.modal} htmlDoc={this.state.htmlDoc} toggle={this.toggle} />
              )}
            </div>
          );
        }
      }
    )
  )
);

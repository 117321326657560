import Swal from 'sweetalert2';
import './DeliveryPopup.css';

const customSwal = Swal.mixin({
  customClass: {
    popup: 'swal-popup swal-popup-2',
    container: 'swal-container',
    title: 'swal-title swal-title-m2',
    content: 'swal-text',
    confirmButton: 'swal-custom-btn',
    actions: 'swal-actions',
  },
  buttonsStyling: false,
});

export default customSwal;

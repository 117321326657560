import React from 'react';
import { Paper, List, ListItem, ListItemText, Typography, Grid } from '@material-ui/core';

const AcquirerComponent = ({ acquirer, muType }) => (
  <Grid container direction="column">
    <Grid item>
      <Typography variant="subtitle2" component="h2" gutterBottom>
        Дані набувача
      </Typography>
    </Grid>
    <Grid container item>
      {acquirer.map((entity, index) => (
        <Grid container item direction="column" spacing={1}>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              ПІБ військовослужбовця
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {entity.name}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body2" color="textSecondary">
              Тип військового формування
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {entity.org_type || '-'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              Номер військового формування
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {entity.org_name || '-'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              E-mail військового формування
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {entity.org_email || '-'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              РНОКПО військовослужбовця
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {entity.rnokpp || '-'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              Номер військового квитка
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {entity.mrn || '-'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              E-mail військовослужбовця
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {entity.email || '-'}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  </Grid>
);

export default AcquirerComponent;

import * as Excel from 'exceljs/dist/exceljs.min';

const addTitles = (worksheet) => {
  const col_names = [
    'Категорія товару',
    'Найменування товарів, опис вантажу',
    'Одиниця виміру',
    'Вага, кг/ об’єм, л',
    'Кількість, шт.',
    'Ціна за одиницю, грн.',
    'Первісна (справедлива) вартість, грн.',
  ];

  // eslint-disable-next-line no-param-reassign
  worksheet.getRow(1).values = [...col_names];
  const titles = worksheet.getRow(1);
  titles.eachCell((cell) => {
    // eslint-disable-next-line no-param-reassign
    cell.border = {
      top: { style: 'thin', color: { argb: '00000000' } },
      left: { style: 'thin', color: { argb: '00000000' } },
      bottom: { style: 'thin', color: { argb: '00000000' } },
      right: { style: 'thin', color: { argb: '00000000' } },
    };
    // eslint-disable-next-line no-param-reassign
    cell.font = {
      name: 'Times New Roman',
      size: 10,
      bold: true,
    };
    // eslint-disable-next-line no-param-reassign
    cell.alignment = {
      vertical: 'top',
      horizontal: 'center',
      wrapText: true,
    };
  });
};

const addData = (worksheet, data) => {
  const col_keys = ['aidType', 'description', 'pi_unit', 'weight', 'count', 'price', 'value'];

  // eslint-disable-next-line no-param-reassign
  worksheet.columns = [
    { key: 'aidType', width: 20 },
    { key: 'description', width: 20 },
    { key: 'pi_unit', width: 20 },
    { key: 'weight', width: 20 },
    { key: 'count', width: 20 },
    { key: 'price', width: 20 },
    { key: 'value', width: 20 },
  ];

  worksheet.addRows([...data]);

  // eslint-disable-next-line no-restricted-syntax
  for (const key of col_keys) {
    const col = worksheet.getColumn(key);
    col.eachCell((cell, rowNumber) => {
      if (rowNumber >= 2) {
        // eslint-disable-next-line no-param-reassign
        cell.border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } },
        };
        // eslint-disable-next-line no-param-reassign
        cell.font = {
          name: 'Times New Roman',
          color: { argb: '00000000' },
          size: 10,
        };
        // eslint-disable-next-line no-param-reassign
        cell.alignment = {
          vertical: 'top',
          horizontal: 'left',
          wrapText: true,
        };
        // eslint-disable-next-line no-param-reassign
        cell.numFmt = '0';
      }
    });
  }
};

const exportExcel = async (data) => {
  const workbook = new Excel.Workbook();
  workbook.addWorksheet('Інвентарна відомість', {
    pageSetup: { paperSize: 9, orientation: 'landscape' },
  });

  const worksheet = workbook.getWorksheet('Інвентарна відомість');
  addTitles(worksheet);
  addData(worksheet, data);

  const uint8Array = await workbook.xlsx.writeBuffer();
  const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Inventory.xlsx';
  a.click();
  a.remove();
};

export default exportExcel;

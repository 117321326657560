// packages
import React from 'react';
import { Card, CardBody, CardFooter } from 'reactstrap';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import * as Feather from 'react-feather';
import BootstrapTooltip from '../../../elements/MaterialBootstrap';
// assets
import isEmpty from '../../../../vibe/components/utilities/MainUtils/is-empty';
import { selectRecipientItem, selectRecipientDocuments } from '../../../../redux/actions/recipient/recipient.selector';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { makeStyles, withStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

const ViewDocumentsAll = (props) => {
  const classes = useStyles();
  let documents = null;
  if (!isEmpty(props.documents) && props.documents.length !== 0) {
    documents = props.documents.map((item, i) => {
      return (
        <tr key={item.id}>
          <td>{i + 1}</td>
          <td>{item.c_docname}</td>
          <td>
            <a href={`/aid/file/${item.c_filename}`} target="blank">
              {item.c_filename}
            </a>
          </td>
        </tr>
      );
    });
  } else {
    documents = (
      <tr>
        <td colSpan="4" className="text-center">
          <h4>
            <strong>Не додано жодного документу</strong>
          </h4>
        </td>
      </tr>
    );
  }

  return (
    <div>
      <Card>
        <CardBody>
          <section className="documentsAllRecipient">
            <div>
              <h4 className="mb-4">Документи по отримувачу:</h4>
              {props.recipient.p_application_id && (
                <Table size="small" className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Назва документу</StyledTableCell>
                      <StyledTableCell>Скан документу</StyledTableCell>
                      <StyledTableCell>Детальний опис документу</StyledTableCell>
                      <StyledTableCell>Номер і дата документу</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {!isEmpty(props.documents) && props.documents.length !== 0 ? (
                    <TableBody>
                      {props.documents.map((row, indexPlans) => (
                        <TableRow>
                          <StyledTableCell width="25%">
                            {/* {documentTypes2.find(({ value }) => value === row.n_doctype)?.label} */}
                            {row.doc_type_txt}
                          </StyledTableCell>
                          <StyledTableCell width="25%">
                            <a href={`/aid/file/${row.c_filename}`} rel="noopener noreferrer" target="_blank">
                              {row.c_filename}
                            </a>
                          </StyledTableCell>
                          <StyledTableCell width="25%">{row.c_docname}</StyledTableCell>
                          <StyledTableCell width="25%">{row.c_dt_numb}</StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow>
                        <StyledTableCell width="30%"></StyledTableCell>
                        <StyledTableCell width="40%" style={{ fontSize: '25px', textAlign: 'center' }}>
                          Не додано жодного документу
                        </StyledTableCell>
                        <StyledTableCell width="15%"></StyledTableCell>
                        <StyledTableCell width="15%"></StyledTableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>

                //   <div className="table-responsive zebra">
                //     <table>
                //       <thead>
                //         <tr>
                //           <th style={{ width: '10%' }}>№ з/п</th>
                //           <th style={{ width: '60%' }}>Назва</th>
                //           <th style={{ width: '40%' }}>Скан документу</th>
                //         </tr>
                //       </thead>
                //       <tbody>{documents}</tbody>
                //     </table>
                //   </div>
              )}
            </div>
          </section>
        </CardBody>
        <CardFooter>
          <BootstrapTooltip title="вихід">
            <IconButton onClick={() => props.history.push('/view-recipient')}>
              <Feather.LogOut style={{ transform: 'scaleX(-1)' }} size="25" />
            </IconButton>
          </BootstrapTooltip>
        </CardFooter>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    // recipient: state.recipient.recipient,
    recipient: selectRecipientItem(state),
    documents: selectRecipientDocuments(state),
  };
};
export default withRouter(connect(mapStateToProps)(ViewDocumentsAll));

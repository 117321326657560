import React from 'react';
import { withRouter } from 'react-router-dom';
import DoneIcon from '@material-ui/icons/Done';
import IconButton from '@material-ui/core/IconButton';
//styles
import { connect } from 'react-redux';
import { setMeetingAction, typeMeeting } from '../../../redux/actions/commissionCreate.action';
import { recipientNew, recipientOld } from '../../../redux/actions/recipient.action';
import { cargoNew, cargoOld } from '../../../redux/actions/cargo.action';
import Swal from 'sweetalert2';
import BootstrapTooltip from '../../elements/MaterialBootstrap';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { numberOrderAction } from '../../../redux/actions/addIDhtml.action';
import Dialog from '@material-ui/core/Dialog';
import { saveDataOrd, openModalInfoPDF, endMeeting } from '../../../api/api';
import MaskedInput from 'react-text-mask';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import { Button } from 'reactstrap';

import 'react-table/react-table.css';
import { Input, TextField } from '@material-ui/core';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const mask = [/[0123]/, /[0-9]/, '.', /[01]/, /[0-9]/, '.', /[12]/, /[890]/, /[0-9]/, /[0-9]/];

class NumberOrderModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [{ text: 'text' }],
      typeMeeting: '',
      test_all_order: this.props.dataNumber[0].all_order,
    };
  }

  handleChangeNumbTest = (cellInfo, e, index) => {
    const number = /^[0-9 . -]*$/;
    const rows = [...this.props.dateOrder];
    const keys = Object.keys(cellInfo[index]);
    const pos = keys.indexOf('order_numb');
    if (number.test(e.target.value)) {
      rows[index][keys[pos]] = e.target.value;
      this.props.numberOrderAction(rows);
    } else {
      e.target.value = null;
    }
  };

  handleChangeDataTest = (cellInfo, e, index) => {
    const rows = [...this.props.dateOrder];
    const keys = Object.keys(cellInfo[index]);
    const pos = keys.indexOf('order_dt');
    rows[index][keys[pos]] = e.target.value;
    this.props.numberOrderAction(rows);
  };

  handleVoute = async (data, index) => {
    const dataSave = {
      meeting_id: data[index].meeting_id,
      order_date: data[index].order_dt,
      order_numb: data[index].order_numb,
      order_type: data[index].order_type,
    };
    const meeting_id = data[index].meeting_id;
    //   const order_dt = data[index].order_dt;
    //   const order_numb = data[index].order_numb;
    //   const order_type = data[index].order_type;
    try {

      //   const response = await saveDataOrd({meeting_id, order_dt, order_numb, order_type })
      const response = await saveDataOrd(dataSave);
      const resp = await openModalInfoPDF(meeting_id);
      this.setState({
        test_all_order: resp.data[0].all_order,
      });
      swalWithBootstrapButtons.fire({
        title: 'Рішення збережено!',
        type: 'success',
      });
      //     try {
      //       const success = await getMeetingList(this.props.id, this.props.user.id)
      //       this.props.setMeetingAction(success.data)
      //     } catch (error) {
      //       swalWithBootstrapButtons.fire({
      //         title: error.response.data,
      //         type: 'error',
      //       })
      //     }
    } catch (error) {
      swalWithBootstrapButtons.fire({
        title: error.response.data,
        type: 'error',
      });
    }
  };
  endMeeting(data) {
    swalWithBootstrapButtons
      .fire({
        text: 'Після завершення засідання не буде можливості редагувати дані. Завершити?',
        showCancelButton: true,
        confirmButtonText: 'Так',
        cancelButtonText: 'Відміна',
      })
      .then(async (result) => {
        if (result.value) {
          try {
            const id_meeting = data[0].meeting_id;
            const id_user = this.props.user.id;
            const response = await endMeeting(id_user, id_meeting);
            swalWithBootstrapButtons.fire({
              title: 'Засідання завершено!',
              type: 'success',
            });
            this.props.closeNumberModal();
            // try {
            //   const success = await getMeetingList(this.props.id, this.props.user.id)
            //   this.props.setMeetingAction(success.data)
            // } catch (error) {
            //   swalWithBootstrapButtons.fire({
            //     title: error.response.data,
            //     type: 'error',
            //   })
            // }
          } catch (error) {
            swalWithBootstrapButtons.fire({
              title: error.response.data,
              type: 'error',
            });
          }
        }
      });
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        scroll="paper"
        onClose={() => {
          this.props.closeNumberModal();
        }}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle
          onClose={() => {
            this.props.closeNumberModal();
          }}
        >
          Таблиця введення дати та номера наказів
          <br />
          <div className="table-responsive">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center"> № з/п</StyledTableCell>
                  <StyledTableCell align="center">Назва наказу</StyledTableCell>
                  <StyledTableCell align="center">Дата наказу</StyledTableCell>
                  <StyledTableCell align="center">Номер наказу</StyledTableCell>
                  {this.props.dateOrder[0].can_be_modif === 1 ? (
                    <StyledTableCell align="center">Зберегти</StyledTableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.dateOrder.map((row, index) => (
                  <TableRow component="th" scope="row" key={row.id}>
                    <StyledTableCell align="center">{row.rw}</StyledTableCell>
                    <StyledTableCell align="left">{row.order_name}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.can_be_modif === 1 ? (
                        <MaskedInput
                          className="form-control"
                          placeholder="dd.mm.yyyy"
                          autocomplete="off"
                          name="Дата"
                          mask={mask}
                          onChange={(e) => this.handleChangeDataTest(this.props.dateOrder, e, index)}
                          value={row.order_dt}
                        />
                      ) : (
                        <MaskedInput
                          className="form-control"
                          placeholder="dd.mm.yyyy"
                          autocomplete="off"
                          name="Дата"
                          disabled
                          mask={mask}
                          onChange={(e) => this.handleChangeDataTest(this.props.dateOrder, e, index)}
                          value={row.order_dt}
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.can_be_modif === 1 ? (
                        <Input
                          className="form-control"
                          placeholder="Номер"
                          autocomplete="off"
                          name="Номер"
                          value={row.order_numb}
                          onChange={(e) => this.handleChangeNumbTest(this.props.dateOrder, e, index)}
                        />
                      ) : (
                        <Input
                          className="form-control"
                          placeholder="Номер"
                          autocomplete="off"
                          name="Номер"
                          disabled
                          value={row.order_numb}
                          onChange={(e) => this.handleChangeNumbTest(this.props.dateOrder, e, index)}
                        />
                      )}
                    </StyledTableCell>
                    {row.can_be_modif === 1 ? (
                      <StyledTableCell align="center">
                        <BootstrapTooltip title="Зберегти">
                          <IconButton id="save_id" onClick={() => this.handleVoute(this.props.dateOrder, index)}>
                            {/* <Feather.Clipboard /> */}
                            <DoneIcon fontSize="inherit" />
                          </IconButton>
                        </BootstrapTooltip>
                      </StyledTableCell>
                    ) : null}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <DialogActions>
            <Grid container justifyContent="flex-start" alignItems="stretch" direction="row" spacing={3}>
              <Grid item>
                {this.state.test_all_order === 1 ? (
                  <Button variant="contained" color="primary" onClick={() => this.endMeeting(this.props.dateOrder)}>
                    Завершити засідання
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </DialogActions>
        </DialogTitle>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dateOrder: state.idHtml.numberOrder,
    user: state.auth.user,
  };
};

export default withRouter(connect(mapStateToProps, { numberOrderAction })(NumberOrderModal));

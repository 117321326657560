import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { saveUktzed } from '../../../../api/api';
import { fetchUktzed } from '../../../../redux/actions/catalog.action';
import { useSelector, connect } from 'react-redux';
import Edit from './Edit';
import TopSelect from './TopSelect';
import { ToastSuccess } from './toast';

const UktzedUpdate = ({ fetchUktzed }) => {
  const {
    auth: {
      user: { id: userId },
    },
    // catalogs: { uktzed },
  } = useSelector((state) => state);

  const [currentOption, setCurrentOption] = useState();
  const [editCode, setEditCode] = useState('');
  const [editDescr, setEditDescr] = useState('');

  const moveToEditor = (option) => {
    if (option) {
      const { label, value } = option;
      setEditCode(value);
      setEditDescr(label);
    }
  };

  const handleSelect = (option) => {
    setCurrentOption(option);
    moveToEditor(option);
  };

  const cleanValues = () => {
    setCurrentOption(null);
    setEditCode('');
    setEditDescr('');
  };

  const handleAdd = () => {
    const data = {
      modified: {
        userId: userId,
        records: [
          {
            label: editDescr,
            value: editCode,
          },
        ],
      },
    };

    saveUktzed(data)
      .then((res) => {
        ToastSuccess.fire({
          type: 'success',
          title: 'Зміни внесено!',
        });
        cleanValues();
      })
      .then(() => fetchUktzed())
      .catch((err) => console.log(err));
  };

  const handleDelete = () => {
    const data = {
      deleted: {
        userId: userId,
        records: [editCode],
      },
    };

    saveUktzed(data)
      .then((res) => {
        ToastSuccess.fire({
          type: 'success',
          title: 'Зміни внесено!',
        });
        cleanValues();
      })
      .then(() => fetchUktzed())
      .catch((err) => console.log(err));
  };

  const handleEdit = () => {
    const data = {
      modified: {
        userId: userId,
        records: [
          {
            label: editDescr,
            value: editCode,
          },
        ],
      },
    };

    saveUktzed(data)
      .then((res) => {
        ToastSuccess.fire({
          type: 'success',
          title: 'Зміни внесено!',
        });
      })
      .then(() => fetchUktzed())
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TopSelect currentOption={currentOption} handleSelect={handleSelect} />
        </Grid>
        <Grid item xs={12}>
          <Edit
            currentOption={currentOption}
            editCode={editCode}
            setEditCode={setEditCode}
            editDescr={editDescr}
            setEditDescr={setEditDescr}
            handleAdd={handleAdd}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            cleanValues={cleanValues}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default connect(null, { fetchUktzed })(UktzedUpdate);

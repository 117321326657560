import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Print from '@material-ui/icons/Print';
import CancelIcon from '@material-ui/icons/Cancel';
import { useDispatch, useSelector } from 'react-redux';
import TopBarProgress from 'react-topbar-progress-indicator';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { CardContent, Card } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import Swal from 'sweetalert2';
import { getInvestoryAction, getCargoByIdFull } from '../../../redux/actions/cargo.action';
import BootstrapTooltip from '../../elements/MaterialBootstrap';
import { getInventoryPDF, unsignInventory } from '../../../api/api';
import { history } from '../../../vibe/components/utilities/MainUtils/hist';

TopBarProgress.config({
  barColors: {
    0: '#2D5CA6',
    '1.0': '#2D5CA6',
  },
  shadowBlur: 5,
});

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function Child({ onMouseEnter, onMouseLeave, text, orederView, history, handleUnsignInventory }) {
  const dispatch = useDispatch();

  const goToInventory = async (cargoId) => {
    await dispatch(getCargoByIdFull(cargoId));
    history.push('/inventory');
  };

  return (
    <>
      <TableRow
        hover
        component="tr"
        dense="true"
        style={{
          minHeight: '20px',
          position: 'relative',
          cursor: 'pointer',
          zIndex: '1',
          boxShadow: 'inset 0 -1px 0 0 rgba(100,121,143,0.122)',
        }}
        onClick={(e) => {
          if (text.l_pdf === 0) {
            e.stopPropagation();
            goToInventory(text.cargo_id);
          } else if (text.l_pdf !== 0) {
            orederView(text.cargo_id);
          }
        }}
        key={text.cargo_id}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <StyledTableCell width="5%">{text.inventory_number}</StyledTableCell>
        <StyledTableCell>{text.donor_name}</StyledTableCell>
        <StyledTableCell>{text.d_customs}</StyledTableCell>
        <StyledTableCell>{text.weight}</StyledTableCell>
        <StyledTableCell width="10%">{text.quantity}</StyledTableCell>
        <StyledTableCell
          style={{
            padding: '0',
            alignItems: 'center',
            flex: '0 0 auto',
            lineHeight: '20px',
            order: '1',
            emptyCells: 'show',
            fontSize: '.875rem',
            height: '50px',
            outline: 'none',
            verticalAlign: 'middle',
            whiteSpace: 'nowrap',
            position: 'relative',
            minWidth: '160px',
            width: '10%',
          }}
        >
          <ul
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              padding: 0,
              margin: 0,
              listStyle: 'none',
            }}
          >
            {text.l_pdf === 0 && (
              <li>
                <BootstrapTooltip title="Редагувати">
                  <IconButton
                    id={`edit${text.cargo_id}`}
                    color="primary"
                    aria-label="Edit"
                    onClick={(e) => {
                      e.stopPropagation();
                      goToInventory(text.cargo_id);
                      // dispatch(getCargoByIdFull(text.cargo_id));
                      // history.push('/inventory');
                    }}
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                </BootstrapTooltip>
              </li>
            )}
            {text.l_pdf !== 0 && (
              <li>
                <BootstrapTooltip title="Друк">
                  <IconButton
                    id={`print${text.cargo_id}`}
                    color="primary"
                    aria-label="Print"
                    onClick={() => orederView(text.cargo_id)}
                  >
                    <Print fontSize="inherit" />
                  </IconButton>
                </BootstrapTooltip>
              </li>
            )}
            {text.l_pdf === 1 && (
              <li>
                <BootstrapTooltip title="Зняти підпис">
                  <IconButton
                    id={`unsign${text.cargo_id}`}
                    color="primary"
                    aria-label="unsign"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUnsignInventory(text.cargo_id);
                    }}
                  >
                    <CancelIcon fontSize="inherit" />
                  </IconButton>
                </BootstrapTooltip>
              </li>
            )}
          </ul>
        </StyledTableCell>
      </TableRow>
    </>
  );
}

function TablePgination({ investory, handleChangePage, handleChangeRowsPerPage, rowsPerPage, page }) {
  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={investory.length}
      rowsPerPage={rowsPerPage}
      page={page}
      backIconButtonProps={{
        'aria-label': 'previous page',
      }}
      nextIconButtonProps={{
        'aria-label': 'next page',
      }}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} з ${count}`}
      labelRowsPerPage="Рядків на сторінці:"
    />
  );
}

const InventoryDescription = () => {
  const [isHovered, setIsHovered] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const investory = useSelector((state) => state.cargo.investory);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInvestoryAction());
  }, [dispatch]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? <span>&uarr;</span> : <span>&darr;</span>;
    }
    return null;
  };

  const getValue = (obj, path) => {
    const keys = path.split('.');
    let value = obj;
    for (const key of keys) {
      value = value[key];
    }
    if (path === 'd_customs') {
      const [day, month, year] = value.split('.').map(Number);
      return new Date(year, month - 1, day);
    }
    return value;
  };

  const sortedArray = useMemo(() => {
    if (sortConfig.key !== null) {
      return investory.slice().sort((a, b) => {
        const valueA = getValue(a, sortConfig.key);
        const valueB = getValue(b, sortConfig.key);

        if (valueA < valueB) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return investory;
  }, [investory, sortConfig.direction, sortConfig.key]);

  const handleMouseEnter = (index) => {
    setIsHovered((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };

  const handleMouseLeave = (index) => {
    setIsHovered((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleUnsignInventory = async (cargoId) => {
    swalWithBootstrapButtons
      .fire({
        text: 'Підпис інвентаризації буде знято',
        showCancelButton: true,
        confirmButtonText: 'Так, зняти',
        cancelButtonText: 'Відміна',
      })
      .then(async (result) => {
        if (result.value) {
          try {
            const res = await unsignInventory(cargoId);
            if (res.status === 200) {
              dispatch(getInvestoryAction());
            }
          } catch (error) {
            console.log(error);
          }
        }
      });
  };

  const orederView = async (inventory) => {
    try {
      const response = await getInventoryPDF(inventory);
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.log(error);
    }
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, investory.length - page * rowsPerPage);

  return (
    <Row>
      <Col xs={12}>
        <Card>
          <CardContent>
            <div className="table-responsive">
              <Table style={{ fontSize: '12px' }} hover size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>№ в Реєстрі</StyledTableCell>
                    <StyledTableCell>Донор</StyledTableCell>
                    <StyledTableCell onClick={() => handleSort('d_customs')}>
                      Дата митного оформлення {getSortIcon('d_customs')}
                    </StyledTableCell>
                    <StyledTableCell>Вага, кг/об’єм, л</StyledTableCell>
                    <StyledTableCell>Кількість</StyledTableCell>
                    <StyledTableCell>Функції</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                    <Child
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                      text={item}
                      isHovering={isHovered[index]}
                      index={index}
                      orederView={orederView}
                      history={history}
                      handleUnsignInventory={handleUnsignInventory}
                    />
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 48 * emptyRows }}>
                      <StyledTableCell colSpan={8} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePgination
              investory={investory}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={rowsPerPage}
              page={page}
            />
          </CardContent>
        </Card>
      </Col>
    </Row>
  );
};

export default InventoryDescription;

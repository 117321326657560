import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { ReactComponent as Eye } from '../../../assets/svg/Eye.svg';
import { setRequestId } from '../../../redux/actions/competency.action';

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const CompetencyRow = ({ history, item }) => {
  const {
    catalogs: { requestType, requestStatus },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const { c_orgname, d_create, i_type, i_state, id, n_edrpou } = item;
  return (
    <TableRow>
      <StyledTableCell>{d_create}</StyledTableCell>
      <StyledTableCell>{requestType.find(({ value }) => value === i_type)?.label}</StyledTableCell>
      <StyledTableCell>{c_orgname}</StyledTableCell>
      <StyledTableCell>{n_edrpou}</StyledTableCell>
      <StyledTableCell>{requestStatus.find(({ value }) => value === i_state)?.label}</StyledTableCell>

      <StyledTableCell>
        <IconButton
          id="eyeview"
          aria-label="Gо to functions"
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setRequestId(id));
            history.push('/competency-view');
          }}
        >
          <Eye />
        </IconButton>
      </StyledTableCell>
    </TableRow>
  );
};

export default withRouter(CompetencyRow);

import React from 'react';
import ReactTable from 'react-table';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment';
import Dvr from '@material-ui/icons/Dvr';
import ViewList from '@material-ui/icons/ViewList';
import Close from '@material-ui/icons/Close';
// core components
import { getOrderDoc, getProtocolDoc } from '../../../../api/api';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import ViewDocModal from '../../Dialogs/ViewDocModal';
export default function CargoTable({ dataTable, history, loading }) {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    setData(
      dataTable.map((prop, key) => {
        return {
          id: prop.id,
          d_meeting: prop.d_meeting,
          c_name: prop.n_order,
          pib: prop.pib,
          order: (
            <div className="text-center">
              <IconButton
                onClick={() => {
                  orederView(prop.id);
                }}
                color="primary"
                className="like"
              >
                <ViewList />
              </IconButton>{' '}
            </div>
          ),
          protocol: (
            <div className="text-center">
              <IconButton
                onClick={() => {
                  protocolView(prop.id);
                }}
                color="primary"
                className="like"
              >
                <ViewList />
              </IconButton>{' '}
            </div>
          ),
        };
      })
    );
  }, [dataTable]);

  const [htmlDoc, getDoc] = React.useState('');
  const [modal, openModal] = React.useState(false);
  const [dialog, toggle] = React.useState(false);

  async function orederView(id) {
    try {
      const response = await getOrderDoc(id);
      getDoc(response.data);
      toggle(!dialog);
    } catch (error) {}
  }

  async function protocolView(id) {
    try {
      const response = await getProtocolDoc(id);
      getDoc(response.data);
      toggle(!dialog);
    } catch (error) {}
  }

  function toggleModal() {
    toggle(!dialog);
    getDoc('');
  }
  return (
    <div>
      <ReactTable
        data={data}
        style={{ fontSize: '0.85rem' }}
        loading={loading}
        defaultFilterMethod={(filter, row) => row[filter.id].includes(filter.value.toUpperCase())}
        columns={[
          {
            Header: '',

            columns: [
              {
                Header: 'Дата створення документів',
                accessor: 'd_meeting',
                width: '25%',
                sortMethod: (a, b) => {
                  if (a === b) {
                    return 0;
                  }
                  const aReverse = a.split('.').reverse().join('');
                  const bReverse = b.split('.').reverse().join('');
                  return aReverse > bReverse ? 1 : -1;
                },
              },

              {
                Header: '№ Наказу та Протоколу',
                accessor: 'c_name',
                width: '25%',
              },
            ],
          },

          {
            Header: 'Перегляд',
            headerStyle: { textAlign: 'center' },
            headerClassName: 'text-center',
            columns: [
              {
                Header: 'Наказ',
                //headerStyle: {textAlign: 'center'},
                headerClassName: 'text-center',
                accessor: 'order',
                width: '25%',
                sortable: false,
                filterable: false,
              },
              {
                Header: 'Протокол',
                //headerStyle: {textAlign: 'center'},
                headerClassName: 'text-center',
                accessor: 'protocol',
                width: '25%',
                sortable: false,
                filterable: false,
              },
            ],
          },
        ]}
        defaultPageSize={10}
        showPaginationTop
        showPaginationBottom={false}
        noDataText="Інформація відсутня!"
        className="-striped -highlight"
        previousText="Попередня"
        nextText="Наступна"
        pageText="Сторінка"
        ofText="з"
        rowsText="рядків"
        loadingText="Завантаження..."
      />
      {htmlDoc && <ViewDocModal modal={dialog} htmlDoc={htmlDoc} toggle={() => toggleModal()} />}
    </div>
  );
}

// packages
import React from 'react';
import { withRouter } from 'react-router-dom';

// reactstrap
import { UncontrolledTooltip } from 'reactstrap';

// MUI
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteRegisterIcon from '@material-ui/icons/NoSim';
import BlurLinearIcon from '@material-ui/icons/BlurLinear';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HistoryIcon from '@material-ui/icons/FileCopy';
import DocIcon from '@material-ui/icons/AssignmentInd';
import ReportIcon from '@material-ui/icons/ListAlt';
import LawIcon from '@material-ui/icons/AccountBalance';
import { withStyles } from '@material-ui/core/styles';

// sweetalert2
import Swal from 'sweetalert2';

// components
import { Typography } from '@material-ui/core';
import DialogHistoryView from './View/DialogHistoryView';
import DialogReport from './View/DialogReport';
import DialogAddApplicationDel from './View/DialogAddApplicationDel';
import DialogChandgeApplication from './View/DialogChandgeApplication';

// api
import {
  getOrderDoc,
  getProtocolDoc,
  setNewApplication,
  deleteRecipientFromRegisterApi,
  controlForDeleteRecipientFromRegister,
} from '../../../api/api';

import ViewDocModal from '../Dialogs/ViewDocModal';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary mr-3',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});
const styles = {
  root: {
    fontSize: '1rem',
    borderRadius: '0px',
  },
};

class Controls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openReport: false,
      openOrder: false,
      openProtocol: false,
      openHtml: false,
      htmlView: '',
      textResponse: '',
      openDel: false,
      openChandge: false,
    };
    this.handleOrder = this.handleOrder.bind(this);
    this.handleProtocol = this.handleProtocol.bind(this);
  }

  handleOpenConfirmation = () => {
    this.setState({
      openDel: true,
    });
  };

  handleCloseConfirmation = () => {
    this.setState({
      openDel: false,
    });
  };

  handleOpenChandgeApplication = () => {
    this.setState({
      openChandge: true,
    });
  };

  handleCloseChandgeApplication = () => {
    this.setState({
      openChandge: false,
    });
  };

  handleOpne = () => {
    this.setState((prevState) => ({ open: !prevState.open, openHtml: false }));
  };

  handleReportDialog = () => {
    this.setState((prevState) => ({ openReport: !prevState.openReport, openHtml: false }));
  };

  handleClose = () => {
    this.setState((prevState) => ({
      open: false,
      openOrder: false,
      openProtocol: false,
      htmlView: '',
      openHtml: false,
    }));
  };

  handleCloseReport = () => {
    this.setState({
      openReport: false,
      // openOrder: false,
      // openProtocol: false,
      htmlView: '',
      openHtml: false,
    });
  };

  toggle = () => {
    this.setState((prevState) => ({ openHtml: !prevState.openHtml, htmlView: '', textResponse: '' }));
  };

  async handleOrder() {
    // this.setState(prevState => ({textResponse: '', htmlView: '', openHtml: true}))
    try {
      const response = await getOrderDoc(this.props.id);
      this.setState((prevState) => ({ htmlView: response.data, openHtml: true }));
    } catch (error) {
      // do something with error

      this.setState((prevState) => ({ textResponse: 'Помилка сервера' }));
    }
  }

  async handleProtocol() {
    try {
      const response = await getProtocolDoc(this.props.id);
      this.setState((prevState) => ({ htmlView: response.data, openHtml: true }));
    } catch (error) {
      // do something with error
      this.setState((prevState) => ({ textResponse: 'Помилка сервера' }));
    }
  }

  handleNewState = () => {
    const data = {
      p_id: this.props.id,
      application_type: 3,
      p_user_id: this.props.user.id,
    };
    setNewApplication(data)
      .then((response) => {
        // this.props.recipientByIdNew(this.props.id);
        this.props.history.push('/edit-recipient');
        // setTimeout(() => {
        //   this.props.history.push('/edit-recipient');
        // }, 1000);
      })
      .catch((error) => {
        swalWithBootstrapButtons.fire({
          type: 'error',
          text: error.response.data,
        });
      });
  };

  handleDataChange = () => {
    this.props.history.push('/edit-recipient');
  };

  // handleControlDelete = () => {
  //   controlForDeleteRecipientFromRegister(this.props.user.id, this.props.id)
  //     .then((response) => {
  //       this.props.history.push(`/delete-recipient`);
  //     })
  //     .catch((error) => {});
  // };

  handleControlDelete = async () => {
    try {
      const data = {
        p_id: this.props.id,
        application_type: 4,
        p_user_id: this.props.user.id,
      };
      await controlForDeleteRecipientFromRegister(this.props.user.id, this.props.id);
      this.props.history.push(`/delete-recipient`);

      await deleteRecipientFromRegisterApi(data);
      // this.props.recipientByIdNew(this.props.id);

      // setTimeout(() => {
      // this.props.history.push('/edit-recipient');
      // }, 1000);
    } catch (error) {
      // swalWithBootstrapButtons.fire({
      //   type: 'error',
      //   text: error.response.data,
      // });
    }
  };

  render() {
    const {
      recipient: { p_application_state },
    } = this.props;
    return (
      <div>
        {p_application_state === 90 && (
          <div>
            <Typography color="secondary" style={{ marginBottom: 10, fontWeight: 800 }}>
              Отримувача виключено з реєстру
            </Typography>
          </div>
        )}
        <div>Функції:</div>

        <UncontrolledTooltip placement="top" target="changesRecipientCard">
          Внесення змін в дані отримувача
        </UncontrolledTooltip>
        <IconButton
          aria-label="Edit"
          id="changesRecipientCard"
          color="primary"
          // onClick={this.handleOpenChandgeApplication}
          onClick={this.handleNewState}
          disabled={p_application_state === 90}
        >
          <EditIcon />
        </IconButton>
        {/* {this.state.openChandge && (
          <DialogChandgeApplication
            open={this.state.openChandge}
            onClose={this.handleCloseChandgeApplication}
            id={this.props.id}
            userId={this.props.user.id}
            history={this.props.history}
            recipientByIdNew={this.props.recipientByIdNew}
          />
        )
        } */}

        <UncontrolledTooltip placement="top" target="deleteResRecipientCard">
          Заява на виключення з Реєстру
        </UncontrolledTooltip>
        <IconButton
          id="deleteResRecipientCard"
          aria-label="Exlude from register"
          color="primary"
          onClick={this.handleOpenConfirmation}
          disabled={p_application_state === 90}
        >
          <DeleteRegisterIcon />
        </IconButton>
        {this.state.openDel && (
          <DialogAddApplicationDel
            open={this.state.openDel}
            onClose={this.handleCloseConfirmation}
            id={this.props.id}
            userId={this.props.user.id}
            history={this.props.history}
          />
        )}

        {/* {this.props.recipient.result == 1 && (
          <>
            <UncontrolledTooltip placement="top" target="inputData">
              Внесення документів
            </UncontrolledTooltip>
            <IconButton id="inputData" aria-label="Input docs" color="secondary" onClick={this.handleDataChange}>
              <AddCircleOutlineIcon />
            </IconButton>
          </>
        )} */}

        <div>Перегляд детальної інформації:</div>

        <UncontrolledTooltip placement="top" target="historyViewRecipientCard">
          Історія
        </UncontrolledTooltip>
        <IconButton
          id="historyViewRecipientCard"
          aria-label="Сhange order"
          color="primary"
          onClick={this.handleOpne}
          // onClick={() => {
          //   this.props.history.push(`/application-history/${0}`)
          //  }}
        >
          <HistoryIcon />
        </IconButton>

        {/* <UncontrolledTooltip 
          placement="top" 
          target="orderRecipientCard">
          Наказ
        </UncontrolledTooltip>
          <IconButton 
          id="orderRecipientCard"
          aria-label="order" 
          color="primary" 
          // onClick={this.handleOrder}>
          onClick={() => {
            this.props.history.push(`/recipients-order`);
          }}
          >
            <LawIcon />
          </IconButton> */}

        {/* <UncontrolledTooltip placement="top" target="protocolRecipientCard">
          Наказ та Протокол
        </UncontrolledTooltip>
        <IconButton
          id="protocolRecipientCard"
          aria-label="protocol"
          color="primary"
          onClick={() => {
            this.props.history.push(`/recipients-protokol`);
          }}
        >
          <ReportIcon />
        </IconButton> */}

        <UncontrolledTooltip placement="top" target="documentRecipientCard">
          Документи по отримувачу
        </UncontrolledTooltip>
        <IconButton
          id="documentRecipientCard"
          aria-label="document"
          color="primary"
          onClick={() => {
            this.props.history.push(`/view-all-documents-recipient`);
          }}
        >
          <DocIcon />
        </IconButton>

        <UncontrolledTooltip placement="top" target="reportRecipient">
          Звіти
        </UncontrolledTooltip>
        <IconButton id="reportRecipient" aria-label="report" color="primary" onClick={this.handleReportDialog}>
          <BlurLinearIcon />
        </IconButton>

        <DialogHistoryView
          // eslint-disable-next-line react/destructuring-assignment
          open={this.state.open}
          handleClose={this.handleClose}
          // eslint-disable-next-line react/destructuring-assignment
          id={this.props.id}
          // eslint-disable-next-line react/destructuring-assignment
          rescipientAppHistory={this.props.rescipientAppHistory}
        />

        <DialogReport
          // eslint-disable-next-line react/destructuring-assignment
          open={this.state.openReport}
          handleClose={this.handleCloseReport}
          // eslint-disable-next-line react/destructuring-assignment
          id={this.props.id}
          // eslint-disable-next-line react/destructuring-assignment
        />
        {/* <DialogHtmlView
          openHtml={this.state.openHtml}
          openOrder={this.state.openOrder}
          openProtocol={this.state.openProtocol}
          handleClose={this.handleClose}
          htmlView={this.state.htmlView}
          textResponse={this.state.textResponse}
        /> */}
        {this.state.htmlView && (
          <ViewDocModal modal={this.state.openHtml} htmlDoc={this.state.htmlView} toggle={this.toggle} />
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Controls));

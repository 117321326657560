import React from 'react';
import ReactTable from 'react-table';
// @material-ui/core components
// import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
// import Assignment from '@material-ui/icons/Assignment';
// import Dvr from '@material-ui/icons/Dvr';
import ViewList from '@material-ui/icons/ViewList';
// import Close from '@material-ui/icons/Close';
// core components
// import matchSorter, { rankings } from 'match-sorter';
// import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

export default function CargoTable({ dataTable, history, loading }) {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    setData(
      dataTable.map((prop) => {
        return {
          id: prop.id,
          cargo_number: prop.cargo_number,
          i_aidform: prop.i_aidform,
          i_country: prop.i_country,
          donor_name: prop.donor_name,
          p_orgname: prop.p_orgname,
          p_edrpou: prop.p_edrpou,
          p_date: prop.p_date,
          actions: (
            <div className="actions-right">
              <IconButton
                onClick={() => {
                  history.push(`/cargo-info/${prop.i_cargo}/view`);
                }}
                color="primary"
                className="like"
              >
                <ViewList />
              </IconButton>{' '}
            </div>
          ),
        };
      })
    );
  }, [dataTable]);
  return (
    <div>
      <ReactTable
        data={data}
        style={{ fontSize: '0.85rem' }}
        filterable
        loading={loading}
        defaultFilterMethod={(filter, row) => row[filter.id].toString().includes(filter.value.toUpperCase())}
        columns={[
          {
            Header: '№ отримувача в реєстрі',
            accessor: 'cargo_number',
          },
          {
            Header: (
              <div>
                Форма <br />
                допомоги
              </div>
            ),
            accessor: 'i_aidform',
          },

          {
            Header: (
              <div>
                Країна
                <br />
                відправлення
              </div>
            ),
            accessor: 'i_country',
            filterMethod: (filter, rows) => rows[filter.id].includes(filter.value.toUpperCase()),
          },
          {
            Header: 'Донор',
            accessor: 'donor_name',
          },
          {
            Header: 'Отримувач',
            accessor: 'p_orgname',
          },
          {
            Header: 'ЄДРПОУ',
            accessor: 'p_edrpou',
          },

          {
            Header: (
              <div>
                Дата
                <br />
                рішення
              </div>
            ),
            accessor: 'p_date',
            sortMethod: (a, b) => {
              if (a === b) {
                return 0;
              }
              const aReverse = a.split('.').reverse().join('');
              const bReverse = b.split('.').reverse().join('');
              return aReverse > bReverse ? 1 : -1;
            },
          },
          {
            Header: 'Перегляд',
            accessor: 'actions',
            sortable: false,
            filterable: false,
          },
        ]}
        defaultPageSize={10}
        showPaginationTop
        showPaginationBottom={false}
        loadingText="Завантаження..."
        noDataText="Інформація відсутня!"
        className="-striped -highlight"
        previousText="Попередня"
        nextText="Наступна"
        pageText="Сторінка"
        ofText="з"
        rowsText="рядків"
      />
    </div>
  );
}

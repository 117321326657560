import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import { UncontrolledTooltip } from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import { setCargoRegister } from '../../../redux/actions/cargo.action';
import { ReactComponent as Eye } from '../../../assets/svg/Eye.svg';
import { getComparator, stableSort } from '../RegisterRecipients/sortFunctions';
import { useStylesTable } from '../RegisterRecipients/TableStyles';
import { StyledTableCell, CustomTablePagination, EnhancedTableHead } from '../../elements/CustomTable';
import CustomLoader from '../../../vibe/components/CustomLoader/CustomLoader';

const headCells = [
  { label: '№ отримувача в реєстрі', numeric: false, disablePadding: false, id: 'recip_number' },
  { label: 'Код вантажу', numeric: false, disablePadding: false, id: 'i_cargo' },
  { label: 'Дата реєстрації вантажу', numeric: false, disablePadding: false, id: 'reg_date' },
  { label: 'Дата митного оформлення', numeric: false, disablePadding: false, id: 'customs_date' },
  { label: 'Статус', numeric: false, disablePadding: false, id: 'state' },
  { label: 'Країна відправлення', numeric: false, disablePadding: false, id: 'country' },
  { label: 'Донор', numeric: false, disablePadding: false, id: 'donor_name' },
  { label: 'Користувач', numeric: false, disablePadding: false, id: 'c_name' },
  { label: 'Перегляд', numeric: false, disablePadding: false, id: 'i_cargo' },
];

const RegisterTableFullScreen = ({ loading, getCargoByIdFull, history, getCargoByID, classes, handleSearch }) => {
  const {
    cargo: { cargoRegister },
  } = useSelector((state) => state);

  const { p_page, p_rows, order, orderBy, total_rows, dataTable } = cargoRegister;

  const dispatch = useDispatch();

  useEffect(() => {
    if (loading === true) {
      dispatch(setCargoRegister({ ...cargoRegister, p_page }));
    }
  }, [loading, p_page]);

  const handleChangePage = async (event, newPage) => {
    const page = newPage + 1;

    await dispatch(setCargoRegister({ ...cargoRegister, p_page: page }));
    await handleSearch(event);
  };

  const handleChangeRowsPerPage = async (event) => {
    await dispatch(setCargoRegister({ ...cargoRegister, p_page: 1, p_rows: +event.target.value }));
    await handleSearch(event);
  };

  // sorting
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';

    dispatch(setCargoRegister({ ...cargoRegister, order: isAsc ? 'desc' : 'asc', orderBy: property }));
  };

  // data
  const emptyRows = p_rows - Math.min(p_rows, dataTable.length - p_page * p_rows);

  console.log('dataTable', dataTable);

  return (
    <>
      <div className="table-responsive bg-white">
        <Table style={{ fontSize: '12px' }} hover="true" size="small">
          <EnhancedTableHead
            classes={{}}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <TableBody>
            {!loading && dataTable.length > 0 ? (
              stableSort(dataTable, getComparator(order, orderBy)).map((item, index) => {
                const { recip_number, i_cargo, reg_date, customs_date, state, country, donor_name, c_name } = item;
                return (
                  <TableRow key={i_cargo}>
                    <StyledTableCell>{recip_number}</StyledTableCell>
                    <StyledTableCell>{i_cargo}</StyledTableCell>
                    <StyledTableCell>{reg_date}</StyledTableCell>
                    <StyledTableCell>{customs_date || '-'}</StyledTableCell>
                    <StyledTableCell>{state}</StyledTableCell>
                    <StyledTableCell>{country}</StyledTableCell>
                    <StyledTableCell>{donor_name}</StyledTableCell>
                    <StyledTableCell>{c_name}</StyledTableCell>
                    <StyledTableCell>
                      <UncontrolledTooltip placement="top" target={`eyeview${index}`}>
                        Перегляд
                      </UncontrolledTooltip>
                      <IconButton
                        id={`eyeview${index}`}
                        aria-label="view"
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          getCargoByIdFull(i_cargo);
                          history.push(`/view-delivery`);
                        }}
                      >
                        <Eye />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                );
              })
            ) : (
              <CustomLoader isLoading={loading} />
            )}
          </TableBody>
        </Table>
      </div>
      <CustomTablePagination
        totalRaws={total_rows}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={p_rows}
        page={p_page - 1}
      />
    </>
  );
};

export default withStyles(useStylesTable)(RegisterTableFullScreen);

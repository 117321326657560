import { 
  SELECT_APPLICATIONS,
  SUCCESS_APPLICATIONS,
  CHECK_APPLICATION,
  TABLE_CHANGE,
  SELECT_CLEAR,
  COMMISSION_SET,
  COMMISSION_CLEAR,
  REQUEST_APPLICATIONS,
  ERROR_APPLICATIONS_DOCUMENTS,
  SUCCESS_CARGO_APPLICATIONS,
  COMMISION_ID,
  SET_MEETING,
  TYPE_MEETING
} from '../actions/action-types';

const initialState = {
сommissions:[],
currentCommission:{},
tabledata:[],
selectedApplicationsForCommission:[],
allSelectedApplications: false,
id: 0,
meeting: [],
type: ''
};


export default (state=initialState, action) => {
    switch (action.type) {
      case TYPE_MEETING:
        return {
          ...state,
          type: action.payload
        }
      case SET_MEETING:
        return {
          ...state,
          meeting: action.payload
        }
      case COMMISION_ID:
        return {
          ...state,
          id: action.payload
        }
      case SELECT_APPLICATIONS://push id to array
                return {
                  ...state,
                  selectedApplicationsForCommission:  action.payload
                  };
      case SUCCESS_APPLICATIONS: //get data from server
                return {
                  ...state,
                  tabledata: action.payload
                  };
      case CHECK_APPLICATION://true when all application isChecked
                return {
                  ...state,
                  allSelectedApplications: action.payload
                  };
      case TABLE_CHANGE://
                return {
                  ...state,
                  tabledata: action.payload
                  };   
      case SELECT_CLEAR:
                return {
                  ...state,
                  selectedApplicationsForCommission:  []
                  };    
      case COMMISSION_SET:
          return {
            ...state,
            currentCommission: action.payload
            };   
      case SUCCESS_CARGO_APPLICATIONS:
        return {
            ...state,
            tabledata: action.payload
        }; 
      case COMMISSION_CLEAR:
          return {
            ...state,
            currentCommission: initialState.currentCommission
        };
      default:
        return state
    }
  }

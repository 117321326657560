import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DialogApprovalChange from './DialogApprovalChange';
import isEmpty from '../../../../vibe/components/utilities/MainUtils/is-empty';
import Select from 'react-select';
//styles
import { customTheme, customStyles } from '../../../../customStyles/reactSelect';
import IconButton from '@material-ui/core/IconButton';
// docs for recipient
import DocumentsLoadingRecipients from '../../RegisterRecipients/RegistrationFormsRecipient/DocumentsLoadingRecipients';
//redux
import { selectRecipientDocuments } from '../../../../redux/actions/recipient/recipient.selector';
import { getRecipientsForSelect } from '../../../../redux/actions/recipient.action';
import {
  getRecipientDocuments,
  deleteRecipientDocument,
  saveRecipientDocument,
} from '../../../../redux/actions/recipient.action';
import { createErrorMessageSelector } from '../../../../redux/actions/selectors';
//swal
import Swal from 'sweetalert2';

import BootstrapTooltip from '../../../elements/MaterialBootstrap';
import * as Feather from 'react-feather';
import { Button } from '@material-ui/core';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const errorSelector = createErrorMessageSelector(['RECIPIENT_SAVE']);

class СhangeRecipient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      newRecipient: '',
    };
  }
  handleOpenConfirmation = () => {
    if (this.state.newRecipient) {
      this.setState({
        open: true,
      });
    } else {
      swalWithBootstrapButtons.fire({
        text: 'Оберіть нового отримувача',
        type: 'error',
      });
    }
  };

  handleCloseConfirmation = () => {
    this.setState({
      open: false,
    });
  };

  filterOptions = (candidate, input) => {
    if (candidate.data.label.toLowerCase().includes(input.toLowerCase())) {
      return true;
      // eslint-disable-next-line no-else-return
    } else if (`${candidate.data.erNumber}`.startsWith(`ЄР${input}`)) {
      return true;
      // eslint-disable-next-line no-else-return
    } else if (`${candidate.data.edrpou}`.startsWith(input)) {
      return true;
    } else {
      return false;
    }
  };

  handleSelectChange = (name) => (select, { action }) => {
    let objDelete = {};
    objDelete[name] = '';
    switch (action) {
      case 'select-option':
        let obj = {};
        obj[name] = select;
        this.setState(obj);
        break;
      case 'clear':
        this.setState(objDelete);
        break;
    }
  };

  componentDidMount() {
    this.props.getRecipientsForSelect(this.props.user.id);
  }

  render() {
    return (
      <section className="deleteRecipient ">
        <DocumentsLoadingRecipients
          getRecipientDocuments={this.props.getRecipientDocuments}
          deleteRecipientDocument={this.props.deleteRecipientDocument}
          saveRecipientDocument={this.props.saveRecipientDocument}
          documents={this.props.documents}
          recipient={this.props.recipient}
          loading={this.props.loading}
          error={this.props.errMessage}
          user={this.props.user}
          currentRecipientId={this.props.currentRecipientId}
          applicationId={this.props.applicationId}
          catalog={this.props.catalog}
        />
        {this.props.recipient.id && (
          <div>
            <h4 className="text-center mt-4">Заява</h4>
            <div className="ml-4 mt-4">
              {' '}
              Прошу змінити отримувача{' '}
              <strong>{!isEmpty(this.props.recipient.p_orgname) && this.props.recipient.p_orgname} </strong> визнаної
              гуманітарної допомоги до подання звітів про фактичне розподілення.
            </div>

            <div className="col-12 col-sm-12 col-md-6 col-xl-4 mb-2">
              <label htmlFor="">Новий отримувач:</label>
              <Select
                value={this.state.newRecipient}
                getOptionLabel={({ label, edrpou, erNumber }) => (
                  <div>
                    <span style={{ color: '#999' }}>{`${erNumber} `}</span>
                    <span>{label}</span> <span style={{ color: '#999', fontSize: '12px' }}>ЄДРПОУ:</span>
                    <span style={{ color: '#999' }}>{edrpou}</span>
                  </div>
                )}
                onChange={this.handleSelectChange('newRecipient')}
                options={this.props.selectRecipient}
                styles={customStyles}
                placeholder=""
                noOptionsMessage={() => 'помилка серверу'}
                menuPortalTarget={document.body}
                theme={customTheme}
                isSearchable={true}
                isClearable={true}
                filterOption={this.filterOptions}
              />
            </div>

            <div className="text-center">
              <Button
                color="primary"
                //className="btn btn-sm btn-primary ml-4 mt-4"
                onClick={this.handleOpenConfirmation}
                // disabled={this.state.newRecipient ? false:true}
              >
                Подати заяву
              </Button>
            </div>

            {this.state.open && (
              <DialogApprovalChange
                open={this.state.open}
                onClose={this.handleCloseConfirmation}
                cargo={this.props.cargo}
                userId={this.props.userId}
                newRecipient={this.state.newRecipient}
                isSearchable={true}
              />
            )}
          </div>
        )}
        {this.props.recipient.id == '' && <h4>Оберіть заяву в пункті меню "Реєстр".</h4>}

        {/* <button className="btn btn-sm btn-primary ml-4 mt-4" onClick={() => this.props.history.push('/register')}>
          Вихід
        </button> */}
        <BootstrapTooltip title="вихід">
          <IconButton onClick={() => this.props.history.push('/register')}>
            <Feather.LogOut style={{ transform: 'scaleX(-1)' }} size="25" />
          </IconButton>
        </BootstrapTooltip>
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    recipient: state.recipient.recipient,
    cargo: state.cargo.cargoApplication,
    userId: state.auth.user.id,
    selectRecipient: state.recipient.selectRecipient,
    user: state.auth.user,
    // new fields
    documents: selectRecipientDocuments(state),
    catalog: state.catalogs,
    error: errorSelector(state),
    loading: state.isFatching,
    user: state.auth.user,
    applicationId: state.recipient.tabsFlag.applicationSaveId,
    applicationSaveId: state.recipient.tabsFlag.applicationSaveId,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    getRecipientsForSelect,
    getRecipientDocuments,
    deleteRecipientDocument,
    saveRecipientDocument,
  })(СhangeRecipient)
);

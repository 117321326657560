import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';

import startInventoryExcel from './startInventoryExcel';

const InventoryExcel = () => {
  const {
    inventory: { array },
  } = useSelector((state) => state);

  const prepareData = (data) => {
    return data.map((item) => {
      const { aidTypeObj, description, runitObj, weight, count, price, value } = item;

      return {
        aidType: aidTypeObj?.label,
        description,
        pi_unit: runitObj?.label,
        weight,
        count,
        price,
        value,
      };
    });
  };

  const downloadExcel = () => {
    const data = array ? prepareData(array) : [];
    startInventoryExcel(data);
  };

  return (
    <>
      <Button
        type="button"
        variant="contained"
        color="secondary"
        // className={classes.button_add}
        onClick={downloadExcel}
        disabled={array && array.length === 0}
      >
        Excel
      </Button>
    </>
  );
};

export default InventoryExcel;

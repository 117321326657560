//packages
import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux';
import ControlsCommission from './ControlsCommission';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fontWeight: {
    fontWeight: 800,
  },
}));

const CommissionView = (props) => {
  const classes = useStyles();
  return (
    <div>
      <Card>
        <CardHeader>
          <ControlsCommission id={props.id} text={props.commission} />
          <Divider />
        </CardHeader>

        <CardBody>
          {/* <div className="row">
              <div className="col-md-4"> */}
          <p className={classes.fontWeight}>Дата засідання: {props.commission.d_meeting} </p>
          <p className={classes.fontWeight}>Стан: {props.commission.c_name} </p>
          <p className={classes.fontWeight}>Користувач: {props.commission.pib}</p>
          {/* </div>
            </div> */}
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  commission: state.commissionCreate.currentCommission,
  id: state.commissionCreate.id,
});

export default connect(mapStateToProps)(CommissionView);

// packages
import React, { Component } from 'react';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import ReactSVG from 'react-svg';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withRouter } from 'react-router';
import Select from 'react-select';
import { connect } from 'react-redux';
import { Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import rules from '../../../../vibe/components/utilities/MainUtils/rules';
import { getCargoDocuments, saveCargoDocuments, deleteCargoDocument } from '../../../../redux/actions/cargo.action';

// for select docs

import { customTheme, customStyles } from '../../../../customStyles/reactSelect';

// assets
import uploadTable from '../../../../assets/images/download.svg';
import { Loader } from '../../../../vibe';
// import isEmpty from '../../../../vibe/components/utilities/MainUtils/is-empty';
import { createLoadingSelector, createErrorMessageSelector, clearErrors } from '../../../../redux/actions/selectors';
import { getOrderTable, getOrderTypes, deleteScan, saveScan } from '../../../../api/api';

class OrderUploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: '',
      fileSize: '',
      submitted: false,
      orderTypes: [],
      orderType: {},
      table: [],
      loading: false,
    };
    this.fileInput = React.createRef();
  }

  componentDidMount() {
    const { id } = this.props;

    getOrderTypes(id).then((res) => {
      this.setState({
        orderTypes: res.data.map(({ id, c_name }) => ({
          value: id,
          label: c_name,
        })),
      });
    });
    getOrderTable(id)
      .then((result) => {
        this.setState({ table: result.data });
      })
      .catch((err) => console.log(err));
  }

  handleSimpleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  // for document select
  handleSelectChange = (e, value) => {
    if (value) {
      this.setState({
        orderType: value,
      });
    } else {
      this.setState({
        orderType: {},
      });
    }
  };

  handleChangeFileInput(e) {
    this.setState({ fileName: '' });
    e.target.files[0] &&
      this.setState({
        fileName: e.target.files[0].name,
        fileSize: e.target.files[0].size,
      });
  }

  submitDocument = (e) => {
    e.preventDefault();

    const { id } = this.props;
    const { userId } = this.props;
    const { value } = this.state.orderType;

    this.setState({ submitted: true });
    if (this.fileInput.current.files[0] && this.state.orderType.value) {
      this.setState({ submitted: false });
      const { type } = this.fileInput.current.files[0];

      const data = new FormData();
      data.append('file', this.fileInput.current.files[0]);
      data.append('scanId', 0);
      data.append('meetingId', id);
      data.append('doctype', value || 0);
      data.append('extension', type);
      data.append('userId', userId);

      this.saveDocument(data, id);
    } else if (!this.fileInput.current.files) {
      this.setState({ validationText: 'Ви не додали файл' });
    }
  };

  saveDocument = async (data, meetingId) => {
    try {
      await saveScan(data);
      await this.updateTable(meetingId);
      this.fileInput.current.value = '';
      this.setState({
        fileName: '',
        fileSize: '',
      });
    } catch (err) {
      console.log(err);
    }
  };

  updateTable = async (meetingId) => {
    try {
      const updated = await getOrderTable(meetingId);
      this.setState({
        table: updated.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  deleteDocument = async (scanId, userId, meetingId) => {
    const data = new FormData();
    data.append('scanId', scanId);
    data.append('userId', userId);

    try {
      await deleteScan(data);
      await this.updateTable(meetingId);
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { userId, id: meetingId } = this.props;
    const { submitted, validationText } = this.state;
    let documents = null;
    const { table } = this.state;

    if (table.length > 0) {
      documents = table.map((item, i) => {
        return (
          <tr key={item.id}>
            <td>{item.rw}</td>
            <td>{item.scan_type}</td>
            <td>{item.d_order}</td>
            <td>{item.n_order}</td>
            <td>
              <a href={`/aid/file/${item.c_filename}`} target="blank">
                {item.c_filename}
              </a>
            </td>
            <td>
              <Button
                // color="primary"
                variant="outlined"
                type="button"
                onClick={() => this.deleteDocument(item.id, userId, meetingId)}
              >
                Видалити
              </Button>
            </td>
          </tr>
        );
      });
    } else {
      documents = (
        <tr>
          <td colSpan="6" className="text-center">
            <h4>
              <strong>Не додано жодного документу</strong>
            </h4>
          </td>
        </tr>
      );
    }

    return (
      <div>
        <Card>
          <CardBody>
            <section className="documentsLoadingForm">
              <form onSubmit={this.submitDocument}>
                <div className="row">
                  <div className="col-12 col-sm-12  col-md-6 col-xl-6 mb-6">
                    <UncontrolledTooltip placement="top" target="selectOrderType">
                      Оберіть тип документа зі списку.
                    </UncontrolledTooltip>
                    {/* <label htmlFor="">Виберіть тип документу</label> */}
                    {/* <Select
                      id="selectOrderType"
                      value={this.state.orderTypes.find(
                        ({ value }) => Number(value) === Number(this.state.orderType.value)
                      )}
                      onChange={this.handleSelectChange}
                      options={this.state.orderTypes}
                      styles={customStyles}
                      isLoading={this.props.loading.CATALOG}
                      placeholder=""
                      noOptionsMessage={() => 'помилка серверу'}
                      menuPortalTarget={document.body}
                      theme={customTheme}
                    /> */}
                    <Autocomplete
                      value={this.state.orderType}
                      // defaulValue={this.state.p_recipienttype}
                      onChange={this.handleSelectChange}
                      autoHighlight
                      getOptionSelected={(option) => option.value === this.state.orderType.value}
                      getOptionLabel={(option) => option.label}
                      options={this.state.orderTypes}
                      loading={this.props.loading.CATALOG}
                      renderInput={(params) => <TextField {...params} label="Виберіть тип документу" />}
                    />
                  </div>
                  <div className="col-12">
                    <div style={{ margin: '10px 0' }}>
                      <label htmlFor="file-upload-cargo" className="custom-file-upload btn100 mr-3 ">
                        <div className="wrapper">
                          <div className="wrapper-svg">
                            <ReactSVG
                              svgstyle={{
                                display: 'inline-block',
                                height: 10,
                              }}
                              src={uploadTable}
                            />
                          </div>

                          <div className="label-text"> ОБЕРІТЬ ФАЙЛ</div>
                        </div>
                      </label>
                      <input
                        id="file-upload-cargo"
                        className="upload"
                        type="file"
                        accept=".jpeg,.jpg,.png,.pdf"
                        onChange={this.handleChangeFileInput.bind(this)}
                        ref={this.fileInput}
                      />
                      {submitted && !this.fileInput.current.files[0] && (
                        <FormHelperText error id="name-error-text">
                          Поле обов'язкове
                        </FormHelperText>
                      )}
                    </div>
                  </div>
                  <div className="ml-3 mt-2"> {this.state.fileName}</div>

                  <div className="col-12 mt-3 mb-4">
                    <div className="text-warning" style={{ fontWeight: 800 }}>
                      Увага! Розмір документу для завантаження не повинен перевищувати 10 Мб
                    </div>
                    {this.state.fileSize > parseInt(100000000) && (
                      <div className="text-danger">Завантаження неможливе! Розмір файла перевищує 10 мб</div>
                    )}
                    <Button type="submit" color="primary" disabled={this.state.fileSize > parseInt(100000000)}>
                      Зберегти
                    </Button>
                  </div>
                </div>
              </form>
              {this.props.loading.CARGO_DOCUMENTS_SAVE && <Loader type="spin" />}
              <div className="table-responsive zebra">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '5%' }}>№ з/п</th>
                      <th style={{ width: '20%' }}>Назва</th>
                      <th style={{ width: '20%' }}>Дата документу</th>
                      <th style={{ width: '20%' }}>Номер документу</th>
                      <th style={{ width: '20%' }}>Скан документу</th>
                      <th style={{ width: '15%' }}>Керувати</th>
                    </tr>
                  </thead>
                  <tbody>{documents}</tbody>
                </table>
              </div>
            </section>
          </CardBody>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  documents: state.cargo.documentsCargo,
  loading: state.isFatching,
  errMessage: state.error,
  applicationCargoId: state.cargo.applicationCargoId,
  cargo: state.cargo.cargoApplication,
});

export default withRouter(
  connect(mapStateToProps, {
    getCargoDocuments,
    saveCargoDocuments,
    deleteCargoDocument,
    createLoadingSelector,
    createErrorMessageSelector,
    clearErrors,
  })(OrderUploadForm)
);

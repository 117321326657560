import {
  FETCH_ACCESS_START,
  FETCH_ACCESS_SUCCESS,
  FETCH_ACCESS_FAILURE,
  FETCH_SWITCH_ACCESS_START,
  FETCH_SWITCH_ACCESS_SUCCESS,
  FETCH_SWITCH_ACCESS_FAILURE,
} from '../actions/action-types';

const initialState = {
  value: 0,
  isLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACCESS_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_ACCESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        value: action.payload,
      };
    case FETCH_ACCESS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case FETCH_SWITCH_ACCESS_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_SWITCH_ACCESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_SWITCH_ACCESS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import BootstrapTooltip from '../../../../views/elements/MaterialBootstrap';
import { Card, CardContent, CardActions, Button, TextField } from '@material-ui/core';
import 'react-datepicker/dist/react-datepicker.css';
import uk from 'date-fns/locale/uk';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import { fetchOrderSearch } from '../../../../redux/actions/order.action';
import OrderTable from './OrderTable';

const OrderSearch = ({ fetchOrderSearch }) => {
  const [date, setDate] = useState('');
  const [number, setNumber] = useState('');

  const handleDate = (date) => {
    setDate(date);
  };
  const handleNum = (event) => {
    setNumber(event.target.value);
  };

  const doOrderSearch = () => {
    fetchOrderSearch({
      date: date ? format(date, 'dd.MM.yyyy') : '',
      numberOrder: number,
      p_type: null,
    });
  };

  return (
    <div>
      <Card>
        <CardContent>
          <div className="row justify-content-center">
            <div className="col-12 col-sm-6  col-md-4 col-xl-3 mt-2">
              {/* <label htmlFor="date">Дата</label> */}
              <DatePicker
                id="date"
                className="datePicker"
                selected={date}
                onChange={handleDate}
                locale={uk}
                maxDate={new Date()}
                dateFormat="dd.MM.yyyy"
                icon={<i className="cal-icon" />}
                autoComplete="off"
                customInput={<TextField label="Дата" />}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-xl-3 mt-md-2">
              {/* <label htmlFor="">Номер</label> */}
              <BootstrapTooltip title="Введіть номер наказу" placement="bottom-start">
                <TextField
                  label="Номер"
                  value={number}
                  onChange={handleNum}
                  id="number"
                  name="number"
                  type="text"
                  autocomplete="off"
                />
              </BootstrapTooltip>
            </div>
          </div>
        </CardContent>
        <CardActions>
          <Button
            type="button"
            onClick={doOrderSearch}
            variant="contained"
            // color="primary"
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <i className="fa fa-search mr-1" /> Пошук
          </Button>
        </CardActions>
      </Card>
      <div>
        <OrderTable />
      </div>
    </div>
  );
};

export default connect(null, { fetchOrderSearch })(OrderSearch);

import isEmpty from '../../vibe/components/utilities/MainUtils/is-empty';

import { SET_CURRENT_USER, CHANGE_POSITION_PROFILE, CHANGE_EMAIL_PROFILE } from '../actions/action-types';

const initialState = {
  isAuthenticated: false,
  user: {},
  userMenu: {
    
    bottom: [
    {
      name: 'Інструкція',
      url: '/manual',
      icon: 'Info',
      external: false,
    },
  ],}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_POSITION_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          c_position: action.payload
        }
      }

      case CHANGE_EMAIL_PROFILE:
          return {
            ...state,
            user: {
              ...state.user,
              c_email: action.payload
            }
          }

    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
        userMenu: {
          
          top: action.userMenu,
          ...initialState.userMenu,
        }
      };
    default:
      return state;
  }
}

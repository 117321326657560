import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Footer from './Footer';
import telegram from '../../../assets/images/telegram.png';
import facebook from '../../../assets/images/facebook.png';

const useStyles = makeStyles((theme) => ({
  footer: {
    // height: '15vh',
    backgroundColor: '#141414',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 24px',
    color: '#FFFFFF',
    fontSize: 14,
    fontFamily: 'diaHead',
    // position: 'absolute',
    // bottom: '0',
    // left: '0',

    // marginTop: '50px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 5px',
      flexWrap: 'wrap',
    },
  },
  footerContact: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      padding: '5px 0',
    },
  },
  footerContactText: {
    fontSize: '15px',
    margin: '3px',
    fontFamily: 'diaHead',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
      margin: '0px',
    },
  },
  socialIcons: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      paddingBottom: '10px',
    },
  },
}));

export default function FooterBase() {
  const classes = useStyles();

  return (
    <Footer>
      <div className={classes.footer}>
        <div className={classes.footerContact}>
          <p className={classes.footerContactText}>© 2023 Мінсоцполітики</p>
          <p className={classes.footerContactText}>
            Розробник:
            <a target="blank" href="https://www.ioc.gov.ua">
              &nbsp;ДП ІОЦ Мінсоцполітики
            </a>
          </p>
          <p className={classes.footerContactText}>(044) 494-19-10</p>
          <p className={classes.footerContactText}>callcenter@ioc.gov.ua</p>
        </div>
        <div className={classes.socialIcons}>
          <Typography style={{ fontSize: '15px' }}>Слідкуй за нами тут:</Typography>
          <a href="https://m.facebook.com/MLSP.gov.ua">
            <img className="icon" alt="Facebook logo" src={facebook} />{' '}
          </a>
          <a href="https://t.me/MinSocUA">
            <img className="icon" alt="Telegram logo" src={telegram} />{' '}
          </a>
        </div>
      </div>
    </Footer>
  );
}

/* eslint-disable consistent-return */
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Alert from '@material-ui/lab/Alert';
import { Button, IconButton, Input, Grid } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import rules from '../../../../vibe/components/utilities/MainUtils/rules';
import add from '../../../../assets/images/add.png';
import InfoPopover from './InfoPopover';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textAlign: 'center',
  },
  body: {
    fontSize: 14,
    textAlign: 'center',
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  button_save: {
    alignItems: 'center',
    padding: '20px 20px',
    gap: '4px',

    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',
    margin: 'auto',

    fontFamily: 'diaHead',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#FFFFFF',

    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
  },
  button_loading_img: {
    marginLeft: '15px',
  },
}));

const Table1 = ({ tab, setTab, saved, setSaved, showInfo }) => {
  const classes = useStyles();

  const [edited, setEdited] = React.useState(false);

  React.useEffect(() => {
    if (saved) setEdited(false);
  }, [saved]);

  React.useEffect(() => {
    if (edited) setSaved(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited]);

  const addRow = () => {
    const newTab = [...tab];
    newTab.push({
      i_car_id: null,
      i_driver_id: null,
      c_licence_plate: '',
      c_brand: '',
      c_name: '',
      c_surname: '',
      c_patronymic: '',
      c_passport: '',
      b_deleted: 0,
    });
    setTab([...newTab]);
    setEdited(true);
  };

  const deleteRow = (index) => {
    if (tab[index].i_car_id === null && tab[index].i_driver_id === null) {
      setTab([...tab.slice(0, index), ...tab.slice(index + 1)]);
    } else {
      const newTab = [...tab];
      newTab[index].b_deleted = 1;
      setTab([...newTab]);
      setEdited(true);
    }
  };

  const handleCellInput = (event, index) => {
    const { name, value } = event.target;
    const newTab = [...tab];
    if (name === 'c_licence_plate') {
      const valuetoUpperCase = value.toUpperCase();
      // eslint-disable-next-line no-unused-expressions
      if (rules.plate.test(valuetoUpperCase)) newTab[index][name] = valuetoUpperCase;
    } else {
      newTab[index][name] = value;
    }
    setTab([...newTab]);
    setEdited(true);
  };

  const notDeletedRows = tab
    .map((item, index) => ({ ...item, coreIndex: index }))
    // eslint-disable-next-line radix
    .filter((item) => parseInt(item.b_deleted) === 0 || item.b_deleted === null);

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid item>
          <Button variant="contained" color="primary" type="button" className={classes.button_save} onClick={addRow}>
            {/* <Icon className="fa fa-plus mr-1" /> */}
            Додати запис
            <img className={classes.button_loading_img} src={add} />
          </Button>
        </Grid>
        {showInfo && (
          <Grid item>
            <InfoPopover />
          </Grid>
        )}
      </Grid>
      {edited && (
        <div className="col-4 offset-4">
          <Alert severity="warning">Незбережені дані</Alert>
        </div>
      )}
      <div className="row mt-2">
        <div className="col-12">
          <div className="table-responsive">
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Номер</StyledTableCell>
                  <StyledTableCell>Марка</StyledTableCell>
                  <StyledTableCell>Прізвище</StyledTableCell>
                  <StyledTableCell>Ім&apos;я</StyledTableCell>
                  <StyledTableCell>По батькові</StyledTableCell>
                  <StyledTableCell>Паспорт</StyledTableCell>
                  <StyledTableCell>Видалення</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notDeletedRows.length > 0 ? (
                  notDeletedRows.map((item, index) => {
                    const { coreIndex } = item;
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <TableRow key={index}>
                        <StyledTableCell width="13%">
                          <Input
                            type="text"
                            name="c_licence_plate"
                            value={item.c_licence_plate}
                            onChange={(event) => handleCellInput(event, coreIndex)}
                          />
                        </StyledTableCell>
                        <StyledTableCell width="13%">
                          <Input
                            type="text"
                            name="c_brand"
                            value={item.c_brand}
                            onChange={(event) => handleCellInput(event, coreIndex)}
                          />
                        </StyledTableCell>
                        <StyledTableCell width="13%">
                          <Input
                            type="text"
                            name="c_surname"
                            value={item.c_surname}
                            onChange={(event) => handleCellInput(event, coreIndex)}
                          />
                        </StyledTableCell>
                        <StyledTableCell width="13%">
                          <Input
                            type="text"
                            name="c_name"
                            value={item.c_name}
                            onChange={(event) => handleCellInput(event, coreIndex)}
                          />
                        </StyledTableCell>
                        <StyledTableCell width="13%">
                          <Input
                            type="text"
                            name="c_patronymic"
                            value={item.c_patronymic}
                            onChange={(event) => handleCellInput(event, coreIndex)}
                          />
                        </StyledTableCell>
                        <StyledTableCell width="13%">
                          <Input
                            type="text"
                            name="c_passport"
                            value={item.c_passport}
                            onChange={(event) => handleCellInput(event, coreIndex)}
                            inputProps={{ maxlength: 20 }}
                          />
                        </StyledTableCell>
                        <StyledTableCell width="9%">
                          <IconButton size="small" color="primary" onClick={() => deleteRow(coreIndex)}>
                            <i className="fa fa-trash-o" />
                          </IconButton>
                        </StyledTableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <>
                    <TableRow>
                      <StyledTableCell colSpan={7}>Не додано жодного запису</StyledTableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table1;

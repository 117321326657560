import React from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { CardHeader } from '@material-ui/core';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import Button from '@material-ui/core/Button';
import { deleteReportDistribution } from '../../../../api/api';

function DeleteIcon(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 2.25C7.25 1.69772 7.69772 1.25 8.25 1.25H15.75C16.3023 1.25 16.75 1.69772 16.75 2.25C16.75 2.80228 16.3023 3.25 15.75 3.25H8.25C7.69772 3.25 7.25 2.80228 7.25 2.25ZM2.75 5.25C2.75 4.69772 3.19772 4.25 3.75 4.25H5.25H18.75H20.25C20.8023 4.25 21.25 4.69772 21.25 5.25C21.25 5.80228 20.8023 6.25 20.25 6.25H19.75V19.5C19.75 19.9641 19.5656 20.4092 19.2374 20.7374C18.9092 21.0656 18.4641 21.25 18 21.25H6C5.53587 21.25 5.09075 21.0656 4.76256 20.7374C4.43437 20.4092 4.25 19.9641 4.25 19.5V6.25H3.75C3.19772 6.25 2.75 5.80228 2.75 5.25ZM6.25 6.25V19.25H17.75V6.25H6.25Z"
        fill="#141414"
        fillOpacity="0.6"
      />
    </SvgIcon>
  );
}

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
});

const useStyles = makeStyles({
  card: {
    border: '1px solid #14141433',
    borderRadius: '4px !important',
    boxShadow: '0px !important',
    padding: '16px',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardTitle: {
    fontFamily: 'dia',
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '16px',
  },
  titleAction: {
    display: 'flex !important',
    alignItems: 'flex-end',
    padding: 0,
  },
  titleActionButton: {
    fontFamily: 'dia',
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '16px',
    color: '#14141499',
  },
  productName: {
    fontFamily: 'dia',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    color: '#14141499',
  },
  productValue: {
    fontFamily: 'dia',
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: '24px',
    color: '#141414',
  },
});

const DistributionCard = ({ item, updatePage }) => {
  const classes = useStyles();
  const aidtypeCatalog = useSelector((state) => state.catalogs.aidType);
  const pi_act = useSelector((state) => state.report.distribution.current.pi_act);
  const pi_acq_type = useSelector((state) => state.report.distribution.current.pi_acq_type);

  const deleteCard = async (id) => {
    try {
      if (id) {
        await deleteReportDistribution(id);
        await updatePage(pi_act);
        Toast.fire({
          type: 'success',
          title: 'Дані видалено!',
        });
      }
    } catch (err) {
      // console.log(err)
    }
  };

  const { rw, i_distrib, i_category, c_bal_descript, c_description, n_weight, n_count, n_acqcnt } = item;

  return (
    <Card className={classes.card}>
      <CardHeader
        classes={{ root: classes.cardHeader, action: classes.titleAction, title: classes.cardTitle }}
        color="primary"
        title={`#${rw} ТОВАР`}
        action={
          <Button
            className={classes.titleActionButton}
            onClick={() => deleteCard(i_distrib)}
            startIcon={<DeleteIcon />}
          >
            Видалити
          </Button>
        }
      />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={8}>
            <Typography className={classes.productName} variant="subtitle1">
              Категорія товару
            </Typography>
            <Typography className={classes.productValue} variant="body2" color="primary" component="p">
              {aidtypeCatalog.find(({ value }) => Number(value) === Number(i_category))?.label || ''}
              {/* {i_category} */}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography className={classes.productName} variant="subtitle1">
              Кількість, шт.
            </Typography>
            <Typography className={classes.productValue} variant="body2" color="primary" component="p">
              {n_count}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <Typography className={classes.productName} variant="subtitle1">
              Товар по коду гуманітарної допомоги
            </Typography>
            <Typography className={classes.productValue} variant="body2" color="primary" component="p">
              {c_bal_descript}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography className={classes.productName} variant="subtitle1">
              Вага, кг/Об&apos;єм, л
            </Typography>
            <Typography className={classes.productValue} variant="body2" color="primary" component="p">
              {n_weight}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <Typography className={classes.productName} variant="subtitle1">
              Найменування товарів
            </Typography>
            <Typography className={classes.productValue} variant="body2" color="primary" component="p">
              {c_description}
            </Typography>
          </Grid>
          {pi_acq_type === '2' && (
            <Grid item xs={12} sm={12} md={4}>
              <Typography className={classes.productName} variant="subtitle1">
                Кількість осіб
              </Typography>
              <Typography className={classes.productValue} variant="body2" color="primary" component="p">
                {n_acqcnt}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DistributionCard;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { Container, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import Landing from '../Public/Landing';
import TabPanel from './TabPanel';
import logo from '../../../assets/images/gov.ua.white.png';
import flag from '../../../assets/images/ASRGD_Logo.png';
import telegram from '../../../assets/images/telegram.png';
import facebook from '../../../assets/images/facebook.png';
import { setCatalogs, getCatalogs } from '../../../redux/actions/catalog.action';
import LoginPage from '../LoginPage/LoginPage';
import MoveToPublic from '../Public/MoveToPublic';
import './landingPage.css';
import { fontSize } from '@material-ui/system';
import headerLogo from '../../../assets/images/ASRGD_Logo 3.svg';
import telegramLogo from '../../../assets/images/telegram-logo.svg';
import instagramLogo from '../../../assets/images/instagram-logo.svg';
import viberLogo from '../../../assets/images/viber-logo.svg';
import facebookLogo from '../../../assets/images/facebook-logo.svg';
import FooterComponent from './FooterComponent/FooterComponent';

function LandingPage({ auth, history, match, setCatalogs, getCatalogs }) {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(0);

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (auth.isAuthenticated) {
      history.push('/home');
    }
  }, [auth.isAuthenticated, history]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [showModal, setShowModal] = useState(false);

  const handleOkClick = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <div className="modalFirst">
          <div className="modalHeader">
            <a href="https://www.ioc.gov.ua">
              <img className="modalLogo" src={headerLogo} alt="modalLogo" />
            </a>
          </div>
          <div className="modalContent-wrapper">
            <div className="modalContent">
              <p className="modalText-title">Шановні користувачі!</p>
              <p className="modalText">
                Наразі наша система АС ГД працює стабільно, але виникли технічні труднощі у процесі
                взаємодії з іншими державними системами, що спричиняє затримки в обробці інформації.
              </p>
              <p className="modalText">
                Наші колеги вже працюють над вирішенням цих питань. Як тільки проблему буде усунено,
                статус щодо передачі вантажів зміниться автоматично.
              </p>
              <p className="modalText">
                Дякуємо за ваше розуміння та терпіння!
              </p>
              <button className="buttonModalOk" onClick={handleOkClick}>
                Зрозуміло
              </button>
            </div>
            {/* <div className="modalContent">
              <p className="modalText-title">Увага, користувачі АС ГД!</p>
              <p className="modalText">
                ⚙️ У зв’язку з проведенням технічних робіт на сервісах{' '}
                <a href="https://id.gov.ua/" target="blank">
                  ID.GOV.UA
                </a>{' '}
                робота системи може бути не стабільною з <b> 22:00 08.05.2024 до 02:00 09.05.2024.</b>
              </p>
              <p className="modalText">
                🦺 Технічна команда <b>ID.GOV.UA</b> намагається як найшвидше стабілізувати систему.
              </p>
              <p className="modalText">🫶🏻 Просимо вибачення за тимчасові незручності.</p>
              <button className="buttonModalOk" onClick={handleOkClick}>
                Зрозуміло
              </button>
            </div> */}
          </div>
          <FooterComponent />
        </div>
      )}
      {!showModal && (
        <div>
          <div className="page">
            <div className="modalHeader">
              <a href="https://www.ioc.gov.ua">
                <img className="modalLogo" src={headerLogo} alt="modalLogo" />
              </a>
            </div>
            <div className="main">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="primary"
                // className={classes.tabs}
                className="tabs"
              >
                <Tab disableRipple disableFocusRipple label="Реєстрація гуманітарної допомоги" />
                <Tab disableRipple disableFocusRipple label="Інформація про завезену гуманітарну допомогу" />
              </Tabs>
              <TabPanel value={value} index={0}>
                <div className="loginPageBlock">
                  <div className="Landing">
                    <Landing />
                  </div>
                  <div className="loginBlock">
                    <LoginPage />
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div className="MoveTopublicBlock">
                  <MoveToPublic />
                </div>
              </TabPanel>
            </div>
            <FooterComponent />
          </div>
        </div>
      )}

      {/* <Grid container direction="column" className={classes.page} justifyContent="space-around"> */}
      {/* <Grid item>
        <Grid container>
          <AppBar position="sticky" elevation={0} style={{ backgroundColor: 'transparent' }}>
            <Toolbar>
              <Button href="https://www.msp.gov.ua">
                <img src={flag} alt="buttonGovUa" />
              </Button>
            </Toolbar>
          </AppBar>
        </Grid>
      </Grid> */}
      {/* <Grid item>
        <Grid container justifyContent="center" className={classes.main}>
          <Grid item xs={12} sm={8}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="primary"
              className={classes.tabs}
            >
              <Tab disableRipple disableFocusRipple label="Реєстрація гуманітарної допомоги" />
              <Tab disableRipple disableFocusRipple label="Інформація про завезену гуманітарну допомогу" />
            </Tabs>
            <TabPanel value={value} index={0}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Landing />
                </Grid>
                <Grid item xs={6}>
                  <LoginPage />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid item xs={6}>
                <MoveToPublic />
              </Grid>
              <Grid item xs={6} />
            </TabPanel>
          </Grid>
        </Grid>
      </Grid> */}

      {/* <Grid item>
        <Grid container className={classes.footer}>
          <Grid item xs={6}>
            <Typography>© 2023 Мінсоцполітики</Typography>
            <Typography>
              Розробник:<a target='blank' href="https://www.ioc.gov.ua/"> ДП ІОЦ Мінсоцполітики  </a></Typography>
              <Typography>(044) 494-19-10</Typography>
              <Typography>callcenter@ioc.gov.ua</Typography>
          </Grid>
          <Grid item>
            <div className={classes.socialIcons}>
              <Typography>Слідкуй за нами тут:</Typography>
              <a href="https://m.facebook.com/MLSP.gov.ua">
                {' '}
                <img className="icon" src={facebook} />{' '}
              </a>
              <a href="https://t.me/MinSocUA">
                {' '}
                <img className="icon" src={telegram} />{' '}
              </a>
            </div>
          </Grid>
        </Grid>
      </Grid> */}
      {/* </Grid> */}
    </>
  );
}

LandingPage.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  setCatalogs: PropTypes.func.isRequired,
  getCatalogs: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default withRouter(connect(mapStateToProps, { setCatalogs, getCatalogs })(LandingPage));

import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  CardContent,
  Card,
  CardActions,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Swal from 'sweetalert2';
import { Autocomplete } from '@material-ui/lab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { deleteRecipientDocumentsApi, saveRequestAction, getRequestAction } from '../../../api/api';

import useStyles from './uploadStyles';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
});

const CompetencyView = ({ history, disableChanges }) => {
  const classes = useStyles();

  const {
    competency: { requestId },
  } = useSelector((state) => state);

  const [state, setState] = React.useState({
    c_orgname: '',
    user_name: '',
    sri: '',
    c_email: '',
    c_org_cod: '',
    table: [],
    actions: [],
    loading: false,
    sign: false,
    cargo: false,
  });
  const [action, setAction] = React.useState();

  const saveAction = async (actionOption) => {
    // const { loading } = state;
    // setState({ ...state, loading: true });
    const { sign, cargo } = state;
    try {
      const saveData = {
        pi_request: requestId,
        pl_sign: sign ? 1 : 0,
        pl_cargo: cargo ? 1 : 0,
        pi_action: actionOption?.value,
      };
      await saveRequestAction(saveData);
      Toast.fire({
        type: 'success',
        title: 'Дані збережено!',
      });
      setAction(actionOption);
    } catch (error) {
      swalWithBootstrapButtons.fire({
        title: 'Помилка',
        text: error.response.data,
        type: 'error',
      });
    }
  };

  const handleAction = (currAction) => {
    const insertPhrase = currAction?.label ? `${currAction?.label}`.toLowerCase() : '';
    swalWithBootstrapButtons
      .fire({
        text: `Ви дійсно бажаєте ${insertPhrase}?`,
        showCancelButton: true,
        confirmButtonText: 'Так',
        cancelButtonText: 'Відміна',
      })
      .then(async (result) => {
        if (result.value) {
          saveAction(currAction);
        }
      });
  };

  const handleSelect = (option) => {
    if (option) {
      // handleAction(option);
      setAction(option);
    } else {
      setAction(null);
    }
  };

  const handleCheckbox = (event) => {
    const { name, checked } = event.target;
    setState({ ...state, [name]: checked });
  };

  const getRequestInfo = async (id) => {
    try {
      const res = await getRequestAction(id);
      const {
        data: {
          p_documents_cursor,
          p_actions_cursor,
          p_request_cursor: {
            0: { c_orgname, user_name, sri, c_email, c_org_cod, sign, cargo },
          },
        },
      } = res;
      setState({
        ...state,
        c_orgname,
        user_name,
        c_org_cod,
        sri,
        c_email,
        table: p_documents_cursor,
        actions: p_actions_cursor,
        sign: Boolean(sign),
        cargo: Boolean(cargo),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const updateTable = async () => {
    try {
      const res = await getRequestAction(requestId);
      const {
        data: { p_documents_cursor },
      } = res;
      setState({ ...state, table: p_documents_cursor });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteDocument = async (id) => {
    try {
      await deleteRecipientDocumentsApi(id);
      await updateTable();
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    if (requestId) getRequestInfo(requestId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId]);

  let documents = null;
  const { table } = state;

  if (table.length > 0) {
    documents = table.map((item) => {
      const { c_filename, i_request, id, doc_type_txt, i_doctype, c_comment } = item;
      return (
        <tr key={id}>
          <td>{doc_type_txt}</td>
          <td>{c_comment}</td>
          <td>
            <a href={`/aid/file/${c_filename}`} target="blank">
              {c_filename}
            </a>
          </td>
          <td>
            <Button variant="outlined" type="button" disabled={disableChanges} onClick={() => deleteDocument(id)}>
              Видалити
            </Button>
          </td>
        </tr>
      );
    });
  } else {
    documents = (
      <tr>
        <td colSpan="5" className="text-center">
          <h4>
            <strong>Не додано жодного документу</strong>
          </h4>
        </td>
      </tr>
    );
  }

  const { user_name, sri, c_orgname, c_email, c_org_cod, actions, loading, sign, cargo } = state;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid container spacing={2} style={{ padding: '20px' }}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" style={{ marginBottom: '20px' }}>
                Дані що були вказані при подачі запиту:
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ marginBottom: '20px' }}>Я:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ fontWeight: 'bold', marginBottom: '20px' }}>{user_name}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ marginBottom: '20px' }}>РНОКПП (ПАСПОРТ):</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ fontWeight: 'bold', marginBottom: '20px' }}>{sri}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ marginBottom: '20px' }}>Email:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ fontWeight: 'bold', marginBottom: '20px' }}>{c_email}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ marginBottom: '20px' }}>Назва організації:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ fontWeight: 'bold', marginBottom: '20px' }}>{c_orgname}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={{ marginBottom: '20px' }}>Код ЄДРПОУ/РНОКПП:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={{ fontWeight: 'bold', marginBottom: '20px' }}>{c_org_cod}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" align="center" style={{ fontSize: '32px' }}>
              Документи
            </Typography>
            <div className="table-responsive zebra">
              <table>
                <thead>
                  <tr>
                    <th style={{ width: '25%' }}>Тип документу</th>
                    <th style={{ width: '25%' }}>Коментар</th>
                    <th style={{ width: '25%' }}>Скан документу</th>
                    <th style={{ width: '25%' }}>Видалити</th>
                  </tr>
                </thead>
                <tbody>{documents}</tbody>
              </table>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center" direction="column">
              <Grid item xs={12} sm={12} md={6}>
                <Autocomplete
                  // eslint-disable-next-line radix
                  value={actions.filter(({ value }) => parseInt(value) === parseInt(action?.value))[0] || null}
                  onChange={(event, option) => handleSelect(option)}
                  autoHighlight
                  getOptionLabel={(option) => `${option?.label}`}
                  options={actions}
                  style={{ marginBottom: 16 }}
                  renderInput={(params) => (
                    <TextField
                      InputLabelProps={{ style: { fontSize: '12px' } }}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      label="Оберіть дію"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
                <FormControlLabel
                  disabled={action?.value !== 6 && action?.value !== 2}
                  control={<Checkbox color="primary" checked={sign} onChange={handleCheckbox} name="sign" />}
                  label={
                    <Typography
                      variant="body1"
                      color={action?.value !== 6 && action?.value !== 2 ? 'textSecondary' : 'primary'}
                      style={{ textAlign: 'justify' }}
                    >
                      Право підпису декларацій та звітів
                    </Typography>
                  }
                  color="primary"
                />
                <FormControlLabel
                  disabled={action?.value !== 6 && action?.value !== 2}
                  control={<Checkbox color="primary" checked={cargo} onChange={handleCheckbox} name="cargo" />}
                  label={
                    <Typography
                      variant="body1"
                      color={action?.value !== 6 && action?.value !== 2 ? 'textSecondary' : 'primary'}
                      style={{ textAlign: 'justify' }}
                    >
                      Бачить лише ті вантажі, які сам заводив
                    </Typography>
                  }
                  color="primary"
                />
              </Grid>
            </Grid>
            {action && (
              <Grid container item justifyContent="center" alignItems="center">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    handleAction(action);
                  }}
                >
                  {action?.label}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          type="button"
          variant="contained"
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            history.push('/competency');
          }}
        >
          до переліку запитів
        </Button>
      </CardActions>
    </Card>
  );
};

export default withRouter(CompetencyView);

import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import queryString from 'query-string';
import isEmpty from '../../vibe/components/utilities/MainUtils/is-empty';
import setAuthToken from '../../vibe/components/utilities/MainUtils/setAuthToken';
import { loginUser, logoutUser } from '../../redux/actions/auth.action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { history } from '../../vibe/components/utilities/MainUtils/hist';
import { fetchAccess } from '../../redux/actions/access.action';
import telegram from '../../../src/assets/images/telegram.png';
import facebook from '../../../src/assets/images/facebook.png';
import flag from '../../../src/assets/images/ASRGD_Logo.png';
import Typography from '@material-ui/core/Typography';
// import gerb from '../../assets/images/Ukraine.png';
import gerb from '../../assets/images/gerbPub2.png';
import Grid from '@material-ui/core/Grid';
import { AppBar, Toolbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import './InfoUrPerson.css';

const InfoUrPerson = ({ loginUser, logoutUser }) => {
  const [agree, setAgree] = useState(false);
  const [agreeTwo, setAgreeTwo] = useState(false);

  const handleCheckbox = (event) => {
    const { name, checked } = event.target;
    if (name == 'agreement_to_receiveOne') {
      setAgree(checked);
    } else if (name == 'agreement_to_receiveSecond') {
      setAgreeTwo(checked);
    }
  };
  const apruveButton = () => {
    fetchAccess();
    const token = localStorage.jwtToken;
    if (!isEmpty(token)) {
      // const decoded = jwt_decode(query.token);
      // localStorage.setItem('jwtToken', query.token);
      // this.props.setCurrentUser(decoded)
      setAuthToken(token);
      loginUser(token);
      history.push('/home');
    } else {
      logoutUser();
    }
  };

  return (
    <div className="content">
       <Grid item>
        <Grid container>
          <AppBar position="sticky" elevation={0} style={{ backgroundColor: 'transparent' }}>
            <Toolbar>
              {/* <Container> */}
              <Button href="https://www.ioc.gov.ua">
                <img src={flag} alt="buttonGovUa" width='210px' style={{ margin: '25px auto', display: 'block', objectFit: 'cover' }} />
              </Button>
            </Toolbar>
          </AppBar>
        </Grid>
      </Grid>
      <h2 className="title_law">Ознайомтеся з нормами законодавства:</h2>
      <div className="block_law">
        <ul className="ListLaw">
          <li className="item_law">
            <img src={gerb} className="item_law_img" alt="buttonGovUa" width={50} height={75} />
            <a
              className="item_law_A"
              target="_blank"
              rel="noopener noreferrer"
              href="https://zakon.rada.gov.ua/laws/show/1907-20#Text"
            >
              Закон України “Про публічні електронні реєстри”
            </a>
          </li>
          <li className="item_law">
            <img src={gerb} className="item_law_img" alt="buttonGovUa" width={50} height={75} />
            <a
              className="item_law_A"
              target="_blank"
              rel="noopener noreferrer"
              href="https://zakon.rada.gov.ua/laws/show/2155-19#Text"
            >
              Закон України "Про електронні довірчі послуги"
            </a>
          </li>
          <li className="item_law">
            <img src={gerb} className="item_law_img" alt="buttonGovUa" width={50} height={75} />
            <a
              className="item_law_A"
              target="_blank"
              rel="noopener noreferrer"
              href="https://zakon.rada.gov.ua/laws/show/1192-14#Text"
            >
              Закон України „Про гуманітарну допомогу”
            </a>
          </li>
          <li className="item_law">
            <img src={gerb} className="item_law_img" alt="buttonGovUa" width={50} height={75} />
            <a
              className="item_law_A"
              target="_blank"
              rel="noopener noreferrer"
              href="https://zakon.rada.gov.ua/laws/show/174-2022-%D0%BF#Text"
            >
              Постанова КМУ „Деякі питання пропуску та обліку гуманітарної допомоги в умовах воєнного стану”
            </a>
          </li>
          <li className="item_law">
            <img src={gerb} className="item_law_img" alt="buttonGovUa" width={50} height={75} />
            <a
              className="item_law_A"
              target="_blank"
              rel="noopener noreferrer"
              href="https://zakon.rada.gov.ua/laws/show/927-2020-%D0%BF#Text"
            >
              Постанова КМУ "Деякі питання автоматизованої системи реєстрації гуманітарної допомоги”.
            </a>
          </li>
        </ul>

        <div className="blocks_Checkbox">
          <FormControlLabel
            style={{ color: '#5e646b' }}
            control={
              <Checkbox color="primary" checked={agree} onChange={handleCheckbox} name="agreement_to_receiveOne" />
            }
            label="Підтверджую згоду на розміщення внесених  мною даних у
              публічному електронному реєстрі."
          />

          <FormControlLabel
            style={{ marginTop: '50px', color: '#5e646b' }}
            control={
              <Checkbox
                color="primary"
                checked={agreeTwo}
                onChange={handleCheckbox}
                name="agreement_to_receiveSecond"
              />
            }
            label="Підтверджую обізнаність про відповідальність за порушення законодавства у сфері реєстрів та самостійне прийняття рішення щодо створення інформації про об’єкти реєстру, здійснення реєстраційних та інших дій.
              Мені відомо про обов’язок завантаження в Автоматизовану систему реєстрації гуманітарної допомоги електронної копії паперової декларації про перелік товарів, що визнаються гуманітарною допомогою, не пізніше 15-го дня з дати відмітки про її митне оформлення."
          />
        </div>
        <div className="section_button">
          {agree === true && agreeTwo === true ? (
            // <NavLink className="buttonEntrance" to={'/home'}>
            //   Вхід
            // </NavLink>
            <button className="button_save" onClick={apruveButton}>
              Вхід
            </button>
          ) : null}
        </div>
      </div>
      <div className='footer1'>
    <div className='footerContact'>
    <p className='footerContactText'>© 2023 Мінсоцполітики</p>
            <p className='footerContactText'>
              Розробник:<a target='blank' href="https://www.ioc.gov.ua"> ДП ІОЦ Мінсоцполітики  </a></p>
              <p className='footerContactText'>(044) 494-19-10</p>
              <p className='footerContactText'>callcenter@ioc.gov.ua</p>
    </div>
 
            <div className='socialIcons'>
              <Typography style={{fontSize: '15px'}}>Слідкуй за нами тут:</Typography>
              <a href="https://m.facebook.com/MLSP.gov.ua">
                {' '}
                <img className="icon" src={facebook} />{' '}
              </a>
              <a href="https://t.me/MinSocUA">
                {' '}
                <img className="icon" src={telegram} />{' '}
              </a>
            </div>
  </div>
    </div>
  );
};

// export default InfoUrPerson;
const mapStateToProps = (state) => {
  return {};
};
export default withRouter(
  connect(mapStateToProps, {
    loginUser,
    logoutUser,
  })(InfoUrPerson)
);

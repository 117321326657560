// packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Card, CardBody, Button } from 'reactstrap';
import InputMask from 'react-input-mask';
// styles
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core';
import { customTheme, customStyles } from '../../../../customStyles/reactSelect';
import AddCargo from './AddCargo';
import AddAquirerModal from './AddAquirerModal';
import BootstrapTooltip from '../../../elements/MaterialBootstrap';

const style = (theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});

class PlanDistribution extends Component {
  state = {
    options: [
      { label: 'option1', value: 1 },
      { label: 'option2', value: 1 },
      { label: 'option3', value: 1 },
      { label: 'option4', value: 1 },
    ],
    selectedOption: '',
    modal: false,
  };

  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  filterAid = (inputValue) => {
    return this.props.catalog.aidType.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.filterAid(inputValue));
      }, 1000);
    });

  addCargo = () => {
    const i = 0;
    const data = {
      i_aid: 0,
      i_unit: '',
      n_count: '',
      i_aidtype: '',
      i_aidcondition: '',
      c_description: '',
      i_aidform: this.props.cargo.i_aidform,
      l_deleted: '',
    };
    this.props.addCargoAction(data);
  };

  deleteItem = (index) => {
    this.props.deleteCargoAction(index);
  };

  handleChange = (e, item, index) => {
    const rows = [...this.props.cargoCollection];

    rows[index][e.target.name] = e.target.value;

    this.props.setCargoAction(rows);
  };

  handleChangeSelect = (select, name, index) => {
    if (select) {
      const rows = [...this.props.cargoCollection];

      rows[index][name] = select.value;

      this.props.setCargoAction(rows);
    }
  };

  filterOptions = (candidate, input) => {
    if (candidate.data.label.toLowerCase().includes(input.toLowerCase())) {
      return true;
    }

    return false;
  };

  render() {
    const { selectedOption } = this.state;
    return (
      <section className="planForm">
        <div className="row">
          <div className="col-12 col-sm-6 col-md-4 col-xl-4  mt-4 mb-2">
            <Button onClick={this.addCargo}>Додати вантаж</Button>
          </div>
          <div className="w-100" />
          <div className="col-12 col-sm-6 col-md-4 col-xl-4 mb-2">
            <label htmlFor="">Тип набувача</label>
            <BootstrapTooltip title=" Оберіть  тип зі списку.">
              <div>
                <Select
                  value={this.props.catalog.acquirerType.filter(
                    ({ value }) => value === this.props.cargo.i_acquiretype
                  )}
                  onChange={this.props.handleSelectChange('i_acquiretype')}
                  options={this.props.catalog.acquirerType}
                  styles={customStyles}
                  placeholder=""
                  // isMulti
                  noOptionsMessage={() => 'помилка серверу'}
                  menuPortalTarget={document.body}
                  theme={customTheme}
                />
              </div>
            </BootstrapTooltip>
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-xl-4 mt-4 mb-2">
            <Button onClick={this.toggle}>+</Button>
          </div>
          <AddAquirerModal
            toggle={this.toggle}
            modal={this.state.modal}
            catalog={this.props.catalog}
            cargo={this.props.cargo}
            handleSelectChange={this.props.handleSelectChange}
            handleChangeRedux={this.props.handleChangeRedux}
            handleChangeSelect={this.handleChangeSelect}
            handleChange={this.handleChange}
            deleteItem={this.deleteItem}
            promiseOptions={this.promiseOptions}
            addCargo={this.addCargo}
            cargoCollection={this.props.cargoCollection}
          />
        </div>
      </section>
    );
  }
}

PlanDistribution.proptTypes = {
  catalog: PropTypes.object.isRequired,
  cargo: PropTypes.object.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleChangeRedux: PropTypes.func.isRequired,
};

export default withStyles(style)(PlanDistribution);

import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Card, CardContent, CardActions, Button, TextField } from '@material-ui/core';

import ModalWindow from './ModalWindow';

const Edit = ({
  editCode,
  setEditCode,
  editDescr,
  setEditDescr,
  currentOption,
  handleAdd,
  handleDelete,
  handleEdit,
  cleanValues,
}) => {
  const {
    catalogs: { uktzed },
  } = useSelector((state) => state);

  const [deleteModal, setDeleteModal] = React.useState(false);

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDeleteWithModal = () => {
    setDeleteModal(false);
    handleDelete();
  };

  const checkCurrent = (check) => {
    return uktzed.find(({ value }) => value === check);
  };

  const inputIsNumber = (val) => {
    const regex = new RegExp(/^[0-9]*$/, 'g');
    return regex.test(val);
  };

  const handleCodeChange = (event) => {
    const { value } = event.target;

    if (inputIsNumber(value)) {
      setEditCode(value);
    }
  };

  const handleDescrChange = (event) => {
    const { value } = event.target;
    setEditDescr(value);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Редактор коду УКТ ЗЕД"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 4 }}
                onChange={handleCodeChange}
                value={editCode}
                fullWidth
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                label="Редактор опису УКТ ЗЕД"
                onChange={handleDescrChange}
                value={editDescr}
                variant="outlined"
                multiline
                rows={8}
                maxRows={8}
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button color="primary" variant="contained" onClick={handleAdd} disabled={currentOption}>
            Додати
          </Button>
          <Button color="primary" variant="contained" onClick={handleOpenDeleteModal} disabled={!currentOption}>
            Видалити
          </Button>
          <Button color="primary" variant="contained" onClick={handleEdit} disabled={!currentOption}>
            Редагувати
          </Button>
          <Button color="primary" variant="outlined" onClick={cleanValues}>
            Очистити
          </Button>
        </CardActions>
      </Card>
      <ModalWindow
        deleteModal={deleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
        handleDeleteWithModal={handleDeleteWithModal}
      />
    </>
  );
};

export default Edit;

import React from 'react';
import { useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { makeStyles, withStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

export default function TableRecipientDocs({ docsR }) {
  const classes = useStyles();

  const {
    catalogs: { documentTypes1 },
  } = useSelector((state) => state);

  return (
    <Table size="small" className={classes.table}>
      <TableHead>
        <TableRow>
          <StyledTableCell>Назва документу</StyledTableCell>
          <StyledTableCell>Скан документу</StyledTableCell>
          <StyledTableCell>Детальний опис документу</StyledTableCell>
          <StyledTableCell>Номер і дата документу</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {docsR.map((row, indexPlans) => (
          <TableRow>
            <StyledTableCell width="25%">
              {documentTypes1.find(({ value }) => value === row.n_doctype)?.label}
            </StyledTableCell>
            <StyledTableCell width="25%">
              <a href={`/aid/file/${row.c_filename}`} rel="noopener noreferrer" target="_blank">
                {row.c_filename}
              </a>
            </StyledTableCell>
            <StyledTableCell width="25%">{row.c_docname}</StyledTableCell>
            <StyledTableCell width="25%">{row.c_dt_numb}</StyledTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Button, TextField, Grid } from '@material-ui/core';
import clsx from 'clsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import uk from 'date-fns/locale/uk';
import format from 'date-fns/format';

// assets
import Swal from 'sweetalert2';
import uploadTable from '../../../assets/images/download.svg';
import vector from '../../../assets/images/Vector.png';
import save from '../../../assets/images/save.png';
import print from '../../../assets/images/print.png';
import sign from '../../../assets/images/sign.png';
import { Loader } from '../../../vibe';
import {
  getApplForSaveDecl,
  saveRecipientDocumentsApi,
  getDocByCargo,
  deleteRecipientDocumentsApi,
  deleteCargoDocumentsApi,
} from '../../../api/api';
import { history } from '../../../vibe/components/utilities/MainUtils/hist';
import { useStyles } from './CustomsDate.styles';

const CustomsDate = ({ disableChanges }) => {
  const classes = useStyles();
  const {
    cargo: {
      cargoApplication: { i_cargo: cargoId },
    },
    // declaration,
  } = useSelector((state) => state);

  const [state, setState] = React.useState({
    fileName: '',
    fileSize: '',
    submitted: false,
    table: [],
    date: null,
    loading: false,
  });
  const [pApp, setPApp] = React.useState();

  const fileInput = React.useRef(null);

  // datapicker
  const handleChangeDate = (dateObj) => {
    setState({ ...state, date: dateObj });
  };

  const handleChangeFileInput = (e) => {
    setState({ ...state, fileName: '' });
    // eslint-disable-next-line no-unused-expressions
    e.target.files[0] &&
      setState({
        ...state,
        fileName: e.target.files[0].name,
        fileSize: e.target.files[0].size,
      });
  };

  const updateTable = async (id) => {
    try {
      const updated = await getDocByCargo(3, id);
      setState({
        ...state,
        table: updated.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getPapplication = async () => {
    try {
      const res = await getApplForSaveDecl(cargoId);
      // console.log(res.data);
      const { p_application } = res.data;
      setPApp(p_application);
    } catch (err) {
      console.log(err);
    }
  };

  const updateTableAtStart = async () => {
    await updateTable(cargoId);
    await getPapplication();
  };

  const saveDocument = async (data) => {
    try {
      await saveRecipientDocumentsApi({ id: 0, p_application: pApp, p_doctype: 30 }, data);
      fileInput.current.value = '';
      setState({
        ...state,
        fileName: '',
        fileSize: '',
        date: null,
      });
      await updateTable(cargoId);
    } catch (err) {
      console.log(err);
    }
  };

  const submitDocument = (e) => {
    e.preventDefault();
    setState({ ...state, submitted: true });

    if (fileInput.current.files[0]) {
      setState({ ...state, submitted: false });
      const { date } = state;
      const customsDate = date ? format(date, 'dd.MM.yyyy') : null;
      const data = new FormData();
      //
      data.append('file', fileInput.current.files[0]);
      //   data.append('p_docname', );
      data.append('p_dt_numb', customsDate);

      saveDocument(data);
    } else if (!fileInput.current.files) {
      setState({ ...state, validationText: 'Ви не додали файл' });
    }
  };

  const deleteDocument = async (id) => {
    try {
      await deleteRecipientDocumentsApi(id);
      await updateTable(cargoId);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    updateTableAtStart();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let documents = null;
  const { table } = state;
  if (table.length > 0) {
    documents = table.map((item, i) => {
      const { id } = item;
      return (
        <tr key={id}>
          <td>{i + 1}</td>
          <td>{item.d_ins}</td>
          <td>{item.c_docname}</td>
          <td>
            <a href={`/aid/file/${item.c_filename}`} target="blank">
              {item.c_filename}
            </a>
          </td>
          <td>
            <Button variant="outlined" type="button" disabled={disableChanges} onClick={() => deleteDocument(id)}>
              Видалити
            </Button>
          </td>
        </tr>
      );
    });
  } else {
    documents = (
      <tr>
        <td colSpan="5" className="text-center">
          <h4>
            <strong>Не додано жодного документу</strong>
          </h4>
        </td>
      </tr>
    );
  }

  const { date } = state;

  return (
    <>
      <Card className={classes.test_root_section}>
        <CardBody>
          <section className="docsCustoms" />
          <form onSubmit={submitDocument} className={classes.test_section_dowload}>
            <div className={classes.test_border_dowload}>
              <label
                htmlFor="file-upload-cargo"
                className={clsx(classes.test_button_loading, {
                  [classes.disabledChanges]: disableChanges,
                })}
              >
                <div
                  className={clsx(classes.test_button_loading_text, {
                    [classes.disabledChanges]: disableChanges,
                  })}
                >
                  {' '}
                  ОБЕРІТЬ ФАЙЛ <img className={classes.test_button_loading_img} src={vector} alt="choose file" />
                </div>
              </label>
              <div className={classes.test_textFile}>
                <div className={classes.test_textFile} style={{ fontWeight: 800 }}>
                  Увага! Розмір документу для завантаження не повинен перевищувати 10 Мб
                </div>
                {state.fileSize > parseInt(100000000) && (
                  <div className="text-danger">Завантаження неможливе! Розмір файла перевищує 10 мб</div>
                )}
              </div>
            </div>
            <div className={classes.test_file_sections}>
              <div className={classes.testdowload_file}>
                <input
                  id="file-upload-cargo"
                  className="upload"
                  type="file"
                  accept=".jpeg,.jpg,.png,.pdf"
                  disabled={disableChanges}
                  onChange={handleChangeFileInput}
                  ref={fileInput}
                />
                {state.submitted && !fileInput.current.files[0] && (
                  <FormHelperText error id="name-error-text">
                    Поле обов&apos;язкове
                  </FormHelperText>
                )}
                <div> {state.fileName}</div>
              </div>

              {state.fileName && (
                <div className={classes.test_commit}>
                  <DatePicker
                    selected={date}
                    onChange={handleChangeDate}
                    dateFormat="dd.MM.yyyy"
                    locale={uk}
                    className="datePicker"
                    isClearable
                    customInput={<TextField label="Дата митного оформлення:" />}
                  />
                </div>
              )}
            </div>

            <Button
              type="submit"
              color="primary"
              className={classes.test_button_save}
              //   disabled={state.fileSize > parseInt(100000000) || disableChanges}
            >
              Зберегти
              <img className={classes.test_button_loading_img} src={save} />
            </Button>
          </form>
          <div className="table-responsive zebra">
            <table>
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>№ з/п</th>
                  <th style={{ width: '25%' }}>Дата завантаження</th>
                  <th style={{ width: '25%' }}>Дата митного оформлення</th>
                  <th style={{ width: '25%' }}>Скан документу</th>
                  <th style={{ width: '20%' }}>Видалити</th>
                </tr>
              </thead>
              <tbody>{documents}</tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default CustomsDate;

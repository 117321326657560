import React from 'react';
import { Grid, List, ListItem, ListItemText, Typography, Paper, Link } from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

const Documents = ({ documents, downloadDeclaration }) => (
  <Grid container direction="column">
    <Grid item>
      <Typography variant="subtitle2" component="h2" gutterBottom>
        Завантажені документи
      </Typography>
    </Grid>
    <Grid container item alignItems="flex-start" direction="column" spacing={4}>
      <Grid container item direction="column" spacing={2}>
        {documents.map((document, index) => (
          <>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {document.file_type}
              </Typography>
            </Grid>
            <Grid
              style={{ cursor: 'pointer' }}
              item
              container
              direction="row"
              alignItems="flex-end"
              justifyContent="space-between"
              spacing={2}
            >
              <Typography
                href={`/aid/file/${document.file_name}`}
                variant="body1"
                color="textSecondary"
                component={Link}
                target="_blank"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <DescriptionOutlinedIcon fontSize="large" />
                {document.file_name}
              </Typography>
            </Grid>
          </>
        ))}
      </Grid>
      <Grid container item direction="column" spacing={2}>
        <Grid item>
          <Typography variant="body2" color="textSecondary">
            Декларація
          </Typography>
        </Grid>
        <Grid
          style={{ cursor: 'pointer' }}
          item
          container
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between"
          spacing={2}
        >
          <Typography
            variant="body1"
            color="textSecondary"
            component={Link}
            onClick={downloadDeclaration}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <DescriptionOutlinedIcon fontSize="large" />
            156545-156-25.pdf
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default Documents;

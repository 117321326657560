import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const CustomTooltip = withStyles((theme) => ({
  tooltipPlacementBottom: {
    margin: '0px 0px',
  },
  tooltip: {
    backgroundColor: '#141414',
    color: '#ffffff',
    fontSize: '10px',
    fontWeight: '300',
    lineHeight: '14px',
    padding: '4px 8px',
    borderRadius: '2px',
  },
}))(Tooltip);

export default CustomTooltip;

import React from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { getReport } from '../../../../api/api';
import {
  setBalanceArray,
  setDistributionArray,
  setReportCargoList,
  setReportParams,
  setReportAidtype,
  setReportAcquirer,
  clearBalanceCurrent,
  clearDistributionCurrent,
  setReportTab,
} from '../../../../redux/actions/report.action';
import { transformBalance, transformDistribution } from './reportHelpers/helpers';
import { getMonths } from './reportHelpers/calendar';

import style from './CalendarCard.module.css';

const useStyles = makeStyles({
  title: {
    fontFamily: 'dia_bold',
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '32px',
    texAlign: 'center',
  },
  arrow: {
    height: 24,
    weight: 24,
  },
  year: {
    fontFamily: 'dia',
    fontSize: 18,
    fontWeight: 400,
    lineHeight: 24,
  },
  currYear: {
    color: '#141414',
  },
  otherYear: {
    color: '#1414144D',
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const CalendarCard = ({ handleClose, history }) => {
  const region = useSelector((state) => state.catalogs.region);
  const i_recip = useSelector((state) => state.report.params.i_recip);
  const minDate = useSelector((state) => state.report.params.min_date);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [year, setYear] = React.useState(new Date().getFullYear());

  const prevYear = () => {
    if (year > 0) setYear(year - 1);
  };
  const nextYear = () => setYear(year + 1);

  const dateToObject = (strDate) => {
    if (strDate) {
      const [day, month, yr] = strDate.split('.');
      return new Date(+yr, +month - 1, +day);
    }
    return null;
  };

  const onDateChangeStr = async (dateStr) => {
    try {
      const res = await getReport(i_recip, dateStr);
      const { balance, distrib, cargo: newCargo, aidtype, acquirer, general } = res.data;
      const { i_recip: Irecip, curr_date: currDateG, min_date: minDateG, i_report } = general[0];
      dispatch(setReportParams({ i_recip: Irecip, curr_date: currDateG, min_date: minDateG, i_report }));
      dispatch(setReportCargoList(newCargo));
      dispatch(setReportAidtype(aidtype));
      dispatch(setReportAcquirer(acquirer));
      dispatch(setBalanceArray(transformBalance(balance, newCargo)));
      dispatch(setDistributionArray(transformDistribution(distrib, acquirer, region)));
      handleClose();
      dispatch(setReportTab(0));
      history.push('/create-report');
      // eslint-disable-next-line no-empty
    } catch (err) {
      dispatch(setReportCargoList([]));
      dispatch(setReportAidtype([]));
      dispatch(setReportAcquirer([]));
      dispatch(setBalanceArray([]));
      dispatch(clearBalanceCurrent());
      dispatch(setDistributionArray([]));
      dispatch(clearDistributionCurrent());
    }
  };

  const gotoMonth = (ind) => {
    const chooseDateStr = moment({ year, month: ind, day: 1 }).endOf('month').format('DD.MM.YYYY');
    onDateChangeStr(chooseDateStr);
  };

  return (
    <Grid container spacing={1}>
      <Grid container item spacing={1} justifyContent="center">
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <Typography variant="h5" align="center" className={classes.title}>
            Оберіть місяць, за яким збираєтесь створити звіт
          </Typography>
        </Grid>
      </Grid>
      <Grid container item spacing={1} justifyContent="center">
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <IconButton onClick={prevYear}>
              <ArrowBackIosIcon />
            </IconButton>
            <Typography variant="body2" className={classes.otherYear} component="p">
              {year - 1}
            </Typography>{' '}
            <Typography variant="body2" className={classes.currYear} component="p">
              {year}
            </Typography>{' '}
            <Typography variant="body2" className={classes.otherYear} component="p">
              {year + 1}
            </Typography>
            <IconButton onClick={nextYear}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Grid container item spacing={1} justifyContent="center">
        {getMonths('uk').map((item, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item xs={6} sm={6} md={4} key={`p${index}`} className={classes.gridItem}>
              <button
                type="button"
                className={style.month}
                onClick={() => gotoMonth(index)}
                disabled={
                  moment({ year, month: index, day: 1 }).endOf('month').toDate() < dateToObject(minDate) ||
                  moment({ year, month: index, day: 1 }).endOf('month').toDate() >
                    moment(new Date()).endOf('month').toDate()
                }
              >
                {item}
              </button>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default withRouter(CalendarCard);

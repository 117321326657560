import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { CardContent, Card, CardHeader, Icon, Button, IconButton, Checkbox } from '@material-ui/core';
import { Route, withRouter } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { StyledTableCell, CustomTablePagination, EnhancedTableHead } from '../../elements/CustomTable';
import { getComparator, stableSort } from '../RegisterRecipients/sortFunctions';
import { ReactComponent as Eye } from '../../../assets/svg/Eye.svg';
import { setRequestId } from '../../../redux/actions/competency.action';

import CompetencyRow from './CompetencyRow';

const headCells = [
  { label: 'Заявник', numeric: false, disablePadding: false, id: 'c_applicant' },
  { label: 'Дата запиту', numeric: false, disablePadding: false, id: 'd_create' },
  { label: 'Тип запиту', numeric: false, disablePadding: false, id: 'i_type' },
  { label: 'Організація', numeric: false, disablePadding: false, id: 'c_orgname' },
  { label: 'Код ЄДРПОУ', numeric: false, disablePadding: false, id: 'n_edrpou' },
  { label: 'Право підпису', numeric: false, disablePadding: false, id: 'l_sign' },
  { label: 'Бачить вантажі', numeric: false, disablePadding: false, id: 'c_cargo' },
  { label: 'Стан', numeric: false, disablePadding: false, id: 'i_state' },
  { label: 'Перегляд', numeric: false, disablePadding: false, id: 'id' },
];

const useStyles = makeStyles((theme) => ({
  button: {
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
  },
}));

const emptyRow = (
  <TableRow>
    <StyledTableCell colSpan={5} style={{ textAlign: 'center' }}>
      <strong>Відсутні дані для відображення</strong>
    </StyledTableCell>
  </TableRow>
);

const CompetencyTable = ({ tableData, newSubmit, handleChangePage, handleChangeRowsPerPage, history }) => {
  const {
    catalogs: { requestType, requestStatus },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  // sorting
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  return (
    <>
      <Card className="request-main-table">
        <CardHeader
          action={
            <Route
              render={({ history }) => (
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push('/competency-request');
                  }}
                  disabled={!newSubmit}
                >
                  <Icon className="fa fa-plus mr-3" />
                  Подати запит
                </Button>
              )}
            />
          }
        />
        <CardContent>
          <div className="table-responsive bg-white">
            <Table style={{ fontSize: '12px' }} hover="true" size="small">
              <EnhancedTableHead
                classes={{}}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                // rowCount={2}
                headCells={headCells}
              />
              <TableBody>
                {stableSort(tableData.cursor, getComparator(order, orderBy)).map((item, index) => {
                  const { c_orgname, d_create, i_type, i_state, id, n_edrpou, c_applicant, l_sign, c_cargo } = item;
                  return (
                    <TableRow key={id}>
                      <StyledTableCell>{c_applicant}</StyledTableCell>
                      <StyledTableCell>{d_create}</StyledTableCell>
                      <StyledTableCell>{requestType.find(({ value }) => value === i_type)?.label}</StyledTableCell>
                      <StyledTableCell>{c_orgname}</StyledTableCell>
                      <StyledTableCell>{n_edrpou}</StyledTableCell>
                      <StyledTableCell>
                        <Checkbox checked={Boolean(l_sign)} disabled />
                      </StyledTableCell>
                      <StyledTableCell>{c_cargo}</StyledTableCell>
                      <StyledTableCell>{requestStatus.find(({ value }) => value === i_state)?.label}</StyledTableCell>

                      <StyledTableCell>
                        <IconButton
                          id="eyeview"
                          aria-label="Gо to functions"
                          color="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch(setRequestId(id));
                            history.push('/competency-view');
                          }}
                        >
                          <Eye />
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
          <CustomTablePagination
            totalRaws={tableData.total_rows}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={tableData.pn_rows}
            page={tableData.pn_page - 1}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default withRouter(CompetencyTable);

import React from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: '8px',
    borderRadius: '8px !important', // Ensure high specificity
    border: '1px solid rgba(20, 20, 20, 0.1)', // Border color #141414 with 10% opacity
  },
  description: {
    whiteSpace: 'pre-line',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '16px',
  },
  iconWithText: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px', // 8px gap between Typography elements
  },
  icon: {
    marginRight: theme.spacing(1),
    cursor: 'pointer',
  },
  title: {
    fontWeight: 400,
    fontSize: '16px',
  },
}));

export default function InfoPopover() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentText, setCurrentText] = React.useState('');

  const handlePopoverOpen = (event, text) => {
    setAnchorEl(event.currentTarget);
    setCurrentText(text);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setCurrentText('');
  };

  const open = Boolean(anchorEl);

  const textElements = [
    {
      title: 'Як додати причіп?',
      description: `1. Натиснути кнопку «ДОДАТИ ЗАПИС+».
2.  Внести інформацію про тягач та водія.
3. Натиснути кнопку «ДОДАТИ ЗАПИС+».
4. Внести інформацію про причіп та того самого водія, що вказували в тагачі.
5. Натиснути кнопку «ЗБЕРЕГТИ».

Зауважте!
Не можна додати причіп, поки не внесли дані про тягач.`,
    },
    {
      title: 'Як додати залізничний транспорт?',
      description: `1. Натиснути кнопку «ДОДАТИ ЗАПИС+».
2. Внести дані в таблицю:
\u00A0\u00A0\u2022 В полі «Номер» вводити номер вагону/платформи/цистерни.
\u00A0\u00A0\u2022 В полі «Марка» вводити — Потяг №ХХХХ.
\u00A0\u00A0\u2022 В полях «Прізвище», «Ім’я», «По батькові» та «Паспорт»
вводити дані супроводжуючої особи.
3. Натиснути кнопку «ЗБЕРЕГТИ».

Зауважте!
На 1 вагон заповнювати 1 декларацію.`,
    },
    {
      title: 'Як додати перевезення автобусом?',
      description: `1. Натиснути кнопку «ДОДАТИ ЗАПИС+».
2. Внести дані в таблицю:
\u00A0\u00A0\u2022 В полі «Номер» вводити номер автобусу.
\u00A0\u00A0\u2022 В полі «Марка» вводити марку автобусу.
\u00A0\u00A0\u2022 В полях «Прізвище», «Ім’я», «По батькові»
та «Паспорт» вводити дані супроводжуючої особи.
3. Натиснути кнопку «ЗБЕРЕГТИ».

Зауважте!
На 1 автобус заповнювати 1 декларацію.`,
    },
  ];

  return (
    <div>
      {textElements.map((item, index) => (
        <div key={index} className={classes.iconWithText}>
          <InfoOutlinedIcon
            className={classes.icon}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={(e) => handlePopoverOpen(e, item.description)}
            onMouseLeave={handlePopoverClose}
          />
          <Typography className={classes.title}>{item.title}</Typography>
        </div>
      ))}
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography className={classes.description}>{currentText}</Typography>
      </Popover>
    </div>
  );
}

// packages
import React, { Component } from 'react';
import { withRouter } from 'react-router';
// material ui
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// assets
import queryString from 'query-string';
import { connect } from 'react-redux';
// import buttonGov from '../../../assets/images/logo.svg';
import { Button, Card, CardContent, Grid } from '@material-ui/core';
import logo from '../../../assets/images/gov.ua.white.png';
import idgovPng from '../../../assets/images/idgov_logo.png';

// components
import LoginForm from './LoginForm';
// css
import './LoginPage.css';
import { loginUser, setCurrentUser, logoutUser, cryptoLoginUser } from '../../../redux/actions/auth.action';
import { fetchAccess } from '../../../redux/actions/access.action';
import setAuthToken from '../../../vibe/components/utilities/MainUtils/setAuthToken';
import { history } from '../../../vibe/components/utilities/MainUtils/hist';
import isEmpty from '../../../vibe/components/utilities/MainUtils/is-empty';
import { createLoadingSelector, createErrorMessageSelector } from '../../../redux/actions/selectors';
import Instructions1 from '../../../files/Інструкція_Гум_допомога.pdf';

import Instruction from '../../../files/Вхід отримувачів ГД.pdf';

const loadingSelector = createLoadingSelector(['USER']);
const errorSelector = createErrorMessageSelector(['USER']);

const mapStateToProps = (state) => ({
  isFatching: loadingSelector(state),
  error: errorSelector(state),
  errMessage: state.error,
  loading: state.isFatching,
  access: state.access.value,
});

export default withRouter(
  connect(mapStateToProps, { setCurrentUser, loginUser, logoutUser, cryptoLoginUser, fetchAccess })(
    class LoginPage extends Component {
      state = {
        closeLogin: true,
        govUaCenter: true,
        currentTab: 0, // add this
      };

      componentDidMount() {
        const { fetchAccess } = this.props;
        fetchAccess();

        const query = queryString.parse(this.props.location.search);

        if (!isEmpty(query.token)) {
          // const decoded = jwt_decode(query.token);
          localStorage.setItem('jwtToken', query.token);
          // this.props.setCurrentUser(decoded)
          setAuthToken(query.token);
          this.props.loginUser(query.token);
          history.push('/home');
        } else {
          this.props.logoutUser();
        }
      }

      openLogin = () => {
        this.setState((prevState) => ({ closeLogin: !prevState.closeLogin, govUaCenter: !prevState.govUaCenter }));
      };

      handleChange = (event, newValue) => {
        this.setState({ currentTab: newValue });
      };

      render() {
        const { access } = this.props;
        const { currentTab } = this.state; // add this
        return (
          <Card>
            <CardContent>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid item xs={6} container justifyContent="center" alignContent="center">
                  <Button
                    variant="contained"
                    style={{ fontSize: '13px' }}
                    color="primary"
                    href={Instructions1}
                    target="blank"
                  >
                    Інструкція
                  </Button>
                </Grid>
                <Grid item xs={6} container justifyContent="center" alignContent="center">
                  <Button
                    variant="contained"
                    style={{ fontSize: '13px' }}
                    color="primary"
                    href="/analytics"
                    target="blank"
                  >
                    Аналітика
                  </Button>
                </Grid>
              </Grid>
              <Tabs
                value={currentTab}
                onChange={this.handleChange}
                indicatorColor="primary"
                variant="fullWidth"
                centered
                style={{ marginBottom: '40px' }}
              >
                <Tab disableRipple disableFocusRipple label="Для користувачів" />
                {/* <Tab disableRipple disableFocusRipple label="Для адміністраторів" /> */}
              </Tabs>
              {currentTab === 0 && (
                <>
                  <Grid container justifyContent="center" alignContent="center" alignItems="center">
                    <Grid item xs={3}>
                      <span>Вхід з</span>
                    </Grid>
                    <Grid item>
                      <img src={idgovPng} alt="id gov logo" width={204} height={61} />
                    </Grid>
                  </Grid>

                  <Grid container direction="column" alignItems="center" justifyContent="center" alignContent="center">
                    <Grid item xs={12}>
                      <p className="text-center">
                        Інтегрована система електронної ідентифікації, призначена для входу громадянам до державних
                        ресурсів
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" color="primary" href="/aid/generate/1">
                        Увійти через ID.GOV.UA
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
              {/* {currentTab === 1 && (
                <LoginForm
                  cryptoLoginUser={this.props.cryptoLoginUser}
                  isFatching={this.props.loading}
                  error={this.props.errMessage}
                />
              )} */}
            </CardContent>
          </Card>
        );
      }
    }
  )
);

import React from 'react';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// MUI
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button } from '@material-ui/core';

// api
import Swal from 'sweetalert2';
import {
  deleteRecipientFromRegisterApi,
  controlForDeleteRecipientFromRegister,
  getRecipientDocumentsApi,
} from '../../../../api/api';

// swal
import { recipientDocuments, newRecipienApplicationID } from '../../../../redux/actions/recipient.action';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const deleteStatus = 4; // back to status 10 for revision

class DialogAddApplicationDel extends React.Component {
  handleClose = () => {
    this.props.onClose();
  };

  handleControlDelete = async () => {
    try {
      const data = {
        p_id: this.props.id,
        application_type: 4,
        p_user_id: this.props.userId,
      };
      await controlForDeleteRecipientFromRegister(this.props.userId, this.props.id);
      this.props.history.push(`/delete-recipient`);

      const respons = await deleteRecipientFromRegisterApi(data);
      const application_id = respons.data.p_application_id;

      this.props.recipientDocuments(application_id);
      this.props.newRecipienApplicationID(application_id);
    } catch (error) {}
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText style={{ color: '#000' }} id="alert-dialog-description">
              Ви дійсно бажаєте подати заяву на виключення з Реєстру?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleControlDelete()} type="button" variant="contained" color="primary">
              Так
            </Button>
            <Button onClick={this.handleClose} type="button" variant="contained" color="primary">
              Відмінити
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
export default withRouter(
  connect(mapStateToProps, {
    recipientDocuments,
    newRecipienApplicationID,
  })(DialogAddApplicationDel)
);

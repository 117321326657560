import React, { Component } from 'react';
import ToggleSidebarButton from './components/ToggleSidebarButton';
import PageLoader from '../PageLoader/PageLoader';

import { Navbar, NavbarToggler, Collapse, Nav } from 'reactstrap';

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  getPageTitle = () => {
    let name;
    this.props.routes.map((prop) => {
      if (prop.path === this.props.location.pathname) {
        name = prop.name;
      }
      return null;
    });
    return name;
  };

  render() {
    const { isMobile, isSidebarCollapsed, setSidebarCollapsedOn } = this.props;
    const isNotMobile = !isMobile;
    const mobileNavbarClassName = isMobile ? 'mobile-navbar' : undefined;

    return (
      <header className="app-header">
        <SkipToContentLink focusId="primary-content" />
        <div className="top-nav">
          <Navbar color="faded" light expand="md" className={mobileNavbarClassName}>
            {isMobile && (
              <ToggleSidebarButton toggleSidebar={setSidebarCollapsedOn} isSidebarCollapsed={isSidebarCollapsed} />
            )}
            <div className="page-heading" style={{ marginLeft: '20px', fontSize: '16px', fontFace: 'diaHead' }}>
              {this.getPageTitle()}
            </div>
            {isNotMobile && (
              <Nav className="ml-auto" navbar>
                {this.props.children}
              </Nav>
            )}
            {/* <NavbarToggler onClick={this.toggle} /> */}
            {/* <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                {this.props.children}
              </Nav>
            </Collapse> */}
            <PageLoader />
          </Navbar>
        </div>
      </header>
    );
  }
}

const SkipToContentLink = ({ focusId }) => {
  return (
    <a href={`#${focusId}`} tabIndex="1" className="skip-to-content">
      Skip to Content
    </a>
  );
};

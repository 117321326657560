import React from 'react';

const Analytic = () => {
    return (
        <div>
            <iframe title="Гуманітарна допомога" width="100%" height="1500" src="https://app.powerbi.com/view?r=eyJrIjoiODRlOWNjYjctMjdhYi00YzllLWEwODEtMWVkMjUxZTc0N2NiIiwidCI6IjhhZGRkYTA5LTdjMjQtNGRiYi05ZjdmLTIyYTgwZTUyM2NlOSIsImMiOjl9" frameborder="0" allowFullScreen="true"></iframe>
        </div>
    );
};

export default Analytic;
import React, { Component } from 'react';
import classnames from 'classnames';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import Statement from './RegistrationsForms/Statement';
import Plan from './RegistrationsForms/PlanDistribution';
import Documents from './RegistrationsForms/DocumentsLoading';
import DeclarationTab from '../RegisterRecipients/RegistrationFormsRecipient/DeclarationTab';

class AddDelivery extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: !this.getEditRoure() ? '1' : '6',
      registerButton: false,
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  getEditRoure = () => {
    return this.props.location.pathname === '/edit-cargo';
  };

  setRegisterButton = (val) => {
    this.setState({ registerButton: val });
  };

  render() {
    const { registerButton } = this.state;
    return (
      // <Card body>
      <div className="full-bleed">
        <Nav tabs>
          {!this.getEditRoure() && (
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '1' })}
                onClick={() => {
                  this.toggle('1');
                }}
              >
                <span>Вантаж</span>
              </NavLink>
            </NavItem>
          )}
          {/* <NavItem>
                        <NavLink
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => { this.toggle('2'); }}
                        >
                       
                        <span>

                 План-розподіл
                </span>
                        </NavLink>
                    </NavItem> */}
          {/* <NavItem>
            <NavLink
              disabled={!this.props.cargo.i_donor}
              className={classnames({ active: this.state.activeTab === '3' })}
              onClick={() => {
                this.toggle('3');
              }}
            >
              <span>Документи</span>
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink
              disabled={!this.props.cargo.i_donor || this.state.registerButton !== true}
              className={classnames({ active: this.state.activeTab === '6' })}
              onClick={() => {
                this.toggle('6');
              }}
            >
              <span>Декларація</span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <Statement
                  toggle={this.toggle}
                  registerButton={registerButton}
                  setRegisterButton={this.setRegisterButton}
                />
              </Col>
            </Row>
          </TabPane>
          {/* <TabPane tabId="2">
                        <Row>
                        <Col sm="12">
                            <Plan/>
                        </Col>
                        </Row>
                    </TabPane> */}
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <Documents catalog={this.props.catalog} toggle={this.toggle} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="6">
            <Row>
              <Col sm="12">
                <DeclarationTab />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
      // {/* </Card> */}
    );
  }
}

const mapStateToProps = (state) => ({
  cargo: state.cargo.cargoApplication,
  catalog: state.catalogs,
});

export default connect(mapStateToProps, null)(AddDelivery);

import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import clsx from 'clsx';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import BootstrapTooltip from '../../../elements/MaterialBootstrap';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingleft: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

export default function SimpleTable({ acquirer, editAquirer, deleteItemAcquirer, aid, catalog }) {
  const classes = useStyles();

  return (
    <Table size="small" className={classes.table}>
      <TableHead>
        <TableRow>
          <StyledTableCell align="left">Тип набувача</StyledTableCell>
          <StyledTableCell align="left">Найменування набувача</StyledTableCell>
          <StyledTableCell align="left">ЄДРПОУ</StyledTableCell>
          <StyledTableCell align="left">ПІБ</StyledTableCell>
          <StyledTableCell align="left">Місцезнаходження набувача</StyledTableCell>
          <StyledTableCell align="left">Кількість набувачів</StyledTableCell>
          <StyledTableCell align="left">Кількість допомоги</StyledTableCell>
          <StyledTableCell align="left">Обґрунтування</StyledTableCell>
          <StyledTableCell align="left">Вага допомоги, кг</StyledTableCell>
          <StyledTableCell align="left">Дії</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {acquirer.map((row) => {
          return (
            <TableRow component="th" scope="row" key={row.name}>
              <StyledTableCell align="left">
                {catalog.acquirerType.find(({ value }) => Number(value) === row.i_acquirertype)?.label || ''}
              </StyledTableCell>
              <StyledTableCell align="left">{row.c_orgname || '–'}</StyledTableCell>
              <StyledTableCell align="left">{row.n_edrpou || '–'}</StyledTableCell>
              <StyledTableCell align="left">
                {row.c_surname ? `${row.c_surname} ${row.c_name} ${row.c_patronymic}` : '–'}
              </StyledTableCell>
              <StyledTableCell align="left">
                {row.i_region
                  ? [
                      `${catalog.region.find(({ value }) => Number(value) === Number(row.i_region))?.label}`,
                      <br />,
                      `${row.locality_name}`,
                      <br />,
                      `${row.c_adress}`,
                    ]
                  : '–'}
              </StyledTableCell>
              <StyledTableCell align="left">{row.n_acqcnt || '–'}</StyledTableCell>
              <StyledTableCell align="left">{row.n_countplan}</StyledTableCell>
              <StyledTableCell align="left">{row.c_reason}</StyledTableCell>
              <StyledTableCell align="left">{row.p_weight || '-'}</StyledTableCell>
              <StyledTableCell align="left">
                <BootstrapTooltip title="Редагувати дані набувача">
                  <IconButton
                    className="mr-2"
                    color="primary"
                    size="small"
                    variant="contained"
                    edge="end"
                    aria-label="Comments"
                    onClick={() => {
                      editAquirer(row.i_acquire, aid);
                    }}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                </BootstrapTooltip>
                <BootstrapTooltip title="Видалити набувача">
                  <IconButton
                    size="small"
                    variant="contained"
                    edge="end"
                    aria-label="Comments"
                    onClick={() => {
                      deleteItemAcquirer(row.i_acquire);
                    }}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </BootstrapTooltip>
              </StyledTableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

import {
  createAct,
  deleteAct,
  deleteDocAct,
  deleteSoldierAct,
  getActById,
  getActs,
  getDocsByAct,
  getMilitaryFormationTypes,
  getSoldiersByAct,
  saveAct,
  saveDocAct,
  saveSoldierAct,
  signFileAct,
  submitAct,
} from '../../api/actsApi';
import {
  GET_ACTS_REQUEST,
  GET_ACTS_SUCCESS,
  GET_ACTS_FAILURE,
  CREATE_ACT_REQUEST,
  CREATE_ACT_SUCCESS,
  CREATE_ACT_FAILURE,
  SAVE_ACT_REQUEST,
  SAVE_ACT_SUCCESS,
  SAVE_ACT_FAILURE,
  GET_ACT_REQUEST,
  GET_ACT_SUCCESS,
  GET_ACT_FAILURE,
  DELETE_ACT_REQUEST,
  DELETE_ACT_SUCCESS,
  DELETE_ACT_FAILURE,
  DELETE_DOC_ACT_SUCCESS,
  DELETE_DOC_ACT_REQUEST,
  DELETE_DOC_ACT_FAILURE,
  DELETE_SOLDIER_ACT_REQUEST,
  DELETE_SOLDIER_ACT_SUCCESS,
  DELETE_SOLDIER_ACT_FAILURE,
  GET_DOCS_BY_ACT_REQUEST,
  GET_DOCS_BY_ACT_SUCCESS,
  GET_DOCS_BY_ACT_FAILURE,
  GET_SOLDIERS_BY_ACT_REQUEST,
  GET_SOLDIERS_BY_ACT_SUCCESS,
  GET_SOLDIERS_BY_ACT_FAILURE,
  SAVE_DOC_ACT_REQUEST,
  SAVE_DOC_ACT_SUCCESS,
  SAVE_DOC_ACT_FAILURE,
  SAVE_SOLDIER_ACT_SUCCESS,
  SAVE_SOLDIER_ACT_REQUEST,
  SAVE_SOLDIER_ACT_FAILURE,
  SIGN_FILE_ACT_REQUEST,
  SIGN_FILE_ACT_SUCCESS,
  SIGN_FILE_ACT_FAILURE,
  SUBMIT_ACT_REQUEST,
  SUBMIT_ACT_SUCCESS,
  SUBMIT_ACT_FAILURE,
  GET_MILITARY_TYPES_REQUEST,
  GET_MILITARY_TYPES_SUCCESS,
  GET_MILITARY_TYPES_FAILURE,
} from './action-types';

export const actionCreateAct = () => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: CREATE_ACT_REQUEST });
      try {
        const response = await createAct();
        dispatch({ type: CREATE_ACT_SUCCESS, payload: response.data });
        resolve(response.data);
      } catch (error) {
        dispatch({ type: CREATE_ACT_FAILURE, payload: error.message });
        reject(error.message);
      }
    });
  };
};

export const actionDeleteAct = (id) => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: DELETE_ACT_REQUEST });
      try {
        const response = await deleteAct(id);
        dispatch({ type: DELETE_ACT_SUCCESS, payload: response.data });
        resolve(response.data);
      } catch (error) {
        dispatch({ type: DELETE_ACT_FAILURE, payload: error.message });
        reject(error.message);
      }
    });
  };
};

export const actionDeleteDocAct = (id) => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: DELETE_DOC_ACT_REQUEST });
      try {
        const response = await deleteDocAct(id);
        dispatch({ type: DELETE_DOC_ACT_SUCCESS, payload: response.data });
        resolve(response.data);
      } catch (error) {
        dispatch({ type: DELETE_DOC_ACT_FAILURE, payload: error.message });
        reject(error.message);
      }
    });
  };
};

export const actionDeleteSoldierAct = (id) => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: DELETE_SOLDIER_ACT_REQUEST });
      try {
        const response = await deleteSoldierAct(id);
        dispatch({ type: DELETE_SOLDIER_ACT_SUCCESS, payload: response.data });
        resolve(response.data);
      } catch (error) {
        dispatch({ type: DELETE_SOLDIER_ACT_FAILURE, payload: error.message });
        reject(error.message);
      }
    });
  };
};

export const actionGetAct = (id) => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: GET_ACT_REQUEST });
      try {
        const response = await getActById(id);
        dispatch({ type: GET_ACT_SUCCESS, payload: response.data });
        resolve(response.data);
      } catch (error) {
        dispatch({ type: GET_ACT_FAILURE, payload: error.message });
        reject(error.message);
      }
    });
  };
};

export const actionGetActs = (filter, status) => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: GET_ACTS_REQUEST });
      try {
        const response = await getActs(filter, status);
        dispatch({ type: GET_ACTS_SUCCESS, payload: response.data });
        resolve(response.data);
      } catch (error) {
        dispatch({ type: GET_ACTS_FAILURE, payload: error.message });
        reject(error.message);
      }
    });
  };
};

export const actionGetDocsByAct = (id) => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: GET_DOCS_BY_ACT_REQUEST });
      try {
        const response = await getDocsByAct(id);
        dispatch({ type: GET_DOCS_BY_ACT_SUCCESS, payload: response.data });
        resolve(response.data);
      } catch (error) {
        dispatch({ type: GET_DOCS_BY_ACT_FAILURE, payload: error.message });
        reject(error.message);
      }
    });
  };
};

export const actionGetSoldiersByAct = (id) => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: GET_SOLDIERS_BY_ACT_REQUEST });
      try {
        const response = await getSoldiersByAct(id);
        dispatch({ type: GET_SOLDIERS_BY_ACT_SUCCESS, payload: response.data });
        resolve(response.data);
      } catch (error) {
        dispatch({ type: GET_SOLDIERS_BY_ACT_FAILURE, payload: error.message });
        reject(error.message);
      }
    });
  };
};

export const actionGetMilitaryStates = () => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: GET_MILITARY_TYPES_REQUEST });
      try {
        const response = await getMilitaryFormationTypes();
        dispatch({ type: GET_MILITARY_TYPES_SUCCESS, payload: response.data });
        resolve(response.data);
      } catch (error) {
        dispatch({ type: GET_MILITARY_TYPES_FAILURE, payload: error.message });
        reject(error.message);
      }
    });
  };
};

export const actionSaveAct = (data) => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: SAVE_ACT_REQUEST });
      try {
        const response = await saveAct(data);
        dispatch({ type: SAVE_ACT_SUCCESS, payload: response.data });
        resolve(response.data); // Resolve the promise with response data
      } catch (error) {
        dispatch({ type: SAVE_ACT_FAILURE, payload: error.message });
        reject(error.message); // Reject the promise with error message
      }
    });
  };
};

export const actionSaveDocAct = (data) => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: SAVE_DOC_ACT_REQUEST });
      try {
        const response = await saveDocAct(data);
        dispatch({ type: SAVE_DOC_ACT_SUCCESS, payload: response.data });
        resolve(response.data);
      } catch (error) {
        dispatch({ type: SAVE_DOC_ACT_FAILURE, payload: error.message });
        reject(error.message);
      }
    });
  };
};

export const actionSaveSoldierAct = (id) => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: SAVE_SOLDIER_ACT_REQUEST });
      try {
        const response = await saveSoldierAct(id);
        dispatch({ type: SAVE_SOLDIER_ACT_SUCCESS, payload: response.data });
        resolve(response.data);
      } catch (error) {
        dispatch({ type: SAVE_SOLDIER_ACT_FAILURE, payload: error.message });
        reject(error.message);
      }
    });
  };
};

export const actionSignFileAct = (id) => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: SIGN_FILE_ACT_REQUEST });
      try {
        const response = await signFileAct(id);
        dispatch({ type: SIGN_FILE_ACT_SUCCESS, payload: response.data });
        resolve(response.data);
      } catch (error) {
        dispatch({ type: SIGN_FILE_ACT_FAILURE, payload: error.message });
        reject(error.message);
      }
    });
  };
};

export const actionSubmitAct = (id) => {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: SUBMIT_ACT_REQUEST });
      try {
        const response = await submitAct(id);
        dispatch({ type: SUBMIT_ACT_SUCCESS, payload: response.data });
        resolve(response.data);
      } catch (error) {
        dispatch({ type: SUBMIT_ACT_FAILURE, payload: error.message });
        reject(error.message);
      }
    });
  };
};

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { getDeclaration2, saveSign, downloadZip } from '../../../../api/api';
import getPage from './getPDFfile';
import { EndUser } from './utils/eusign';
// const EndUser = require('./utils/eusign');

function convertDataURIToBinary(dataURI) {
  const raw = window.atob(dataURI);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i += 1) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}

const StartWidget = () => {
  const {
    cargo: {
      cargoApplication: { i_cargo: cargoId },
    },
  } = useSelector((state) => state);

  // const dispatch = useDispatch();
  const [euSign, setEuSign] = React.useState();
  const [string64, setString64] = React.useState();
  const [myblob, setMyblob] = React.useState();
  const [dataSign, setDataSign] = React.useState([]);
  const [signature, setSignature] = React.useState();

  const getSigned = async (file64) => {
    const previousSign = null;
    const external = true;
    const asBase64String = true;
    const signAlgo = EndUser.SignAlgo.DSTU4145WithGOST34311;
    const signType = 16;
    const URI_BINARY = convertDataURIToBinary(file64);
    euSign.SignData(URI_BINARY, external, asBase64String, signAlgo, null, signType).then((sign) => {
      setSignature(sign);
    });
  };

  const initWidget = () => {
    // const EndUserInstance = Object.values(EndUser)[0];

    const SIGN_WIDGET_PARENT_ID = 'sign-widget-parent';
    /*
	Ідентифікатор iframe, який завантажує сторінку SignWidget
*/
    const SIGN_WIDGET_ID = 'sign-widget';
    /*
	URI з адресою за якою розташована сторінка SignWidget
*/
    const SIGN_WIDGET_URI = 'https://test.id.gov.ua/sign-widget/v2022testnew/';

    /*
	Створення об'єкту типу EndUser для взаємодії з iframe, 
	який завантажує сторінку SignWidget
*/
    // const euSign1 = new EndUser(SIGN_WIDGET_PARENT_ID, SIGN_WIDGET_ID, SIGN_WIDGET_URI, EndUser.FORM_TYPE_READ_PKEY);
    const euSign1 = new EndUser(
      SIGN_WIDGET_PARENT_ID,
      SIGN_WIDGET_ID,
      SIGN_WIDGET_URI,
      EndUser.FormType.ReadPKey,
      EndUser.FormParams
    );
    setEuSign(euSign1);
    return euSign1;
  };

  const handler = (event) => {
    // console.log('event message', event);
  };

  const signer = (event) => {
    event.preventDefault();
    euSign
      .ReadPrivateKey()
      .then((res) => {
        return res;
      })
      .then((res) => {
        if (string64) {
          getSigned(string64);
        } else {
          // eslint-disable-next-line no-console
          // console.log('no 64');
        }
      });
  };

  React.useEffect(() => {
    const timer = setTimeout(() => initWidget(), 1000);
    window.addEventListener('message', handler);
    return () => {
      clearTimeout(timer);
      // euSign.destroy();
      window.removeEventListener('message', handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDeclaration = () => {
    getDeclaration2(cargoId)
      .then((res) => {
        const { data } = res;
        return getPage(data);
      })
      .then((file) => {
        file.getBase64((data) => {
          setString64(data);
        });
        file.getBlob((blob) => {
          setMyblob(blob);
        });
      })
      .catch((err) => {});
  };

  // eslint-disable-next-line consistent-return
  const readKey = async () => {
    try {
      const data = await euSign.ReadPrivateKey();
      setDataSign(data);
      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const downloadFile = () => {
    // const url = URL.createObjectURL(load);
    const link = document.createElement('a');
    // link.href = url;
    link.setAttribute('download', 'archive.zip');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const sendFileWithSignature = async () => {
    const formData = new FormData();
    // const data = { sign, fileName: 'scan.zip' };
    // formData.append('data', JSON.stringify(data));
    // const binary = convertDataURIToBinary(zip);
    // const blob = new Blob([binary], { type: 'application/zip' });
    const file = new File([myblob], 'declaration.pdf', {
      type: 'application/pdf',
    });

    // formData.append('file', blob, 'scan.zip');
    formData.append('file', file);
    formData.append('sign', signature);

    try {
      // const res = await signScanFile(formData);
      const res = await saveSign(cargoId, formData);
      // const { id } = res.data;
      // setScan(id);
    } catch (err) {
      console.log(err);
    }
  };

  const downloadZipArchive = async () => {
    const res = await downloadZip(cargoId);
    const blob = new Blob([res.data], { type: 'application/zip' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'files.zip');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  React.useEffect(() => {
    openDeclaration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cargoId]);

  // React.useEffect(() => {
  //   if (string64) {
  //   }
  // }, [string64]);

  React.useEffect(() => {
    if (euSign) {
      // eslint-disable-next-line no-console
      readKey();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [euSign]);

  React.useEffect(() => {
    if (dataSign.length) {
      // getSigned(string64);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSign]);

  React.useEffect(() => {
    if (signature) {
      sendFileWithSignature();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signature]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div id="sign-widget-parent" style={{ width: '700px', height: '800px', border: '1px solid green' }} />

      <div>
        {euSign && (
          <Button variant="contained" onClick={signer}>
            Підписати
          </Button>
        )}
        {euSign && (
          <Button variant="contained" onClick={() => downloadZipArchive()}>
            Отримати
          </Button>
        )}
      </div>
    </div>
  );
};

export default StartWidget;

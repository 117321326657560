function isNumeric(value) {
  return /^[+-]?([0-9]*[.])?[0-9]+$/.test(value);
}

const validDate = (str) => {
  if (!str) return false;

  return /^\d{2}\.\d{2}\.\d{4}$/.test(str);
};

const dateToObject = (strDate) => {
  if (strDate) {
    const [day, month, year] = strDate.split('.');
    return new Date(+year, +month - 1, +day);
  }

  return null;
};

// sorting
export const descendingComparator = (a, b, orderBy) => {
  // if (b[orderBy] < a[orderBy]) {
  //   return -1;
  // }
  // if (b[orderBy] > a[orderBy]) {
  //   return 1;
  // }
  // return 0;
  if (!a[orderBy] && !b[orderBy]) return 0;
  if (a[orderBy] && !b[orderBy]) return -1;
  if (!a[orderBy] && b[orderBy]) return 1;

  if (validDate(b[orderBy]) && validDate(a[orderBy])) {
    const x = dateToObject(a[orderBy]);
    const y = dateToObject(b[orderBy]);

    if (y < x) {
      return -1;
    }
    if (y > x) {
      return 1;
    }
  }

  if (isNumeric(b[orderBy]) && isNumeric(a[orderBy])) {
    const x = +a[orderBy];
    const y = +b[orderBy];
    if (y < x) {
      return -1;
    }
    if (y > x) {
      return 1;
    }
    return 0;
  }

  if (b[orderBy] && a[orderBy]) return b[orderBy]?.toString()?.localeCompare(a[orderBy]?.toString());

  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

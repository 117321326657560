import React, { useEffect, useState } from 'react';
import './EditAct.scss';
import { useDispatch, useSelector } from 'react-redux';
import { format, parse, isValid } from 'date-fns';
import { Button, MenuItem, TextField } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import uk from 'date-fns/locale/uk';
import { history } from '../../../../vibe/components/utilities/MainUtils/hist';
import {
  actionDeleteDocAct,
  actionDeleteSoldierAct,
  actionGetAct,
  actionSaveAct,
  actionSaveDocAct,
  actionSaveSoldierAct,
} from '../../../../redux/actions/acts.action';
import { getStatusTextAndClass, VisuallyHiddenInput } from '../Acts.utils';

const EditAct = () => {
  const { act, savedActLoading, savedSoldierLoading, savedDocLoading, militaryTypes } = useSelector(
    (state) => state.acts
  );
  const [formActData, setFormActData] = useState({
    actNumber: act.general[0].c_act_num,
    actDate: isValid(parse(act.general[0].d_act, 'dd.MM.yyyy', new Date()))
      ? parse(act.general[0].d_act, 'dd.MM.yyyy', new Date())
      : null,
    actBasedOn: act.general[0].c_based_on,
    vinCode: act.car[0].i_car,
    unitCost: act.car[0].n_price,
    totalCost: act.car[0].n_value,
    militaryName: act.acquirer[0].sld_pib,
    formationType: act.acquirer[0].i_mu_type,
    formationNumber: act.acquirer[0].mil_num,
    formationEmail: act.acquirer[0].mil_email,
    militaryRNOCP: act.acquirer[0].c_sri,
    militaryTicketNumber: act.acquirer[0].sld_id,
    militaryEmail: act.acquirer[0].sld_email,
  });
  const [formMilitaryData, setFormMilitaryData] = useState({
    militaryName: null,
    militaryTicketNumber: null,
    militaryEmail: null,
  });
  const [vinCodes, setVinCodes] = useState([]);
  const [car, setCar] = useState(null);
  const [cars, setCars] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (act) {
      setVinCodes(act.spr_tr || []);
      setCars(act.TR || []);
      setCar(act.car[0]);
    }
  }, [act]);

  const handleDateChange = (date) => {
    setFormActData({ ...formActData, actDate: date });
  };

  const handleInputChange = (event, form, setForm) => {
    let { name, value } = event.target;
    switch (name) {
      case 'formationEmail':
      case 'militaryEmail': {
        let atIndex = value.indexOf('@');
        let dotIndex = value.lastIndexOf('.');
        let newValue = value;
        if (atIndex === -1 && value.length > 0) {
          newValue = value + '@' + '.';
        }
        if (dotIndex === -1 && atIndex !== -1 && value.length > atIndex + 1) {
          newValue = value.substring(0, atIndex + 1) + value.substring(atIndex + 1) + '.';
        }
        setForm({
          ...form,
          [name]: newValue,
        });
        break;
      }
      case 'militaryRNOCP': {
        const maskedValue = value.replace(/[^\d.]/g, '');
        setForm({
          ...form,
          [name]: maskedValue,
        });
        break;
      }
      case 'militaryTicketNumber': {
        let letters = '';
        let numbers = '';
        letters = value.replace(/[^a-zA-Zа-яА-ЯёЁ]/g, '').substring(0, 2);
        if (letters.length === 2) {
          numbers = value
            .substring(2)
            .replace(/[^0-9]/g, '')
            .substring(0, 6);
        }
        const maskedValue = letters + numbers;
        setForm({
          ...form,
          [name]: maskedValue,
        });
        break;
      }
      case 'vinCode': {
        setCar(cars.find((с) => с.id === value));
        setForm({
          ...form,
          [name]: value,
        });
        break;
      }
      case 'totalCost':
      case 'unitCost': {
        let maskedValue = value.replace(/[^\d.]/g, '');
        const parts = maskedValue.split('.');
        if (parts.length > 1) {
          maskedValue = `${parts[0]}.${parts[1].slice(0, 4)}`;
        }
        setForm({
          ...form,
          [name]: maskedValue,
        });
        break;
      }
      default: {
        setForm({
          ...form,
          [name]: value,
        });
        break;
      }
    }
  };

  const actFormSubmit = (event) => {
    event.preventDefault();
    const actData = {
      pi_act: act.general[0].i_act,
      pc_act_num: formActData.actNumber,
      pd_act: formActData.actDate ? format(new Date(formActData.actDate), 'dd.MM.yyyy') : null,
      pi_mu_type: formActData.formationType,
      pc_mil_num: formActData.formationNumber,
      pc_mil_email: formActData.formationEmail,
      pc_sld_PIB: formActData.militaryName,
      pc_sld_ID: formActData.militaryTicketNumber,
      pc_sld_email: formActData.militaryEmail,
      pi_car: formActData.vinCode,
      pn_price: formActData.unitCost,
      pn_value: formActData.totalCost,
      pc_sld_sri: formActData.militaryRNOCP,
      pc_based_on: formActData.actBasedOn,
    };
    dispatch(actionSaveAct(actData))
      .then(() => {
        history.push('/act');
      })
      .catch((error) => {
        console.error('Failed to save act:', error);
      });
  };

  const militaryFormSubmit = (event) => {
    event.preventDefault();
    const data = {
      pi_soldier: null,
      pi_act: act.general[0].i_act,
      pc_PIB: formMilitaryData.militaryName,
      pc_ID_num: formMilitaryData.militaryTicketNumber,
      pc_email: formMilitaryData.militaryEmail,
    };
    dispatch(actionSaveSoldierAct(data))
      .then(() => {
        dispatch(actionGetAct(act.general[0].i_act));
      })
      .catch((error) => {
        console.error(error);
      });
    setFormMilitaryData({
      militaryName: '',
      militaryTicketNumber: '',
      militaryEmail: '',
    });
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('pc_file_ext', 'pdf');
    formData.append('pi_act', act.general[0].i_act);
    formData.append('pi_doc_type', '60');
    dispatch(actionSaveDocAct(formData))
      .then(() => {
        dispatch(actionGetAct(act.general[0].i_act));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const removeDoc = (id) => {
    dispatch(actionDeleteDocAct(id))
      .then(() => {
        dispatch(actionGetAct(act.general[0].i_act));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const removeSoldier = (id) => {
    dispatch(actionDeleteSoldierAct(id))
      .then(() => {
        dispatch(actionGetAct(act.general[0].i_act));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const isFormComplete = (form) => {
    return Object.values(form).every((value) => value !== null && value !== '');
  };

  return (
    <div className="edit-act">
      <form onSubmit={actFormSubmit}>
        <div className="edit-act__head">
          <div className="edit-act__head__info">
            <div className="edit-act__head__info__left-part">
              <div className="info__return-button" onClick={(e) => history.goBack()} />
              <div className="info__title">Акт №{act.general[0].c_act_num}</div>
            </div>
            <div className={getStatusTextAndClass(act.general[0].i_state).valueClass}>
              {getStatusTextAndClass(act.general[0].i_state).text}
            </div>
          </div>
          <div className="act__head__date">
            <div className="date__title">Дата підписання:</div>
            <div className="date__value">{act.general[0].d_act}</div>
          </div>
        </div>
        <div className="edit-act__main">
          <div className="edit-act__main__form-left-part">
            <div className="form-section">
              <div className="form-section__title">Загальні дані</div>
              <TextField
                className="form-section__field"
                type="text"
                name="actNumber"
                value={formActData.actNumber}
                inputProps={{ maxLength: 20 }}
                label="№ акту"
                onChange={(e) => handleInputChange(e, formActData, setFormActData)}
              />
              <DatePicker
                className="form-section__field menu-dropdown"
                selected={formActData.actDate}
                onChange={(date) => handleDateChange(date)}
                dateFormat="dd.MM.yyyy"
                autoComplete="off"
                locale={uk}
                customInput={
                  <TextField
                    name="actDate"
                    label="Дата підписання акту"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                }
              />
              <TextField
                className="form-section__field"
                type="text"
                name="actBasedOn"
                value={formActData.actBasedOn}
                label="Отримувач діє на підставі"
                onChange={(e) => handleInputChange(e, formActData, setFormActData)}
              />
            </div>
            <div className="form-section">
              <div className="form-section__title">Дані транспортного засобу</div>
              <TextField
                className="form-section__field menu-dropdown"
                select
                type="text"
                name="vinCode"
                value={formActData.vinCode}
                label="Обрати VIN-код"
                onChange={(e) => handleInputChange(e, formActData, setFormActData)}
              >
                {vinCodes.length > 0 ? (
                  vinCodes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{option.label}</div>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No options available</MenuItem>
                )}
              </TextField>
              {car !== null && car !== undefined && (
                <div className="car">
                  <div className="details__detail">
                    <div className="detail__lable">Марка</div>
                    <div className="detail__value">{car.c_brand || '-'}</div>
                  </div>
                  <div className="details__detail">
                    <div className="detail__lable">Модель</div>
                    <div className="detail__value">{car.c_model || '-'}</div>
                  </div>
                  <div className="details__detail">
                    <div className="detail__lable">VIN-код</div>
                    <div className="detail__value">{car.c_vin || '-'}</div>
                  </div>
                </div>
              )}
              <TextField
                className="form-section__field"
                type="text"
                name="unitCost"
                value={formActData.unitCost}
                label="Вартість за 1 шт., грн"
                onChange={(e) => handleInputChange(e, formActData, setFormActData)}
              />
              <TextField
                className="form-section__field"
                type="text"
                name="totalCost"
                value={formActData.totalCost}
                label="Загальна вартість, грн"
                onChange={(e) => handleInputChange(e, formActData, setFormActData)}
              />
            </div>
            <div className="form-section">
              <div className="form-section__title short">Завантажити підписаний акт приймання-передачі ТЗ</div>
              {act.doc.length > 0 &&
                act.doc.map((doc) => (
                  <div className="file">
                    <div className="file__img" />
                    <a
                      href={`/aid/file/${doc.c_filename}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="file__value"
                    >
                      {doc.c_filename}
                    </a>
                    <div className="delete-button" onClick={() => removeDoc(doc.id)} />
                  </div>
                ))}
              {act.doc && act.doc.length < 2 && (
                <>
                  <Button className="add-file" component="label" disabled={savedDocLoading}>
                    {savedDocLoading ? 'ЗАГРУЗКА...' : 'ЗАВАНТАЖИТИ'}
                    <VisuallyHiddenInput
                      type="file"
                      accept=".pdf, .png, .jpg, .zip"
                      onChange={handleFileSelect}
                      disabled={savedDocLoading}
                    />
                  </Button>
                  <div className="file-desc">Можливі формати документу .zip, .pdf, .png, .jpg</div>
                  <div className="file-desc">Максимальний розмір файлу 5 Мб</div>
                </>
              )}
            </div>
            {act.recipient[0] ? (
              <div className="details">
                <div className="details__title">Дані отримувача</div>
                <div className="details__detail">
                  <div className="detail__lable">Назва організації</div>
                  <div className="detail__value">{act.recipient[0].c_orgname}</div>
                </div>
                <div className="details__detail">
                  <div className="detail__lable">ПІБ уповноваженої особи</div>
                  <div className="detail__value">{act.recipient[0].c_pib}</div>
                </div>
                <div className="details__detail">
                  <div className="detail__lable">Посада уповноваженої особи</div>
                  <div className="detail__value">{act.recipient[0].c_job_title}</div>
                </div>
                <div className="details__detail">
                  <div className="detail__lable">E-mail організації</div>
                  <div className="detail__value">{act.recipient[0].c_email}</div>
                </div>
                <div className="details__detail">
                  <div className="detail__lable">Код ЄДРПОУ організації</div>
                  <div className="detail__value">{act.recipient[0].n_edrpou}</div>
                </div>
                <div className="details__detail">
                  <div className="detail__lable">Юридична адреса організації</div>
                  <div className="detail__value">{act.recipient[0].c_adress}</div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="create-act__main__form-right-part">
            <div className="form-section">
              <div className="form-section__title">Дані набувача</div>
              <TextField
                className="form-section__field"
                type="text"
                name="militaryName"
                value={formActData.militaryName}
                label="ПІБ військовослужбовця"
                onChange={(e) => handleInputChange(e, formActData, setFormActData)}
              />
              <TextField
                className="form-section__field menu-dropdown"
                type="text"
                select
                name="formationType"
                value={formActData.formationType}
                label="Тип військового формування"
                onChange={(e) => handleInputChange(e, formActData, setFormActData)}
              >
                {militaryTypes.length > 0 ? (
                  militaryTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{option.label}</div>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No options available</MenuItem>
                )}
              </TextField>
              <TextField
                className="form-section__field"
                type="text"
                name="formationNumber"
                value={formActData.formationNumber}
                label="Номер військового формування"
                onChange={(e) => handleInputChange(e, formActData, setFormActData)}
              />
              <TextField
                className="form-section__field"
                type="text"
                name="formationEmail"
                value={formActData.formationEmail}
                label="E-mail військового формування"
                onChange={(e) => handleInputChange(e, formActData, setFormActData)}
              />
              <TextField
                className="form-section__field"
                type="text"
                name="militaryRNOCP"
                value={formActData.militaryRNOCP}
                label="РНОКПО військовослужбовця"
                inputProps={{ maxLength: 10 }}
                onChange={(e) => handleInputChange(e, formActData, setFormActData)}
              />
              <TextField
                className="form-section__field"
                type="text"
                name="militaryTicketNumber"
                value={formActData.militaryTicketNumber}
                label="Номер військового квитка"
                inputProps={{ maxLength: 11 }}
                onChange={(e) => handleInputChange(e, formActData, setFormActData)}
              />
              <TextField
                className="form-section__field"
                type="text"
                name="militaryEmail"
                value={formActData.militaryEmail}
                label="E-mail військовослужбовця"
                onChange={(e) => handleInputChange(e, formActData, setFormActData)}
              />
            </div>
            <div className="form-section">
              <div className="form-section__title short">
                Військовослужбовці, які мають право на відомчу перереєстрацію
              </div>
              <div className="detail__militaries">
                {act.soldier.length > 0 &&
                  act.soldier.map((soldier, index) => (
                    <div className="military" key={index}>
                      <div className="military__header">
                        <div className="military__title">#{index + 1} ВІЙСЬКОВОСЛУЖБОВЕЦЬ</div>
                        <div className="delete-button" onClick={() => removeSoldier(soldier.i_soldier)} />
                      </div>
                      <div className="details__detail">
                        <div className="detail__lable">ПІБ військовослужбовця</div>
                        <div className="detail__value">{soldier.c_pib}</div>
                      </div>
                      <div className="details__detail">
                        <div className="detail__lable">Номер військового квитка</div>
                        <div className="detail__value">{soldier.c_id_num}</div>
                      </div>
                      <div className="details__detail">
                        <div className="detail__lable">E-mail військовослужбовця</div>
                        <div className="detail__value">{soldier.c_email}</div>
                      </div>
                    </div>
                  ))}
              </div>
              {act.soldier && act.soldier.length < 5 && (
                <>
                  <TextField
                    className="form-section__field"
                    type="text"
                    name="militaryName"
                    value={formMilitaryData.militaryName}
                    label="ПІБ військовослужбовця"
                    onChange={(e) => handleInputChange(e, formMilitaryData, setFormMilitaryData)}
                  />
                  <TextField
                    className="form-section__field"
                    type="text"
                    name="militaryTicketNumber"
                    value={formMilitaryData.militaryTicketNumber}
                    inputProps={{ maxLength: 11 }}
                    label="Номер військового квитка"
                    onChange={(e) => handleInputChange(e, formMilitaryData, setFormMilitaryData)}
                  />
                  <TextField
                    className="form-section__field"
                    type="text"
                    name="militaryEmail"
                    value={formMilitaryData.militaryEmail}
                    label="E-mail військовослужбовця"
                    onChange={(e) => handleInputChange(e, formMilitaryData, setFormMilitaryData)}
                  />
                </>
              )}
            </div>
            {act.soldier && act.soldier.length < 5 && (
              <Button
                type="button"
                variant="contained"
                color="primary"
                className="add-soldier"
                onClick={militaryFormSubmit}
                disabled={!isFormComplete(formMilitaryData) || savedSoldierLoading}
              >
                {savedSoldierLoading ? 'ЗАГРУЗКА...' : 'ДОДАТИ'}
              </Button>
            )}
          </div>
        </div>
        <div className="container-button">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="create-button"
            disabled={!isFormComplete(formActData) || savedActLoading}
          >
            {savedActLoading ? 'ЗАГРУЗКА...' : 'ЗБЕРЕГТИ'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditAct;

import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import ReactGA from 'react-ga4';
import * as serviceWorker from './registerServiceWorker';
import App from './App';

import isEmpty from './vibe/components/utilities/MainUtils/is-empty';
import { history } from './vibe/components/utilities/MainUtils/hist';
import setAuthToken from './vibe/components/utilities/MainUtils/setAuthToken';

// ReactGA.initialize('UA-175675982-1');
// ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA.initialize('G-Y9380KN42K');

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});
axios.interceptors.request.use(
  (response) => {
    if (!isEmpty(response.headers.common.Authorization)) {
      const token = response.headers.common.Authorization;
      const clearToken = token.substr(token.indexOf(' ') + 1);
      setAuthToken(token);
    }
    return response;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 400) {
      swalWithBootstrapButtons.fire({
        title: 'Помилка',
        text: error.response.data,
        type: 'error',
      });
      return Promise.reject(error);
    }
    if (error.response.status === 401) {
      swalWithBootstrapButtons
        .fire({
          title: `Час сесії закінчився.
          Авторизуйтесь повторно.`,
        })
        .then((result) => {
          if (result.value) {
            history.push('/');
          }
        });
      return Promise.reject(error);
    }
    if (error.response.status === 500) {
      swalWithBootstrapButtons.fire({
        text: 'Помилка сервера',
        type: 'error',
      });
      // , function(){

      // });
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(<App />, document.getElementById('app'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<App />);

import React from 'react';
import { withRouter } from 'react-router';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import * as Feather from 'react-feather';
import BootstrapTooltip from '../../../elements/MaterialBootstrap';
import { Card, CardBody, CardFooter } from 'reactstrap';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getmeetingByRecipient } from '../../../../api/api';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

const HumanProtokol = ({ history }) => {
  const [data, getData] = React.useState([]);

  React.useEffect(() => {
    const handlePrntedDocs = async () => {
      try {
        const response = await getmeetingByRecipient();
        getData(response.data);
      } catch (error) {
        history.goBack();
      }

      handlePrntedDocs();
    };
  });

  const classes = useStyles();

  return (
    <div>
      <Card>
        <CardBody>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Тип заяви</StyledTableCell>
                <StyledTableCell align="left">Накази</StyledTableCell>
                <StyledTableCell align="left">Протоколи</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow component="th" scope="row" key={row.name}>
                  <StyledTableCell align="left">{`${row.c_surname} ${row.c_name} ${row.c_patronymic}`}</StyledTableCell>
                  <StyledTableCell align="left">{row.c_orgname}</StyledTableCell>
                  <StyledTableCell align="left">{row.c_adress}</StyledTableCell>
                  <StyledTableCell align="left">{row.n_countplan}</StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardBody>
        <CardFooter>
          <BootstrapTooltip title="вихід">
            <IconButton onClick={() => history.push('/view-commission')}>
              <Feather.LogOut style={{ transform: 'scaleX(-1)' }} size="25" />
            </IconButton>
          </BootstrapTooltip>
        </CardFooter>
      </Card>
    </div>
  );
};

export default withRouter(HumanProtokol);

// packages
import React, { Component } from 'react';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import ReactSVG from 'react-svg';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withRouter } from 'react-router';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import uk from 'date-fns/locale/uk';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import rules from '../../../../vibe/components/utilities/MainUtils/rules';
import { getCargoDocuments, saveCargoDocuments, deleteCargoDocument } from '../../../../redux/actions/cargo.action';
// assets
import uploadTable from '../../../../assets/images/download.svg';

// for select docs

import { customTheme, customStyles } from '../../../../customStyles/reactSelect';

// date
import 'react-datepicker/dist/react-datepicker.css';

import { Loader } from '../../../../vibe';
import isEmpty from '../../../../vibe/components/utilities/MainUtils/is-empty';
import { createLoadingSelector, createErrorMessageSelector, clearErrors } from '../../../../redux/actions/selectors';
import { Button, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/core/styles';

import vector from '../../../../assets/images/Vector.png';
import next from '../../../../assets/images/next.png';
import save from '../../../../assets/images/save.png';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const useStyles = (theme) => ({
  choice_sections: {
    width: '95%',
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '20px',
  },
  choice_item: {
    width: '30%',
  },
  border_dowload: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '60px 30px',
    width: '90%',
    border: '2px dashed #5B5AFF',
    margin: '50px auto',
  },
  button_loading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 70px',
    gap: '4px',
    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
    },
  },
  button_loading_text: {
    width: '119px',
    height: '24px',

    fontFamily: 'diaHead',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    /* identical to box height, or 150% */

    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',

    color: '#FFFFFF',
    '&:hover': {
      color: '#141414',
    },
  },
  textFile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 0px',
    width: '100%',
    fontFamily: 'diaHead',
    fontStyle: 'normal',
    fontWeight: '200',
    fontSize: '16px',
    lineHeight: '24px',
  },
  file_sections: {
    padding: '20px 0px',
    width: '90%',
    display: 'flex',
    justifyContent: 'flex-start',
    // margin: 'auto',
  },
  button_save: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 70px',
    gap: '4px',

    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',
    margin: '30px auto',

    fontFamily: 'diaHead',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    // display: 'flex',
    // alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',

    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
  },
  button_save_text: {
    fontFamily: 'diaHead',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    '&:hover': {
      color: '#141414',
    },
  },
  title_text: {
    fontFamily: 'diaHead',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '24px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  button_loading_img: {
    marginLeft: '15px',
  },
});

class DocumentsLoading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: '',
      fileSize: '',
      fileNameUser: '',
      doctype2: {},
      submitted: false,
      p_docname: '',
      p_dt_numb: '',
      customDate: null,
    };
    this.fileInput = React.createRef();
  }

  handleSimpleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleInputChange = (e) => {
    if (e.target.value === '') {
      this.setState({
        fileNameUser: e.target.value,
      });
    } else {
      rules.orgName.test(e.target.value.toUpperCase()) &&
        this.setState({
          fileNameUser: e.target.value.toUpperCase(),
        });
    }
  };

  // datepicker
  handleChangeDate = (dateObj) => {
    this.setState({ customDate: dateObj });
  };

  // for document select
  // handleSelectChange = (value) => {
  //   this.setState({
  //     doctype2: value,
  //   });
  // };
  handleSelectChange = (e, value) => {
    if (value) {
      this.setState({
        doctype2: value,
      });
    } else {
      this.setState({
        doctype2: {},
      });
    }
  };

  handleChangeFileInput(e) {
    this.setState({ fileName: '' });
    e.target.files[0] && this.setState({ fileName: e.target.files[0].name, fileSize: e.target.files[0].size });
  }

  deleteDocument = (id) => {
    this.props.deleteCargoDocument(id);
  };

  submitDocument = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
    if (this.fileInput.current.files[0] && this.state.doctype2?.value) {
      this.setState({ submitted: false });

      swalWithBootstrapButtons.fire({
        text: 'Перейдіть "Далі" та на вкладці "Декларація" натисніть "Зареєструвати"',
        showCancelButton: false,
        confirmButtonText: 'Ок',
        cancelButtonText: 'Відміна',
      });

      const info = {
        id: 0,
        p_application: this.props.cargo.i_cargo_app,
        p_doctype: this.state.doctype2?.value || 0,
      };
      const { p_docname, p_dt_numb, customDate } = this.state;
      const data = new FormData();
      data.append('file', this.fileInput.current.files[0]);
      data.append('p_docname', p_docname);
      data.append('p_dt_numb', p_dt_numb);
      if (customDate) data.append('dateDoc', format(customDate, 'dd.MM.yyyy'));

      this.props.saveCargoDocuments(info, data);

      // clear fields
      this.fileInput.current.value = '';
      this.setState({
        fileName: '',
        fileSize: '',
        doctype2: {},
        p_docname: '',
        p_dt_numb: '',
        customDate: null,
      });
    } else if (!this.fileInput.current.files) {
      this.setState({ validationText: 'Ви не додали файл' });
    }
  };

  render() {
    const { classes } = this.props;
    const { submitted, validationText } = this.state;
    let documents = null;
    if (!isEmpty(this.props.documents) && this.props.documents.length !== 0) {
      documents = this.props.documents.map((item, i) => {
        return (
          <tr key={item.id}>
            <td>{i + 1}</td>
            <td>{item.doc_type_txt}</td>
            <td>{item.c_docname}</td>
            {/* <td>{item.c_dt_numb}</td> */}
            <td>{item.d_doc}</td>
            <td>
              <a href={`/aid/file/${item.c_filename}`} target="blank">
                {item.c_filename}
              </a>
            </td>
            <td>
              <Button variant="outlined" onClick={() => this.deleteDocument(item.id)}>
                Видалити
              </Button>
            </td>
          </tr>
        );
      });
    } else {
      documents = (
        <tr>
          <td colSpan="7" className="text-center">
            <h4>
              <strong>Не додано жодного документу</strong>
            </h4>
          </td>
        </tr>
      );
    }

    return (
      <>
        <form onSubmit={this.submitDocument}>
          {/* <Grid container spacing={5} justifyContent="center"> */}
          {/* <Grid item xs={12}> */}
          <label htmlFor="" className={classes.title_text}>
            Якщо Вами вже було завезено гуманітарну допомогу та до митного органу було подано Декларацію в паперовій
            формі, то завантажте копію оригіналу паперової Декларації з відміткою митниці про дату митного
            оформлення.Якщо Ви ще не перетинали кордон і формуєте Декларацію в системі, документ можна не завантажувати.
            У разі необхідності, завантажте Гарантійний лист.
          </label>
          {/* </Grid> */}
          {/* <Grid item xs={12}> */}
          {/* <Grid item md={6} xs={12}>
            <Grid container direction="column" spacing={5}>
              <Grid item> */}
          {/* <div> */}

          <div className={classes.choice_sections}>
            <div className={classes.choice_item}>
              <UncontrolledTooltip placement="top" target="selectDocumentType2">
                Оберіть тип документа зі списку.
              </UncontrolledTooltip>
              <Autocomplete
                id="selectDocumentType2"
                value={this.state.doctype2}
                // defaulValue={this.state.p_recipienttype}
                onChange={this.handleSelectChange}
                autoHighlight
                getOptionSelected={(option) => option.value === this.state.doctype2?.value}
                getOptionLabel={(option) => option.label}
                options={this.props.catalog.documentTypes2}
                loading={this.props.loading.CATALOG}
                renderInput={(params) => <TextField {...params} label="Виберіть тип документу" />}
              />
              {submitted && !this.state.doctype2?.value && (
                <FormHelperText error id="name-error-text1">
                  Поле обов&apos;язкове
                </FormHelperText>
              )}
            </div>

            {/* </div> */}
            {/* </Grid> */}
            {/* <Grid item> */}
            <div className={classes.choice_item}>
              <TextField
                label="Детальний опис документу"
                type="text"
                name="p_docname"
                value={this.state.p_docname}
                onChange={this.handleSimpleChange}
              />
            </div>
            {/* </Grid> */}
            {/* </Grid>
           </Grid> */}
            {/* <Grid item md={6} xs={12}> */}
            {/* <Grid container direction="column" spacing={5}> */}
            {/* <Grid item>
                <TextField
                  label="Номер і дата документу"
                  type="text"
                  name="p_dt_numb"
                  value={this.state.p_dt_numb}
                  onChange={this.handleSimpleChange}
                />
              </Grid> */}
            <div className={classes.choice_item}>
              {this.state.doctype2?.value === 30 && (
                // <Grid item>
                <DatePicker
                  selected={this.state.customDate}
                  onChange={this.handleChangeDate}
                  dateFormat="dd.MM.yyyy"
                  selectsStart
                  // maxDate={this.state.date_till}
                  // startDate={this.state.date_from}
                  // endDate={this.state.date_till}
                  locale={uk}
                  className="datePicker"
                  icon={<i className="cal-icon" />}
                  customInput={<TextField label=" Дата митного оформлення" />}
                />
                //  </Grid>
              )}
              {submitted && this.state.doctype2?.value === 30 && !this.state.customDate && (
                <FormHelperText error id="name-error-text2">
                  Поле обов&apos;язкове
                </FormHelperText>
              )}
            </div>
          </div>
          {/* </Grid> */}
          {/* </Grid> */}
          {/* <Grid item xs={12}> */}
          <div className={classes.border_dowload}>
            <label htmlFor="file-upload-cargo" className={classes.button_loading}>
              {/* <div className="wrapper">
                <div className="wrapper-svg">
                  <ReactSVG
                    svgstyle={{
                      display: 'inline-block',
                      height: 10,
                    }}
                    src={uploadTable}
                  />
                </div> */}
              <div className={classes.button_loading_text}>
                ОБЕРІТЬ ФАЙЛ <img className={classes.button_loading_img} src={vector} />
              </div>
              {/* </div> */}
            </label>
            <div className={classes.textFile}>
              Увага! Розмір документу для завантаження не повинен перевищувати 10 Мб
              {this.state.fileSize > parseInt(100000000) && (
                <div className="text-danger">Завантаження неможливе! Розмір файла перевищує 10 мб</div>
              )}
            </div>
          </div>
          <div className={classes.file_sections}>
            <input
              id="file-upload-cargo"
              className="upload"
              type="file"
              accept=".jpeg,.jpg,.png,.pdf,.p7s"
              onChange={this.handleChangeFileInput.bind(this)}
              ref={this.fileInput}
            />
            {submitted && !this.fileInput.current.files[0] && (
              <FormHelperText error id="name-error-text">
                Поле обов'язкове
              </FormHelperText>
            )}
            <div> {this.state.fileName}</div>
            {/* <div className="ml-3 mt-2"> {this.state.fileName}</div> */}
            {/* <div className="text-warning" style={{ fontWeight: 800 }}>
              Увага! Розмір документу для завантаження не повинен перевищувати 100 Мб
            </div> */}
            {/* </Grid> */}
            {/* <Grid item xs={12}> */}
            {/* {this.state.fileSize > parseInt(100000000) && (
              <div className="text-danger">Завантаження не можливе! Розмір файла перевищує 100 мб</div>
            )} */}
          </div>
          <Button
            type="submit"
            color="primary"
            className={classes.button_save}
            disabled={this.state.fileSize > parseInt(100000000)}
          >
            Зберегти
            <img className={classes.button_loading_img} src={save} />
          </Button>
          {/* </Grid> */}
          {/* </Grid> */}
          {this.props.loading.CARGO_DOCUMENTS_SAVE && <Loader type="spin" />}
          {/* {!isEmpty(this.props.errMessage.CARGO_DOCUMENTS_SAVE) && (
          <div className="p-0 m-0 col-12 col-sm-6  col-md-4 col-xl-4 ">
                      <UncontrolledAlert color="danger">{this.props.errMessage.CARGO_DOCUMENTS_SAVE}</UncontrolledAlert>
                      </div>
                    )} */}
        </form>

        <Grid item xs={12}>
          <div className="table-responsive zebra">
            <table>
              {/* <table className="table table-border "> */}
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>№ з/п</th>
                  <th style={{ width: '18%' }}>Назва</th>
                  <th style={{ width: '18%' }}>Детальний опис документу</th>
                  {/* <th style={{ width: '18%' }}>Номер і дата документу</th> */}
                  <th style={{ width: '18%' }}>Дата митного оформлення</th>
                  <th style={{ width: '18%' }}>Скан документу</th>
                  <th style={{ width: '15%' }}>Керувати</th>
                </tr>
              </thead>
              {/* <tbody>{documents}</tbody> */}
              <tbody>{documents}</tbody>
            </table>
          </div>
        </Grid>
        <Grid item xs={12}>
          {/* <div className="row justify-content-center" style={{ marginTop: 50, marginBottom: 25 }}> */}
          <Button
            type="button"
            variant="outlined"
            className={classes.button_save}
            // className="btn btn-sm btn-outline-primary"
            // disabled={true}
            onClick={() => {
              this.props.toggle('6');
            }}
          >
            {/* <i className="fa fa-arrow-alt-circle-right mr-2" /> */}
            Далі
            <img className={classes.button_loading_img} src={next} />
          </Button>
        </Grid>
        {/* </div> */}
        {/* </Grid> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  documents: state.cargo.documentsCargo,
  loading: state.isFatching,
  errMessage: state.error,
  applicationCargoId: state.cargo.applicationCargoId,
  cargo: state.cargo.cargoApplication,
});

export default withStyles(useStyles)(
  withRouter(
    connect(mapStateToProps, {
      getCargoDocuments,
      saveCargoDocuments,
      deleteCargoDocument,
      createLoadingSelector,
      createErrorMessageSelector,
      clearErrors,
    })(DocumentsLoading)
  )
);

import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import spinner from '../../../assets/images/tube-spinner.svg';
import { StyledTableCell } from '../../../views/pages/Public/PublicRecipient/RecipientTableV2';

const CustomLoader = ({ isLoading }) => {
  return (
    <TableRow>
      <StyledTableCell colSpan={15} style={{ textAlign: 'center' }}>
        {isLoading === true ? (
          <div className="custom-loader">
            <div className="custom-loader__container">
              <div className="custom-loader__title">Загрузка, почекайте</div>
              <img
                loading="lazy"
                height="100px"
                width="100px"
                className="custom-loader__spinner"
                src={spinner}
                alt="spinner"
              />
            </div>
          </div>
        ) : (
          <strong>Відсутні дані для відображення</strong>
        )}
      </StyledTableCell>
    </TableRow>
  );
};

export default CustomLoader;

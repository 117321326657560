import React from 'react';
import { IconButton, withStyles } from '@material-ui/core';
import CloseRounded from '@material-ui/icons/CloseRounded';

const StyledIconButton = withStyles({
  root: {
    marginLeft: '10px',
    marginRight: '10px',
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  label: {
    fontSize: '40px',
  },
})(IconButton);

const NavCloseButton = ({ onClick }) => {
  return (
    <StyledIconButton size="medium" onClick={onClick}>
      <CloseRounded fontSize="inherit" />
    </StyledIconButton>
  );
};

export default NavCloseButton;

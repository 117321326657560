import React from 'react';
import {Card,CardBody, CardTitle, Button} from 'reactstrap'
import {withRouter} from 'react-router-dom'
import ReactToPrint from "react-to-print";
import IconButton from "@material-ui/core/IconButton";
import { Print } from "@material-ui/icons";


class ViewAllDocuments extends React.Component {
 constructor(props) {
  super(props);
 }

 render() {
  const {history} = this.props;
  return (
      <section  className="viewAllDocuments" style={{fontSize:"0.9rem"}}  >
    <div ref={el => (this.componentRef = el)}>
      <Card>
       <CardBody>
       <CardTitle>Інформація по отримувачу</CardTitle>
          <div>№ заяви: </div>
          <div>Область: </div>
          <div>Населений пункт: </div>
          <div>Місцезнаходження: </div>
          <div>Код ЄДРПОУ: </div>
          <div>Назва отримувача: </div>
          <div>ПІБ керівника: </div>
          <div>Телефон: </div>
          <div>Електронна адреса: </div>
          <div>Документи: (Перелік документів) </div>
      </CardBody>
      </Card>
      

      <Card>
       <CardBody>
       <CardTitle>Інформація по гуманітарній допомозі </CardTitle>
            <div>№ заяви:</div>
            <div>Найменування:</div>
            <div>Найменування донора:</div>
            <div>Реквізити донора:</div>
            <div>Країна донора:</div>
            <div>Країна відправлення:</div>
            <div>Форма допомоги:</div>
            <div>Документи: (Перелік документів)</div>
      </CardBody>
      </Card>


      <Card>
       <CardBody>
       <CardTitle>План-розподіл </CardTitle>
            <div>Тип набувача:</div>
            <div>Найменування набувача:</div>
            <div>ПІБ керівника:</div>
            <div>ПІБ набувача:</div>
            <div>Соціальна категорія:</div>
            <div>Місцезнаходження набувача:</div>
            <div>Телефон набувача:</div>
            <div>Тип допомоги:</div>
            <div>Опис вантажу:</div>
            <div>Кількість:</div>
            <div>Одиниці виміру:</div>
            <div>Стан:</div>
      </CardBody>
      </Card>
      </div>
      <Button className="btn btn-primary btn-sm mr-2" color="primary" 
       onClick={e => history.goBack() }> Вихід
        </Button>
        
        <ReactToPrint
            trigger={() => (
              <IconButton color="inherit" aria-label="print">
                <Print />
              </IconButton>
            )}
            content={() => this.componentRef}
          />

      </section>
  );
 }
}

export default withRouter(ViewAllDocuments);
import React from 'react'
import PropTypes from 'prop-types'

import {
    Card,
    CardBody,
    Row,
    Col,
  } from 'reactstrap';

function RegisterHistoryMobile({data}) {
    const renderingData = data.map((item, index) => (
        <Row key={index}>
            <Col xs={12} sm={6}>
                <Card>
                    <CardBody>
                        <p>№ заяви: {item.text}</p>
                        <p>Код ЄДРПОУ: {item.text}</p>
                        <p>Назва отримувача: {item.text}</p>
                        <p>ПІБ керівника: {item.text}</p>
                        <p>Телефон:</p>
                        <p>Електронна адреса:</p>
                        <p>Дата включення в Реєстр:</p>
      <p>Дата виключення з Реєстру:</p>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        
    ))
    return (
        <React.Fragment>
            {renderingData}
        </React.Fragment>
    )
}

RegisterHistoryMobile.propTypes = {
    data: PropTypes.array.isRequired
}

export default RegisterHistoryMobile


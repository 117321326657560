import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from 'reactstrap';
import AqcuirerInputs from './AqcuirerInputs';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { UncontrolledAlert } from 'reactstrap';

const AddAquirerModal = (props) => {
  return (
    <Modal isOpen={props.modal} toggle={props.toggle} className="modal-xl">
      <ModalHeader toggle={props.toggle}>Набувач(і)</ModalHeader>
      <ModalBody>
        <AqcuirerInputs {...props} />
      </ModalBody>
      <ModalFooter>
        {props.errorText && (
          <UncontrolledAlert color="danger" className="float-left mb-0">
            <Typography component="span">{props.errorText}</Typography>
          </UncontrolledAlert>
        )}
        <Button color="primary" onClick={() => props.addAcquirer(props.indexRow)}>
          Зберегти
        </Button>{' '}
        <Button variant="outlined" onClick={props.toggle}>
          Відміна
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddAquirerModal;

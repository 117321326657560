import React from 'react';
import './Landing.css';
import { Grid, Typography, Button } from '@material-ui/core';

const MoveToPublic = () => {
  return (

    <div>
    <p className='landingTitle'>
    Перегляд інформації про завезену гуманітарну допомогу
   </p>
   <p className='landingText'>
   Призначена для забезпечення прозорого обліку користувачів гуманітарної допомоги та вантажів.
   </p>
   <Button variant="contained" color="primary" href="/register-recipients-public">
          Єдиний реєстр отримувачів
        </Button>
 </div>


    // <Grid container direction="column" justifyContent="flex-start" spacing={1}>
    //   <Grid item>
    //     <Typography variant="h5" gutterBottom>
    //       Перегляд інформації про завезену гуманітарну допомогу
    //     </Typography>
    //     <Typography variant="h6">
    //       Призначена для забезпечення прозорого обліку користувачів гуманітарної допомоги та вантажів.
    //     </Typography>
    //   </Grid>
    //   <Grid item>
    //     <Button variant="contained" color="primary" href="/register-recipients-public">
    //       Єдиний реєстр отримувачів
    //     </Button>
    //   </Grid>
    // </Grid>
  );
};

export default MoveToPublic;

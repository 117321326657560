import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import { getReportData } from '../../../../api/api';
import {
  setBalanceArray,
  setDistributionArray,
  setReportCargoList,
  setReportParams,
  setReportAidtype,
  setReportAcquirer,
  setReportTab,
} from '../../../../redux/actions/report.action';

import { transformBalance, transformDistribution } from './reportHelpers/helpers';
import { NextIcon } from './reportHelpers/CustomIcon';

import Table2 from './Table2';

const useStyles = makeStyles((theme) => ({
  topButton: {},
  icon: {
    '& > svg': {
      margin: theme.spacing(2),
    },
  },
  endIcon: {},
  moveBtn: {
    padding: '12px 16px',
    gap: '8px',
    borderRadius: '30px',
    border: '2px solid #141414',
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    textAlign: 'center',
  },
}));

const Distribution = ({ currentDate }) => {
  const region = useSelector((state) => state.catalogs.region);
  const i_recip = useSelector((state) => state.report.params.i_recip);
  const curr_date = useSelector((state) => state.report.params.curr_date);
  const dispatch = useDispatch();
  const classes = useStyles();

  const updateBothTables = () => {
    getReportData({ pd_rep: curr_date, pi_recip: i_recip })
      .then((res) => {
        // eslint-disable-next-line no-shadow
        const { balance, distrib, cargo: newCargo, aidtype, acquirer, general } = res.data;
        const { i_recip: Irecip, curr_date: currDate, min_date: minDateG, i_report } = general[0];
        dispatch(setReportParams({ i_recip: Irecip, curr_date: currDate, min_date: minDateG, i_report }));
        dispatch(setReportCargoList(newCargo));
        dispatch(setReportAidtype(aidtype));
        dispatch(setReportAcquirer(acquirer));
        dispatch(setBalanceArray(transformBalance(balance, newCargo)));
        dispatch(setDistributionArray(transformDistribution(distrib, acquirer, region)));
      })
      // eslint-disable-next-line no-unused-vars
      .catch((err) => {});
  };

  return (
    <Grid container spacing={4}>
      <Grid container item sx={12}>
        {currentDate && <Table2 updateBothTables={updateBothTables} />}
      </Grid>
      <Grid container item justifyContent="center">
        <Button
          type="button"
          className={classes.moveBtn}
          classes={{ endIcon: classes.endIcon }}
          onClick={() => dispatch(setReportTab(2))}
          variant="outlined"
          endIcon={<NextIcon />}
        >
          Перейти до документів
        </Button>
      </Grid>
    </Grid>
  );
};

export default Distribution;

import React from 'react';

//reactstrap
import { Button } from 'reactstrap';

//MUI
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

//api
import { setNewApplication } from '../../../../api/api';

//swal
import Swal from 'sweetalert2';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const deleteStatus = 4; //back to status 10 for revision

class DialogChandgeApplication extends React.Component {
  handleClose = () => {
    this.props.onClose();
  };

  handleNewState = () => {
    const data = {
      p_id: this.props.id,
      application_type: 3,
      p_user_id: this.props.userId,
    };
    setNewApplication(data)
      .then((response) => {
        this.props.recipientByIdNew(this.props.id);
        setTimeout(() => {
          this.props.history.push('/edit-recipient');
        }, 1000);
      })
      .catch((error) => {
        swalWithBootstrapButtons.fire({
          type: 'error',
          text: error.response.data,
        });
      });
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText style={{ color: '#000' }} id="alert-dialog-description">
              Ви дійсно бажаєте подати заяву на внесення змін до отримувача?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleNewState()} className="mr-2" color="primary">
              Так
            </Button>
            <Button onClick={this.handleClose} color="primary">
              Відмінити
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default DialogChandgeApplication;

// packages
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import 'react-table/react-table.css';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import uk from 'date-fns/locale/uk';
import { withStyles, Card, CardContent, CardActions, Button, TextField } from '@material-ui/core';
import Swal from 'sweetalert2';
import { customTheme, customStyles } from '../../../customStyles/reactSelect';

import RegisterTableFullScreen from './RegisterTableFullScreen';
import { getCargoByFilters, getStateList, getStatusList } from '../../../api/api';
import { createLoadingSelector } from '../../../redux/actions/selectors';
import BootstrapTooltip from '../../elements/MaterialBootstrap';

// validation
import rules from '../../../vibe/components/utilities/MainUtils/rules';

import {
  setCargoByRecipient,
  getCargoByID,
  getCargoByIdFull,
  clearCargo,
  setCargoRegister,
} from '../../../redux/actions/cargo.action';

import RegExcel from './RegExcel/RegExcel';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const loadingSelector = createLoadingSelector(['CATALOG', 'LOCALITY']);

const styles = (theme) => ({
  button: {
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
  },
});

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // i_country: '',
      // p_state: '',
      // p_reg_date: '',
      // p_customs_date: '',
      // p_edrpou: '',
      // p_numb: '',
      // donor_name: '',
      // p_cargo: '',
      // other
      // dataTable: [],
      // small comment
      visible: false,
      loading: false,
      statusList: [],
    };
  }

  componentDidMount() {
    // this.props.clearCargo()
    this.fetchStatusList();
  }

  fetchStatusList = () => {
    getStatusList()
      .then((response) => {
        this.setState({ statusList: response.data });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  handleSelectChange = (name) => (select, { action }) => {
    // const objDelete = {};
    // objDelete[name] = '';
    // switch (action) {
    //   case 'select-option':
    //     const obj = {};
    //     obj[name] = select;
    //     this.setState(obj);
    //     break;
    //   case 'clear':
    //     this.setState(objDelete);
    //     break;
    // }

    // eslint-disable-next-line no-shadow
    const { filters, setCargoRegister } = this.props;
    const { filter } = filters;
    // eslint-disable-next-line default-case
    switch (action) {
      case 'select-option':
        setCargoRegister({ ...filters, filter: { ...filter, [name]: select } });
        break;
      case 'clear':
        setCargoRegister({ ...filters, filter: { ...filter, [name]: '' } });
        break;
    }
  };

  handleChangeData = (name) => (data) => {
    // const obj = {};
    // obj[name] = data;
    // this.setState(obj);

    // eslint-disable-next-line no-shadow
    const { filters, setCargoRegister } = this.props;
    const { filter } = filters;
    const dateStr = data ? format(data, 'dd.MM.yyyy') : '';
    setCargoRegister({ ...filters, filter: { ...filter, [name]: dateStr } });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    const valuetoUpperCase = value.toUpperCase();
    // if (value === '') {
    //   this.setState({ [name]: value });
    // } else if (name === 'p_edrpou' || name === 'p_cargo') {
    //   rules.edrpou.test(event.target.value) && this.setState({ [name]: event.target.value });
    // } else {
    //   this.setState({ [name]: valuetoUpperCase });
    // }

    // eslint-disable-next-line no-shadow
    const { filters, setCargoRegister } = this.props;
    const { filter } = filters;
    setCargoRegister({ ...filters, filter: { ...filter, [name]: value } });

    if (value === '') {
      setCargoRegister({ ...filters, filter: { ...filter, [name]: value } });
    } else if (name === 'p_edrpou' || name === 'p_cargo') {
      rules.edrpou.test(value) && setCargoRegister({ ...filters, filter: { ...filter, [name]: value } });
    } else {
      setCargoRegister({ ...filters, filter: { ...filter, [name]: valuetoUpperCase } });
    }
  };

  dateToObject = (strDate) => {
    if (strDate) {
      const [day, month, year] = strDate.split('.');
      return new Date(+year, +month - 1, +day);
    }
    return null;
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.setState({
      submitted: true,
      loading: true,
    });

    // const { i_country, p_state, p_reg_date, p_customs_date, p_cargo, p_edrpou, donor_name, p_numb } = this.state;
    // eslint-disable-next-line no-shadow
    const { filters, setCargoRegister } = this.props;
    const {
      filter: { p_numb, p_edrpou, p_cargo, p_state, p_reg_date, p_customs_date, i_country, donor_name },
      p_page,
      p_rows,
    } = filters;

    const data = {
      i_country: i_country ? (i_country || {}).value : '',
      p_state: p_state ? (p_state || {}).value : '',
      p_reg_date: p_reg_date || '',
      p_customs_date: p_customs_date || '',
      p_cargo: p_cargo.trim(),
      p_edrpou: p_edrpou.trim(),
      p_numb: p_numb.trim(),
      donor_name: donor_name.trim(),
      p_page,
      p_rows,
    };

    getCargoByFilters(data)
      .then((response) => {
        this.setState({ visible: true, loading: false });
        setCargoRegister({
          ...filters,
          dataTable: response.data.cursor,
          p_page: response.data.pn_page,
          p_rows: response.data.pn_rows,
          total_rows: response.data.total_rows,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        setCargoRegister({ ...filters, dataTable: [] });

        // if (error.response && error.response.status === 400) {
        //   swalWithBootstrapButtons.fire({
        //       title: 'Помилка',
        //       text: error.response.data,
        //       type: 'error',
        //   })
        // } else {
        //   swalWithBootstrapButtons.fire({
        //       title: 'Помилка серверу',
        //       type: 'error',
        //   })
        // }
      });
  };

  startSearch = async (e) => {
    e.preventDefault();
    // eslint-disable-next-line no-shadow
    const { filters, setCargoRegister } = this.props;
    await setCargoRegister({
      ...filters,
      p_page: 1,
    });
    this.handleSearch(e);
  };

  render() {
    const { classes } = this.props;
    const { filters } = this.props;
    const {
      filter: { p_numb, p_edrpou, p_cargo, p_state, p_reg_date, p_customs_date, i_country, donor_name },
      dataTable,
    } = filters;

    return (
      <div className="mainRegister">
        <section className="searchMainRegister">
          <Card>
            <form onSubmit={this.startSearch} autoComplete="off">
              <CardContent>
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                    <TextField
                      label="№ отримувача в реєстрі"
                      value={p_numb}
                      onChange={this.handleChange}
                      name="p_numb"
                      type="text"
                      autocomplete="off"
                    />
                  </div>
                  <div className="col-12 col-sm-6  col-md-4 col-xl-3">
                    <TextField
                      label="ЄДРПОУ"
                      value={p_edrpou}
                      onChange={this.handleChange}
                      name="p_edrpou"
                      type="text"
                      autocomplete="off"
                      // maxLength="8"
                      inputProps={{
                        maxLength: 8,
                      }}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                    <TextField
                      label="Код вантажу"
                      value={p_cargo}
                      onChange={this.handleChange}
                      name="p_cargo"
                      type="text"
                      autocomplete="off"
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                    <label htmlFor="">Статус</label>
                    <BootstrapTooltip title=" Оберіть cтатус зі списку." placement="top-start">
                      <div>
                        <Select
                          value={p_state}
                          onChange={this.handleSelectChange('p_state')}
                          options={this.state.statusList}
                          styles={customStyles}
                          placeholder=""
                          isClearable
                          isSearchable
                          // isMulti
                          noOptionsMessage={() => 'помилка серверу'}
                          menuPortalTarget={document.body}
                          theme={customTheme}
                        />
                      </div>
                    </BootstrapTooltip>
                  </div>
                  <div className="col-12 mt-3 mb-4 text-center" />
                  <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                    <DatePicker
                      selected={this.dateToObject(p_reg_date)}
                      onChange={this.handleChangeData('p_reg_date')}
                      dateFormat="dd.MM.yyyy"
                      maxDate={new Date()}
                      locale={uk}
                      className="datePicker"
                      autoComplete="off"
                      icon={<i className="cal-icon" />}
                      customInput={<TextField label="Дата реєстрації вантажу" />}
                      isClearable
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                    <DatePicker
                      selected={this.dateToObject(p_customs_date)}
                      onChange={this.handleChangeData('p_customs_date')}
                      dateFormat="dd.MM.yyyy"
                      maxDate={new Date()}
                      startDate={this.state.p_reg_date}
                      minDate={this.state.p_reg_date}
                      className="datePicker"
                      autoComplete="off"
                      locale={uk}
                      customInput={<TextField label="Дата митного оформлення" />}
                      isClearable
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                    <label htmlFor="">Країна відправлення</label>
                    <BootstrapTooltip title=" Оберіть країну зі списку." placement="top-start">
                      <div>
                        <Select
                          value={i_country}
                          onChange={this.handleSelectChange('i_country')}
                          options={this.props.catalog.country}
                          styles={customStyles}
                          isClearable
                          isSearchable
                          placeholder=""
                          // isMulti
                          noOptionsMessage={() => 'помилка серверу'}
                          menuPortalTarget={document.body}
                          theme={customTheme}
                        />
                      </div>
                    </BootstrapTooltip>
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                    <TextField
                      label="Донор"
                      value={donor_name}
                      onChange={this.handleChange}
                      name="donor_name"
                      type="text"
                      // maxLength="250"
                      autocomplete="off"
                      inputProps={{
                        maxLength: 250,
                      }}
                    />
                  </div>
                </div>
              </CardContent>
              <CardActions>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  style={{ marginLeft: 'auto', marginRight: 'auto' }}
                >
                  <i className="fa fa-search mr-2" /> Пошук
                </Button>
                <RegExcel dataTable={dataTable} />
              </CardActions>
            </form>
          </Card>
        </section>
        <section className="dataMainRegister mt-3">
          <div>
            {dataTable.length > 0 ? (
              <RegisterTableFullScreen
                history={this.props.history}
                getCargoByIdFull={this.props.getCargoByIdFull}
                loading={this.state.loading}
                handleSearch={this.handleSearch}
              />
            ) : (
              ''
            )}
            <br />
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile,
    loading: state.isFatching,
    errMessage: state.error,
    user: state.auth.user,
    catalog: state.catalogs,
    isFatching: loadingSelector(state),
    filters: state.cargo.cargoRegister,
  };
};

Register.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, {
  setCargoByRecipient,
  clearCargo,
  getCargoByID,
  getCargoByIdFull,
  setCargoRegister,
})(withStyles(styles)(Register));

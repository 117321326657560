// packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import MaskedInput from 'react-text-mask';
import emailMask from 'text-mask-addons/dist/emailMask';
import InputMask from 'react-input-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
// import FormHelperText from '@material-ui/core/FormHelperText';
import { withRouter } from 'react-router';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { IconButton, Tooltip, Button, Grid, TextField, InputAdornment, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import { Autocomplete } from '@material-ui/lab';
import isEmpty from '../../../../vibe/components/utilities/MainUtils/is-empty';
import BootstrapTooltip from '../../../elements/MaterialBootstrap';
import { saveNewApply, saveRecipientOrd, getNaisData, getNaisData2 } from '../../../../api/api';
import { Loader } from '../../../../vibe';
import TextMaskCustom from '../../../../vibe/components/inputs/TextMaskCustoms.component';
import vector from '../../../../assets/images/Vector.png';
import add from '../../../../assets/images/add.png';
import arrowBack from '../../../../assets/images/arrowBack.png';
import next from '../../../../assets/images/next.png';
import cross from '../../../../assets/images/cross.png';

// styles
import { customTheme, customStyles } from '../../../../customStyles/reactSelect';
import { localityAction } from '../../../../redux/actions/catalog.action';

const useStyles = (theme) => ({
  activeButton: {
    '&:hover': { backgroundColor: 'white', outline: 'none' },
    '&:focus': { backgroundColor: 'white', outline: 'none' },
    '&:active': { backgroundColor: 'white', outline: 'none' },
  },
  addToRegisterButton: {
    width: '540px',
    height: '51px',
  },
  searchButton: {
    fontSize: '16px',
    width: '210px',
    height: '46px',
  },
  buttonSection: {
    display: 'flex',
    width: '100%',
    alignContent: 'space-around',
  },

  button_save: {
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 20px',
    gap: '4px',

    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',
    margin: 'auto',

    fontFamily: 'diaHead',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    // display: 'flex',
    // alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      margin: '25px 5px',
    },

    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
  },
  button_loading_img: {
    marginLeft: '15px',
  },
  buttonR_loading_img: {
    marginRight: '15px',
  },
});

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd.mm.yyyy');

const mask = [/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, '.', /[12]/, /[890]/, /[0-9]/, /[0-9]/];

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

class StatementRecipient extends Component {
  state = { selected: '', loading: false, edrpouError: false };
  blockRequest = false;

  componentDidMount() {
    const {
      getRecipientsForSelect,
      user: { id },
      recipientId,
      clearRecipient,
      getRoute,
    } = this.props;
    if (this.getRouteMSPUEdit()) {
      getRecipientsForSelect(id);
    }

    if (getRoute()) {
      clearRecipient();
    }

    getNaisData2(recipientId)
      .then((res) => {
        const {
          cursor: { ...all },
          editable,
        } = res.data;
        this.defineNaisProperties({ ...all, editable });
      })
      .catch((err) => {});
  }

  // componentDidUpdate(prevProps) {
  //   // eslint-disable-next-line react/destructuring-assignment
  //   if (prevProps.recipient.p_region !== this.props.recipient.p_region) {
  //     // eslint-disable-next-line react/destructuring-assignment
  //     this.props.localityAction(this.props.recipient.p_region);
  //   }
  // }

  defineNaisProperties = (naisData) => {
    const {
      // values
      p_edrpou,
      p_region,
      p_locality,
      p_adress,
      p_orgname,
      p_recipient_type,
      p_surname,
      p_name,
      p_patronymic,
      p_phone,
      p_phone2,
      p_email,
      // diplomacy params
      p_ag_code,
      p_ag_name,
      p_ag_patr,
      p_ag_surname,
      p_position,
      p_rnokpp,
      p_reg_address,
      p_country,
      // disabled
      pl_edrpou,
      pl_region,
      pl_locality,
      pl_adress,
      pl_orgname,
      pl_recipient_type,
      pl_surname,
      pl_name,
      pl_patronymic,
      // two additional
      pl_got_olfcode,
      editable,
      // private
      p_private,
    } = naisData;
    // eslint-disable-next-line no-shadow
    const { recipientAction, localityAction } = this.props;
    if (p_region) localityAction(p_region);
    // values
    recipientAction('p_edrpou', p_edrpou || '');
    recipientAction('p_region', p_region || '');
    recipientAction('p_koatuu', p_locality || '');
    recipientAction('p_adress', p_adress || '');
    recipientAction('p_orgname', p_orgname || '');
    recipientAction('p_recipientType', p_recipient_type || '');
    recipientAction('p_surname', p_surname || '');
    recipientAction('p_name', p_name || '');
    recipientAction('p_patronymic', p_patronymic || '');
    recipientAction('p_phone', p_phone || '');
    recipientAction('p_phone2', p_phone2 || '');
    recipientAction('p_email', p_email || '');
    // additional diplomacy params
    recipientAction('p_reprOccupation', p_position || '');
    recipientAction('p_reprSurname', p_ag_surname || '');
    recipientAction('p_reprFirstname', p_ag_name || '');
    recipientAction('p_reprPatronymic', p_ag_patr || '');
    recipientAction('p_agent_rnokpp', p_rnokpp || '');
    recipientAction('p_agent_code', p_ag_code || '');
    recipientAction('p_reprCountry', p_country || '');
    recipientAction('p_reprLocality', p_reg_address || '');
    // disabled values
    recipientAction('pl_edrpou', pl_edrpou === 0);
    recipientAction('pl_region', pl_region === 0);
    recipientAction('pl_locality', pl_locality === 0);
    recipientAction('pl_adress', pl_adress === 0);
    recipientAction('pl_orgname', pl_orgname === 0);
    recipientAction('pl_recipient_type', pl_recipient_type === 0);
    recipientAction('pl_surname', pl_surname === 0);
    recipientAction('pl_name', pl_name === 0);
    recipientAction('pl_patronymic', pl_patronymic === 0);
    // two aditional
    recipientAction('editable', editable);
    recipientAction('pl_got_olfcode', pl_got_olfcode);
    // private
    // eslint-disable-next-line no-nested-ternary
    const privateTransform = p_private === 1 ? 'yes' : p_private === 0 ? 'no' : '';
    recipientAction('p_private', privateTransform);
  };

  passToRegister = () => {
    const { history } = this.props;
    history.push(`/register-recipients-personal`);
  };

  getRouteMSPUSave = () => {
    const {
      location: { pathname },
    } = this.props;
    return pathname === '/save-recipient-mspu';
  };

  getRouteMSPUEdit = () => {
    const {
      location: { pathname },
    } = this.props;
    return pathname === '/edit-recipient-mspu';
  };

  filterOptions = (candidate, input) => {
    if (candidate.data.label.toLowerCase().includes(input.toLowerCase())) {
      return true;
    }
    if (`${candidate.data.erNumber}`.startsWith(`ЄР${input}`)) {
      return true;
      // eslint-disable-next-line no-else-return
    } else if (`${candidate.data.edrpou}`.startsWith(input)) {
      return true;
    } else {
      return false;
    }
  };

  saveNewApplication = () => {
    this.blockRequest = true;
    const {
      recipient: {
        p_edrpou,
        p_orgname,
        p_surname,
        p_name,
        p_patronymic,
        p_koatuu,
        p_phone,
        p_email,
        p_adress,
        id,
        // new parameters
        p_recipientType,
        p_reprSurname,
        p_reprFirstname,
        p_reprPatronymic,
        p_reprOccupation,
        p_agent_rnokpp,
        p_agent_code,
        p_reprCountry,
        p_reprLocality,
        p_phone2,
        editable,
        pl_got_olfcode,
        // new
        p_private,
      },
      user,
      recipientByIdNew,
    } = this.props;

    const data = {
      p_user_id: user.id,
      p_user_role: user.c_role,
      p_edrpou,
      p_orgname,
      p_surname,
      p_name,
      p_patronymic,
      p_koatuu,
      p_adress,
      p_phone,
      p_email,
      // new parameters
      p_rec_type: p_recipientType,
      p_ag_surname: p_reprSurname,
      p_ag_name: p_reprFirstname,
      p_ag_patr: p_reprPatronymic,
      p_position: p_reprOccupation,
      p_rnokpp: p_agent_rnokpp,
      p_ag_code: p_agent_code,
      p_country: p_reprCountry,
      p_reg_address: p_reprLocality,
      p_phone2: p_phone2 || '',
      p_editable: editable,
      p_got_olf_code: pl_got_olfcode,
      // private
      p_private: p_private === 'yes' ? 1 : 0,
      setstate: {
        p_id: id,
        p_new_state: '3',
        p_user: user.id,
      },
    };

    swalWithBootstrapButtons
      .fire({
        // title: 'Are you sure?',
        text: 'Подати заяву зі змінами?',
        showCancelButton: true,
        confirmButtonText: 'Так',
        cancelButtonText: 'Відміна',
      })
      .then((result) => {
        if (result.value) {
          saveNewApply(JSON.stringify(data))
            .then((res) => {
              const { p_recipient_id, p_application_id } = res.data;
              const { currentRecipientId, currentApplicationId } = this.props;
              currentRecipientId(p_recipient_id);
              currentApplicationId(p_application_id);

              swalWithBootstrapButtons
                .fire({
                  type: 'success',
                  text: 'Заяву подано!',
                })
                .then((res) => {
                  if (res.value) {
                    recipientByIdNew(p_recipient_id);
                  }
                });
            })
            .catch((error) => {
              swalWithBootstrapButtons.fire({
                type: 'error',
                text: error.response.data,
              });
              this.blockRequest = false;
            });
        }
      });
  };

  saveRacipientOrder = () => {
    const { user, recipient, recipientByIdNew } = this.props;
    this.blockRequest = true;

    const {
      // new parameters
      p_recipientType,
      p_reprSurname,
      p_reprFirstname,
      p_reprPatronymic,
      p_reprOccupation,
      p_agent_rnokpp,
      p_agent_code,
      p_reprCountry,
      p_reprLocality,
      p_phone2,
      // odd parameter
      p_phone_max,
      // older parameters
      ...olderParams
    } = recipient;

    const data = {
      // user params
      p_user_id: user.id,
      p_user_role: user.c_role,
      // p_phone: recipient.p_phone.replace(/\D/g, ''),
      ...olderParams,
      // new parameters
      p_rec_type: p_recipientType,
      p_ag_surname: p_reprSurname,
      p_ag_name: p_reprFirstname,
      p_ag_patr: p_reprPatronymic,
      p_position: p_reprOccupation,
      p_rnokpp: p_agent_rnokpp,
      p_ag_code: p_agent_code,
      p_country: p_reprCountry,
      p_reg_address: p_reprLocality,
      p_phone2: p_phone2 || '',
    };

    saveRecipientOrd(data)
      .then((response) => {
        swalWithBootstrapButtons
          .fire({
            type: 'success',
            text: 'Дані отримувача збережено!',
          })
          .then((res) => {
            if (res.value) {
              // this.props.clearRecipient();
              recipientByIdNew(response.data.p_recipient_id);
            }
          });
      })
      .catch((err) => {
        console.log(err);
        this.blockRequest = false;
      });
  };

  handleSelectChange = () => (select) => {
    const { recipientByIdNew } = this.props;
    if (select) {
      // this.props.cargoAction(name, parseInt(select.value));
      this.setState({ selected: select.value });
      // this.props.recipientByIdEdit(select.value);
      recipientByIdNew(select.value);
      // if (name === 'i_region') {
      //   this.props.localityAction(parseInt(select.value));
      // }
    }
  };

  handleChangeRadio = (event) => {
    const { value } = event.target;
    const { recipientAction } = this.props;
    recipientAction('p_private', value);
  };

  filterAid = (inputValue) => {
    const { selectRecipient } = this.props;
    return selectRecipient.filter((i) => {
      return (
        i.label.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.erNumber.startsWith(`ЄР${inputValue}`) ||
        `${i.edrpou}`.startsWith(inputValue)
      );
    });
  };

  promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.filterAid(inputValue));
      }, 1000);
    });

  clearFields = () => {
    this.props.clearRecipient();
  };

  fetchNais = async () => {
    const {
      recipient: { p_edrpou },
    } = this.props;

    const codeLength = `${p_edrpou}`.length;
    if (codeLength !== 8 && codeLength !== 10) {
      this.setState({ edrpouError: true });
    } else {
      this.setState({ edrpouError: false });
    }

    try {
      if (codeLength === 8 || codeLength === 10) {
        this.setState({ loading: true });
        const res = await getNaisData(p_edrpou);
        this.setState({ loading: false });
        const {
          cursor: { ...all },
          editable,
        } = res.data;
        this.defineNaisProperties({ ...all, editable });
      }
    } catch (err) {
      this.setState({ loading: false });
    }
  };

  render() {
    const { registered } = this.props;
    const { edrpouError } = this.state;
    const {
      classes,
      recipient: {
        pl_edrpou,
        pl_region,
        pl_locality,
        pl_adress,
        pl_orgname,
        pl_recipient_type,
        pl_surname,
        pl_name,
        pl_patronymic,
        //
        p_private,
        p_recipientType,
      },
    } = this.props;
    const diplomacy =
      p_recipientType !== 2 && p_recipientType !== 996 && p_recipientType !== 997 && p_recipientType !== 998;
    return (
      <>
        {/* <Card>
         <CardBody> */}
        <Grid container spacing={5} justifyContent="center">
          <Grid item md={6} xs={12}>
            <Grid container direction="column" spacing={6}>
              {this.getRouteMSPUSave() && (
                <Grid item>
                  <Button variant="contained" color="primary" type="button" onClick={this.clearFields}>
                    Додати нового отримувача
                  </Button>
                </Grid>
              )}

              {this.getRouteMSPUEdit() && (
                <Grid item>
                  <label htmlFor="">Найменування отримувача</label>

                  <BootstrapTooltip
                    placement="top"
                    title="Введіть назву отримувача. Дозволено введення цифр, літер українською мовою та символів \ / № «» . , ’ -"
                  >
                    <div>
                      <AsyncSelect
                        cacheOptions
                        value={this.props.selectRecipient.filter(({ value }) => value === this.state.selected)}
                        onChange={this.handleSelectChange('i_recipient')}
                        // options={this.props.selectRecipient}
                        loadOptions={this.promiseOptions}
                        // loadOptions={this.props.selectRecipient}
                        styles={customStyles}
                        placeholder=""
                        // isMulti
                        getOptionLabel={({ label, edrpou, erNumber }) => (
                          <div>
                            <span style={{ color: '#999', marginRight: '10px' }}>{erNumber}</span>
                            <span>{label}</span> <span style={{ color: '#999', fontSize: '12px' }}>ЄДРПОУ:</span>
                            <span style={{ color: '#999' }}>{edrpou}</span>
                          </div>
                        )}
                        noOptionsMessage={() => 'помилка серверу'}
                        menuPortalTarget={document.body}
                        isSearchable
                        filterOption={this.filterOptions}
                        theme={customTheme}
                      />
                    </div>
                  </BootstrapTooltip>
                </Grid>
              )}

              <Grid item>
                <UncontrolledTooltip placement="top" target="inputEdrpouRecipient">
                  Введіть код ЕДРПОУ.
                </UncontrolledTooltip>
                {/* <label htmlFor="">Код ЄДРПОУ</label> */}
                <TextField
                  id="inputEdrpouRecipient"
                  required
                  value={this.props.recipient.p_edrpou}
                  onChange={this.props.handleChange}
                  name="p_edrpou"
                  type="text"
                  label="Код ЄДРПОУ"
                  error={edrpouError}
                  helperText={edrpouError && 'Код з 8 або 10 знаків'}
                  inputProps={{
                    maxLength: 10,
                    readOnly: pl_edrpou,
                  }}
                  // className={submitted && !this.props.recipient.p_edrpou ? 'error' : ''}
                />
                {/* {submitted && !this.props.recipient.p_edrpou && (
                      <FormHelperText error id="name-error-text">
                        Поле обов'язкове
                      </FormHelperText>
                    )} */}
              </Grid>

              <Grid item>
                <Button
                  // type="button"
                  variant="outlined"
                  className={classes.button_save}
                  // color="primary"
                  // style={{ marginLeft: 'auto', marginRight: 'auto' }}
                  onClick={this.fetchNais}
                  disabled={this.state.loading}
                >
                  {/* <Icon className="fa fa-search mr-1" />  */}
                  Завантажити з ЄДР
                  <img className={classes.button_loading_img} src={vector} />
                </Button>
              </Grid>

              <Grid item>
                {/* <label htmlFor="">Область</label> */}
                <UncontrolledTooltip placement="top" target="selectRegionRecipient">
                  Оберіть область із списку.
                </UncontrolledTooltip>

                <Autocomplete
                  id="selectRegionRecipient"
                  value={
                    this.props.catalog.region.filter(({ value }) => value === this.props.recipient.p_region)[0] || {}
                  }
                  onChange={this.props.handleSelectChange('p_region')}
                  autoHighlight
                  // getOptionSelected={(option) => option.value === this.props.recipient.p_region}
                  getOptionLabel={(option) => option?.label || ''}
                  options={this.props.catalog.region}
                  loading={this.props.loading.CATALOG}
                  disabled={pl_region}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      label="Область"
                    />
                  )}
                />

                {/* </BootstrapTooltip> */}
                {/* {submitted && !this.props.recipient.p_region && (
                      <FormHelperText error id="name-error-text">
                        Поле обов'язкове
                      </FormHelperText>
                    )} */}
              </Grid>

              <Grid item>
                <UncontrolledTooltip placement="top" target="selectLocalityRecipient">
                  Оберіть населений пункт із списку.
                </UncontrolledTooltip>
                <div>
                  <Autocomplete
                    id="selectLocalityRecipient"
                    value={
                      this.props.catalog.locality.filter(({ value }) => value === this.props.recipient.p_koatuu)[0] ||
                      {}
                    }
                    onChange={this.props.handleSelectChange('p_koatuu')}
                    autoHighlight
                    // getOptionSelected={(option) => option.value === this.props.recipient.p_koatuu}
                    getOptionLabel={(option) => (isEmpty(option) ? '' : `${option?.label}, ${option?.district}`)}
                    // renderOption={(option) => (
                    //   <Grid container direction="column">
                    //     <span>{option?.label || ''}</span>
                    //     <span style={{ color: '#999', fontSize: '12px' }}>{option?.district || ''}</span>
                    //   </Grid>
                    // )}
                    options={this.props.catalog.locality}
                    loading={this.props.loading.CATALOG}
                    filterOption={this.filterOptions}
                    disabled={pl_locality}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // className={addressData.region.visible[addressData.type] ? '' : 'd-none'}
                        label="Населений пункт"
                        // margin="dense"
                        // size="small"
                        // variant="outlined"
                      />
                    )}
                  />
                </div>
                {/* </BootstrapTooltip> */}
                {/* {submitted && !this.props.recipient.p_koatuu && (
                      <FormHelperText error id="name-error-text">
                        Поле обов'язкове
                      </FormHelperText>
                    )} */}
              </Grid>

              <Grid item>
                <div>
                  {/* <label htmlFor="p_adress">Місцезнаходження</label> */}
                  <UncontrolledTooltip placement="top" target="inputAdressRecipient">
                    Введіть адресу. Дозволено введення цифр, літер українською мовою та символів <br /> /\ №"" '., -
                  </UncontrolledTooltip>
                  <TextField
                    id="inputAdressRecipient"
                    required
                    value={this.props.recipient.p_adress}
                    onChange={this.props.handleChange}
                    name="p_adress"
                    type="text"
                    maxLength="128"
                    label="Місцезнаходження"
                    inputProps={{
                      readOnly: pl_adress,
                    }}
                    // className="form-control"
                    // className={submitted && !this.props.recipient.p_adress ? 'error' : ''}
                  />

                  {/* {submitted && !this.props.recipient.p_adress && (
                      <FormHelperText error id="name-error-text">
                        Поле обов'язкове
                      </FormHelperText>
                    )} */}
                </div>
              </Grid>

              <Grid item>
                {/* <label htmlFor="">Назва отримувача </label> */}
                <UncontrolledTooltip placement="top" target="inputNameRecipient">
                  Введіть назву отримувача. Дозволено введення цифр, літер українською мовою та символів \/№"".,' - ()
                </UncontrolledTooltip>
                <TextField
                  id="inputNameRecipient"
                  required
                  value={this.props.recipient.p_orgname}
                  onChange={this.props.handleChange}
                  name="p_orgname"
                  type="text"
                  maxLength="250"
                  label="Назва отримувача"
                  inputProps={{
                    readOnly: pl_orgname,
                  }}
                  // className={submitted && !this.props.recipient.p_orgname ? 'error' : ''}
                />
                {/* {submitted && !this.props.recipient.p_orgname && (
                      <FormHelperText error id="name-error-text">
                        Поле обов'язкове
                      </FormHelperText>
                    )} */}
              </Grid>

              <Grid item>
                <UncontrolledTooltip placement="top" target="selectRecipientType">
                  Оберіть тип отримувача зі списку.
                </UncontrolledTooltip>

                <Autocomplete
                  id="selectRecipientType"
                  value={
                    this.props.catalog.recipientTypes.filter(
                      ({ value }) => Number(value) === Number(this.props.recipient.p_recipientType)
                    )[0] || {}
                  }
                  onChange={this.props.handleSelectChange('p_recipientType')}
                  autoHighlight
                  // getOptionSelected={(option) => option.value === this.props.recipient.p_recipientType}
                  getOptionLabel={(option) => option?.label || ''}
                  // isOptionEqualToValue={(option, value) => option.value === value.value}
                  options={this.props.catalog.recipientTypes}
                  loading={this.props.loading.CATALOG}
                  disabled={pl_recipient_type}
                  renderInput={(params) => <TextField {...params} label="Організаційно-правова форма" />}
                />
              </Grid>

              <Grid item>
                <Typography style={{ color: '#7f8fa4', fontWeight: 'bold' }}>Керівник:</Typography>
              </Grid>

              <Grid item>
                <UncontrolledTooltip placement="top" target="inputSuNameBossRecipient">
                  Введіть прізвище керівника отримувача. Дозволено введення цифр літер українською мовою та символів \ /
                  № «» . , ’ -
                </UncontrolledTooltip>
                <TextField
                  id="inputSuNameBossRecipient"
                  required
                  value={this.props.recipient.p_surname}
                  onChange={this.props.handleChange}
                  name="p_surname"
                  type="text"
                  maxLength="64"
                  label="Прізвище"
                  inputProps={{
                    readOnly: pl_surname,
                  }}
                  // className={submitted && !this.props.recipient.p_surname ? 'error' : ''}
                />

                {/* {submitted && !this.props.recipient.p_surname && (
                      <FormHelperText error id="name-error-text">
                        Поле обов'язкове
                      </FormHelperText>
                    )} */}
              </Grid>

              <Grid item>
                {/* <label htmlFor="">Ім'я</label> */}
                <UncontrolledTooltip placement="top" target="inputNameBossRecipient">
                  Введіть ім&#39;я керівника отримувача. Дозволено введення цифр, літер українською мовою та символів
                  &apos;-
                </UncontrolledTooltip>
                <TextField
                  id="inputNameBossRecipient"
                  required
                  value={this.props.recipient.p_name}
                  onChange={this.props.handleChange}
                  name="p_name"
                  type="text"
                  maxLength="64"
                  label="Ім'я"
                  inputProps={{
                    readOnly: pl_name,
                  }}
                  // className={submitted && !this.props.recipient.p_name ? 'error' : ''}
                />
                {/* {submitted && !this.props.recipient.p_name && (
                      <FormHelperText error id="name-error-text">
                        Поле обов'язкове
                      </FormHelperText>
                    )} */}
              </Grid>

              <Grid item>
                <UncontrolledTooltip placement="top" target="inputFatherNameRecipient">
                  Введіть по батькові керівника отримувача. Дозволено введення цифр, літер українською мовою та символів
                  символів \ / № «» . , ’ -
                </UncontrolledTooltip>
                <TextField
                  id="inputFatherNameRecipient"
                  value={this.props.recipient.p_patronymic}
                  onChange={this.props.handleChange}
                  name="p_patronymic"
                  type="text"
                  maxLength="64"
                  label="По батькові"
                  inputProps={{
                    readOnly: pl_patronymic,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={6} xs={12}>
            <Grid container direction="column" spacing={5}>
              {/* {(this.props.recipient.p_recipientType === 2 || this.props.recipient.p_recipientType === 3) && ( */}
              <>
                <Grid item>
                  <Typography style={{ color: '#7f8fa4', fontWeight: 'bold' }}>
                    Відомості про уповноважену особу іноземної організації:
                  </Typography>
                </Grid>

                <Grid item>
                  <UncontrolledTooltip placement="top" target="inputReprOccupation">
                    Введіть посаду уповноваженої особи отримувача. Дозволено введення цифр, літер українською мовою та
                    символів символів \ / № «» . , ’ -
                  </UncontrolledTooltip>
                  <TextField
                    disabled={diplomacy}
                    id="inputReprOccupation"
                    value={this.props.recipient.p_reprOccupation || ''}
                    onChange={this.props.handleChange}
                    name="p_reprOccupation"
                    type="text"
                    maxLength="64"
                    label="Посада"
                  />
                </Grid>

                <Grid item>
                  <UncontrolledTooltip placement="top" target="inputReprSurname">
                    Введіть прізвище уповноваженої особи отримувача. Дозволено введення цифр, літер українською мовою та
                    символів символів \ / № «» . , ’ -
                  </UncontrolledTooltip>
                  <TextField
                    disabled={diplomacy}
                    id="inputReprSurname"
                    value={this.props.recipient.p_reprSurname || ''}
                    onChange={this.props.handleChange}
                    name="p_reprSurname"
                    type="text"
                    maxLength="64"
                    label="Прізвище"
                  />
                </Grid>

                <Grid item>
                  <UncontrolledTooltip placement="top" target="inputReprFirstname">
                    Введіть ім'я уповноваженої особи отримувача. Дозволено введення цифр, літер українською мовою та
                    символів символів \ / № «» . , ’ -
                  </UncontrolledTooltip>
                  <TextField
                    disabled={diplomacy}
                    id="inputReprFirstname"
                    value={this.props.recipient.p_reprFirstname || ''}
                    onChange={this.props.handleChange}
                    name="p_reprFirstname"
                    type="text"
                    maxLength="64"
                    label="Ім'я"
                  />
                </Grid>

                <Grid item>
                  <UncontrolledTooltip placement="top" target="inputReprPatronymic">
                    Введіть по батькові уповноваженої особи отримувача. Дозволено введення цифр, літер українською мовою
                    та символів символів \ / № «» . , ’ -
                  </UncontrolledTooltip>
                  <TextField
                    disabled={diplomacy}
                    id="inputReprPatronymic"
                    value={this.props.recipient.p_reprPatronymic || ''}
                    onChange={this.props.handleChange}
                    name="p_reprPatronymic"
                    type="text"
                    maxLength="64"
                    label="По батькові"
                  />
                </Grid>

                <Grid item>
                  <UncontrolledTooltip placement="top" target="agentRNOKPPRecipient">
                    Введіть РНОКПП уповноваженої особи (10 цифр)
                  </UncontrolledTooltip>
                  <TextField
                    disabled={diplomacy}
                    id="agentRNOKPPRecipient"
                    placeholder=""
                    value={this.props.recipient.p_agent_rnokpp}
                    onChange={this.props.handleChange}
                    name="p_agent_rnokpp"
                    type="text"
                    label="РНОКПП уповноваженої особи"
                    inputProps={{
                      maxLength: 10,
                    }}
                  />
                </Grid>

                <Grid item>
                  <UncontrolledTooltip placement="top" target="inputReprCode">
                    Введіть код уповноваженої особи. Дозволено введення цифр, літер та символів символів \ / № «» . , ’
                    -
                  </UncontrolledTooltip>
                  <TextField
                    disabled={diplomacy}
                    id="inputReprCode"
                    value={this.props.recipient.p_agent_code}
                    onChange={this.props.handleChange}
                    name="p_agent_code"
                    type="text"
                    label="Код уповноваженої особи"
                    inputProps={{
                      maxLength: 30,
                    }}
                  />
                </Grid>

                <Grid item>
                  <UncontrolledTooltip placement="top" target="inputReprCountry">
                    Введіть країну реєстрації уповноваженої особи отримувача зі списку.
                  </UncontrolledTooltip>
                  <Autocomplete
                    disabled={diplomacy}
                    id="inputReprCountry"
                    value={
                      this.props.catalog.country.filter(
                        ({ value }) => Number(value) === Number(this.props.recipient.p_reprCountry)
                      )[0] || {}
                    }
                    onChange={this.props.handleSelectChange('p_reprCountry')}
                    autoHighlight
                    getOptionSelected={(option) => option.value === this.props.recipient.p_reprCountry}
                    getOptionLabel={(option) => option.label || ''}
                    options={this.props.catalog.country}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    renderInput={(params) => <TextField {...params} label="Країна реєстрації головного офісу" />}
                  />
                </Grid>

                <Grid item>
                  <UncontrolledTooltip placement="top" target="inputReprLocality">
                    Введіть місцезнаходження уповноваженої особи отримувача. Дозволено введення цифр, літер українською,
                    англійською мовою та символів символів \ / № «» . , ’ -
                  </UncontrolledTooltip>
                  <TextField
                    disabled={diplomacy}
                    id="inputReprLocality"
                    value={this.props.recipient.p_reprLocality || ''}
                    onChange={this.props.handleChange}
                    name="p_reprLocality"
                    type="text"
                    maxLength="100"
                    label="Місцезнаходження"
                  />
                </Grid>
              </>
              {/* )} */}

              <Divider variant="middle" style={{ marginTop: 10 }} />

              <Grid item>
                {/* <label htmlFor="p_phone">Телефон(и)</label> */}
                <UncontrolledTooltip placement="top" target="inputPhoneRecipient">
                  Введіть телефон. Дозволено введення цифр, відповідно до прикладу у полі нижче.
                </UncontrolledTooltip>
                {/* <InputMask
                  id="inputPhoneRecipient"
                  mask="+38(999)999-99-99"
                  placeholder="+38(XXX)XXX-XX-XX"
                  maskChar={null}
                  required
                  value={this.props.recipient.p_phone}
                  onChange={this.props.handleChange}
                  name="p_phone"
                  type="text"
                  // className={submitted && !this.props.recipient.p_phone ? 'error' : ''}
                /> */}
                <TextField
                  id="inputPhoneRecipient"
                  label="Телефон"
                  value={this.props.recipient.p_phone}
                  name="p_phone"
                  onChange={this.props.handleChange}
                  placeholder="+38(XXX)XXX-XX-XX"
                  required
                  InputProps={{
                    inputComponent: TextMaskCustom,
                    inputProps: {
                      // mask: ['+38(999)999-99-99'],
                      mask: [
                        '+',
                        '3',
                        '8',
                        '(',
                        /\d/,
                        /\d/,
                        /\d/,
                        ')',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                      ],
                      // maskChar: null,
                    },
                  }}
                />

                {/* {submitted && !this.props.recipient.p_phone && (
                      <FormHelperText error id="name-error-text">
                        Поле обов'язкове
                      </FormHelperText>
                    )} */}
              </Grid>
              {/* {this.props.recipient.p_phone_max > 1 && ( */}
              <Grid item>
                <UncontrolledTooltip placement="top" target="inputPhoneRecipient2">
                  Введіть додатковий номер телефону. Дозволено введення цифр, відповідно до прикладу у полі нижче.
                </UncontrolledTooltip>
                <TextField
                  id="inputPhoneRecipient2"
                  label="Додатковий номер телефону"
                  value={this.props.recipient.p_phone2}
                  name="p_phone2"
                  onChange={this.props.handleChange}
                  placeholder="+38(XXX)XXX-XX-XX"
                  InputProps={{
                    inputComponent: TextMaskCustom,
                    inputProps: {
                      // mask: ['+38(999)999-99-99'],
                      mask: [
                        '+',
                        '3',
                        '8',
                        '(',
                        /\d/,
                        /\d/,
                        /\d/,
                        ')',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                      ],
                      // maskChar: null,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        {this.props.recipient.p_phone2 && (
                          <Tooltip title="Видалити номер телефону">
                            <IconButton aria-label="remove" onClick={this.props.removePhone}>
                              <img src={cross} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {/* )} */}

              {/* <Grid item>
                <Tooltip title="Додати номер телефону">
                  <IconButton
                    aria-label="add"
                    // classes={{
                    //   root: classes.activeButton,
                    // }}
                    onClick={this.props.addPhone}
                  >
                    <AddCircleIcon color="primary" fontSize="large" />
                  </IconButton>
                </Tooltip>

                {this.props.recipient.p_phone_max > 1 && (
                  <Tooltip title="Видалити номер телефону">
                    <IconButton aria-label="remove" onClick={this.props.removePhone}>
                      <RemoveCircleIcon style={{ color: red[600] }} fontSize="large" />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid> */}

              <Grid item>
                <UncontrolledTooltip placement="top" target="inputEmailRecipient">
                  Введіть электронну адресу відповідно до вказаного нижче прикладу.
                </UncontrolledTooltip>

                <TextField
                  id="inputEmailRecipient"
                  label="Електронна адреса"
                  value={this.props.recipient.p_email}
                  name="p_email"
                  onChange={this.props.handleChange}
                  maxLength="128"
                  required
                  placeholder="example@domain.com"
                  InputProps={{
                    inputComponent: TextMaskCustom,
                    inputProps: {
                      mask: emailMask,
                    },
                  }}
                />
                {/* {submitted && !this.props.recipient.p_emai && (
                      <FormHelperText error id="name-error-text">
                        Поле обов'язкове
                      </FormHelperText>
                    )} */}
                {/* {!isEmpty(this.props.successText) && <div className="col-12 col-sm-6  col-md-4 col-xl-4 ">
                      <UncontrolledAlert color="success">{this.props.successText}</UncontrolledAlert>
                      </div>}
                  {!isEmpty(this.props.error.RECIPIENT_SAVE) && (
                      <div className="col-12 col-sm-6  col-md-4 col-xl-4 ">
                      <UncontrolledAlert color="danger">{this.props.error.RECIPIENT_SAVE}</UncontrolledAlert>
                      </div>
                    )} */}
                {(this.getRouteMSPUSave() || this.getRouteMSPUEdit()) && (
                  <>
                    {this.getRouteMSPUEdit() ? (
                      <Grid item>
                        <UncontrolledTooltip placement="top" target="inputEmailRecipient">
                          Номер наказу про {this.getRouteMSPUSave() ? 'внесення змін.' : 'внесення змін'}
                        </UncontrolledTooltip>
                        {/* <label htmlFor="orderNumber">
                          Номер наказу про {this.getRouteMSPUEdit() ? 'внесення змін.' : null}
                        </label> */}
                        <TextField
                          id="orderNumber"
                          value={
                            this.getRouteMSPUSave()
                              ? this.props.recipient.p_order_cng
                              : this.props.recipient.p_order_cng
                          }
                          onChange={this.props.handleChange}
                          name={this.getRouteMSPUSave() ? 'p_order_cng' : 'p_order_cng'}
                          type="text"
                          maxLength="50"
                          label={`Номер наказу про ${this.getRouteMSPUEdit() ? 'внесення змін.' : null}`}
                        />
                      </Grid>
                    ) : null}

                    {this.getRouteMSPUEdit() ? (
                      <Grid item>
                        <UncontrolledTooltip placement="top" target="inputEmailRecipient">
                          Дата наказу про {this.getRouteMSPUSave() ? 'внесення змін.' : 'внесення змін.'}.
                        </UncontrolledTooltip>
                        <label htmlFor="dateOrder">
                          Дата наказу про {this.getRouteMSPUSave() ? 'внесення змін.' : 'внесення змін.'}
                        </label>
                        <MaskedInput
                          id="dateOrder"
                          mask={mask}
                          type="text"
                          // placeholderChar=" "
                          guide={false}
                          keepCharPositions
                          pipe={autoCorrectedDatePipe}
                          value={
                            this.getRouteMSPUSave()
                              ? this.props.recipient.p_dt_order_cng
                              : this.props.recipient.p_dt_order_cng
                          }
                          onChange={this.props.handleChange}
                          name={this.getRouteMSPUSave() ? 'p_dt_order_cng' : 'p_dt_order_cng'}
                        />
                      </Grid>
                    ) : null}

                    <Grid item>
                      <UncontrolledTooltip placement="top" target="inputEmailRecipient">
                        Номер наказу про {this.getRouteMSPUSave() ? 'включення.' : 'виключення.'}
                      </UncontrolledTooltip>
                      {/* <label htmlFor="orderNumber">
                        Номер наказу про {this.getRouteMSPUSave() ? 'включення.' : 'виключення.'}
                      </label> */}
                      <TextField
                        id="orderNumber"
                        value={
                          this.getRouteMSPUSave() ? this.props.recipient.p_order : this.props.recipient.p_order_off
                        }
                        onChange={this.props.handleChange}
                        name={this.getRouteMSPUSave() ? 'p_order' : 'p_order_off'}
                        type="text"
                        maxLength="50"
                        label={`Номер наказу про ${this.getRouteMSPUSave() ? 'включення.' : 'виключення.'}`}
                      />
                    </Grid>

                    <Grid item>
                      <UncontrolledTooltip placement="top" target="inputEmailRecipient">
                        Дата наказу про {this.getRouteMSPUSave() ? 'включення.' : 'виключення.'}.
                      </UncontrolledTooltip>
                      <label htmlFor="dateOrder">
                        Дата наказу про {this.getRouteMSPUSave() ? 'включення.' : 'виключення.'}
                      </label>
                      <MaskedInput
                        id="dateOrder"
                        mask={mask}
                        type="text"
                        // placeholderChar=" "
                        guide={false}
                        keepCharPositions
                        pipe={autoCorrectedDatePipe}
                        value={
                          this.getRouteMSPUSave()
                            ? this.props.recipient.p_dt_order
                            : this.props.recipient.p_dt_order_off
                        }
                        onChange={this.props.handleChange}
                        name={this.getRouteMSPUSave() ? 'p_dt_order' : 'p_dt_order_off'}
                        // maxLength="250"
                        // className={submitted && !this.props.recipient.p_email ? 'error' : ''}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel id="yes-no-pprivate" color="primary">
                <Typography variant="body1" style={{ textAlign: 'justify' }}>
                  Чи є отримувач суб&apos;єктом, дані якого, згідно чинного законодавства, не мають відображатися у
                  публічному доступі: органи військового управління, визначені Міноборони, правоохоронні органи, інші
                  військові формування, утворені відповідно до закону, Адміністрація Держспецзв&apos;язку чи
                  підпорядкований підрозділ, визначений нею, військові адміністрації, утворені відповідно до
                  законодавства, суб&apos;єкти, які безпосередньо здійснюють боротьбу з тероризмом або суб&apos;єкти,
                  які залучаються до боротьби з тероризмом?
                </Typography>
              </FormLabel>
              <RadioGroup
                aria-labelledby="yes-no-pprivate"
                name="radio-yes-no"
                value={p_private || ''}
                onChange={this.handleChangeRadio}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
                row
              >
                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Так" />
                <FormControlLabel value="no" color="primary" control={<Radio color="primary" />} label="Ні" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item>
            {this.getRouteMSPUSave() || this.getRouteMSPUEdit() ? (
              <Button
                className={classes.button_save}
                color="primary"
                onClick={this.saveRacipientOrder}
                disabled={this.props.registered || this.blockRequest}
              >
                Внести в реєстр
                <img className={classes.button_loading_img} src={add} />
              </Button>
            ) : (
              <Button
                color="primary"
                className={classes.button_save}
                onClick={this.props.getRoute() ? this.saveNewApplication : this.props.saveRecipient}
                disabled={this.props.registered || this.blockRequest}
              >
                Внести в реєстр
                <img className={classes.button_loading_img} src={add} />
              </Button>
            )}

            {this.props.loading.RECIPIENT_SAVE && <Loader type="spin" />}
          </Grid>
        </Grid>

        {!this.props.getRoute() && (
          // <Grid container spacing={5}>
          <div className={classes.buttonSection}>
            {/* <Grid item> */}
            <Button
              onClick={this.passToRegister}
              variant="outlined"
              className={classes.button_save}
              // color="primary"
            >
              {/* <Icon className="fa fa-arrow-alt-circle-left mr-1" />  */}
              <img className={classes.buttonR_loading_img} src={arrowBack} />
              До реєстру
            </Button>
            {/* </Grid> */}
            {/* <Grid item> */}
            <Button
              variant="outlined"
              className={classes.button_save}
              // color="primary"
              disabled={this.props.blockMoveOn}
              onClick={() => {
                this.props.toggleAction('3');
              }}
              // startIcon={}
            >
              {/* <Icon className="fa fa-arrow-alt-circle-right" /> */}
              До реєстрації вантажу
              <img className={classes.button_loading_img} src={next} />
            </Button>
            {/* </Grid> */}
          </div>
          // {/* </Grid> */}
        )}
      </>
    );
  }
}

StatementRecipient.propTypes = {
  recipient: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
  saveRecipient: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  recipientByIdNew: PropTypes.func.isRequired,
  selectRecipient: PropTypes.array.isRequired,
  // errorr: PropTypes.object,
  // loading: PropTypes.object,
  user: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(withRouter(StatementRecipient));

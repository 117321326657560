import React from 'react';
import Modal from '@material-ui/core/Modal';
import { Card, CardContent, CardActions, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    border: '2px solid #000',
    padding: theme.spacing(2, 4, 3),
  },
  cardActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
}));

const ModalWindow = ({ deleteModal, handleCloseDeleteModal, handleOpenDeleteModal, handleDeleteWithModal }) => {
  const classes = useStyles();

  const body = (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h4" align="center" gutterBottom>
          Видалення
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          Підтверджуєте видалення елементу?
        </Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button color="primary" variant="contained" onClick={handleDeleteWithModal}>
          Так
        </Button>
        <Button color="primary" variant="contained" onClick={handleCloseDeleteModal}>
          Ні
        </Button>
      </CardActions>
    </Card>
  );

  return (
    <>
      <Modal
        open={deleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        {body}
      </Modal>
    </>
  );
};

export default ModalWindow;

import {
  SET_REPORT_TAB,
  SET_REPORT_PARAMS,
  SET_REPORT_ID,
  SET_REPORT_CARGO_LIST,
  SET_REPORT_DATE,
  SET_REPORT_AIDTYPE,
  SET_REPORT_ACQUIRER,
  ADD_BALANCE_ITEM,
  SET_BALANCE_INPUT,
  SET_BALANCE_ARRAY,
  CLEAR_BALANCE_CURRENT,
  REMOVE_BALANCE_ITEM,
  SET_BALANCE_CURRENT,
  //
  ADD_DISTRIBUTION_ITEM,
  SET_DISTRIBUTION_INPUT,
  SET_DISTRIBUTION_ARRAY,
  CLEAR_DISTRIBUTION_CURRENT,
  REMOVE_DISTRIBUTION_ITEM,
  SET_DISTRIBUTION_CURRENT,
  SET_DISTRIBUTION_CARDS,
  SET_DISTRIBUTION_SAVER,
} from '../actions/action-types';

const initialDistrCurrent = {
  pi_act: null,
  // act
  pi_acq_type: '',
  pd_distribut: '',
  pc_document: '',
  pl_losses: false,
  pc_edrpou: '',
  edrpouOption: null,
  pc_orgname: '',
  //  address
  pi_region_obj: null,
  pi_atu_obj: null,
  pi_atu: '',
  pc_address: '',
  // card
  aidtypeCatalogOption: null,
  pi_balance_obj: null,
  pi_balance: '',
  pc_description: '',
  pn_weight: '',
  pn_count: '',
  pn_acqcnt: '',
};

const initialState = {
  params: {
    i_recip: null,
    curr_date: null,
    min_date: null,
    i_report: null,
  },
  reportId: '',
  balance: {
    array: [],
    current: {
      pi_id: null,
      pi_aidtype_obj: {},
      pc_descript: '',
      pn_weight_in: '',
      pn_count_in: '',
      pn_weight_out: '',
      pn_count_out: '',
      pn_weight_rej: '',
      pn_count_rej: '',
      pn_weight_bal: '',
      pn_count_bal: '',
    },
  },
  distribution: {
    array: [],
    current: {
      ...initialDistrCurrent,
    },
    cards: [],
  },
  cargo: [],
  aidtype: [],
  acquirer: [],
  distributionSaver: false,
  reportTab: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // distribution saver
    case SET_DISTRIBUTION_SAVER: {
      return {
        ...state,
        distributionSaver: action.payload,
      };
    }

    case SET_REPORT_TAB: {
      return {
        ...state,
        reportTab: action.payload,
      };
    }

    // report
    case SET_REPORT_PARAMS: {
      const { i_recip, curr_date, min_date, i_report } = action.payload;
      return {
        ...state,
        params: {
          i_recip,
          curr_date,
          min_date,
          i_report,
        },
      };
    }

    case SET_REPORT_ID: {
      return {
        ...state,
        reportId: action.payload,
      };
    }

    case SET_REPORT_CARGO_LIST: {
      return {
        ...state,
        cargo: [...action.payload],
      };
    }

    case SET_REPORT_AIDTYPE: {
      return {
        ...state,
        aidtype: [...action.payload],
      };
    }

    case SET_REPORT_ACQUIRER: {
      return {
        ...state,
        acquirer: [...action.payload],
      };
    }

    case SET_REPORT_DATE: {
      const { params } = state;
      return {
        ...state,
        params: {
          ...params,
          curr_date: action.payload,
        },
      };
    }

    // balance
    case SET_BALANCE_INPUT: {
      const { name, value } = action.payload;
      const { balance } = state;
      const {
        balance: { current },
      } = state;
      return {
        ...state,
        balance: {
          ...balance,
          current: {
            ...current,
            [name]: value,
          },
        },
      };
    }

    case ADD_BALANCE_ITEM: {
      const { balance } = state;
      const {
        balance: { array },
      } = state;
      return {
        ...state,
        balance: {
          ...balance,
          array: [
            {
              pi_id: null,
              pi_aidtype_obj: {},
              pc_descript: '',
              pn_weight_in: '',
              pn_count_in: '',
              pn_weight_out: '',
              pn_count_out: '',
              pn_weight_rej: '',
              pn_count_rej: '',
              pn_weight_bal: '',
              pn_count_bal: '',
            },
            ...array,
          ],
        },
      };
    }

    case SET_BALANCE_ARRAY: {
      const { balance } = state;
      return {
        ...state,
        balance: {
          ...balance,
          array: [...action.payload],
        },
      };
    }

    case CLEAR_BALANCE_CURRENT: {
      const { balance } = state;
      return {
        ...state,
        balance: {
          ...balance,
          current: {
            pi_id: null,
            pi_aidtype_obj: {},
            pc_descript: '',
            pn_weight_in: '',
            pn_count_in: '',
            pn_weight_out: '',
            pn_count_out: '',
            pn_weight_rej: '',
            pn_count_rej: '',
            pn_weight_bal: '',
            pn_count_bal: '',
          },
        },
      };
    }

    case REMOVE_BALANCE_ITEM: {
      const { balance } = state;
      const {
        balance: { array },
      } = state;
      return {
        ...state,
        balance: {
          ...balance,
          array: [...array.slice(1)],
        },
      };
    }

    case SET_BALANCE_CURRENT: {
      const { balance } = state;
      return {
        ...state,
        balance: {
          ...balance,
          current: { ...action.payload },
        },
      };
    }

    // distribution
    case SET_DISTRIBUTION_INPUT: {
      const { name, value } = action.payload;
      const { distribution } = state;
      const {
        distribution: { current },
      } = state;
      return {
        ...state,
        distribution: {
          ...distribution,
          current: {
            ...current,
            [name]: value,
          },
        },
      };
    }

    // ???
    case ADD_DISTRIBUTION_ITEM: {
      const { distribution } = state;
      const {
        distribution: { array },
      } = state;
      const { payload } = action;

      return {
        ...state,
        distribution: {
          ...distribution,
          array,
          current: {
            ...initialDistrCurrent,
          },
        },
      };
    }

    case SET_DISTRIBUTION_ARRAY: {
      const { distribution } = state;
      return {
        ...state,
        distribution: {
          ...distribution,
          array: [...action.payload],
        },
      };
    }

    case CLEAR_DISTRIBUTION_CURRENT: {
      const { distribution } = state;
      return {
        ...state,
        distribution: {
          ...distribution,
          current: {
            ...initialDistrCurrent,
          },
          cards: [],
        },
      };
    }

    case REMOVE_DISTRIBUTION_ITEM: {
      const { distribution } = state;
      const {
        distribution: { array },
      } = state;
      return {
        ...state,
        distribution: {
          ...distribution,
          array: [...array.slice(0, action.payload)],
        },
      };
    }

    case SET_DISTRIBUTION_CURRENT: {
      const { distribution } = state;
      return {
        ...state,
        distribution: {
          ...distribution,
          current: { ...action.payload },
        },
      };
    }

    case SET_DISTRIBUTION_CARDS: {
      const { distribution } = state;
      return {
        ...state,
        distribution: {
          ...distribution,
          cards: [...action.payload],
        },
      };
    }

    default:
      return state;
  }
}

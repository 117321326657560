import * as Excel from 'exceljs/dist/exceljs.min';

const addTitles1 = (worksheet) => {
  worksheet.mergeCells('A1:A2');
  const c1 = worksheet.getCell('A1');
  c1.value = 'Товар по коду ГД';

  worksheet.mergeCells('B1:B2');
  const c2 = worksheet.getCell('B1');
  c2.value = 'Найменування товарів';

  worksheet.mergeCells('C1:D1');
  const c3 = worksheet.getCell('C1');
  c3.value = 'Отримано у звітному місяці';

  worksheet.mergeCells('E1:F1');
  const c4 = worksheet.getCell('E1');
  c4.value = 'Розподілено у звітному місяці';

  worksheet.mergeCells('G1:H1');
  const c5 = worksheet.getCell('G1');
  c5.value = 'Втрати загалом';

  worksheet.mergeCells('I1:J1');
  const c6 = worksheet.getCell('I1');
  c6.value = 'Залишок загалом';

  const c7 = worksheet.getCell('C2');
  c7.value = 'Вага, кг/об’єм, л';

  const c8 = worksheet.getCell('D2');
  c8.value = 'Кількість, шт.';

  const c9 = worksheet.getCell('E2');
  c9.value = 'Вага, кг/об’єм, л';

  const c10 = worksheet.getCell('F2');
  c10.value = 'Кількість, шт.';

  const c11 = worksheet.getCell('G2');
  c11.value = 'Вага, кг/об’єм, л';

  const c12 = worksheet.getCell('H2');
  c12.value = 'Кількість, шт.';

  const c13 = worksheet.getCell('I2');
  c13.value = 'Вага, кг/об’єм, л';

  const c14 = worksheet.getCell('J2');
  c14.value = 'Кількість, шт.';

  const titles1 = worksheet.getRow(1);
  titles1.eachCell((cell) => {
    // eslint-disable-next-line no-param-reassign
    cell.border = {
      top: { style: 'thin', color: { argb: '00000000' } },
      left: { style: 'thin', color: { argb: '00000000' } },
      bottom: { style: 'thin', color: { argb: '00000000' } },
      right: { style: 'thin', color: { argb: '00000000' } },
    };
    // eslint-disable-next-line no-param-reassign
    cell.font = {
      name: 'Times New Roman',
      size: 10,
      bold: true,
    };
    // eslint-disable-next-line no-param-reassign
    cell.alignment = {
      vertical: 'top',
      horizontal: 'center',
      wrapText: true,
    };
  });
  const titles2 = worksheet.getRow(2);
  titles2.eachCell((cell) => {
    // eslint-disable-next-line no-param-reassign
    cell.border = {
      top: { style: 'thin', color: { argb: '00000000' } },
      left: { style: 'thin', color: { argb: '00000000' } },
      bottom: { style: 'thin', color: { argb: '00000000' } },
      right: { style: 'thin', color: { argb: '00000000' } },
    };
    // eslint-disable-next-line no-param-reassign
    cell.font = {
      name: 'Times New Roman',
      size: 10,
      bold: true,
    };
    // eslint-disable-next-line no-param-reassign
    cell.alignment = {
      vertical: 'top',
      horizontal: 'center',
      wrapText: true,
    };
  });
};

const addTitles2 = (worksheet) => {
  const col_names = [
    'Набувачі',
    'Код ЄДРПОУ',
    'Товар по коду ГД',
    'Найменування товарів',
    "Вага, кг/ Об'єм, л",
    'Кількість, шт.',
    'Підтверджуючі документи',
    'Кількість осіб',
    'Дата розподілу',
    'Адреса',
  ];

  // eslint-disable-next-line no-param-reassign
  worksheet.getRow(1).values = [...col_names];
  const titles = worksheet.getRow(1);
  titles.eachCell((cell) => {
    // eslint-disable-next-line no-param-reassign
    cell.border = {
      top: { style: 'thin', color: { argb: '00000000' } },
      left: { style: 'thin', color: { argb: '00000000' } },
      bottom: { style: 'thin', color: { argb: '00000000' } },
      right: { style: 'thin', color: { argb: '00000000' } },
    };
    // eslint-disable-next-line no-param-reassign
    cell.font = {
      name: 'Times New Roman',
      size: 10,
      bold: true,
    };
    // eslint-disable-next-line no-param-reassign
    cell.alignment = {
      vertical: 'top',
      horizontal: 'center',
      wrapText: true,
    };
  });
};

const addData1 = (worksheet, data) => {
  const col_keys = [
    'i_aidtype',
    'c_description',
    'n_weight_in',
    'n_count_in',
    'n_weight_out',
    'n_count_out',
    'n_weight_rej',
    'n_count_rej',
    'n_weight_bal',
    'n_count_bal',
  ];

  // eslint-disable-next-line no-param-reassign
  worksheet.columns = [
    { key: 'i_aidtype', width: 20 },
    { key: 'c_description', width: 20 },
    { key: 'n_weight_in', width: 20 },
    { key: 'n_count_in', width: 20 },
    { key: 'n_weight_out', width: 20 },
    { key: 'n_count_out', width: 20 },
    { key: 'n_weight_rej', width: 20 },
    { key: 'n_count_rej', width: 20 },
    { key: 'n_weight_bal', width: 20 },
    { key: 'n_count_bal', width: 20 },
  ];

  worksheet.addRows([...data]);

  // eslint-disable-next-line no-restricted-syntax
  for (const key of col_keys) {
    const col = worksheet.getColumn(key);
    col.eachCell((cell, rowNumber) => {
      if (rowNumber >= 3) {
        // eslint-disable-next-line no-param-reassign
        cell.border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } },
        };
        // eslint-disable-next-line no-param-reassign
        cell.font = {
          name: 'Times New Roman',
          color: { argb: '00000000' },
          size: 10,
        };
        // eslint-disable-next-line no-param-reassign
        cell.alignment = {
          vertical: 'top',
          horizontal: 'left',
          wrapText: true,
        };
        // eslint-disable-next-line no-param-reassign
        cell.numFmt = '0';
      }
    });
  }

  // weight
  const col1 = worksheet.getColumn('n_weight_in');
  col1.eachCell((cell, rowNumber) => {
    if (rowNumber >= 3) {
      // eslint-disable-next-line no-param-reassign
      cell.numFmt = '0.000';
    }
  });

  const col2 = worksheet.getColumn('n_weight_out');
  col2.eachCell((cell, rowNumber) => {
    if (rowNumber >= 3) {
      // eslint-disable-next-line no-param-reassign
      cell.numFmt = '0.000';
    }
  });

  const col3 = worksheet.getColumn('n_weight_rej');
  col3.eachCell((cell, rowNumber) => {
    if (rowNumber >= 3) {
      // eslint-disable-next-line no-param-reassign
      cell.numFmt = '0.000';
    }
  });

  const col4 = worksheet.getColumn('n_weight_bal');
  col4.eachCell((cell, rowNumber) => {
    if (rowNumber >= 3) {
      // eslint-disable-next-line no-param-reassign
      cell.numFmt = '0.000';
    }
  });

  // count
  const col5 = worksheet.getColumn('n_count_in');
  col5.eachCell((cell, rowNumber) => {
    if (rowNumber >= 3) {
      // eslint-disable-next-line no-param-reassign
      cell.numFmt = '0.00';
    }
  });

  const col6 = worksheet.getColumn('n_count_out');
  col6.eachCell((cell, rowNumber) => {
    if (rowNumber >= 3) {
      // eslint-disable-next-line no-param-reassign
      cell.numFmt = '0.00';
    }
  });

  const col7 = worksheet.getColumn('n_count_rej');
  col7.eachCell((cell, rowNumber) => {
    if (rowNumber >= 3) {
      // eslint-disable-next-line no-param-reassign
      cell.numFmt = '0.00';
    }
  });

  const col8 = worksheet.getColumn('n_count_bal');
  col8.eachCell((cell, rowNumber) => {
    if (rowNumber >= 3) {
      // eslint-disable-next-line no-param-reassign
      cell.numFmt = '0.00';
    }
  });
};

const addData2 = (worksheet, data) => {
  const col_keys = [
    'c_orgname',
    'n_edrpou',
    'i_aidtype',
    'c_description',
    'n_weight',
    'n_count',
    'c_document',
    'n_acqcnt',
    'd_distribution',
    'i_region',
  ];

  // eslint-disable-next-line no-param-reassign
  worksheet.columns = [
    { key: 'c_orgname', width: 20 },
    { key: 'n_edrpou', width: 20 },
    { key: 'i_aidtype', width: 20 },
    { key: 'c_description', width: 20 },
    { key: 'n_weight', width: 20 },
    { key: 'n_count', width: 20 },
    { key: 'c_document', width: 20 },
    { key: 'n_acqcnt', width: 20 },
    { key: 'd_distribution', width: 20 },
    { key: 'i_region', width: 20 },
  ];

  worksheet.addRows([...data]);

  // eslint-disable-next-line no-restricted-syntax
  for (const key of col_keys) {
    const col = worksheet.getColumn(key);
    col.eachCell((cell, rowNumber) => {
      if (rowNumber >= 2) {
        // eslint-disable-next-line no-param-reassign
        cell.border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } },
        };
        // eslint-disable-next-line no-param-reassign
        cell.font = {
          name: 'Times New Roman',
          color: { argb: '00000000' },
          size: 10,
        };
        // eslint-disable-next-line no-param-reassign
        cell.alignment = {
          vertical: 'top',
          horizontal: 'left',
          wrapText: true,
        };
        // eslint-disable-next-line no-param-reassign
        cell.numFmt = '0';
      }
    });
  }

  // weight
  const col1 = worksheet.getColumn('n_weight');
  col1.eachCell((cell, rowNumber) => {
    if (rowNumber >= 2) {
      // eslint-disable-next-line no-param-reassign
      cell.numFmt = '0.000';
    }
  });

  // count
  const col2 = worksheet.getColumn('n_count');
  col2.eachCell((cell, rowNumber) => {
    if (rowNumber >= 2) {
      // eslint-disable-next-line no-param-reassign
      cell.numFmt = '0.00';
    }
  });
};

const exportReportExcel = async (data1, data2) => {
  const workbook = new Excel.Workbook();
  workbook.addWorksheet('Баланс', {
    pageSetup: { paperSize: 9, orientation: 'landscape' },
  });

  const worksheet1 = workbook.getWorksheet('Баланс');
  addTitles1(worksheet1);
  addData1(worksheet1, data1);

  workbook.addWorksheet('Розподіл', {
    pageSetup: { paperSize: 9, orientation: 'landscape' },
  });

  const worksheet2 = workbook.getWorksheet('Розподіл');
  addTitles2(worksheet2);
  addData2(worksheet2, data2);

  const uint8Array = await workbook.xlsx.writeBuffer();
  const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Report.xlsx';
  a.click();
  a.remove();
};

export default exportReportExcel;

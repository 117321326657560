import { combineReducers } from 'redux';
import isMobile from './is-mobile.reducer';
import toggle from './toggle.reducer';
import authReducer from './auth.reducer';
import { loadingReducer } from './loadingReducer';
import { errorReducer } from './errorReducer';
import catalogs from './catalog.reducer';
import recipient from './recipient.reducer';
import cargo from './cargo.reducer';
import commissionCreate from './commissionCreate.reducer';
import check from './check.reducer';
import order from './order.reducer';
import idHtml from './addIDhtml.reducer';
import access from './access.reducer';
import acts from './acts.reducer';
// import reference from './uktzed.reducer';
import declaration from './declaration.reducer';
import report from './report.reducer';
import inventory from './inventory.reducer';
import competency from './competency.reducer';

const hummanApp = combineReducers({
  isMobile,
  auth: authReducer,
  isFatching: loadingReducer,
  error: errorReducer,
  recipient,
  catalogs,
  cargo,
  toggle,
  commissionCreate,
  check,
  order,
  idHtml,
  access,
  // reference,
  declaration,
  report,
  inventory,
  competency,
  acts,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    state = undefined;
  }

  return hummanApp(state, action);
};

export default rootReducer;

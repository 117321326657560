import { NUMBER_DT } from './action-types';

import { getDetailedView } from '../../api/api';

export const getTakePDFSAction = (data) => ({
  type: 'TakePDFS_DataID',
  payload: data,
});

export const getTakePDFSData = (userId) => async (dispatch) => {
  function successDataID(data) {
    dispatch(getTakePDFSAction(data));
    return data;
  }
  try {
    dispatch({ type: 'REQUEST_TakePDFS' });
    const response = await getDetailedView(userId);
    dispatch({ type: 'SUCCESS_TakePDFS' });
    successDataID(response.data);
  } catch (error) {
    // error(error)
    dispatch({ type: 'ERROR_TakePDFS', error });
  }
};

export const numberOrderAction = (data) => ({
  type: NUMBER_DT,
  payload: data,
});

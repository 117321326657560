import { TOGGLE } from '../actions/action-types';

const initialState = '1';

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE:
      return action.payload;

    default:
      return state;
  }
}

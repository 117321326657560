import React, { useState } from 'react';
import './CustomDropdownAct.scss';
import Menu from '@material-ui/core/Menu';
import { MenuItem } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { history } from '../../../../vibe/components/utilities/MainUtils/hist';
import { actionDeleteAct, actionGetAct } from '../../../../redux/actions/acts.action';

const CustomDropdownAct = ({ act }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(act);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openViewPage = () => {
    setLoading(true);
    dispatch(actionGetAct(act.id))
      .then(() => {
        history.push('/act-view');
        setLoading(false);
        handleClose();
      })
      .catch((error) => {
        setLoading(false);
        handleClose();
        console.error('Failed to save act:', error);
      });
  };

  const openEditPage = () => {
    setLoading(true);
    dispatch(actionGetAct(act.id))
      .then(() => {
        history.push('/act-edit');
        setLoading(false);
        handleClose();
      })
      .catch((error) => {
        setLoading(false);
        handleClose();
        console.error('Failed to save act:', error);
      });
  };

  const removeAct = () => {
    setLoading(true);
    dispatch(actionDeleteAct(act.id))
      .then(() => {
        setLoading(false);
        handleClose();
      })
      .catch((error) => {
        setLoading(false);
        handleClose();
        console.error('Failed to save act:', error);
      });
  };

  return (
    <div className="custom-dropdown">
      <div className="actions-button" onClick={handleClick} />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {!loading ? (
          <>
            <MenuItem onClick={() => openViewPage()}>Переглянути</MenuItem>
            {(act.i_state === 0 || act.i_state === 10) && (
              <>
                <MenuItem onClick={() => openEditPage()}>Редагувати</MenuItem>
                <MenuItem onClick={() => removeAct()}>Видалити</MenuItem>
              </>
            )}
          </>
        ) : (
          <div className="custom-loader">
            <img
              loading="lazy"
              height="100px"
              width="100px"
              className="custom-loader__spinner"
              src={
                "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 200 200'%3E%3CradialGradient id='a12' cx='.66' fx='.66' cy='.3125' fy='.3125' gradientTransform='scale(1.5)'%3E%3Cstop offset='0' stop-color='%23000000'%3E%3C/stop%3E%3Cstop offset='.3' stop-color='%23000000' stop-opacity='.9'%3E%3C/stop%3E%3Cstop offset='.6' stop-color='%23000000' stop-opacity='.6'%3E%3C/stop%3E%3Cstop offset='.8' stop-color='%23000000' stop-opacity='.3'%3E%3C/stop%3E%3Cstop offset='1' stop-color='%23000000' stop-opacity='0'%3E%3C/stop%3E%3C/radialGradient%3E%3Ccircle transform-origin='center' fill='none' stroke='url(%23a12)' stroke-width='14' stroke-linecap='round' stroke-dasharray='200 1000' stroke-dashoffset='0' cx='100' cy='100' r='70' %3E%3CanimateTransform type='rotate' attributeName='transform' calcMode='spline' dur='0.7' values='360;0' keyTimes='0;1' keySplines='0 0 1 1' repeatCount='indefinite' %3E%3C/animateTransform%3E%3C/circle%3E%3Ccircle transform-origin='center' fill='none' opacity='.2' stroke='%23000000' stroke-width='14' stroke-linecap='round' cx='100' cy='100' r='70' %3E%3C/circle%3E%3C/svg%3E"
              }
              alt="spinner"
            />
          </div>
        )}
      </Menu>
    </div>
  );
};

export default CustomDropdownAct;

import React, { useState } from 'react';
import headerLogo from '../../../assets/images/ASRGD_Logo 3.svg';
import { Button, Card, CardContent, TextField } from '@material-ui/core';
import rules from '../../../vibe/components/utilities/MainUtils/rules';
import {getCargoForMVS, viewDeclarationForPublic} from '../../../api/api';
import Table from '@material-ui/core/Table';
import { StyledTableCell } from '../RegisterRecipients/StyledTableComponents';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import { UncontrolledTooltip } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import { EnhancedTableHead } from '../../elements/CustomTable';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import getPage from '../RegisterRecipients/RegistrationFormsRecipient/getPDFfile';

const headCells = [
  { label: 'Код вантажу', numeric: false, disablePadding: false, id: 'i_cargo' },
  { label: 'Дата реєстрації вантажу', numeric: false, disablePadding: false, id: 'reg_date' },
  { label: 'Дата митного оформлення', numeric: false, disablePadding: false, id: 'customs_date' },
  { label: 'Статус', numeric: false, disablePadding: false, id: 'state' },
  { label: 'Країна відправлення', numeric: false, disablePadding: false, id: 'country' },
  { label: 'Перегляд', numeric: false, disablePadding: false, id: 'view' },
];

const MvsPage = () => {
  const [pi_cargo, setPiCargo] = useState('');
  const [pc_vin, setPcVin] = useState('');
  const [visible, setVisible] = useState(false);
  const [dataTable, setDataTable] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'pi_cargo':
        rules.edrpou.test(event.target.value) && setPiCargo(value);
        break;
      case 'pc_vin':
        rules.vin.test(event.target.value) && setPcVin(value);
        break;
      default:
        break;
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const data = {
      pi_cargo,
      pc_vin,
    };
    getCargoForMVS(data)
      .then(response => {
        setDataTable(response.data);
        setVisible(true);
        setPcVin('');
        setPiCargo('');
      })
      .catch(error => {
        setDataTable([]);
        setVisible(false);
        setPcVin('');
        setPiCargo('');
      });
  };

  const downloadDeclaration = (id) => {
    viewDeclarationForPublic(id)
      .then((res) => {
        return getPage(res.data);
      })
      .then((file) => {
        file.open();
      })
      .catch((err) => {});
  };

  return (
    <div>
      <div className="page">
        <div className="modalHeader">
          <a href="https://www.ioc.gov.ua">
            <img className="modalLogo" src={headerLogo} alt="modalLogo"/>
          </a>
        </div>
        <div className="main">
          <div className="RegisterRecipient">
            <section className="searchRegisterRecipient">
              <Card>
                <form onSubmit={handleSearch} autoComplete="off">
                  <CardContent>
                    <div className="row">
                      <div className="col-12 col-sm-6 col-md-4 col-xl-3 mt-2">
                        <TextField
                          label="Код вантажу"
                          value={pi_cargo}
                          onChange={handleChange}
                          name="pi_cargo"
                          type="text"
                          autocomplete="off"
                          InputLabelProps={{ style: { fontSize: '12px' } }}
                        />
                      </div>
                      <div className="col-12 col-sm-6 col-md-4 col-xl-3 mt-2">
                        <TextField
                          label="VIN код"
                          value={pc_vin}
                          onChange={handleChange}
                          name="pc_vin"
                          type="text"
                          autocomplete="off"
                          InputLabelProps={{ style: { fontSize: '12px' } }}
                        />
                      </div>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ margin: '10px auto auto auto'}}
                      >
                        <i className="fa fa-search mr-1" /> Пошук
                      </Button>
                    </div>
                  </CardContent>
                </form>
              </Card>
              {visible ? (
                <Card>
                  <CardContent>
                    <div className="table-responsive">
                      <Table style={{ fontSize: '12px' }} hover="true" size="small">
                        <EnhancedTableHead
                          rowCount={dataTable.length}
                          headCells={headCells}
                        />
                        <TableBody>
                          {dataTable.map((item, index) => {
                          const { i_cargo, reg_date, customs_date, state, country } = item;
                          return (
                          <TableRow key={i_cargo}>
                          <StyledTableCell>{i_cargo}</StyledTableCell>
                          <StyledTableCell>{reg_date}</StyledTableCell>
                          <StyledTableCell>{customs_date}</StyledTableCell>
                          <StyledTableCell>{state}</StyledTableCell>
                            <StyledTableCell>{country}</StyledTableCell>
                          <StyledTableCell>
                            <UncontrolledTooltip placement="top" target={`eyeview${index}`}>
                              Перегляд
                            </UncontrolledTooltip>
                            <IconButton
                              id={`eyeview${index}`}
                              aria-label="view"
                              color="primary"
                              onClick={(e) => {
                                e.stopPropagation();
                                downloadDeclaration(i_cargo);
                              }}
                            >
                              <VisibilityOutlinedIcon style={{ fontSize: 21 }} />
                            </IconButton>
                          </StyledTableCell>
                        </TableRow>
                        )})}
                      </TableBody>
                    </Table>
                  </div>
                </CardContent>
                </Card>
              ) : (
                <></>
              )}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MvsPage;

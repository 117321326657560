import * as Excel from 'exceljs/dist/exceljs.min';

const makeHeader = (worksheet) => {
  worksheet.mergeCells('A1:L1');
  const customCell1 = worksheet.getCell('A1');
  customCell1.value = 'Єдиний реєстр отримувачів';
  customCell1.font = {
    name: 'Times New Roman',
    size: 12,
    bold: true,
  };
  customCell1.alignment = { vertical: 'middle', horizontal: 'center' };
};

const addTitles = (worksheet) => {
  const col_names = [
    '№ отримувача в реєстрі',
    'Область',
    'Населений пункт',
    'Місцезнаходження',
    'Код ЄДРПОУ',
    'Назва отримувача',
    'Тип отримувача',
    'ПІБ керівника',
    'Телефон',
    'Дата включення в реєстр',
    'Дата виключення з реєстру',
    'Користувач',
    'Електронна адреса',
  ];

  worksheet.getRow(2).values = [...col_names];
  const titles = worksheet.getRow(2);
  titles.eachCell((cell) => {
    // eslint-disable-next-line no-param-reassign
    cell.border = {
      top: { style: 'thin', color: { argb: '00000000' } },
      left: { style: 'thin', color: { argb: '00000000' } },
      bottom: { style: 'thin', color: { argb: '00000000' } },
      right: { style: 'thin', color: { argb: '00000000' } },
    };
    // eslint-disable-next-line no-param-reassign
    cell.font = {
      name: 'Times New Roman',
      size: 10,
      bold: true,
    };
    // eslint-disable-next-line no-param-reassign
    cell.alignment = {
      vertical: 'top',
      horizontal: 'center',
      wrapText: true,
    };
  });
};

const addData = (worksheet, data) => {
  const col_keys = [
    'n_number',
    'region_name',
    'locality_name',
    'c_adress',
    'n_edrpou',
    'c_orgname',
    // recipient_type
    'recipient_type',
    // pib
    'c_pib',
    'n_phone',
    'dateStart',
    'dateEnd',
    'applicant_pib',
    'c_email',
  ];

  worksheet.columns = [
    { key: 'n_number', width: 7 },
    { key: 'region_name', width: 10 },
    { key: 'locality_name', width: 10 },
    { key: 'c_adress', width: 20 },
    { key: 'n_edrpou', width: 10 },
    { key: 'c_orgname', width: 12 },
    { key: 'recipient_type', width: 12 },
    { key: 'c_pib', width: 12 },
    { key: 'n_phone', width: 12 },
    { key: 'dateStart', width: 10 },
    { key: 'dateEnd', width: 10 },
    { key: 'applicant_pib', width: 7 },
    { key: 'c_email', width: 15 },
  ];

  worksheet.addRows([...data]);

  // eslint-disable-next-line no-restricted-syntax
  for (const key of col_keys) {
    const col = worksheet.getColumn(key);
    col.eachCell(function (cell, rowNumber) {
      if (rowNumber >= 3) {
        // eslint-disable-next-line no-param-reassign
        cell.border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } },
        };
        // eslint-disable-next-line no-param-reassign
        cell.font = {
          name: 'Times New Roman',
          color: { argb: '00000000' },
          size: 10,
        };
        // eslint-disable-next-line no-param-reassign
        cell.alignment = {
          vertical: 'top',
          horizontal: 'left',
          wrapText: true,
        };
        // eslint-disable-next-line no-param-reassign
        cell.numFmt = '0';
      }
    });
  }
};

const exportExcel = async (data) => {
  const workbook = new Excel.Workbook();
  workbook.addWorksheet('Єдиний реєстр', {
    pageSetup: { paperSize: 9, orientation: 'landscape' },
  });

  const worksheet = workbook.getWorksheet('Єдиний реєстр');
  makeHeader(worksheet);
  addTitles(worksheet);
  addData(worksheet, data);

  const uint8Array = await workbook.xlsx.writeBuffer();
  const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Reestr.xlsx';
  a.click();
  a.remove();
};

export default exportExcel;

import React from 'react';
import { IconButton, withStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const StyledIconButton = withStyles({
  root: {
    outline: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  label: {
    fontSize: '32px',
  },
})(IconButton);

export default function ToggleSidebarButton({ isSidebarCollapsed, toggleSidebar }) {
  const screenReaderLabel = isSidebarCollapsed ? 'Expand Sidebar Navigation' : 'Collapse Sidebar Navigation';

  return (
    <StyledIconButton size="small" onClick={toggleSidebar} aria-label={screenReaderLabel}>
      <MenuIcon fontSize="inherit" />
    </StyledIconButton>
  );
}

import React from 'react';
import ReactTable from 'react-table';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
// mui
import DoneIcon from '@material-ui/icons/Done';
import IconButton from '@material-ui/core/IconButton';
import Detail from '@material-ui/icons/FindInPage';
// reactstrap
import { UncontrolledTooltip } from 'reactstrap';
// styles
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import * as Feather from 'react-feather';
import axios from 'axios';
import { customTheme, customStyles } from '../../../../customStyles/reactSelect';
import { saveDecision, getMeetingList, getRecipientOldNew, getCargoOldNew } from '../../../../api/api';
import { setMeetingAction, typeMeeting } from '../../../../redux/actions/commissionCreate.action';
import { recipientNew, recipientOld } from '../../../../redux/actions/recipient.action';
import { cargoNew, cargoOld } from '../../../../redux/actions/cargo.action';
import BootstrapTooltip from '../../../elements/MaterialBootstrap';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});
class Vouting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [{ text: 'text' }],
      typeMeeting: '',
    };
  }

  handleVoute(data) {
    swalWithBootstrapButtons
      .fire({
        text: 'Зберегти рішення?',
        showCancelButton: true,
        confirmButtonText: 'Так',
        cancelButtonText: 'Відміна',
      })
      .then(async (result) => {
        if (result.value) {
          try {
            const dataSave = {
              n_number: data.n_number,
              app_type: data.app_type,
              decision: data.decision,
              c_orgname: data.c_orgname,
              n_edrpou: data.n_edrpou,
              c_comment: data.c_comment,
              agenda_id: data.agenda_id,
              user_id: this.props.user.id,
            };
            const response = await saveDecision(dataSave);
            swalWithBootstrapButtons.fire({
              title: 'Рішення збережено!',
              type: 'success',
            });
            try {
              const success = await getMeetingList(this.props.id, this.props.user.id);
              this.props.setMeetingAction(success.data);
            } catch (error) {
              swalWithBootstrapButtons.fire({
                title: error.response.data,
                type: 'error',
              });
            }
          } catch (error) {
            swalWithBootstrapButtons.fire({
              title: error.response.data,
              type: 'error',
            });
          }
        }
      });
  }

  async ditailedView(id) {
    try {
      const [response, success] = await axios.all([getRecipientOldNew(id), getCargoOldNew(id)]);
      this.props.recipientNew(response.data.new);
      this.props.recipientOld(response.data.old);
      this.props.typeMeeting(response.data.p_apl_type);
      // this.props.cargoNew(success.data.new)
      this.props.cargoOld(success.data);

      setTimeout(() => {
        this.props.history.push(`/detail-info-view`);
      }, 100);
    } catch (error) {
      swalWithBootstrapButtons.fire({
        title: error.response.data,
        type: 'error',
      });
    }
  }

  handleSelect = (cellInfo) => (
    <BootstrapTooltip title="Оберіть рішення зі списку">
      <div>
        <Select
          value={this.props.decisonType.filter(({ value }) => parseInt(value) === parseInt(cellInfo.original.decision))}
          onChange={(select) => {
            const rows = [...this.props.meeting];
            if (select) {
              rows[cellInfo.index][cellInfo.column.id] = select.value;
              this.props.setMeetingAction(rows);
            }
          }}
          options={this.props.decisonType}
          styles={customStyles}
          placeholder=""
          // isMulti
          noOptionsMessage={() => 'помилка серверу'}
          menuPortalTarget={document.body}
          isSearchable
          filterOption={this.filterOptions}
          theme={customTheme}
        />
      </div>
    </BootstrapTooltip>
  );

  handleChange = (cellInfo) => (
    <BootstrapTooltip title="Напишіть коментар у разі необхідності">
      <div>
        <TextareaAutosize
          maxLength="500"
          rows="3"
          cols="10"
          name="text"
          width="100%"
          style={{ width: '100%' }}
          value={cellInfo.original.c_comment}
          onChange={(e) => {
            const rows = [...this.props.meeting];
            rows[cellInfo.index][cellInfo.column.id] = e.target.value;
            this.props.setMeetingAction(rows);
          }}
        />
      </div>
    </BootstrapTooltip>
  );

  render() {
    const { history } = this.props;
    return (
      <div>
        <ReactTable
          data={this.props.meeting}
          style={{ fontSize: '0.85rem', textAlign: 'center' }}
          columns={[
            {
              Header: (
                <div>
                  № <br />
                  заяви в реєстрі
                </div>
              ),
              accessor: 'n_number',
            },

            {
              Header: (
                <div>
                  Тип
                  <br />
                  заяви
                </div>
              ),
              accessor: 'app_type',
            },
            {
              Header: (
                <div>
                  Назва
                  <br />
                  отримувача
                </div>
              ),
              accessor: 'c_orgname',
            },
            {
              Header: (
                <div>
                  Код
                  <br />
                  ЄДРПОУ
                </div>
              ),
              accessor: 'n_edrpou',
            },
            //  <UncontrolledTooltip placement="top" target="decision">
            //         Детальний перегляд інформації по заявах
            //       </UncontrolledTooltip>

            {
              Header: 'Рішення',
              accessor: 'decision',
              Cell: this.handleSelect,
            },

            {
              Header: 'Коментар',
              accessor: 'c_comment',
              // minWidth: '200px',
              Cell: this.handleChange,
            },

            {
              Header: 'Детальний перегляд',
              accessor: 'agenda_id',
              sortable: false,
              filterable: false,
              Cell: (data) => (
                <div>
                  {/* <UncontrolledTooltip placement="top" target="detailInfoAid">
                    Детальний перегляд інформації по заявах
                  </UncontrolledTooltip> */}
                  <BootstrapTooltip title=" Детальний перегляд інформації по заявах">
                    <IconButton
                      id="detailInfoAid"
                      aria-label="detailInfoAid"
                      color="primary"
                      // className="mr-3"
                      onClick={() => {
                        this.ditailedView(data.original.app_id);
                      }}
                    >
                      <Detail />
                    </IconButton>
                  </BootstrapTooltip>
                </div>
              ),
            },
            {
              Header: 'Голосування',
              accessor: 'agenda_id',
              sortable: false,
              filterable: false,
              Cell: (data) => (
                <div className="col-12">
                  {/* <UncontrolledTooltip placement="top" target="voutingDone">
                      Голосувати
                </UncontrolledTooltip> */}
                  <BootstrapTooltip title="Голосувати">
                    <IconButton id="voutingDone" onClick={() => this.handleVoute(data.original)}>
                      <DoneIcon fontSize="inherit" />
                    </IconButton>
                  </BootstrapTooltip>
                </div>
              ),
            },
          ]}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          noDataText="Інформація відсутня!"
          className="-striped -highlight"
          previousText="Попередня"
          nextText="Наступна"
          pageText="Сторінка"
          ofText="з"
          rowsText="рядків"
        />
        <BootstrapTooltip title="вихід">
          <IconButton onClick={() => history.push('/commission-meeting')}>
            <Feather.LogOut style={{ transform: 'scaleX(-1)' }} size="25" />
          </IconButton>
        </BootstrapTooltip>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    meeting: state.commissionCreate.meeting,
    user: state.auth.user,
    id: state.commissionCreate.id,
    decisonType: state.catalogs.decisonType,
  };
};

export default withRouter(
  connect(mapStateToProps, { setMeetingAction, recipientNew, recipientOld, cargoNew, cargoOld, typeMeeting })(Vouting)
);

import React, { useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles, makeStyles, createStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() =>
  createStyles({
    dropdown: {
      display: 'inline-grid',
    },
    show: {
      visibility: 'visible',
    },
    hide: {
      visibility: 'hidden',
    },
    arrowFontSize: {
      fontSize: '1.7rem',
    },
  })
);

export const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#D5DCEA',
    color: '#141414',
    border: '1px solid white',
    fontSize: 14,
    height: '56px',
    padding: '0 24px 0 16px',
  },
  body: {
    fontSize: 14,
    height: '56px',
  },
}))(TableCell);

export const StyledTableSortLabel = withStyles((theme) => ({
  root: {
    color: '#141414',
    '&:active, &:focus, &:hover': {
      color: '#141414',
    },
    width: '100%',
  },
  active: {
    color: 'white',
    '&:active, &:focus, &:hover': {
      color: '#141414',
    },
  },
  icon: {
    backgroundColor: theme.palette.common.black,
    '& path': {
      fill: 'white',
    },
  },
}))(TableSortLabel);

export const CustomTablePagination = ({ totalRaws, handleChangePage, handleChangeRowsPerPage, rowsPerPage, page }) => (
  <TablePagination
    rowsPerPageOptions={[10, 25, 50]}
    component="div"
    count={totalRaws}
    rowsPerPage={rowsPerPage}
    page={page}
    backIconButtonProps={{
      'aria-label': 'previous page',
    }}
    nextIconButtonProps={{
      'aria-label': 'next page',
    }}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
    labelDisplayedRows={({ from, to, count }) => `${from}-${to} з ${count}`}
    labelRowsPerPage="Рядків на сторінці:"
  />
);

const ArrowDrop = (props) => {
  const classes = useStyles();
  return (
    <div {...props.innerProps} className={`${classes.dropdown} ${classes.show}`}>
      <ArrowDropUpIcon
        viewBox="1 -9 24 24"
        className={`${classes.arrowFontSize}`}
        color={props.active && props.direction === 'asc' ? 'primary' : 'disabled'}
      />
      <ArrowDropDownIcon
        viewBox="1 9 24 24"
        className={`${classes.arrowFontSize}`}
        color={props.active && props.direction === 'desc' ? 'primary' : 'disabled'}
      />
    </div>
  );
};

export const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort, headCells } = props;
  const [hover, setHover] = useState(null);
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <StyledTableCell
            key={headCell.id}
            align={index === headCells.length - 1 ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {index !== headCells.length - 1 ? (
              <StyledTableSortLabel
                onMouseOver={() => {
                  setHover(headCell.id);
                }}
                onMouseOut={() => {
                  setHover(null);
                }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                IconComponent={
                  index !== headCells.length - 1
                    ? () => (
                        <ArrowDrop
                          orderBy={orderBy}
                          active={orderBy === headCell.id}
                          hover={hover}
                          propertyName={headCell.id}
                          direction={order}
                        />
                      )
                    : () => <></>
                }
              >
                <span style={{ flexGrow: 1 }}>{headCell.label}</span>
              </StyledTableSortLabel>
            ) : (
              headCell.label
            )}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

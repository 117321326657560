import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import StatementRecipient from './RegistrationFormsRecipient/StatementRecipient';
import DocumentRecipient from './RegistrationFormsRecipient/DocumentsLoadingRecipients';
import Statement from '../RegisterMain/RegistrationsForms/Statement';
import DeclarationTab from './RegistrationFormsRecipient/DeclarationTab';
// import Plan from '../RegisterMain/RegistrationsForms/PlanDistribution';
// import Documents from '../RegisterMain/RegistrationsForms/DocumentsLoading';
import {
  recipientByIdNew,
  recipientAction,
  saveRecipientAction,
  getRecipientDocuments,
  currentRecipientId,
  deleteRecipientDocument,
  saveRecipientDocument,
  currentApplicationId,
  clearRecipient,
  getRecipientsForSelect,
  recipientByIdEdit,
  recipientNais,
  registerRecipient,
} from '../../../redux/actions/recipient.action';
import { localityAction } from '../../../redux/actions/catalog.action';
import { clearCargo, cargoAction } from '../../../redux/actions/cargo.action';
import { createLoadingSelector, createErrorMessageSelector, clearErrors } from '../../../redux/actions/selectors';
import rules from '../../../vibe/components/utilities/MainUtils/rules';
import isEmpty from '../../../vibe/components/utilities/MainUtils/is-empty';
import { toggleAction } from '../../../redux/actions/toggle.action';
import DocumentsLoadingCargo from './RegistrationFormsRecipient/DocumentsLoadingCargo';
import { selectRecipientItem, selectRecipientDocuments } from '../../../redux/actions/recipient/recipient.selector';

const loadingSelector = createLoadingSelector(['CATALOG', 'LOCALITY', 'RECIPIENT_SAVE', 'RECIPIENT_DOCUMENTS_SAVE']);
const errorSelector = createErrorMessageSelector(['RECIPIENT_SAVE']);

class AddRecipient extends Component {
  constructor(props) {
    super(props);

    // this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      submitted: false,
      registerButton: false,
    };
  }

  componentDidMount() {
    this.props.clearErrors();
    if (this.getRouteSave()) {
      // this.props.clearRecipient();
      // this.props.clearCargo();
    }
    if (this.props.recipient.result === 1) this.props.toggleAction('3');

    if (this.getRouteMSPUEdit() || this.getRouteMSPUSave()) {
      this.props.clearRecipient();
    }
  }

  getRouteSave = () => {
    return this.props.location.pathname === '/create-recipient/save';
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    // const inputValue = value.toUpperCase().trim();
    const inputValue = value.toUpperCase();
    if (inputValue === '') {
      this.props.recipientAction(name, inputValue);
    } else {
      switch (name) {
        case 'p_surname':
        case 'p_name':
        case 'p_patronymic':
        case 'p_reprSurname':
        case 'p_reprFirstname':
        case 'p_reprPatronymic':
          rules.name.test(inputValue) && this.props.recipientAction(name, inputValue);
          break;
        case 'p_edrpou':
          rules.edrpou.test(value) && this.props.recipientAction(name, value);
          break;
        case 'p_orgname':
          rules.orgName.test(inputValue) && this.props.recipientAction(name, inputValue);
        case 'p_adress':
          rules.address.test(inputValue) && this.props.recipientAction(name, inputValue);
          break;
        case 'p_email':
          rules.email.test(value) && this.props.recipientAction(name, value);
          break;
        case 'p_reprOccupation':
          rules.occupation.test(inputValue) && this.props.recipientAction(name, inputValue);
          break;
        case 'p_reprLocality':
          rules.localization.test(inputValue) && this.props.recipientAction(name, inputValue);
          break;
        case 'p_agent_rnokpp':
          // eslint-disable-next-line no-unused-expressions
          rules.edrpou.test(value) && this.props.recipientAction(name, value);
          break;
        default:
          this.props.recipientAction(name, value);
      }
    }
  };

  handleSelectChange = (name) => (e, select) => {
    if (select) {
      const { value } = select;
      this.props.recipientAction(name, value);

      if (name === 'p_region') {
        this.props.localityAction(value);
      }

      const diplomacyCheck =
        Number(value) !== 2 && Number(value) !== 996 && Number(value) !== 997 && Number(value) !== 998;

      if (name === 'p_recipientType' && diplomacyCheck) {
        this.props.recipientAction('p_reprOccupation', '');
        this.props.recipientAction('p_reprSurname', '');
        this.props.recipientAction('p_reprFirstname', '');
        this.props.recipientAction('p_reprPatronymic', '');
        this.props.recipientAction('p_agent_rnokpp', '');
        this.props.recipientAction('p_agent_code', '');
        this.props.recipientAction('p_reprCountry', '');
        this.props.recipientAction('p_reprLocality', '');
      }
    }
  };

  addPhone = () => {
    const { p_phone_max } = this.props.recipient;
    if (p_phone_max < 2) this.props.recipientAction('p_phone_max', p_phone_max + 1);
  };

  removePhone = () => {
    // const { p_phone_max } = this.props.recipient;

    // if (p_phone_max === 2) {
    this.props.recipientAction('p_phone2', '');
    // }

    // if (p_phone_max > 1) this.props.recipientAction('p_phone_max', p_phone_max - 1);
  };

  saveRecipient = (e) => {
    e.preventDefault();
    this.setState({
      submitted: true,
    });

    const {
      p_edrpou,
      p_orgname,
      p_surname,
      p_name,
      p_patronymic,
      p_koatuu,
      p_phone,
      p_email,
      p_adress,
      // new parameters
      p_recipientType,
      p_reprSurname,
      p_reprFirstname,
      p_reprPatronymic,
      p_reprOccupation,
      p_agent_rnokpp,
      p_agent_code,
      p_reprCountry,
      p_reprLocality,
      p_phone2,
      editable,
      pl_got_olfcode,
      // new
      p_private,
    } = this.props.recipient;

    const data = {
      p_user_id: this.props.user.id,
      p_user_role: this.props.user.c_role,
      p_edrpou,
      p_orgname,
      p_surname,
      p_name,
      p_patronymic,
      p_koatuu,
      p_adress,
      p_phone,
      p_email,
      // new parameters
      p_rec_type: p_recipientType,
      p_ag_surname: p_reprSurname,
      p_ag_name: p_reprFirstname,
      p_ag_patr: p_reprPatronymic,
      p_position: p_reprOccupation,
      p_rnokpp: p_agent_rnokpp,
      p_ag_code: p_agent_code,
      p_country: p_reprCountry,
      p_reg_address: p_reprLocality,
      p_phone2: p_phone2 || '',
      p_editable: editable,
      p_got_olfcode: pl_got_olfcode,
      // private
      p_private: p_private === 'yes' ? 1 : 0,
    };

    this.props.saveRecipientAction(data);
  };

  getRoute = () => {
    return this.props.location.pathname === '/edit-recipient';
  };

  getRouteMSPUSave = () => {
    return this.props.location.pathname === '/save-recipient-mspu';
  };

  getRouteMSPUEdit = () => {
    return this.props.location.pathname === '/edit-recipient-mspu';
  };

  setRegisterButton = (val) => {
    this.setState({ registerButton: val });
  };

  render() {
    const { registerButton } = this.state;
    return (
      // <Card body>
      <div className="full-bleed">
        <Nav tabs>
          {(this.props.recipient.result === 0 || !this.props.recipient.hasOwnProperty('result')) && (
            <NavItem>
              <NavLink
                className={classnames({ active: this.props.toggle === '1' })}
                onClick={() => {
                  // this.toggle('1')
                  this.props.toggleAction('1');
                }}
              >
                <span>Отримувач</span>
              </NavLink>
            </NavItem>
          )}
          {/* <NavItem>
              <NavLink
                disabled={isEmpty(this.props.applicationSaveId)}
                className={classnames({ active: this.props.toggle === '2' })}
                onClick={() => {
                  this.props.toggleAction('2');
                }}
              >
              <span>Документи</span>
                
              </NavLink>
            </NavItem> */}
          {!this.getRoute() && !this.getRouteMSPUSave() && !this.getRouteMSPUEdit() && (
            <NavItem>
              <NavLink
                disabled={isEmpty(this.props.recipientSavedID)}
                className={classnames({ active: this.props.toggle === '3' })}
                onClick={() => {
                  // this.toggle('3')
                  this.props.toggleAction('3');
                }}
              >
                <span>Вантаж</span>
              </NavLink>
            </NavItem>
          )}
          {/* <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '4' })}
                onClick={() => {
                  this.toggle('4');
                }}
              >
                
                <span>План-розподіл</span>
              </NavLink>
            </NavItem> */}
          {/* {!this.getRoute() && !this.getRouteMSPUSave() && !this.getRouteMSPUEdit() && (
            <NavItem>
              <NavLink
                disabled={!this.props.cargo.i_donor}
                className={classnames({ active: this.props.toggle === '5' })}
                onClick={() => {
                  this.props.toggleAction('5');
                }}
              >
                <span>Документи</span>
              </NavLink>
            </NavItem>
          )} */}
          {!this.getRoute() && !this.getRouteMSPUSave() && !this.getRouteMSPUEdit() && (
            <NavItem>
              <NavLink
                disabled={!this.props.cargo.i_donor}
                className={classnames({ active: this.props.toggle === '6' })}
                onClick={() => {
                  this.props.toggleAction('6');
                }}
              >
                <span>Декларація</span>
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={this.props.toggle}>
          <TabPane tabId="1">
            {/* <Row> */}
            {/* <Col sm="12"> */}
            <StatementRecipient
              submitted={this.state.submitted}
              handleChange={this.handleChange}
              handleSelectChange={this.handleSelectChange}
              saveRecipient={this.saveRecipient}
              recipient={this.props.recipient}
              catalog={this.props.catalog}
              loading={this.props.loading}
              error={this.props.errMessage}
              user={this.props.user}
              currentRecipientId={this.props.currentRecipientId}
              currentApplicationId={this.props.currentApplicationId}
              successText={this.props.successText}
              toggleAction={this.props.toggleAction}
              getRoute={this.getRoute}
              recipientByIdNew={this.props.recipientByIdNew}
              cargoAction={this.props.cargoAction}
              localityAction={this.props.localityAction}
              selectRecipient={this.props.selectRecipient}
              getRecipientsForSelect={this.props.getRecipientsForSelect}
              cargo={this.props.cargo}
              recipientByIdEdit={this.props.recipientByIdEdit}
              clearRecipient={this.props.clearRecipient}
              addPhone={this.addPhone}
              removePhone={this.removePhone}
              recipientNais={this.props.recipientNais}
              recipientId={this.props.recipientSavedID}
              blockMoveOn={isEmpty(this.props.recipientSavedID)}
              recipientAction={this.props.recipientAction}
            />
            {/* </Col> */}
            {/* </Row> */}
          </TabPane>
          {/* <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <DocumentRecipient
                    documents={this.props.documents}
                    recipient={this.props.recipient}
                    getRecipientDocuments={this.props.getRecipientDocuments}
                    saveRecipientDocument={this.props.saveRecipientDocument}
                    deleteRecipientDocument={this.props.deleteRecipientDocument}
                    loading={this.props.loading}
                    error={this.props.errMessage}
                    user={this.props.user}
                    currentRecipientId={this.props.currentRecipientId}
                    applicationId={this.props.applicationId}
                    catalog={this.props.catalog}
                  />
                </Col>
              </Row>
            </TabPane> */}
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <Statement registerButton={registerButton} setRegisterButton={this.setRegisterButton} />
              </Col>
            </Row>
          </TabPane>
          {/* <TabPane tabId="4">
              <Row>
                <Col sm="12">
                  <Plan/>
                </Col>
              </Row>
            </TabPane> */}
          <TabPane tabId="5">
            <Row>
              <Col sm="12">
                {/* <Documents /> */}
                <DocumentsLoadingCargo catalog={this.props.catalog} toggleAction={this.props.toggleAction} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="6">
            <Row>
              <Col sm="12">
                {/* <Documents /> */}
                <DeclarationTab />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
      // </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // recipient: state.recipient.recipient,
    recipient: selectRecipientItem(state),
    recipientSavedID: state.recipient.tabsFlag.recipietnSaveID,
    successText: state.recipient.successText,
    selectRecipient: state.recipient.selectRecipient,
    // documents: state.recipient.documents,
    documents: selectRecipientDocuments(state),
    catalog: state.catalogs,
    isFatching: loadingSelector(state),
    error: errorSelector(state),
    errMessage: state.error,
    loading: state.isFatching,
    user: state.auth.user,
    toggle: state.toggle,
    applicationId: state.recipient.tabsFlag.applicationSaveId,
    cargo: state.cargo.cargoApplication,
    cargoAppSaveId: state.recipient.tabsFlag.cargoAppSaveId,
    applicationSaveId: state.recipient.tabsFlag.applicationSaveId,
    registered: state.recipient.recipientState.registered,
  };
};

AddRecipient.propTypes = {
  recipient: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired,
  saveRecipientAction: PropTypes.func.isRequired,
  localityAction: PropTypes.func.isRequired,
  recipientAction: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  currentRecipientId: PropTypes.func.isRequired,
  currentApplicationId: PropTypes.func.isRequired,
  errMessage: PropTypes.object,
  loading: PropTypes.object,
  user: PropTypes.object,
  successText: PropTypes.string,
  toggle: PropTypes.string,
  registered: PropTypes.bool,
};

export default connect(mapStateToProps, {
  registerRecipient,
  recipientAction,
  localityAction,
  saveRecipientAction,
  clearErrors,
  getRecipientDocuments,
  currentRecipientId,
  saveRecipientDocument,
  deleteRecipientDocument,
  toggleAction,
  currentApplicationId,
  clearRecipient,
  clearCargo,
  recipientByIdNew,
  recipientByIdEdit,
  getRecipientsForSelect,
  cargoAction,
  recipientNais,
})(AddRecipient);

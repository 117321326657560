import {
    SET_CHECK_ROWS,
    CLEAR_CHECK_ROWS,
    FETCH_CHECK_ROWS_START,
    FETCH_CHECK_ROWS_SUCCESS,
    FETCH_CHECK_ROWS_FALIURE,
    SET_CHECK_ROW_COMMENT,
    SET_CHECK_ROW_ACT,
} from './action-types';

import { getAppsForCheck } from '../../api/api';

export const setCheckRows = (data) => ({
    type: SET_CHECK_ROWS,
    payload: data,
});

export const clearCheckRows = () => ({
    type: CLEAR_CHECK_ROWS,
});

export const setRowComment = (index, text) => ({ type: SET_CHECK_ROW_COMMENT, payload: { index, text } });

export const setRowAct = (index, value) => ({ type: SET_CHECK_ROW_ACT, payload: { index, value } });

const fetchRowsStart = () => ({
    type: FETCH_CHECK_ROWS_START,
});

const fetchRowsSussess = (rows) => ({
    type: FETCH_CHECK_ROWS_SUCCESS,
    payload: rows,
});

const fetchRowsFaliure = (error) => ({
    type: FETCH_CHECK_ROWS_FALIURE,
    payload: error,
});

export const fetchRows = () => async (dispatch) => {
    dispatch(fetchRowsStart());
    try {
        const response = await getAppsForCheck();
        dispatch(fetchRowsSussess(response.data));
    } catch (error) {
        dispatch(fetchRowsFaliure(error));
    }

};

import * as Excel from 'exceljs/dist/exceljs.min';

const makeHeader1 = (worksheet, header, text, attachNum) => {
  worksheet.mergeCells('F1:G1');
  const cCell1 = worksheet.getCell('F1');
  cCell1.value = `Додаток ${attachNum}`;
  cCell1.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('F2:G2');
  const cCell2 = worksheet.getCell('F2');
  cCell2.value = header?.line_1;
  cCell2.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('F3:G3');
  const cCell3 = worksheet.getCell('F3');
  cCell3.value = header?.line_2;
  cCell3.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('F4:G4');
  const cCell4 = worksheet.getCell('F4');
  cCell4.value = '№ ______  ___________________';
  cCell4.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('A6:G6');
  const customCell1 = worksheet.getCell('A6');
  customCell1.value = 'ПЕРЕЛІК';
  customCell1.font = {
    name: 'Times New Roman',
    size: 12,
    bold: true,
  };
  customCell1.alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.mergeCells('A7:G7');
  const customCell2 = worksheet.getCell('A7');
  customCell2.font = {
    name: 'Times New Roman',
    size: 12,
    bold: true,
  };
  customCell2.alignment = { vertical: 'middle', horizontal: 'center' };
  customCell2.value = text;
};

const makeHeader2 = (worksheet, header, text, attachNum) => {
  worksheet.mergeCells('I1:J1');
  const cCell1 = worksheet.getCell('I1');
  cCell1.value = `Додаток ${attachNum}`;
  cCell1.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('I2:J2');
  const cCell2 = worksheet.getCell('I2');
  cCell2.value = header?.line_1;
  cCell2.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('I3:J3');
  const cCell3 = worksheet.getCell('I3');
  cCell3.value = header?.line_2;
  cCell3.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('I4:J4');
  const cCell4 = worksheet.getCell('I4');
  cCell4.value = '№ ______  ___________________';
  cCell4.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('A6:J6');
  const customCell1 = worksheet.getCell('A6');
  customCell1.value = 'ПЕРЕЛІК';
  customCell1.font = {
    name: 'Times New Roman',
    size: 12,
    bold: true,
  };
  customCell1.alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.mergeCells('A7:J7');
  const customCell2 = worksheet.getCell('A7');
  customCell2.font = {
    name: 'Times New Roman',
    size: 12,
    bold: true,
  };
  customCell2.alignment = { vertical: 'middle', horizontal: 'center' };
  customCell2.value = text;
};

const makeHeader3 = (worksheet, header, text, attachNum) => {
  worksheet.mergeCells('L1:M1');
  const cCell1 = worksheet.getCell('L1');
  cCell1.value = `Додаток ${attachNum}`;
  cCell1.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('L2:M2');
  const cCell2 = worksheet.getCell('L2');
  cCell2.value = header?.line_1;
  cCell2.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('L3:M3');
  const cCell3 = worksheet.getCell('L3');
  cCell3.value = header?.line_2;
  cCell3.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('L4:M4');
  const cCell4 = worksheet.getCell('L4');
  cCell4.value = '№ ______  ___________________';
  cCell4.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('A6:M6');
  const customCell1 = worksheet.getCell('A6');
  customCell1.value = 'ПЕРЕЛІК';
  customCell1.font = {
    name: 'Times New Roman',
    size: 12,
    bold: true,
  };
  customCell1.alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.mergeCells('A7:M7');
  const customCell2 = worksheet.getCell('A7');
  customCell2.font = {
    name: 'Times New Roman',
    size: 12,
    bold: true,
  };
  customCell2.alignment = { vertical: 'middle', horizontal: 'center' };
  customCell2.value = text;
};

const addTitles1 = (worksheet) => {
  // "code": 31,
  const col_names = [
    'Номер в ЄР',
    'Назва отримувача',
    'Код ЄДРПОУ',
    'Місцезнаходження',
    'Телефон',
    'ПІБ керівника',
    '№ та дата наказу про внесення до ЄР/зміни в ЄР',
  ];

  worksheet.getRow(8).values = [...col_names];
  const titles = worksheet.getRow(8);
  titles.eachCell((cell) => {
    // eslint-disable-next-line no-param-reassign
    cell.border = {
      top: { style: 'thin', color: { argb: '00000000' } },
      left: { style: 'thin', color: { argb: '00000000' } },
      bottom: { style: 'thin', color: { argb: '00000000' } },
      right: { style: 'thin', color: { argb: '00000000' } },
    };
    // eslint-disable-next-line no-param-reassign
    cell.font = {
      name: 'Times New Roman',
      size: 10,
      bold: true,
    };
    // eslint-disable-next-line no-param-reassign
    cell.alignment = {
      vertical: 'top',
      horizontal: 'center',
      wrapText: true,
    };
  });
};

const addTitles2 = (worksheet) => {
  // "code": 41,
  const col_names = [
    'Номер в ЄР',
    'Назва отримувача',
    'Код ЄДРПОУ',
    'Місцезнаходження',
    'Телефон',
    'ПІБ керівника',
    '№ та дата наказу про внесення до ЄР/зміни в ЄР',
  ];

  worksheet.getRow(8).values = [...col_names];
  const titles = worksheet.getRow(8);
  titles.eachCell((cell) => {
    // eslint-disable-next-line no-param-reassign
    cell.border = {
      top: { style: 'thin', color: { argb: '00000000' } },
      left: { style: 'thin', color: { argb: '00000000' } },
      bottom: { style: 'thin', color: { argb: '00000000' } },
      right: { style: 'thin', color: { argb: '00000000' } },
    };
    // eslint-disable-next-line no-param-reassign
    cell.font = {
      name: 'Times New Roman',
      size: 10,
      bold: true,
    };
    // eslint-disable-next-line no-param-reassign
    cell.alignment = {
      vertical: 'top',
      horizontal: 'center',
      wrapText: true,
    };
  });
};

const addTitles3 = (worksheet) => {
  // "code": 61,
  const col_names = [
    '№ з/п',
    'Країна',
    'Донор',
    'Отримувач',
    'Адреса',
    'ЄДРПОУ',
    '№ в ЄР',
    'Вантаж',
    'Вага (кг)',
    'Супровідні документи',
  ];

  worksheet.getRow(8).values = [...col_names];
  const titles = worksheet.getRow(8);
  titles.eachCell((cell) => {
    // eslint-disable-next-line no-param-reassign
    cell.border = {
      top: { style: 'thin', color: { argb: '00000000' } },
      left: { style: 'thin', color: { argb: '00000000' } },
      bottom: { style: 'thin', color: { argb: '00000000' } },
      right: { style: 'thin', color: { argb: '00000000' } },
    };
    // eslint-disable-next-line no-param-reassign
    cell.font = {
      name: 'Times New Roman',
      size: 10,
      bold: true,
    };
    // eslint-disable-next-line no-param-reassign
    cell.alignment = {
      vertical: 'top',
      horizontal: 'center',
      wrapText: true,
    };
  });
};

const addTitles4 = (worksheet) => {
  // "code": 71,
  const col_names = [
    '№ з/п',
    'Країна',
    'Донор',
    'Отримувач',
    'Адреса',
    'ЄДРПОУ',
    '№ в ЄР',
    'Вантаж',
    'Отримувач, якому здійснюється пере-адресування',
    'Адреса',
    'ЄДРПОУ',
    '№ в ЄР',
    'Супровідні документи',
  ];

  worksheet.getRow(8).values = [...col_names];
  const titles = worksheet.getRow(8);
  titles.eachCell((cell) => {
    // eslint-disable-next-line no-param-reassign
    cell.border = {
      top: { style: 'thin', color: { argb: '00000000' } },
      left: { style: 'thin', color: { argb: '00000000' } },
      bottom: { style: 'thin', color: { argb: '00000000' } },
      right: { style: 'thin', color: { argb: '00000000' } },
    };
    // eslint-disable-next-line no-param-reassign
    cell.font = {
      name: 'Times New Roman',
      size: 10,
      bold: true,
    };
    // eslint-disable-next-line no-param-reassign
    cell.alignment = {
      vertical: 'top',
      horizontal: 'center',
      wrapText: true,
    };
  });
};

const addData1 = (worksheet, data) => {
  const col_keys = [
    // "code": 31,
    'n_number',
    'c_orgname',
    'n_edrpou',
    'adress',
    'n_phone',
    'head_name',
    'c_order',
  ];

  // eslint-disable-next-line no-param-reassign
  worksheet.columns = [
    // "code": 31,
    { key: 'n_number', width: 7 },
    { key: 'c_orgname', width: 20 },
    { key: 'n_edrpou', width: 10 },
    { key: 'adress', width: 20 },
    { key: 'n_phone', width: 12 },
    { key: 'head_name', width: 14 },
    { key: 'c_order', width: 20 },
  ];

  worksheet.addRows([...data]);

  // eslint-disable-next-line no-restricted-syntax
  for (const key of col_keys) {
    const col = worksheet.getColumn(key);
    col.eachCell(function (cell, rowNumber) {
      if (rowNumber >= 9) {
        // eslint-disable-next-line no-param-reassign
        cell.border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } },
        };
        // eslint-disable-next-line no-param-reassign
        cell.font = {
          name: 'Times New Roman',
          color: { argb: '00000000' },
          size: 10,
        };
        // eslint-disable-next-line no-param-reassign
        cell.alignment = {
          vertical: 'top',
          horizontal: 'left',
          wrapText: true,
        };
        // eslint-disable-next-line no-param-reassign
        cell.numFmt = '0';
      }
    });
  }
};

const addData2 = (worksheet, data) => {
  const col_keys = [
    // "code": 41,
    'n_number',
    'c_orgname',
    'n_edrpou',
    'adress',
    'n_phone',
    'head_name',
    'c_order',
  ];

  // eslint-disable-next-line no-param-reassign
  worksheet.columns = [
    // "code": 41,
    { key: 'n_number', width: 7 },
    { key: 'c_orgname', width: 20 },
    { key: 'n_edrpou', width: 10 },
    { key: 'adress', width: 20 },
    { key: 'n_phone', width: 12 },
    { key: 'head_name', width: 14 },
    { key: 'c_order', width: 20 },
  ];

  worksheet.addRows([...data]);

  // eslint-disable-next-line no-restricted-syntax
  for (const key of col_keys) {
    const col = worksheet.getColumn(key);
    col.eachCell(function (cell, rowNumber) {
      if (rowNumber >= 9) {
        // eslint-disable-next-line no-param-reassign
        cell.border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } },
        };
        // eslint-disable-next-line no-param-reassign
        cell.font = {
          name: 'Times New Roman',
          color: { argb: '00000000' },
          size: 10,
        };
        // eslint-disable-next-line no-param-reassign
        cell.alignment = {
          vertical: 'top',
          horizontal: 'left',
          wrapText: true,
        };
        // eslint-disable-next-line no-param-reassign
        cell.numFmt = '0';
      }
    });
  }
};

const addData3 = (worksheet, data) => {
  const col_keys = [
    // "code": 61,
    'rn',
    'country',
    'donor',
    'c_orgname',
    'adress',
    'n_edrpou',
    'n_number',
    // 'cargo_name',
    'c_description',
    'n_weight',
    'docs',
  ];

  // eslint-disable-next-line no-param-reassign
  worksheet.columns = [
    // "code": 61,
    { key: 'rn', width: 5 },
    { key: 'country', width: 8 },
    { key: 'donor', width: 8 },
    { key: 'c_orgname', width: 12 },
    { key: 'adress', width: 16 },
    { key: 'n_edrpou', width: 10 },
    { key: 'n_number', width: 10 },
    { key: 'c_description', width: 25 },
    // { key: 'cargo_name', width: 25 },
    { key: 'n_weight', width: 6 },
    { key: 'docs', width: 25 },
  ];

  worksheet.addRows([...data]);

  // eslint-disable-next-line no-restricted-syntax
  for (const key of col_keys) {
    const col = worksheet.getColumn(key);
    col.eachCell(function (cell, rowNumber) {
      if (rowNumber >= 9) {
        // eslint-disable-next-line no-param-reassign
        cell.border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } },
        };
        // eslint-disable-next-line no-param-reassign
        cell.font = {
          name: 'Times New Roman',
          color: { argb: '00000000' },
          size: 10,
        };
        // eslint-disable-next-line no-param-reassign
        cell.alignment = {
          vertical: 'top',
          horizontal: 'left',
          wrapText: true,
        };
        // eslint-disable-next-line no-param-reassign
        cell.numFmt = '0';
      }
    });
  }
};

const getLines = (data) => {
  const data71 = data.filter((item) => item.code === 71);
  // get app ids
  const appIds = Array.from(new Set(data71.map((item) => item['appl_recip_id'])));

  const lines = [];

  for (const id of appIds) {
    const groupe = data.reduce((acc, item) => {
      if (item['appl_recip_id'] === id) {
        return [...acc, item];
      }

      return [...acc];
    }, []);

    // find recipient
    const recipient = groupe.find((item) => !item.rn);

    for (const item of groupe) {
      if (item['rn']) {
        lines.push({
          ...item,
          n_number_2: recipient.n_number,
          c_orgname_2: recipient.c_orgname,
          n_edrpou_2: recipient.n_edrpou,
          adress_2: recipient.adress,
        });
      }
    }
  }

  return lines;
};

const addData4 = (worksheet, data) => {
  const col_keys = [
    // "code": 71,
    'rn',
    'country',
    'donor',
    'c_orgname',
    'adress',
    'n_edrpou',
    'n_number',
    'c_description',
    // 'cargo_name',
    'c_orgname_2',
    'adress_2',
    'n_edrpou_2',
    'n_number_2',
    'docs',
  ];

  // eslint-disable-next-line no-param-reassign
  worksheet.columns = [
    // "code": 71,
    { key: 'rn', width: 5 },
    { key: 'country', width: 8 },
    { key: 'donor', width: 8 },
    { key: 'c_orgname', width: 12 },
    { key: 'adress', width: 16 },
    { key: 'n_edrpou', width: 10 },
    { key: 'n_number', width: 10 },
    { key: 'c_description', width: 25 },
    // { key: 'cargo_name', width: 25 },
    //
    { key: 'c_orgname_2', width: 20 },
    { key: 'adress_2', width: 20 },
    { key: 'n_edrpou_2', width: 10 },
    { key: 'n_number_2', width: 10 },
    { key: 'docs', width: 25 },
  ];

  worksheet.addRows([...data]);

  // eslint-disable-next-line no-restricted-syntax
  for (const key of col_keys) {
    const col = worksheet.getColumn(key);
    col.eachCell(function (cell, rowNumber) {
      if (rowNumber >= 9) {
        // eslint-disable-next-line no-param-reassign
        cell.border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } },
        };
        // eslint-disable-next-line no-param-reassign
        cell.font = {
          name: 'Times New Roman',
          color: { argb: '00000000' },
          size: 10,
        };
        // eslint-disable-next-line no-param-reassign
        cell.alignment = {
          vertical: 'top',
          horizontal: 'left',
          wrapText: true,
        };
        // eslint-disable-next-line no-param-reassign
        cell.numFmt = '0';
      }
    });
  }
};

const makeHeader_recipients = (worksheet, header, text, attachNum) => {
  worksheet.mergeCells('F1:G1');
  const cCell1 = worksheet.getCell('F1');
  cCell1.value = `Додаток ${attachNum}`;
  cCell1.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('F2:G2');
  const cCell2 = worksheet.getCell('F2');
  cCell2.value = header?.line_1;
  cCell2.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('F3:G3');
  const cCell3 = worksheet.getCell('F3');
  cCell3.value = header?.line_2;
  cCell3.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('F4:G4');
  const cCell4 = worksheet.getCell('F4');
  cCell4.value = '№ ______  ___________________';
  cCell4.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('A6:G6');
  const customCell1 = worksheet.getCell('A6');
  customCell1.value = 'ПЕРЕЛІК';
  customCell1.font = {
    name: 'Times New Roman',
    size: 12,
    bold: true,
  };
  customCell1.alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.mergeCells('A7:G7');
  const customCell2 = worksheet.getCell('A7');
  customCell2.font = {
    name: 'Times New Roman',
    size: 12,
    bold: true,
  };
  customCell2.alignment = { vertical: 'middle', horizontal: 'center' };
  customCell2.value = text;
};

const makeHeader_cargos = (worksheet, header, text, attachNum) => {
  worksheet.mergeCells('I1:J1');
  const cCell1 = worksheet.getCell('I1');
  cCell1.value = `Додаток ${attachNum}`;
  cCell1.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('I2:J2');
  const cCell2 = worksheet.getCell('I2');
  cCell2.value = header?.line_1;
  cCell2.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('I3:J3');
  const cCell3 = worksheet.getCell('I3');
  cCell3.value = header?.line_2;
  cCell3.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('I4:J4');
  const cCell4 = worksheet.getCell('I4');
  cCell4.value = '№ ______  ___________________';
  cCell4.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('A6:J6');
  const customCell1 = worksheet.getCell('A6');
  customCell1.value = 'ПЕРЕЛІК';
  customCell1.font = {
    name: 'Times New Roman',
    size: 12,
    bold: true,
  };
  customCell1.alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.mergeCells('A7:J7');
  const customCell2 = worksheet.getCell('A7');
  customCell2.font = {
    name: 'Times New Roman',
    size: 12,
    bold: true,
  };
  customCell2.alignment = { vertical: 'middle', horizontal: 'center' };
  customCell2.value = text;
};

const makeHeader_services = (worksheet, header, text, attachNum) => {
  worksheet.mergeCells('I1:J1');
  const cCell1 = worksheet.getCell('I1');
  cCell1.value = `Додаток ${attachNum}`;
  cCell1.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('I2:J2');
  const cCell2 = worksheet.getCell('I2');
  cCell2.value = header?.line_1;
  cCell2.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('I3:J3');
  const cCell3 = worksheet.getCell('I3');
  cCell3.value = header?.line_2;
  cCell3.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('I4:J4');
  const cCell4 = worksheet.getCell('I4');
  cCell4.value = '№ ______  ___________________';
  cCell4.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('A6:I6');
  const customCell1 = worksheet.getCell('A6');
  customCell1.value = 'ПЕРЕЛІК';
  customCell1.font = {
    name: 'Times New Roman',
    size: 12,
    bold: true,
  };
  customCell1.alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.mergeCells('A7:I7');
  const customCell2 = worksheet.getCell('A7');
  customCell2.font = {
    name: 'Times New Roman',
    size: 12,
    bold: true,
  };
  customCell2.alignment = { vertical: 'middle', horizontal: 'center' };
  customCell2.value = text;
};
const makeHeader_funds = (worksheet, header, text, attachNum) => {
  worksheet.mergeCells('I1:J1');
  const cCell1 = worksheet.getCell('I1');
  cCell1.value = `Додаток ${attachNum}`;
  cCell1.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('I2:J2');
  const cCell2 = worksheet.getCell('I2');
  cCell2.value = header?.line_1;
  cCell2.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('I3:J3');
  const cCell3 = worksheet.getCell('I3');
  cCell3.value = header?.line_2;
  cCell3.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('I4:J4');
  const cCell4 = worksheet.getCell('I4');
  cCell4.value = '№ ______  ___________________';
  cCell4.font = {
    name: 'Times New Roman',
    color: { argb: '00000000' },
    size: 10,
  };

  worksheet.mergeCells('A6:I6');
  const customCell1 = worksheet.getCell('A6');
  customCell1.value = 'ПЕРЕЛІК';
  customCell1.font = {
    name: 'Times New Roman',
    size: 12,
    bold: true,
  };
  customCell1.alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.mergeCells('A7:I7');
  const customCell2 = worksheet.getCell('A7');
  customCell2.font = {
    name: 'Times New Roman',
    size: 12,
    bold: true,
  };
  customCell2.alignment = { vertical: 'middle', horizontal: 'center' };
  customCell2.value = text;
};

const addTitles_recipients = (worksheet) => {
  const col_names = [
    'Номер в ЄР',
    'Назва отримувача',
    'Код ЄДРПОУ',
    'Місцезнаходження',
    'Телефон',
    'П. І. Б. керівника',
    '№ та дата наказу про внесення до ЄР/зміни в ЄР',
  ];

  worksheet.getRow(8).values = [...col_names];
  const titles = worksheet.getRow(8);
  titles.eachCell((cell) => {
    // eslint-disable-next-line no-param-reassign
    cell.border = {
      top: { style: 'thin', color: { argb: '00000000' } },
      left: { style: 'thin', color: { argb: '00000000' } },
      bottom: { style: 'thin', color: { argb: '00000000' } },
      right: { style: 'thin', color: { argb: '00000000' } },
    };
    // eslint-disable-next-line no-param-reassign
    cell.font = {
      name: 'Times New Roman',
      size: 10,
      bold: true,
    };
    // eslint-disable-next-line no-param-reassign
    cell.alignment = {
      vertical: 'top',
      horizontal: 'center',
      wrapText: true,
    };
  });
};

const addTitles_cargos = (worksheet) => {
  // cargo
  const col_names = [
    '№ з/п',
    'Країна',
    'Донор',
    'Отримувач',
    'Адреса',
    'ЄДРПОУ',
    '№ в ЄР',
    'Вантаж',
    'Вага (кг)',
    'Супровідні документи',
  ];

  worksheet.getRow(8).values = [...col_names];
  const titles = worksheet.getRow(8);
  titles.eachCell((cell) => {
    // eslint-disable-next-line no-param-reassign
    cell.border = {
      top: { style: 'thin', color: { argb: '00000000' } },
      left: { style: 'thin', color: { argb: '00000000' } },
      bottom: { style: 'thin', color: { argb: '00000000' } },
      right: { style: 'thin', color: { argb: '00000000' } },
    };
    // eslint-disable-next-line no-param-reassign
    cell.font = {
      name: 'Times New Roman',
      size: 10,
      bold: true,
    };
    // eslint-disable-next-line no-param-reassign
    cell.alignment = {
      vertical: 'top',
      horizontal: 'center',
      wrapText: true,
    };
  });
};
const addTitles_services = (worksheet) => {
  // services
  const col_names = [
    '№ з/п',
    'Країна',
    'Донор',
    'Отримувач',
    'Адреса',
    'ЄДРПОУ',
    '№ в ЄР',
    'Послуга',
    'Супровідні документи',
  ];

  worksheet.getRow(8).values = [...col_names];
  const titles = worksheet.getRow(8);
  titles.eachCell((cell) => {
    // eslint-disable-next-line no-param-reassign
    cell.border = {
      top: { style: 'thin', color: { argb: '00000000' } },
      left: { style: 'thin', color: { argb: '00000000' } },
      bottom: { style: 'thin', color: { argb: '00000000' } },
      right: { style: 'thin', color: { argb: '00000000' } },
    };
    // eslint-disable-next-line no-param-reassign
    cell.font = {
      name: 'Times New Roman',
      size: 10,
      bold: true,
    };
    // eslint-disable-next-line no-param-reassign
    cell.alignment = {
      vertical: 'top',
      horizontal: 'center',
      wrapText: true,
    };
  });
};
const addTitles_funds = (worksheet) => {
  // funds
  const col_names = [
    '№ з/п',
    'Країна',
    'Донор',
    'Отримувач',
    'Адреса',
    'ЄДРПОУ',
    '№ в ЄР',
    'Сума коштів, грн.',
    'Супровідні документи',
  ];

  worksheet.getRow(8).values = [...col_names];
  const titles = worksheet.getRow(8);
  titles.eachCell((cell) => {
    // eslint-disable-next-line no-param-reassign
    cell.border = {
      top: { style: 'thin', color: { argb: '00000000' } },
      left: { style: 'thin', color: { argb: '00000000' } },
      bottom: { style: 'thin', color: { argb: '00000000' } },
      right: { style: 'thin', color: { argb: '00000000' } },
    };
    // eslint-disable-next-line no-param-reassign
    cell.font = {
      name: 'Times New Roman',
      size: 10,
      bold: true,
    };
    // eslint-disable-next-line no-param-reassign
    cell.alignment = {
      vertical: 'top',
      horizontal: 'center',
      wrapText: true,
    };
  });
};

const addData_recipients = (worksheet, data) => {
  // recipients
  const col_keys = ['n_number', 'c_orgname', 'n_edrpou', 'adress', 'n_phone', 'head_name', 'c_order'];

  // eslint-disable-next-line no-param-reassign
  worksheet.columns = [
    { key: 'n_number', width: 7 },
    { key: 'c_orgname', width: 20 },
    { key: 'n_edrpou', width: 10 },
    { key: 'adress', width: 20 },
    { key: 'n_phone', width: 12 },
    { key: 'head_name', width: 14 },
    { key: 'c_order', width: 20 },
  ];

  worksheet.addRows([...data]);

  // eslint-disable-next-line no-restricted-syntax
  for (const key of col_keys) {
    const col = worksheet.getColumn(key);
    col.eachCell(function (cell, rowNumber) {
      if (rowNumber >= 9) {
        // eslint-disable-next-line no-param-reassign
        cell.border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } },
        };
        // eslint-disable-next-line no-param-reassign
        cell.font = {
          name: 'Times New Roman',
          color: { argb: '00000000' },
          size: 10,
        };
        // eslint-disable-next-line no-param-reassign
        cell.alignment = {
          vertical: 'top',
          horizontal: 'left',
          wrapText: true,
        };
        // eslint-disable-next-line no-param-reassign
        cell.numFmt = '0';
      }
    });
  }
};
const addData_cargos = (worksheet, data) => {
  // cargo
  const col_keys = [
    'rn',
    'country',
    'donor',
    'c_orgname',
    'adress',
    'n_edrpou',
    'n_number',
    'c_description',
    // 'cargo_name',
    'n_weight',
    'docs',
  ];

  // eslint-disable-next-line no-param-reassign
  worksheet.columns = [
    { key: 'rn', width: 5 },
    { key: 'country', width: 8 },
    { key: 'donor', width: 8 },
    { key: 'c_orgname', width: 12 },
    { key: 'adress', width: 16 },
    { key: 'n_edrpou', width: 10 },
    { key: 'n_number', width: 10 },
    { key: 'c_description', width: 25 },
    // { key: 'cargo_name', width: 25 },
    { key: 'n_weight', width: 6 },
    { key: 'docs', width: 25 },
  ];

  worksheet.addRows([...data]);

  // eslint-disable-next-line no-restricted-syntax
  for (const key of col_keys) {
    const col = worksheet.getColumn(key);
    col.eachCell(function (cell, rowNumber) {
      if (rowNumber >= 9) {
        // eslint-disable-next-line no-param-reassign
        cell.border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } },
        };
        // eslint-disable-next-line no-param-reassign
        cell.font = {
          name: 'Times New Roman',
          color: { argb: '00000000' },
          size: 10,
        };
        // eslint-disable-next-line no-param-reassign
        cell.alignment = {
          vertical: 'top',
          horizontal: 'left',
          wrapText: true,
        };
      }
    });
  }
};
const addData_services = (worksheet, data) => {
  // services
  const col_keys = ['rn', 'country', 'donor', 'c_orgname', 'adress', 'n_edrpou', 'n_number', 'c_description', 'docs'];

  // eslint-disable-next-line no-param-reassign
  worksheet.columns = [
    { key: 'rn', width: 5 },
    { key: 'country', width: 8 },
    { key: 'donor', width: 8 },
    { key: 'c_orgname', width: 12 },
    { key: 'adress', width: 16 },
    { key: 'n_edrpou', width: 10 },
    { key: 'n_number', width: 10 },
    { key: 'c_description', width: 25 },
    { key: 'docs', width: 25 },
  ];

  worksheet.addRows([...data]);

  // eslint-disable-next-line no-restricted-syntax
  for (const key of col_keys) {
    const col = worksheet.getColumn(key);
    col.eachCell(function (cell, rowNumber) {
      if (rowNumber >= 9) {
        // eslint-disable-next-line no-param-reassign
        cell.border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } },
        };
        // eslint-disable-next-line no-param-reassign
        cell.font = {
          name: 'Times New Roman',
          color: { argb: '00000000' },
          size: 10,
        };
        // eslint-disable-next-line no-param-reassign
        cell.alignment = {
          vertical: 'top',
          horizontal: 'left',
          wrapText: true,
        };
      }
    });
  }
};
const addData_funds = (worksheet, data) => {
  // funds
  const col_keys = [
    'rn',
    'country',
    'donor',
    'c_orgname',
    'adress',
    'n_edrpou',
    'n_number',
    'c_description',
    // 'n_count',
    'docs',
  ];

  // eslint-disable-next-line no-param-reassign
  worksheet.columns = [
    { key: 'rn', width: 5 },
    { key: 'country', width: 8 },
    { key: 'donor', width: 8 },
    { key: 'c_orgname', width: 12 },
    { key: 'adress', width: 16 },
    { key: 'n_edrpou', width: 10 },
    { key: 'n_number', width: 10 },
    { key: 'c_description', width: 25 },
    // { key: 'n_count', width: 25 },
    { key: 'docs', width: 25 },
  ];

  worksheet.addRows([...data]);

  // eslint-disable-next-line no-restricted-syntax
  for (const key of col_keys) {
    const col = worksheet.getColumn(key);
    col.eachCell(function (cell, rowNumber) {
      if (rowNumber >= 9) {
        // eslint-disable-next-line no-param-reassign
        cell.border = {
          top: { style: 'thin', color: { argb: '00000000' } },
          left: { style: 'thin', color: { argb: '00000000' } },
          bottom: { style: 'thin', color: { argb: '00000000' } },
          right: { style: 'thin', color: { argb: '00000000' } },
        };
        // eslint-disable-next-line no-param-reassign
        cell.font = {
          name: 'Times New Roman',
          color: { argb: '00000000' },
          size: 10,
        };
        // eslint-disable-next-line no-param-reassign
        cell.alignment = {
          vertical: 'top',
          horizontal: 'left',
          wrapText: true,
        };
      }
    });
  }
};

const exportExcel = async (header, data) => {
  const workbook = new Excel.Workbook();
  const recipients = data.filter((item) => item.code === 11);
  // 51 Про визнання вантажів гуманітарною допомогою
  // 52 Про визнання коштів гуманітарною допомогою
  // 53 Про визнання послуг гуманітарною допомогою
  const cargos = data.filter((item) => item.code === 51);
  const funds = data.filter((item) => item.code === 52);
  const services = data.filter((item) => item.code === 53);
  const er = data.filter((item) => item.code === 31);
  const exclude = data.filter((item) => item.code === 41);
  const ch_cargo = data.filter((item) => item.code === 61);
  const ch_recipient = data.filter((item) => item.code === 71);

  if (data.length === 0) {
    workbook.addWorksheet('Аркуш 1', {
      pageSetup: { paperSize: 9, orientation: 'landscape' },
    });
  }

  if (recipients.length > 0) {
    // Отримувачі
    workbook.addWorksheet('Отримувачі', {
      pageSetup: { paperSize: 9, orientation: 'landscape' },
    });
    const worksheet0 = workbook.getWorksheet('Отримувачі');
    const text0 = 'юридичних осіб, включених до Єдиного реєстру отримувачів гуманітарної допомоги';

    const attachNum = recipients[0]['attached_numb'];
    makeHeader_recipients(worksheet0, header, text0, attachNum);
    addTitles_recipients(worksheet0);
    addData_recipients(worksheet0, recipients);
  }

  if (cargos.length > 0) {
    // Вантажі
    workbook.addWorksheet('Вантажі', {
      pageSetup: { paperSize: 9, orientation: 'landscape' },
    });
    const worksheet = workbook.getWorksheet('Вантажі');
    const text = 'вантажів, визнаних гуманітарною допомогою';
    const attachNum = cargos[0]['attached_numb'];

    makeHeader_cargos(worksheet, header, text, attachNum);
    addTitles_cargos(worksheet);
    addData_cargos(worksheet, cargos);
  }

  if (services.length > 0) {
    // Послуги
    workbook.addWorksheet('Послуги', {
      pageSetup: { paperSize: 9, orientation: 'landscape' },
    });
    const worksheet2 = workbook.getWorksheet('Послуги');
    const text2 = 'послуг, визнаних гуманітарною допомогою';
    const attachNum = services[0]['attached_numb'];

    makeHeader_services(worksheet2, header, text2, attachNum);
    addTitles_services(worksheet2);
    addData_services(worksheet2, services);
  }

  if (funds.length > 0) {
    // Кошти
    workbook.addWorksheet('Кошти', {
      pageSetup: { paperSize: 9, orientation: 'landscape' },
    });
    const worksheet1 = workbook.getWorksheet('Кошти');
    const text1 = 'коштів, визнаних гуманітарною допомогою';
    const attachNum = funds[0]['attached_numb'];

    makeHeader_funds(worksheet1, header, text1, attachNum);
    addTitles_funds(worksheet1);
    addData_funds(worksheet1, funds);
  }

  // 31
  if (er.length > 0) {
    // Зміни до ЄР
    er.forEach((element) => {
      const attachNum = element['attached_numb'];

      workbook.addWorksheet(`Зміни до ЄР ${attachNum}`, {
        pageSetup: { paperSize: 9, orientation: 'landscape' },
      });
      const worksheet5 = workbook.getWorksheet(`Зміни до ЄР ${attachNum}`);
      const text5 = 'юридичних осіб, включених до Єдиного реєстру отримувачів гуманітарної допомоги';
      const array = [];
      array.push(element);
      makeHeader1(worksheet5, header, text5, attachNum);
      addTitles1(worksheet5);
      addData1(worksheet5, array);
    });
  }

  if (exclude.length > 0) {
    // Виключення
    workbook.addWorksheet('Виключення', {
      pageSetup: { paperSize: 9, orientation: 'landscape' },
    });
    const worksheet6 = workbook.getWorksheet('Виключення');
    const text6 = 'юридичних осіб, виключених з Єдиного реєстру отримувачів гуманітарної допомоги';
    const attachNum = exclude[0]['attached_numb'];

    makeHeader1(worksheet6, header, text6, attachNum);
    addTitles2(worksheet6);
    addData2(worksheet6, exclude);
  }

  // 61
  if (ch_cargo.length > 0) {
    // Зміна вантажів
    ch_cargo.forEach((element) => {
      const attachNum = element['attached_numb'];

      workbook.addWorksheet(`Зміна вантажу ${attachNum}`, {
        pageSetup: { paperSize: 9, orientation: 'landscape' },
      });
      const worksheet7 = workbook.getWorksheet(`Зміна вантажу ${attachNum}`);
      const text7 = 'вантажів, визнаних гуманітарною допомогою';
      const array = [];
      array.push(element);

      makeHeader2(worksheet7, header, text7, attachNum);
      addTitles3(worksheet7);
      addData3(worksheet7, array);
    });
  }

  if (ch_recipient.length > 0) {
    // Зміна отримувачів
    workbook.addWorksheet('Зміна отримувачів', {
      pageSetup: { paperSize: 9, orientation: 'landscape' },
    });
    const worksheet8 = workbook.getWorksheet('Зміна отримувачів');
    const text8 =
      'вантажів, визнаних гуманітарною допомогою, щодо яких проводиться зміна отримувача та переадресування';
    const attachNum = ch_recipient[0]['attached_numb'];

    makeHeader3(worksheet8, header, text8, attachNum);
    addTitles4(worksheet8);
    // additional step
    const lines_ch_recipient = getLines(ch_recipient);
    addData4(worksheet8, lines_ch_recipient);
  }

  const uint8Array = await workbook.xlsx.writeBuffer();
  const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Dodatok.xlsx';
  a.click();
  a.remove();
};

export default exportExcel;

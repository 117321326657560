import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ActPdfGenerate = (act, militaryType) => {
  const docDefinition = {
    content: [
      { text: 'АКТ \nприймання-передачі \nтранспортного засобу у вигляді гуманітарної допомоги', style: 'header' },
      {
        columns: [
          { text: `${act.general[0]?.d_act || '_____'} року`, style: 'info' },
          { text: `${act.recipient[0]?.c_city || '_____'}`, alignment: 'right', margin: [0, 20, 10, 0] }
        ]
      },
      {
        text: [
          { text: '-------', fontSize: 8, color: 'white' },
          {
            text: `${act.recipient[0]?.c_orgname || '_____'}, код за ЄДРПОУ ${
              act.recipient[0]?.n_edrpou || '_____'
            } (далі - Отримувач), в особі ${act.recipient[0]?.c_pib || '_____'}, що діє на підставі ${
              act.general[0]?.c_based_on || '_____'
            }, з однієї сторони, та військовослужбовця ${act.acquirer[0]?.sld_pib || '_____'}, номер військового квитка ${
              act.acquirer[0]?.sld_id || '_____'
            }, який проходить військову службу в ${militaryType || '_____'}, номер військового формування ${
              act.acquirer[0]?.sld_id || '_____'
            } (далі - Набувач) з іншої сторони, (надалі разом іменовані “Сторони”, а кожна окремо - “Сторона”), уклали цей акт приймання-передачі транспортного засобу у вигляді гуманітарної допомоги (далі - Акт) про таке:`,
          fontSize: 12,
          alignment: 'left'
        }
      ],
      margin: [0, 20, 0, 0]
    },
      { text: '1. Отримувач передав, а Набувач отримав транспортний засіб:', style: 'subTitle', marginBottom: 5 },
      {
        table: {
          widths: [20, '*', 50, 55, '*', '*'],
          body: [
            [
              '№ з/п',
              'Марка, модель, номер шассі (кузова, рами)',
              'Одиниця виміру',
              'Кількість, шт.',
              'Вартість за 1 шт., грн.',
              'Загальна вартість, грн.',
            ],
            [
              '1.',
              `${act.car[0]?.c_brand || ' - '}, ${act.car[0]?.c_model || ' - '}, ${act.car[0]?.c_vin || ' - '}`,
              'шт.',
              '1',
              `${act.car[0]?.n_price || ' - '}`,
              `${act.car[0]?.n_value || ' - '}`,
            ],
            [' ', ' ', ' ', ' ', ' ', ' '],
            ['', 'УСЬОГО', '', '1 шт.', `${act.car[0]?.n_price || ' - '}`, `${act.car[0]?.n_value || ' - '}`],
          ],
        },
      },
      {
        text: '2. Транспортний засіб передано в належному стані, Сторони претензій одна до одної не мають.',
        style: 'subTitle',
      },
      {
        text:
          '3. Військовослужбовці, які мають переважне право на відомчу перереєстрацію, згідно встановленого чинним законодавством порядку:',
        style: 'subTitle',
      },
      ...act.soldier?.map((soldier, index) => ({
        text: `${index + 1}) ${soldier.c_pib || '_____'}, номер військового квитка ${soldier.c_id_num || '_____'}, контактні дані: електронна пошта ${soldier.c_email || '_____'};`,
        style: 'subColumn',
      })),
      { text: '4. Цей Акт набуває чинності з дати його підписання Сторонами.', style: 'subTitle' },
      {
        text: '5. Цей Акт складено у двох примірниках, які мають однакову юридичну силу для обох Сторін.',
        style: 'subTitle',
      },
      { text: 'Місцезнаходження і реквізити сторін', style: 'header' },
      {
        columns: [
          [
            {
              text: `Отримувач`,
              style: 'boldText',
            },
            {
              text: 'повна назва юридичної особи:',
            },
            {
              text: `${act.recipient[0]?.c_orgname || '_____'}`,
              style: 'text',
            },
            {
              text: 'код за ЄДРПОУ:',
            },
            {
              text: `${act.recipient[0]?.n_edrpou || '_____'}`,
              style: 'text',
            },
            {
              text: 'юридична адреса:',
            },
            {
              text: `${act.recipient[0]?.c_adress || '_____'}`,
              style: 'text',
            },
          ],
          [
            {
              text: `Набувач`,
              style: 'boldText',
            },
            {
              text: 'повне ім’я:',
            },
            {
              text: `${act.acquirer[0]?.sld_pib || '_____'}`,
              style: 'text',
            },
            {
              text: 'номер військового квитка:',
            },
            {
              text: `${act.acquirer[0]?.sld_id || '_____'}`,
              style: 'text',
            },
            {
              text: 'електронна пошта:',
            },
            {
              text: `${act.acquirer[0]?.sld_email || '_____'}`,
              style: 'text',
            },
          ],
        ],
        columnGap: 50,
      },
      {
        columns: [
          {
            text: [
              {
                text: `${act.recipient[0]?.c_job_title || '_____'} \n${act.recipient[0]?.c_pib || '_____'}`,
              },
              {
                text: '\n\n\nпідпис _______________________', fontSize: 8
              },
            ],
          },
          {
            text: [
              {
                text: `${act.acquirer[0]?.sld_pib || '_____'}`,
              },
              {
                text: '\n\n\nпідпис _______________________', fontSize: 8
              },
            ],
          },
        ],
        columnGap: 50,
        style: 'text',
      },
    ],
    styles: {
      header: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
        margin: [0, 10, 0, 10],
      },
      paragraph: {
        alignment: 'justify',
        margin: [0, 20, 0, 0],
      },
      subTitle: {
        alignment: 'justify',
        margin: [20, 5, 0, 0],
      },
      subColumn: {
        alignment: 'justify',
        margin: [40, 10, 0, 10],
      },
      description: {
        fontSize: 8,
      },
      info: {
        alignment: 'left',
        margin: [0, 20, 0, 0],
      },
      boldText: {
        bold: true,
        margin: [0, 20, 0, 20],
      },
      text: {
        margin: [0, 0, 0, 5],
      },
    },
  };

  pdfMake.createPdf(docDefinition).open();
};

export default ActPdfGenerate;

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import DialogViewPdf from './DialogViewPdf';

const useStyles = makeStyles({
  dialog: {
    zIndex: '10001 !important',
  },
  autoPopper: {
    zIndex: '10001 !important',
  },
});

const DialogView = ({ openView, handleCloseView, handleOpenSign, pdf }) => {
  const classes = useStyles();
  return (
    <Dialog
      classes={{ root: classes.dialog }}
      open={openView}
      onClose={handleCloseView}
      aria-labelledby="view-dialog-title"
      aria-describedby="view-dialog-description"
      style={{ zIndex: '10001 !important' }}
      fullWidth
      fullScreen
    >
      <DialogTitle id="view-dialog-title">Перегляд декларації</DialogTitle>
      <DialogContent>
        <DialogViewPdf pdf={pdf} />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button variant="contained" color="primary" onClick={handleOpenSign}>
          Накладення підпису
        </Button>
        <Button variant="contained" color="primary" onClick={handleCloseView}>
          Відмовитись
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogView;

import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import startExcel from './startExcel';

const useStyles = makeStyles(() => ({
  button: {
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
  },
}));

const RegisterExcel = ({ dataToGetExcel }) => {
  const {
    recipient: { recipients },
    catalogs: { recipientTypes },
  } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const prepareData = (data) => {
    return data.map((item) => {
      const {
        n_number,
        region_name,
        locality_name,
        c_adress,
        n_edrpou,
        c_orgname,
        i_recipient_type,
        c_surname,
        c_name,
        c_patronymic,
        n_phone,
        dateStart,
        dateEnd,
        applicant_pib,
        c_email,
      } = item;

      return {
        n_number,
        region_name,
        locality_name,
        c_adress,
        n_edrpou,
        c_orgname,
        recipient_type: recipientTypes.find((el) => Number(el.value) === Number(i_recipient_type))?.label || '',
        c_pib: `${c_surname} ${c_name} ${c_patronymic}`,
        n_phone,
        dateStart,
        dateEnd,
        applicant_pib,
        c_email,
      };
    });
  };

  const downloadExcel = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post('/aid/getRecipientsForExcel', dataToGetExcel);
      const parseData = prepareData(data);
      await startExcel(parseData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={downloadExcel}
        className={classes.button}
        startIcon={<SaveAltOutlinedIcon />}
        style={{ marginLeft: 'auto', marginRight: '10px' }}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : 'Excel'}
      </Button>
    </>
  );
};

export default RegisterExcel;

import {
  ADD_INVENTORY_ITEM,
  SET_INVENTORY_INPUT,
  SET_INVENTORY_ARRAY,
  CLEAR_INVENTORY_CURRENT,
  REMOVE_INVENTORY_ITEM,
  SET_INVENTORY_CURRENT,
} from '../actions/action-types';

const initialState = {
  array: [],
  current: {
    pi_id: null,
    aidTypeObj: null,
    description: '',
    runitObj: null,
    weight: '',
    count: '',
    price: '',
    value: '',
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_INVENTORY_INPUT: {
      const { name, value } = action.payload;
      const { current } = state;
      return {
        ...state,
        current: {
          ...current,
          [name]: value,
        },
      };
    }

    case ADD_INVENTORY_ITEM: {
      const { array } = state;
      const { current } = state;
      return {
        ...state,
        array: [
          ...array,
          {
            pi_id: null,
            aidTypeObj: null,
            description: '',
            runitObj: null,
            weight: '',
            count: '',
            price: '',
            value: '',
          },
        ],
      };
    }

    case SET_INVENTORY_ARRAY: {
      return {
        ...state,
        array: [...action.payload],
      };
    }

    case CLEAR_INVENTORY_CURRENT: {
      return {
        ...state,
        current: {
          pi_id: null,
          aidTypeObj: null,
          description: '',
          runitObj: null,
          weight: '',
          count: '',
          price: '',
          value: '',
        },
      };
    }

    case REMOVE_INVENTORY_ITEM: {
      const { array } = state;
      return {
        ...state,
        array: [...array.slice(0, action.payload)],
      };
    }

    case SET_INVENTORY_CURRENT: {
      return {
        ...state,
        current: { ...action.payload },
      };
    }

    default:
      return state;
  }
}

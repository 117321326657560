import React from 'react';
import { useSelector } from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton, Input, Typography } from '@material-ui/core';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import { customTheme, customStyles } from '../../../../customStyles/reactSelect';
import { StyledTableCell } from './StyledTableCell';
import NumberFormatCustom from '../../../../vibe/components/inputs/NumberFormatCustom.component';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
  },
}));

const BalanceRow = ({
  currentIndex,
  index,
  item,
  handleInput,
  handleSelect,
  saveBalanceRow,
  deleteBalanceRow,
  editBalanceRow,
}) => {
  const {
    report: {
      balance: { current },
      cargo,
    },
  } = useSelector((state) => state);

  const classes = useStyles();

  const {
    pi_id,
    pi_aidtype_obj,
    pc_descript,
    pn_weight_in,
    pn_count_in,
    pn_weight_out,
    pn_count_out,
    pn_weight_rej,
    pn_count_rej,
    pn_weight_bal,
    pn_count_bal,
  } = item;

  const cargoOptions = cargo || [];

  return (
    <>
      <TableRow>
        <StyledTableCell>{index + 1}</StyledTableCell>
        <StyledTableCell width="21%" style={{ minWidth: '300px' }}>
          {currentIndex === index ? (
            <Select
              value={cargo.filter(({ id }) => Number(id) === Number(current.pi_aidtype_obj?.id))}
              onChange={handleSelect('pi_aidtype_obj')}
              options={cargoOptions}
              getOptionLabel={(option) => `${option.c_name}`}
              getOptionValue={(option) => option.id}
              styles={customStyles}
              // isLoading={this.props.loading.CATALOG}
              placeholder=""
              noOptionsMessage={() => 'помилка серверу'}
              menuPortalTarget={document.body}
              theme={customTheme}
            />
          ) : (
            <Typography>{pi_aidtype_obj?.c_name}</Typography>
          )}
        </StyledTableCell>
        <StyledTableCell width="7%">
          {currentIndex === index ? (
            <Input className={classes.input} name="pc_descript" value={current.pc_descript} onChange={handleInput} />
          ) : (
            <Typography>{pc_descript}</Typography>
          )}
        </StyledTableCell>
        <StyledTableCell width="7%">
          {currentIndex === index ? (
            <Input
              className={classes.input}
              name="pn_weight_in"
              value={current.pn_weight_in}
              onChange={handleInput}
              inputComponent={NumberFormatCustom}
              inputProps={{
                decimalSeparator: '.',
                decimalScale: 3,
                maxLength: 10,
              }}
            />
          ) : (
            <Typography>{pn_weight_in}</Typography>
          )}
        </StyledTableCell>
        <StyledTableCell width="7%">
          {currentIndex === index ? (
            <Input
              className={classes.input}
              name="pn_count_in"
              value={current.pn_count_in}
              onChange={handleInput}
              inputComponent={NumberFormatCustom}
              inputProps={{
                decimalSeparator: '.',
                decimalScale: 2,
                maxLength: 10,
              }}
            />
          ) : (
            <Typography>{pn_count_in}</Typography>
          )}
        </StyledTableCell>
        <StyledTableCell width="7%">
          <Typography>{pn_weight_out}</Typography>
        </StyledTableCell>
        <StyledTableCell width="7%">
          <Typography>{pn_count_out}</Typography>
        </StyledTableCell>
        <StyledTableCell width="7%">
          <Typography>{pn_weight_rej}</Typography>
        </StyledTableCell>
        <StyledTableCell width="7%">
          <Typography>{pn_count_rej}</Typography>
        </StyledTableCell>
        <StyledTableCell width="7%">
          <Typography>{pn_weight_bal}</Typography>
        </StyledTableCell>
        <StyledTableCell width="7%">
          <Typography>{pn_count_bal}</Typography>
        </StyledTableCell>
        <StyledTableCell width="21%" style={{ minWidth: '200px' }}>
          {/* <BootstrapTooltip title="Редагувати"> */}
          <IconButton
            disabled={currentIndex !== index}
            // id={`edit${text.cargo_id}`}
            color="primary"
            aria-label="Done"
            onClick={(e) => {
              e.stopPropagation();
              saveBalanceRow();
            }}
          >
            <DoneIcon fontSize="inherit" />
          </IconButton>
          <IconButton
            disabled={currentIndex !== -1}
            color="primary"
            aria-label="Edit"
            onClick={(e) => {
              e.stopPropagation();
              editBalanceRow(index, item.pi_id);
            }}
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="Delete"
            onClick={(e) => {
              e.stopPropagation();
              deleteBalanceRow(item.pi_id);
            }}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </StyledTableCell>
      </TableRow>
    </>
  );
};

export default BalanceRow;

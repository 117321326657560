export const IS_MOBILE = 'IS_MOBILE';
export const TOGGLE = 'TOGGLE';
//-------------------auth-------------------------
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const REQUEST_USER = 'REQUEST_USER';
export const SUCCESS_USER = 'SUCCESS_USER';
export const ERROR_USER = 'ERROR_USER';
//------------------------------------------------

//-------------------catalog-----------------------
export const REQUEST_CATALOG = 'REQUEST_CATALOG';
export const SUCCESS_CATALOG = 'SUCCESS_CATALOG';
export const ERROR_CATALOG = 'ERROR_CATALOG';

export const REQUEST_LOCALITY = 'REQUEST_LOCALITY';
export const SUCCESS_LOCALITY = 'SUCCESS_LOCALITY';
//------------------------------------------------

//-------------------recipient-------------------------
export const GET_RECIPIENT = 'GET_RECIPIENT';
export const GET_RECIPIENTS = 'SET_RECIPIENTS';
//recipient inputs selects
export const RECIPIENT_REGISTERED = 'RECIPIENT_REGISTERED';
export const RECIPIENT_REGION = 'RECIPIENT_REGION';
export const RECIPIENT_CITY = 'RECIPIENT_CITY';
export const RECIPIENT_EDRPOU = 'RECIPIENT_EDRPOU';
export const RECIPIENT_TITLE = 'RECIPIENT_TITLE';
export const RECIPIENT_SURNAME = 'RECIPIENT_SURNAME';
export const RECIPIENT_NAME = 'RECIPIENT_NAME';
export const RECIPIENT_LASTNAME = 'RECIPIENT_LASTNAME';
export const RECIPIENT_RECIPIENT_TYPE = 'RECIPIENT_RECIPIENT_TYPE';
export const RECIPIENT_REPR_OCCUPATION = 'RECIPIENT_REPR_OCCUPATION';
export const RECIPIENT_REPR_SURNAME = 'RECIPIENT_REPR_SURNAME';
export const RECIPIENT_REPR_FIRSTNAME = 'RECIPIENT_REPR_FIRSTNAME';
export const RECIPIENT_REPR_PATRONYMIC = 'RECIPIENT_REPR_PATRONYMIC';
export const RECIPIENT_REPR_RNOKPP = 'RECIPIENT_REPR_RNOKPP';
export const RECIPIENT_REPR_CODE = 'RECIPIENT_REPR_CODE';
export const RECIPIENT_REPR_COUNTRY = 'RECIPIENT_REPR_COUNTRY';
export const RECIPIENT_REPR_LOCALITY = 'RECIPIENT_REPR_LOCALITY';
export const RECIPIENT_PHONE = 'RECIPIENT_PHONE';
export const RECIPIENT_PHONE2 = 'RECIPIENT_PHONE2';
export const RECIPIENT_PHONE3 = 'RECIPIENT_PHONE3';
export const RECIPIENT_PHONE_MAX = 'RECIPIENT_PHONE_MAX';
export const RECIPIENT_PHONES_ARRAY = 'RECIPIENT_PHONES_ARRAY';
export const RECIPIENT_P_PRIVATE = 'RECIPIENT_P_PRIVATE';

// recipient fields disabled
export const RECIPIENT_PL_EDRPOU = 'RECIPIENT_PL_EDRPOU';
export const RECIPIENT_PL_REGION = 'RECIPIENT_PL_REGION';
export const RECIPIENT_PL_LOCALITY = 'RECIPIENT_PL_LOCALITY';
export const RECIPIENT_PL_ADRESS = 'RECIPIENT_PL_ADRESS';
export const RECIPIENT_PL_ORGNAME = 'RECIPIENT_PL_ORGNAME';
export const RECIPIENT_PL_RECIPIENT_TYPE = 'RECIPIENT_PL_RECIPIENT_TYPE';
export const RECIPIENT_PL_SURNAME = 'RECIPIENT_PL_SURNAME';
export const RECIPIENT_PL_NAME = 'RECIPIENT_PL_NAME';
export const RECIPIENT_PL_PATRONYMIC = 'RECIPIENT_PL_PATRONYMIC';
// two editional
export const RECIPIENT_EDITABLE = 'RECIPIENT_EDITABLE';
export const RECIPIENT_PL_GOT_OLFCODE = 'RECIPIENT_PL_GOT_OLFCODE';

export const RECIPIENT_ADD_PHONE = 'RECIPIENT_ADD_PHONE';
export const RECIPIENT_REMOVE_PHONE = 'RECIPIENT_REMOVE_PHONE';

export const RECIPIENT_EMAIL = 'RECIPIENT_EMAIL';
export const RECIPIENT_ADDRESS = 'RECIPIENT_ADDRESS';
export const RECIPIENT_KOATUU = 'RECIPIENT_KOATUU';

export const RECIPIENT_NAIS = 'RECIPIENT_NAIS';

export const RECIPIENT_ID = 'RECIPIENT_ID';
export const REQUEST_RECIPIENT = 'REQUEST_RECIPIENT';
export const REQUEST_RECIPIENT_DOC = 'REQUEST_RECIPIENT_DOC';
export const SUCCESS_RECIPIENT = 'SUCCESS_RECIPIENT';
export const ERROR_RECIPIENT = 'ERROR_RECIPIENT';

export const REQUEST_RECIPIENTS = 'REQUEST_RECIPIENTS';
export const SUCCESS_RECIPIENTS = 'SUCCESS_RECIPIENTS';
export const SUCCESS_RECIPIENTS_PAGE = 'SUCCESS_RECIPIENTS_PAGE';
export const ERROR_RECIPIENTS = 'ERROR_RECIPIENTS';

export const REQUEST_CONTROLS = 'REQUEST_CONTROLS';
export const SUCCESS_CONTROLS = 'SUCCESS_CONTROLS';
export const ERROR_CONTROLS = 'ERROR_CONTROLS';

export const REQUEST_APPLY_APP = 'REQUEST_APPLY_APP';
export const SUCCESS_APPLY_APP = 'SUCCESS_APPLY_APP';
export const ERROR_APPLY_APP = 'ERROR_APPLY_APP';

export const REQUEST_APPROVE = 'REQUEST_APPROVE';
export const SUCCESS_APPROVE = 'SUCCESS_APPROVE';
export const ERROR_APPROVE = 'ERROR_APPROVE';

export const REQUEST_DENIED = 'REQUEST_DENIED';
export const SUCCESS_DENIED = 'SUCCESS_DENIED';
export const ERROR_DENIED = 'ERROR_DENIED';

export const REQUEST_RECIPIENT_SAVE = 'REQUEST_RECIPIENT_SAVE';
export const SUCCESS_RECIPIENT_SAVE = 'SUCCESS_RECIPIENT_SAVE';
export const ERROR_RECIPIENT_SAVE = 'ERROR_RECIPIENT_SAVE';

export const APPLICATION_ID = 'APPLICATION_ID';
export const RECIPIENT_NEW_ID = 'RECIPIENT_NEW_ID';

//RECIPIENT DOCUMENTS
export const REQUEST_RECIPIENT_DOCUMENTS_SAVE = 'REQUEST_RECIPIENT_DOCUMENTS_SAVE';
export const SUCCESS_RECIPIENT_DOCUMENTS_SAVE = 'SUCCESS_RECIPIENT_DOCUMENTS_SAVE';
export const ERROR_RECIPIENT_DOCUMENTS_SAVE = 'ERROR_RECIPIENT_DOCUMENTS_SAVE';

export const SUCCESS_RECIPIENT_DOCUMENTS = 'SUCCESS_RECIPIENT_DOCUMENTS';
export const ERROR_RECIPIENT_DOCUMENTS = 'ERROR_RECIPIENT_DOCUMENTS';
export const REQUEST_RECIPIENT_DOCUMENTS = 'REQUEST_RECIPIENT_DOCUMENTS';

export const SUCCESS_RECIPIENT_DOCUMENTS_DEL = 'SUCCESS_RECIPIENT_DOCUMENTS_DEL';
export const ERROR_RECIPIENT_DOCUMENTS_DEL = 'ERROR_RECIPIENT_DOCUMENTS_DEL';
export const REQUEST_RECIPIENT_DOCUMENTS_DEL = 'REQUEST_RECIPIENT_DOCUMENTS_DEL';

//

export const RECIPIENT_CLEAR = 'RECIPIENT_CLEAR';
export const RECIPIENTS_CLEAR = 'RECIPIENTS_CLEAR';
export const CONTROLS_CLEAR = 'CONTROLS_CLEAR';

export const SUCCESS_RECIPIENT_APPLICATIONS_HISTORY = 'SUCCESS_RECIPIENT_APPLICATIONS_HISTORY';
export const ERROR_RECIPIENT_APPLICATIONS_HISTORY = 'ERROR_RECIPIENT_APPLICATIONS_HISTORY';
export const REQUEST_RECIPIENT_APPLICATIONS_HISTORY = 'REQUEST_RECIPIENT_APPLICATIONS_HISTORY';

export const SUCCESS_DELETE_RECIPIENT = 'SUCCESS_DELETE_RECIPIENT';
export const ERROR_DELETE_RECIPIENT = 'ERROR_DELETE_RECIPIENT';
export const REQUEST_DELETE_RECIPIENT = 'REQUEST_DELETE_RECIPIENT';

export const SUCCESS_RECIPIENTS_SELECT = 'SUCCESS_RECIPIENTS_SELECT';
export const ERROR_RECIPIENTS_SELECT = 'ERROR_RECIPIENTS_SELECT';
export const REQUEST_RECIPIENTS_SELECT = 'REQUEST_RECIPIENTS_SELECT';

export const REQUEST_RECIPIENT_DELETE_FROM_REGISTER = 'REQUEST_RECIPIENT_DELETE_FROM_REGISTER';
export const SUCCESS_RECIPIENT_DELETE_FROM_REGISTER = 'SUCCESS_RECIPIENT_DELETE_FROM_REGISTER';
export const ERROR_RECIPIENT_DELETE_FROM_REGISTER = 'ERROR_RECIPIENT_DELETE_FROM_REGISTER';

export const RECIPIENT_SAVE_TRACK = 'RECIPIENT_SAVE_TRACK';
//--------------------------------------------

// -----------------cargo-------------------------
export const SET_CARGO_SEARCH = 'SET_CARGO_SEARCH';
export const SET_CARGO_REGISTER = 'SET_CARGO_REGISTER';
export const SET_CARGO_LOADING = 'SET_CARGO_LOADING';
export const SET_CARGO_MINUS_ID = 'SET_CARGO_MINUS_ID';
export const REQUEST_CARGO_BY_ID = 'REQUEST_CARGO_BY_ID';
export const SUCCESS_CARGO_BY_ID = 'SUCCESS_CARGO_BY_ID';
export const ERROR_CARGO_BY_ID = 'ERROR_CARGO_BY_ID';

export const CARGO_RECIPIENT_NAME_SELECT = 'RECIPIENT_NAME_SELECT';
export const CARGO_DONOR_NAME = 'CARGO_DONOR_NAME';
export const CARGO_DONOR_DETAILS = 'CARGO_DONOR_DETAILS';
export const CARGO_DONOR_COUNTRY_SELECT = 'CARGO_DONOR_COUNTRY_SELECT';
export const CARGO_SENDING_COUNTRY_SELECT = 'CARGO_SENDING_COUNTRY_SELECT';
export const CARGO_ACQUIRER_TYPE_SELECT = 'CARGO_ACQUIRER_TYPE_SELECT';

export const CARGO_DONOR_REGNUMBER = 'CARGO_DONOR_REGNUMBER';
export const CARGO_HEAD_SURNAME = 'CARGO_HEAD_SURNAME';
export const CARGO_HEAD_FIRSTNAME = 'CARGO_HEAD_FIRSTNAME';
export const CARGO_HEAD_PATRONYMIC = 'CARGO_HEAD_PATRONYMIC';
export const CARGO_WRITTEN_PROPOSAL = 'CARGO_WRITTEN_PROPOSAL';
export const CARGO_INVOICE = 'CARGO_INVOICE';
export const CARGO_PACKAGE_LETTER = 'CARGO_PACKAGE_LETTER';
export const CARGO_OTHER_ACCOMPANY_DOCS = 'CARGO_OTHER_ACCOMPANY_DOCS';
export const CARGO_AGREEMENT_TO_RECEIVE = 'CARGO_AGREEMENT_TO_RECEIVE';
export const CARGO_L_PRIVATE = 'CARGO_L_PRIVATE';
export const CARGO_WEAR_PERCENTAGE = 'CARGO_WEAR_PERCENTAGE';
export const CARGO_WEIGHT = 'CARGO_WEIGHT';
export const CARGO_EXPIRATION_TERM = 'CARGO_EXPIRATION_TERM';
export const CARGO_EXPLOITATION_TERM = 'CARGO_EXPLOITATION_TERM';
export const CARGO_PAIRS_NUM = 'CARGO_PAIRS_NUM';

export const CARGO_ACQUIRER_MAIN_NAME = 'CARGO_ACQUIRER_MAIN_NAME';
export const CARGO_ACQUIRER_SURNAME = 'CARGO_ACQUIRER_SURNAME';
export const CARGO_ACQUIRER_NAME = 'CARGO_ACQUIRER_NAME';
export const CARGO_ACQUIRER_LASTNAME = 'CARGO_ACQUIRER_LASTNAME';
export const CARGO_ACQUIRER_SOCIAL_CATEGORY = 'CARGO_ACQUIRER_SOCIAL_CATEGORY';
export const CARGO_ACQUIRER_REGION = 'CARGO_ACQUIRER_REGION';
export const CARGO_ACQUIRER_LOCALITY = 'CARGO_ACQUIRER_LOCALITY';
export const CARGO_ACQUIRER_LOCATION = 'CARGO_ACQUIRER_LOCATION';
export const CARGO_ACQUIRER_PHONE = 'CARGO_ACQUIRER_PHONE';
export const CARGO_ACQUIRER_COUNT_PLAN = 'CARGO_ACQUIRER_COUNT_PLAN';
export const CARGO_AID_TYPE_SELECT = 'CARGO_AID_TYPE_SELECT';
export const CARGO_ACQUIRER_EDRPOU_CODE = 'CARGO_ACQUIRER_EDRPOU_CODE';
export const CARGO_ACQUIRER_ACQUIRES_NUMBER = 'CARGO_ACQUIRER_ACQUIRES_NUMBER';
export const CARGO_ACQUIRER_SUPPORTING_CIRCUMSTANCES = 'CARGO_ACQUIRER_SUPPORTING_CIRCUMSTANCES';
export const CARGO_ACQUIRER_WEIGHT = 'CARGO_ACQUIRER_WEIGHT';

export const CARGO_DESCRIPTION = 'CARGO_DESCRIPTION';
export const CARGO_DESCRIPTION_SELECT = 'CARGO_DESCRIPTION_SELECT';
export const CARGO_QUONTITY = 'CARGO_QUONTITY';
export const CARGO_UNIT = 'CARGO_UNIT';
export const CARGO_CONDITION = 'CARGO_CONDITION';
export const CARGO_ADD = 'CARGO_ADD';
export const CARGO_DELETE = 'CARGO_DELETE';
export const CARGO_SET_ARRAY = 'CARGO_SET_ARRAY';

export const SET_CURGO_BY_RECIPIENT = 'SET_CURGO_BY_RECIPIENT';

export const REQUEST_CARGO_SAVE = 'REQUEST_CARGO_SAVE';
export const SUCCESS_CARGO_SAVE = 'SUCCESS_CARGO_SAVE';
export const ERROR_CARGO_SAVE = 'ERROR_CARGO_SAVE';

export const APPLICATION_CARGO_ID = 'APPLICATION_CARGO_ID';
export const CARGO_ID = 'CARGO_ID';
export const REQUEST_MY_CARGO = 'REQUEST_MY_CARGO';
export const SUCCESS_MY_CARGO = 'SUCCESS_MY_CARGO';
export const ERROR_MY_CARGO = 'ERROR_MY_CARGO';

//InvestoryDescription
export const REQUEST_INVESTORY = 'REQUEST_INVESTORY';
export const SUCCESS_INVESTORY = 'SUCCESS_INVESTORY';

//ReportDescription
export const REQUEST_REPORT = 'REQUEST_REPORT';
export const SUCCESS_REPORT = 'SUCCESS_REPORT';
//CARGO DOCUMENTS

export const REQUEST_CARGO_DOCUMENTS_SAVE = 'REQUEST_CARGO_DOCUMENTS_SAVE';
export const SUCCESS_CARGO_DOCUMENTS_SAVE = 'SUCCESS_CARGO_DOCUMENTS_SAVE';
export const ERROR_CARGO_DOCUMENTS_SAVE = 'ERROR_CARGO_DOCUMENTS_SAVE';

export const REQUEST_CARGO_DOCUMENTS = 'REQUEST_CARGO_DOCUMENTS';
export const SUCCESS_CARGO_DOCUMENTS = 'SUCCESS_CARGO_DOCUMENTS';
export const ERROR_CARGO_DOCUMENTS = 'ERROR_CARGO_DOCUMENTS';

export const REQUEST_CARGO_DOCUMENTS_DEL = 'REQUEST_CARGO_DOCUMENTS_DEL';
export const SUCCESS_CARGO_DOCUMENTS_DEL = 'SUCCESS_CARGO_DOCUMENTS_DEL';
export const ERROR_CARGO_DOCUMENTS_DEL = ' ERROR_CARGO_DOCUMENTS_DEL';

export const REQUEST_CARGO_REPORT = 'REQUEST_CARGO_REPORT';
export const SUCCESS_CARGO_REPORT = 'SUCCESS_CARGO_REPORT';
export const ERROR_CARGO_REPORT = ' ERROR_CARGO_REPORT';

export const CARGO_CLEAR = 'CARGO_CLEAR';
export const AQUIRERE_ADD = 'AQUIRERE_ADD';
export const AID_ID = 'AID_ID';
export const CARGO_AID_COLLECTION = 'CARGO_AID_COLLECTION';
export const SUCCESS_AQUIRER_BY_ID = 'SUCCESS_AQUIRER_BY_ID';
export const REQUEST_AQUIRER_BY_ID = 'REQUEST_AQUIRER_BY_ID';
export const ERROR_AQUIRER_BY_ID = 'ERROR_AQUIRER_BY_ID';
export const CLEAR_AQCUIRER = 'CLEAR_AQCUIRER';
export const EDIT_AID_ACTION = 'EDIT_AID_ACTION';
export const DISTRIBUTIONS = 'DISTRIBUTIONS';
export const EDIT_UNIT = 'EDIT_UNIT';
export const DATA_TO_PDF = 'DATA_TO_PDF';
export const ID_TO_PDF = 'ID_TO_PDF';
export const DATE_TO_PDF = 'DATE_TO_PDF';

//commission
//сommission create
export const SELECT_APPLICATIONS = 'SELECT_APPLICATIONS'; //push cheched it into array
export const SUCCESS_APPLICATIONS = 'SUCCESS_APPLICATIONS'; //get data for table from server
export const CHECK_APPLICATION = 'CHECK_APPLICATION'; //when all cheched flag is true
export const TABLE_CHANGE = 'TABLE_CHANGE'; //renew table data after checking
export const SELECT_CLEAR = 'SELECT_CLEAR'; //clear
//сommission data
export const COMMISSION_SET = 'COMMISSION_SET';
export const COMMISSION_CLEAR = 'COMMISSION_CLEAR';
export const COMMISION_ID = 'COMMISION_ID';
export const SET_MEETING = 'SET_MEETING';

export const REQUEST_APPLICATIONS = 'REQUEST_APPLICATIONS';
export const ERROR_APPLICATIONS = 'ERROR_APPLICATIONS';
export const SUCCESS_CARGO_APPLICATIONS = 'SUCCESS_CARGO_APPLICATIONS';

export const RECIPIENT_NEW = 'RECIPIENT_NEW';
export const RECIPIENT_OLD = 'RECIPIENT_OLD';
export const CARGO_NEW = 'CARGO_NEW';
export const CARGO_OLD = 'CARGO_OLD';
export const TYPE_MEETING = 'TYPE_MEETING';

export const CHANGE_POSITION_PROFILE = 'CHANGE_POSITION_PROFILE';
export const CHANGE_EMAIL_PROFILE = 'CHANGE_EMAIL_PROFILE';

// check component
export const SET_CHECK_ROWS = 'SET_CHECK_ROWS';
export const CLEAR_CHECK_ROWS = 'CLEAR_CHECK_ROWS';
export const FETCH_CHECK_ROWS_START = 'FETCH_CHECK_ROWS_START';
export const FETCH_CHECK_ROWS_SUCCESS = 'FETCH_CHECK_ROWS_SUCCESS';
export const FETCH_CHECK_ROWS_FALIURE = 'FETCH_CHECK_ROWS_FALIURE';
export const SET_CHECK_ROW_COMMENT = 'SET_CHECK_ROW_COMMENT';
export const SET_CHECK_ROW_ACT = 'SET_CHECK_ROW_ACT';

//-----------------------------------------------------
// order search
export const SET_ORDER_ROWS = 'SET_ORDER_ROWS';
export const FETCH_ORDER_ROWS_START = 'FETCH_ORDER_ROWS_START';
export const FETCH_ORDER_ROWS_SUCCESS = 'FETCH_ORDER_ROWS_SUCCESS';
export const FETCH_ORDER_ROWS_FALIURE = 'FETCH_ORDER_ROWS_FALIURE';

//-----------------numberOrder-------------------------
export const NUMBER_DT = 'NUMBER_DT';

//-----------------------------------------------------
// access
export const FETCH_ACCESS_START = 'FETCH_ACCESS_START';
export const FETCH_ACCESS_SUCCESS = 'FETCH_ACCESS_SUCCESS';
export const FETCH_ACCESS_FAILURE = 'FETCH_ACCESS_FAILURE';
export const FETCH_SWITCH_ACCESS_START = 'FETCH_SWITCH_ACCESS_START';
export const FETCH_SWITCH_ACCESS_SUCCESS = 'FETCH_SWITCH_ACCESS_SUCCESS';
export const FETCH_SWITCH_ACCESS_FAILURE = 'FETCH_SWITCH_ACCESS_FAILURE';

//-----------------------------------------------------
// uktzed
export const FETCH_SAVE_UKTZED_START = 'FETCH_SAVE_UKTZED_START';
export const FETCH_SAVE_UKTZED_SUCCESS = 'FETCH_SAVE_UKTZED_SUCCESS';
export const FETCH_SAVE_UKTZED_FAILURE = 'FETCH_SAVE_UKTZED_FAILURE';
export const FETCH_UKTZED_START = 'FETCH_UKTZED_START';
export const FETCH_UKTZED_SUCCESS = 'FETCH_UKTZED_SUCCESS';
export const FETCH_UKTZED_FAILURE = 'FETCH_UKTZED_FAILURE';

// declaration
export const DECLARATION = 'DECLARATION';
export const CLEAR_DECLARATION = 'CLEAR_DECLARATION';

// report
export const SET_REPORT_PARAMS = 'SET_REPORT_PARAMS';
export const SET_REPORT_ID = 'SET_REPORT_ID';
export const SET_REPORT_DATE = 'SET_REPORT_DATE';
export const SET_REPORT_CARGO_LIST = 'SET_REPORT_CARGO_LIST';
export const SET_REPORT_AIDTYPE = 'SET_REPORT_AIDTYPE';
export const SET_REPORT_ACQUIRER = 'SET_REPORT_ACQUIRER';
export const SET_REPORT_TAB = 'SET_REPORT_TAB';

// balance
export const ADD_BALANCE_ITEM = 'ADD_BALANCE_ITEM';
export const SET_BALANCE_INPUT = 'SET_BALANCE_INPUT';
export const SET_BALANCE_ARRAY = 'SET_BALANCE_ARRAY';
export const CLEAR_BALANCE_CURRENT = 'CLEAR_BALANCE_CURRENT';
export const REMOVE_BALANCE_ITEM = 'REMOVE_BALANCE_ITEM';
export const SET_BALANCE_CURRENT = 'SET_BALANCE_CURRENT';
// distribution
export const ADD_DISTRIBUTION_ITEM = 'ADD_DISTRIBUTION_ITEM';
export const SET_DISTRIBUTION_INPUT = 'SET_DISTRIBUTION_INPUT';
export const SET_DISTRIBUTION_ARRAY = 'SET_DISTRIBUTION_ARRAY';
export const CLEAR_DISTRIBUTION_CURRENT = 'CLEAR_DISTRIBUTION_CURRENT';
export const REMOVE_DISTRIBUTION_ITEM = 'REMOVE_DISTRIBUTION_ITEM';
export const SET_DISTRIBUTION_CURRENT = 'SET_DISTRIBUTION_CURRENT';
export const SET_DISTRIBUTION_SAVER = 'SET_DISTRIBUTION_SAVER';
export const SET_DISTRIBUTION_CARDS = 'SET_DISTRIBUTION_CARDS';

// inventory
export const ADD_INVENTORY_ITEM = 'ADD_INVENTORY_ITEM';
export const SET_INVENTORY_INPUT = 'SET_INVENTORY_INPUT';
export const SET_INVENTORY_ARRAY = 'SET_INVENTORY_ARRAY';
export const CLEAR_INVENTORY_CURRENT = 'CLEAR_INVENTORY_CURRENT';
export const REMOVE_INVENTORY_ITEM = 'REMOVE_INVENTORY_ITEM';
export const SET_INVENTORY_CURRENT = 'SET_INVENTORY_CURRENT';

// competency
export const SET_COMPETENCY_REQUEST_ID = 'SET_COMPETENCY_REQUEST_ID';

// acts
export const CREATE_ACT_REQUEST = 'CREATE_ACT_REQUEST';
export const CREATE_ACT_SUCCESS = 'CREATE_ACT_SUCCESS';
export const CREATE_ACT_FAILURE = 'CREATE_ACT_FAILURE';
export const DELETE_ACT_REQUEST = 'DELETE_ACT_REQUEST';
export const DELETE_ACT_SUCCESS = 'DELETE_ACT_SUCCESS';
export const DELETE_ACT_FAILURE = 'DELETE_ACT_FAILURE';
export const DELETE_DOC_ACT_REQUEST = 'DELETE_DOC_ACT_REQUEST';
export const DELETE_DOC_ACT_SUCCESS = 'DELETE_DOC_ACT_SUCCESS';
export const DELETE_DOC_ACT_FAILURE = 'DELETE_DOC_ACT_FAILURE';
export const DELETE_SOLDIER_ACT_REQUEST = 'DELETE_SOLDIER_ACT_REQUEST';
export const DELETE_SOLDIER_ACT_SUCCESS = 'DELETE_SOLDIER_ACT_SUCCESS';
export const DELETE_SOLDIER_ACT_FAILURE = 'DELETE_SOLDIER_ACT_FAILURE';
export const GET_ACT_REQUEST = 'GET_ACT_REQUEST';
export const GET_ACT_SUCCESS = 'GET_ACT_SUCCESS';
export const GET_ACT_FAILURE = 'GET_ACT_FAILURE';
export const GET_ACTS_REQUEST = 'GET_ACTS_REQUEST';
export const GET_ACTS_SUCCESS = 'GET_ACTS_SUCCESS';
export const GET_ACTS_FAILURE = 'GET_ACTS_FAILURE';
export const GET_DOCS_BY_ACT_REQUEST = 'GET_DOCS_BY_ACT_REQUEST';
export const GET_DOCS_BY_ACT_SUCCESS = 'GET_DOCS_BY_ACT_SUCCESS';
export const GET_DOCS_BY_ACT_FAILURE = 'GET_DOCS_BY_ACT_FAILURE';
export const GET_SOLDIERS_BY_ACT_REQUEST = 'GET_SOLDIERS_BY_ACT_REQUEST';
export const GET_SOLDIERS_BY_ACT_SUCCESS = 'GET_SOLDIERS_BY_ACT_SUCCESS';
export const GET_SOLDIERS_BY_ACT_FAILURE = 'GET_SOLDIERS_BY_ACT_FAILURE';
export const GET_MILITARY_TYPES_REQUEST = 'GET_MILITARY_TYPES_REQUEST';
export const GET_MILITARY_TYPES_SUCCESS = 'GET_MILITARY_TYPES_SUCCESS';
export const GET_MILITARY_TYPES_FAILURE = 'GET_MILITARY_TYPES_FAILURE';
export const SAVE_ACT_REQUEST = 'SAVE_ACT_REQUEST';
export const SAVE_ACT_SUCCESS = 'SAVE_ACT_SUCCESS';
export const SAVE_ACT_FAILURE = 'SAVE_ACT_FAILURE';
export const SAVE_DOC_ACT_REQUEST = 'SAVE_DOC_ACT_REQUEST';
export const SAVE_DOC_ACT_SUCCESS = 'SAVE_DOC_ACT_SUCCESS';
export const SAVE_DOC_ACT_FAILURE = 'SAVE_DOC_ACT_FAILURE';
export const SAVE_SOLDIER_ACT_REQUEST = 'SAVE_SOLDIER_ACT_REQUEST';
export const SAVE_SOLDIER_ACT_SUCCESS = 'SAVE_SOLDIER_ACT_SUCCESS';
export const SAVE_SOLDIER_ACT_FAILURE = 'SAVE_SOLDIER_ACT_FAILURE';
export const SIGN_FILE_ACT_REQUEST = 'SIGN_FILE_ACT_REQUEST';
export const SIGN_FILE_ACT_SUCCESS = 'SIGN_FILE_ACT_SUCCESS';
export const SIGN_FILE_ACT_FAILURE = 'SIGN_FILE_ACT_FAILURE';
export const SUBMIT_ACT_REQUEST = 'SUBMIT_ACT_REQUEST';
export const SUBMIT_ACT_SUCCESS = 'SUBMIT_ACT_SUCCESS';
export const SUBMIT_ACT_FAILURE = 'SUBMIT_ACT_FAILURE';

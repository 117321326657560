import React from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ReactComponent as ImportIcon } from './reportHelpers/assets/ImportIcon.svg';
import { ReactComponent as CloseIcon } from './reportHelpers/assets/CloseIcon.svg';
import { uploadDistribution } from '../../../../api/api';
import popup from './reportHelpers/Popup.module.css';

const SwalPopup = Swal.mixin({
  position: 'center',
  showConfirmButton: true,
  animation: false,
  customClass: {
    popup: popup['toast-popup'],
    container: popup['toast-container'],
    title: popup['toast-title'],
    actions: popup['toast-action'],
    confirmButton: popup['toast-confirm'],
  },
});

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: '36px',
    margin: 0,
  },
  content: {
    padding: '24px 0 0',
  },
  topButton: {
    backgroundColor: '#D5D8E0',
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    textAlign: 'center',
    padding: '12px 24px 12px 16px',
    borderRadius: '30px',
  },
  icon: {
    '& > svg': {
      margin: 0,
    },
  },
  input: {
    display: 'none',
  },
  inputLabel: {
    padding: 0,
  },
  uploadBtn: {
    padding: '14px 24px',
    borderRadius: '30px',
    backgroundColor: '#D5D8E0',
  },
  uploadBtnTxt: {
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    textAlign: 'center',
  },
  droparea: {
    border: '1px dashed #000000',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0',
  },
  dropinfo: {
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'center',
    margin: 0,
  },
  drophelp: {
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'center',
    margin: 0,
  },
  updateMsg: {
    margin: 0,
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'center',
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: 0,
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    transition: theme.transitions.create('transform'),
    transform: 'translate(+50%, -50%)',
  },
  titleText: {
    textAlign: 'center',
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '32px',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.titleText} variant="h6">
        {children}
      </Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const MenuImport = ({ updateBothTables }) => {
  const classes = useStyles();
  const i_report = useSelector((state) => state.report.params.i_report);
  const [file, setFile] = React.useState(null);
  const [upload, setUpload] = React.useState(false);

  const handleCloseUpload = () => {
    setUpload(false);
  };

  const handleOpenUpload = () => {
    setUpload(true);
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const uploadFile = async (fileUpload) => {
    if (fileUpload) {
      const fileName = fileUpload.name;
      const format = fileName.substr(fileName.lastIndexOf('.') + 1);
      const formData = new FormData();
      formData.append('file', fileUpload);
      formData.append('report', i_report);
      formData.append('format', format);
      try {
        const res = await uploadDistribution(formData);
        if (res.status === 200) {
          updateBothTables();
          handleCloseUpload();
          SwalPopup.fire({
            allowEscapeKey: true,
            allowOutsideClick: true,
            title: res.data,
            confirmButtonText: 'ЗРОЗУМІЛО',
          });
        }
        // eslint-disable-next-line no-empty
      } catch (err) {}
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const newFile = Array.from(droppedFiles);
      const fileName = newFile[0].name;
      const format = fileName.substr(fileName.lastIndexOf('.') + 1);
      if (['xlsx', 'json'].includes(format)) {
        setFile(newFile[0]);
      }
    }
  };

  React.useEffect(() => {
    if (file) {
      uploadFile(file);
      setFile(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <div>
      <div>
        <Button
          aria-controls="export-menu"
          aria-haspopup="true"
          color="primary"
          onClick={handleOpenUpload}
          startIcon={<ImportIcon />}
          className={classes.topButton}
        >
          Розподіл
        </Button>
      </div>
      <Dialog
        open={upload}
        onClose={handleCloseUpload}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        classes={{ paper: classes.dialog }}
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title" onClose={handleCloseUpload}>
          Завантажити файл
        </DialogTitle>
        <DialogContent className={classes.content}>
          <div onDrop={handleDrop} onDragOver={(event) => event.preventDefault()} className={classes.droparea}>
            <p className={classes.dropinfo}>Перетягніть сюди файл, або натисніть, щоб вибрати потрібні файли</p>
            <input
              accept=".xlsx,.json"
              className={classes.input}
              id="contained-button-file"
              type="file"
              onChange={handleFileChange}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="contained-button-file" className={classes.inputLabel}>
              <Button variant="contained" component="span" className={classes.uploadBtn}>
                <span className={classes.uploadBtnTxt}>ЗАВАНТАЖИТИ ДОКУМЕНТ</span>
              </Button>
            </label>
            <p className={classes.drophelp}>Можливі формати документу .xlsx, .jsоn</p>
            <p className={classes.updateMsg}>УВАГА! Документ замінить усі дані в таблиці РОЗПОДІЛ</p>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MenuImport;

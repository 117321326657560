import { styled } from '@material-ui/core';

export const tableHeadCells = [
  { label: 'Номер акту', sortBy: 'c_act' },
  { label: 'Номер вантажу', sortBy: 'i_cargo' },
  { label: 'VIN-код', sortBy: 'c_vin' },
  { label: 'Ім’я набувача', sortBy: 'c_pib' },
  { label: 'Статус', sortBy: 'i_state' },
];

export const getStatusTextAndClass = (i_state) => {
  let text = '';
  let valueClass = '';
  switch (i_state) {
    case 0:
      text = 'Створено';
      valueClass = 'state__grey';
      break;
    case 10:
      text = 'Підписано';
      valueClass = 'state__blue';
      break;
    case 30:
      text = 'Подано';
      valueClass = 'state__purple';
      break;
    case 50:
      text = 'Зареєстровано';
      valueClass = 'state__green';
      break;
    case 90:
      text = 'Відхілено';
      valueClass = 'state__red';
      break;
    case 35:
      text = 'На розгляді';
      valueClass = 'state__yellow';
      break;
    case 40:
      text = 'Заблоковано';
      valueClass = 'state__grey';
      break;
    default:
      text = '-';
      valueClass = '';
  }

  return { text, valueClass };
};

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ReportTables from './report/ReportTables';
import Distribution from './report/Distribution';
import ReportUpload from './report/ReportUpload';
import ReportSignature from './report/ReportSignature';
import { setReportTab } from '../../../redux/actions/report.action';
import { getMonthYearNameUk } from './report/reportHelpers/calendar';
import { ReactComponent as BackIcon } from './report/reportHelpers/assets/BackIcon.svg';
import CustomTooltip from './report/reportHelpers/CustomTooltip';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`report-tabpanel-${index}`}
      aria-labelledby={`report-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && (
        <Box style={{ padding: 0 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `report-tab-${index}`,
    'aria-controls': `report-tabpanel-${index}`,
  };
}

const getReportMonth = (strDate) => {
  if (strDate) {
    const [day, month, year] = strDate.split('.');
    return `за ${getMonthYearNameUk(+month - 1, +year)}`;
  }
  return '';
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  bar: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    maxWidth: 'fit-content',
  },
  tabs: {
    padding: '0 16px',
    borderRadius: '6px',
    maxWidth: 'fit-content',
  },
  container: {
    display: 'flex',
    gap: '16px',
    overflow: 'auto',
    flexWrap: 'nowrap',
  },
  indicator: {
    borderBottom: '2px solid #141414',
    background: 'transparent',
  },
  tab: {
    color: '#14141499',
    padding: '8px 0',
    fontFamily: 'e-Ukraine',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'left',
    width: 'auto',
    minWidth: 'fit-content',
  },
  selected: {
    color: '#141414',
  },
  panel: {
    padding: 0,
  },
  monthData: {
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '32px',
    textAlign: 'left',
  },
}));

const CreateReport = (props) => {
  const classes = useStyles();

  const [currentDate, setCurrentDate] = React.useState();
  const [minDate, setMinDate] = React.useState();

  const curr_date = useSelector((state) => state.report.params.curr_date);
  const min_date = useSelector((state) => state.report.params.min_date);
  const reportTab = useSelector((state) => state.report.reportTab);
  const dispatch = useDispatch();

  const dateToObject = (strDate) => {
    if (strDate) {
      const [day, month, year] = strDate.split('.');
      return new Date(+year, +month - 1, +day);
    }

    return null;
  };

  React.useEffect(() => {
    setCurrentDate(dateToObject(curr_date));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curr_date]);

  React.useEffect(() => {
    setMinDate(dateToObject(min_date));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min_date]);

  const toggle = (tab) => {
    dispatch(setReportTab(tab));
  };

  const handleChange = (event, newValue) => {
    dispatch(setReportTab(newValue));
  };

  return (
    <>
      <div className={classes.root}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '16px' }}>
          <CustomTooltip title="До таблиці Звітів" placement="bottom">
            <IconButton onClick={() => props.history.push('/report_description')}>
              <BackIcon />
            </IconButton>
          </CustomTooltip>
          <span className={classes.monthData}>{`Звіт ${getReportMonth(curr_date)}`}</span>
        </div>
        <AppBar position="static" className={classes.bar} elevation={0}>
          <Tabs
            classes={{ root: classes.tabs, indicator: classes.indicator, flexContainer: classes.container }}
            variant="standard"
            value={reportTab}
            onChange={handleChange}
            aria-label="report tabs"
          >
            <Tab
              classes={{ root: classes.tab, selected: classes.selected }}
              label="ЗВЕДЕНА ТАБЛИЦЯ"
              {...a11yProps(0)}
            />
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Tab classes={{ root: classes.tab, selected: classes.selected }} label="РОЗПОДІЛ" {...a11yProps(1)} />
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Tab classes={{ root: classes.tab, selected: classes.selected }} label="ДОКУМЕНТИ" {...a11yProps(2)} />
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Tab classes={{ root: classes.tab, selected: classes.selected }} label="ПІДПИС" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        <TabPanel value={reportTab} index={0}>
          <ReportTables
            toggle={toggle}
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
            minDate={minDate}
            setMinDate={setMinDate}
          />
        </TabPanel>
        <TabPanel value={reportTab} index={1}>
          <Distribution currentDate={currentDate} />
        </TabPanel>
        <TabPanel value={reportTab} index={2}>
          <ReportUpload />
        </TabPanel>
        <TabPanel value={reportTab} index={3}>
          <ReportSignature />
        </TabPanel>
      </div>
    </>
  );
};

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default CreateReport;

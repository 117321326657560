// packages
import React from 'react';
import { withRouter } from 'react-router';
import { useSelector, useDispatch, connect } from 'react-redux';
import Swal from 'sweetalert2';
// reactstrap
import { UncontrolledTooltip } from 'reactstrap';
// MUI
import IconButton from '@material-ui/core/IconButton';
import RecipientIcon from '@material-ui/icons/TransferWithinAStation';
import OrderIcon from '@material-ui/icons/Compare';
import CloudDoneSharp from '@material-ui/icons/CloudDoneSharp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ReportIcon from '@material-ui/icons/ListAlt';
import HistoryIcon from '@material-ui/icons/AssignmentTurnedIn';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DocIcon from '@material-ui/icons/Assignment';
import LawIcon from '@material-ui/icons/AccountBalance';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';
import AccountBalance from '@material-ui/icons/AccountBalance';
import { viewDeclaration, getReportByCargo, copyAppCargo, getInventoryPDF } from '../../../api/api';
import { cargoDataPdf, clearCargo, getCargoByID } from '../../../redux/actions/cargo.action';

import getPage from '../RegisterRecipients/RegistrationFormsRecipient/getPDFfile';

import { setReportParams } from '../../../redux/actions/report.action';
import './CustomPopUp.css';

const styles = {
  root: {
    fontSize: '1rem',
    borderRadius: '0px',
  },
};

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const Controls = ({
  history,
  handleNewState,
  viewOrder,
  viewProtocol,
  fetchReports,
  changeRecipientStatement,
  cargo,
  clearCargo,
  getCargoByID,
  user,
}) => {
  const {
    cargo: {
      cargoApplication: { i_cargo: cargoId },
    },
    declaration,
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const downloadDeclaration = () => {
    viewDeclaration(cargoId)
      .then((res) => {
        const { data } = res;
        return getPage(data);
      })
      .then((file) => {
        file.open();
      })
      .catch((err) => {});
  };

  async function orederView() {
    try {
      const response = await getInventoryPDF(cargoId);
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      // link.setAttribute('download', `${data[index].c_name}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {}
  }

  async function getCargoPdf() {
    try {
      const response = await getReportByCargo(cargoId);
      dispatch(cargoDataPdf(response.data.general[0].i_recip));
      // this.props.cargoDataPdf(response.data.general[0].i_recip);
    } catch (error) {}
  }
  const fetchReportParams = async () => {
    try {
      const res = await getReportByCargo(cargoId);
      dispatch(setReportParams(res.data.general[0]));
    } catch (err) {}
  };

  const handleCopyCargo = () => {
    swalWithBootstrapButtons
      .fire({
        text: 'Створити копію цього вантажа з новим унікальним кодом?',
        showCancelButton: true,
        confirmButtonText: 'Так, створити копію',
        cancelButtonText: 'Відміна',
        customClass: {
          confirmButton: 'btn-custom btn-black confirm-button-class',
          cancelButton: 'btn-custom btn-black cancel-button-class',
          popup: 'custom-popup-class',
          title: 'custom-title-class',
          content: 'custom-content-class',
          actions: 'custom-actions-class',
        },
      })
      .then(async (result) => {
        if (result.value) {
          try {
            const res = await copyAppCargo(cargoId);
            const { pi_new_cargo } = res.data;
            if (pi_new_cargo) {
              await clearCargo();
              await getCargoByID(pi_new_cargo);
              history.push('/create-delivery/edit');
            }
          } catch (err) {
            // console.log(err);
          }
        }
      });
  };

  return (
    <div>
      <div>Функції:</div>

      <UncontrolledTooltip placement="top" target="changeOrderCard">
        Внесення змін
      </UncontrolledTooltip>

      <IconButton
        aria-label="Сhange order"
        id="changeOrderCard"
        color="primary"
        onClick={handleNewState}
        disabled={cargo.disabled_changes == 1 || user.l_member == 1}
      >
        <EditIcon />
      </IconButton>

      {/* <UncontrolledTooltip placement="top" target="changeRecipipentCard">
        Заява на зміну отримувача
      </UncontrolledTooltip>

      <IconButton
        id="changeRecipipentCard"
        aria-label="Сhange recipient"
        color="primary"
        onClick={() => changeRecipientStatement()}
      >
        <RecipientIcon />
      </IconButton> */}

      {/* <UncontrolledTooltip placement="top" target="reportCard">
        Звіт
      </UncontrolledTooltip>

      <IconButton
        id="reportCard"
        aria-label="Report"
        color="primary"
        onClick={() => {
          getCargoPdf();
          fetchReportParams();
          history.push('/create-report');
        }}
      >
        <HistoryIcon />
      </IconButton> */}

      <UncontrolledTooltip placement="top" target="inventoryCard">
        Інвентарна відомість
      </UncontrolledTooltip>

      <IconButton
        id="inventoryCard"
        aria-label="Inventory"
        color="primary"
        disabled={cargo.disabled_inventory == 1 || user.l_member == 1}
        onClick={() => {
          history.push('/inventory');
        }}
      >
        <AccountBalance />
      </IconButton>

      {/* date customs */}
      <UncontrolledTooltip placement="top" target="customsDate">
        Завантаження декларації
      </UncontrolledTooltip>

      <IconButton
        id="customsDate"
        aria-label="customs"
        color="primary"
        disabled={cargo.disabled_upload == 1 || user.l_member == 1}
        onClick={() => {
          history.push('/customs-date');
        }}
      >
        <CloudDoneSharp />
      </IconButton>

      <UncontrolledTooltip placement="top" target="copyCargo">
        Копія вантажа
      </UncontrolledTooltip>

      <IconButton
        id="copyCargo"
        aria-label="copycargo"
        color="primary"
        disabled={user.l_member == 1}
        onClick={handleCopyCargo}
      >
        <FileCopyIcon />
      </IconButton>

      <div>Перегляд детальної інформації:</div>

      {/* <UncontrolledTooltip placement="top" target="orderCard">
        Наказ
      </UncontrolledTooltip>

      <IconButton id="orderCard" aria-label="order" color="primary" onClick={
       ()=>{history.push(`/cargo-order`)}
      }>
          <LawIcon />
      </IconButton> */}

      {/* <UncontrolledTooltip placement="top" target="protocolCard">
      Наказ та Протокол
      </UncontrolledTooltip>
      
      <IconButton id="protocolCard" aria-label="protocol" color="primary" onClick={
       ()=>{history.push(`/cargo-protokol`)}
      }>
         <ReportIcon />
      </IconButton> */}

      <UncontrolledTooltip placement="top" target="protocolCard">
        Декларація
      </UncontrolledTooltip>

      <IconButton id="protocolCard" aria-label="protocol" color="primary" onClick={downloadDeclaration}>
        <ReportIcon />
      </IconButton>

      <UncontrolledTooltip placement="top" target="documentCard">
        Документи по гуманітарній допомозі
      </UncontrolledTooltip>

      <IconButton
        id="documentCard"
        aria-label="document"
        color="primary"
        onClick={() => {
          history.push(`/view-all-documents-cargo`);
        }}
      >
        <DocIcon />
      </IconButton>

      <UncontrolledTooltip placement="top" target="inventoryPDF">
        Інвентарна відомість
      </UncontrolledTooltip>
      <IconButton
        id="inventoryPDF"
        aria-label="Inventory"
        color="primary"
        disabled={cargo.disabled_pdf_inv == 1}
        onClick={orederView}
      >
        <AccountBalance />
      </IconButton>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cargo: state.cargo.cargoApplication,
  dataPDF: state.dataPDF,
  user: state.auth.user,
});

export default withRouter(
  connect(mapStateToProps, {
    cargoDataPdf,
    clearCargo,
    getCargoByID,
  })(withStyles(styles)(Controls))
);

//packsges
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { Button } from 'reactstrap';
import Checkbox from '@material-ui/core/Checkbox';
import Swal from 'sweetalert2';
import matchSorter, { rankings } from 'match-sorter';
import {
  getNewMeetingInfo,
  saveCurrentApplicationState,
  saveAllApplicationState,
  meetingStartApi,
} from '../../../api/api';
import {
  tableChange,
  select,
  check,
  clearSelect,
  getApplicationsForCommissionAction,
  getApplicationInfo,
} from '../../../redux/actions/commissionCreate.action';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const checkedApplication = (
  e,
  isSelected, // true/false - checkbox
  row, // react-table row
  tabledata, // redux table data for react-table
  tableChange, //  action for renew table data after cheking
  select, //  action for saving in redux cheking row id
  check, // action for change redux-flag when all application is checked (true)
  selected,
  getApplicationsForCommissionAction,
  getApplicationInfo, // redux state with selected row id
  loadingTrue,
  loadingFalse
) => {
  loadingTrue();
  const rows = [...tabledata]; //all table data from redux state
  rows[row.index][row.column.id] = isSelected ? 1 : 0; //depends on checkBox state (true/false)
  tableChange(rows); //change redux-state table data

  let selection = [...selected]; ///array with selected application id
  const key = row.original.id;
  const keyIndex = selection.indexOf(key);
  if (keyIndex >= 0) {
    selection = [...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)];
  } else {
    selection.push(key);
  }
  select(selection);

  if (selection.length === tabledata.length) {
    check(true);
  } else {
    check(false);
  }

  saveCurrentApplicationState(row.original.meeting_id, row.original.apl_id, row.original.checked)
    .then((response) => {
      getApplicationInfo(row.original.meeting_id);
      loadingFalse();
      // getApplicationsForCommissionAction(response.data); //for new table
    })
    .catch((error) => {
      loadingFalse();
      // if (error.response && error.response.status === 400) {
      //   swalWithBootstrapButtons.fire({
      //     title: 'Помилка',
      //     text: error.response.data,
      //     type: 'error',
      //   });
      // } else {
      //   swalWithBootstrapButtons.fire({
      //     title: 'Помилка серверу',
      //     type: 'error',
      //   });
      // }
    });
};

const checkedAllApplication = (
  e,
  isSelected, // true/false - checkbox
  select, //  action for saving in redux cheking row id
  tabledata, // redux table data for react-table
  tableChange, // action for renew table data after cheking
  check, //action for change redux-flag when all application is checked (true)
  allSelected, //redux-flag when all application is checked (true)
  getApplicationsForCommissionAction,
  getApplicationInfo,
  loadingTrue,
  loadingFalse
) => {
  loadingTrue();
  const rows = [...tabledata];

  rows.forEach((item) => {
    if (isSelected) {
      item.checked = 1;
    } else {
      item.checked = 0;
    }
  });
  tableChange(rows);

  const selectAll = isSelected ? true : false; //
  const selection = [];
  if (selectAll) {
    tabledata.forEach((item) => {
      selection.push(item.apl_id);
    });
  }
  select(selection);

  const allApplicationsSelected = rows.every((item) => item.checked === 1);
  check(allApplicationsSelected); // change flag true/false when all is selected

  const checkedAll = allSelected ? 0 : 1;

  saveAllApplicationState(tabledata[0].meeting_id, checkedAll)
    .then((response) => {
      // getApplicationsForCommissionAction(response.data); //for new table

      getApplicationInfo(tabledata[0].meeting_id);
      loadingFalse();
    })
    .catch((error) => {
      loadingFalse();
      // if (error.response && error.response.status === 400) {
      //   swalWithBootstrapButtons.fire({
      //     title: 'Помилка',
      //     text: error.response.data,
      //     type: 'error',
      //   });
      // } else {
      //   swalWithBootstrapButtons.fire({
      //     title: 'Помилка серверу',
      //     type: 'error',
      //   });
      // }
    });
};

class CreateCommissionMeeting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openTable: false,
      loading: false,
    };
  }

  loadingTrue = () => {
    this.setState({ loading: true });
  };
  loadingFalse = () => {
    this.setState({ loading: false });
  };

  meetingStart = () => {
    meetingStartApi(this.props.tabledata[0].meeting_id, this.props.user.id)
      .then((response) => {
        this.props.history.push('/commission-meeting-personal');
        swalWithBootstrapButtons.fire({
          title: 'Засідання розпочате!',
          type: 'success',
        });
      })
      .catch((error) => {
        // if (error.response && error.response.status === 400) {
        //   swalWithBootstrapButtons.fire({
        //     title: 'Помилка',
        //     text: error.response.data,
        //     type: 'error',
        //   });
        // } else {
        //   swalWithBootstrapButtons.fire({
        //     title: 'Помилка серверу',
        //     type: 'error',
        //   });
        // }
      });
  };

  componentDidMount() {
    if (this.props.location.pathname === '/create-сommission-meeting') {
      this.setState({ loading: true });
      getNewMeetingInfo(this.props.user.id)
        .then((response) => {
          if (response.data.length !== 0) {
            this.props.getApplicationInfo(response.data[0].meeting_id);
          }
          if (response.data.length === 0) {
            swalWithBootstrapButtons
              .fire({
                title: 'Відсутні заяви для розгляду!',
                type: 'error',
              })
              .then((result) => {
                if (result.value) {
                  this.props.history.goBack();
                }
              });
          }

          this.setState({ openTable: true, loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
          this.props.history.goBack();
          // if (error.response && error.response.status === 400) {
          //   swalWithBootstrapButtons.fire({
          //     title: 'Помилка',
          //     text: error.response.data,
          //     type: 'error',
          //   })
          //   .then(result => {
          //     if (result.value) {
          //       this.props.history.goBack()
          //     }
          //   });
          // } else {
          //   swalWithBootstrapButtons.fire({
          //     title: 'Помилка серверу',
          //     type: 'error',
          //   })
          //   .then(result => {
          //     if (result.value) {
          //       this.props.history.goBack()
          //     }
          //   });
          // }
        });
    }
  }

  render() {
    return (
      <section className="createCommissionMeeting">
        {(this.state.openTable && this.props.tabledata.length > 0) ||
        (this.props.location.pathname === '/edit-сommission-meeting' && this.props.tabledata.length > 0) ? (
          <div>
            <ReactTable
              loading={this.state.loading}
              sortable={false}
              filterable={true}
              data={this.props.tabledata}
              style={{ fontSize: '0.85rem', textAlign: 'center', overflowWrap: 'break-word' }}
              defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
              columns={[
                {
                  Header: '№ заяви в реєстрі',
                  accessor: 'apl_numb',
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value.toUpperCase(), {
                      keys: [{ threshold: rankings.WORD_STARTS_WITH, key: ['apl_numb'] }],
                    }),
                  filterAll: true,
                },
                {
                  Header: (
                    <div>
                      Тип
                      <br />
                      заяви
                    </div>
                  ),
                  style: { whiteSpace: 'unset', textAlign: 'left' },
                  accessor: 'applicationtype',
                  filterable: false,
                },
                {
                  Header: (
                    <div>
                      Назва
                      <br />
                      отримувача
                    </div>
                  ),
                  accessor: 'c_orgname',
                  filterable: true,
                  style: { whiteSpace: 'unset', textAlign: 'left' },
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value.toUpperCase(), {
                      keys: [{ threshold: rankings.WORD_STARTS_WITH, key: ['c_orgname'] }],
                    }),
                  filterAll: true,
                },
                {
                  Header: (
                    <div>
                      Код
                      <br />
                      ЄДРПОУ
                    </div>
                  ),
                  accessor: 'n_edrpou',
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value.toUpperCase(), {
                      keys: [{ threshold: rankings.WORD_STARTS_WITH, key: ['n_edrpou'] }],
                    }),
                  filterAll: true,
                },
                {
                  Header: (
                    <div>
                      Адреса
                      <br />
                      отримувача
                    </div>
                  ),
                  style: { whiteSpace: 'unset', textAlign: 'left' },
                  accessor: 'adress',
                  filterable: false,
                },
                {
                  Header: (row) => (
                    <div>
                      <strong>Обрати/Обрати всі </strong>
                      <br />
                      <Checkbox
                        checked={this.props.allSelected ? true : false}
                        onChange={(e, isSelected) =>
                          checkedAllApplication(
                            e,
                            isSelected,
                            this.props.select,
                            this.props.tabledata,
                            this.props.tableChange,
                            this.props.check,
                            this.props.allSelected,
                            this.props.getApplicationsForCommissionAction,
                            this.props.getApplicationInfo,
                            this.loadingTrue,
                            this.loadingFalse
                          )
                        }
                        value="allSelected"
                        color="default"
                        style={{ padding: '0px' }}
                      />
                    </div>
                  ),

                  accessor: 'checked',
                  sortable: false,
                  filterable: false,
                  Cell: (row) => (
                    <div>
                      {/* {row.original.checked} */}
                      <Checkbox
                        value="checked"
                        checked={row.original.checked === 1 ? true : false}
                        onChange={(e, isSelected) =>
                          checkedApplication(
                            e,
                            isSelected,
                            row,
                            this.props.tabledata,
                            this.props.tableChange,
                            this.props.select,
                            this.props.check,
                            this.props.selected,
                            this.props.getApplicationsForCommissionAction,
                            this.props.getApplicationInfo,
                            this.loadingTrue,
                            this.loadingFalse
                          )
                        }
                        color="primary"
                      />
                    </div>
                  ),
                },
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              noDataText="Інформація відсутня!"
              className="-striped -highlight"
              previousText="Попередня"
              nextText="Наступна"
              pageText="Сторінка"
              ofText="з"
              rowsText="рядків"
              loadingText="Збереження даних..."
            />

            <div className="mt-4">
              <Button onClick={this.meetingStart} color="primary" className="pull-right">
                <i className="fa fa-plus mr-3" />
                Розпочати засідання
              </Button>
            </div>
            <div className="w-100" />
            <div className="text-left" />
          </div>
        ) : (
          ''
        )}

        {/* <Button
          onClick={() => this.props.history.push('/commission-meeting-personal')}
          color="primary"
          className=" btn btn-primary btn-sm"
        >
          Вихід
        </Button> */}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tabledata: state.commissionCreate.tabledata,
    selected: state.commissionCreate.selectedApplicationsForCommission,
    allSelected: state.commissionCreate.allSelectedApplications,
    user: state.auth.user,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    tableChange,
    select,
    check,
    clearSelect,
    getApplicationsForCommissionAction,
    getApplicationInfo,
  })(CreateCommissionMeeting)
);

import React from 'react';
import { Paper, List, ListItem, ListItemText, Typography, Grid } from '@material-ui/core';

const RecipientComponent = ({ recipient }) => (
  <Grid container direction="column">
    <Grid item>
      <Typography variant="subtitle2" component="h2" gutterBottom>
        Дані отримувача
      </Typography>
    </Grid>
    <Grid container item>
      {recipient.map((entity, index) => (
        <Grid container item direction="column" spacing={1}>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              Назва організації
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {entity.org_name || '-'}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body2" color="textSecondary">
              ПІБ уповноваженої особи
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {entity.name || '-'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              E-mail організації
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {entity.email || '-'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              Телефон
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {entity.phone || '-'}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  </Grid>
);

export default RecipientComponent;

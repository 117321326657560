import {
  SET_REPORT_DATE,
  SET_REPORT_PARAMS,
  SET_REPORT_ID,
  SET_REPORT_CARGO_LIST,
  SET_REPORT_AIDTYPE,
  SET_REPORT_ACQUIRER,
  SET_REPORT_TAB,
  // eslint-disable-next-line import/no-duplicates
} from './action-types';

import {
  ADD_BALANCE_ITEM,
  SET_BALANCE_INPUT,
  SET_BALANCE_ARRAY,
  CLEAR_BALANCE_CURRENT,
  REMOVE_BALANCE_ITEM,
  SET_BALANCE_CURRENT,
  // eslint-disable-next-line import/no-duplicates
} from './action-types';

import {
  ADD_DISTRIBUTION_ITEM,
  SET_DISTRIBUTION_INPUT,
  SET_DISTRIBUTION_ARRAY,
  CLEAR_DISTRIBUTION_CURRENT,
  REMOVE_DISTRIBUTION_ITEM,
  SET_DISTRIBUTION_CURRENT,
  SET_DISTRIBUTION_CARDS,
  SET_DISTRIBUTION_SAVER,
  // eslint-disable-next-line import/no-duplicates
} from './action-types';

export const setReportTab = (tab) => ({
  type: SET_REPORT_TAB,
  payload: tab,
});

export const setDistributionSaver = (val) => ({
  type: SET_DISTRIBUTION_SAVER,
  payload: val,
});

// report params
export const setReportParams = (params) => ({
  type: SET_REPORT_PARAMS,
  payload: params,
});

export const setReportId = (id) => ({
  type: SET_REPORT_ID,
  payload: id,
});

export const setReportCargoList = (cargo) => ({
  type: SET_REPORT_CARGO_LIST,
  payload: cargo,
});

export const setReportAidtype = (aidtype) => ({
  type: SET_REPORT_AIDTYPE,
  payload: aidtype,
});

export const setReportAcquirer = (acquirer) => ({
  type: SET_REPORT_ACQUIRER,
  payload: acquirer,
});

export const setReportDate = (date) => ({
  type: SET_REPORT_DATE,
  payload: date,
});

// balance
export const setBalanceInput = (name, value) => ({
  type: SET_BALANCE_INPUT,
  payload: { name, value },
});

export const addBalanceItem = () => ({
  type: ADD_BALANCE_ITEM,
});

export const setBalanceArray = (data) => ({
  type: SET_BALANCE_ARRAY,
  payload: data,
});

export const clearBalanceCurrent = () => ({
  type: CLEAR_BALANCE_CURRENT,
});

export const removeBalanceItem = (index) => ({
  type: REMOVE_BALANCE_ITEM,
  payload: index,
});

export const setBalanceCurrent = (item) => ({
  type: SET_BALANCE_CURRENT,
  payload: item,
});

// distribution
export const setDistributionInput = (name, value) => ({
  type: SET_DISTRIBUTION_INPUT,
  payload: { name, value },
});

export const addDistributionItem = (typeNum) => ({
  type: ADD_DISTRIBUTION_ITEM,
  payload: typeNum,
});

export const setDistributionArray = (data) => ({
  type: SET_DISTRIBUTION_ARRAY,
  payload: data,
});

export const clearDistributionCurrent = () => ({
  type: CLEAR_DISTRIBUTION_CURRENT,
});

export const removeDistributionItem = (index) => ({
  type: REMOVE_DISTRIBUTION_ITEM,
  payload: index,
});

export const setDistributionCurrent = (item) => ({
  type: SET_DISTRIBUTION_CURRENT,
  payload: item,
});

export const setDistributionCards = (data) => ({
  type: SET_DISTRIBUTION_CARDS,
  payload: data,
});

import React from 'react';

const DialogViewPdf = ({ pdf }) => {
  React.useEffect(() => {
    if (pdf) {
      console.log(pdf);
      console.log(document.getElementById('pdfV'));
      pdf.getDataUrl((outDoc) => {
        document.getElementById('pdfV').src = outDoc;
      });
    }
  }, [pdf]);

  return (
    <div>
      <iframe title="aha" id="pdfV" style={{ width: '100%', height: 700 }} />
    </div>
  );
};

export default DialogViewPdf;

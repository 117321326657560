import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon, withStyles } from '@material-ui/core';
import startExcel from './startExcel';

const styles = (theme) => ({
  button: {
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
  },
});

const RegisterExcel = ({ recipientTypes, dataTable, classes }) => {
  const prepareData = (data) => {
    return data.map((item) => {
      const { recip_number, i_aidform, i_country, donor_name, p_orgname, p_edrpou, p_date, c_name } = item;

      return {
        recip_number,
        i_aidform,
        i_country,
        donor_name,
        p_orgname,
        p_edrpou,
        p_date,
        c_name,
      };
    });
  };

  const downloadExcel = () => {
    const data = prepareData(dataTable);
    startExcel(data);
  };

  return (
    <>
      <Button
        type="button"
        variant="contained"
        color="primary"
        onClick={downloadExcel}
        disabled={dataTable.length === 0}
        className={classes.button}
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
      >
        <Icon className="fa fa-file-excel mr-2" /> Excel
      </Button>
    </>
  );
};

const mapStateToProps = (state) => ({
  recipientTypes: state.catalogs.recipientTypes,
});

export default connect(mapStateToProps, null)(withStyles(styles)(RegisterExcel));

import React, { Component } from 'react';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import vector from '../../../../assets/images/Vector.png';
import rules from '../../../../vibe/components/utilities/MainUtils/rules';
import { Loader } from '../../../../vibe';
import isEmpty from '../../../../vibe/components/utilities/MainUtils/is-empty';

const styles = (theme) => ({
  test_button_loading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 70px',
    gap: '4px',
    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',
    cursor: 'pointer',
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '180px',
      padding: '0',
    },
  },
  test_button_loading_text: {
    fontFamily: 'e-Ukraine Head',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    '&:hover': {
      color: '#141414',
    },
  },
  test_button_loading_img: {
    marginLeft: '15px',
  },
});

class DocumentsLoadingRecipient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: '',
      fileSize: '',
      fileNameUser: '',
      doctype1: null,
      submitted: false,
      p_docname: '',
      p_dt_numb: '',
    };
    this.fileInput = React.createRef();
  }

  handleSimpleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleInputChange = (e) => {
    if (e.target.value === '') {
      this.setState({
        fileNameUser: e.target.value,
      });
    } else {
      rules.orgName.test(e.target.value.toUpperCase()) &&
        this.setState({
          fileNameUser: e.target.value.toUpperCase(),
        });
    }
  };

  // for document select
  handleSelectChange = (e, value) => {
    this.setState({
      doctype1: value,
    });
  };

  handleChangeFile(e) {
    this.setState({ fileName: '' });
    e.target.files[0] && this.setState({ fileName: e.target.files[0].name, fileSize: e.target.files[0].size });
  }

  deleteDocument = (id) => {
    this.props.deleteRecipientDocument(id);
  };

  submitDocument = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
    const { doctype1, p_docname, p_dt_numb } = this.state;
    if (this.fileInput.current.files[0] && doctype1?.value && p_docname && p_dt_numb) {
      this.setState({ submitted: false });

      let p_applicationID;
      {
        this.props.p_new_application_id === '' || this.props.p_new_application_id === undefined
          ? (p_applicationID = this.props.applicationId)
          : (p_applicationID = this.props.p_new_application_id);
      }

      const info = {
        id: 0,
        p_application: p_applicationID,
        // p_application: 101,
        // p_doctype: 'Новий документ',
        p_doctype: this.state.doctype1?.value || 0,
      };
      const { p_docname, p_dt_numb } = this.state;
      const data = new FormData();
      data.append('file', this.fileInput.current.files[0]);
      data.append('p_docname', p_docname);
      data.append('p_dt_numb', p_dt_numb);

      // eslint-disable-next-line react/destructuring-assignment
      this.props.saveRecipientDocument(info, data);

      // clear fields
      this.fileInput.current.value = '';
      this.setState({
        fileName: '',
        fileSize: '',
        doctype1: null,
        p_docname: '',
        p_dt_numb: '',
      });
    } else if (!this.fileInput.current.files) {
      this.setState({ validationText: 'Ви не додали файл' });
    }
  };

  render() {
    const { submitted, validationText } = this.state;
    const { classes } = this.props;
    let documents = null;
    if (!isEmpty(this.props.documents) && this.props.documents.length !== 0) {
      documents = this.props.documents.map((item, i) => {
        return (
          <tr key={item.id}>
            <td>{i + 1}</td>
            <td>{this.props.catalog.documentTypes1.find(({ value }) => value === item.n_doctype)?.label || ''}</td>
            <td>{item.c_docname}</td>
            <td>{item.c_dt_numb}</td>
            <td>
              <a href={`/aid/file/${item.c_filename}`} target="blank">
                {item.c_filename}
              </a>
            </td>
            <td>
              <Button variant="outlined" onClick={() => this.deleteDocument(item.id)}>
                Видалити
              </Button>
            </td>
          </tr>
        );
      });
    } else {
      documents = (
        <tr>
          <td colSpan="6" className="text-center">
            <h4>
              <strong>Не додано жодного документу</strong>
            </h4>
          </td>
        </tr>
      );
    }

    const { doctype1, p_docname, p_dt_numb } = this.state;

    return (
      <div>
        <Card>
          <CardBody>
            <section className="documentsLoadingForm">
              <form onSubmit={this.submitDocument}>
                <div className="row">
                  <div className="col-12">
                    <label htmlFor="" style={{ color: 'darkblue' }}>
                      Завантажуються документи мовою оригіналу та їх переклад українською мовою
                    </label>
                  </div>
                  <div className="col-12 col-sm-12  col-md-4 col-xl-4 mb-2">
                    <UncontrolledTooltip placement="top" target="selectDocumentType1">
                      Оберіть тип документа зі списку.
                    </UncontrolledTooltip>
                    <Autocomplete
                      id="selectDocumentType1"
                      value={
                        // eslint-disable-next-line react/destructuring-assignment
                        this.props.catalog.documentTypes1.filter(
                          ({ value }) => Number(value) === Number(this.state.doctype1?.value)
                        )[0] || null
                      }
                      onChange={this.handleSelectChange}
                      autoHighlight
                      // eslint-disable-next-line react/destructuring-assignment
                      getOptionSelected={(option) => option.value === this.state.doctype1?.value}
                      getOptionLabel={(option) => option.label}
                      options={this.props.catalog.documentTypes1}
                      loading={this.props.loading.CATALOG}
                      renderInput={(params) => <TextField {...params} label="Виберіть тип документу" />}
                    />
                  </div>
                  <div className="col-12 col-sm-12  col-md-4 col-xl-4 mb-2">
                    <TextField
                      label="Детальний опис документу"
                      type="text"
                      name="p_docname"
                      value={this.state.p_docname}
                      onChange={this.handleSimpleChange}
                    />
                  </div>
                  <div className="col-12 col-sm-12  col-md-4 col-xl-4 mb-2">
                    <TextField
                      type="text"
                      name="p_dt_numb"
                      value={this.state.p_dt_numb}
                      onChange={this.handleSimpleChange}
                      label="Номер і дата документу"
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="file-upload-delete" className={classes.test_button_loading}>
                      <div className={classes.test_button_loading_text}>
                        {' '}
                        Обрати файл <img className={classes.test_button_loading_img} src={vector} alt="choose file" />
                      </div>
                    </label>

                    <input
                      id="file-upload-delete"
                      className="upload"
                      type="file"
                      accept=".jpeg,.jpg,.png,.pdf,.p7s"
                      onChange={this.handleChangeFile.bind(this)}
                      ref={this.fileInput}
                    />

                    {submitted && (!this.fileInput.current.files[0] || !doctype1?.value || !p_docname || !p_dt_numb) && (
                      <FormHelperText error id="name-error-text">
                        Поля тип, детальний опис, номер і дата документу та файл обов&apos;язкові
                      </FormHelperText>
                    )}
                  </div>
                  <div className="ml-3 mt-2"> {this.state.fileName}</div>

                  <div className="col-12 mt-3 mb-4">
                    <div style={{ fontWeight: 800, marginBottom: 10 }}>
                      Увага! Розмір документу для завантаження не повинен перевищувати 10 Мб
                    </div>
                    {this.state.fileSize > parseInt(100000000) && (
                      <div className="text-danger">Завантаження не можливе! Розмір файла перевищує 10 мб</div>
                    )}
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={this.state.fileSize > parseInt(100000000)}
                    >
                      Зберегти
                    </Button>
                  </div>
                </div>
              </form>
              {this.props.loading.RECIPIENT_DOCUMENTS_SAVE && <Loader type="spin" />}
              <div className="table-responsive zebra">
                <table>
                  {/* <table className="table table-border "> */}
                  <thead>
                    <tr>
                      <th style={{ width: '5%' }}>№ з/п</th>
                      <th style={{ width: '20%' }}>Назва</th>
                      <th style={{ width: '20%' }}>Детальний опис документу</th>
                      <th style={{ width: '20%' }}>Номер і дата документу</th>
                      <th style={{ width: '20%' }}>Скан документу</th>
                      <th style={{ width: '15%' }}>Керувати</th>
                    </tr>
                  </thead>
                  <tbody>{documents}</tbody>
                </table>
              </div>
            </section>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(DocumentsLoadingRecipient));

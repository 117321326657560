import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@material-ui/core/Modal';
import ApplicationHistory from './ApplicationHistory'
import { makeStyles } from '@material-ui/core/styles';
function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

const useStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: "90%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      outline: 'none',
    },
  }));

function DialogHistoryView({open, handleClose, id, rescipientAppHistory}) {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    return (
        <Modal  open={open}
            onClose={handleClose}>
                <div style={modalStyle} className={classes.paper}>
                    <ApplicationHistory rescipientAppHistory={rescipientAppHistory} handleClose={handleClose}/>
                </div>
        </Modal>
    )
}

DialogHistoryView.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    rescipientAppHistory: PropTypes.array

}

export default DialogHistoryView


import React from 'react';
import * as Feather from 'react-feather';
import NavBadge from './NavBadge';

const NavButtonItem = ({ item, onClick }) => {
  const Icon = item.icon && Feather[item.icon] ? Feather[item.icon] : null;

  return (
    <li className="nav-item">
      <button type="button" activeClassName="active" onClick={onClick}>
        {item.icon && Icon && <Icon className="side-nav-icon" />}
        <span className="nav-item-label">{item.name}</span>
        {item.badge && <NavBadge color={item.badge.variant} text={item.badge.text} />}
      </button>
    </li>
  );
};

export default NavButtonItem;

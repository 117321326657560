import {
  GET_ACTS_REQUEST,
  GET_ACTS_SUCCESS,
  GET_ACTS_FAILURE,
  CREATE_ACT_REQUEST,
  CREATE_ACT_SUCCESS,
  CREATE_ACT_FAILURE,
  SAVE_ACT_REQUEST,
  SAVE_ACT_SUCCESS,
  SAVE_ACT_FAILURE,
  GET_ACT_REQUEST,
  GET_ACT_SUCCESS,
  GET_ACT_FAILURE,
  DELETE_ACT_REQUEST,
  DELETE_ACT_SUCCESS,
  DELETE_ACT_FAILURE,
  DELETE_DOC_ACT_REQUEST,
  DELETE_DOC_ACT_SUCCESS,
  DELETE_DOC_ACT_FAILURE,
  DELETE_SOLDIER_ACT_REQUEST,
  DELETE_SOLDIER_ACT_SUCCESS,
  DELETE_SOLDIER_ACT_FAILURE,
  GET_DOCS_BY_ACT_REQUEST,
  GET_DOCS_BY_ACT_SUCCESS,
  GET_DOCS_BY_ACT_FAILURE,
  GET_SOLDIERS_BY_ACT_REQUEST,
  GET_SOLDIERS_BY_ACT_SUCCESS,
  GET_SOLDIERS_BY_ACT_FAILURE,
  SAVE_DOC_ACT_REQUEST,
  SAVE_DOC_ACT_SUCCESS,
  SAVE_DOC_ACT_FAILURE,
  SAVE_SOLDIER_ACT_REQUEST,
  SAVE_SOLDIER_ACT_SUCCESS,
  SAVE_SOLDIER_ACT_FAILURE,
  SIGN_FILE_ACT_REQUEST,
  SIGN_FILE_ACT_SUCCESS,
  SIGN_FILE_ACT_FAILURE,
  SUBMIT_ACT_SUCCESS,
  SUBMIT_ACT_REQUEST,
  SUBMIT_ACT_FAILURE,
  GET_MILITARY_TYPES_REQUEST,
  GET_MILITARY_TYPES_SUCCESS,
  GET_MILITARY_TYPES_FAILURE,
} from '../actions/action-types';

const initialState = {
  createdActLoading: false,
  createdAct: null,
  createdActError: null,
  deletedActLoading: false,
  deletedAct: null,
  deletedActError: null,
  deletedDocLoading: false,
  deletedDoc: null,
  deletedDocError: null,
  deletedSoldierLoading: false,
  deletedSoldier: null,
  deletedSoldierError: null,
  actLoading: false,
  act: null,
  actError: null,
  actsLoading: false,
  acts: [],
  actsError: null,
  docsLoading: false,
  docs: [],
  docsError: null,
  soldiersLoading: false,
  soldiers: [],
  soldiersError: null,
  militaryTypesLoading: false,
  militaryTypes: [],
  militaryTypesError: null,
  savedActLoading: false,
  savedAct: null,
  savedActError: null,
  savedDocLoading: false,
  savedDoc: null,
  savedDocError: null,
  savedSoldierLoading: false,
  savedSoldier: null,
  savedSoldierError: null,
  signedFileLoading: false,
  signedFile: null,
  signedFileError: null,
  submittedActLoading: false,
  submittedAct: null,
  submittedActError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ACT_REQUEST:
      return {
        ...state,
        createdActLoading: true,
      };
    case CREATE_ACT_SUCCESS:
      return {
        ...state,
        createdActLoading: false,
        createdAct: action.payload,
        createdActError: null,
      };
    case CREATE_ACT_FAILURE:
      return {
        ...state,
        createdActLoading: false,
        createdAct: null,
        createdActError: action.payload,
      };
    case DELETE_ACT_REQUEST:
      return {
        ...state,
        deletedActLoading: true,
      };
    case DELETE_ACT_SUCCESS:
      return {
        ...state,
        deletedActLoading: false,
        deletedAct: action.payload,
        deletedActError: null,
      };
    case DELETE_ACT_FAILURE:
      return {
        ...state,
        deletedActLoading: false,
        deletedAct: null,
        deletedActError: action.payload,
      };
    case DELETE_DOC_ACT_REQUEST:
      return {
        ...state,
        deletedDocLoading: true,
      };
    case DELETE_DOC_ACT_SUCCESS:
      return {
        ...state,
        deletedDocLoading: false,
        deletedDoc: action.payload,
        deletedDocError: null,
      };
    case DELETE_DOC_ACT_FAILURE:
      return {
        ...state,
        deletedDocLoading: false,
        deletedDoc: null,
        deletedDocError: action.payload,
      };
    case DELETE_SOLDIER_ACT_REQUEST:
      return {
        ...state,
        deletedSoldierLoading: true,
      };
    case DELETE_SOLDIER_ACT_SUCCESS:
      return {
        ...state,
        deletedSoldierLoading: false,
        deletedSoldier: action.payload,
        deletedSoldierError: null,
      };
    case DELETE_SOLDIER_ACT_FAILURE:
      return {
        ...state,
        deletedSoldierLoading: false,
        deletedSoldier: null,
        deletedSoldierError: action.payload,
      };
    case GET_ACT_REQUEST:
      return {
        ...state,
        actLoading: true,
      };
    case GET_ACT_SUCCESS:
      return {
        ...state,
        actLoading: false,
        act: action.payload,
        actError: null,
      };
    case GET_ACT_FAILURE:
      return {
        ...state,
        actLoading: false,
        act: null,
        actError: action.payload,
      };
    case GET_ACTS_REQUEST:
      return {
        ...state,
        actsLoading: true,
      };
    case GET_ACTS_SUCCESS:
      return {
        ...state,
        actsLoading: false,
        acts: action.payload,
        actsError: null,
      };
    case GET_ACTS_FAILURE:
      return {
        ...state,
        actsLoading: false,
        acts: [],
        actsError: action.payload,
      };
    case GET_DOCS_BY_ACT_REQUEST:
      return {
        ...state,
        docsLoading: true,
      };
    case GET_DOCS_BY_ACT_SUCCESS:
      return {
        ...state,
        docsLoading: false,
        docs: action.payload,
        docsError: null,
      };
    case GET_DOCS_BY_ACT_FAILURE:
      return {
        ...state,
        docsLoading: false,
        docs: null,
        docsError: action.payload,
      };
    case GET_SOLDIERS_BY_ACT_REQUEST:
      return {
        ...state,
        soldiersLoading: true,
      };
    case GET_SOLDIERS_BY_ACT_SUCCESS:
      return {
        ...state,
        soldiersLoading: false,
        soldiers: action.payload,
        soldiersError: null,
      };
    case GET_SOLDIERS_BY_ACT_FAILURE:
      return {
        ...state,
        soldiersLoading: false,
        soldiers: null,
        soldiersError: action.payload,
      };
    case GET_MILITARY_TYPES_REQUEST:
      return {
        ...state,
        militaryTypesLoading: true,
      };
    case GET_MILITARY_TYPES_SUCCESS:
      return {
        ...state,
        militaryTypesLoading: false,
        militaryTypes: action.payload,
        militaryTypesError: null,
      };
    case GET_MILITARY_TYPES_FAILURE:
      return {
        ...state,
        militaryTypesLoading: false,
        militaryTypes: null,
        militaryTypesError: action.payload,
      };
    case SAVE_ACT_REQUEST:
      return {
        ...state,
        savedActLoading: true,
      };
    case SAVE_ACT_SUCCESS:
      return {
        ...state,
        savedActLoading: false,
        savedAct: action.payload,
        savedActError: null,
      };
    case SAVE_ACT_FAILURE:
      return {
        ...state,
        savedActLoading: false,
        savedAct: null,
        savedActError: action.payload,
      };
    case SAVE_DOC_ACT_REQUEST:
      return {
        ...state,
        savedDocLoading: true,
      };
    case SAVE_DOC_ACT_SUCCESS:
      return {
        ...state,
        savedDocLoading: false,
        savedDoc: action.payload,
        savedDocError: null,
      };
    case SAVE_DOC_ACT_FAILURE:
      return {
        ...state,
        savedDocLoading: false,
        savedDoc: null,
        savedDocError: action.payload,
      };
    case SAVE_SOLDIER_ACT_REQUEST:
      return {
        ...state,
        savedSoldierLoading: true,
      };
    case SAVE_SOLDIER_ACT_SUCCESS:
      return {
        ...state,
        savedSoldierLoading: false,
        savedSoldier: action.payload,
        savedSoldierError: null,
      };
    case SAVE_SOLDIER_ACT_FAILURE:
      return {
        ...state,
        savedSoldierLoading: false,
        savedSoldier: null,
        savedSoldierError: action.payload,
      };
    case SIGN_FILE_ACT_REQUEST:
      return {
        ...state,
        signedFileLoading: true,
      };
    case SIGN_FILE_ACT_SUCCESS:
      return {
        ...state,
        signedFileLoading: false,
        signedFile: action.payload,
        signedFileError: null,
      };
    case SIGN_FILE_ACT_FAILURE:
      return {
        ...state,
        signedFileLoading: false,
        signedFile: null,
        signedFileError: action.payload,
      };
    case SUBMIT_ACT_REQUEST:
      return {
        ...state,
        submittedActLoading: true,
      };
    case SUBMIT_ACT_SUCCESS:
      return {
        ...state,
        submittedActLoading: false,
        submittedAct: action.payload,
        submittedActError: null,
      };
    case SUBMIT_ACT_FAILURE:
      return {
        ...state,
        submittedActLoading: false,
        submittedAct: null,
        submittedActError: action.payload,
      };
    default:
      return state;
  }
};

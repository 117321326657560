import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  test_root_section: {
    // background: '#afc6e6',
  },

  test_section_dowload: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    background: '#ffffff',
    margin: ' 50px auto',
  },

  test_border_dowload: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '60px 30px',
    width: '90%',
    border: '2px dashed #5B5AFF',
    margin: '50px auto',
    [theme.breakpoints.down('sm')]: {
      padding: '60px 10px',
      width: '100%',
    },
  },

  test_button_loading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 70px',
    gap: '4px',
    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '180px',
      padding: '0',
    },
  },
  disabledChanges: {
    background: 'rgb(128, 128, 128)',
    '&:hover': {
      background: 'rgb(128, 128, 128)',
      color: 'white !important',
    },
  },

  test_button_loading_text: {
    fontFamily: 'e-Ukraine Head',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    '&:hover': {
      color: '#141414',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  test_button_loading_img: {
    marginLeft: '15px',
  },

  test_textFile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 0px',
    width: '100%',
    fontFamily: 'e-Ukraine',
    fontStyle: 'normal',
    fontWeight: '200',
    fontSize: '16px',
    lineHeight: '24px',
  },

  test_file_sections: {
    padding: '20px 0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },

  testdowload_file: {
    paddingTop: '20px',
    width: '40%',
  },

  test_commit: {
    width: '40%',
  },

  test_button_save: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 70px',
    gap: '4px',
    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',
    margin: '30px auto',
    fontFamily: 'e-Ukraine Head',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#FFFFFF',
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
    [theme.breakpoints.down('sm')]: {
      width: '260px',
    },
  },

  test_button_save_text: {
    fontFamily: 'e-Ukraine Head',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    '&:hover': {
      color: '#141414',
    },
  },
  button_img: {
    marginLeft: '15px',
  },
  button_sign: {
    marginRight: '30px',
    alignItems: 'center',
    padding: '20px 70px',
    gap: '4px',
    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',

    fontFamily: 'e-Ukraine Head',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#FFFFFF',
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '15px',
      width: '260px',
    },
  },
}));

import React from 'react';
import format from 'date-fns/format';
import { Card, CardBody } from 'reactstrap';
import { connect, useSelector } from 'react-redux';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Button, TextField, Grid, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
// import rules from '../../../../vibe/components/utilities/MainUtils/rules';
// import { withRouter } from 'react-router';
import Swal from 'sweetalert2';
import {
  saveInventoryDoc,
  deleteRecipientDocumentsApi,
  getDocByCargo,
  getInventoryPDF,
  signInventory,
  saveInventoryDates,
} from '../../../../api/api';
import { history } from '../../../../vibe/components/utilities/MainUtils/hist';
// import uploadTable from '../../../../assets/images/download.svg';
import vector from '../../../../assets/images/Vector.png';
import save from '../../../../assets/images/save.png';
import print from '../../../../assets/images/print.png';
import sign from '../../../../assets/images/sign.png';
// import { Loader } from '../../../../vibe';
// import isEmpty from '../../../../vibe/components/utilities/MainUtils/is-empty';
// import { createLoadingSelector, createErrorMessageSelector, clearErrors } from '../../../../redux/actions/selectors';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
});

const useStyles = makeStyles((theme) => ({
  test_root_section: {
    // background: '#afc6e6',
  },

  test_section_dowload: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    background: '#ffffff',
    margin: ' 50px auto',
  },

  test_border_dowload: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '60px 30px',
    width: '90%',
    border: '2px dashed #5B5AFF',
    margin: '50px auto',
    [theme.breakpoints.down('sm')]: {
      padding: '60px 10px',
      width: '100%',
    },
  },

  test_button_loading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 70px',
    gap: '4px',
    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
    },
    [theme.breakpoints.down('sm')]: {
      width: '180px',
      padding: '0',
    },
  },
  disabledChanges: {
    background: 'rgb(128, 128, 128)',
    '&:hover': {
      background: 'rgb(128, 128, 128)',
      color: 'white !important',
    },
  },

  test_button_loading_text: {
    fontFamily: 'e-Ukraine Head',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    '&:hover': {
      color: '#141414',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  test_button_loading_img: {
    marginLeft: '15px',
  },

  test_textFile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 0px',
    width: '100%',
    fontFamily: 'e-Ukraine',
    fontStyle: 'normal',
    fontWeight: '200',
    fontSize: '16px',
    lineHeight: '24px',
  },

  test_file_sections: {
    padding: '20px 0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },

  testdowload_file: {
    paddingTop: '20px',
    width: '40%',
  },

  test_commit: {
    width: '40%',
  },

  test_button_save: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 70px',
    gap: '4px',
    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',
    margin: '30px auto',
    fontFamily: 'e-Ukraine Head',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#FFFFFF',
    textTransform: 'capitalize',
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
    [theme.breakpoints.down('sm')]: {
      width: '260px',
    },
  },

  test_button_save_text: {
    fontFamily: 'e-Ukraine Head',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    '&:hover': {
      color: '#141414',
    },
  },
  button_img: {
    marginLeft: '15px',
  },
  bottomTitle: {
    color: '#000',
    fontFamily: 'e-Ukraine',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '130%',
    marginTop: '25px',
  },
  bottomText: {
    color: '#000',
    fontFamily: 'e-Ukraine',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '130%',
  },
  button_sign: {
    marginRight: '30px',
    alignItems: 'center',
    padding: '20px 70px',
    gap: '4px',
    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',
    textTransform: 'capitalize',

    fontFamily: 'e-Ukraine Head',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#FFFFFF',
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '15px',
      width: '260px',
    },
  },
}));

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const InventoryUpload = ({ disableChanges, privat, setPrivat, startDate, endDate }) => {
  const classes = useStyles();
  const {
    cargo: {
      cargoApplication: { i_cargo },
    },
  } = useSelector((state) => state);

  const [state, setState] = React.useState({
    fileName: '',
    fileSize: '',
    submitted: false,
    table: [],
    comment: '',
    date: null,
    loading: false,
  });
  const fileInput = React.useRef(null);

  const handleChangeComment = (event) => {
    setState({ ...state, comment: event.target.value });
  };

  const handleChangeFileInput = (e) => {
    setState({ ...state, fileName: '' });
    // eslint-disable-next-line no-unused-expressions
    e.target.files[0] &&
      setState({
        ...state,
        fileName: e.target.files[0].name,
        fileSize: e.target.files[0].size,
      });
  };

  const updateTable = async (id) => {
    try {
      const updated = await getDocByCargo(4, id);
      setState({
        ...state,
        table: updated.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const saveDocument = async (data) => {
    try {
      await saveInventoryDoc(data);
      fileInput.current.value = '';
      setState({
        ...state,
        fileName: '',
        fileSize: '',
        comment: '',
        date: null,
      });
      await updateTable(i_cargo);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteDocument = async (id) => {
    try {
      await deleteRecipientDocumentsApi(id);
      await updateTable(i_cargo);
    } catch (err) {
      console.log(err);
    }
  };

  const submitDocument = (e) => {
    e.preventDefault();
    setState({ ...state, submitted: true });

    if (fileInput.current.files[0]) {
      setState({ ...state, submitted: false });
      const { type } = fileInput.current.files[0];
      const { comment } = state;
      const data = new FormData();
      data.append('file', fileInput.current.files[0]);
      data.append('piCargo', i_cargo);
      data.append('extension', type);
      data.append('pcDocName', comment);
      // save document
      saveDocument(data);
    } else if (!fileInput.current.files) {
      setState({ ...state, validationText: 'Ви не додали файл' });
    }
  };

  const updateTableAtStart = async () => {
    await updateTable(i_cargo);
  };

  async function orederView() {
    try {
      const response = await getInventoryPDF(i_cargo);
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      // link.setAttribute('download', `${data[index].c_name}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {}
  }

  async function sing() {
    swalWithBootstrapButtons
      .fire({
        text: 'Перевірте дані! Після підписання дані неможливо буде корегувати!',
        showCancelButton: true,
        confirmButtonText: 'Підписати',
        cancelButtonText: 'Відмовитися',
      })
      .then(async (result) => {
        if (result.value) {
          try {
            const resp = await signInventory(i_cargo);

            swalWithBootstrapButtons
              .fire({
                type: 'success',
                text: 'Інвентарізаційна відомість підписана!',
              })
              .then(async (res) => {
                if (res.value) {
                  try {
                    const response = await getInventoryPDF(i_cargo);
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('target', '_blank');
                    // link.setAttribute('download', `${data[index].c_name}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    history.push(`/view-delivery`);
                  } catch (error) {}
                }
              });
          } catch (error) {
            swalWithBootstrapButtons.fire({
              type: 'error',
              text: error.response.data,
            });
          }
        }
      });
  }

  const saveYesno = (data) => {
    saveInventoryDates(data)
      .then(() => {
        Toast.fire({
          type: 'success',
          title: 'Збережено!',
        });
      })
      .catch((err) => {});
  };

  const handleChangeRadio = (event) => {
    const { value } = event.target;
    setPrivat(value);
    // eslint-disable-next-line no-nested-ternary
    console.log(value === 'yes' ? 1 : value === 'no' ? 0 : '');
    const data = {
      start_date: startDate ? format(startDate, 'dd.MM.yyyy') : '',
      end_date: endDate ? format(endDate, 'dd.MM.yyyy') : '',
      idCargo: i_cargo,
      // eslint-disable-next-line no-nested-ternary
      pl_private: value === 'yes' ? 1 : value === 'no' ? 0 : '',
    };
    saveYesno(data);
  };

  React.useEffect(() => {
    updateTableAtStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let documents = null;
  const { table } = state;
  if (table.length > 0) {
    documents = table.map((item, i) => {
      const { id } = item;
      return (
        <tr key={id}>
          <td>{i + 1}</td>
          <td>{item.d_ins}</td>
          <td>{item.c_docname}</td>
          <td>
            <a href={`/aid/file/${item.c_filename}`} target="blank">
              {item.c_filename}
            </a>
          </td>
          <td>
            <Button variant="outlined" type="button" disabled={disableChanges} onClick={() => deleteDocument(id)}>
              Видалити
            </Button>
          </td>
        </tr>
      );
    });
  } else {
    documents = (
      <tr>
        <td colSpan="5" className="text-center">
          <h4>
            <strong>Не додано жодного документу</strong>
          </h4>
        </td>
      </tr>
    );
  }

  return (
    <>
      <Card className={classes.test_root_section}>
        <CardBody>
          <section className="documentsLoadingForm">
            <p style={{ fontSize: '24px', width: '100%' }}>Завантажити підтверджуючі документи</p>
            <p style={{ fontSize: '16px' }}>У випадку якщо:</p>
            <ul style={{ marginLeft: '30px' }}>
              <li style={{ fontSize: '16px' }}>
                є розбіжності між даними товарів в Декларації і фактично отриманою допомогою.
              </li>
            </ul>
            {/* <section className={classes.section_dowload}> */}

            <form onSubmit={submitDocument} className={classes.test_section_dowload}>
              {/* <p>По Інвентаризаційному опису підтверджуючі документи потрібно завантажувати тільки у тому випадку, якщо у Вас є розбіжності між даними товарів в Декларації і фактично отриманою допомогою. Якщо розбіжностей немає, то непотрібно нічого завантажувати. Також в цьому вікні здійснюється підпис сформованого і перевіреного інвентаризаційного опису.</p> */}
              <div className={classes.test_border_dowload}>
                <label
                  htmlFor="file-upload-cargo"
                  // className={classes.test_button_loading}
                  className={clsx(classes.test_button_loading, {
                    [classes.disabledChanges]: disableChanges,
                  })}
                >
                  <div
                    className={clsx(classes.test_button_loading_text, {
                      [classes.disabledChanges]: disableChanges,
                    })}
                    // className={classes.test_button_loading_text}
                  >
                    {' '}
                    Обрати файл <img className={classes.test_button_loading_img} src={vector} alt="choose file" />
                  </div>
                  {/* </div> */}
                  {/* <div className="text-warning" style={{ fontWeight: 800 }}> */}
                </label>
                <div className={classes.test_textFile}>
                  <div className={classes.test_textFile} style={{ fontWeight: 800 }}>
                    Увага! Розмір документу для завантаження не повинен перевищувати 10 Мб
                  </div>
                  {state.fileSize > parseInt(100000000) && (
                    <div className="text-danger">Завантаження неможливе! Розмір файла перевищує 10 мб</div>
                  )}
                </div>
              </div>
              <div className={classes.test_file_sections}>
                <div className={classes.testdowload_file}>
                  <input
                    id="file-upload-cargo"
                    className="upload"
                    type="file"
                    accept=".jpeg,.jpg,.png,.pdf"
                    disabled={disableChanges}
                    onChange={handleChangeFileInput}
                    ref={fileInput}
                  />
                  {state.submitted && !fileInput.current.files[0] && (
                    <FormHelperText error id="name-error-text">
                      Поле обов'язкове
                    </FormHelperText>
                  )}
                  <div> {state.fileName}</div>
                </div>

                {/* </div> */}

                {/* </div> */}
                {/* <div className="row" style={{ margin: '20px 0 0 0', padding: 0 }}> */}
                {/* <div className="col-12 col-sm-12  col-md-6 col-xl-6 mb-6" style={{ padding: 0 }}> */}

                {state.fileName && (
                  <div className={classes.test_commit}>
                    {/* <label htmlFor="">Коментар: </label> */}
                    <TextField
                      label="Коментар:"
                      type="text"
                      name="comment"
                      value={state.comment}
                      onChange={handleChangeComment}
                      style={{ width: '100%' }}
                    />
                  </div>
                )}
                {/* </div> */}
              </div>
              {/* <div className="col-12 mt-3 mb-4"> */}
              {/* <div className="text-warning" style={{ fontWeight: 800 }}>
                      Увага! Розмір документу для завантаження не повинен перевищувати 100 Мб
                    </div>
                    {state.fileSize > parseInt(100000000) && (
                      <div className="text-danger">Завантаження неможливе! Розмір файла перевищує 100 мб</div>
                    )} */}
              <Button
                type="submit"
                color="primary"
                className={classes.test_button_save}
                disabled={state.fileSize > parseInt(100000000) || disableChanges}
              >
                Зберегти
                <img className={classes.test_button_loading_img} src={save} />
              </Button>
              {/* </div> */}
            </form>
            {/* {this.props.loading.CARGO_DOCUMENTS_SAVE && <Loader type="spin" />} */}
            {
              <div className="table-responsive zebra">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '5%' }}>№ з/п</th>
                      <th style={{ width: '25%' }}>Дата завантаження</th>
                      <th style={{ width: '25%' }}>Коментар</th>
                      <th style={{ width: '25%' }}>Скан документу</th>
                      <th style={{ width: '20%' }}>Видалити</th>
                    </tr>
                  </thead>
                  <tbody>{documents}</tbody>
                </table>
              </div>
            }
            <Grid container spacing={3} className="mt-4">
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="yes-no-inv" color="primary">
                    <Typography variant="body1" style={{ textAlign: 'justify' }}>
                      Чи є Отримувач або набувачі допомоги органами, формуваннями, адміністраціями або суб&apos;єктами,
                      по яких, згідно чинного законодавства дані не мають відображатись у публічному доступі: органи
                      військового управління, правоохоронні органи, інші військові формування, Адміністрація
                      Держспецзв&apos;язку чи підпорядкований підрозділ, визначений нею, військові адміністрації,
                      суб&apos;єкти, які безпосередньо здійснюють боротьбу з тероризмом або суб&apos;єкти, які
                      залучаються до боротьби з тероризмом?
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="yes-no-inv"
                    name="radio-yes-no"
                    value={privat}
                    onChange={handleChangeRadio}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    row
                  >
                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Так" />
                    <FormControlLabel value="no" color="primary" control={<Radio color="primary" />} label="Ні" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <p className={classes.bottomTitle}>Підписати Інвентаризаційний опис</p>
            <p className={classes.bottomText}>
              Підписувати тільки повністю сформований та перевірений за звітний місяць.
            </p>
            <p className={classes.bottomText}>
              Після підписання редагувати <b>НЕМОЖЛИВО!</b>
            </p>

            <div className="row" style={{ margin: '50px 0 0 0' }}>
              <div className="col-12 mt-3 mb-2 text-center">
                <Button
                  type="button"
                  className={classes.button_sign}
                  onClick={orederView}
                  // color="primary"
                  variant="outlined"
                >
                  Роздрукувати
                  <img className={classes.button_img} src={print} />
                </Button>

                <Button
                  disabled={disableChanges}
                  type="button"
                  className={classes.button_sign}
                  onClick={sing}
                  variant="outlined"
                >
                  Підписати
                  <img className={classes.button_img} src={sign} />
                </Button>
              </div>
            </div>
          </section>
        </CardBody>
      </Card>
    </>
  );
};

export default connect(null, {})(InventoryUpload);

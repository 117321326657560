export const customTheme = (theme) => ({
  ...theme,
  borderRadius: 0,
  minHeight: 'fit-content',
});

export const customStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  input: () => ({
    height: 'auto',
    maxHeight: '34px',
    paddingBootom: '10px',
    width: '100%',
  }),
  valueContainer: (base) => ({
    ...base,
    width: '100%',
    minHeight: 'fit-content',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    height: 'auto',
    minHeight: 'fit-content',
  }),
  menu: () => ({
    width: '100%',
    backgroundColor: '#fff',
    zIndex: 444,
    border: '1px solid #C2C5CB',
  }),

  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }), //- resolve problem with zindex of options
  multiValue: (base) => ({
    ...base,
    whiteSpace: 'none',
  }),
  multiValueLabel: (base) => ({}),
};

export const updatedDesignSelect = {
  control: (provided) => ({
    ...provided,
    borderRadius: '30px',
    border: '1px solid #1414144D',
    padding: '5px 16px 14px 16px',
    height: '48px',
    whiteSpace: 'nowrap',
    '&:focus-within': {
      outline: '1px solid black !important',
    },
    '&:hover': {
      outline: 'none',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? '#F5F8FD' : state.isSelected ? '#F5F8FD' : state.isFocused ? '#F5F8FD' : null,
    color: state.isDisabled ? 'black' : state.isSelected ? 'black' : 'black',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      backgroundColor: '#F5F8FD',
      color: 'black',
    },
    '&:click': {
      backgroundColor: '#F5F8FD',
    },
  }),
  placeholder: (base) => ({
    ...base,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '90%',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: '0',
  }),
};

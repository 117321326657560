//packages
import React from 'react';
import { withRouter } from 'react-router';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import uk from 'date-fns/locale/uk';
import { NavLink, Link } from 'react-router-dom';
import { Container, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, Button } from 'reactstrap';
//assets
import logo from '../../../../assets/images/gov.ua.white.png';

//own css

import { Row, Col, Card, Progress, CardBody, Table } from 'reactstrap';
import CardMaterial from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ButtonMaterial from '@material-ui/core/Button';
import { getCargoPublic, getmeetingByCargo, getOrderDoc, getProtocolDoc } from '../../../../api/api';
import axios from 'axios';
import { IconButton } from '@material-ui/core';
import * as Feather from 'react-feather';
import ViewDocModal from '../../Dialogs/ViewDocModal';

function RecipientInfo({ match, history }) {
  const [isOpen, toggle] = React.useState(false);
  const [data, getData] = React.useState({});
  const [dataDocs, getDataDocs] = React.useState([]);
  const [htmlDoc, getDoc] = React.useState('');
  const [dialog, toggleDoc] = React.useState(false);
  React.useEffect(() => {
    const getInfo = async () => {
      try {
        const [response, success] = await axios.all([
          getCargoPublic(match.params.id),
          getmeetingByCargo(match.params.id),
        ]);
        getData(response.data);
        getDataDocs(success.data);
      } catch (error) {
        history.goBack();
      }
    };

    getInfo();
  }, []);

  async function orederView(id) {
    getDoc('');
    try {
      const response = await getOrderDoc(id);
      getDoc(response.data);
      toggleDoc(!dialog);
    } catch (error) {}
  }

  async function protocolView(id) {
    getDoc('');
    try {
      const response = await getProtocolDoc(id);
      getDoc(response.data);
      toggleDoc(!dialog);
    } catch (error) {}
  }

  function toggleModal() {
    toggleDoc(!dialog);
    getDoc('');
  }

  return (
    <div>
      <header>
        <Navbar
          color="light"
          dark
          expand="md"
          className="bg-gradient text-white"
          style={{ paddingBottom: '1.5rem', paddingTop: '1.5rem' }}
        >
          <Container>
            <NavbarBrand href="https://www.gov.ua/">
              <img src={logo} alt="buttonGovUa" />
            </NavbarBrand>
            <NavbarToggler onClick={() => toggle(!isOpen)} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  {/* <NavNavLink className="btn-outline" href="/register-recipients-public">Реєстр отримувачів</NavNavLink> */}
                  <NavLink
                    activeStyle={{
                      outline: '1px solid #fff',
                      fontFamily: 'dia_bold',
                    }}
                    className="nav-link text-white-btn"
                    to="/register-recipients-public"
                  >
                    Реєстр отримувачів
                  </NavLink>
                </NavItem>
                <NavItem>
                  {/* <NavNavLink className="btn-outline" href="/register-public">Реєстр гуманітарної допомоги</NavNavLink> */}
                  <NavLink
                    activeStyle={{
                      outline: '1px solid #fff',
                      fontFamily: 'dia_bold',
                    }}
                    className="nav-link text-white-btn"
                    to="/register-public"
                  >
                    Реєстр гуманітарної допомоги
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    activeStyle={{
                      outline: '1px solid #fff',
                      fontFamily: 'dia_bold',
                    }}
                    className="nav-link text-white-btn"
                    to="/commission"
                  >
                    Засідання робочої групи
                  </NavLink>
                </NavItem>
                <NavItem>
                  <Button className="btn-warning text-dark ml-2" onClick={(e) => history.push('/')}>
                    Вхід
                  </Button>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </header>
      <div className="mainRegister" style={{ width: '90%', margin: '50px auto' }}>
        <div className="row">
          <div className="col-md-4">
            <CardMaterial>
              <CardContent>
                <h4 className="text-bold">Детальний перегляд</h4>
                <div className="h6 text-muted m-t">
                  Форма допомоги: <span className="h5 text-info m-t">{(data.scargo || {}).aid_name}</span>
                </div>
                <div className="h6 text-muted m-t">
                  Країна відправлення: <span className="h5 text-info m-t">{(data.scargo || {}).donor_cnt_name}</span>
                </div>
                <div className="h6 text-muted m-t">
                  Донор: <span className="h5 text-info m-t">{(data.scargo || {}).donor_name}</span>
                </div>
                <div className="h6 text-muted m-t">
                  Отримувач: <span className="h5 text-info m-t">{(data.scargo || {}).org_name}</span>
                </div>
                <div className="h6 text-muted m-t">
                  Код ЄДРПОУ: <span className="h5 text-info m-t">{(data.scargo || {}).p_edrpou}</span>
                </div>
                {/* <div className="h6 text-muted m-t">Дата виключення з Реєстру: <span className="h5 text-info m-t">{(data.scargo || {}).p_number}</span></div> */}
              </CardContent>
            </CardMaterial>
          </div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-12">
                <CardMaterial>
                  <Table>
                    <thead>
                      <tr className="h6 text-bold">
                        <th style={{ width: '10%' }}>Тип</th>
                        <th style={{ width: '50%' }}>Опис</th>
                        <th style={{ width: '10%' }}>Одиниця виміру</th>
                        <th style={{ width: '10%' }}>Всього</th>
                        <th style={{ width: '10%' }}>Розподілено</th>
                        <th style={{ width: '10%' }}>Залишилось</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(data.aid || []).map((item, index) => (
                        <tr className="h6 text-info text-bold">
                          <td>{item.cargo_form}</td>
                          {item.i_aidform === 1 ? <td>{item.aid_type}</td> : <td>{item.c_description}</td>}
                          <td>{item.i_unit}</td>
                          <td>{item.n_count}</td>
                          <td>{item.distrib_count}</td>
                          <td>{item.difference}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardMaterial>
              </div>
              <div className="col-md-12">
                <CardMaterial>
                  <Table>
                    <thead>
                      <tr className="h6 text-bold">
                        <th style={{ width: '60%' }}>Тип заяви</th>
                        <th style={{ width: '20%' }}>Дата створення документів</th>
                        <th style={{ width: '10%' }}>Накази</th>
                        <th style={{ width: '10%' }}>Протоколи</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataDocs.map((item) => (
                        <tr className="h6 text-info text-bold">
                          <td>{item.c_name}</td>
                          <td>{item.d_create}</td>
                          <td>
                            <IconButton onClick={() => orederView(item.id)}>
                              <Feather.Clipboard />
                            </IconButton>
                          </td>
                          <td>
                            <IconButton onClick={() => protocolView(item.id)}>
                              <Feather.FileText />
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardMaterial>
              </div>
            </div>
          </div>
        </div>

        <ButtonMaterial>Назад</ButtonMaterial>
      </div>
      {htmlDoc && <ViewDocModal modal={dialog} htmlDoc={htmlDoc} toggle={() => toggleModal()} />}
    </div>
  );
}

export default withRouter(RecipientInfo);

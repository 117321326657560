import React from 'react';

//reactStrap
import { Button } from 'reactstrap';

//MUI
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

//api
import { changeRecipientCargo } from '../../../../api/api';

//swal
import Swal from 'sweetalert2';



const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});


class DialogApprovalDelete extends React.Component {
  state = {
    open: false,
    validationText: '',
    errorText: '',
  };
  handleClose = () => {
    this.setState({ validationText: '', errorText: '' });
    this.props.onClose();
  };
  handleСhange = data => {
    changeRecipientCargo(data)
      .then(resp => {
        
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Заяву подано',
          showConfirmButton: false,
          timer: 2200,
        });
        this.handleClose();
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          swalWithBootstrapButtons.fire({
            title: 'Помилка',
            text: error.response.data,
            type: 'error',
          });
          this.handleClose();
        } else {
          swalWithBootstrapButtons.fire({
            title: 'Помилка серверу',
            type: 'error',
          });
        }
        this.handleClose();
      });
  };

  render() {
    const data = {
      p_cargo_id: this.props.cargo.i_cargo,
      p_new_recipient_id: this.props.newRecipient.value,
      p_user_id: this.props.userId,
    };

    
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText style={{ color: '#000' }} id="alert-dialog-description">
              Ви дійсно бажаєте змінити отримувача?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleСhange(data)} className="mr-2" color="primary">
              Так
            </Button>
            <Button onClick={this.handleClose} color="primary">
              Відмінити
            </Button>
          </DialogActions>
          {this.state.validationText && <h4 className="text-right text-success mr-2">{this.state.validationText}</h4>}
          {this.state.errorText && <h4 className="text-right text-danger mr-2">{this.state.errorText}</h4>}
        </Dialog>
      </div>
    );
  }
}

export default DialogApprovalDelete;

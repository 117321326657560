import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector } from 'react-redux';

const TableWindow = ({ view, setView }) => {
  const {
    catalogs: { uktzed },
  } = useSelector((state) => state);

  const handleClose = () => {
    setView(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (view) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [view]);

  const body = (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Код УКТЗЕД</TableCell>
          <TableCell>Опис УКТЗЕД</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {uktzed.map(({ value, label }) => (
          <TableRow key={value}>
            <TableCell component="th" scope="row">
              {value}
            </TableCell>
            <TableCell component="th" scope="row">
              {label}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <>
      <Dialog
        open={view}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">УКТ ЗЕД</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
            {body}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TableWindow;

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import DialogApprovalDelete from './DialogApprovalDelete';
import isEmpty from '../../../../vibe/components/utilities/MainUtils/is-empty';
// docs for recipient
import DocumentsLoadingRecipients from '../RegistrationFormsRecipient/DocumentsLoadingRecipients';
// redux
import {
  getRecipientDocuments,
  deleteRecipientDocument,
  saveRecipientDocument,
} from '../../../../redux/actions/recipient.action';
import { createErrorMessageSelector } from '../../../../redux/actions/selectors';
import { selectRecipientItem, selectRecipientDocuments } from '../../../../redux/actions/recipient/recipient.selector';

const errorSelector = createErrorMessageSelector(['RECIPIENT_SAVE']);

class DeleteRecipient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleOpenConfirmation = () => {
    this.setState({
      open: true,
    });
  };

  handleCloseConfirmation = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { recipient, userStatus } = this.props;
    return (
      <section className="deleteRecipient ">
        {userStatus === 0 && (
          <DocumentsLoadingRecipients
            getRecipientDocuments={this.props.getRecipientDocuments}
            deleteRecipientDocument={this.props.deleteRecipientDocument}
            saveRecipientDocument={this.props.saveRecipientDocument}
            documents={this.props.documents}
            recipient={this.props.recipient}
            loading={this.props.loading}
            error={this.props.errMessage}
            user={this.props.user}
            currentRecipientId={this.props.currentRecipientId}
            applicationId={this.props.applicationId}
            catalog={this.props.catalog}
            p_new_application_id={this.props.p_new_application_id}
          />
        )}
        {recipient.id && (
          <div>
            <h4 className="text-center mt-4">Заява</h4>
            <div className="ml-4 mt-4">
              Прошу отримувача{' '}
              <strong>
                {!isEmpty(recipient.p_orgname) && recipient.p_orgname}{' '}
                {!isEmpty(recipient.p_edrpou) && recipient.p_edrpou}
              </strong>{' '}
              виключити з Єдиного реєстру отримувачів гуманітарної допомоги.
            </div>
            <div className="text-center">
              <Button
                variant="contained"
                color="primary"
                type="button"
                className="ml-4 mt-4"
                onClick={this.handleOpenConfirmation}
              >
                Подати заяву
              </Button>
            </div>

            {this.state.open && (
              <DialogApprovalDelete
                open={this.state.open}
                onClose={this.handleCloseConfirmation}
                recipient={recipient}
                userId={this.props.userId}
                application_id={this.props.application_id}
                p_new_application_id={this.props.p_new_application_id}
              />
            )}
          </div>
        )}
        {recipient.id === '' && <h3 className="ml-2">Оберіть заяву в пункті меню "Єдиний реєстр"</h3>}
        <Button
          type="button"
          color="primary"
          variant="outlined"
          className="ml-4 mt-4"
          onClick={() => this.props.history.push('/inspector')}
        >
          Вихід
        </Button>
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    // recipient: state.recipient.recipient,
    recipient: selectRecipientItem(state),
    userId: state.auth.user.id,
    userStatus: state.auth.user.l_applicant,
    // new fields
    documents: selectRecipientDocuments(state),
    catalog: state.catalogs,
    error: errorSelector(state),
    loading: state.isFatching,
    user: state.auth.user,
    applicationId: state.recipient.tabsFlag.applicationSaveId,
    applicationSaveId: state.recipient.tabsFlag.applicationSaveId,
    p_new_application_id: state.recipient.tabsFlag.p_new_application_id,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    getRecipientDocuments,
    deleteRecipientDocument,
    saveRecipientDocument,
  })(DeleteRecipient)
);

import React from 'react';
import { useSelector } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { PopperIcon } from './reportHelpers/CustomIcon';
import { ReactComponent as ExportIcon } from './reportHelpers/assets/ExportIcon.svg';
import { downloadBalance } from '../../../../api/api';

const useStyles = makeStyles((theme) => ({
  icon: {
    '& > svg': {
      margin: theme.spacing(2),
    },
  },
  endIcon: {
    borderLeft: '1px solid #141414',
  },
  divider: {
    backgroundColor: '#000',
    width: '1px',
    height: '20px',
  },
  topButton: {
    backgroundColor: '#D5D8E0',
    color: '#141414',
    padding: '12px 24px 12px 16px',
    borderRadius: '30px',
  },
  refbuttonText: {
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    textAlign: 'center',
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: '10px',
  },
  list: {
    padding: 0,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#F5F8FD',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#141414',
      },
    },
    '&:active': {
      backgroundColor: '#F5F8FD',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#141414',
      },
    },
  },
}))(MenuItem);

const MenuRefer = () => {
  const classes = useStyles();
  const [anchorEl_1, setAnchorEl_1] = React.useState(null);
  const aidtypeCatalog = useSelector((state) => state.catalogs.aidType);
  const i_report = useSelector((state) => state.report.params.i_report);

  const downloadJson = () => {
    const element = document.createElement('a');
    const textFile = new Blob([JSON.stringify(aidtypeCatalog, null, 2)], { type: 'application/json' });
    element.href = URL.createObjectURL(textFile);
    element.download = 'categories.json';
    document.body.appendChild(element);
    element.click();
    element.remove();
  };

  const downloadExcel = async () => {
    try {
      const res = await downloadBalance(i_report);
      const blob = new Blob([res.data], { type: 'application/octet-binary' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Template.xlsx';
      a.click();
      a.remove();
    } catch (err) {}
  };

  const handleClick1 = (event) => {
    setAnchorEl_1(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl_1(null);
  };

  const handleClose1 = () => {
    setAnchorEl_1(null);
    downloadJson();
  };

  const handleClose2 = () => {
    setAnchorEl_1(null);
    downloadExcel();
  };

  return (
    <div>
      <Button
        aria-controls="export-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleClick1}
        className={classes.topButton}
      >
        <Box
          component="span"
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px' }}
        >
          <ExportIcon />
          <Typography variant="body1" component="span" className={classes.refbuttonText}>
            Довідники
          </Typography>
          <Divider orientation="vertical" className={classes.divider} />
          <PopperIcon />
        </Box>
      </Button>
      <StyledMenu id="export-menu" anchorEl={anchorEl_1} keepMounted open={Boolean(anchorEl_1)} onClose={handleClose}>
        <StyledMenuItem onClick={handleClose1} button>
          <ListItemText primary="Довідник категорій" />
        </StyledMenuItem>
        <StyledMenuItem onClick={handleClose2} button disabled={!i_report}>
          <ListItemText primary="Шаблон EXCEL" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
};

export default MenuRefer;

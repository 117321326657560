import React from 'react';
import { withRouter } from 'react-router';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import * as Feather from 'react-feather';
import { connect } from 'react-redux';
import { Card, CardBody, CardFooter } from 'reactstrap';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import BootstrapTooltip from '../../../elements/MaterialBootstrap';
// import {getmeetingByRecipient} from '../../../../api/api';
import {
  getmeetingByRecipient,
  getOrderDoc,
  getProtocolDoc,
  getTakePDFS,
  downloadOrder,
  downloadProtocol,
} from '../../../../api/api';
import { selectRecipientItem } from '../../../../redux/actions/recipient/recipient.selector';
import ViewDocModal from '../../Dialogs/ViewDocModal';
import ReportIcon from '@material-ui/icons/ListAlt';
import ExcelExport from '../../Commission/ExcelExport';
import ArrowDropDownCircle from '@material-ui/icons/ArrowDropDownCircle';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

const RecipientsProtokol = ({ history, recipient }) => {
  const classes = useStyles();
  const [htmlDoc, getDoc] = React.useState('');

  const [data, getData] = React.useState([]);
  const [dialog, toggle] = React.useState(false);
  React.useEffect(() => {
    const handlePrntedDocs = async () => {
      try {
        const response = await getmeetingByRecipient(recipient.id);
        getData(response.data);
      } catch (error) {
        // getData([])
        history.goBack();
      }
    };

    handlePrntedDocs();
  }, []);

  // async function orederView(id) {
  //   try {
  //     const response = await getOrderDoc(id);
  //     getDoc(response.data);
  //     toggle(!dialog);
  //   } catch (error) {}
  // }

  async function orederView(row, index) {
    try {
      const idShablona = data[index].i_order_type;
      const id = data[index].id;
      const response = await getTakePDFS(id, idShablona);
      // getPDFDoc(response.data);
      // toggle(!dialog);

      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${data[index].c_name}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {}
  }

  async function protocolView(id) {
    try {
      const response = await getProtocolDoc(id);
      getDoc(response.data);
      toggle(!dialog);
    } catch (error) {}
  }
  function toggleModal() {
    toggle(!dialog);
    getDoc('');
  }

  function exportToWord() {
    const blob = new Blob(['\ufeff', htmlDoc], {
      type: 'application/msword',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('A');
    link.href = url;
    link.download = 'Document.doc';
    document.body.appendChild(link);
    if (navigator.msSaveOrOpenBlob) navigator.msSaveOrOpenBlob(blob, 'Document.doc');
    // IE10-11
    else link.click(); // other browsers
    document.body.removeChild(link);
  }

  const downloadZip = (data) => {
    const blob = new Blob([data], { type: 'application/zip' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'files.zip');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const downloadOrderZip = async (id) => {
    try {
      const response = await downloadOrder(id);
      // const response = await downloadOrder('29274');
      downloadZip(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const downloadProtocolZip = async (id) => {
    try {
      const response = await downloadProtocol(id);
      // const response = await downloadProtocol('29274');
      downloadZip(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    /* jshint ignore:start */
    <div>
      <Card>
        <CardBody>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Тип заяви</StyledTableCell>
                <StyledTableCell align="center">Дата створення документів</StyledTableCell>
                <StyledTableCell align="center">Перегляд наказу</StyledTableCell>
                <StyledTableCell align="center">Перегляд протоколу</StyledTableCell>
                {/* <StyledTableCell align="left">Накази</StyledTableCell>
                <StyledTableCell align="left">Додаток до наказу</StyledTableCell>
                <StyledTableCell align="left">Протоколи</StyledTableCell>
                <StyledTableCell align="left">Додаток до протоколу</StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow component="th" scope="row" key={row.id}>
                  <StyledTableCell align="center">{row.c_name}</StyledTableCell>
                  <StyledTableCell align="center">{row.d_create}</StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton
                      color="primary"
                      aria-label="download picture"
                      component="label"
                      disabled={row.isOrder == 0}
                      onClick={() => downloadOrderZip(row.i_order)}
                    >
                      <ArrowDropDownCircle />
                    </IconButton>
                    {/* <ExcelExport text={row} type="nakaz" status="in_table" /> */}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <IconButton
                      color="primary"
                      aria-label="download picture"
                      component="label"
                      disabled={row.isProt == 0}
                      onClick={() => downloadProtocolZip(row.i_order)}
                    >
                      <ArrowDropDownCircle />
                    </IconButton>
                    {/* <ExcelExport text={row} type="nakaz" status="in_table" /> */}
                  </StyledTableCell>
                  {/* <IconButton
                    color="primary"
                    aria-label="download picture"
                    component="label"
                    disabled={!data.original.isorder}
                    onClick={() => downloadOrderZip(data.original.order_id)}
                  >
                    <ArrowDropDownCircle />
                  </IconButton> */}

                  {/* <StyledTableCell align="left">{row.c_orgname}</StyledTableCell>
                  <StyledTableCell align="left">
                    <IconButton onClick={() => orederView(row.id, index)}>
                      <Feather.Clipboard />
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <ExcelExport text={row} type="nakaz" status="in_table" />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <IconButton onClick={() => protocolView(row.id)}>
                      <Feather.FileText />
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <ExcelExport text={row} type="protocol" status="in_table" />
                  </StyledTableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardBody>
        <CardFooter>
          <BootstrapTooltip title="вихід">
            <IconButton onClick={() => history.push('/view-recipient')}>
              <Feather.LogOut style={{ transform: 'scaleX(-1)' }} size="25" />
            </IconButton>
          </BootstrapTooltip>
        </CardFooter>
        {htmlDoc && (
          <ViewDocModal
            modal={dialog}
            htmlDoc={htmlDoc}
            toggle={() => toggleModal()}
            exportToWord={() => exportToWord()}
          />
        )}
      </Card>
    </div>
    /* jshint ignore:end */
  );
};

const mapStateToProps = (state) => {
  return {
    // recipient: state.recipient.recipient,
    recipient: selectRecipientItem(state),
  };
};
export default withRouter(connect(mapStateToProps)(RecipientsProtokol));

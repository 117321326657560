import * as Excel from 'exceljs/dist/exceljs.min';

const exportExcel = async (uktzed) => {
  const workbook = new Excel.Workbook();
  workbook.addWorksheet('УКТ ЗЕД', {
    pageSetup: { paperSize: 9, orientation: 'portrait' },
  });
  const worksheet = workbook.getWorksheet('УКТ ЗЕД');
  const cell1 = worksheet.getCell('A1');
  cell1.value = 'Код УКТЗЕД';
  const cell2 = worksheet.getCell('B1');
  cell2.value = 'Опис УКТЗЕД';

  const col_keys = ['value', 'label'];

  worksheet.columns = [
    { key: 'value', width: 7 },
    { key: 'label', width: 20 },
  ];

  worksheet.addRows([...uktzed]);
  //
  //
  const uint8Array = await workbook.xlsx.writeBuffer();
  const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Uktzed.xlsx';
  a.click();
  a.remove();
};

export default exportExcel;

import { DECLARATION, CLEAR_DECLARATION } from './action-types';

export const setDeclaration = (data) => ({
  type: DECLARATION,
  payload: data,
});

export const clearDeclaration = () => ({
  type: CLEAR_DECLARATION,
});

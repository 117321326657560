// packages
import React from 'react';
import PropTypes from 'prop-types';

import ReactTable from 'react-table';
import 'react-table/react-table.css';

import 'react-datepicker/dist/react-datepicker.css';

import IconButton from '@material-ui/core/IconButton';
import Functions from '@material-ui/icons/ExitToApp';
import Send from '@material-ui/icons/Send';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import { CardContent, Card } from '@material-ui/core';

import { UncontrolledTooltip } from 'reactstrap';
import clsx from 'clsx';
import Swal from 'sweetalert2';
import { ReactComponent as Eye } from '../../../assets/svg/Eye.svg';
import { getComparator, stableSort } from './sortFunctions';
import { useStyles, useStylesTable } from './TableStyles';

import { StyledTableCell, CustomTablePagination, EnhancedTableHead } from './StyledTableComponents';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const headCells = [
  { label: '№ запису', numeric: false, disablePadding: false, id: 'id' },
  { label: 'Дата створення', numeric: false, disablePadding: false, id: 'd_create' },
  { label: 'Причина', numeric: false, disablePadding: false, id: 'c_reason' },
  { label: 'ЄДРПОУ отримувача', numeric: false, disablePadding: false, id: 'n_edrpou' },
];

class InvestorTableFullScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 5,
      order: 'asc',
      orderBy: '',
    };
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  };

  // sorting
  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc' });
    this.setState({ orderBy: property });
  };

  render() {
    const { classes, dataTable, recipientSaveTrack } = this.props;
    const { rowsPerPage, page, order, orderBy } = this.state;
    // data
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataTable.length - page * rowsPerPage);
    return (
      <>
        <Card>
          <CardContent>
            <div className="table-responsive">
              <Table style={{ fontSize: '12px' }} hover="true" size="small">
                <EnhancedTableHead
                  classes={classes}
                  // numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  // onSelectAllClick={handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={dataTable.length}
                  headCells={headCells}
                />
                <TableBody>
                  {stableSort(dataTable, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => {
                      const { id, d_create, c_reason, n_edrpou, i_recipient } = item;
                      return (
                        <TableRow key={id}>
                          <StyledTableCell>{id}</StyledTableCell>
                          <StyledTableCell>{d_create}</StyledTableCell>
                          <StyledTableCell>{c_reason}</StyledTableCell>
                          <StyledTableCell>{n_edrpou}</StyledTableCell>
                          <StyledTableCell>
                            <UncontrolledTooltip placement="top" target={`eyeview${index}`}>
                              Перегляд
                            </UncontrolledTooltip>
                            <IconButton
                              id={`eyeview${index}`}
                              aria-label="Gо to functions"
                              color="primary"
                              onClick={(e) => {
                                e.stopPropagation();
                                recipientSaveTrack('/inspector');
                                this.props.recipientById(item.i_recipient);
                                this.props.getRecipientHistoryApplications(item.i_recipient);
                              }}
                            >
                              <Eye />
                            </IconButton>
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 48 * emptyRows }}>
                      <StyledTableCell colSpan={13} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <CustomTablePagination
              array={dataTable}
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
            />
          </CardContent>
        </Card>
        {/* <div>
          <ReactTable
            // data={this.props.recipients}
            data={this.props.dataTable}
            loading={(this.props.loading || {}).RECIPIENTS}
            style={{ fontSize: '0.75rem', textAlign: 'center' }}
            columns={[
              {
                Header: <div>№ запису</div>,
                style: { whiteSpace: 'unset', textAlign: 'left' },
                accessor: 'id',
              },

              {
                Header: <div>Дата створення</div>,
                accessor: 'd_create',
                style: { whiteSpace: 'unset', textAlign: 'left' },
              },

              {
                Header: <div>Причина</div>,
                style: { whiteSpace: 'unset', textAlign: 'left' },
                accessor: 'c_reason',
              },
              {
                Header: (
                  <div>
                    ЄДРПОУ
                    <br />
                    отримувача
                  </div>
                ),
                style: { whiteSpace: 'unset', textAlign: 'left' },
                accessor: 'n_edrpou',
              },
              {
                Header: 'Перегляд',
                accessor: 'c_name',
                style: { textAlign: 'center' },
                sortable: false,
                filterable: false,
                Cell: (data) => (
                  <div className={classes.wrapper}>
                    <IconButton
                      id={`functions${data.index}`}
                      aria-label="Gо to functions"
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.props.recipientById(data.original.i_recipient);
                        this.props.getRecipientHistoryApplications(data.original.i_recipient);
                        // this.props.history.push(`/view-recipient/${data.original.id}`)
                      }}
                    >
                      <Functions />
                    </IconButton>
                  </div>
                ),
              },
            ]}
            defaultPageSize={10}
            showPaginationTop
            showPaginationBottom={false}
            noDataText="Інформація відсутня!"
            className="-striped -highlight"
            loadingText="Завантаження..."
            previousText="Попередня"
            nextText="Наступна"
            pageText="Сторінка"
            ofText="з"
            rowsText="рядків"
          />
        </div> */}
      </>
    );
  }
}

export default withStyles(useStylesTable)(InvestorTableFullScreen);

import React from 'react';
import { TimelineEvent } from 'react-event-timeline';

const RecipientHistory = ({ item }) => {
  const editedText = item.description.name.split(';');
  return (
    <TimelineEvent
      key={item.id}
      // title={`Номер наказу: ${item.id}`}
      createdAt={item.date}
      icon={<i />}
      iconColor="#6fba1c"
    >
      {editedText.map((text) => (
        <div className="p font-weight-bold" key={text}>
          {text}
        </div>
      ))}
    </TimelineEvent>
  );
};

export default React.memo(RecipientHistory);

import React from 'react';
import Swal from 'sweetalert2';
import FormHelperText from '@material-ui/core/FormHelperText';
import DatePicker from 'react-datepicker';
import uk from 'date-fns/locale/uk';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { Grid, Box, TextField, Button, IconButton } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { withRouter } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { saveReportDoc, deleteRecipientDocumentsApi, getDocBalance } from '../../../../api/api';
import { setReportTab } from '../../../../redux/actions/report.action';
import { NextIcon } from './reportHelpers/CustomIcon';
import popup from './reportHelpers/Popup.module.css';
import { maxWidth } from '@material-ui/system';
import { DeleteIcon } from './reportHelpers/CustomIcon';
import { ReactComponent as DocIcon } from './reportHelpers/assets/Doc.svg';

const swalWithBootstrapButtons = Swal.mixin({
  buttonsStyling: false,
});

const swalWithBootstrapButtonsError = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const useStyles = makeStyles((theme) => ({
  button_img: {
    marginLeft: '15px',
  },
  button_sign: {
    marginRight: '30px',
    alignItems: 'center',
    padding: '20px 70px',
    gap: '4px',
    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',
    textTransform: 'capitalize',
    fontFamily: 'e-Ukraine',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#FFFFFF',
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      width: '260px',
    },
  },
  test_button_upload: {
    maxWidth: '268px',
    padding: '14px 24px',
    background: '#D5D8E0',
    borderRadius: '30px',
    cursor: 'pointer',
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
    },
  },
  test_button_loading: {
    padding: '18px 36px',
    background: '#141414',
    borderRadius: '30px',
    cursor: 'pointer',
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
    },
  },
  test_button_upload_text: {
    textTransform: 'uppercase',
    fontFamily: 'e-Ukraine',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#141414',
    '&:hover': {
      color: '#141414',
    },
  },
  test_button_loading_text: {
    textTransform: 'uppercase',
    fontFamily: 'e-Ukraine',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#FFFFFF',
    '&:hover': {
      color: '#141414',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  bottomTitle: {
    color: '#000',
    fontFamily: 'e-Ukraine',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '130%',
    marginTop: '25px',
  },
  bottomText: {
    color: '#000',
    fontFamily: 'e-Ukraine',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '130%',
  },
  test_button_loading_img: {
    marginLeft: '15px',
  },
  file: {
    display: 'flex',
    alignItems: 'center',
  },
  fileIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fileName: {
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: '20.8px',
    textAlign: 'left',
  },
  fileInfo: {
    fontFamily: 'e-Ukraine',
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '16px',
    textAlign: 'left',
    color: 'rgba(20, 20, 20, 0.6)',
    margin: 0,
  },
  input: {
    borderBottom: '2px solid #141414',
  },
  textInput: {
    WebkitBoxShadow: '0 0 0 1000px transparent inset',
  },
  autoEnd: {
    color: '#141414',
  },
  autoCross: {
    color: '#141414',
  },
  box: {
    maxWidth: '638px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  listTitle: {
    margin: 0,
    color: '#000',
    fontFamily: 'e-Ukraine',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '28px',
    textAlign: 'left',
  },
  list: {
    listStylePosition: 'inside',
    listStyleType: 'disc',
    padding: 0,
    margin: 0,
  },
  listItem: {
    display: 'list-item',
  },
  listText: {
    textWrap: 'wrap',
    color: '#000',
    fontFamily: 'e-Ukraine',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
  },
  moveBtn: {
    padding: '12px 16px',
    gap: '8px',
    borderRadius: '30px',
    border: '2px solid #141414',
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    textAlign: 'center',
  },
}));

const ReportUpload = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const docTypesReport = useSelector((state) => state.catalogs.docTypesReport);
  const i_recip = useSelector((state) => state.report.params.i_recip);
  const i_report = useSelector((state) => state.report.params.i_report);

  const [state, setState] = React.useState({
    fileName: '',
    fileSize: '',
    submitted: false,
    docType: {},
    table: [],
    comment: '',
    date: null,
    loading: false,
  });
  const fileInput = React.useRef(null);

  // datapicker
  const handleChangeDate = (dateObj) => {
    setState({ ...state, date: dateObj });
  };

  const handleChangeComment = (event) => {
    setState({ ...state, comment: event.target.value });
  };

  const handleSelect = (e, option) => {
    if (option) {
      setState({
        ...state,
        docType: option,
      });
    }
  };

  const removeFile = () => {
    fileInput.current.value = '';
    setState({
      ...state,
      fileName: '',
      fileSize: '',
    });
  };

  const handleChangeFileInput = (e) => {
    setState({ ...state, fileName: '' });

    if (e.target.files[0]) {
      const fileName = e.target.files[0].name;
      const ext = fileName.substr(fileName.lastIndexOf('.') + 1);
      if (['jpeg', 'jpg', 'png', 'pdf', 'p7s'].includes(ext)) {
        setState({
          ...state,
          fileName: e.target.files[0].name,
          fileSize: e.target.files[0].size,
        });
      } else {
        removeFile();
        swalWithBootstrapButtonsError.fire({
          title: 'Інформація',
          text: 'Формат файлу p7s, pdf, jpg або png',
          type: 'warning',
        });
      }
    }
  };

  const updateTable = async (id) => {
    try {
      if (id) {
        const updated = await getDocBalance(id);
        setState({
          ...state,
          table: [...updated.data],
        });
      }
    } catch (err) {}
  };

  const deleteDocument = async (id) => {
    swalWithBootstrapButtons
      .fire({
        customClass: {
          popup: popup['swal-popup'],
          container: popup['swal-container'],
          title: [popup['swal-title'], popup['swal-title-m1']],
          confirmButton: popup['swal-custom-btn'],
          cancelButton: [popup['swal-custom-btn'], popup['swal-custom-btn-lt']],
          actions: popup['swal-actions'],
        },
        title: 'Ви впевнені, що бажаєте видалити документ?',
        showConfirmButton: true,
        showCancelButton: true,
        focusConfirm: true,
        focusCancel: false,
        reverseButtons: true,
        confirmButtonText: 'ТАК, ВИДАЛИТИ',
        cancelButtonText: 'НІ, ПОВЕРНУТИСЯ',
      })
      .then(async (result) => {
        if (result.value) {
          try {
            await deleteRecipientDocumentsApi(id);
            await updateTable(i_report);
          } catch (error) {}
        }
      });
  };

  const submitDocument = async (e) => {
    e.preventDefault();
    const { value } = state.docType;
    setState({ ...state, submitted: true });

    if (fileInput.current.files[0] && value) {
      setState({ ...state, submitted: false });
      const { type } = fileInput.current.files[0];
      const { comment, date } = state;
      const data = new FormData();
      data.append('file', fileInput.current.files[0]);
      data.append('dateDoc', date ? format(date, 'dd.MM.yyyy') : '');
      data.append('piCargo', null);
      data.append('piRecip', i_recip);
      data.append('doctype', value);
      data.append('extension', type);
      data.append('comment', comment);
      try {
        await saveReportDoc(data);
        const updated = await getDocBalance(i_report);
        // clear
        fileInput.current.value = '';
        setState({
          ...state,
          table: [...updated.data],
          fileName: '',
          fileSize: '',
          docType: {},
          comment: '',
          date: null,
          submitted: false,
        });
      } catch (err) {
        setState({ ...state, submitted: false });
      }
    } else if (!fileInput.current.files) {
      setState({ ...state, validationText: 'Ви не додали файл' });
    }
  };

  const updateTableAtStart = async () => {
    await updateTable(i_report);
  };

  React.useEffect(() => {
    updateTableAtStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let documents = null;
  const { table } = state;
  if (table.length > 0) {
    documents = table.map((item, i) => {
      const { id } = item;
      return (
        <tr key={id}>
          <td>{i + 1}</td>
          <td>{item.d_ins}</td>
          <td style={{ width: '19%', overflowWrap: 'break-word' }}>{item.c_docname}</td>
          <td>{item.d_doc || '-'}</td>
          <td style={{ width: '19%', overflowWrap: 'break-word' }}>
            <a href={`/aid/file/${item.c_filename}`} target="blank">
              {item.c_filename}
            </a>
          </td>
          <td>
            <IconButton onClick={() => deleteDocument(id)}>
              <DeleteIcon />
            </IconButton>
          </td>
        </tr>
      );
    });
  } else {
    documents = (
      <tr>
        <td colSpan="6" className="text-center">
          <h4>
            <strong>Не додано жодного документу</strong>
          </h4>
        </td>
      </tr>
    );
  }
  const { docType } = state;
  return (
    <div>
      <div>
        <section className="documentsLoadingForm">
          <form onSubmit={submitDocument}>
            <div className="row">
              <div className="col-12" style={{ paddingTop: 16 }}>
                <Box className={classes.box} component="div">
                  <p className={classes.listTitle}>Підтверджуючі документи додаються в таких випадках:</p>
                  <ul className={classes.list}>
                    <li className={classes.listItem}>
                      <span className={classes.listText}>
                        Була паперова декларація і по ній немає інформації в меню «Мої вантажі/Декларація»;
                      </span>
                    </li>
                    <li className={classes.listItem}>
                      <span className={classes.listText}>
                        Є розбіжності між даними товарів в Декларації і фактично отриманою допомогою.
                      </span>
                    </li>
                  </ul>
                </Box>
              </div>
            </div>

            <div className="row" style={{ margin: '20px 0 0 0', flexDirection: 'column' }}>
              <div className="col-12 col-sm-12 col-md-6 col-xl-4 mb-6 mr-2 pl-0 pr-0">
                <Autocomplete
                  value={
                    docTypesReport.filter(({ value }) => Number(value) === Number(state.docType?.value))[0] || null
                  }
                  onChange={handleSelect}
                  autoHighlight
                  getOptionSelected={(option) => option.value === state.docType?.value}
                  getOptionLabel={(option) => option?.label || ''}
                  classes={{
                    popupIndicator: classes.autoEnd,
                    clearIndicator: classes.autoCross,
                  }}
                  options={docTypesReport}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  renderInput={(params) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <TextField {...params} label="Виберіть тип документу" className={classes.input} />
                  )}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-xl-4 mb-6 mr-2 pl-0 pr-0">
                <TextField
                  label="Назва документу (опціонально):"
                  type="text"
                  name="comment"
                  value={state.comment}
                  onChange={handleChangeComment}
                  className={classes.input}
                  style={{ width: '100%' }}
                  autoComplete="off"
                  inputProps={{ maxLength: 128 }}
                />
              </div>

              {docType?.value === 33 && (
                <div className="col-12 col-sm-12 col-md-2 col-xl-4 mb-6 pl-0 pr-0">
                  <DatePicker
                    selected={state.date}
                    onChange={handleChangeDate}
                    dateFormat="dd.MM.yyyy"
                    locale={uk}
                    className="datePicker"
                    icon={<i className="cal-icon" />}
                    customInput={<TextField label="Станом на:" style={{ borderBottom: '2px solid #141414' }} />}
                  />
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-12" style={{ margin: '16px 0' }}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="file-upload-cargo" className={clsx(classes.test_button_upload, {})}>
                  <div className={clsx(classes.test_button_upload_text, {})}>Завантажити документ</div>
                </label>
                <input
                  id="file-upload-cargo"
                  className="upload"
                  type="file"
                  accept=".jpeg,.jpg,.png,.pdf,.p7s"
                  onChange={handleChangeFileInput}
                  ref={fileInput}
                />
                {state.submitted && (!fileInput.current.files[0] || !state.docType?.value) && (
                  <FormHelperText error id="name-error-text">
                    Файл та тип документу обов&apos;язкові
                  </FormHelperText>
                )}
              </div>

              <div className="col-12">
                <p className={classes.fileInfo}>Можливі формати документу .p7s, .pdf, .png, .jpg </p>
                <p className={classes.fileInfo}>Максимальний розмір файлу 10 Мб</p>
              </div>

              {state?.fileName && (
                <div className="col-12">
                  <div className={classes.file}>
                    <span className={classes.fileIcon}>
                      <DocIcon />
                    </span>
                    <span className={classes.fileName}>{state.fileName}</span>
                    <IconButton className={classes.Btn} component="span" onClick={removeFile}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-12 mt-3 mb-4">
                {/* eslint-disable-next-line radix */}
                {state.fileSize > parseInt(100000000) && (
                  <div className="text-danger">Завантаження неможливе! Розмір файла перевищує 10 мб</div>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.test_button_loading}
                  disabled={state.fileSize > parseInt(100000000) || !i_report}
                >
                  <span className={classes.test_button_loading_text}>Зберегти</span>
                </Button>
              </div>
            </div>
          </form>
          <div style={{ overflow: 'auto', marginBottom: 32 }}>
            <table className="xtable zebra">
              <thead>
                <tr>
                  <th style={{ width: '5%' }}>№ з/п</th>
                  <th style={{ width: '19%' }}>Дата завантаження</th>
                  <th style={{ width: '19%' }}>Назва документу</th>
                  <th style={{ width: '19%' }}>Станом на</th>
                  <th style={{ width: '19%' }}>Скан документу</th>
                  <th style={{ width: '19%' }}>Функції</th>
                </tr>
              </thead>
              <tbody>{documents}</tbody>
            </table>
          </div>
        </section>
      </div>
      <Grid container item justifyContent="center">
        <Button
          type="button"
          className={classes.moveBtn}
          classes={{ endIcon: classes.endIcon }}
          onClick={() => dispatch(setReportTab(3))}
          variant="outlined"
          endIcon={<NextIcon />}
        >
          Перейти до підписання
        </Button>
      </Grid>
    </div>
  );
};

export default withRouter(ReportUpload);

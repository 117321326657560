// "exceljs": "1.7.0",
import React from 'react';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import TableChartIcon from '@material-ui/icons/TableChart';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import exportExcel from './ExportExcelFunction';

const header_nakaz = {
  line_1: 'до наказу Міністерства',
  line_2: 'соціальної політики України',
};

const header_protocol = {
  line_1: 'до Протоколу засідання робочої групи',
  line_2: 'з питань гуманітарної допомоги',
};

const ExcelExport = ({ text, type, status }) => {
  const [data, setData] = React.useState(null);

  const do_not_block = false;
  const block_not_going = text.i_state !== 30;
  const block_not_ended = text.i_state !== 40 && text.i_state !== 50;
  const block = status === 'going' ? block_not_going : status === 'ended' ? block_not_ended : do_not_block;
  const tooltipPlacement = status === 'going' ? 'bottom' : 'top';
  const tooltipText = type === 'nakaz' ? 'Додатки до наказу' : 'Додатки до протоколу';
  const headerType = type === 'nakaz' ? header_nakaz : header_protocol;

  const fetchData = (id) => {
    axios
      .get(`aid/getAttachmentTable/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handlerClickDownloadButton = async (e, id) => {
    e.preventDefault();
    fetchData(id);
  };

  React.useEffect(() => {
    if (data) exportExcel(headerType, data);
  }, [data]);

  return (
    <>
      <IconButton
        disabled={block}
        id={`${type}excel${text.id}`}
        color="primary"
        aria-label="Excel"
        onClick={(e) => {
          e.stopPropagation();
          handlerClickDownloadButton(e, text.id);
        }}
      >
        <TableChartIcon fontSize="inherit" />
      </IconButton>
      {!block && (
        <UncontrolledTooltip placement={tooltipPlacement} target={`${type}excel${text.id}`}>
          {tooltipText}
        </UncontrolledTooltip>
      )}
    </>
  );
};

export default ExcelExport;

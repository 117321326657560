import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const getPage = (data) => {
  const { manifest, assistance, vechicles } = data;
  const {
    m_number,
    n_number,
    n_code,
    driver_name,
    licence_plate,
    consignee,
    brand_rb,
    sender,
    driver_name_rb,
    licence_plate_rb,
    name,
    brand,
    edrpou,
    c_sys_name,
    c_kep,
    c_serial,
    c_time,
    d_passed_customs,
  } = manifest[0];

  const createQRCodeBlock = (base64QRCode) => {
    if (base64QRCode === null) {
      return [];
    }

    const binaryQRCode = atob(base64QRCode);
    const dataURIQRCode = `data:image/png;base64,${btoa(binaryQRCode)}`;

    return [
      {
        layout: 'noBorders',
        table: {
          widths: [500, '*'],
          body: [
            [
              {
                stack: [
                  {
                    image: dataURIQRCode,
                    fit: [150, 150],
                    margin: [0, 30, 0, 0],
                  },
                  { text: `ВІДМІТКА \nПРО МИТНЕ ОФОРМЛЕННЯ`, margin: [150, -100, 0, 0] },
                ],
              },
            ],
          ],
        },
      },
    ];
  };

  const base64QRCode = data.QRCode || null;
  const qrCodeBlock = createQRCodeBlock(base64QRCode);

  const assistanceRows = assistance.map(({ num, category, count, description, weight, count_items }) => {
    return [num, category, description, count, weight, count_items];
  });

  const vehicleRows =
    vechicles.length > 0
      ? vechicles.map(({ num, c_vin, c_brand, c_specification, n_capacity, c_engine, n_comm_year }) => {
          return [num, c_vin, c_brand, c_specification, n_capacity, c_engine, n_comm_year];
        })
      : [
          [
            { text: '–', alignment: 'center' },
            { text: '–', alignment: 'center' },
            { text: '–', alignment: 'center' },
            { text: '–', alignment: 'center' },
            { text: '–', alignment: 'center' },
            { text: '–', alignment: 'center' },
            { text: '–', alignment: 'center' },
          ],
        ];

  const dd = {
    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: [40, 60, 40, 60],
    header: (currentPage) => {
      if (currentPage > 1 && d_passed_customs) {
        return [
          {
            text: `Унікальний код гуманітарної допомоги: ${n_code}\nДата митного оформлення: ${d_passed_customs}`,
            alignment: 'right',
            margin: [40, 10, 40, 10],
          },
        ];
      } else if (currentPage > 1 && !d_passed_customs) {
        return [
          { text: `Унікальний код гуманітарної допомоги: ${n_code}`, alignment: 'right', margin: [40, 10, 40, 10] },
        ];
      }
      return {};
    },
    content: [
      { text: `ДЕКЛАРАЦІЯ №${m_number}`, alignment: 'center', style: 'header' },
      { text: 'про перелік товарів, що визнаються гуманітарною допомогою', alignment: 'center', style: 'subheader' },
      d_passed_customs
        ? { text: `Дата митного оформлення: ${d_passed_customs}`, alignment: 'center', style: 'subheader' }
        : {},
      {
        style: 'tableExample',
        table: {
          widths: ['*', '*'],
          body: [
            [
              '1. Повне найменування отримувача гуманітарної допомоги/ Full Name of the Recipient of Humanitarian Aid',
              consignee,
            ],
            [
              '2. Код згідно з ЄДРПОУ отримувача гуманітарної допомоги/ USREO Code of the Recipient of Humanitarian Aid *',
              edrpou,
            ],
            ['3. Номер отримувача в Єдиному реєстрі отримувачів гуманітарної допомоги', n_number],
            [
              '4. Унікальний код гуманітарної допомоги, наданий автоматизованою системою реєстрації гуманітарної допомоги',
              n_code,
            ],
            [
              '5. Прізвище, власне ім’я, по батькові (за наявності) водія/ Surname, First Name, Patronymic (where available) of the Driver',
              driver_name,
            ],
            [
              '6. Прізвище, власне ім’я, по батькові (за наявності) особи, що здійснює декларування/ Surname, First Name,Patronymic (where available) of the Person Making the Declaration',
              name,
            ],
            ['7. Марка машини/ Brand of Car', brand],
            ['8. Номер машини/Licence Plate', licence_plate],
            [
              '9. Донор гуманітарної допомоги, адреса, країна / Donor of Humanitarian Aid (Name, Address, Country)',
              sender,
            ],
          ],
        },
      },
      { text: '10. Вид гуманітарного вантажу / Type of Humanitarian Cargo', style: 'simpleText' },
      {
        style: 'tableExample',
        table: {
          widths: ['auto', 150, 'auto', 'auto', 'auto', 'auto'],
          body: [
            [
              'Поряд-ковий номер',
              'Категорія товару / Category of Goods',
              'Найменування товарів / Description of Goods',
              'Кількість місць / Number of Packages',
              'Орієнтовна вага, кілограмів/ об’єм, літрів/ Approximate weight, kg/ Volume, l',
              'Кількість, штук/ Number of Pieces',
            ],
            // [{ text: 'here', italics: true, color: 'gray' }]
            ...assistanceRows,
          ],
        },
      },
      {
        text:
          '11. Відомості щодо транспортного засобу та водія у разі перевантаження вантажу у пункті пропуску / Vehicle and driver information in case of overloading of goods at the checkpoint:',
        style: 'simpleText',
        pageBreak: 'before',
      },
      {
        style: 'tableExample',
        table: {
          widths: ['*', '*'],
          body: [
            [
              '1) прізвище, власне імя, по батькові (за наявності), паспортні дані (серія, номер) водія/Surname, First Name, Patronymic (where available), Passport data (series number) of the Driver',
              driver_name_rb,
            ],
            ['2) марка машини/ Brand of car', brand_rb],
            ['3) номер машини/Licence plate', licence_plate_rb],
          ],
        },
      },
      {
        text: '12. Додаткова інформація щодо транспортних засобів/Additional piecies of information of Vehicles:',
        style: 'simpleText',
      },
      {
        style: 'tableExample',
        table: {
          widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          body: [
            [
              'Поряд-ковий номер',
              'Ідентифікаційний номер транспортного засобу/ Vehicle identification number',
              'Марка/ Brand',
              'Комерційний опис (в тех паспорті D3  -седан, джип, хечбек, тощо)/ Commercial description',
              'Об’єм двигуна, куб. санти-метрів/ Сapacity, cm3',
              'Тип палива/ Fuel type',
              'Рік випуску/ Year of manufacture',
            ],
            ...vehicleRows,
          ],
        },
      },
      {
        text: c_sys_name,
        style: 'signature',
      },
      {
        text: c_kep,
        style: 'signature1',
      },
      {
        text: c_serial,
        style: 'signature1',
      },
      {
        text: c_time,
        style: 'signature1',
      },
      {
        text:
          '* Не зазначається для дипломатичного представництва, консульської установи іноземної держави або представництва міжнародної чи іноземної благодійної організації в Україні/  Does not need to be specified for diplomatic mission, consular institution of a foreign state or a representative office of an international or foreign charitable organization in Ukraine.',
        style: 'bottomText1',
      },
      {
        text: '** Додаткова інформація щодо транспортних засобів зазначається у пункті 12 декларації.',
        style: 'bottomText2',
      },
      qrCodeBlock,
    ],
    styles: {
      header: {
        fontSize: 14,
        bold: true,
        margin: [0, 0, 0, 0],
      },
      subheader: {
        fontSize: 12,
        bold: true,
        margin: [0, 0, 0, 0],
      },
      tableExample: {
        margin: [0, 5, 0, 15],
        fontSize: 10,
      },
      tableHeader: {
        bold: true,
        fontSize: 12,
        color: 'black',
      },
      tableCell: {
        // alignment: 'right',
        width: '50%',
      },
      signature: {
        margin: [0, 50, 0, 0],
      },
      bottomText1: {
        margin: [0, 50, 0, 0],
        alignment: 'justify',
      },
      bottomText2: {
        alignment: 'justify',
      },
      simpleText: {
        margin: [0, 0, 0, 0],
      },
    },
    defaultStyle: {
      // alignment: 'justify',
      fontSize: 10,
    },
  };

  return pdfMake.createPdf(dd);
};

export default getPage;

import { TOGGLE } from './action-types'
import { localityAction } from './catalog.action'
import isEmpty from '../../vibe/components/utilities/MainUtils/is-empty';

const toggle = (id) => ({
    type: TOGGLE,
    payload: id 
})


export const toggleAction = id => (dispatch, getState) => {
    if (getState().toggle !== id) {
        dispatch(toggle(id))
    }

    if (id === '1' && !isEmpty(getState().recipient.recipient.p_region)) {
        dispatch(localityAction(getState().recipient.recipient.p_region))
    }
        
   
}
import React from 'react';
import './Landing.css';
import { Grid, Typography } from '@material-ui/core';

const Landing = () => {
  return (
    <div>
      <h1 className="landingTitle">Автоматизована система реєстрації гуманітарної допомоги</h1>
      <p className="landingText">
        Автоматизована система реєстрації гуманітарної допомоги призначена для забезпечення прозорого обліку отримувачів
        гуманітарної допомоги та вантажів.
      </p>
    </div>
    // <Grid container direction="column" justifyContent="flex-start">
    //   <Typography variant="h5" gutterBottom>
    //     Автоматизована система реєстрації гуманітарної допомоги
    //   </Typography>
    //   <Typography variant="h6">
    //     Автоматизована система реєстрації гуманітарної допомоги призначена для забезпечення прозорого обліку отримувачів
    //     гуманітарної допомоги та вантажів.
    //   </Typography>
    // </Grid>
  );
};

export default Landing;

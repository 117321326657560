import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  Modal,
  Fade,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { withRouter } from 'react-router';
import { Skeleton } from '@material-ui/lab';
import { ArrowBack } from '@material-ui/icons';
import Soldiers from './Soldiers';
import Documents from './Documents';
import TransportData from './TransportData';
import AcquirerComponent from './Acquirer';
import ModalContent from './ModalConenten';
import TransportDataSkeleton from './TransportDataSkeleton';
import Recipient from './Recipient';
import { viewDeclaration } from '../../../api/api';
import getPage from '../RegisterRecipients/RegistrationFormsRecipient/getPDFfile';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '540',
    zIndex: 9999, // Ensure the modal is on top of other elements
  },
  main: {
    backgroundColor: theme.palette.background.paper,
    padding: 36,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(20, 20, 20, 0.2)',
    padding: 36,
    width: 540, // Adjust width here
    [theme.breakpoints.down('sm')]: {
      width: '100%', // For smaller screens
    },
  },
}));

const VehicleRegistrationDetails = ({ match, history }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState({});
  const [muType, setMuType] = useState([]);
  const [modalType, setModalType] = useState('');

  const handleDialogOpen = (type) => {
    setModalType(type);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const fetchData = async (idAct) => {
    try {
      setLoading(true); // Set loading to true before making the request
      const response = await axios.get(`/aid/getPackageById/${idAct}`);
      // Handle the response data as needed
      setData(response.data);
    } catch (error) {
      // Handle errors
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMUType = async () => {
    try {
      const response = await axios.get('/aid/muType');
      setMuType(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  };

  const downloadDeclaration = () => {
    viewDeclaration(data.main[0]?.cargo_id)
      .then((res) => {
        return getPage(res.data);
      })
      .then((file) => {
        file.open();
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchMUType();
  }, []);

  useEffect(() => {
    fetchData(match.params.id);
  }, [match.params.id]);

  if (Object.keys(data).length === 0) {
    return (
      <div className={classes.main}>
        <Grid container spacing={8}>
          <Grid container item alignItems="center" alignContent="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="subtitle1">
                <Skeleton variant="text" width={200} />
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="p" color="textSecondary">
                <Skeleton variant="text" width={100} />
              </Typography>
            </Grid>
          </Grid>
          <Grid container item spacing={2} xs={4} direction="column">
            <Grid item>
              <TransportDataSkeleton />
            </Grid>
            <Grid item>
              <TransportDataSkeleton />
            </Grid>
          </Grid>
          <Grid container item spacing={2} xs={8}>
            <Grid item>
              <TransportDataSkeleton />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  const stateColors = {
    30: '#EAEFFF', // Подано
    35: '#FFF9D7', // На розгляді
    40: '#FEF2E3', // Заблоковано
    50: '#E6F5EA', // Зареєстровано
    90: '#FFEFEF', // Відхілено
  };

  const stateColorText = {
    30: '#5C6A9D', // Подано
    35: '#8E7B4D', // На розгляді
    40: '#A77438', // Заблоковано
    50: '#5F7B67', // Зареєстровано
    90: '#AB5656', // Відхілено
  };

  return (
    <div className={classes.main}>
      <Grid container spacing={4}>
        <Grid container item alignItems="flex-start" justifyContent="space-between">
          <Grid item>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <IconButton size="small" onClick={() => history.push('/register-transport')}>
                  <ArrowBack />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography style={{ fontWeight: '500' }} variant="subtitle1" component="h1">
                  Пакет документів
                </Typography>
              </Grid>
              <Grid item>
                {data.main.length > 0 && (
                  <Chip
                    style={{
                      backgroundColor: stateColors[data.main[0].state_id],
                      color: stateColorText[data.main[0].state_id],
                    }}
                    label={data.main[0].state}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {data.main.length > 0 && (
              <Grid container spacing={2} direction="column">
                <Typography variant="body2" color="textSecondary">
                  Дата подання:{' '}
                  <Typography variant="body1" component="span" color="textPrimary">
                    {data.main[0].submit_date}
                  </Typography>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Уповноважена особа:{' '}
                </Typography>
                <Typography variant="body1" component="p" color="textPrimary">
                  {data.main[0].pib}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container item spacing={2} xs={4} direction="column">
          <Grid item>
            <TransportData transport={data.transport} />
          </Grid>
          <Grid item>
            <Documents documents={data.document} downloadDeclaration={downloadDeclaration} />
          </Grid>
          <Grid item>
            <Recipient recipient={data.recipient} />
          </Grid>
        </Grid>
        <Grid container item spacing={2} xs={8}>
          <Grid item>
            <AcquirerComponent acquirer={data.acquirer} muType={muType} />
          </Grid>
          <Grid item>
            <Soldiers soldiers={data.soldier} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center" spacing={4}>
            {!!data.pl_take_on && (
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => handleDialogOpen('pl_take_on')}>
                  ВЗЯТИ НА РОЗГЛЯД
                </Button>
              </Grid>
            )}
            {!!data.pl_unlock && (
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => handleDialogOpen('pl_unlock')}>
                  РОЗБЛОКУВАТИ
                </Button>
              </Grid>
            )}
            {!!data.pl_register && (
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => handleDialogOpen('pl_register')}>
                  ЗАРЕЄСТРУВАТИ
                </Button>
              </Grid>
            )}
            {!!data.pl_reject && (
              <Grid item>
                <Button variant="outlined" color="primary" onClick={() => handleDialogOpen('pl_reject')}>
                  ВІДМОВИТИ
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Modal
        style={{ zIndex: 'auto' }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openDialog}
        onClose={handleDialogClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDialog}>
          <div className={classes.paper}>
            <ModalContent
              data={data}
              modalType={modalType}
              id={match.params.id}
              getViewData={fetchData}
              onClose={handleDialogClose}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default withRouter(VehicleRegistrationDetails);

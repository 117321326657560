import axios from 'axios';

export const createAct = () => axios.get(`/aid/createAct`);

export const deleteAct = (id) => axios.get(`/aid/deleteAct/${id}`);
export const deleteDocAct = (id) => axios.get(`/aid/delDocument/${id}`);
export const deleteSoldierAct = (id) => axios.get(`/aid/deleteSoldier/${id}`);

export const getActById = (id) => axios.get(`/aid/getActById/${id}`);
export const getActs = (filter, status) =>
  axios.post(`/aid/getActByApplicant`, { pc_filter: filter, pn_state: status });
export const getDocsByAct = (id) => axios.get(`/aid/getDocByAct/${id}`);
export const getSoldiersByAct = (id) => axios.get(`/aid/getSoldiersByAct/${id}`);
export const getActsStates = () => axios.get(`/aid/actState`);
export const getMilitaryFormationTypes = () => axios.get(`/aid/muType`);

export const saveAct = (data) => axios.post(`/aid/saveAct`, data);
export const saveDocAct = (data) =>
  axios.post(`/aid/saveDocAct`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const saveSoldierAct = (data) => axios.post(`/aid/saveSoldier`, data);

export const signFileAct = (id) => axios.get(`/aid/signFile/${id}`);
export const submitAct = (id) => axios.get(`/aid/submitAct/${id}`);

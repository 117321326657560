import axios from 'axios';
import { id } from 'date-fns/locale';
import {
  getRegion,
  getLocality,
  applicationState,
  applicationType,
  acquirerType,
  aidCondition,
  aidForm,
  aidType,
  country,
  meetingState,
  organization,
  reciver,
  userState,
  decisonType,
  recipientTypes,
  uktzed,
  documentTypes1,
  documentTypes2,
  conReason,
  // new catalog
  getDocsReport,
  runit,
  requestType,
  requestStatus,
  fuelTypes,
} from '../../api/api';
import {
  REQUEST_CATALOG,
  SUCCESS_CATALOG,
  ERROR_CATALOG,
  SUCCESS_LOCALITY,
  REQUEST_LOCALITY,
  FETCH_UKTZED_SUCCESS,
} from './action-types';

export const setCatalogs = (data) => {
  return {
    type: SUCCESS_CATALOG,
    payload: data,
  };
};

export function getCatalogs() {
  return function (dispatch) {
    dispatch({ type: REQUEST_CATALOG });
    axios
      .all([
        getRegion(),
        applicationState(),
        applicationType(),
        acquirerType(),
        aidCondition(),
        aidForm(),
        aidType(),
        country(),
        meetingState(),
        organization(),
        reciver(),
        userState(),
        decisonType(),
        recipientTypes(),
        uktzed(),
        documentTypes1(),
        documentTypes2(),
        conReason(),
        //
        getDocsReport(),
        runit(),
        requestType(),
        requestStatus(),
        fuelTypes(),
      ])
      .then(
        axios.spread(
          (
            region,
            applicationState,
            applicationType,
            acquirerType,
            aidCondition,
            aidForm,
            aidType,
            country,
            meetingState,
            organization,
            reciver,
            userState,
            decisonType,
            recipientTypes,
            uktzed,
            documentTypes1,
            documentTypes2,
            conReason,
            // new requests
            docTypesReport,
            runit,
            requestType,
            requestStatus,
            fuelTypes
          ) => {
            const data = {
              region: region.data,
              applicationState: applicationState.data,
              applicationType: applicationType.data,
              acquirerType: acquirerType.data,
              aidCondition: aidCondition.data,
              aidForm: aidForm.data,
              aidType: aidType.data,
              country: country.data,
              meetingState: meetingState.data,
              organization: organization.data,
              reciver: reciver.data,
              userState: userState.data,
              decisonType: decisonType.data,
              recipientTypes: recipientTypes.data,
              uktzed: uktzed.data
                .filter(({ l_deleted }) => l_deleted === 0)
                .map(({ c_name, id }) => ({ label: c_name, value: id.toString() })),
              uktzedFull: uktzed.data.map(({ c_name, id }) => ({ label: c_name, value: id.toString() })),
              documentTypes1: documentTypes1.data,
              documentTypes2: documentTypes2.data,
              conReason: conReason.data,
              //
              docTypesReport: docTypesReport.data,
              runit: runit.data,
              requestType: requestType.data,
              requestStatus: requestStatus.data,
              fuelTypes: fuelTypes.data,
            };
            dispatch(setCatalogs(data));
            localStorage.setItem('catalogs', JSON.stringify(data));
          }
        )
      )
      .catch((err) => {
        dispatch({
          type: ERROR_CATALOG,
        });
      });
  };
}

export const setLocality = (data) => {
  return {
    type: SUCCESS_LOCALITY,
    payload: data,
  };
};

export const setUktzed = (data) => {
  return {
    type: FETCH_UKTZED_SUCCESS,
    payload: data,
  };
};

export const fetchUktzed = () => async (dispatch) => {
  try {
    const response = await uktzed();

    const result = {
      uktzed: response.data
        .filter(({ l_deleted }) => l_deleted === 0)
        .map(({ c_name, id }) => ({ label: c_name, value: id.toString() })),
      uktzedFull: response.data.map(({ c_name, id }) => ({ label: c_name, value: id.toString() })),
    };

    dispatch(setUktzed({ ...result }));
  } catch (error) {
    dispatch({
      type: ERROR_CATALOG,
    });
  }
};

export const localityAction = (region) => (dispatch, getState) => {
  dispatch({ type: REQUEST_LOCALITY });
  getLocality(region).then((response) => {
    if (response.data.length > 0) {
      const localityCatalog = response.data.map((item) => {
        return {
          label: `${item.c_type}${item.c_name}`,
          value: item.id,
          district: item.district_name,
        };
      });
      dispatch(setLocality(localityCatalog));
    }
  });
};

import React from 'react';

// MUI
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button } from '@material-ui/core';

// api
import Swal from 'sweetalert2';
import { deleteRecipientFromRegisterApi, cargoApply } from '../../../../api/api';

// swal

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const deleteStatus = 4; // back to status 10 for revision

class DialogApprovalDelete extends React.Component {
  state = {
    open: false,
    validationText: '',
    errorText: '',
  };

  handleClose = () => {
    this.setState({ validationText: '', errorText: '' });
    this.props.onClose();
  };

  handleDelete = (data) => {
    cargoApply(data)
      .then((resp) => {
        Swal.fire({
          position: 'center',
          type: 'success',
          title: 'Заяву подано',
          showConfirmButton: false,
          timer: 2000,
        });
        this.handleClose();
      })
      .catch((error) => {
        this.handleClose();
        // if (error.response && error.response.status === 400) {
        //   swalWithBootstrapButtons.fire({
        //     title: 'Помилка',
        //     text: error.response.data,
        //     type: 'error',
        //   });
        //   this.handleClose();
        // } else {
        //   swalWithBootstrapButtons.fire({
        //     title: 'Помилка серверу',
        //     type: 'error',
        //   });
        //   this.handleClose();
        // }
      });
  };

  render() {
    const data = {
      // application_id: this.props.application_id,
      p_id: this.props.p_new_application_id,
      // p_id: this.props.recipient.id,
      // application_type: deleteStatus,
      p_user: this.props.userId,
    };
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText style={{ color: '#000' }} id="alert-dialog-description">
              Ви дійсно бажаєте виключити отримувача {this.props.recipient.p_orgname} з Реєстру?
              <br />
              Після виключення з реєстру ви не зможете знову зареєструватися як отримувач ГД впродовж 6 місяців.
              Продовжити?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleDelete(data)} type="button" variant="contained" color="primary">
              Так
            </Button>
            <Button onClick={this.handleClose} type="button" variant="contained" color="primary">
              Відмінити
            </Button>
          </DialogActions>
          {this.state.validationText && <h4 className="text-right text-success mr-2">{this.state.validationText}</h4>}
          {this.state.errorText && <h4 className="text-right text-danger mr-2">{this.state.errorText}</h4>}
        </Dialog>
      </div>
    );
  }
}

export default DialogApprovalDelete;

import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { IconButton } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ArrowDropDownCircle from '@material-ui/icons/ArrowDropDownCircle';

import { viewDeclarationForPublic, getPublicInventoryPDF } from '../../../api/api';
import getPage from '../RegisterRecipients/RegistrationFormsRecipient/getPDFfile';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

export default function RecipientModal({ modal, toggle, docCargo }) {
  const classes = useStyles();

  const downloadDeclaration = (cargoId) => {
    viewDeclarationForPublic(cargoId)
      .then((res) => {
        const { data } = res;
        return getPage(data);
      })
      .then((file) => {
        file.open();
      })
      .catch((err) => {});
  };

  const dowloadInventory = async (cargoId) => {
    try {
      const response = await getPublicInventoryPDF(cargoId);
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {}
  };

  const downloadDocuments = (row) => {
    const { pi_cargo, doc_group } = row;
    if (doc_group === 3 && pi_cargo) {
      downloadDeclaration(pi_cargo);
    } else if (doc_group === 4 && pi_cargo) {
      dowloadInventory(pi_cargo);
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-xl" zIndex="1500">
      <ModalHeader toggle={toggle}></ModalHeader>
      <ModalBody>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell>№ з/п</StyledTableCell>
              <StyledTableCell>НАЗВА</StyledTableCell>
              <StyledTableCell>ДАТА ЗАВАНТАЖЕННЯ</StyledTableCell>
              <StyledTableCell>КОМЕНТАР</StyledTableCell>
              <StyledTableCell>ЗАВАНТАЖЕННІ ДОКУМЕНТИ</StyledTableCell>
              <StyledTableCell>СФОРМОВАНІ ДОКУМЕНТИ</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {docCargo.map((row, index) => (
              <TableRow>
                <StyledTableCell width="25%">{1 + index}</StyledTableCell>
                <StyledTableCell width="25%">{row.c_name}</StyledTableCell>
                <StyledTableCell width="25%">{row.d_load}</StyledTableCell>
                <StyledTableCell width="25%">{row.c_comment}</StyledTableCell>
                {/* <StyledTableCell width="25%">{row.c_filename}</StyledTableCell> */}
                <StyledTableCell width="25%">
                  <a href={`/aid/file/${row.c_filename}`} rel="noopener noreferrer" target="_blank">
                    {row.c_filename}
                  </a>
                </StyledTableCell>
                <StyledTableCell width="25%">
                  {row.doc_group > 0 ? (
                    <IconButton
                      color="primary"
                      aria-label="download picture"
                      component="label"
                      onClick={() => downloadDocuments(row)}
                      // disabled={!data.original.isprot}
                    >
                      <ArrowDropDownCircle />
                    </IconButton>
                  ) : null}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ModalBody>
      {/* <ModalFooter> */}
      {/* <IconButton disabled>
                    <Feather.Save />
                </IconButton> */}
      {/* <IconButton onClick={() => openModal()}>
                    <Feather.X />
                </IconButton> */}

      {/* </ModalFooter> */}
    </Modal>
  );
}

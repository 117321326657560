import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody } from 'reactstrap';
import Typography from '@material-ui/core/Typography';
import { UncontrolledAlert } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import IconButton from '@material-ui/core/IconButton';
import Print from '@material-ui/icons/Print';
import Close from '@material-ui/icons/Close';
import ResVoutingIcon from '@material-ui/icons/AddBox';

// import DownloadIcon from '@mui/icons-material/Download';
// import DownloadingIcon from '@mui/icons-material/Downloading';
// import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
// import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
// import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
// import AutorenewIcon from '@mui/icons-material/Autorenew';

import { UncontrolledTooltip } from 'reactstrap';

const ViewDocModal = (props) => {
  let componentRef = React.createRef();
  return (
    <Modal isOpen={props.modal} toggle={props.toggle} className="modal-xl">
      <ModalBody>
        <div dangerouslySetInnerHTML={{ __html: props.htmlDoc }} ref={(el) => (componentRef = el)} />
      </ModalBody>
      <ModalFooter>
        <ReactToPrint
          trigger={() => (
            <IconButton color="primary" aria-label="print" id="print">
              <Print />
            </IconButton>
          )}
          content={() => componentRef}
        />
        <UncontrolledTooltip placement="top" target="print">
          Друк
        </UncontrolledTooltip>

        <IconButton color="primary" onClick={props.exportToWord} id="save">
          Word
          {/* <ResVoutingIcon /> */}
        </IconButton>
        <UncontrolledTooltip placement="top" target="save">
          Зберегти
        </UncontrolledTooltip>

        <IconButton color="primary" onClick={props.toggle} id="exit">
          {/* <IconButton color="primary" onClick={props.exportToWord} id="exit"> */}
          <Close />
        </IconButton>
        <UncontrolledTooltip placement="top" target="exit">
          Закрити
        </UncontrolledTooltip>
      </ModalFooter>
    </Modal>
  );
};

export default ViewDocModal;

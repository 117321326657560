import React, { Component } from 'react';
// import reactFeature from '../../assets/images/react-feature.svg';
// import sassFeature from '../../assets/images/sass-feature.svg';
// import bootstrapFeature from '../../assets/images/bootstrap-feature.svg';
// import responsiveFeature from '../../assets/images/responsive-feature.svg';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Row, Col, Card, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { getCatalogs } from '../../redux/actions/catalog.action';
import { fetchAccess, fetchSwitchAccess } from '../../redux/actions/access.action';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core';
import arrowUp from '../../assets/images/arrowUp.png';
import { wrap } from 'lodash';

const style = (theme) => ({
  blocksText: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    background: '#ffffff',
    padding: '30px 10px 0 10px',
    // height: '50vh',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  blocksText2: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    background: '#ffffff',
    padding: '0 10px 30px 10px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  blocksTextSmall: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      background: '#ffffff',
      padding: '30px 10px 0 10px',
    },
  },
  blockText: {
    width: '23%',
    [theme.breakpoints.down('sm')]: {
      width: '45%',
    },
  },
  blockTextLink: {
    textDecoration: 'none',
    color: '#000000',
  },
  blockTextTitle: {
    fontFamily: 'dia',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '19px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#000000',
    margin: '0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  blockTextTitleZvit: {
    fontFamily: 'dia',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '19px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#000000',
    padding: '0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      margin: '24px 0',
    },
  },
  blockTextText: {
    fontFamily: 'dia',
    fontStyle: 'normal',
    fontWeight: '200',
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '-0.3px',
    borderTop: '2px solid black',
    paddingTop: '10px',
    color: 'rgba(0, 0, 0, 0.8)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
  blockTextThirt: {
    width: '23%',
    [theme.breakpoints.down('sm')]: {
      width: '45%',
    },
  },

  arrowImg: {
    marginLeft: '1px',
  },
});

class Dashboard extends Component {
  swithAccess = () => {
    const { fetchAccess, fetchSwitchAccess } = this.props;
    const { access } = this.props;

    fetchSwitchAccess()
      .then(() => {
        fetchAccess();
      })
      .catch((error) => console.log(error));
  };

  // cleanCache = () => {
  //   if ("caches" in window) {
  //     caches.keys().then((names) => {
  //       names.forEach((name) => {
  //         caches.delete(name);
  //       });
  //     });
  //   }
  // }

  // componentDidMount(){
  //   this.cleanCache();
  // }

  render() {
    const heroStyles = {
      padding: '50px 0 70px',
    };

    const {
      user: { c_name, c_surname, c_patronymic, c_position, c_organization, l_officer },
    } = this.props;
    const { access, accessLoading } = this.props;

    return (
      <>
        <div
          style={{ height: '50vh', display: 'flex', alignItems: 'center' }}
          // style={{ background: 'radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)' }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ height: '100%', fontFamily: 'dia' }}
          >
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="center" style={{ fontSize: '24px' }}>
                Вітаємо в системі!
              </Typography>
              <Typography variant="h6" align="center" style={{ fontSize: '32px' }}>
                {c_surname} {c_name} {c_patronymic}
              </Typography>
              <Typography variant="subtitle1" align="center" style={{ fontSize: '14px' }}>
                Посада: {c_position}
              </Typography>
              <Typography variant="subtitle1" align="center" style={{ fontSize: '14px' }}>
                Роль: {c_organization}
              </Typography>
            </Grid>
            {/* <Grid item xs={12}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {l_officer === 1 ? (
                <Button color={access ? 'danger' : 'success'} onClick={this.swithAccess} disabled={accessLoading}>
                  {access ? 'Закрити' : 'Відкрити'}
                  <br />
                  доступ
                  <br />
                  користувачам
                </Button>
              ) : (
                <></>
              )}
            </div>
          </Grid> */}
          </Grid>
        </div>
        <div className={this.props.classes.blocksText}>
          <div className={this.props.classes.blockText}>
            <p className={this.props.classes.blockTextTitle}>
            <a href='/create-recipient/save' className={this.props.classes.blockTextLink} style={{textDecoration: 'none'}}> Реєстрація отримувача </a> 
              <img src={arrowUp} className={this.props.classes.arrowImg} alt="buttonGovUa" />
            </p>
          </div>
          <div className={this.props.classes.blockText}>
            <p className={this.props.classes.blockTextTitle}>
            <a href='/pages/delivery' className={this.props.classes.blockTextLink} style={{textDecoration: 'none'}}>Реєстрація вантажу</a> 
              <img src={arrowUp} className={this.props.classes.arrowImg} alt="buttonGovUa" />
            </p>
          </div>
          <div className={this.props.classes.blockText}>
            <p className={this.props.classes.blockTextTitle}>
            <a href='/inventory-description' className={this.props.classes.blockTextLink} style={{textDecoration: 'none'}}> Формування інвентаризаційного опису </a>
              <img src={arrowUp} className={this.props.classes.arrowImg} alt="buttonGovUa" />
            </p>
          </div>
          <div className={this.props.classes.blockText}>
            <p className={this.props.classes.blockTextTitle}>
            <a href='/report_description' className={this.props.classes.blockTextLink} style={{textDecoration: 'none'}}> Звіт </a>
              <img src={arrowUp} className={this.props.classes.arrowImg} alt="buttonGovUa" />
            </p>
          </div>
        </div>
        <div className={this.props.classes.blocksText2}>
          <div className={this.props.classes.blockText}>
            <p className={this.props.classes.blockTextText}>
              Розпочніть роботу з пункту меню "Дані отримувача", якщо Ви ще не зареєстровані в Єдиному реєстрі
              отримувачів гуманітарної допомоги
            </p>
          </div>
          <div className={this.props.classes.blockText}>
            <p className={this.props.classes.blockTextText}>
              Якщо отримувач зареєстрований в Єдиному реєстрі отримувачів гуманітарної допомоги, для реєстрації вантажу
              перейдіть до меню "Мої вантажі/Декларація"
            </p>
          </div>
          <div className={this.props.classes.blockText}>
            <p className={this.props.classes.blockTextText}>
              Заповніть Інвентаризаційний опис не пізніше ніж через 15 календарних днів з дати проставлення відмітки про
              митне оформлення. Для цього перейдіть до пункту меню "Інвентаризаційний опис"
            </p>
          </div>
          <div className={this.props.classes.blockText}>
            <p className={this.props.classes.blockTextText}>
              Подайте звіт до 15 числа кожного місяця, наступнного за звітним. Для цього перейдіть до пункту меню "Звіт"
            </p>
          </div>
        </div>
        <div className={this.props.classes.blocksTextSmall}>
          <div className={this.props.classes.blockText}>
            <p className={this.props.classes.blockTextTitle}>
              <a href='/create-recipient/save' className={this.props.classes.blockTextLink} style={{textDecoration: 'none'}}> Реєстрація отримувача </a>
              <img src={arrowUp} className={this.props.classes.arrowImg} alt="buttonGovUa" />
            </p>
            <p className={this.props.classes.blockTextText}>
              Розпочніть роботу з пункту меню "Дані отримувача", якщо Ви ще не зареєстровані в Єдиному реєстрі
              отримувачів гуманітарної допомоги
            </p>
          </div>
          <div className={this.props.classes.blockText}>
            <p className={this.props.classes.blockTextTitle}>
              <a href='/pages/delivery' className={this.props.classes.blockTextLink} style={{textDecoration: 'none'}}>Реєстрація вантажу</a>
              <img src={arrowUp} className={this.props.classes.arrowImg} alt="buttonGovUa" />
            </p>
            <p className={this.props.classes.blockTextText}>
              Якщо отримувач зареєстрований в Єдиному реєстрі отримувачів гуманітарної допомоги, для реєстрації вантажу
              перейдіть до меню "Мої вантажі/Декларація"
            </p>
          </div>
          <div className={this.props.classes.blockText}>
            <p className={this.props.classes.blockTextTitle}>
              <a href='/inventory-description' className={this.props.classes.blockTextLink} style={{textDecoration: 'none'}}> Формування інвентаризаційного опису </a>
              <img src={arrowUp} className={this.props.classes.arrowImg} alt="buttonGovUa" />
            </p>
            <p className={this.props.classes.blockTextText}>
              Заповніть Інвентаризаційний опис не пізніше ніж через 15 календарних днів з дати проставлення відмітки про
              митне оформлення. Для цього перейдіть до пункту меню "Інвентаризаційний опис"
            </p>
          </div>
          <div className={this.props.classes.blockText}>
            <p className={this.props.classes.blockTextTitleZvit}>
              <a href='/report_description' className={this.props.classes.blockTextLink} style={{textDecoration: 'none'}}> Звіт </a>
              <img src={arrowUp} className={this.props.classes.arrowImg} alt="buttonGovUa" />
            </p>
            <p className={this.props.classes.blockTextText}>
              Подайте звіт до 15 числа кожного місяця, наступнного за звітним. Для цього перейдіть до пункту меню "Звіт"
            </p>
          </div>
        </div>

        {/* <Row>
          <Col md={8}>
            <div className="home-hero" style={heroStyles}>
              <h1>Вітаємо в системі!</h1>
              <h3>Для подальшої роботи перейдіть до необхідного пункту меню.</h3>
              <h3>Гуманітарна допомога</h3>
              <p className="text-muted">
                Електронна соціальна послуга для забезпечення контролю та обліку цільової адресної безплатної допомоги в
                грошовій або натуральній формі, у вигляді безповоротної фінансової допомоги або добровільних
                пожертвувань, або допомога у вигляді виконання робіт, надання послуг, що надається іноземними та
                вітчизняними донорами з гуманних мотивів одержувачам гуманітарної допомоги в Україні або за кордоном.
              </p>
            </div>
          </Col>
          <Col md={4}>
            <Card body>
              <h5>
                {this.props.user.c_surname} {this.props.user.c_name} {this.props.user.c_patronymic}
              </h5>
              <h5 className="text-muted">{this.props.user.c_organization}</h5>
              <h5 className="text-muted">{this.props.user.c_position}</h5>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2 style={{ textAlign: 'center', color: 'tomato', paddingTop: '10%' }}>
              УВАГА!
              <br />
              Автоматизована система працює в бета-версії.
              <br />
              Можливе обмеження функціоналу в деяких режимах.
            </h2>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {l_officer === 1 ? (
                <Button color={access ? 'danger' : 'success'} onClick={this.swithAccess} disabled={accessLoading}>
                  {access ? 'Закрити' : 'Відкрити'}
                  <br />
                  доступ
                  <br />
                  користувачам
                </Button>
              ) : (
                <></>
              )}
            </div>
          </Col>
        </Row> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  access: state.access.value,
  accessLoading: state.access.isLoading,
});

export default withRouter(
  connect(mapStateToProps, { getCatalogs, fetchAccess, fetchSwitchAccess })(withStyles(style)(Dashboard))
);

import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

export const useStylesTable = (theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

export const useStyles = (theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonDanger: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  fabProgressApprove: {
    color: green[500],
    position: 'absolute',
    top: 5,
    left: 0,
    zIndex: 1,
  },
  fabProgressDenied: {
    color: green[500],
    position: 'absolute',
    top: 5,
    left: 30,
    zIndex: 1,
  },
});

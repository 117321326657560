import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { AssignmentInd } from '@material-ui/icons';
import { CustomTablePagination } from '../../RegisterRecipients/StyledTableComponents';
import CustomLoader from '../../../../vibe/components/CustomLoader/CustomLoader';

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const RecipientRow = ({ history, item, index }) => {
  const {
    id,
    reg_num = '',
    recip_region = '',
    recip_locality = '',
    recip_edrpou = '',
    recip_orgname = '',
    dateStart = '',
    d_delete = '',
    acq_edrpou = '',
    acq_orgname = '',
    aid_type = '',
    aid_name = '',
    distribution_date = '',
    acq_address = '',
    rn = '',
  } = item;

  return (
    <TableRow>
      <StyledTableCell>{rn}</StyledTableCell>
      <StyledTableCell>{reg_num}</StyledTableCell>
      <StyledTableCell>{recip_region}</StyledTableCell>
      <StyledTableCell>{recip_locality}</StyledTableCell>
      <StyledTableCell>{recip_edrpou}</StyledTableCell>
      <StyledTableCell>{recip_orgname}</StyledTableCell>
      <StyledTableCell>{dateStart}</StyledTableCell>
      <StyledTableCell>{d_delete}</StyledTableCell>
      <StyledTableCell>{acq_edrpou}</StyledTableCell>
      <StyledTableCell>{acq_orgname}</StyledTableCell>
      <StyledTableCell>{aid_type}</StyledTableCell>
      <StyledTableCell>{aid_name}</StyledTableCell>
      <StyledTableCell>{distribution_date}</StyledTableCell>
      <StyledTableCell>{acq_address}</StyledTableCell>
      <StyledTableCell>
        <div className="actions-right">
          <IconButton
            onClick={() => {
              history.push(`/recipient-info/${id}/view`);
            }}
            color="primary"
            className="like"
          >
            <AssignmentInd />
          </IconButton>{' '}
        </div>
      </StyledTableCell>
    </TableRow>
  );
};

const RecipientTableV2 = ({ tableData, history, isLoading }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const perPage = Number(event.target.value) || 5;
    setRowsPerPage(perPage);
    setPage(0);
  };

  const dataSlice = tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <>
      <div className="table-responsive">
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Номер за порядком</StyledTableCell>
              <StyledTableCell>Номер в Реєстрі</StyledTableCell>
              <StyledTableCell>Область</StyledTableCell>
              <StyledTableCell>Населений пункт</StyledTableCell>
              <StyledTableCell>Код ЄДРПОУ отримувача</StyledTableCell>
              <StyledTableCell>Назва отримувача</StyledTableCell>
              <StyledTableCell>Дата включення в реєстр</StyledTableCell>
              <StyledTableCell>Дата виключення з реєстру</StyledTableCell>
              <StyledTableCell>Код ЄДРПОУ набувача</StyledTableCell>
              <StyledTableCell>Назва набувача</StyledTableCell>
              <StyledTableCell>Категорія товару</StyledTableCell>
              <StyledTableCell>Найменування товару</StyledTableCell>
              <StyledTableCell>Дата розподілу товару</StyledTableCell>
              <StyledTableCell>Адреса розподілу</StyledTableCell>
              <StyledTableCell>Перегляд</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSlice.length > 0 ? (
              dataSlice.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <RecipientRow key={index} item={item} index={index} history={history} />
              ))
            ) : (
              <CustomLoader isLoading={isLoading} />
            )}
          </TableBody>
        </Table>
      </div>
      <CustomTablePagination
        array={tableData}
        page={page}
        handleChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default RecipientTableV2;

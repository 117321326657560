import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import CalendarCard from './CalendarCard';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: '300px',
    maxWidth: '456px',
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #14141433',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 3),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
}));

export default function CalendarModal({ open, handleClose }) {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.paper}>
        <CalendarCard handleClose={handleClose} />
      </div>
    </Modal>
  );
}

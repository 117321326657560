// packages
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import uk from 'date-fns/locale/uk';
import Swal from 'sweetalert2';
// styles
import { Card, CardContent, CardActions, Button, TextField, Icon } from '@material-ui/core';
import { customTheme, customStyles } from '../../../customStyles/reactSelect';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-table/react-table.css';
// component
import RegisterCommission from './RegisterCommissionMeetingsFullScreen';
// api
import { getCommissionsByFilters, getMeetingList } from '../../../api/api';
import {
  setCurrentCommission,
  clearCurrentCommission,
  setMeetingAction,
  commisionID,
} from '../../../redux/actions/commissionCreate.action';
import BootstrapTooltip from '../../elements/MaterialBootstrap';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});
const ALL_COMMISSIONS = 0;

class Сommission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      date_from: '',
      date_till: '',
      applicationState: '',
      visible: false,
    };
    this.handleVouting = this.handleVouting.bind(this);
  }

  // datapicker
  handleChangeData = (name) => (data) => {
    this.setState({ [name]: data });
  };

  // select
  handleSelectChange = (name) => (select, { action }) => {
    const objDelete = {};
    objDelete[name] = '';
    switch (action) {
      case 'select-option':
        const obj = {};
        obj[name] = select;
        this.setState(obj);
        break;
      case 'clear':
        this.setState(objDelete);
        break;
    }
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.setState({
      submitted: true,
      loading: true,
    });
    const { date_from, date_till, applicationState } = this.state;
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    const data = {
      p_user_id: this.props.user.id,
      p_only_my: ALL_COMMISSIONS,
      p_state: applicationState ? (applicationState || {}).value : '',
      p_dt_beg: date_from ? date_from.toLocaleDateString('uk', options) : '',
      p_dt_end: date_till ? date_till.toLocaleDateString('uk', options) : '',
    };

    getCommissionsByFilters(data)
      .then((response) => {
        this.setState({ data: response.data, visible: true, loading: false, visible: true });
      })
      .catch((error) => {
        this.setState({ data: [], loading: false });
        // if (error.response && error.response.status === 400) {
        //   swalWithBootstrapButtons.fire({
        //       title: 'Помилка',
        //       text: error.response.data,
        //       type: 'error',
        //   })
        // } else {
        //   swalWithBootstrapButtons.fire({
        //       title: 'Помилка серверу',
        //       type: 'error',
        //   })
        // }
      });
  };

  componentDidMount() {
    this.props.clearCurrentCommission();
  }

  async handleVouting(id) {
    try {
      const response = await getMeetingList(id, this.props.user.id);
      this.props.setMeetingAction(response.data);
      this.props.commisionID(id);
      this.props.history.push('/vouting');
    } catch (error) {
      swalWithBootstrapButtons.fire({
        title: error.response.data,
        type: 'error',
      });
    }
  }

  render() {
    const { selectedOption } = this.state;

    return (
      <div className="commissionMeeting">
        <section className="searchCommissionMeeting">
          <Card>
            <form onSubmit={this.handleSearch}>
              <CardContent>
                <div className="row justify-content-md-center">
                  <div className="col-12 col-sm-6  col-md-4 col-xl-3">
                    {/* <label htmlFor="">Рішення з:</label> */}

                    <DatePicker
                      selected={this.state.date_from}
                      onChange={this.handleChangeData('date_from')}
                      dateFormat="dd.MM.yyyy"
                      maxDate={new Date()}
                      locale={uk}
                      className="datePicker"
                      icon={<i className="cal-icon" />}
                      customInput={<TextField label="Рішення з:" />}
                    />
                  </div>

                  <div className="col-12 col-sm-6  col-md-4 col-xl-3">
                    {/* <label htmlFor="">по:</label> */}
                    <DatePicker
                      selectsEnd
                      selected={this.state.date_till}
                      startDate={this.state.date_from}
                      minDate={this.state.date_from}
                      onChange={this.handleChangeData('date_till')}
                      dateFormat="dd.MM.yyyy"
                      maxDate={new Date()}
                      className="datePicker"
                      locale={uk}
                      customInput={<TextField label="по:" />}
                    />
                  </div>

                  <div className="col-12 col-sm-6  col-md-4 col-xl-3">
                    <label htmlFor="">Стан в Реєстрі:</label>
                    <BootstrapTooltip title="Оберіть стан зі списку">
                      <div>
                        <Select
                          value={selectedOption}
                          onChange={this.handleSelectChange('applicationState')}
                          options={this.props.catalog.meetingState}
                          isSearchable
                          isClearable
                          placeholder=""
                          styles={customStyles}
                          placeholder=""
                          noOptionsMessage={() => 'помилка серверу'}
                          menuPortalTarget={document.body}
                          theme={customTheme}
                          name="applicationState"
                        />
                      </div>
                    </BootstrapTooltip>
                  </div>
                </div>
              </CardContent>
              <CardActions>
                <Button
                  type="submit"
                  // variant="contained"
                  color="primary"
                  style={{ marginLeft: 'auto', marginRight: 'auto' }}
                >
                  <Icon className="fa fa-search mr-2" /> Пошук
                </Button>
              </CardActions>
            </form>
          </Card>
        </section>
        <section className="registerCommissionMeetings mt-3">
          {this.state.visible ? (
            <RegisterCommission
              setCurrentCommission={this.props.setCurrentCommission}
              loading={this.state.loading}
              handleVouting={this.handleVouting}
              commisionID={this.props.commisionID}
              data={this.state.data}
            />
          ) : (
            ''
          )}
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    catalog: state.catalogs,
    user: state.auth.user,
  };
};

export default withRouter(
  connect(mapStateToProps, { setCurrentCommission, clearCurrentCommission, setMeetingAction, commisionID })(Сommission)
);

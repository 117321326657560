import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Landing from '../Public/Landing';
import TabPanel from '../LandingPage/TabPanel';
import { setCatalogs, getCatalogs } from '../../../redux/actions/catalog.action';
import '../LandingPage/landingPage.css';
import headerLogo from '../../../assets/images/ASRGD_Logo 3.svg';
import FooterComponent from '../LandingPage/FooterComponent/FooterComponent';
import AdminLogin from './AdminLogin';

function AdminPage({ auth, history, match, setCatalogs, getCatalogs }) {
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (auth.isAuthenticated) {
      history.push('/home');
    }
  }, [auth.isAuthenticated, history]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div className="page">
        <div className="modalHeader">
          <a href="https://www.ioc.gov.ua">
            <img className="modalLogo" src={headerLogo} alt="modalLogo"/>
          </a>
        </div>
        <div className="main">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="primary"
            className="tabs"
          >
            <Tab disableRipple disableFocusRipple label="Перевірка гуманітарної допомоги" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <div className="loginPageBlock">
              <div className="Landing">
                <Landing />
              </div>
              <div className="loginBlock">
                <AdminLogin />
              </div>
            </div>
          </TabPanel>
        </div>
        <FooterComponent/>
      </div>
    </div>
  );
}

AdminPage.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  setCatalogs: PropTypes.func.isRequired,
  getCatalogs: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default withRouter(connect(mapStateToProps, { setCatalogs, getCatalogs })(AdminPage));

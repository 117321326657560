import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import StartWidget from './StartWidget';

const useStyles = makeStyles({
  dialog: {
    zIndex: '10002 !important',
  },
  autoPopper: {
    zIndex: '10002 !important',
  },
});

const DialogSign = ({ openSign, handleCloseSign, doSignAction, string64, cargoId }) => {
  const classes = useStyles();
  return (
    <Dialog
      classes={{ root: classes.dialog }}
      open={openSign}
      onClose={handleCloseSign}
      aria-labelledby="sign-dialog-title"
      aria-describedby="sign-dialog-description"
      fullWidth
      fullScreen
    >
      <DialogTitle id="sign-dialog-title">Накладення підпису</DialogTitle>
      <DialogContent>
        <StartWidget string64={string64} cargoId={cargoId} />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button variant="contained" color="primary" onClick={doSignAction} autoFocus>
          Підписати
        </Button>
        <Button variant="contained" color="primary" onClick={handleCloseSign}>
          Скасувати
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSign;

// packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import Select from 'react-select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
// import { Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import List from '@material-ui/core/List';
import { Icon, TextField, withStyles, Grid, Typography } from '@material-ui/core';
import TopBarProgress from 'react-topbar-progress-indicator';
// import * as Feather from 'react-feather';
import Swal from 'sweetalert2';
import Divider from '@material-ui/core/Divider';
import { Autocomplete } from '@material-ui/lab';
import {
  getCargoByIdFullNewOrder,
  editAidAction,
  editCargoAction,
  cargoAidCollection,
  clearAqcuirer,
  getAcquirerByID,
  getAidCargoCollection,
  addAquirerAction,
  cargoAction,
  addCargoAction,
  deleteCargoAction,
  setCargoAction,
  saveCargoAction,
  clearCargo,
} from '../../../../redux/actions/cargo.action';
import { getRecipientsForSelect, currentAidId } from '../../../../redux/actions/recipient.action';
import { localityAction } from '../../../../redux/actions/catalog.action';
import { toggleAction } from '../../../../redux/actions/toggle.action';
// import PlanDistribution from './PlanDistribution';
import {
  saveCargoAid,
  saveCargoAquire,
  deleteAqcuirer,
  deleteAid,
  cargoSetApply,
  cargosetapply,
  viewDeclaration,
  // getPDFCargo,
  getPDFForCargo,
} from '../../../../api/api';
// styles
// import { customTheme, customStyles } from '../../../../customStyles/reactSelect';
import AddCargo from './AddCargo';
import AddAquirerModal from './AddAquirerModal';
import BootstrapTooltip from '../../../elements/MaterialBootstrap';

import add from '../../../../assets/images/add.png';
import next from '../../../../assets/images/next.png';
import save from '../../../../assets/images/save.png';
import arrowBack from '../../../../assets/images/arrowBack.png';

import getPage from '../../RegisterRecipients/RegistrationFormsRecipient/getPDFfile';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
});

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const style = (theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  buttons_save: {
    display: 'flex',
    width: '100%',
    alignContent: 'space-between',
    margin: '30px auto',
  },
  button_style: {
    alignItems: 'center',
    padding: '20px 20px',
    gap: '4px',
    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',
    margin: '30px auto',
    fontFamily: 'diaHead',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
  },
  button_styleRegister: {
    alignItems: 'center',
    padding: '20px 20px',
    gap: '4px',
    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',
    margin: '30px auto',
    fontFamily: 'diaHead',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      width: '70%',
      fontSize: '16px',
    },
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
  },
  button_styleDecl: {
    alignItems: 'center',
    padding: '20px 20px',
    gap: '4px',
    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',
    margin: '30px auto',
    fontFamily: 'diaHead',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      margin: '30px 15px',
      width: '70%',
    },
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
  },
  buttonSection: {
    display: 'flex',
    width: '100%',
    alignContent: 'space-around',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },

  button_loading_img: {
    marginLeft: '15px',
  },
  buttonR_loading_img: {
    marginRight: '15px',
  },
});

class Statement extends Component {
  state = {
    selectedOption: '',
    modal: false,
    index: '',
    aid: '',
    error: '',
    loading: false,
    open: false,
  };

  handleSelectChange = (name) => (e, select) => {
    if (select) {
      this.props.cargoAction(name, parseInt(select.value));
      if (name === 'i_region') {
        this.props.localityAction(parseInt(select.value));
      }

      if (name === 'i_acquirertype') {
        if (parseInt(select.value) === 1) {
          this.props.cargoAction('c_orgname', '');
          this.props.cargoAction('n_edrpou', '');
          this.props.cargoAction('c_surname', '');
          this.props.cargoAction('c_name', '');
          this.props.cargoAction('c_patronymic', '');
          this.props.cargoAction('i_region', '');
          this.props.cargoAction('i_locality', '');
          this.props.cargoAction('c_adress', '');
        }

        if (parseInt(select.value) === 2) {
          this.props.cargoAction('n_acqcnt', '');
        }
      }
    }
  };

  handleChangeSelect = (select, name, index) => {
    if (select) {
      // eslint-disable-next-line react/destructuring-assignment
      const rows = [...this.props.cargoCollection];

      rows[index][name] = select.value;

      if (name === 'i_aidtype' && Number(rows[index].i_aidtype) === 6) {
        rows[index].n_count = 1;
        rows[index].n_sum = 1;
        rows[index].i_unit2 = '25';
      }

      // eslint-disable-next-line react/destructuring-assignment
      this.props.setCargoAction(rows);
    }
  };

  handleChange = (e) => {
    this.props.cargoAction(e.target.name, e.target.value.toUpperCase());
    this.setState({ error: '' });
  };

  handleAgreementChange = (event) => {
    const { name, checked } = event.target;
    this.props.cargoAction(name, checked ? 1 : 0);
  };

  handleChangeNumberFormat = (name, value) => {
    this.props.cargoAction(name, value);
    this.setState({ error: '' });
  };

  handleChangeRadio = (event) => {
    const { value } = event.target;
    // eslint-disable-next-line no-shadow
    const { cargoAction } = this.props;
    cargoAction('l_private', value);
  };

  getRouteSave = () => {
    return (
      this.props.location.pathname === '/create-delivery/save' ||
      this.props.location.pathname === '/create-recipient/save'
    );
  };

  getRoute = () => {
    return (
      this.props.location.pathname === '/create-delivery/save' ||
      this.props.location.pathname === '/create-delivery/edit' ||
      this.props.location.pathname === '/edit-cargo'
    );
  };

  componentDidMount() {
    if (!this.getRouteSave()) {
      this.props.getRecipientsForSelect(this.props.user.id);
    }

    // if (this.getRouteSave()) {
    //   this.props.clearCargo()
    // }

    if (this.getEditRoure()) {
      this.props.getCargoByIdFullNewOrder(this.props.cargo.i_cargo);
    }
  }

  getRouteDeliveryEdit = () => {
    return (
      this.props.location.pathname === '/create-delivery/edit' ||
      this.props.location.pathname === '/create-recipient/edit'
    );
  };

  getEditRoure = () => {
    return this.props.location.pathname === '/edit-cargo';
  };

  toggleAid = (index, aid) => {
    this.props.clearAqcuirer();
    this.setState((prevState) => ({ modal: !prevState.modal, index, aid }));
  };

  saveAid = (index) => {
    this.setState({ loading: true });
    const { i_aidtype } = this.props.cargoCollection[index];
    const data = {
      i_aid: this.props.cargoCollection[index].i_aid,
      // i_aidtype: this.props.cargoCollection[index].i_aidtype,
      i_cargo: this.props.cargo.i_cargo,
      c_description: this.props.cargoCollection[index].c_description,
      i_unit: this.props.cargoCollection[index].i_unit,
      i_unit2: this.props.cargoCollection[index].i_unit2,
      // n_count: Number(this.props.cargo.i_aidform) === 4 ? 1 : this.props.cargoCollection[index].n_count,
      n_count: this.props.cargoCollection[index].n_count,
      l_deleted: this.props.cargoCollection[index].n_count,
      // i_aidcondition: this.props.cargoCollection[index].i_aidcondition,
      // new fields
      // both
      // new
      // c_category: this.props.cargoCollection[index].c_category || this.props.cargoCollection[index].c_vehicle_category,
      // vantazh 1
      n_weight: this.props.cargoCollection[index].n_weight || '',
      // c_service_life: this.props.cargoCollection[index].c_service_life_term || '',
      // c_expiration_date: this.props.cargoCollection[index].c_exploitation_term || '',
      // vantazh 4
      // n_comm_year: this.props.cargoCollection[index].n_year_use_start || '',
      // n_reg_year: this.props.cargoCollection[index].c_first_registration || '',
      // c_vehicle_type: this.props.cargoCollection[index].c_vehicle_type || '',
      // c_category: this.props.cargoCollection[index].c_vehicle_category || '',
      // c_brand: this.props.cargoCollection[index].c_vehicle_brand || '',
      c_model: this.props.cargoCollection[index].c_vehicle_model || '',
      // c_reg: this.props.cargoCollection[index].c_vehicle_num || '',
      // c_engine: this.props.cargoCollection[index].c_vehicle_engine_num || '',
      // c_vin: this.props.cargoCollection[index].c_vehicle_VIN_code || '',
      // c_body_numb: this.props.cargoCollection[index].c_truckbody_num || '',
      // c_ch: this.props.cargoCollection[index].c_chassis_num || '',

      // c_owners: this.props.cargoCollection[index].c_owners || '',
      // index to save
      // i_uktzed: this.props.cargoCollection[index].i_aidtype || '',
      i_aidtype: this.props.cargoCollection[index].i_aidtype || '',
      // кількість місць
      n_sum: this.props.cargoCollection[index].n_sum || '',
      //
      // transport params
      c_vin: Number(i_aidtype) === 6 ? this.props.cargoCollection[index].c_vin : '',
      c_brand: Number(i_aidtype) === 6 ? this.props.cargoCollection[index].c_brand : '',
      c_specification: Number(i_aidtype) === 6 ? this.props.cargoCollection[index].c_specification : '',
      n_capacity: Number(i_aidtype) === 6 ? this.props.cargoCollection[index].n_capacity : '',
      // c_engine: Number(i_aidtype) === 6 ? this.props.cargoCollection[index].c_engine : '',
      i_fuel_type: Number(i_aidtype) === 6 ? this.props.cargoCollection[index].c_engine : '',
      n_comm_year: Number(i_aidtype) === 6 ? this.props.cargoCollection[index].n_comm_year : '',
    };
    saveCargoAid(data)
      .then((response) => {
        // this.props.currentAidId(response.data.i_aid)
        this.props.getAidCargoCollection(response.data.i_cargo);
        this.props.editAidAction(index, false);
        this.setState({ loading: false });

        Toast.fire({
          type: 'success',
          title: 'Дані збережено!',
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  saveCargo = () => {
    const {
      cargo: { l_private },
    } = this.props;
    const data = {
      i_cargo: this.props.cargo.i_cargo || null,
      i_donor: this.props.cargo.i_donor || null,
      // donor_country: this.props.cargo.donor_country,
      donor_name: this.props.cargo.donor_name,
      i_country: this.props.cargo.i_country,
      c_details: this.props.cargo.c_details,
      i_recipient: this.getRoute() ? this.props.cargo.i_recipient : this.props.i_recipient,
      // i_aidform: this.props.cargo.i_aidform,
      i_aidform: 1,
      i_applicant: this.props.user.id,
      i_cargo_app: this.props.cargo.i_cargo_app,
      n_number: this.props.cargo.n_number,
      l_full: this.props.cargo.l_full,
      alloved: this.props.cargo.alloved,
      // eslint-disable-next-line no-nested-ternary
      l_private: l_private === 'yes' ? 1 : l_private === 'no' ? 0 : '',
      // new parameters
      // c_reg_number: this.props.cargo.c_donor_regnumber,
      // c_ag_surname: this.props.cargo.c_head_surname,
      // c_ag_name: this.props.cargo.c_head_firstname,
      // c_ag_patron: this.props.cargo.c_head_patronymic,
      // c_proposal: this.props.cargo.c_written_proposal,
      // c_enumer: this.props.cargo.c_invoice,
      // c_packing: this.props.cargo.c_package_letter,
      // c_docs: this.props.cargo.c_other_accompany_docs,
    };
    this.props.saveCargoAction(JSON.stringify(data));
  };

  editCargo = () => {
    const {
      cargo: { l_private },
    } = this.props;
    const data = {
      i_cargo: this.props.cargo.i_cargo,
      i_donor: this.props.cargo.i_donor,
      // donor_country: this.props.cargo.donor_country,
      donor_name: this.props.cargo.donor_name,
      i_country: this.props.cargo.i_country,
      c_details: this.props.cargo.c_details,
      i_recipient: this.getRoute() ? this.props.cargo.i_recipient : this.props.i_recipient,
      // i_aidform: this.props.cargo.i_aidform,
      i_aidform: 1,
      i_applicant: this.props.user.id,
      i_cargo_app: this.props.cargo.i_cargo_app,
      n_number: this.props.cargo.n_number,
      l_full: this.props.cargo.l_full,
      alloved: this.props.cargo.alloved,
      // eslint-disable-next-line no-nested-ternary
      l_private: l_private === 'yes' ? 1 : l_private === 'no' ? 0 : '',
      // new parameters
      // c_reg_number: this.props.cargo.c_donor_regnumber,
      // c_ag_surname: this.props.cargo.c_head_surname,
      // c_ag_name: this.props.cargo.c_head_firstname,
      // c_ag_patron: this.props.cargo.c_head_patronymic,
      // c_proposal: this.props.cargo.c_written_proposal,
      // c_enumer: this.props.cargo.c_invoice,
      // c_packing: this.props.cargo.c_package_letter,
      // c_docs: this.props.cargo.c_other_accompany_docs,
    };
    this.props.editCargoAction(JSON.stringify(data));
    // this.props.getAprovedRecipients(JSON.stringify(data));
  };

  addCargo = () => {
    const data = {
      i_aid: 0,
      i_unit: '',
      n_count: '',
      i_aidtype: '',
      i_aidcondition: '',
      c_description: '',
      i_aidform: this.props.cargo.i_aidform,
      l_deleted: '',
      i_cargo: this.props.cargo.i_cargo,
      isEdit: true,
      acquirer: [],
    };
    this.props.addCargoAction(data);

    // this.setState({loading: true})
    // saveCargoAid(data)
    // .then(response => {
    //   //this.props.currentAidId(response.data.i_aid)
    //   this.props.getAidCargoCollection(response.data.i_cargo)
    //   this.setState({loading: false})
    //   //this.props.editAidAction(index, true)
    // })
    // .catch(error => {
    //   this.setState({loading: false})
    //   swalWithBootstrapButtons.fire({
    //     type:'error',
    //     text: error.response.data
    //   })
    // })

    // this.props.addCargoAction(data)
  };

  addAcquirer = (index) => {
    const data = {
      i_aid: this.state.aid,

      // i_acquire: this.props.cargo.i_acquire, this for edit
      // this for new row with 0
      i_acquire: this.props.cargoAquirer.i_acquire,
      i_acquirertype: this.props.cargoAquirer.i_acquirertype,
      c_category: this.props.cargoAquirer.c_category,
      c_surname: this.props.cargoAquirer.c_surname,
      c_name: this.props.cargoAquirer.c_name,
      c_patronymic: this.props.cargoAquirer.c_patronymic,
      n_phone: this.props.cargoAquirer.n_phone,
      i_locality: this.props.cargoAquirer.i_locality,
      c_adress: this.props.cargoAquirer.c_adress,
      c_orgname: this.props.cargoAquirer.c_orgname,
      i_region: this.props.cargoAquirer.i_region,
      n_countplan: this.props.cargoAquirer.n_countplan,
      i_aidplan: this.props.cargoAquirer.i_aidplan,
      i_cargo: this.props.cargo.i_cargo,
      // 4 new fields
      n_edrpou: this.props.cargoAquirer.n_edrpou,
      n_acqcnt: this.props.cargoAquirer.n_acqcnt,
      c_reason: this.props.cargoAquirer.c_reason,
      // p_weight
      p_weight: this.props.cargoAquirer.p_weight,
      // old index
      index,
    };
    this.setState({ loading: true });

    saveCargoAquire(data)
      .then((response) => {
        // this.props.addAquirerAction(data);
        this.props.getAidCargoCollection(response.data.i_cargo);
        Toast.fire({
          type: 'success',
          title: 'Дані збережено!',
        });
        this.setState((prevState) => ({ modal: !prevState.modal, aid: '', loading: false }));
      })
      .catch((error) => {
        // if (error.response.status === 400) {
        //   this.setState({error: error.response.data, loading: false})
        // } else {

        //   this.setState({error: "Помилка сервера!", loading: false})
        // }
        this.setState({ error: 'Помилка сервера!', loading: false });
      });

    // this.toggle(index)
  };

  filterAid = (inputValue) => {
    return this.props.catalog.aidType.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  filterUktzed = (inputValue) => {
    return this.props.catalog.uktzed.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  filterCode = (inputValue) => {
    return this.props.catalog.uktzed.filter((i) => i.value.startsWith(inputValue));
  };

  promiseCode = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.filterCode(inputValue));
      }, 1000);
    });

  promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.filterUktzed(inputValue));
      }, 1000);
    });

  handleChangeInputsArray = (e, item, index) => {
    const rows = [...this.props.cargoCollection];
    if (typeof e === 'object') {
      rows[index][e.target.name] = e.target.value;
    } else {
      rows[index][item] = e;
    }

    this.props.setCargoAction(rows);
  };

  filterOptions = (candidate, input) => {
    if (candidate.data.label.toLowerCase().includes(input.toLowerCase())) {
      return true;
      // eslint-disable-next-line no-else-return
    } else if (`${candidate.data.erNumber}`.startsWith(`ЄР${input}`)) {
      return true;
      // eslint-disable-next-line no-else-return
    } else if (`${candidate.data.edrpou}`.startsWith(input)) {
      return true;
    } else {
      return false;
    }
  };

  toggle = () => {
    this.setState((prevState) => ({ modal: !prevState.modal, error: '' }));
  };

  editAquirer = (id, aid) => {
    this.props.getAcquirerByID(id);
    // this.props.getAcquirerByID(-29149);

    this.setState((prevState) => ({ modal: !prevState.modal, aid }));
  };

  deleteItemAcquirer = (id) => {
    this.setState({ loading: true });
    deleteAqcuirer(id)
      .then((response) => {
        this.props.getAidCargoCollection(this.props.cargo.i_cargo);
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  deleteAidById = (id) => {
    swalWithBootstrapButtons
      .fire({
        text: 'Ви дійсно бажаєте видалити допомогу',
        showCancelButton: true,
        confirmButtonText: 'Так, видалити допомогу',
        cancelButtonText: 'Відміна',
      })
      .then((result) => {
        if (result.value) {
          this.setState({ loading: true });
          deleteAid(id)
            .then((response) => {
              this.setState({ loading: false });
              swalWithBootstrapButtons
                .fire({
                  type: 'success',
                  text: 'Допомогу видалено!',
                })
                .then((result) => {
                  if (result.value) {
                    this.props.getAidCargoCollection(this.props.cargo.i_cargo);
                  }
                });
            })
            .catch((error) => {
              this.setState({ loading: false });
              swalWithBootstrapButtons.fire({
                type: 'error',
                text: error.response.data,
              });
            });
        }
      });
  };

  passToRegister = () => {
    const { history } = this.props;
    history.push(`/pages/delivery`);
  };

  register = () => {
    const { setRegisterButton } = this.props;
    const data = {
      p_id: this.props.cargo.i_cargo_app,
      p_new_state: 10,
      p_user: this.props.user.id,
    };
    swalWithBootstrapButtons
      .fire({
        text: 'Після реєстрації ви не зможете вносити зміни до вантажа. Зареєструвати?',
        showCancelButton: true,
        confirmButtonText: 'Так',
        cancelButtonText: 'Ні',
      })
      .then(async (result) => {
        if (result.value) {
          this.setState({ loading: true });

          await cargosetapply(data)
            .then((response) => {
              this.setState({ loading: false });
              setRegisterButton(true);
              swalWithBootstrapButtons.fire({
                type: 'success',
                text: 'Наступний крок - оформитими Декларацію. У вас є 90 днів на оформлення декларації та завезення вантажу',
              });
            })
            .catch((error) => {
              this.setState({ loading: false });
              swalWithBootstrapButtons.fire({
                type: 'error',
                text: error?.response?.data,
              });
            });
        }
      });
  };

  addAidAcquirer = () => {
    const data = {
      i_aid: 0,
      i_unit: '',
      n_count: '',
      i_aidtype: '',
      i_aidcondition: '',
      c_description: '',
      // add to vantazh
      n_wear_percentage: '',
      n_weight: '',
      c_service_life_term: '',
      c_exploitation_term: '',
      n_num_of_pairs: '',
      // vantazh 4
      n_year_use_start: '',
      c_vehicle_type: '',
      c_vehicle_category: '',
      c_vehicle_brand: '',
      c_vehicle_model: '',
      c_vehicle_num: '',
      c_vehicle_engine_num: '',
      c_vehicle_VIN_code: '',
      c_truckbody_num: '',
      c_chassis_num: '',
      c_first_registration: '',
      n_value: '',
      c_owners: '',
      // end
      i_aidform: this.props.cargo.i_aidform,
      l_deleted: '',
      isEdit: true,
      acquirer: [],
    };
    this.props.addCargoAction(data);
  };

  editAid = (index) => {
    const row = [...this.props.cargoCollection];
    row[index].isEdit = true;

    this.props.editAidAction(index, true);
  };

  handleOpen = (open) => {
    this.setState({ open }); // will show/hide tooltip when called
  };

  cargoApply = () => {
    swalWithBootstrapButtons
      .fire({
        text: 'Ви дійсно бажаєте подати заяву',
        showCancelButton: true,
        confirmButtonText: 'Так, подати заяву',
        cancelButtonText: 'Відміна',
      })
      .then(async (result) => {
        if (result.value) {
          try {
            const data = {
              p_id: this.props.cargo.i_cargo_app,
              p_user: this.props.user.id,
            };
            const response = await cargoSetApply(data);
            swalWithBootstrapButtons
              .fire({
                type: 'success',
                text: 'Заяву подано!',
              })
              .then((resp) => {
                if (resp.value) {
                  this.props.toggleAction('2');
                  this.props.getCargoByIdFullNewOrder(this.props.cargo.i_cargo);
                  // this.props.history.goBack()
                }
              });
          } catch (error) {
            swalWithBootstrapButtons
              .fire({
                type: 'error',
                text: error.response.data,
              })
              .then((success) => {
                if (success.value) {
                  this.props.history.goBack();
                }
              });
          }
        }
      });
  };

  getDeclarationForCargo = (event) => {
    const {
      cargo: { i_cargo: cargoId },
    } = this.props;
    getPDFForCargo(cargoId)
      .then((res) => {
        const { data } = res;
        getPage(data).open();
      })
      .catch((err) => {});
  };

  render() {
    const { classes } = this.props;
    const {
      cargo: { l_private },
    } = this.props;

    return (
      <section className="statementForm">
        <Grid container direction="column" justifyContent="center" spacing={5}>
          {this.getRoute() && (
            <>
              <Grid item md={6} xs={12}>
                {/* <label htmlFor="">Найменування отримувача (ЄР, ЄДРПОУ)</label> */}

                <BootstrapTooltip
                  placement="top"
                  title="Введіть назву отримувача, №ЄР або ЄДРПОУ. Дозволено введення цифр, літер українською мовою та символів \ / № «» . , ’ -"
                >
                  <div>
                    {/* <Select
                      value={this.props.selectRecipient.filter(
                        ({ value }) => Math.abs(parseInt(value)) === Math.abs(parseInt(this.props.cargo.i_recipient))
                      )}
                      onChange={this.handleSelectChange('i_recipient')}
                      options={this.props.selectRecipient}
                      styles={customStyles}
                      placeholder=""
                      // isMulti
                      getOptionLabel={({ label, edrpou, erNumber }) => (
                        <div>
                          <span style={{ color: '#999' }}>{`${erNumber} `}</span>
                          <span>{label}</span> <span style={{ color: '#999', fontSize: '12px' }}>ЄДРПОУ:</span>
                          <span style={{ color: '#999' }}>{edrpou}</span>
                        </div>
                      )}
                      noOptionsMessage={() => 'помилка серверу'}
                      menuPortalTarget={document.body}
                      isSearchable
                      filterOption={this.filterOptions}
                      theme={customTheme}
                    /> */}
                    <Autocomplete
                      value={
                        this.props.selectRecipient.filter(
                          ({ value }) => Math.abs(parseInt(value)) === Math.abs(parseInt(this.props.cargo.i_recipient))
                        )[0]
                      }
                      onChange={this.handleSelectChange('i_recipient')}
                      autoHighlight
                      getOptionSelected={(option) => option.value === this.props.cargo.i_recipient}
                      getOptionLabel={(option) => option.label}
                      options={this.props.selectRecipient}
                      renderOption={({ label, edrpou, erNumber }) => (
                        <Grid container direction="column">
                          <span style={{ color: '#999' }}>{`${erNumber} `}</span>
                          <span>{label}</span>{' '}
                          <span style={{ color: '#999', fontSize: '12px' }}>
                            ЄДРПОУ: <span style={{ color: '#999' }}>{edrpou}</span>
                          </span>
                        </Grid>
                      )}
                      renderInput={(params) => <TextField {...params} label="Найменування отримувача (ЄР, ЄДРПОУ)" />}
                    />
                  </div>
                </BootstrapTooltip>
              </Grid>
            </>
          )}
          <Grid item md={6} xs={12}>
            {/* <label htmlFor="">Найменування відправника</label> */}
            <BootstrapTooltip title="Введіть найменування донору. Дозволено ведення цифр, літер українською, англійською мовами та символів Ʌ№””.’,-/">
              <TextField
                required
                value={this.props.cargo.donor_name}
                onChange={this.handleChange}
                name="donor_name"
                type="text"
                // maxLength="64"
                inputProps={{
                  maxLength: 64,
                }}
                label="Найменування відправника (донора)"
              />
            </BootstrapTooltip>
          </Grid>
          <Grid item md={6} xs={12}>
            {/* <label htmlFor="">Реквізити відправника (юр.адреса, тел.)</label> */}
            <BootstrapTooltip title=" Введіть реквізити донора: юридичну адресу (країну, населений пункт, вулиця, номер будинку, номер офісу), номер телефону). Дозволено ведення цифр, літер українською, англійською мовами та символів Ʌ№””.’,-/">
              <TextField
                required
                value={this.props.cargo.c_details}
                onChange={this.handleChange}
                name="c_details"
                type="text"
                // maxLength="250"
                inputProps={{
                  maxLength: 250,
                }}
                label="Реквізити відправника (донора) (країна, юр.адреса, тел.)"
              />
            </BootstrapTooltip>
          </Grid>
          {/* <div className="col-12 col-sm-6 col-md-4 col-xl-3 mb-2">
            <label htmlFor="">Країна донора</label>
            <BootstrapTooltip
              title=" Оберіть країну зі списку."
              placement="top-start"
              onClose={() => this.handleOpen(false)}
              onOpen={() => this.handleOpen(true)}
              open={this.state.open}
            >
              <div>
                <Select
                  value={this.props.catalog.country.filter(
                    ({ value }) => parseInt(value) === parseInt(this.props.cargo.donor_country)
                  )}
                  onChange={this.handleSelectChange('donor_country')}
                  options={this.props.catalog.country}
                  styles={customStyles}
                  placeholder=""
                  // isMulti
                  noOptionsMessage={() => 'помилка серверу'}
                  menuPortalTarget={document.body}
                  theme={customTheme}
                  // onMouseEnter={() => this.handleOpen(true)}
                  // onMouseLeave={() => this.handleOpen(false)}
                  // onClick={() => this.handleOpen(false)}
                />
              </div>
            </BootstrapTooltip>
          </div> */}
          <Grid item md={6} xs={12}>
            {/* <label htmlFor="">Країна відправлення</label> */}
            <BootstrapTooltip title=" Оберіть країну зі списку." placement="top-start">
              <div>
                {/* <Select
                  value={this.props.catalog.country.filter(
                    ({ value }) => parseInt(value) === parseInt(this.props.cargo.i_country)
                  )}
                  onChange={this.handleSelectChange('i_country')}
                  options={this.props.catalog.country}
                  styles={customStyles}
                  placeholder=""
                  // isMulti
                  noOptionsMessage={() => 'помилка серверу'}
                  menuPortalTarget={document.body}
                  theme={customTheme}
                /> */}
                <Autocomplete
                  id="selectLocalityRecipient"
                  value={
                    this.props.catalog.country.filter(
                      ({ value }) => parseInt(value) === parseInt(this.props.cargo.i_country)
                    )[0]
                  }
                  onChange={this.handleSelectChange('i_country')}
                  autoHighlight
                  getOptionSelected={(option) => option.value === this.props.catalog.country.value}
                  getOptionLabel={(option) => option.label}
                  options={this.props.catalog.country}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // className={addressData.region.visible[addressData.type] ? '' : 'd-none'}
                      label="Країна відправлення"
                      // margin="dense"
                      // size="small"
                      // variant="outlined"
                    />
                  )}
                />
              </div>
            </BootstrapTooltip>
          </Grid>
          {/* <div className="col-12 col-sm-6 col-md-4 col-xl-3 mb-2">
            <label htmlFor="">Тип допомоги</label>
            <BootstrapTooltip title=" Оберіть тип допомоги зі списку." placement="top-start">
              <div>
                <Select
                  value={this.props.catalog.aidForm.filter(
                    ({ value }) => parseInt(value) === parseInt(this.props.cargo.i_aidform)
                  )}
                  onChange={this.handleSelectChange('i_aidform')}
                  options={this.props.catalog.aidForm}
                  styles={customStyles}
                  placeholder=""
                  // isMulti
                  noOptionsMessage={() => 'помилка серверу'}
                  menuPortalTarget={document.body}
                  theme={customTheme}
                  isDisabled={this.props.cargoCollection.length > 0}
                />
              </div>
            </BootstrapTooltip>
          </div> */}
          {/* <div className="col-12 col-sm-6 col-md-4 col-xl-3 mb-2">
            <label htmlFor="">Реєстр. номер донора</label>
            <BootstrapTooltip title="Введіть рєстраційний номер донора. Дозволено ведення не більше 10 цифр">
              <input
                value={this.props.cargo.c_donor_regnumber}
                onChange={this.handleChange}
                name="c_donor_regnumber"
                type="text"
                maxLength="10"
              />
            </BootstrapTooltip>
          </div> */}
          {/* <div className="w-100" />
          <hr /> */}
          {/* <div className="col-12 col-sm-6 col-md-4 col-xl-3 mb-2">
            <div style={{ color: '#7f8fa4', fontWeight: 'bold' }}>Керівник донора:</div>
            <label htmlFor="">Прізвище</label>
            <BootstrapTooltip title="Введіть прізвище керівника.">
              <input
                required
                value={this.props.cargo.c_head_surname}
                onChange={this.handleChange}
                name="c_head_surname"
                type="text"
                maxLength="64"
              />
            </BootstrapTooltip>
            <label htmlFor="">Ім'я</label>
            <BootstrapTooltip title="Введіть ім'я керівника.">
              <input
                required
                value={this.props.cargo.c_head_firstname}
                onChange={this.handleChange}
                name="c_head_firstname"
                type="text"
                maxLength="64"
              />
            </BootstrapTooltip>
            <label htmlFor="">По батькові (за наявності)</label>
            <BootstrapTooltip title="Введіть по батькові керівника.">
              <input
                required
                value={this.props.cargo.c_head_patronymic}
                onChange={this.handleChange}
                name="c_head_patronymic"
                type="text"
                maxLength="64"
              />
            </BootstrapTooltip>
          </div> */}
          {/* <div className="col-12 col-sm-6 col-md-4 col-xl-3 mb-2">
            <div style={{ color: '#7f8fa4', fontWeight: 'bold' }}>Супровідні документи:</div>
            <label htmlFor="">Письмова пропозиція донора (номер і дата)</label>
            <BootstrapTooltip title="Введіть реквізити письмової пропозиції донора (номер і дату).">
              <input
                required
                value={this.props.cargo.c_written_proposal}
                onChange={this.handleChange}
                name="c_written_proposal"
                type="text"
                maxLength="32"
              />
            </BootstrapTooltip>
            <label htmlFor="">Рахунок фактура або інвойс (номер і дата)</label>
            <BootstrapTooltip title="Введіть реквізити рахунку фактури або інвойса (номер і дату).">
              <input
                required
                value={this.props.cargo.c_invoice}
                onChange={this.handleChange}
                name="c_invoice"
                type="text"
                maxLength="32"
              />
            </BootstrapTooltip>
            <label htmlFor="">Пакувальний лист або специфікація на вантаж (номер і дата)</label>
            <BootstrapTooltip title="Введіть реквізити пакувального листа або специфікації на вантаж (номер і дату).">
              <input
                required
                value={this.props.cargo.c_package_letter}
                onChange={this.handleChange}
                name="c_package_letter"
                type="text"
                maxLength="32"
              />
            </BootstrapTooltip>
            <label htmlFor="">Інші супровідні документи (номер і дата)</label>
            <BootstrapTooltip title="Введіть реквізити інших супровідних документів (номер і дату).">
              <input
                required
                value={this.props.cargo.c_other_accompany_docs}
                onChange={this.handleChange}
                name="c_other_accompany_docs"
                type="text"
                maxLength="32"
              />
            </BootstrapTooltip>
          </div> */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={!!this.props.cargo.agreement_to_receive}
                  onChange={this.handleAgreementChange}
                  name="agreement_to_receive"
                />
              }
              label={
                <Typography variant="body1" color="primary" style={{ textAlign: 'justify' }}>
                  Підтверджую свою згоду на одержання гуманітарної допомоги та підтверджую обізнаність про кримінальну
                  відповідальність за ухилення від сплати податків, зборів (обов’язкових платежів) (стаття 212
                  Кримінального кодексу України) та підроблення документів, печаток, штампів та бланків, збут чи
                  використання підроблених документів, печаток, штампів (стаття 358 Кримінального кодексу України)
                </Typography>
              }
              color="primary"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel id="yes-no-radio-buttons-group" color="primary">
                <Typography variant="body1" style={{ textAlign: 'justify' }}>
                  Отримувач або набувачі допомоги є органами, формуваннями, адміністраціями або суб&apos;єктами, по
                  яких, згідно чинного законодавства дані не мають відображуватися у публічному доступі: органи
                  військового управління, правоохоронні органи, інші військові формування, Адміністрація
                  Держспецзв&apos;язку чи підпорядкований підрозділ, визначений нею, військові адміністрації,
                  суб&apos;єкти, які безпосередньо здійснюють боротьбу з тероризмом або суб&apos;єкти, які залучаються
                  до боротьби з тероризмом?
                </Typography>
              </FormLabel>
              <RadioGroup
                aria-labelledby="yes-no-radio-buttons-group"
                name="radio-yes-no"
                value={l_private}
                onChange={this.handleChangeRadio}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
                row
              >
                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Так" />
                <FormControlLabel value="no" color="primary" control={<Radio color="primary" />} label="Ні" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} style={{ alignSelf: 'center' }}> */}
          <div className={this.props.classes.buttons_save}>
            {this.props.cargoCollection.length === 0 &&
              (this.getRouteSave() || this.getRouteDeliveryEdit()) &&
              !this.props.cargo.i_cargo && (
                <Button
                  color="primary"
                  className={this.props.classes.button_style}
                  onClick={this.saveCargo}
                  disabled={!this.props.cargo.agreement_to_receive || !l_private}
                >
                  {/* {this.getEditRoure() ? 'Подати заяву' : "Зберегти"}</button> */}
                  Зберегти
                  <img className={this.props.classes.button_loading_img} src={add} />
                </Button>
              )}
            {(this.getRouteDeliveryEdit() || this.getRouteSave() || this.getEditRoure()) && this.props.cargo.i_cargo ? (
              <Button
                className={this.props.classes.button_style}
                onClick={this.editCargo}
                disabled={!this.props.cargo.agreement_to_receive || !l_private}
              >
                Зберегти/Редагувати
                <img className={this.props.classes.button_loading_img} src={save} />
              </Button>
            ) : (
              ''
            )}

            {this.props.cargo.i_cargo && this.props.cargoCollection.length === 0 ? (
              <Button
                variant="contained"
                color="primary"
                className={classes.button_style}
                onClick={this.addAidAcquirer}
              >
                {/* <Feather.PlusSquare className="mr-1" /> */}
                Додати вантаж до Декларації
                <img className={this.props.classes.button_loading_img} src={add} />
              </Button>
            ) : (
              ''
            )}
          </div>
          {/* </Grid> */}
        </Grid>
        <div className="row">
          <div className="col-12">
            <List className={this.props.classes.root}>
              {this.props.cargoCollection.map((item, index) => {
                return (
                  <AddCargo
                    key={index}
                    item={item}
                    index={index}
                    deleteItemAcquirer={this.deleteItemAcquirer}
                    deleteItem={this.deleteAidById}
                    cargo={this.props.cargo}
                    catalog={this.props.catalog}
                    promiseOptions={this.promiseOptions}
                    promiseCode={this.promiseCode}
                    handleChange={this.handleChangeInputsArray}
                    handleChangeSelect={this.handleChangeSelect}
                    addAcquirer={this.addAcquirer}
                    editAquirer={this.editAquirer}
                    toggle={this.toggleAid}
                    modal={this.state.modal}
                    saveAid={this.saveAid}
                    editAid={this.editAid}
                  />
                );
              })}
            </List>
          </div>
          {this.state.modal && (
            <AddAquirerModal
              toggle={this.toggle}
              modal={this.state.modal}
              catalog={this.props.catalog}
              cargoAquirer={this.props.cargoAquirer}
              handleSelectChange={this.handleSelectChange}
              handleChangeRedux={this.handleChange}
              handleChangeNumberFormat={this.handleChangeNumberFormat}
              handleChangeSelect={this.handleChangeSelect}
              handleChange={this.handleChange}
              deleteItem={this.deleteItem}
              promiseOptions={this.promiseOptions}
              addCargo={this.addCargo}
              addAcquirer={this.addAcquirer}
              cargoCollection={this.props.cargoCollection}
              indexRow={this.state.index}
              errorText={this.state.error}
              cargoAction={this.props.cargoAction}
            />
          )}
          {parseInt(this.props.cargo.i_cargo) !== 0 && (
            <div>
              {this.props.cargoCollection.length > 0 &&
                !this.props.cargoCollection[this.props.cargoCollection.length - 1].isEdit && (
                  <Button variant="contained" color="primary" className={classes.button_style} onClick={this.addCargo}>
                    <Icon className="fa fa-plus mr-2" />
                    Додати вантаж до Декларації
                  </Button>
                )}
            </div>
          )}
          <Divider variant="middle" className="mb-3 mt-4" />

          {/* {this.getEditRoure() && (
            <Button
              // style={{ marginLeft: 'auto', marginRight: 'auto' }}
              // className="btn btn-sm btn-primary"
              color="primary"
              onClick={this.cargoApply}
            >
              Подати заяву
            </Button>
          )} */}
        </div>
        {this.state.loading && <TopBarProgress />}
        {/* <div className="row justify-content-center" style={{ marginTop: 50 }}>
          <Button
            variant="outlined"
            className={this.props.classes.button_style}
            disabled={this.props.cargo.i_cargo === 0 || !this.props.cargo.i_cargo}
            onClick={() => {
              this.getRoute() ? this.props.toggle('3') : this.props.toggleAction('5');
            }}
          >
            Далі
            <img className={this.props.classes.button_loading_img} src={next} />
          </Button>
        </div> */}

        <div className="row justify-content-center" style={{ marginTop: 50 }}>
          <p>
            Заводити дані по вантажу та редагувати їх можна до того як Ви зареєструєте вантаж і йому присвоїться
            унікальний код гуманітарного вантажу. Не натискайте “ЗАРЕЄСТРУВАТИ”, якщо Ви плануєте ще заводити вантаж до
            Декларації. Після реєстрації вантажу буде присвоєно унікальний гуманітарний код і змінювати введену
            інформацію буде неможливо
          </p>
          <Button
            variant="outlined"
            className={classes.button_style}
            disabled={this.props.cargoCollection.length === 0 || this.props.registerButton === true}
            onClick={this.getDeclarationForCargo}
          >
            Перелік вантажу
          </Button>
        </div>
        <div>
          {(this.getRouteSave() || this.getRouteDeliveryEdit()) && (
            <div className={classes.buttonSection}>
              <Button onClick={this.passToRegister} variant="outlined" className={classes.button_styleDecl}>
                <img className={classes.buttonR_loading_img} src={arrowBack} />
                До реєстру
              </Button>

              <Button
                variant="outlined"
                className={this.props.classes.button_styleRegister}
                disabled={this.props.cargoCollection.length === 0 || this.props.registerButton === true}
                onClick={() => {
                  this.register();
                }}
              >
                Зареєструвати вантаж
                <img className={this.props.classes.button_loading_img} src={next} />
              </Button>

              <Button
                variant="outlined"
                className={classes.button_styleRegister}
                // eslint-disable-next-line react/destructuring-assignment
                disabled={!this.props.registerButton}
                onClick={() => {
                  // this.getRouteSave() && this.props.toggle('6');
                  // eslint-disable-next-line no-unused-expressions, react/destructuring-assignment
                  (this.getRouteSave() && (this.props?.toggleAction('6') || this.props?.toggle('6'))) ||
                    // eslint-disable-next-line react/destructuring-assignment
                    (this.getRouteDeliveryEdit() && (this.props?.toggleAction('6') || this.props?.toggle('6')));
                }}
              >
                Оформити декларацію
                <img className={classes.button_loading_img} src={next} />
              </Button>
            </div>
          )}
        </div>
      </section>

      //     </CardBody>
      //   </Card>
      // </div>
    );
  }
}

Statement.proptTypes = {
  catalog: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  cargo: PropTypes.object.isRequired,
  cargoCollection: PropTypes.array.isRequired,
  deleteCargoAction: PropTypes.func.isRequired,
  addCargoAction: PropTypes.func.isRequired,
  localityAction: PropTypes.func.isRequired,
  i_recipient: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  catalog: state.catalogs,
  cargo: state.cargo.cargoApplication,
  cargoAquirer: state.cargo.cargoAquirer,
  cargoCollection: state.cargo.cargo_acquirer,
  i_recipient: state.recipient.tabsFlag.recipietnSaveID,
  cargoSaveId: state.recipient.tabsFlag.cargoSaveId,
  aidSaveId: state.recipient.tabsFlag.aidSaveId,
  selectRecipient: state.recipient.selectRecipient,
  user: state.auth.user,
});

export default withRouter(
  connect(mapStateToProps, {
    cargoAction,
    addCargoAction,
    deleteCargoAction,
    setCargoAction,
    localityAction,
    saveCargoAction,
    editCargoAction,
    getRecipientsForSelect,
    clearCargo,
    addAquirerAction,
    currentAidId,
    getAidCargoCollection,
    getAcquirerByID,
    clearAqcuirer,
    cargoAidCollection,
    editAidAction,
    getCargoByIdFullNewOrder,
    toggleAction,
  })(withStyles(style)(Statement))
);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Header, SidebarNav, Footer, PageContent, Chat, PageAlert, Page } from '../vibe';
import Logo from '../assets/images/gov.ua.white.png';
import LogoGerb from '../assets/images/Trezub.png';
import avatar1 from '../assets/images/avatar1.png';
import LOGO_menu from '../assets/images/ASRGD_Logo 3.svg';
import telegram from '../assets/images/telegram.png';
import facebook from '../assets/images/facebook.png';
import nav from '../_nav';
import routes from '../views';
import ContextProviders from '../vibe/components/utilities/ContextProviders';
import { withRouter } from 'react-router-dom';

//redux
import { connect } from 'react-redux';
import { isMobileAction } from '../redux/actions/is-mobile.action';
import { logoutUser } from '../redux/actions/auth.action';
import { setCatalogs, getCatalogs } from '../redux/actions/catalog.action';

const MOBILE_SIZE = 992;

// const styles = {
  const styles = (theme) => ({
  footer: {
    // height: '15vh',
    backgroundColor: '#141414',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 24px',
    color: '#FFFFFF',
    fontSize: 14,
    fontFamily: 'diaHead',
    // position: 'absolute',
    // bottom: '0',
    // left: '0',

    // marginTop: '50px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 5px',
      flexWrap: 'wrap',
    },
  },
  footerContact: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      padding: '5px 0',
    },
  },
  footerContactText: {
    fontSize: '15px',
    margin: '3px',
    fontFamily: 'diaHead',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
      margin: '0px',
    },
  },
  socialIcons: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      paddingBottom: '10px',
    },
  },
  icon: {
    color: '#FFFFFF',
    margin: '0 8px',
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
        width: '20px',
        height: '20px',
    },
  },
  exitButton: {
    padding: '12px 16px',

    width: '200px',
    height: '54px',
    alignItem: 'center',
    background: '#141414',
    borderRadius: '40px',
  },
});

class DashboardLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarCollapsed: false,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      // showChat1: true, //was initial
      showChat1: false,
    };
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ sidebarCollapsed: false, isMobile: true });
      this.props.isMobileAction(true);
    } else {
      this.setState({ isMobile: false });
      this.props.isMobileAction(false);
    }
  };

  setSidebarCollapsedOn = () => {
    this.setState({ sidebarCollapsed: true });
  };

  setSidebarCollapsedOff = () => {
    this.setState({ sidebarCollapsed: false });
  };

  //sideBar closed-mobile version onChange path
  componentDidUpdate(prev) {
    if (this.state.isMobile && prev.location.pathname !== this.props.location.pathname) {
      this.setSidebarCollapsedOff();
    }
  }

  //sideBar closed-mobile version onMount
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);

    if (localStorage.catalogs) {
      this.props.setCatalogs(JSON.parse(localStorage.catalogs));
    } else {
      this.props.getCatalogs();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  //sideBar closed-mobile version onChange path
  toggleSideCollapse = () => {
    this.setState((prevState) => ({ sidebarCollapsed: !prevState.sidebarCollapsed }));
  };

  closeChat = () => {
    this.setState({ showChat1: false });
  };

  render() {
    const { classes } = this.props;
    const { sidebarCollapsed, isMobile } = this.state;
    const sidebarCollapsedClass = sidebarCollapsed ? 'side-menu-collapsed' : '';


    return (
      <ContextProviders>
        <div className={`app ${sidebarCollapsedClass}`}>
          <PageAlert />
          <div className="app-body">
            <SidebarNav
              nav={this.props.userMenu}
              logo={Logo}
              logoGerb={LogoGerb}
              LOGO_menu={LOGO_menu}
              // logoText="VIBE."
              isSidebarCollapsed={sidebarCollapsed}
              toggleSidebar={this.toggleSideCollapse}
              isMobile={isMobile}
              setSidebarCollapsedOff={this.setSidebarCollapsedOff}
              {...this.props}
            />
            <Page>
              <Header
                toggleSidebar={this.toggleSideCollapse}
                isSidebarCollapsed={sidebarCollapsed}
                routes={routes}
                isMobile={isMobile}
                setSidebarCollapsedOn={this.setSidebarCollapsedOn}
                {...this.props}
              >
                <HeaderNav
                  location={this.props.location}
                  logoutUser={this.props.logoutUser}
                  user={this.props.user}
                  history={this.props.history}
                />
              </Header>
              <PageContent>
                <Switch>
                  {routes.map((page, key) => (
                    <Route path={page.path} component={page.component} key={key} />
                  ))}

                  {/* <Route component={Error}/> */}
                  {/* <Redirect from="/" exaсt to="/home" /> */}
                </Switch>
              </PageContent>
            </Page>
            <Footer/>
          </div>
          <Chat.Container>
            {this.state.showChat1 && (
              <Chat.ChatBox name="Messages" status="online" image={avatar1} close={this.closeChat} />
            )}
          </Chat.Container>
        </div>
      </ContextProviders>
    );
  }
}

function HeaderNav({ logoutUser, user, location, history }) {
  return (
    <React.Fragment>
      {/* <NavItem>
        <form className="form-inline">
          <input className="form-control mr-sm-1" type="search" placeholder="Пошук" aria-label="Search" />
          <Button type="submit" className="d-none d-sm-block">
            <i className="fa fa-search" />
          </Button>
        </form>
      </NavItem> */}
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          {/* {(location.pathname === "/register-recipients" ||
           location.pathname === "/register-recipients-personal")? "Представник" : "Користувач"} */}
          <span style={{ fontSize: '16px', fontWeight: 800, fontFamily: 'diaHead' }}>
            {/* {user.c_surname} {user.c_name} */}
            Параметри/Вихід
          </span>
        </DropdownToggle>
        <DropdownMenu right>
          {/* <div>
            <ul className="list-unstyled p-2">
              <li>{user.c_surname}</li>
              <li>{user.c_name}</li>
              <li>{user.c_patronymic}</li>
            </ul>
          </div> */}
          <DropdownItem
            onClick={() => {
              history.push(`/user-profile`);
            }}
          >
            Профіль
          </DropdownItem>
          {/* <DropdownItem>User</DropdownItem> */}
          <DropdownItem divider />
          <DropdownItem onClick={(e) => logoutUser()} style={{ fontWeight: 800 }}>
            {/* <Button className="d-none d-sm-block" onClick={e => logoutUser()}> */}
            Вихід
            {/* </Button> */}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      {/* <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav>
          <Avatar size="small" color="blue" initials="USER" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem>Option 1</DropdownItem>
          <DropdownItem>Option 2</DropdownItem>
          <DropdownItem divider />
          <DropdownItem>Reset</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown> */}
      {/* <NavItem>
          <Button className="d-none d-sm-block" onClick={e => logoutUser()}>
            Вихід
          </Button>
      </NavItem> */}
    </React.Fragment>
  );
}

DashboardLayout.propTypes = {
  isMobileAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    userMenu: state.auth.userMenu,
  };
};

export default withRouter(
  connect(mapStateToProps, { isMobileAction, logoutUser, setCatalogs, getCatalogs })(
    withStyles(styles)(DashboardLayout)
  )
);

// packages
import React from 'react';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import Functions from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
// table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles } from '@material-ui/core/styles';
import { UncontrolledTooltip } from 'reactstrap';
import * as Feather from 'react-feather';
import ModalDocument from '../../Commission/DetailInfoView/ModalDocument';
import { getRecipReports, cargoReportGetPDF } from '../../../../api/api';

// import { StyledTableSortLabel } from '../StyledTableComponents';
// import { getComparator, stableSort } from '../sortFunctions';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const tableHeads = [
  {
    id: 'd_state',
    numeric: false,
    label: 'Станом на',
  },
  {
    id: 'd_modify',
    numeric: false,
    label: 'Дата останніх змін',
  },
  {
    id: 'c_state',
    numeric: false,
    label: 'Статус',
  },
  {
    id: 'view',
    numeric: false,
    label: 'Перегляд',
  },
  {
    id: 'docs',
    numeric: false,
    label: 'Документи',
  },
];

const DialogReportContent = ({ id }) => {
  const [docsView, setDocsView] = React.useState(false);
  const [modal, openModal] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [docs, setDocs] = React.useState([]);
  const [docsSelect, setDocsSelect] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const selectDocs = (docId) => {
    // eslint-disable-next-line no-shadow
    openModal((modal) => !modal);
    setDocsSelect(docs.filter(({ i_report }) => i_report === docId));
    setDocsView(true);
  };

  const getReports = async (recipId) => {
    try {
      const response = await getRecipReports(recipId);
      const {
        data: { documents, reports },
      } = response;
      setData(reports);
      setDocs(documents);
      //   console.log(response.data);
    } catch (error) {
      // console.log(error)
    }
  };

  const getReportPDF = async (reportDate, reportId) => {
    try {
      const params = {
        pd_rep: reportDate,
        id: reportId,
      };
      const response = await cargoReportGetPDF(params);

      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      // console.log(error);
    }
  };

  React.useEffect(() => {
    if (id) getReports(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const tableRows = data || [];

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, tableRows.length - page * rowsPerPage);

  return (
    <div>
      <div className="table-responsive">
        <TableContainer>
          <Table style={{ fontSize: '12px' }} hover="true" size="small">
            <TableHead>
              <TableRow>
                {tableHeads.map(({ id: itemId, label }) => (
                  <StyledTableCell key={itemId}>{label}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows.length > 0 ? (
                tableRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                  const { d_state, d_modify, c_state, l_documents, i_report } = item;
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow key={index}>
                      <StyledTableCell>{d_state}</StyledTableCell>
                      <StyledTableCell>{d_modify}</StyledTableCell>
                      <StyledTableCell>{c_state}</StyledTableCell>
                      <StyledTableCell>
                        <UncontrolledTooltip placement="top" target={`reports${index}`}>
                          Перегляд
                        </UncontrolledTooltip>
                        <IconButton
                          id={`reports${index}`}
                          aria-label="View Report"
                          color="primary"
                          onClick={() => getReportPDF(d_state, i_report)}
                        >
                          <Feather.Eye />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell>
                        <UncontrolledTooltip placement="top" target={`docs${index}`}>
                          Документи
                        </UncontrolledTooltip>
                        <IconButton
                          id={`docs${index}`}
                          aria-label="Report Docs"
                          color="primary"
                          disabled={l_documents === 0}
                          onClick={() => selectDocs(i_report)}
                        >
                          <Functions />
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <StyledTableCell colSpan={6} style={{ textAlign: 'center' }}>
                    Відсутні значення
                  </StyledTableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <StyledTableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={tableRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} з ${count}`}
          labelRowsPerPage="Рядків на сторінці:"
        />
      </div>

      {docsView && <ModalDocument openModal={openModal} modal={modal} docsView={docsView} docsSelect={docsSelect} />}
    </div>
  );
};

DialogReportContent.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DialogReportContent;

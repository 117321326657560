import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import clsx from 'clsx';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import * as Feather from 'react-feather';
import { UncontrolledTooltip } from 'reactstrap';
import NumberFormat from 'react-number-format';
import AttachMoney from '@material-ui/icons/AttachMoney';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import BootstrapTooltip from '../../../elements/MaterialBootstrap';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  // highlight:
  //   theme.palette.type === 'light'
  //     ? {
  //         color: theme.palette.secondary.main,
  //         backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  //       }
  //     : {
  //         color: theme.palette.row.primary,
  //         backgroundColor: theme.palette.secondary.dark,
  //       },
  spacer: {
    flex: '1 1 100%',
  },
  // actions: {
  //   color: theme.palette.row.secondary,
  // },
  title: {
    flex: '0 0 auto',
  },
}));

const EnhancedTableToolbar = ({ item, index }) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar className={classes.root} dense="true" key={item.plan_id}>
      {/* <UncontrolledTooltip placement="top" target={`tooltipIcon${index}`}>
              {directoryParse(catalog.aidForm, cargo.i_aidform)}
            </UncontrolledTooltip> */}
      {parseInt(item.i_aidform) === 1 && (
        <BootstrapTooltip
        // title={directoryParse(catalog.aidForm, item.i_aidform)}
        >
          <ListItemIcon>
            <Feather.Truck id={`tooltipIcon${index}`} />
          </ListItemIcon>
        </BootstrapTooltip>
      )}
      {parseInt(item.i_aidform) === 3 && (
        <BootstrapTooltip
        // title={directoryParse(catalog.aidForm, item.i_aidform)}
        >
          <ListItemIcon>
            {/* <i class="fas fa-users-cog" style={{fontSize: '22px'}} id={`tooltipIcon${index}`}></i> */}
            <SupervisorAccount />
          </ListItemIcon>
        </BootstrapTooltip>
      )}
      {parseInt(item.i_aidform) === 2 && (
        <BootstrapTooltip
        // title={directoryParse(catalog.aidForm, item.i_aidform)}
        >
          <ListItemIcon>
            <AttachMoney id={`tooltipIcon${index}`} />
          </ListItemIcon>
        </BootstrapTooltip>
      )}
      <ListItemText primary={item.aid_type} />
      <ListItemText primary={item.i_aidcondition} />
      <ListItemText primary={item.c_description} />
      <ListItemText primary={item.i_unit} />
      <ListItemText
        primary={
          <Typography id={`all${index}`} component="span">
            {item.count_all}
          </Typography>
        }
      />
      <UncontrolledTooltip placement="top" target={`all${index}`}>
        Всього
      </UncontrolledTooltip>

      <ListItemText
        primary={
          <Typography id={`distrib${index}`} component="span">
            {item.n_count}
          </Typography>
        }
      />
      <UncontrolledTooltip placement="top" target={`distrib${index}`}>
        Розподілено
      </UncontrolledTooltip>

      <ListItemText
        primary={
          <Typography id={`ramain${index}`} component="span">
            {item.difference}
          </Typography>
        }
      />
      <UncontrolledTooltip placement="top" target={`ramain${index}`}>
        Залишилось
      </UncontrolledTooltip>
      <ListItemSecondaryAction />
    </Toolbar>
  );
};

export default function NewDataAid({ newDataCargo }) {
  const classes = useStyles();

  return (
    <List>
      <Card>
        <CardHeader title="Донор" />
        <CardContent>
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="">Найменування отримувача:</label>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {(newDataCargo.scargo || {}).org_name}
              </Typography>
            </div>
            <div className="col-md-4">
              <label htmlFor="">Найменування донора:</label>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {(newDataCargo.scargo || {}).donor_name}
              </Typography>
            </div>
            <div className="col-md-4">
              <label htmlFor="">Реквізити донора:</label>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {(newDataCargo.scargo || {}).c_details}
              </Typography>
            </div>

            <div className="col-md-4">
              <label htmlFor="">Країна донора:</label>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {(newDataCargo.scargo || {}).donor_cnt_name}
              </Typography>
            </div>
            <div className="col-md-4">
              <label htmlFor="">Країна відправлення:</label>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {(newDataCargo.scargo || {}).country_name}
              </Typography>
            </div>
            <div className="col-md-4">
              <label htmlFor="">Тип допомоги:</label>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {(newDataCargo.scargo || {}).aid_name}
              </Typography>
            </div>
          </div>
        </CardContent>
      </Card>
      {(newDataCargo.aid || []).map((item, index) => (
        <Paper className={classes.root} key={item.i_aid}>
          <EnhancedTableToolbar item={item} index={index} />

          {item.acquirer.map((row, indexPlans) => (
            <Card key={row.i_acquire}>
              <CardContent>
                <div className="row">
                  <div className="col md-4">
                    <label htmlFor=""> Тип набувача:</label>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      {row.acquiretype}
                    </Typography>
                  </div>
                  <div className="col md-4">
                    <label htmlFor=""> Найменування набувача:</label>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      {row.c_name}
                    </Typography>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor=""> ПІБ набувача:</label>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      {row.c_surname} {row.c_name} {row.c_patronymic}
                    </Typography>
                  </div>

                  {row.i_acquirertype === 2 && (
                    <>
                      <div className="col-md-4">
                        <label htmlFor="">Соціальна категорія:</label>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          {row.c_category}
                        </Typography>
                      </div>

                      <div className="col-md-4">
                        <label htmlFor=""> Телефон набувача:</label>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          {row.n_phone}
                        </Typography>
                      </div>
                    </>
                  )}
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor=""> Область: </label>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      {row.region_name}
                    </Typography>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor=""> Населений пункт:</label>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      {row.locality_name}
                    </Typography>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor=""> Місцезнаходження набувача:</label>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      {row.c_adress}
                    </Typography>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor=""> Кількість:</label>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      {row.n_countplan}
                    </Typography>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
        </Paper>
      ))}
    </List>
  );
}

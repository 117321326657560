import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers';

const middleware = [thunkMiddleware];

const saver = (store) => (next) => (action) => {
  const result = next(action);
  localStorage['redux-store'] = JSON.stringify(store.getState());
  return result;
};

const storeFactory = () =>
  composeWithDevTools(applyMiddleware(...middleware, saver))(createStore)(
    rootReducer,
    localStorage['redux-store'] ? JSON.parse(localStorage['redux-store']) : {}
  );

export default storeFactory;

// export default function configureStore(preloadedState) {
//   return createStore(
//     //rootReducer,
//      rootReducer,

//     preloadedState,
//     composeWithDevTools(
//         applyMiddleware(...middleware, saver)
//       )
//       (localStorage['redux-store']) ?
//       JSON.parse(localStorage['redux-store']) :
//       {},

//   )
// }

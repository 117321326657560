import React, { useState, useEffect } from 'react';
import {
  // Table,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  UncontrolledTooltip,
  CardFooter,
} from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import Edit from '@material-ui/icons/Edit';
import CreateNewFolder from '@material-ui/icons/CreateNewFolder';
import * as Feather from 'react-feather';
import { connect } from 'react-redux';
import TopBarProgress from 'react-topbar-progress-indicator';
import Swal from 'sweetalert2';
import format from 'date-fns/format';
import { withRouter } from 'react-router';
import { editUnitAction, cargoReport } from '../../../redux/actions/cargo.action';
import { saveDistribution, setReport, getViewReport } from '../../../api/api';
import BootstrapTooltip from '../../elements/MaterialBootstrap';
import ModalReportPeriod from './ModalReportPeriod';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const mapStateToProps = (state) => ({
  reports: state.cargo.reports,
  distributions: state.cargo.distributions,
  loading: state.isFatching,
  cargo: state.cargo.cargoApplication,
});

export default withRouter(
  connect(mapStateToProps, { editUnitAction, cargoReport })(function ReportView({
    reports,
    loading,
    distributions,
    editUnitAction,
    cargo,
    cargoReport,
    history,
  }) {
    const [modal, openModal] = useState(false);
    const [view, getView] = useState(false);
    const [edit, getAddEdit] = useState(false);
    const [distributionView, setDistribution] = useState([]);
    const [date, handleChangeDate] = useState(new Date());

    async function handleListItemClick(event, id) {
      try {
        const response = await setReport(id);
        if (response.status === 200) {
          cargoReport(cargo.i_cargo);
        }
      } catch (error) {
        swalWithBootstrapButtons.fire({
          type: 'error',
          text: error.response.data,
        });
      }
    }
    function handleChange(value, indexDistribution, indexPlans) {
      // handleChangeUnit(index)
      editUnitAction(indexDistribution, indexPlans, value);
    }

    async function handleView(e, id) {
      try {
        const response = await getViewReport(id);
        setDistribution(response.data);
      } catch (error) {
        swalWithBootstrapButtons.fire({
          type: 'error',
          text: error.response.data,
        });
      }
    }

    async function handleSaveDitribution(e, rowPlans) {
      try {
        const data = {
          distr_id: rowPlans.distr_id,
          plan_id: rowPlans.plan_id,
          distrib_count: rowPlans.distrib_p,
          date: format(date, 'dd.MM.yyyy'),
        };
        const response = await saveDistribution(data);
        if (response.status === 200) {
          cargoReport(cargo.i_cargo);
        }
      } catch (error) {
        swalWithBootstrapButtons.fire({
          type: 'error',
          text: error.response.data,
        });
      }
    }

    function dateChane(value) {
      handleChangeDate(value);
    }

    return (
      <Row>
        <Col xs={12}>
          <Card plain="true">
            <CardHeader>Звіт</CardHeader>
            <CardBody>
              <div className="table-responsive">
                <Table style={{ fontSize: '12px' }} hover>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Дата</StyledTableCell>
                      <StyledTableCell>Назва отримувачв</StyledTableCell>
                      <StyledTableCell>ЄДРПОУ отримувача</StyledTableCell>
                      <StyledTableCell>Назва донора</StyledTableCell>
                      <StyledTableCell>Країна відправлення</StyledTableCell>
                      <StyledTableCell>Тип допомоги</StyledTableCell>
                      <StyledTableCell>Дії</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reports.map((text, index) => (
                      <TableRow key={text.n_number}>
                        <StyledTableCell>{text.last_date}</StyledTableCell>
                        <StyledTableCell>{text.c_orgname}</StyledTableCell>
                        <StyledTableCell>{text.n_edrpou}</StyledTableCell>
                        <StyledTableCell>{text.donor_name}</StyledTableCell>

                        <StyledTableCell>{text.country_name}</StyledTableCell>
                        <StyledTableCell>{text.aid_form}</StyledTableCell>
                        <StyledTableCell>
                          {!text.distr_state && (
                            <>
                              <IconButton
                                onClick={(e) => {
                                  openModal((modal) => !modal);
                                  getView((view) => false);
                                  getAddEdit((edit) => true);
                                }}
                                id={`plus${index}`}
                              >
                                <Feather.FilePlus />
                              </IconButton>
                              <UncontrolledTooltip placement="top" target={`plus${index}`}>
                                Додати звіт
                              </UncontrolledTooltip>
                            </>
                          )}
                          {!!text.distr_state && (
                            <>
                              <IconButton
                                onClick={(e) => {
                                  openModal((modal) => !modal);
                                  getView((view) => false);
                                  getAddEdit((edit) => true);
                                }}
                                id={`edit${index}`}
                              >
                                <Feather.Edit3 />
                              </IconButton>
                              <UncontrolledTooltip placement="top" target={`edit${index}`}>
                                Редагувати
                              </UncontrolledTooltip>
                            </>
                          )}
                          {!!text.distr_state && (
                            <>
                              <IconButton
                                onClick={(e) => {
                                  handleListItemClick(e, text.cargo_id);
                                }}
                                id={`check${index}`}
                              >
                                <Feather.Check />
                              </IconButton>
                              <UncontrolledTooltip placement="top" target={`check${index}`}>
                                Подати
                              </UncontrolledTooltip>
                            </>
                          )}
                          <IconButton
                            onClick={(e) => {
                              handleView(e, text.cargo_id);
                              openModal((modal) => !modal);
                              getView((view) => true);
                              getAddEdit((edit) => false);
                            }}
                            id={`eye${index}`}
                          >
                            <Feather.Eye />
                          </IconButton>
                          <UncontrolledTooltip placement="top" target={`eye${index}`}>
                            Перегляд
                          </UncontrolledTooltip>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </CardBody>
            <CardFooter>
              <BootstrapTooltip title="вихід">
                <IconButton onClick={() => history.push('/view-delivery')}>
                  <Feather.LogOut style={{ transform: 'scaleX(-1)' }} size="25" />
                </IconButton>
              </BootstrapTooltip>
            </CardFooter>
          </Card>
        </Col>
        <ModalReportPeriod
          distributionView={distributionView}
          edit={edit}
          view={view}
          modal={modal}
          openModal={openModal}
          distributions={distributions}
          handleChange={handleChange}
          dateChane={dateChane}
          date={date}
          handleSaveDitribution={handleSaveDitribution}
        />
        {loading.CARGO_REPORT && <TopBarProgress />}
      </Row>
    );
  })
);

export const getUTCDate = (dateString = Date.now()) => {
    const dateStr = dateString || Date.now()
    const date = new Date(dateStr);
  
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
    );
  };
import { IS_MOBILE } from '../actions/action-types'

const initialState = false

export default function(state=initialState, action) {
    switch (action.type) {
        case IS_MOBILE:
            return action.payload
            
        default:
            return state;
    }
}
const months = {
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  uk: [
    'січень',
    'лютий',
    'березень',
    'квітень',
    'травень',
    'червень',
    'липень',
    'серпень',
    'вересень',
    'жовтень',
    'листопад',
    'грудень',
  ],
};

export const getMonths = (lang) => {
  return months[lang] || [];
};

export const getMonthYearNameUk = (ind, yr) => {
  return `${getMonths('uk')[ind]} ${yr} р.`;
};

// packages
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Lock from '@material-ui/icons/Lock';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { UncontrolledAlert } from 'reactstrap';
import { Loader } from '../../../vibe';
import isEmpty from '../../../vibe/components/utilities/MainUtils/is-empty';

const styles = (theme) => ({
  gridContainer: {
    position: 'relative',
    zIndex: '1000',
    borderRadius: '0px',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  card: {
    borderRadius: '0px',
    width: '100%',
    margin: '40px auto',
    paddingTop: '30px',
  },
  input: {
    width: '100%',
    paddingBottom: '0px !important',
    // height: '25px !important',
    // boxSizing: 'inherit',
  },
  marginTop15: {
    marginTop: '15px',
  },
  marginBottom10: {
    marginBottom: '10px',
  },
  marginBottom20: {
    marginBottom: '20px',
  },
  marginTop50: {
    marginTop: '50px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
    },
  },
  marginTop90: {
    marginTop: '90px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '40px',
    },
  },
  button: {
    // borderRadius: '0px',
    // color: '#fff',
    marginTop: '48px',
    padding: '12px 70px',
    // background: 'linear-gradient(74.17deg, #5B5AFF 0%, #CC2E4F 65.36%, #89DB33 98.78%)',
    // '&:hover': {
    //   backgroundColor: '#fff',
    //   color: '#000',
    // },
  },
  formControl: {
    width: '100%',
  },
});

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    // this.props.logout();//clear local storage, clear user({}), clear Bearer
    // this.props.websocketClear()//clear webSocket({})
    this.state = {
      username: '',
      password: '',
      submitted: false,
    };
  }

  handleInputChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
    const { username, password } = this.state;

    if (username && password) {
      this.props.cryptoLoginUser(this.state.username, this.state.password);
    }
  };

  render() {
    const { classes } = this.props;
    const { username, password, submitted } = this.state;
    return (
      <Grid
        container
        spacing={3}
        direction="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        classes={{ container: `${classes.gridContainer}`, paper: classes.gridPaper }}
        maxWidth={400}
      >
        <form onSubmit={(e) => this.handleSubmit(e)} style={{ width: '100%' }}>
          <Grid container spacing={1} alignItems="flex-end" justifyContent="center" style={{ width: '100%' }}>
            <Grid item>
              <AccountCircle color={submitted && !username ? 'error' : 'primary'} />
            </Grid>
            <Grid item xs={9}>
              <FormControl
                classes={{ root: classes.formControl }}
                error={submitted && !username}
                aria-describedby="username"
                style={{ width: '100%' }}
              >
                <InputLabel htmlFor="username">Логін</InputLabel>
                <Input
                  id="username"
                  label="Логін"
                  classes={{ root: classes.input }}
                  name="username"
                  value={username}
                  onChange={this.handleInputChange('username')}
                />
                {submitted && !username && <FormHelperText id="username">Логін обов'язковий</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="flex-end" justifyContent="center" style={{ width: '100%' }}>
            <Grid item>
              <Lock color={submitted && !password ? 'error' : 'primary'} />
            </Grid>
            <Grid item xs={9}>
              <FormControl
                classes={{ root: classes.formControl }}
                error={submitted && !password}
                aria-describedby="password"
                style={{ width: '100%' }}
              >
                <InputLabel htmlFor="password">Пароль</InputLabel>
                <Input
                  id="password"
                  label="Пароль"
                  classes={{ root: classes.input }}
                  name="password"
                  type="password"
                  value={password}
                  onChange={this.handleInputChange('password')}
                />
                {submitted && !password && <FormHelperText id="password">Пароль обов'язковий</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center', width: '100%' }}>
            <Button type="submit" variant="contained" color="primary" classes={{ root: classes.button }}>
              Вхід
            </Button>
            {this.props.isFatching.USER && <Loader type="spin" small />}

            {!isEmpty(this.props.error.USER) && (
              <UncontrolledAlert color="danger">{this.props.error.USER}</UncontrolledAlert>
            )}
          </Grid>
        </form>
      </Grid>
    );
  }
}

export default withStyles(styles)(LoginForm);
// export default withRouter(connect(null, ({logout, websocketMiddlware, websocketClear}) )(withStyles(styles)(LoginForm)));

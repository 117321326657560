import Swal from 'sweetalert2';
import {
  SET_CARGO_SEARCH,
  SET_CARGO_REGISTER,
  SET_CARGO_LOADING,
  REQUEST_CARGO_BY_ID,
  SET_CARGO_MINUS_ID,
  SUCCESS_CARGO_BY_ID,
  ERROR_CARGO_BY_ID,
  CARGO_RECIPIENT_NAME_SELECT,
  CARGO_DONOR_NAME,
  CARGO_DONOR_DETAILS,
  CARGO_DONOR_COUNTRY_SELECT,
  CARGO_SENDING_COUNTRY_SELECT,
  CARGO_ACQUIRER_TYPE_SELECT,
  CARGO_DONOR_REGNUMBER,
  CARGO_HEAD_SURNAME,
  CARGO_HEAD_FIRSTNAME,
  CARGO_HEAD_PATRONYMIC,
  CARGO_WRITTEN_PROPOSAL,
  CARGO_INVOICE,
  CARGO_PACKAGE_LETTER,
  CARGO_OTHER_ACCOMPANY_DOCS,
  CARGO_AGREEMENT_TO_RECEIVE,
  CARGO_L_PRIVATE,
  CARGO_WEAR_PERCENTAGE,
  CARGO_WEIGHT,
  CARGO_EXPIRATION_TERM,
  CARGO_EXPLOITATION_TERM,
  CARGO_PAIRS_NUM,
  CARGO_ACQUIRER_MAIN_NAME,
  CARGO_ACQUIRER_SURNAME,
  CARGO_ACQUIRER_NAME,
  CARGO_ACQUIRER_LASTNAME,
  CARGO_ACQUIRER_SOCIAL_CATEGORY,
  CARGO_ACQUIRER_REGION,
  CARGO_ACQUIRER_LOCALITY,
  CARGO_ACQUIRER_LOCATION,
  CARGO_ACQUIRER_PHONE,
  CARGO_AID_TYPE_SELECT,
  CARGO_ACQUIRER_EDRPOU_CODE,
  CARGO_ACQUIRER_ACQUIRES_NUMBER,
  CARGO_ACQUIRER_SUPPORTING_CIRCUMSTANCES,
  CARGO_ACQUIRER_WEIGHT,
  CARGO_DESCRIPTION,
  CARGO_DESCRIPTION_SELECT,
  CARGO_QUONTITY,
  CARGO_UNIT,
  CARGO_CONDITION,
  CARGO_ADD,
  CARGO_DELETE,
  CARGO_SET_ARRAY,
  SET_CURGO_BY_RECIPIENT,
  REQUEST_CARGO_SAVE,
  SUCCESS_CARGO_SAVE,
  ERROR_CARGO_SAVE,
  REQUEST_CARGO_DOCUMENTS_SAVE,
  SUCCESS_CARGO_DOCUMENTS_SAVE,
  ERROR_CARGO_DOCUMENTS_SAVE,
  REQUEST_CARGO_DOCUMENTS,
  SUCCESS_CARGO_DOCUMENTS,
  ERROR_CARGO_DOCUMENTS,
  REQUEST_CARGO_DOCUMENTS_DEL,
  SUCCESS_CARGO_DOCUMENTS_DEL,
  ERROR_CARGO_DOCUMENTS_DEL,
  CARGO_CLEAR,
  SUCCESS_MY_CARGO,
  REQUEST_MY_CARGO,
  ERROR_MY_CARGO,
  AQUIRERE_ADD,
  CARGO_ACQUIRER_COUNT_PLAN,
  CARGO_AID_COLLECTION,
  SUCCESS_AQUIRER_BY_ID,
  REQUEST_AQUIRER_BY_ID,
  ERROR_AQUIRER_BY_ID,
  CLEAR_AQCUIRER,
  EDIT_AID_ACTION,
  REQUEST_CARGO_REPORT,
  SUCCESS_CARGO_REPORT,
  ERROR_CARGO_REPORT,
  DISTRIBUTIONS,
  EDIT_UNIT,
  CARGO_NEW,
  CARGO_OLD,
  DATA_TO_PDF,
  ID_TO_PDF,
  REQUEST_INVESTORY,
  SUCCESS_INVESTORY,
  REQUEST_REPORT,
  SUCCESS_REPORT,
  DATE_TO_PDF,
} from './action-types';

import {
  getCargo,
  saveCargo,
  getСargoDocumentsApi,
  saveCargoDocumentsApi,
  deleteCargoDocumentsApi,
  getCargoByIdFullFetch,
  deleteCargo,
  getMyCargo,
  getAidCargo,
  getCargoByIdFetch,
  getAcquirerById,
  getCargoReport,
  getCargoByIdFullFetchNewOrder,
  getInventoryApplicant,
  getReportsByUser,
} from '../../api/api';
import {
  currentAidId,
  getRecipientsForSelect,
  currentApplicationCargoId,
  currentCargoId,
  currentRecipient,
  getRecipientDocumentsAction,
  currentRecipientId,
  currentApplicationId,
} from './recipient.action';
import { toggleAction } from './toggle.action';
import { localityAction } from './catalog.action';
import { setDeclaration } from './declaration.action';
import { history } from '../../vibe/components/utilities/MainUtils/hist';
import isEmpty from '../../vibe/components/utilities/MainUtils/is-empty';

import customSwal from '../../views/pages/RegisterMain/CustomSwal';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
});

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

export const setCargoLoading = (value) => ({
  type: SET_CARGO_LOADING,
  payload: value,
});

export const cargoNew = (data) => ({
  type: CARGO_NEW,
  payload: data,
});

// cargo search
export const setCargoSearch = (params) => ({
  type: SET_CARGO_SEARCH,
  payload: params,
});

// cargo register filter
export const setCargoRegister = (params) => ({
  type: SET_CARGO_REGISTER,
  payload: params,
});

export const cargoOld = (data) => ({
  type: CARGO_OLD,
  payload: data,
});

export const reports = (data) => ({
  type: SUCCESS_CARGO_REPORT,
  payload: data,
});

export const distribution = (data) => ({
  type: DISTRIBUTIONS,
  payload: data,
});

export const cargoReport = (id) => async (dispatch) => {
  function success(data) {
    dispatch(reports(data.reports));
    dispatch(distribution(data.distributions));
    history.push(`/report/view-report`);
    return data;
  }
  function error(error) {
    dispatch({
      type: ERROR_CARGO_REPORT,
      payload: error,
    });
    return error;
  }
  try {
    dispatch({ type: REQUEST_CARGO_REPORT });
    const response = await getCargoReport(id);

    return success(response.data);
  } catch (err) {
    return error(err.response.data);
  }
};

export const editUnitAction = (indexDistribution, indexPlans, value) => ({
  type: EDIT_UNIT,
  payload: { indexDistribution, indexPlans, value },
});

export const editAidAction = (index, bool) => ({
  type: EDIT_AID_ACTION,
  payload: { index, bool },
});

export const cargoAidCollection = (data) => ({
  type: CARGO_AID_COLLECTION,
  payload: data,
});

export const getAidCargoCollection = (id) => async (dispatch) => {
  try {
    const response = await getAidCargo(id);
    dispatch(cargoAidCollection(response.data));
  } catch (error) {}
};
export const cargo = (data) => ({
  type: SUCCESS_CARGO_BY_ID,
  payload: data,
});

export const getCargoByIdFull = (id) => async (dispatch, getState) => {
  const succesCargo = (succes) => {
    dispatch(cargo(succes));
    return succes;
  };

  const errorCargo = (error) => {
    dispatch({ type: ERROR_CARGO_BY_ID });
    history.push('/pages/delivery');
    return error;
  };

  try {
    dispatch(setCargoLoading(true));
    dispatch(setCargoMinusId(id));
    dispatch({ type: REQUEST_CARGO_BY_ID });
    const success = await getCargoByIdFullFetch(id);
    dispatch(setCargoLoading(false));
    dispatch(currentRecipient(success.data.recipient));
    // history.push(`/view-delivery`);

    const data = success.data.scargo;
    // eslint-disable-next-line no-nested-ternary
    const scargo = { ...data, l_private: data?.l_private === '1' ? 'yes' : data?.l_private === '0' ? 'no' : '' };
    dispatch(getRecipientDocumentsAction(success.data.docs_recipient));
    dispatch(getCargoDocumentsAction(success.data.docs_cargo));
    dispatch(setCargoByRecipient(scargo));
    const arrayAid = success.data.aid.map((item) => {
      const {
        // acquire array
        acquirer,
        // both
        i_aidtype, // більше не потрібен
        i_aidform,
        // n_sum,
        // v1
        i_uktzed,
        // n_weight,
        // c_expiration_date,
        // c_service_life,
        // v4
        // n_comm_year,
        // n_reg_year,
        // c_vehicle_type,
        // c_category,
        // c_brand,
        // c_model,
        // c_reg,
        c_engine,
        i_fuel_type,
        // c_vin,
        // c_body_numb,
        // c_ch,
        // c_owners,
        ...oldData
      } = item;

      return {
        // розкладається на елементи тут
        // acquirer array
        acquirer: [...acquirer],
        // vantazh 1
        // n_wear_percentage: i_aidform === 1 ? n_sum : '',
        // n_weight: n_weight || '',
        // c_service_life_term: c_service_life || '',
        // c_exploitation_term: c_expiration_date || '',
        // vantazh 4
        // n_year_use_start: n_comm_year || '',
        // c_first_registration: n_reg_year || '',
        // c_vehicle_type: c_vehicle_type || '',
        // c_vehicle_category: c_category || '',
        // c_vehicle_brand: c_brand || '',
        // c_vehicle_model: c_model || '',
        // c_vehicle_num: c_reg || '',
        // c_vehicle_engine_num: c_engine || '',
        // c_vehicle_VIN_code: c_vin || '',
        // c_truckbody_num: c_body_numb || '',
        // c_chassis_num: c_ch || '',
        // n_value: i_aidform === 4 ? n_sum : '',
        // c_owners: c_owners || '',
        c_engine: i_fuel_type || '',
        i_aidtype: i_uktzed || '', // modify
        ...oldData,
        isEdit: false,
      };
    });
    // //dispatch(setCargoAction(success.data.aid))
    dispatch(setCargoAction(arrayAid));
    dispatch(currentRecipientId(success.data.recipient.id));
    dispatch(currentApplicationId(success.data.recipient.p_application_id));
    dispatch(currentApplicationCargoId(success.data.scargo.i_cargo_app));
    dispatch(currentCargoId(success.data.scargo.i_cargo));
    // dispatch(localityAction(success.data.acquirer.i_region))
    // set declaration
    const { manifest } = success.data;
    const declaration = manifest ? { ...manifest } : {};
    dispatch(setDeclaration(declaration));

    dispatch(toggleAction('1'));

    return succesCargo(data);
  } catch (error) {
    dispatch(setCargoLoading(false));
    return errorCargo(error);
  }
};

export const getCargoByIdFullNewOrder = (id) => async (dispatch, getState) => {
  const succesCargo = (succes) => {
    dispatch(cargo(succes));
    return succes;
  };

  const errorCargo = (error) => {
    dispatch({ type: ERROR_CARGO_BY_ID });
    // history.push('/pages/delivery')
    return error;
  };

  try {
    dispatch({ type: REQUEST_CARGO_BY_ID });
    const success = await getCargoByIdFullFetchNewOrder(id);
    dispatch(currentRecipient(success.data.recipient));

    const data = success.data.scargo;
    // eslint-disable-next-line no-nested-ternary
    const scargo = { ...data, l_private: data?.l_private === '1' ? 'yes' : data?.l_private === '0' ? 'no' : '' };
    dispatch(getRecipientDocumentsAction(success.data.docs_recipient));
    dispatch(getCargoDocumentsAction(success.data.docs_cargo));
    dispatch(setCargoByRecipient(scargo));
    const arrayAid = success.data.aid.map((item) => ({
      ...item,
      isEdit: false,
    }));
    // //dispatch(setCargoAction(success.data.aid))
    dispatch(setCargoAction(arrayAid));
    dispatch(currentRecipientId(success.data.recipient.id));
    dispatch(currentApplicationId(success.data.recipient.p_application_id));
    dispatch(currentApplicationCargoId(success.data.scargo.i_cargo_app));
    dispatch(currentCargoId(success.data.scargo.i_cargo));
    // dispatch(localityAction(success.data.acquirer.i_region))
    // set declaration
    const { manifest } = success.data;
    const declaration = manifest ? { ...manifest } : {};
    dispatch(setDeclaration(declaration));

    // dispatch(toggleAction('1'))

    return succesCargo(data);
  } catch (error) {
    return errorCargo(error);
  }
};

export const setCargoMinusId = (minusId) => ({
  type: SET_CARGO_MINUS_ID,
  payload: minusId,
});

export const getCargoByID = (id) => async (dispatch, getState) => {
  const succesCargo = (succes) => {
    dispatch(cargo(succes));
    return succes;
  };

  const errorCargo = (error) => {
    dispatch({ type: ERROR_CARGO_BY_ID });
    return error;
  };

  try {
    dispatch({ type: REQUEST_CARGO_BY_ID });
    const success = await getCargoByIdFetch(id);
    // history.push('/create-delivery/edit')
    dispatch(getRecipientsForSelect(getState().auth.user.id));
    // dispatch (currentCargoId(success.data.scargo.i_cargo))

    const data = success.data.scargo;

    const {
      c_reg_number,
      c_ag_surname,
      c_ag_name,
      c_ag_patron,
      c_proposal,
      c_enumer,
      c_packing,
      c_docs,
      l_private,
      ...oldData
    } = success.data.scargo;

    dispatch(getCargoDocumentsAction(success.data.cargodoc));
    // dispatch (currentApplicationCargoId(success.data.scargo.i_cargo_app))

    // old and new params
    dispatch(
      setCargoByRecipient({
        c_donor_regnumber: c_reg_number || '',
        c_head_surname: c_ag_surname || '',
        c_head_firstname: c_ag_name || '',
        c_head_patronymic: c_ag_patron || '',
        c_written_proposal: c_proposal || '',
        c_invoice: c_enumer || '',
        c_package_letter: c_packing || '',
        c_other_accompany_docs: c_docs || '',
        // eslint-disable-next-line no-nested-ternary
        l_private: l_private === '1' ? 'yes' : l_private === '0' ? 'no' : '',
        ...oldData,
      })
    );
    // dispatch(setCargoByRecipient(success.data.scargo));
    const arrayAid = success.data.aid.map((item) => {
      const {
        // acquire array
        acquirer,
        // both
        i_aidtype, // знову потрібен
        i_aidform,
        // n_sum,
        // v1
        i_uktzed,
        // n_weight,
        // c_expiration_date,
        // c_service_life,
        // v4
        // n_comm_year,
        // n_reg_year,
        // c_vehicle_type,
        // c_category,
        // c_brand,
        c_model,
        // c_reg,
        c_engine,
        i_fuel_type,
        // c_vin,
        // c_body_numb,
        // c_ch,
        // c_owners,
        ...oldData
      } = item;

      return {
        // розкладається на елементи тут
        // acquirer array
        acquirer: [...acquirer],
        // vantazh 1
        // n_wear_percentage: i_aidform === 1 ? n_sum : '',
        // n_weight: n_weight || '',
        // c_service_life_term: c_service_life || '',
        // c_exploitation_term: c_expiration_date || '',
        // vantazh 4
        // n_year_use_start: n_comm_year || '',
        // c_first_registration: n_reg_year || '',
        // c_vehicle_type: c_vehicle_type || '',
        // c_vehicle_category: c_category || '',
        // c_vehicle_brand: c_brand || '',
        c_vehicle_model: c_model || '',
        // c_vehicle_num: c_reg || '',
        // c_vehicle_engine_num: c_engine || '',
        // c_vehicle_VIN_code: c_vin || '',
        // c_truckbody_num: c_body_numb || '',
        // c_chassis_num: c_ch || '',
        // n_value: i_aidform === 4 ? n_sum : '',
        // c_owners: c_owners || '',
        //
        // fuel type
        c_engine: i_fuel_type || '',
        i_aidtype: i_aidtype || '', // get back to aidtype
        ...oldData,
        isEdit: false,
      };
    });

    dispatch(setCargoAction(arrayAid));
    // set declaration
    const { manifest } = success.data;
    const declaration = manifest ? { ...manifest } : {};
    dispatch(setDeclaration(declaration));

    // dispatch (currentCargoId(success.data.scargo.i_cargo))

    // dispatch(toggleAction('1'))
    // dispatch(currentRecipient(success.data.recipient))
    // dispatch(currentRecipientId(success.data.recipient.id))
    // dispatch(currentApplicationId(success.data.recipient.p_application_id))

    // dispatch(localityAction(success.data.acquirer.i_region))
    // dispatch(setCargoAction(success.data.aid))
    // dispatch(getRecipientDocumentsAction(success.data.docs_recipient))

    return succesCargo(data);
  } catch (error) {
    return errorCargo(error);
  }
};

export const acquirerByID = (data) => ({
  type: SUCCESS_AQUIRER_BY_ID,
  payload: data,
});

export const clearAqcuirer = () => ({
  type: CLEAR_AQCUIRER,
});

export const getAcquirerByID = (id) => async (dispatch) => {
  dispatch({
    type: REQUEST_AQUIRER_BY_ID,
  });
  try {
    const success = await getAcquirerById(id);

    const { n_weight, ...all } = success.data;

    dispatch(acquirerByID({ p_weight: n_weight, ...all }));
    // dispatch(acquirerByID(success.data));
    dispatch(localityAction(success.data.i_region));
    dispatch(currentAidId(success.data.i_aid));
    return dispatch;
  } catch (error) {
    dispatch({
      type: ERROR_AQUIRER_BY_ID,
      payload: error.response.data,
    });
  }
};

// String id;
//   String i_donor;
//   String donor_country;
//   String donor_name;
//   String i_country;
//   String i_recipient;
//   String i_aidform;

export const addCargoAction = (data) => ({
  type: CARGO_ADD,
  payload: data,
});

export const addAquirerAction = (data) => ({
  type: AQUIRERE_ADD,
  payload: data,
});

export const deleteCargoAction = (index) => ({
  type: CARGO_DELETE,
  payload: index,
});

export const setCargoAction = (arr) => ({
  type: CARGO_SET_ARRAY,
  payload: arr,
});

export const saveCargoAction = (data) => (dispatch, getState) => {
  dispatch({ type: REQUEST_CARGO_SAVE });
  saveCargo(data)
    .then((response) => {
      dispatch({ type: SUCCESS_CARGO_SAVE });
      dispatch(currentApplicationCargoId(response.data.i_cargo_app));

      // old and new data
      const {
        c_reg_number,
        c_ag_surname,
        c_ag_name,
        c_ag_patron,
        c_proposal,
        c_enumer,
        c_packing,
        c_docs,
        ...oldData
      } = response.data;

      dispatch(
        setCargoByRecipient({
          c_donor_regnumber: c_reg_number || '',
          c_head_surname: c_ag_surname || '',
          c_head_firstname: c_ag_name || '',
          c_head_patronymic: c_ag_patron || '',
          c_written_proposal: c_proposal || '',
          c_invoice: c_enumer || '',
          c_package_letter: c_packing || '',
          c_other_accompany_docs: c_docs || '',
          ...oldData,
        })
      );

      // dispatch(setCargoByRecipient(response.data));
      dispatch(currentCargoId(response.data.i_cargo));
      dispatch(getCargoByID(response.data.i_cargo));
      const data = {
        i_aid: 0,
        i_unit: '',
        n_count: '',
        i_aidtype: '',
        i_aidcondition: '',
        c_description: '',
        // vantazh 3
        n_wear_percentage: '',
        n_weight: '',
        c_service_life_term: '',
        c_exploitation_term: '',
        // vantazh 4
        n_year_use_start: '',
        c_vehicle_type: '',
        c_vehicle_category: '',
        c_vehicle_brand: '',
        c_vehicle_model: '',
        c_vehicle_num: '',
        c_vehicle_engine_num: '',
        c_vehicle_VIN_code: '',
        c_truckbody_num: '',
        c_chassis_num: '',
        c_first_registration: '',
        n_value: '',
        c_owners: '',
        // end
        i_aidform: getState().cargo.cargoApplication.i_aidform,
        l_deleted: '',
        isEdit: true,
        acquirer: [],
      };
      dispatch(addCargoAction(data));

      Toast.fire({
        type: 'success',
        title: 'Дані збережено!',
      });
    })
    .catch((error) => {
      dispatch({
        type: ERROR_CARGO_SAVE,
        payload: error.response.data,
      });
      // if (error.response.status === 400) {
      //     dispatch({
      //         type: ERROR_CARGO_SAVE,
      //         payload: error.response.data
      //     })
      //     swalWithBootstrapButtons.fire({
      //         type:'error',
      //         text: error.response.data
      //       })
      // } else {
      //     dispatch({
      //         type: ERROR_CARGO_SAVE,
      //         payload: "Помилка серевера"
      //     })

      //     swalWithBootstrapButtons.fire({
      //         type:'error',
      //         text: "Помилка сервера!"
      //       })

      // }
    });
};

export const editCargoAction = (data) => (dispatch, getState) => {
  dispatch({ type: REQUEST_CARGO_SAVE });
  saveCargo(data)
    .then(async (response) => {
      dispatch({ type: SUCCESS_CARGO_SAVE });
      dispatch(currentApplicationCargoId(response.data.i_cargo_app));

      // old and new data
      const {
        c_reg_number,
        c_ag_surname,
        c_ag_name,
        c_ag_patron,
        c_proposal,
        c_enumer,
        c_packing,
        c_docs,
        ...oldData
      } = response.data;

      dispatch(
        setCargoByRecipient({
          c_donor_regnumber: c_reg_number || '',
          c_head_surname: c_ag_surname || '',
          c_head_firstname: c_ag_name || '',
          c_head_patronymic: c_ag_patron || '',
          c_written_proposal: c_proposal || '',
          c_invoice: c_enumer || '',
          c_package_letter: c_packing || '',
          c_other_accompany_docs: c_docs || '',
          ...oldData,
        })
      );

      dispatch(currentCargoId(response.data.i_cargo));
      // dispatch(getAidCargoCollection(response.data.i_cargo))
      dispatch(getCargoByID(response.data.i_cargo));

      Toast.fire({
        type: 'success',
        title: 'Дані збережено!',
      });
    })
    .catch((error) => {
      dispatch({
        type: ERROR_CARGO_SAVE,
        payload: 'Помилка серевера',
      });
      // if (error.response.status === 400) {
      //     dispatch({
      //         type: ERROR_CARGO_SAVE,
      //         payload: error.response.data
      //     })
      //     swalWithBootstrapButtons.fire({
      //         type:'error',
      //         text: error.response.data
      //       })
      // } else {
      //     dispatch({
      //         type: ERROR_CARGO_SAVE,
      //         payload: "Помилка серевера"
      //     })

      //     swalWithBootstrapButtons.fire({
      //         type:'error',
      //         text: "Помилка сервера!"
      //       })

      // }
    });
};

export const setMyCargo = (data) => ({
  type: SUCCESS_MY_CARGO,
  payload: data,
});

export const getMyCargoAction = (params) => async (dispatch) => {
  dispatch({ type: REQUEST_MY_CARGO });
  try {
    const response = await getMyCargo(params);

    // redirect if empty
    // const { data } = response;
    // if (data.length === 0) {
    //   history.push(`/create-delivery/save`);
    // }

    const sortedByRcipient = response.data.sort((a, b) => {
      // var aa = a.split('/').reverse().join(),
      // bb = b.split('/').reverse().join();
      return a.orgname < b.orgname ? -1 : a.orgname > b.orgname ? 1 : 0;
    });
    return dispatch(setMyCargo(sortedByRcipient));
  } catch (error) {
    dispatch({ type: ERROR_MY_CARGO });
  }
};

export const setInvestory = (data) => ({
  type: SUCCESS_INVESTORY,
  payload: data,
});

export const getInvestoryAction = () => async (dispatch) => {
  dispatch({ type: REQUEST_INVESTORY });
  try {
    const response = await getInventoryApplicant();
    // redirect if empty
    const { data } = response;
    if (data.length === 0) {
      history.push(`/inventory-description`);
    }

    const sortedByInvestory = response.data.sort((a, b) => {
      return a.orgname < b.orgname ? -1 : a.orgname > b.orgname ? 1 : 0;
    });
    return dispatch(setInvestory(sortedByInvestory));
  } catch (error) {
    dispatch();
  }
};

export const setReport = (data) => ({
  type: SUCCESS_REPORT,
  payload: data,
});

export const getReportAction = () => async (dispatch) => {
  dispatch({ type: REQUEST_REPORT });
  try {
    const response = await getReportsByUser();
    if (response.data.length === 0) {
      history.push(`/create-report`);
    }
    return dispatch(setReport(response.data));
  } catch (error) {
    history.push(`/home`);
  }
};

export const deleteCargoFromMyDelivery = (id) => async (dispatch, getState) => {
  deleteCargo(id)
    .then((response) => {
      customSwal
        .fire({
          title: 'Запит про вилучення з реєстру Вантажу і Декларації по ньому відправлено на митну службу',
          text: 'Декларація буде вилучена, у випадку якщо вантаж ще не пройшов митне оформлення',
          showConfirmButton: true,
          confirmButtonText: 'ПІДТВЕРДИТИ',
        })
        .then((result) => {
          if (result?.value) {
            dispatch(
              getMyCargoAction({
                id: getState().auth.user.id,
                p_cargo: null,
                p_reg_date: null,
                p_passed_date: null,
                p_state: null,
              })
            );
          }
        });
    })
    .catch((error) => {
      swalWithBootstrapButtons
        .fire({
          title: 'Помилка',
          text: error.response.data,
          type: 'error',
        })
        .then((result) => {
          if (result.value) {
            dispatch(
              getMyCargoAction({
                id: getState().auth.user.id,
                p_cargo: null,
                p_reg_date: null,
                p_passed_date: null,
                p_state: null,
              })
            );
          }
        });
    });
};

export const setCargoByRecipient = (data) => ({
  type: SET_CURGO_BY_RECIPIENT,
  payload: data,
});

export const clearCargo = () => {
  return { type: CARGO_CLEAR };
};

export const cargoAction = (type, value) => {
  switch (type) {
    case 'i_recipient':
      return {
        type: CARGO_RECIPIENT_NAME_SELECT,
        payload: value,
      };
    case 'donor_name':
      return {
        type: CARGO_DONOR_NAME,
        payload: value,
      };
    case 'c_details':
      return {
        type: CARGO_DONOR_DETAILS,
        payload: value,
      };
    case 'donor_country':
      return {
        type: CARGO_DONOR_COUNTRY_SELECT,
        payload: value,
      };
    case 'i_country':
      return {
        type: CARGO_SENDING_COUNTRY_SELECT,
        payload: value,
      };

    case 'i_aidform':
      return {
        type: CARGO_AID_TYPE_SELECT,
        payload: value,
      };
    case 'c_donor_regnumber':
      return {
        type: CARGO_DONOR_REGNUMBER,
        payload: value,
      };
    case 'c_head_surname':
      return {
        type: CARGO_HEAD_SURNAME,
        payload: value,
      };
    case 'c_head_firstname':
      return {
        type: CARGO_HEAD_FIRSTNAME,
        payload: value,
      };
    case 'c_head_patronymic':
      return {
        type: CARGO_HEAD_PATRONYMIC,
        payload: value,
      };
    case 'c_written_proposal':
      return {
        type: CARGO_WRITTEN_PROPOSAL,
        payload: value,
      };
    case 'c_invoice':
      return {
        type: CARGO_INVOICE,
        payload: value,
      };
    case 'c_package_letter':
      return {
        type: CARGO_PACKAGE_LETTER,
        payload: value,
      };
    case 'c_other_accompany_docs':
      return {
        type: CARGO_OTHER_ACCOMPANY_DOCS,
        payload: value,
      };
    case 'agreement_to_receive':
      return {
        type: CARGO_AGREEMENT_TO_RECEIVE,
        payload: value,
      };
    case 'l_private':
      return {
        type: CARGO_L_PRIVATE,
        payload: value,
      };
    case 'c_wear_percentage':
      return {
        type: CARGO_WEAR_PERCENTAGE,
        payload: value,
      };
    case 'c_weight':
      return {
        type: CARGO_WEIGHT,
        payload: value,
      };
    case 'c_expiration_term':
      return {
        type: CARGO_EXPIRATION_TERM,
        payload: value,
      };
    case 'c_exploitation_term':
      return {
        type: CARGO_EXPLOITATION_TERM,
        payload: value,
      };
    case 'c_pairs_num':
      return {
        type: CARGO_PAIRS_NUM,
        payload: value,
      };
    case 'i_acquirertype':
      return {
        type: CARGO_ACQUIRER_TYPE_SELECT,
        payload: value,
      };
    case 'c_orgname':
      return {
        type: CARGO_ACQUIRER_MAIN_NAME,
        payload: value,
      };
    case 'n_edrpou':
      return {
        type: CARGO_ACQUIRER_EDRPOU_CODE,
        payload: value,
      };
    case 'c_surname':
      return {
        type: CARGO_ACQUIRER_SURNAME,
        payload: value,
      };
    case 'c_name':
      return {
        type: CARGO_ACQUIRER_NAME,
        payload: value,
      };
    case 'c_patronymic':
      return {
        type: CARGO_ACQUIRER_LASTNAME,
        payload: value,
      };
    case 'c_category':
      return {
        type: CARGO_ACQUIRER_SOCIAL_CATEGORY,
        payload: value,
      };
    case 'n_phone':
      return {
        type: CARGO_ACQUIRER_PHONE,
        payload: value,
      };
    case 'i_region':
      return {
        type: CARGO_ACQUIRER_REGION,
        payload: value,
      };
    case 'i_locality':
      return {
        type: CARGO_ACQUIRER_LOCALITY,
        payload: value,
      };
    case 'c_adress':
      return {
        type: CARGO_ACQUIRER_LOCATION,
        payload: value,
      };
    case 'n_countplan':
      return {
        type: CARGO_ACQUIRER_COUNT_PLAN,
        payload: value,
      };
    case 'n_acqcnt':
      return {
        type: CARGO_ACQUIRER_ACQUIRES_NUMBER,
        payload: value,
      };
    case 'c_reason':
      return {
        type: CARGO_ACQUIRER_SUPPORTING_CIRCUMSTANCES,
        payload: value,
      };
    case 'p_weight':
      return {
        type: CARGO_ACQUIRER_WEIGHT,
        payload: value,
      };
    default:
      return value;
  }
};

// DOCUMENTS CARGO
export const saveCargoDocuments = (info, data) => (dispatch, getState) => {
  dispatch({ type: REQUEST_CARGO_DOCUMENTS_SAVE });
  saveCargoDocumentsApi(info, data)
    .then((resp) => {
      dispatch({ type: SUCCESS_CARGO_DOCUMENTS_SAVE });
      // dispatch(getCargoDocuments(getState().cargo.applicationCargoId));
      dispatch(getCargoDocuments(getState().cargo.cargoApplication.i_cargo_app));
    })
    .catch((error) => {
      dispatch({
        type: ERROR_CARGO_DOCUMENTS_SAVE,
        payload: error.response.data,
      });
      //   if (error.response && error.response.status === 400) {
      //     dispatch({
      //       type: ERROR_CARGO_DOCUMENTS_SAVE,
      //       payload: error.response.data,
      //     });
      //     swalWithBootstrapButtons.fire({
      //         title: 'Помилка',
      //         text: error.response.data,
      //         type: 'error',
      //     })
      //   } else {
      //     dispatch({
      //       type: ERROR_CARGO_DOCUMENTS_SAVE,
      //       payload: 'Помилка серевера',
      //     });
      //     swalWithBootstrapButtons.fire({
      //         title: 'Помилка серверу',
      //         type: 'error',
      //     })
      //   }
    });
};

export const getCargoDocuments = (application_id) => (dispatch) => {
  dispatch({ type: REQUEST_CARGO_DOCUMENTS });
  getСargoDocumentsApi(application_id)
    .then((response) => {
      dispatch(getCargoDocumentsAction(response.data));
    })
    .catch((error) => {
      dispatch({
        type: ERROR_CARGO_DOCUMENTS,
        payload: error.response.data,
      });
      // if (error.response && error.response.status === 400) {
      //     dispatch({
      //         type: ERROR_CARGO_DOCUMENTS,
      //         payload: error.response.data
      //     })
      // } else {
      //     dispatch({
      //         type: ERROR_CARGO_DOCUMENTS,
      //         payload: "Помилка серевера"
      //     })
      // }
    });
};

export const getCargoDocumentsAction = (documents) => {
  return {
    type: SUCCESS_CARGO_DOCUMENTS,
    payload: documents,
  };
};

export const deleteCargoDocument = (id) => (dispatch, getState) => {
  dispatch({ type: REQUEST_CARGO_DOCUMENTS_DEL });
  deleteCargoDocumentsApi(id)
    .then((resp) => {
      dispatch({ type: SUCCESS_CARGO_DOCUMENTS_DEL });
      // dispatch(getCargoDocuments(getState().cargo.applicationCargoId));
      dispatch(getCargoDocuments(getState().cargo.cargoApplication.i_cargo_app));
    })
    .catch((error) => {
      dispatch({
        type: ERROR_CARGO_DOCUMENTS_DEL,
        payload: error.response.data,
      });
      //   if (error.response && error.response.status === 400) {
      //     dispatch({
      //       type: ERROR_CARGO_DOCUMENTS_DEL,
      //       payload: error.response.data,
      //     });
      //   } else {
      //     dispatch({
      //       type: ERROR_CARGO_DOCUMENTS_DEL,
      //       payload: 'Помилка серевера',
      //     });
      //   }
    });
};

export const cargoDataPdf = (data) => ({
  type: DATA_TO_PDF,
  payload: data,
});

export const cargoIDPdf = (data) => ({
  type: ID_TO_PDF,
  payload: data,
});

export const cargoCurrentDate = (data) => ({
  type: DATE_TO_PDF,
  payload: data,
});

import { NUMBER_DT } from '../actions/action-types';

const initialState = {
  IdHtml: [],
  numberOrder: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'TakePDFS_DataID':
      return {
        ...state,
        IdHtml: action.payload,
      };
    case NUMBER_DT:
      return {
        ...state,
        numberOrder: action.payload,
      };
    default:
      return state;
  }
}

import { FETCH_ORDER_ROWS_FALIURE, FETCH_ORDER_ROWS_SUCCESS, FETCH_ORDER_ROWS_START } from './action-types';

import { orderSearch } from '../../api/api';

const fetchRowsStart = () => ({
  type: FETCH_ORDER_ROWS_START,
});

const fetchRowsSussess = (rows) => ({
  type: FETCH_ORDER_ROWS_SUCCESS,
  payload: rows,
});

const fetchRowsFaliure = (error) => ({
  type: FETCH_ORDER_ROWS_FALIURE,
  payload: error,
});

export const fetchOrderSearch = (params) => async (dispatch) => {
  dispatch(fetchRowsStart());
  try {
    const response = await orderSearch(params);
    dispatch(fetchRowsSussess(response.data));
  } catch (error) {
    dispatch(fetchRowsFaliure(error));
  }
};

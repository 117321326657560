import React from 'react';
import ReactTable from 'react-table';
import IconButton from '@material-ui/core/IconButton';
import Functions from '@material-ui/icons/ExitToApp';
import { UncontrolledTooltip } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getTakePDFSData } from '../../../redux/actions/addIDhtml.action';
import { Button } from '@material-ui/core';

function RegisterCommissionMeetingsFullScreen({
  data,
  history,
  loading,
  setCurrentCommission,
  handleVouting,
  commisionID,
  getTakePDFSData,
}) {
  return (
    <div>
      <ReactTable
        loading={loading}
        data={data}
        style={{ fontSize: '0.85rem', textAlign: 'center' }}
        columns={[
          {
            Header: 'Дата засідання',
            accessor: 'd_meeting',
          },

          {
            Header: 'Стан',
            accessor: 'c_name',
          },
          {
            Header: 'Користувач',
            accessor: 'pib',
          },

          {
            Header: 'Функції',
            accessor: 'text',
            sortable: false,
            filterable: false,
            Cell: (row) => (
              <div>
                <UncontrolledTooltip placement="top" target="functionCommissionRegister">
                  Функції
                </UncontrolledTooltip>
                <IconButton
                  id="functionCommissionRegister"
                  aria-label="Order"
                  color="primary"
                  style={{ padding: '0' }}
                  onClick={(e) => {
                    history.push('/view-commission');
                    setCurrentCommission(row.original);
                    commisionID(row.original.id);
                    getTakePDFSData(row.original.id);
                  }}
                >
                  <Functions />
                </IconButton>

                <Button
                  aria-label="Order"
                  color="primary"
                  style={{ padding: '0' }}
                  disabled={row.original.i_state !== 30}
                  onClick={(e) => {
                    handleVouting(row.original.id);
                  }}
                >
                  ГОЛОСУВАТИ
                </Button>
              </div>
            ),
          },
        ]}
        defaultPageSize={10}
        showPaginationTop
        showPaginationBottom={false}
        loadingText="Завантаження..."
        noDataText="Інформація відсутня!"
        className="-striped -highlight"
        previousText="Попередня"
        nextText="Наступна"
        pageText="Сторінка"
        ofText="з"
        rowsText="рядків"
      />
    </div>
  );
}

export default withRouter(connect(null, { getTakePDFSData })(RegisterCommissionMeetingsFullScreen));

import React from 'react';
import PropTypes from 'prop-types';
import 'react-table/react-table.css';
import 'react-datepicker/dist/react-datepicker.css';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { UncontrolledTooltip } from 'reactstrap';
// table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import { ReactComponent as Eye } from '../../../assets/svg/Eye.svg';
import { getComparator, stableSort } from './sortFunctions';
import { useStylesTable } from './TableStyles';
import { StyledTableCell, CustomTablePagination, EnhancedTableHead } from '../../elements/CustomTable';

const headCells = [
  { label: '№ отримувача в реєстрі', numeric: false, disablePadding: false, id: 'n_number' },
  { label: 'Область', numeric: false, disablePadding: false, id: 'region_name' },
  { label: 'Населений пункт', numeric: false, disablePadding: false, id: 'locality_name' },
  { label: 'Місцезнаходження', numeric: false, disablePadding: false, id: 'c_adress' },
  { label: 'Код ЄДРПОУ', numeric: false, disablePadding: false, id: 'n_edrpou' },
  { label: 'Назва отримувача', numeric: false, disablePadding: false, id: 'c_orgname' },
  { label: 'Тип отримувача', numeric: false, disablePadding: false, id: 'recipient_type' },
  { label: 'ПІБ керівника', numeric: false, disablePadding: false, id: 'c_surname' },
  { label: 'Телефон', numeric: false, disablePadding: false, id: 'n_phone' },
  { label: 'Дата включення в реєстр', numeric: false, disablePadding: false, id: 'dateStart' },
  { label: 'Дата виключення з реєстру', numeric: false, disablePadding: false, id: 'dateEnd' },
  { label: 'Користувач', numeric: false, disablePadding: false, id: 'applicant_pib' },
  { label: 'Перегляд', numeric: false, disablePadding: false, id: 'id' },
];

// const swalWithBootstrapButtons = Swal.mixin({
//   customClass: {
//     confirmButton: 'btn btn-primary ',
//     cancelButton: 'btn btn-danger ml-3',
//   },
//   buttonsStyling: false,
// });

class RegisterRecipients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: '',
    };
  }

  // sorting
  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc' });
    this.setState({ orderBy: property });
  };

  render() {
    const { classes, recipientSaveTrack } = this.props;
    const { recipients, pn_page, pn_rows, total_rows } = this.props;
    const { rowsPerPage, page, order, orderBy } = this.state;

    // recipients
    const emptyRows = pn_rows - Math.min(pn_rows, recipients.length - pn_page * pn_rows);
    // const percent = (currentCount * 100) / 100;

    return (
      <>
        <div className="table-responsive bg-white">
          <Table style={{ fontSize: '12px' }} hover="true" size="small">
            <EnhancedTableHead
              classes={{}}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              // rowCount={2}
              headCells={headCells}
            />
            <TableBody>
              {stableSort(recipients, getComparator(order, orderBy)).map((item, index) => {
                const {
                  n_number,
                  region_name,
                  locality_name,
                  c_adress,
                  n_edrpou,
                  c_orgname,
                  recipient_type,
                  c_surname,
                  c_name,
                  c_patronymic,
                  n_phone,
                  dateStart,
                  dateEnd,
                  applicant_pib,
                } = item;
                return (
                  <TableRow key={n_number}>
                    <StyledTableCell>{n_number}</StyledTableCell>
                    <StyledTableCell>{region_name}</StyledTableCell>
                    <StyledTableCell>{locality_name}</StyledTableCell>
                    <StyledTableCell>{c_adress}</StyledTableCell>
                    <StyledTableCell>{n_edrpou}</StyledTableCell>
                    <StyledTableCell>{c_orgname}</StyledTableCell>
                    <StyledTableCell>{recipient_type}</StyledTableCell>
                    <StyledTableCell>
                      <div>
                        {c_surname} <br />
                        {c_name} <br />
                        {c_patronymic}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>{n_phone}</StyledTableCell>
                    <StyledTableCell>{dateStart}</StyledTableCell>
                    <StyledTableCell>{dateEnd}</StyledTableCell>
                    <StyledTableCell>{applicant_pib}</StyledTableCell>
                    <StyledTableCell>
                      <UncontrolledTooltip placement="top" target={`eyeview${index}`}>
                        Перегляд
                      </UncontrolledTooltip>
                      <IconButton
                        id={`eyeview${index}`}
                        aria-label="Gо to functions"
                        color="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          recipientSaveTrack('/register-recipients');
                          this.props.recipientById(item.id);
                          this.props.getRecipientHistoryApplications(item.id);
                        }}
                      >
                        <Eye />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <CustomTablePagination
          totalRaws={total_rows}
          handleChangePage={this.props.handleChangePage}
          handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
          rowsPerPage={pn_rows}
          page={pn_page - 1}
        />
      </>
    );
  }
}

RegisterRecipients.propTypes = {
  getRecipientsFatch: PropTypes.func.isRequired,
  recipientById: PropTypes.func.isRequired,
  recipients: PropTypes.array.isRequired,
  loading: PropTypes.object.isRequired,
  approve: PropTypes.func.isRequired,
  denied: PropTypes.func.isRequired,
};

export default withStyles(useStylesTable)(RegisterRecipients);

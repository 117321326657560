export function getErrMsg(cd) {
    switch (cd) {
        case 0: return "ОК";
        case 1: return "Некоректне значення обов'язкового поля команди.";
        case 2: return "Невірний пароль сесії.";
        case 3: return "Користувач не підключив ключ";
        case 4: return "Невірний формат електронного конверта";
        case 5: return "Підпис невірна";
        case 6: return "Передані для перевірки ЕЦП дані не збігаються з тими, що містяться в конверті.";
        case 7: return "Ключ користувача заборонено використовувати для підпису даних.";
        case 8: return "Ключ користувача заборонено використовувати для шифрування даних.";
        case 9: return "Не вдалося знайти ключ для розшифрування даних.";
        case 10: return "Не знайдено необхідний сертифікат.";
        case 11: return "Термін дії сертифіката вичерпаний або не настав.";
        case 12: return "Сертифікат було пошкоджено.";
        case 13: return "Неможливо перевірити цілісність сертифіката. Не вдалося знайти відповідний сертифікат ЦСК.";
        case 14: return "Сертифікат відкликаний. Перевірка за списком відкликаних сертифікатів.";
        case 15: return "Сертифікат відкликаний. Перевірка по протоколу OCSP.";
        case 20: return "Помилка протоколу OCSP";
        case 21: return "Помилка протоколу  TSP";
        case 22: return "Мітка часу невірна (перевірка підпису)";
        case 30: return "Не знайдено ключ електронної печатки.";
        case 100: return "Помилка криптографічної обробки даних.";
        case 101: return "Внутрішня помилка системи.";
        default: return "???";
    }  
}

export const getRandNumb = (N) => {
	var str = "";
	for (var i = 0; i < N; i++) {
			str += Math.floor(Math.random() * 10).toString();
  }
	return str;
}
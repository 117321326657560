// packages
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-table/react-table.css';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import uk from 'date-fns/locale/uk';
import Swal from 'sweetalert2';
import axios from 'axios';
import { Container, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Card, CardContent, CardActions, TextField, Icon } from '@material-ui/core';
import ButtonMaterial from '@material-ui/core/Button';
import { customTheme, customStyles } from '../../../customStyles/reactSelect';

import RegisterTableFullScreen from './RegisterTableFullScreen';
import { getCargoByFilters, country, aidForm } from '../../../api/api';
import { createLoadingSelector } from '../../../redux/actions/selectors';
import BootstrapTooltip from '../../elements/MaterialBootstrap';
import logo from '../../../assets/images/gov.ua.white.png';
// validation
import rules from '../../../vibe/components/utilities/MainUtils/rules';
import { setCargoByRecipient, getCargoByID, getCargoByIdFull, clearCargo } from '../../../redux/actions/cargo.action';
import CargoTable from './PublicCargo/CargoTable';

import { Loader } from '../../../vibe';
import { getUTCDate } from '../utils/utc';

const STATE_APPROVED = '50';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const loadingSelector = createLoadingSelector(['CATALOG', 'LOCALITY']);

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      i_country: '',
      i_aidform: '',
      dataTable: [],
      date_from: '',
      date_till: '',
      p_orgname: '',
      p_edrpou: '',
      donor_name: '',
      visible: false,
      loading: false,
      countries: [],
      aidForm: [],
    };
  }

  componentDidMount() {
    if (localStorage.reference) {
      const { countries, aidForm } = JSON.parse(localStorage.reference);
      this.setState({ countries, aidForm });
    } else {
      async function getReference() {
        try {
          const [response, success] = await axios.all([country(), aidForm()]);
          const reference = {
            countries: response.data,
            aidForm: success.data,
          };
          localStorage.setItem('reference', JSON.stringify(reference));
          this.setState({ countries: response.data, aidForm: success.data });
        } catch (error) {
          localStorage.setItem('reference', []);
        }
      }
      getReference();
    }

    if (localStorage.cargo) {
      this.setState({ dataTable: JSON.parse(localStorage.cargo) });
    }

    if (localStorage.cargoObject) {
      const { i_country, i_aidform, date_from, date_till, p_orgname, p_edrpou, donor_name, p_state } = JSON.parse(
        localStorage.cargoObject
      );
    

      const dateFrom = date_from ? getUTCDate(date_from) : '';
      const dateTill = date_till ? getUTCDate(date_till) : '';
      this.setState({
        i_country,
        i_aidform,
        date_from: dateFrom,
        date_till: dateTill,
        p_orgname,
        p_edrpou,
        donor_name,
        p_state,
      });
    }
  }

  // datePicker
  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  handleSelectChange = (name) => (select, { action }) => {
    const objDelete = {};
    objDelete[name] = '';
    switch (action) {
      case 'select-option':
        const obj = {};
        obj[name] = select.value;
        this.setState(obj);
        break;
      case 'clear':
        this.setState(objDelete);
        break;
    }
  };

  handleChangeData = (name) => (data) => {
    const obj = {};
    obj[name] = data;
    this.setState(obj);
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    const valuetoUpperCase = value.toUpperCase();
    if (value === '') {
      this.setState({ [name]: value });
    } else if (name === 'p_edrpou') {
      rules.edrpou.test(event.target.value) && this.setState({ [name]: event.target.value });
    } else if (name === 'p_orgname') {
      rules.orgName.test(valuetoUpperCase) && this.setState({ [name]: valuetoUpperCase });
    } else {
      this.setState({ [name]: valuetoUpperCase });
    }
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.setState({
      submitted: true,
      loading: true,
      dataTable: [],
    });

    const { i_country, i_aidform, date_from, date_till, p_orgname, p_edrpou, donor_name } = this.state;

    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };
    const data = {
      i_country,
      i_aidform,
      date_from: date_from ? date_from.toLocaleDateString('uk', options) : '',
      date_till: date_till ? date_till.toLocaleDateString('uk', options) : '',
      p_orgname: p_orgname.trim(),
      p_edrpou: p_edrpou.trim(),
      donor_name: donor_name.trim(),
      p_state: STATE_APPROVED,
    };

    getCargoByFilters(data)
      .then((response) => {
        this.setState({ dataTable: response.data, visible: true, loading: false });
        localStorage.setItem('cargo', JSON.stringify(response.data));
        localStorage.setItem('cargoObject', JSON.stringify(this.state));
      })
      .catch((error) => {
        this.setState({ dataTable: [], loading: false });
        // if (error.response && error.response.status === 400) {
        //   swalWithBootstrapButtons.fire({
        //       title: 'Помилка',
        //       text: error.response.data,
        //       type: 'error',
        //   })
        // } else {
        //   swalWithBootstrapButtons.fire({
        //       title: 'Помилка серверу',
        //       type: 'error',
        //   })
        // }
      });
  };

  render() {
    return (
      <>
        <header>
          <Navbar
            color="light"
            dark
            expand="md"
            className="bg-gradient text-white"
            style={{ paddingBottom: '1.5rem', paddingTop: '1.5rem' }}
          >
            <Container>
              <NavbarBrand href="https://www.gov.ua/">
                <img src={logo} alt="buttonGovUa" />
              </NavbarBrand>
              <NavbarToggler onClick={this.toggle} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  <NavItem>
                    {/* <NavNavLink className="btn-outline" href="/register-recipients-public">Реєстр отримувачів</NavNavLink> */}
                    <NavLink
                      activeStyle={{
                        outline: '1px solid #fff',
                        fontFamily: 'dia_bold',
                      }}
                      className="nav-link text-white-btn"
                      to="/register-recipients-public"
                    >
                      Реєстр отримувачів
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    {/* <NavNavLink className="btn-outline" href="/register-public">Реєстр гуманітарної допомоги</NavNavLink> */}
                    <NavLink
                      activeStyle={{
                        outline: '1px solid #fff',
                        fontFamily: 'dia_bold',
                      }}
                      className="nav-link text-white-btn"
                      to="/register-public"
                    >
                      Реєстр гуманітарної допомоги
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      activeStyle={{
                        outline: '1px solid #fff',
                        fontFamily: 'dia_bold',
                      }}
                      className="nav-link text-white-btn"
                      to="/commission"
                    >
                      Засідання робочої групи
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <Button className="btn-warning text-dark ml-2" onClick={(e) => this.props.history.push('/')}>
                      Вхід
                    </Button>
                  </NavItem>
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
        </header>
        <div className="mainRegister" style={{ width: '90%', margin: '50px auto' }}>
          <Card>
            <CardContent>
              <section className="searchMainRegister">
                <form onSubmit={this.handleSearch} autoComplete="off">
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
                      {/* <label htmlFor="">Отримувач</label> */}
                      <TextField
                        label="Отримувач"
                        value={this.state.p_orgname}
                        onChange={this.handleChange}
                        name="p_orgname"
                        type="text"
                        autocomplete="off"
                        inputProps={{
                          maxLength: 250,
                        }}
                      />
                    </div>

                    <div className="col-12 col-sm-6  col-md-4 col-xl-3">
                      {/* <label htmlFor="">ЄДРПОУ</label> */}
                      <TextField
                        label="ЄДРПОУ"
                        value={this.state.p_edrpou}
                        onChange={this.handleChange}
                        name="p_edrpou"
                        type="text"
                        autocomplete="off"
                        // maxLength="8"
                        inputProps={{
                          maxLength: 8,
                        }}
                      />
                    </div>

                    <div className="col-12 col-sm-6  col-md-4 col-xl-3">
                      <label htmlFor="">Країна відправлення</label>
                      <BootstrapTooltip title=" Оберіть країну зі списку." placement="top-start">
                        <div>
                          <Select
                            // value={this.state.i_country}
                            value={this.state.countries.filter(({ value }) => value === this.state.i_country)}
                            onChange={this.handleSelectChange('i_country')}
                            options={this.state.countries}
                            styles={customStyles}
                            isClearable
                            isSearchable
                            placeholder=""
                            // isMulti
                            noOptionsMessage={() => 'помилка серверу'}
                            menuPortalTarget={document.body}
                            theme={customTheme}
                          />
                        </div>
                      </BootstrapTooltip>
                    </div>

                    <div className="col-12 col-sm-6  col-md-4 col-xl-3 mt-md-2 mt-xl-0">
                      {/* <label htmlFor="">Донор</label> */}
                      <TextField
                        label="Донор"
                        value={this.state.donor_name}
                        onChange={this.handleChange}
                        name="donor_name"
                        type="text"
                        // maxLength="250"
                        autocomplete="off"
                        inputProps={{
                          maxLength: 250,
                        }}
                      />
                    </div>

                    {/* <div className="w-100 mt-1"></div> */}
                    <div className="col-12 col-sm-6  col-md-4 col-xl-3 mt-2">
                      <label htmlFor="">Тип допомоги</label>
                      <BootstrapTooltip title=" Оберіть тип допомоги зі списку." placement="top-start">
                        <div>
                          <Select
                            // value={this.state.i_aidform}
                            value={this.state.aidForm.filter(({ value }) => value === this.state.i_aidform)}
                            onChange={this.handleSelectChange('i_aidform')}
                            options={this.state.aidForm}
                            styles={customStyles}
                            placeholder=""
                            isClearable
                            isSearchable
                            // isMulti
                            noOptionsMessage={() => 'помилка серверу'}
                            menuPortalTarget={document.body}
                            theme={customTheme}
                          />
                        </div>
                      </BootstrapTooltip>
                    </div>

                    <div className="col-12 col-sm-6  col-md-4 col-xl-3 mt-2">
                      {/* <label htmlFor="">Рішення з:</label> */}
                      <DatePicker
                        selected={this.state.date_from}
                        onChange={this.handleChangeData('date_from')}
                        dateFormat="dd.MM.yyyy"
                        selectsStart
                        startDate={this.state.date_from}
                        endDate={this.state.date_till}
                        locale={uk}
                        className="datePicker"
                        autoComplete="off"
                        icon={<i className="cal-icon" />}
                        customInput={<TextField label="Рішення з:" />}
                      />
                    </div>

                    <div className="col-12 col-sm-6  col-md-4 col-xl-3 mt-2">
                      {/* <label htmlFor="">по:</label> */}
                      <DatePicker
                        selected={this.state.date_till}
                        onChange={this.handleChangeData('date_till')}
                        dateFormat="dd.MM.yyyy"
                        // maxDate={new Date()}
                        selectsEnd
                        endDate={this.state.date_till}
                        minDate={this.state.date_from}
                        className="datePicker"
                        autoComplete="off"
                        locale={uk}
                        customInput={<TextField label="по:" />}
                      />
                    </div>

                    <div className="col-12 mt-3 mb-4 text-center">
                      <ButtonMaterial
                        type="submit"
                        // variant="contained"
                        color="primary"
                        style={{ marginLeft: 'auto', marginRight: 'auto' }}
                      >
                        <Icon className="fa fa-search mr-2" /> Пошук
                      </ButtonMaterial>
                    </div>
                    {this.state.loading && <Loader type="spin" small />}
                  </div>
                </form>
              </section>
              <section className="dataMainRegister">
                <div>
                  <CargoTable
                    history={this.props.history}
                    dataTable={this.state.dataTable}
                    loading={this.state.loading}
                  />
                </div>
              </section>
            </CardContent>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile,
    loading: state.isFatching,
    errMessage: state.error,
    user: state.auth.user,
    catalog: state.catalogs,
    isFatching: loadingSelector(state),
  };
};

Register.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, { setCargoByRecipient, clearCargo, getCargoByID, getCargoByIdFull })(Register);

import React, { useEffect, useMemo, useState } from 'react';
import './Acts.scss';
import {
  Button,
  TablePagination,
  TableHead,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Grid,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { actionCreateAct, actionGetActs, actionGetMilitaryStates } from '../../../redux/actions/acts.action';
import { getActsStates } from '../../../api/actsApi';
import CustomLoader from '../../../vibe/components/CustomLoader/CustomLoader';
import { updatedDesignSelect } from '../../../customStyles/reactSelect';
import CustomDropdownAct from './CustomDropdownAct/CustomDropdownAct';
import { history } from '../../../vibe/components/utilities/MainUtils/hist';
import { getStatusTextAndClass, tableHeadCells } from './Acts.utils';
import ActPdf from '../../../files/Акт Приймання-передачі.pdf';

const Acts = () => {
  const { acts, actsLoading, createdActLoading, deletedAct, savedAct, createdAct } = useSelector((state) => state.acts);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionGetActs(null, null));
    dispatch(actionGetMilitaryStates());
    getActsStates()
      .then((res) => {
        setStates(res.data);
      })
      .catch((error) => {
        throw error;
      });
    if (deletedAct) {
      dispatch(actionGetActs(null, null));
    }
  }, [dispatch, deletedAct, savedAct, createdAct]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getValue = (obj, path) => {
    const keys = path.split('.');
    let value = obj;
    for (const key of keys) {
      value = value[key];
    }
    return value;
  };

  const sortedArray = useMemo(() => {
    if (sortConfig.key !== null) {
      return acts.cursor.slice().sort((a, b) => {
        const valueA = getValue(a, sortConfig.key);
        const valueB = getValue(b, sortConfig.key);
        if (valueA === null && valueB === null) {
          return 0;
        }
        if (valueA === null) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (valueB === null) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        if (valueA < valueB) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return acts.cursor;
  }, [acts.cursor, sortConfig.direction, sortConfig.key]);

  const handleSearch = (searchValue) => {
    dispatch(actionGetActs(searchValue, null));
    setPage(0);
  };

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    dispatch(actionGetActs(null, selectedOption.value));
    setPage(0);
  };

  const resetSearch = () => {
    dispatch(actionGetActs(null, null));
    setPage(0);
    setSelectedState(null);
  };

  const openCreateActPage = () => {
    dispatch(actionCreateAct())
      .then(() => {
        history.push('/act-create');
      })
      .catch((error) => {
        console.error('Failed to save act:', error);
      });
  };

  const exportBlank = () => {
    const pdfUrl = ActPdf;
    window.open(pdfUrl, '_blank');
  };

  return (
    <div className="ac-tr-ack">
      <div className="ac-tr-ack__actions-container">
        <div className="filters">
          <div className="filters__search-bar">
            <input
              className="filters__search-input"
              placeholder="Пошук по акту, VIN-код, вантажу, набувачу"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <div className="filters__state-bar">
            <Select
              styles={updatedDesignSelect}
              value={selectedState}
              onChange={handleStateChange}
              options={states}
              placeholder="Статус акту"
            />
            {selectedState && <CloseIcon className="filters__state-bar-reset" onClick={resetSearch} />}
          </div>
        </div>
        <div className="actions">
          <Button
            type="button"
            variant="contained"
            color="primary"
            className="create-button"
            onClick={openCreateActPage}
            disabled={createdActLoading}
          >
            {createdActLoading ? 'СТВОРЕННЯ...' : 'СТВОРИТИ АКТ'}
          </Button>
          <Button type="button" variant="contained" color="primary" className="export-blank" onClick={exportBlank}>
            БЛАНК АКТУ
          </Button>
        </div>
      </div>
      <div className="ac-tr-ack__main-container">
        <TableContainer component={Paper} className="ac-tr-ack__table">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableHeadCells.map((headCell) => (
                  <TableCell className="table-head-cell" align="center" onClick={() => handleSort(headCell.sortBy)}>
                    {headCell.label}
                  </TableCell>
                ))}
                <TableCell className="table-head-cell-more" align="center">
                  Дія
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {acts && acts.cursor && acts.cursor.length > 0 && rowsPerPage > 0 ? (
                sortedArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((act) => (
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="center">{act.c_act || '-'}</TableCell>
                    <TableCell align="center">{act.i_cargo || '-'}</TableCell>
                    <TableCell align="center">{act.c_vin || '-'}</TableCell>
                    <TableCell align="center">{act.c_pib || '-'}</TableCell>
                    <TableCell align="center">
                      <div className={getStatusTextAndClass(act.i_state).valueClass}>
                        {getStatusTextAndClass(act.i_state).text}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <CustomDropdownAct act={act} />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <CustomLoader isLoading={actsLoading} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="ac-tr-ack__table-pagination">
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={acts?.cursor?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Рядків на сторінці:"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} з ${count}`}
          />
        </div>
      </div>
    </div>
  );
};

export default Acts;

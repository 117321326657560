import React, { useEffect, useState } from 'react';
import './Act.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { history } from '../../../../vibe/components/utilities/MainUtils/hist';
import { actionDeleteAct, actionGetAct, actionSubmitAct } from '../../../../redux/actions/acts.action';
import { getStatusTextAndClass } from '../Acts.utils';
import ActPdfGenerate from '../ActPdfGenerate';

const Act = () => {
  const { act, militaryTypes, submittedActLoading } = useSelector((state) => state.acts);
  const [loading, setLoading] = useState(false);
  const [militaryType, setMilitaryType] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const type = act.acquirer[0]?.i_mu_type
      ? militaryTypes.find((t) => t.value === act.acquirer[0].i_mu_type.toString())
      : null;
    setMilitaryType(type?.label || '-');
  }, [act, militaryTypes]);

  const openEditPage = () => {
    setLoading(true);
    dispatch(actionGetAct(act.general[0].i_act))
      .then(() => {
        history.push('/act-edit');
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error('Failed to save act:', error);
      });
  };

  const deleteAct = () => {
    setLoading(true);
    dispatch(actionDeleteAct(act.general[0].i_act))
      .then(() => {
        history.push('/act');
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error('Failed to save act:', error);
      });
  };

  const submitAct = () => {
    dispatch(actionSubmitAct(act.general[0].i_act));
    setLoading(true);
    dispatch(actionGetAct(act.general[0].i_act))
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error('Failed to save act:', error);
      });
  };

  const printAct = async () => {
    ActPdfGenerate(act, militaryType);
  };

  return (
    <>
      {!loading ? (
        <div className="act">
          <div className="act__head">
            <div className="act__head__info">
              <div className="act__head__info__left-part">
                <div className="info__return-button" onClick={(e) => history.goBack()} />
                <div className="info__title">Акт №{act.general[0].c_act_num || '-'}</div>
              </div>
              <div className={getStatusTextAndClass(act.general[0].i_state).valueClass}>
                {getStatusTextAndClass(act.general[0].i_state).text}
              </div>
              {(act.general[0].i_state === 0 || act.general[0].i_state === 10) && (
                <div className="info__actions">
                  <div className="info__edit-button" onClick={(e) => openEditPage()} />
                  <div className="info__delete-button" onClick={(e) => deleteAct()} />
                </div>
              )}
            </div>
            <div className="act__head__date">
              <div className="date__title">Дата створення:</div>
              <div className="date__value">{act.general[0].d_act || '-'}</div>
            </div>
          </div>
          <div className="act__main">
            <div className="act__main__details-left-part">
              <div className="details">
                <div className="details__title">Загальні дані</div>
                <div className="details__detail">
                  <div className="detail__lable">№ акту</div>
                  <div className="detail__value">{act.general[0].c_act_num || '-'}</div>
                </div>
                <div className="details__detail">
                  <div className="detail__lable">Дата створення акту</div>
                  <div className="detail__value">{act.general[0].d_act || '-'}</div>
                </div>
                <div className="details__detail">
                  <div className="detail__lable">Діє на підставі</div>
                  <div className="detail__value">{act.general[0].c_based_on || '-'}</div>
                </div>
              </div>
              <div className="details">
                <div className="details__title">Дані транспортного засобу</div>
                <div className="car-act">
                  <div className="details__detail">
                    <div className="detail__lable">Марка</div>
                    <div className="detail__value">{act.car[0]?.c_brand || '-'}</div>
                  </div>
                  <div className="details__detail">
                    <div className="detail__lable">Модель</div>
                    <div className="detail__value">{act.car[0]?.c_model || '-'}</div>
                  </div>
                  <div className="details__detail">
                    <div className="detail__lable">VIN-код</div>
                    <div className="detail__value">{act.car[0]?.c_vin || '-'}</div>
                  </div>
                </div>
                <div className="details__detail">
                  <div className="detail__lable">Об’єм двигуна, см3</div>
                  <div className="detail__value">{act.car[0]?.n_capacity || '-'}</div>
                </div>
                <div className="details__detail">
                  <div className="detail__lable">Вартість за 1 шт., грн</div>
                  <div className="detail__value">{act.car[0]?.n_price || '-'}</div>
                </div>
                <div className="details__detail">
                  <div className="detail__lable">Загальна вартість, грн</div>
                  <div className="detail__value">{act.car[0]?.n_value || '-'}</div>
                </div>
              </div>
              <div className="details">
                <div className="details__title short">Підписаний акт приймання-передачі ТЗ</div>
                {act.doc.length > 0 ? (
                  act.doc.map((doc) => (
                    <div className="file">
                      <div className="file__img" />
                      <a
                        href={`/aid/file/${doc.c_filename}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="file-act__value"
                      >
                        {doc.c_filename}
                      </a>
                    </div>
                  ))
                ) : (
                  <> - </>
                )}
              </div>
              <div className="details">
                <div className="details__title">Дані отримувача</div>
                <div className="details__detail">
                  <div className="detail__lable">Назва організації</div>
                  <div className="detail__value">{act.recipient[0]?.c_orgname || '-'}</div>
                </div>
                <div className="details__detail">
                  <div className="detail__lable">ПІБ уповноваженої особи</div>
                  <div className="detail__value">{act.recipient[0]?.c_pib || '-'}</div>
                </div>
                <div className="details__detail">
                  <div className="detail__lable">Посада уповноваженої особи</div>
                  <div className="detail__value">{act.recipient[0]?.c_job_title || '-'}</div>
                </div>
                <div className="details__detail">
                  <div className="detail__lable">E-mail організації</div>
                  <div className="detail__value">{act.recipient[0]?.c_email || '-'}</div>
                </div>
                <div className="details__detail">
                  <div className="detail__lable">Код ЄДРПОУ організації</div>
                  <div className="detail__value">{act.recipient[0]?.n_edrpou || '-'}</div>
                </div>
                <div className="details__detail">
                  <div className="detail__lable">Юридична адреса організації</div>
                  <div className="detail__value">{act.recipient[0]?.c_adress || '-'}</div>
                </div>
              </div>
            </div>
            <div className="act__main__details-right-part">
              <div className="details">
                <div className="details__title">Дані набувача</div>
                <div className="details__detail">
                  <div className="detail__lable">ПІБ військовослужбовця</div>
                  <div className="detail__value">{act.acquirer[0]?.sld_pib || '-'}</div>
                </div>
                <div className="details__detail">
                  <div className="detail__lable">Тип військового формування</div>
                  <div className="detail__value">{militaryType || '-'}</div>
                </div>
                <div className="details__detail">
                  <div className="detail__lable">Номер військового формування</div>
                  <div className="detail__value">{act.acquirer[0]?.mil_num || '-'}</div>
                </div>
                <div className="details__detail">
                  <div className="detail__lable">E-mail військового формування</div>
                  <div className="detail__value">{act.acquirer[0]?.mil_email || '-'}</div>
                </div>
                <div className="details__detail">
                  <div className="detail__lable">РНОКПО військовослужбовця</div>
                  <div className="detail__value">{act.acquirer[0]?.c_sri || '-'}</div>
                </div>
                <div className="details__detail">
                  <div className="detail__lable">Номер військового квитка</div>
                  <div className="detail__value">{act.acquirer[0]?.sld_id || '-'}</div>
                </div>
                <div className="details__detail">
                  <div className="detail__lable">E-mail військовослужбовця</div>
                  <div className="detail__value">{act.acquirer[0]?.sld_email || '-'}</div>
                </div>
              </div>
              <div className="details">
                <div className="details__title short">
                  Військовослужбовці, які мають право на відомчу перереєстрацію
                </div>
                <div className="detail__militaries">
                  {act.soldier.length > 0 &&
                    act.soldier.map((soldier, index) => (
                      <div className="military" key={index}>
                        <div className="military__header">
                          <div className="military__title">#{index + 1} ВІЙСЬКОВОСЛУЖБОВЕЦЬ</div>
                        </div>
                        <div className="details__detail">
                          <div className="detail__lable">ПІБ військовослужбовця</div>
                          <div className="detail__value">{soldier.c_pib}</div>
                        </div>
                        <div className="details__detail">
                          <div className="detail__lable">Номер військового квитка</div>
                          <div className="detail__value">{soldier.c_id_num}</div>
                        </div>
                        <div className="details__detail">
                          <div className="detail__lable">E-mail військовослужбовця</div>
                          <div className="detail__value">{soldier.c_email}</div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {act.doc.length === 0 && (
            <div className="container-button">
              <Button variant="contained" color="primary" className="create-button" onClick={(e) => printAct()}>
                ДРУК
              </Button>
            </div>
          )}
          {act.general[0].i_state === 0 && act.doc.length > 0 && (
            <div className="container-button-submit">
              <Button
                variant="contained"
                color="primary"
                className="create-button"
                disabled={submittedActLoading}
                onClick={(e) => submitAct()}
              >
                {submittedActLoading ? 'ЗАГРУЗКА...' : 'ПОДАТИ'}
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div className="custom-loader">
          <img
            loading="lazy"
            height="100px"
            width="100px"
            className="custom-loader__spinner"
            src={
              "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 200 200'%3E%3CradialGradient id='a12' cx='.66' fx='.66' cy='.3125' fy='.3125' gradientTransform='scale(1.5)'%3E%3Cstop offset='0' stop-color='%23000000'%3E%3C/stop%3E%3Cstop offset='.3' stop-color='%23000000' stop-opacity='.9'%3E%3C/stop%3E%3Cstop offset='.6' stop-color='%23000000' stop-opacity='.6'%3E%3C/stop%3E%3Cstop offset='.8' stop-color='%23000000' stop-opacity='.3'%3E%3C/stop%3E%3Cstop offset='1' stop-color='%23000000' stop-opacity='0'%3E%3C/stop%3E%3C/radialGradient%3E%3Ccircle transform-origin='center' fill='none' stroke='url(%23a12)' stroke-width='14' stroke-linecap='round' stroke-dasharray='200 1000' stroke-dashoffset='0' cx='100' cy='100' r='70' %3E%3CanimateTransform type='rotate' attributeName='transform' calcMode='spline' dur='0.7' values='360;0' keyTimes='0;1' keySplines='0 0 1 1' repeatCount='indefinite' %3E%3C/animateTransform%3E%3C/circle%3E%3Ccircle transform-origin='center' fill='none' opacity='.2' stroke='%23000000' stroke-width='14' stroke-linecap='round' cx='100' cy='100' r='70' %3E%3C/circle%3E%3C/svg%3E"
            }
            alt="spinner"
          />
        </div>
      )}
    </>
  );
};

export default Act;

import React from 'react';
import ReactTable from 'react-table';
import IconButton from '@material-ui/core/IconButton';
import { useSelector } from 'react-redux';
import ArrowDropDownCircle from '@material-ui/icons/ArrowDropDownCircle';
import { downloadOrder, downloadProtocol } from '../../../../api/api';

const OrderTable = () => {
  const { rows, isLoading, error } = useSelector((state) => state.order);

  const downloadZip = (data) => {
    const blob = new Blob([data], { type: 'application/zip' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'files.zip');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const downloadOrderZip = async (id) => {
    try {
      const response = await downloadOrder(id);
      // const response = await downloadOrder('29274');
      downloadZip(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  const downloadProtocolZip = async (id) => {
    try {
      const response = await downloadProtocol(id);
      // const response = await downloadProtocol('29274');
      downloadZip(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {rows.length > 0 && (
        <ReactTable
          data={rows}
          columns={[
            {
              Header: <p>Назва наказу</p>,
              headerStyle: {
                textAlign: 'center',
              },
              accessor: 'order_type',
              style: { whiteSpace: 'unset', textAlign: 'left' },
            },
            {
              Header: <p>Дата наказу</p>,
              headerStyle: {
                textAlign: 'center',
              },
              accessor: 'd_order',
              style: { whiteSpace: 'unset', textAlign: 'center' },
            },
            {
              Header: <p>Номер наказу</p>,
              headerStyle: {
                textAlign: 'center',
              },
              accessor: 'n_order',
              style: { whiteSpace: 'unset', textAlign: 'center' },
            },
            {
              Header: <p>Перегляд наказу</p>,
              headerStyle: {
                textAlign: 'center',
              },
              accessor: null,
              style: { whiteSpace: 'unset', textAlign: 'center' },
              sortable: false,
              filterable: false,
              Cell: (data) => (
                <div>
                  <IconButton
                    color="primary"
                    aria-label="download picture"
                    component="label"
                    disabled={!data.original.isorder}
                    onClick={() => downloadOrderZip(data.original.order_id)}
                  >
                    <ArrowDropDownCircle />
                  </IconButton>
                </div>
              ),
            },
            {
              Header: <p>Перегляд протоколу</p>,
              headerStyle: {
                textAlign: 'center',
              },
              accessor: null,
              style: { whiteSpace: 'unset', textAlign: 'center' },
              sortable: false,
              filterable: false,
              Cell: (data) => (
                <div>
                  <IconButton
                    color="primary"
                    aria-label="download picture"
                    component="label"
                    disabled={!data.original.isprot}
                    onClick={() => downloadProtocolZip(data.original.order_id)}
                  >
                    <ArrowDropDownCircle />
                  </IconButton>
                </div>
              ),
            },
          ]}
          defaultPageSize={5}
          showPaginationTop
          showPaginationBottom={false}
          noDataText="Інформація відсутня!"
          className="-striped -highlight"
          loadingText="Завантаження..."
          previousText="Попередня"
          nextText="Наступна"
          pageText="Сторінка"
          ofText="з"
          rowsText="рядків"
        />
      )}
    </>
  );
};

export default OrderTable;

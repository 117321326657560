import Swal from 'sweetalert2';
import {
  GET_RECIPIENT,
  GET_RECIPIENTS,
  RECIPIENT_REGISTERED,
  RECIPIENT_REGION,
  RECIPIENT_CITY,
  RECIPIENT_EDRPOU,
  RECIPIENT_TITLE,
  RECIPIENT_SURNAME,
  RECIPIENT_NAME,
  RECIPIENT_LASTNAME,
  RECIPIENT_RECIPIENT_TYPE,
  RECIPIENT_REPR_OCCUPATION,
  RECIPIENT_REPR_SURNAME,
  RECIPIENT_REPR_FIRSTNAME,
  RECIPIENT_REPR_PATRONYMIC,
  RECIPIENT_REPR_RNOKPP,
  RECIPIENT_REPR_CODE,
  RECIPIENT_REPR_COUNTRY,
  RECIPIENT_REPR_LOCALITY,
  RECIPIENT_PHONE,
  RECIPIENT_PHONE2,
  RECIPIENT_PHONE3,
  RECIPIENT_PHONE_MAX,
  RECIPIENT_EMAIL,
  RECIPIENT_ADDRESS,
  RECIPIENT_KOATUU,
  RECIPIENT_ID,
  RECIPIENT_P_PRIVATE,
  // disabled
  RECIPIENT_PL_EDRPOU,
  RECIPIENT_PL_REGION,
  RECIPIENT_PL_LOCALITY,
  RECIPIENT_PL_ADRESS,
  RECIPIENT_PL_ORGNAME,
  RECIPIENT_PL_RECIPIENT_TYPE,
  RECIPIENT_PL_SURNAME,
  RECIPIENT_PL_NAME,
  RECIPIENT_PL_PATRONYMIC,
  RECIPIENT_EDITABLE,
  RECIPIENT_PL_GOT_OLFCODE,
  // other
  REQUEST_RECIPIENT,
  REQUEST_RECIPIENT_DOC,
  SUCCESS_RECIPIENT,
  ERROR_RECIPIENT,
  REQUEST_RECIPIENTS,
  SUCCESS_RECIPIENTS,
  SUCCESS_RECIPIENTS_PAGE,
  ERROR_RECIPIENTS,
  REQUEST_CONTROLS,
  SUCCESS_CONTROLS,
  ERROR_CONTROLS,
  REQUEST_APPLY_APP,
  SUCCESS_APPLY_APP,
  ERROR_APPLY_APP,
  REQUEST_APPROVE,
  SUCCESS_APPROVE,
  ERROR_APPROVE,
  REQUEST_DENIED,
  SUCCESS_DENIED,
  ERROR_DENIED,
  REQUEST_RECIPIENT_SAVE,
  SUCCESS_RECIPIENT_SAVE,
  ERROR_RECIPIENT_SAVE,
  RECIPIENT_CLEAR,
  SUCCESS_RECIPIENT_APPLICATIONS_HISTORY,
  ERROR_RECIPIENT_APPLICATIONS_HISTORY,
  REQUEST_RECIPIENT_APPLICATIONS_HISTORY,
  SUCCESS_DELETE_RECIPIENT,
  ERROR_DELETE_RECIPIENT,
  REQUEST_DELETE_RECIPIENT,
  SUCCESS_RECIPIENTS_SELECT,
  ERROR_RECIPIENTS_SELECT,
  REQUEST_RECIPIENTS_SELECT,
  SUCCESS_RECIPIENT_DOCUMENTS,
  ERROR_RECIPIENT_DOCUMENTS,
  REQUEST_RECIPIENT_DOCUMENTS,
  REQUEST_RECIPIENT_DOCUMENTS_SAVE,
  SUCCESS_RECIPIENT_DOCUMENTS_SAVE,
  ERROR_RECIPIENT_DOCUMENTS_SAVE,
  REQUEST_RECIPIENT_DOCUMENTS_DEL,
  SUCCESS_RECIPIENT_DOCUMENTS_DEL,
  ERROR_RECIPIENT_DOCUMENTS_DEL,
  APPLICATION_ID,
  APPLICATION_CARGO_ID,
  CARGO_ID,
  ERROR_RECIPIENT_DELETE_FROM_REGISTER,
  SUCCESS_RECIPIENT_DELETE_FROM_REGISTER,
  REQUEST_RECIPIENT_DELETE_FROM_REGISTER,
  AID_ID,
  RECIPIENTS_CLEAR,
  CONTROLS_CLEAR,
  RECIPIENT_NEW,
  RECIPIENT_OLD,
  RECIPIENT_NAIS,
  RECIPIENT_NEW_ID,
  RECIPIENT_SAVE_TRACK,
} from './action-types';

import {
  searchRecipientRegistr,
  getRecipients,
  saveRecipient,
  approveAuthority,
  deniedAuthority,
  getRecipient,
  applyApplication,
  getRecipientHistory,
  deleteRecipient,
  getAprovedRecipients,
  getRecipientDocumentsApi,
  saveRecipientDocumentsApi,
  deleteRecipientDocumentsApi,
  getRecipientFull,
  deleteRecipientFromRegisterApi,
  getRecipientFullNew,
  getControls,
} from '../../api/api';
import { localityAction } from './catalog.action';
import { toggleAction } from './toggle.action';
import { setCargoByRecipient, setCargoAction, getCargoDocumentsAction, clearCargo } from './cargo.action';
import { setDeclaration } from './declaration.action';
import { history } from '../../vibe/components/utilities/MainUtils/hist';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: true,
  timer: 5000,
});

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

export const recipientNais = (data) => ({
  type: RECIPIENT_NAIS,
  payload: data,
});

export const recipientNew = (data) => ({
  type: RECIPIENT_NEW,
  payload: data,
});

export const recipientOld = (data) => ({
  type: RECIPIENT_OLD,
  payload: data,
});

export const clearRecipient = () => {
  return { type: RECIPIENT_CLEAR };
};

export const clearRecipients = () => {
  return { type: RECIPIENTS_CLEAR };
};

export const setRecipientsForSelect = (data) => ({
  type: SUCCESS_RECIPIENTS_SELECT,
  payload: data,
});

export const getRecipientsForSelect = (id) => (dispatch) => {
  dispatch({ type: REQUEST_RECIPIENTS_SELECT });
  getAprovedRecipients(id)
    .then((response) => {
      const data = response.data.map((item) => ({
        value: item.id,
        label: item.c_orgname,
        edrpou: item.n_edrpou,
        erNumber: item.r_number,
      }));
      dispatch(setRecipientsForSelect(data));
    })
    .catch((error) => {
      dispatch({ type: ERROR_RECIPIENTS_SELECT, payload: error.response.data });
    });
};

export const deleteRecipientAction = (id) => (dispatch, getState) => {
  dispatch({ type: REQUEST_DELETE_RECIPIENT });
  deleteRecipient(id)
    .then((response) => {
      dispatch({ type: SUCCESS_DELETE_RECIPIENT });
      dispatch(getRecipientsFatch(getState().auth.user.id));
      swalWithBootstrapButtons.fire({
        text: 'Заяву видалено!',
        type: 'success',
      });
    })
    .catch((error) => {
      dispatch({ type: ERROR_DELETE_RECIPIENT, payload: error.response.data });
      swalWithBootstrapButtons.fire({
        title: 'Помилка',
        text: error.response.data,
        type: 'error',
      });
    });
};

export const recipientsApplicationsHistory = (data) => ({
  type: SUCCESS_RECIPIENT_APPLICATIONS_HISTORY,
  payload: data,
});

export const getRecipientHistoryApplications = (id) => async (dispatch) => {
  const succesRecipientHistoryApplications = (succes) => {
    dispatch(recipientsApplicationsHistory(succes));
    return succes;
  };

  const errorRecipientHistoryApplications = (error) => {
    dispatch({ type: ERROR_RECIPIENT_APPLICATIONS_HISTORY });
    return error;
  };

  try {
    dispatch({ type: REQUEST_RECIPIENT_APPLICATIONS_HISTORY });
    const success = await getRecipientHistory(id);
    return succesRecipientHistoryApplications(success.data);
  } catch (error) {
    return errorRecipientHistoryApplications(error);
  }
};

export const apply = (id) => (dispatch, getState) => {
  dispatch({ type: REQUEST_APPLY_APP });
  applyApplication(id)
    .then((response) => {
      dispatch({ type: SUCCESS_APPLY_APP });
      dispatch(getRecipientsFatch(getState().auth.user.id));
      swalWithBootstrapButtons.fire({
        text: 'Заяву подано!',
        type: 'success',
      });
    })
    .catch((error) => {
      dispatch({ type: ERROR_APPLY_APP, payload: error.response.data });
      swalWithBootstrapButtons.fire({
        type: 'error',
        title: 'Помилка',
        text: error.response.data,
      });
    });
};

export const newRecipienApplicationID = (id) => ({
  type: RECIPIENT_NEW_ID,
  payload: id,
});

export const currentRecipientId = (id) => ({
  type: RECIPIENT_ID,
  payload: id,
});

export const currentApplicationId = (id) => ({
  type: APPLICATION_ID,
  payload: id,
});

export const currentApplicationCargoId = (id) => ({
  type: APPLICATION_CARGO_ID,
  payload: id,
});

export const currentCargoId = (id) => ({
  type: CARGO_ID,
  payload: id,
});

export const currentAidId = (id) => ({
  type: AID_ID,
  payload: id,
});

export const recipientSaveTrack = (page) => ({
  type: RECIPIENT_SAVE_TRACK,
  payload: page,
});

export const recipientDocuments = (application_id) => (dispatch) => {
  dispatch({ type: REQUEST_RECIPIENT_DOC });
  getRecipientDocumentsApi(application_id)
    .then((response) => {
      dispatch(getRecipientDocumentsAction(response.data));
    })
    .catch((error) => {
      dispatch({
        type: ERROR_RECIPIENT_DOCUMENTS,
        payload: error.response.data,
      });
    });
};

export const recipientById = (id) => (dispatch) => {
  dispatch({ type: REQUEST_RECIPIENT });
  getRecipientFull(id)
    .then((response) => {
      // dispatch(localityAction(response.data.recipient.p_region))
      dispatch(currentRecipient(response.data.recipient));
      dispatch(getRecipientDocumentsAction(response.data.docs_recipient));
      dispatch(getCargoDocumentsAction(response.data.docs_cargo));
      // dispatch(setCargoByRecipient(response.data.scargo))
      // dispatch(setCargoAction(response.data.aid))
      dispatch(currentRecipientId(response.data.recipient.id));
      dispatch(currentApplicationId(response.data.recipient.p_application_id));
      dispatch(currentApplicationCargoId(response.data.scargo.i_cargo_app));
      // dispatch (currentCargoId(response.data.scargo.i_cargo))
      dispatch(toggleAction('1'));
      history.push(`/view-recipient`);
    })
    .catch((error) => {
      dispatch({ type: ERROR_RECIPIENT });

      dispatch(currentRecipientId(''));
      // dispatch(localityAction(response.data.recipient.p_region))
      dispatch(currentRecipient({}));
      const data = {};
      dispatch(setCargoByRecipient(data));
      dispatch(setCargoAction([]));

      swalWithBootstrapButtons
        .fire({
          text: error.response.data,
          type: 'error',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#2D5CA6',
        })
        .then((result) => {
          // if (result.value) {
          //     history.push('/register-recipients-personal')
          // }
        });
      // .then((result => {

      // })
    });
};

export const recipientByIdNew = (id) => (dispatch) => {
  dispatch({ type: REQUEST_RECIPIENT });
  getRecipientFullNew(id)
    .then((response) => {
      const { recipient } = response.data;

      const {
        // new params
        p_recipient_type,
        p_agent_position,
        p_agent_surname,
        p_agent_name,
        p_agent_patronymic,
        p_agent_rnokpp,
        p_agent_code,
        p_country,
        p_reg_address,
        p_phone2,
        ...olderParams
      } = recipient;

      // dispatch(localityAction(response.data.recipient.p_region))
      // dispatch(currentRecipient(response.data.recipient));
      dispatch(
        currentRecipient({
          ...olderParams,
          p_recipientType: p_recipient_type || '',
          p_reprOccupation: p_agent_position || '',
          p_reprSurname: p_agent_surname || '',
          p_reprFirstname: p_agent_name || '',
          p_reprPatronymic: p_agent_patronymic || '',
          p_agent_rnokpp: p_agent_rnokpp || '',
          p_agent_code: p_agent_code || '',
          p_reprCountry: p_country || '',
          p_reprLocality: p_reg_address || '',
          p_phone2: p_phone2 || '',
          p_phone_max: p_phone2 ? 2 : 1,
        })
      );
      dispatch(getRecipientDocumentsAction(response.data.docs_recipient));
      dispatch(getCargoDocumentsAction(response.data.docs_cargo));

      const {
        c_reg_number,
        c_ag_surname,
        c_ag_name,
        c_ag_patron,
        // c_proposal,
        // c_enumer,
        // c_packing,
        // c_docs,
        ...oldScargo
      } = response.data.scargo;

      const basic = response.data.scargo.i_aidform;
      // dispatch(setCargoByRecipient(response.data.scargo));
      dispatch(
        setCargoByRecipient({
          ...oldScargo,
          c_donor_regnumber: c_reg_number || '',
          c_head_surname: c_ag_surname || '',
          c_head_firstname: c_ag_name || '',
          c_head_patronymic: c_ag_patron || '',
          // c_written_proposal: c_proposal || '',
          // c_invoice: c_enumer || '',
          // c_package_letter: c_packing || '',
          // c_other_accompany_docs: c_docs || '',
        })
      );
      // we need this func to розподілити допомогу

      const arrayAid = response.data.aid
        ? response.data.aid.map((item) => {
            const {
              // acquire array
              acquirer,
              // both
              i_aidtype, // знову потрібен
              i_aidform,
              // n_sum,
              c_category,
              // v1
              i_uktzed,
              n_weight,
              c_expiration_date,
              c_service_life,
              // v4
              n_comm_year,
              n_reg_year,
              c_vehicle_type,
              // c_category,
              // c_brand,
              // c_model,
              // c_reg,
              // c_engine,
              // c_vin,
              // c_body_numb,
              // c_ch,
              // n_sum,
              // c_owners,
              ...oldData
            } = item;
            return {
              // розкладається на елементи тут
              // acquirer array
              acquirer: acquirer ? [...acquirer] : [],
              // vantazh 1
              // n_wear_percentage: n_sum || '',
              c_category: Number(basic) === 1 ? c_category : '',
              n_weight: n_weight || '',
              c_service_life_term: c_service_life || '',
              c_exploitation_term: c_expiration_date || '',
              // vantazh 4
              n_year_use_start: n_comm_year || '',
              c_first_registration: n_reg_year || '',
              c_vehicle_type: c_vehicle_type || '',
              // c_vehicle_category: c_category || '',
              // c_vehicle_category: Number(basic) === 4 ? c_category : '',
              // c_vehicle_brand: c_brand || '',
              // c_vehicle_model: c_model || '',
              // c_vehicle_num: c_reg || '',
              // c_vehicle_engine_num: c_engine || '',
              // c_vehicle_VIN_code: c_vin || '',
              // c_truckbody_num: c_body_numb || '',
              // c_chassis_num: c_ch || '',
              // n_value: n_sum || '',
              // c_owners: c_owners || '',
              i_aidtype: i_aidtype || '', // get back
              ...oldData,
              isEdit: false,
            };
          })
        : [];
      dispatch(setCargoAction(arrayAid));
      // dispatch(setCargoAction(response.data.aid));

      dispatch(currentRecipientId(response.data.recipient.id));
      dispatch(currentApplicationId(response.data.recipient.p_application_id));
      dispatch(currentApplicationCargoId(response.data.scargo.i_cargo_app));
      // dispatch(currentCargoId(response.data.scargo.i_cargo));
      // set declaration
      const { manifest } = response.data;
      const declaration = manifest ? { ...manifest } : {};
      dispatch(setDeclaration(declaration));
      // dispatch(toggleAction('3'));
    })
    .catch((error) => {
      dispatch({ type: ERROR_RECIPIENT });

      dispatch(currentRecipientId(''));
      // dispatch(localityAction(response.data.recipient.p_region))
      dispatch(currentRecipient({}));
      const data = {};
      dispatch(setCargoByRecipient(data));
      dispatch(setCargoAction([]));

      swalWithBootstrapButtons
        .fire({
          text: error.response.data,
          type: 'error',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#2D5CA6',
        })
        .then((result) => {
          // if (result.value) {
          //     history.push('/register-recipients-personal')
          // }
        });
      // .then((result => {

      // })
    });
};

export const recipientByIdEdit = (id) => (dispatch) => {
  dispatch({ type: REQUEST_RECIPIENT });
  getRecipientFullNew(id)
    .then((response) => {
      // dispatch(localityAction(response.data.recipient.p_region))
      // dispatch(currentRecipient(response.data.recipient));

      const { recipient } = response.data;

      const {
        // new params
        p_recipient_type,
        p_agent_position,
        p_agent_surname,
        p_agent_name,
        p_agent_patronymic,
        p_agent_rnokpp,
        p_agent_code,
        p_country,
        p_reg_address,
        p_phone2,
        p_private,
        ...olderParams
      } = recipient;

      dispatch(
        currentRecipient({
          ...olderParams,
          p_recipientType: p_recipient_type || '',
          p_reprOccupation: p_agent_position || '',
          p_reprSurname: p_agent_surname || '',
          p_reprFirstname: p_agent_name || '',
          p_reprPatronymic: p_agent_patronymic || '',
          p_agent_rnokpp: p_agent_rnokpp || '',
          p_agent_code: p_agent_code || '',
          p_reprCountry: p_country || '',
          p_reprLocality: p_reg_address || '',
          p_phone2: p_phone2 || '',
          p_phone_max: p_phone2 ? 2 : 1,
          // eslint-disable-next-line no-nested-ternary
          p_private: p_private === 1 ? 'yes' : p_private === 0 ? 'no' : '',
        })
      );

      const data = { ...response.data.scargo };
      dispatch(getRecipientDocumentsAction(response.data.docs_recipient));
      dispatch(getCargoDocumentsAction(response.data.docs_cargo));

      const {
        c_reg_number,
        c_ag_surname,
        c_ag_name,
        c_ag_patron,
        // c_proposal,
        // c_enumer,
        // c_packing,
        // c_docs,
        ...oldScargo
      } = response.data.scargo;

      const basic = response.data.scargo.i_aidform;

      dispatch(
        setCargoByRecipient({
          ...oldScargo,
          c_donor_regnumber: c_reg_number || '',
          c_head_surname: c_ag_surname || '',
          c_head_firstname: c_ag_name || '',
          c_head_patronymic: c_ag_patron || '',
          // c_written_proposal: c_proposal || '',
          // c_invoice: c_enumer || '',
          // c_package_letter: c_packing || '',
          // c_other_accompany_docs: c_docs || '',
        })
      );

      // dispatch(setCargoByRecipient(response.data.scargo));
      const arrayAid = response.data.aid
        ? response.data.aid.map((item) => {
            const {
              // acquire array
              acquirer,
              // both
              i_aidtype, // знову потрібен
              i_aidform,
              // n_sum,
              c_category,
              // v1
              i_uktzed,
              n_weight,
              c_expiration_date,
              c_service_life,
              // v4
              n_comm_year,
              n_reg_year,
              c_vehicle_type,
              // c_category,
              // c_brand,
              // c_model,
              // c_reg,
              // c_engine,
              // c_vin,
              // c_body_numb,
              // c_ch,
              // n_sum,
              // c_owners,
              ...oldData
            } = item;
            return {
              // розкладається на елементи тут
              // acquirer array
              acquirer: acquirer ? [...acquirer] : [],
              // vantazh 1
              // n_wear_percentage: n_sum || '',
              c_category: Number(basic) === 1 ? c_category : '',
              n_weight: n_weight || '',
              c_service_life_term: c_service_life || '',
              c_exploitation_term: c_expiration_date || '',
              // vantazh 4
              n_year_use_start: n_comm_year || '',
              c_first_registration: n_reg_year || '',
              c_vehicle_type: c_vehicle_type || '',
              // c_vehicle_category: c_category || '',
              // c_vehicle_category: Number(basic) === 4 ? c_category : '',
              // c_vehicle_brand: c_brand || '',
              // c_vehicle_model: c_model || '',
              // c_vehicle_num: c_reg || '',
              // c_vehicle_engine_num: c_engine || '',
              // c_vehicle_VIN_code: c_vin || '',
              // c_truckbody_num: c_body_numb || '',
              // c_chassis_num: c_ch || '',
              // n_value: n_sum || '',
              // c_owners: c_owners || '',
              i_aidtype: i_aidtype || '', // back to aidtype
              ...oldData,
              isEdit: false,
            };
          })
        : [];

      // dispatch(setCargoAction(response.data.aid))
      dispatch(setCargoAction(arrayAid));
      dispatch(currentRecipientId(response.data.recipient.id));
      dispatch(currentApplicationId(response.data.recipient.p_application_id));
      dispatch(currentApplicationCargoId(response.data.scargo.i_cargo_app));
      dispatch(currentCargoId(response.data.scargo.i_cargo));
      // set declaration
      const { manifest } = response.data;
      const declaration = manifest ? { ...manifest } : {};
      dispatch(setDeclaration(declaration));
      // dispatch(toggleAction('3'));
      // history.push('/create-recipient/edit');
    })
    .catch((error) => {
      dispatch({ type: ERROR_RECIPIENT });

      dispatch(currentRecipientId(''));
      // dispatch(localityAction(response.data.recipient.p_region))
      dispatch(currentRecipient({}));
      const data = {};
      dispatch(setCargoByRecipient(data));
      dispatch(setCargoAction([]));

      swalWithBootstrapButtons
        .fire({
          text: error.response.data,
          type: 'error',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#2D5CA6',
        })
        .then((result) => {
          if (result.value) {
            history.push('/register-recipients-personal');
          }
        });
      // .then((result => {

      // })
    });
};

export const currentRecipient = (data) => {
  return {
    type: SUCCESS_RECIPIENT,
    payload: data,
  };
};

export const approve = (data) => (dispatch) => {
  dispatch({ type: `REQUEST_${data.index}` });
  const dataRecipientApprove = {
    p_id: data.p_id,
    p_user: data.p_user,
  };
  approveAuthority(dataRecipientApprove)
    .then((response) => {
      dispatch({ type: `SUCCESS_${data.index}` });
    })
    .catch((error) => {
      dispatch({ type: `ERROR_${data.index}` });
    });
};

export const denied = (data) => (dispatch) => {
  dispatch({ type: `REQUEST_${data.index}` });
  const dataRecipientDenied = {
    p_id: data.p_id,
    p_user: data.p_user,
  };
  deniedAuthority(dataRecipientDenied)
    .then((response) => {
      dispatch({ type: `SUCCESS_${data.index}` });
    })
    .catch((error) => {
      dispatch({ type: `ERROR_${data.index}` });
    });
};

export const getRecipientsFatch = (id) => (dispatch) => {
  dispatch({ type: REQUEST_RECIPIENTS });
  getRecipients(id)
    .then((response) => {
      // redirect if empty
      const { data } = response;
      if (data.pn_page === 1) {
        history.push(`/create-recipient/save`);
      }

      const { pn_page } = response.data;
      const sortedByRcipient = response.data.cursor.sort((a, b) => {
        // var aa = a.split('/').reverse().join(),
        // bb = b.split('/').reverse().join();
        return a.c_orgname < b.c_orgname ? -1 : a.c_orgname > b.c_orgname ? 1 : 0;
      });
      dispatch(setRecipients(response.data));
      dispatch(setRecipientsPage(pn_page));
    })
    .catch((error) => {
      dispatch({
        type: ERROR_RECIPIENTS,
        payload: 'Помилка серевера',
      });
    });
};

export const getRecipientsSearch = (data) => (dispatch) => {
  dispatch({ type: REQUEST_RECIPIENTS });
  searchRecipientRegistr(data)
    .then((response) => {
      dispatch(setRecipients(response.data));
    })
    .catch((error) => {
      dispatch(clearRecipient());
      dispatch({
        type: ERROR_RECIPIENTS,
        payload: error.response.data,
      });
      // if (error.response && error.response.status === 400) {
      //     dispatch({
      //       type: ERROR_RECIPIENTS,
      //       payload: error.response.data,
      //     });
      //     swalWithBootstrapButtons.fire({
      //         title: 'Помилка',
      //         text: error.response.data,
      //         type: 'error',
      //     })
      //   } else {
      //     dispatch({
      //       type: ERROR_RECIPIENTS,
      //       payload: 'Помилка серевера',
      //     });
      //     swalWithBootstrapButtons.fire({
      //         title: 'Помилка серверу',
      //         type: 'error',
      //     })
      //   }
    });
};

export const setRecipients = (recipients) => {
  return {
    type: SUCCESS_RECIPIENTS,
    payload: recipients,
  };
};

export const setRecipientsPage = (recipientsPage) => {
  return {
    type: SUCCESS_RECIPIENTS_PAGE,
    payload: recipientsPage,
  };
};
export const clearControls = () => {
  return { type: CONTROLS_CLEAR };
};

export const setControls = (controls) => {
  return {
    type: SUCCESS_CONTROLS,
    payload: controls,
  };
};

export const getControlsSearch = (data) => (dispatch) => {
  dispatch({ type: REQUEST_CONTROLS });
  getControls(data)
    .then((response) => {
      dispatch(setControls(response.data));
    })
    .catch((error) => {
      // dispatch(clearRecipient());
      dispatch({
        type: ERROR_CONTROLS,
        payload: error.response.data,
      });
    });
};
// DOCUMENTS OF RECIPIENT
export const saveRecipientDocument = (info, data) => (dispatch, getState) => {
  dispatch({ type: REQUEST_RECIPIENT_DOCUMENTS_SAVE });
  saveRecipientDocumentsApi(info, data)
    .then((resp) => {
      dispatch({ type: SUCCESS_RECIPIENT_DOCUMENTS_SAVE });
      // dispatch(getRecipientDocuments(getState().recipient.tabsFlag.applicationSaveId));
      {
        getState().recipient.tabsFlag.p_new_application_id === ''
          ? dispatch(getRecipientDocuments(getState().recipient.tabsFlag.applicationSaveId))
          : dispatch(getRecipientDocuments(getState().recipient.tabsFlag.p_new_application_id));
      }
      //   dispatch(getRecipientDocuments(101));
    })
    .catch((error) => {
      dispatch({
        type: ERROR_RECIPIENT_DOCUMENTS_SAVE,
        payload: error.response.data,
      });
      //   if (error.response && error.response.status === 400) {
      //     dispatch({
      //       type: ERROR_RECIPIENT_DOCUMENTS_SAVE,
      //       payload: error.response.data,
      //     });
      //     swalWithBootstrapButtons.fire({
      //         title: 'Помилка',
      //         text: error.response.data,
      //         type: 'error',
      //     })
      //   } else {
      //     dispatch({
      //       type: ERROR_RECIPIENT_DOCUMENTS_SAVE,
      //       payload: 'Помилка серевера',
      //     });
      //     swalWithBootstrapButtons.fire({
      //         title: 'Помилка серверу',
      //         type: 'error',
      //     })
      //   }
    });
};

export const getRecipientDocuments = (application_id) => (dispatch) => {
  dispatch({ type: REQUEST_RECIPIENT_DOCUMENTS });
  getRecipientDocumentsApi(application_id)
    .then((response) => {
      dispatch(getRecipientDocumentsAction(response.data));
    })
    .catch((error) => {
      dispatch({
        type: ERROR_RECIPIENT_DOCUMENTS,
        payload: error.response.data,
      });
      // if (error.response && error.response.status === 400) {
      //     dispatch({
      //         type: ERROR_RECIPIENT_DOCUMENTS,
      //         payload: error.response.data
      //     })
      // } else {
      //     dispatch({
      //         type: ERROR_RECIPIENT_DOCUMENTS,
      //         payload: "Помилка серевера"
      //     })
      // }
    });
};

export const deleteRecipientDocument = (id) => (dispatch, getState) => {
  dispatch({ type: REQUEST_RECIPIENT_DOCUMENTS_DEL });
  deleteRecipientDocumentsApi(id)
    .then((resp) => {
      dispatch({ type: SUCCESS_RECIPIENT_DOCUMENTS_DEL });
      {
        getState().recipient.tabsFlag.p_new_application_id === ''
          ? dispatch(getRecipientDocuments(getState().recipient.tabsFlag.applicationSaveId))
          : dispatch(getRecipientDocuments(getState().recipient.tabsFlag.p_new_application_id));
      }
    })
    .catch((error) => {
      dispatch({
        type: ERROR_RECIPIENT_DOCUMENTS_DEL,
        payload: error.response.data,
      });
      //   if (error.response && error.response.status === 400) {
      //     dispatch({
      //       type: ERROR_RECIPIENT_DOCUMENTS_DEL,
      //       payload: error.response.data,
      //     });
      //   } else {
      //     dispatch({
      //       type: ERROR_RECIPIENT_DOCUMENTS_DEL,
      //       payload: 'Помилка серевера',
      //     });
      //   }
    });
};

export const getRecipientDocumentsAction = (documents) => {
  return {
    type: SUCCESS_RECIPIENT_DOCUMENTS,
    payload: documents,
  };
};

export const saveRecipientAction = (data) => (dispatch) => {
  dispatch({ type: REQUEST_RECIPIENT_SAVE });
  saveRecipient(data)
    .then((response) => {
      dispatch({ type: SUCCESS_RECIPIENT_SAVE, payload: 'Заяву збережено!' });

      dispatch(currentRecipientId(response.data.p_recipient_id));
      dispatch(currentApplicationId(response.data.p_application_id));
      dispatch(recipientByIdEdit(response.data.p_recipient_id));
      // dispatch(toggleAction('3'));
      const message = `Отримувача зареєстровано за № ${response.data.p_recipient_number}`;
      Toast.fire({
        type: 'success',
        title: message,
      });
      dispatch(clearCargo());
    })
    .catch((error) => {
      dispatch({
        type: ERROR_RECIPIENT_SAVE,
        payload: error.response.data,
      });
      // if (error.response.status === 400) {
      //     swalWithBootstrapButtons.fire({
      //         title: 'Помилка',
      //         text: error.response.data,
      //         type: 'error',
      //     })
      //     dispatch({
      //         type: ERROR_RECIPIENT_SAVE,
      //         payload: error.response.data
      //     })
      // } else {
      //     dispatch({
      //         type: ERROR_RECIPIENT_SAVE,
      //         payload: "Помилка серевера"
      //     })

      //     swalWithBootstrapButtons.fire({
      //         title: '"Помилка серевера"',
      //         //text: error.response.data,
      //         type: 'error',
      //     })
      // }
    });
};

export const registerRecipient = (value) => ({
  type: RECIPIENT_REGISTERED,
  payload: value,
});

export const recipientAction = (type, value) => {
  switch (type) {
    case 'p_region':
      return {
        type: RECIPIENT_REGION,
        payload: value,
      };
    case 'p_city':
      return {
        type: RECIPIENT_CITY,
        payload: value,
      };
    case 'p_koatuu':
      return {
        type: RECIPIENT_KOATUU,
        payload: value,
      };
    case 'p_edrpou':
      return {
        type: RECIPIENT_EDRPOU,
        payload: value,
      };
    case 'p_orgname':
      return {
        type: RECIPIENT_TITLE,
        payload: value,
      };
    case 'p_surname':
      return {
        type: RECIPIENT_SURNAME,
        payload: value,
      };
    case 'p_name':
      return {
        type: RECIPIENT_NAME,
        payload: value,
      };
    case 'p_patronymic':
      return {
        type: RECIPIENT_LASTNAME,
        payload: value,
      };
    case 'p_recipientType':
      return {
        type: RECIPIENT_RECIPIENT_TYPE,
        payload: value,
      };
    case 'p_reprOccupation':
      return {
        type: RECIPIENT_REPR_OCCUPATION,
        payload: value,
      };
    case 'p_reprSurname':
      return {
        type: RECIPIENT_REPR_SURNAME,
        payload: value,
      };
    case 'p_reprFirstname':
      return {
        type: RECIPIENT_REPR_FIRSTNAME,
        payload: value,
      };
    case 'p_reprPatronymic':
      return {
        type: RECIPIENT_REPR_PATRONYMIC,
        payload: value,
      };
    case 'p_agent_rnokpp':
      return {
        type: RECIPIENT_REPR_RNOKPP,
        payload: value,
      };
    case 'p_agent_code':
      return {
        type: RECIPIENT_REPR_CODE,
        payload: value,
      };
    case 'p_reprCountry':
      return {
        type: RECIPIENT_REPR_COUNTRY,
        payload: value,
      };
    case 'p_reprLocality':
      return {
        type: RECIPIENT_REPR_LOCALITY,
        payload: value,
      };
    case 'p_phone':
      return {
        type: RECIPIENT_PHONE,
        payload: value,
      };
    case 'p_phone2':
      return {
        type: RECIPIENT_PHONE2,
        payload: value,
      };
    case 'p_phone3':
      return {
        type: RECIPIENT_PHONE3,
        payload: value,
      };
    case 'p_phone_max':
      return {
        type: RECIPIENT_PHONE_MAX,
        payload: value,
      };
    case 'p_email':
      return {
        type: RECIPIENT_EMAIL,
        payload: value,
      };
    case 'p_adress':
      return {
        type: RECIPIENT_ADDRESS,
        payload: value,
      };
    case 'pl_edrpou':
      return {
        type: RECIPIENT_PL_EDRPOU,
        payload: value,
      };
    case 'pl_region':
      return {
        type: RECIPIENT_PL_REGION,
        payload: value,
      };
    case 'pl_locality':
      return {
        type: RECIPIENT_PL_LOCALITY,
        payload: value,
      };
    case 'pl_adress':
      return {
        type: RECIPIENT_PL_ADRESS,
        payload: value,
      };
    case 'pl_orgname':
      return {
        type: RECIPIENT_PL_ORGNAME,
        payload: value,
      };
    case 'pl_recipient_type':
      return {
        type: RECIPIENT_PL_RECIPIENT_TYPE,
        payload: value,
      };
    case 'pl_surname':
      return {
        type: RECIPIENT_PL_SURNAME,
        payload: value,
      };
    case 'pl_name':
      return {
        type: RECIPIENT_PL_NAME,
        payload: value,
      };
    case 'pl_patronymic':
      return {
        type: RECIPIENT_PL_PATRONYMIC,
        payload: value,
      };
    case 'editable':
      return {
        type: RECIPIENT_EDITABLE,
        payload: value,
      };
    case 'p_private':
      return {
        type: RECIPIENT_P_PRIVATE,
        payload: value,
      };
    case 'pl_got_olfcode':
      return {
        type: RECIPIENT_PL_GOT_OLFCODE,
        payload: value,
      };
    case 'p_order':
      return {
        type: 'RECIPIENT_ORDER',
        payload: value,
      };
    case 'p_dt_order':
      return {
        type: 'RECIPIENT_DATE_ORDER',
        payload: value,
      };
    case 'p_order_off':
      return {
        type: 'RECIPIENT_ORDER_OFF',
        payload: value,
      };
    case 'p_dt_order_off':
      return {
        type: 'RECIPIENT_DATE_ORDER_OFF',
        payload: value,
      };
    case 'p_order_cng':
      return {
        type: 'RECIPIENT_ORDER_CNG',
        payload: value,
      };
    case 'p_dt_order_cng':
      return {
        type: 'RECIPIENT_DATE_CHANGES',
        payload: value,
      };
    default:
      return value;
  }
};

// delete from register Recipient

export const handleDeleleteFromRegister = (data) => (dispatch) => {
  dispatch({ type: REQUEST_RECIPIENT_DELETE_FROM_REGISTER });
  deleteRecipientFromRegisterApi(data)
    .then((resp) => {
      dispatch({ type: SUCCESS_RECIPIENT_DELETE_FROM_REGISTER });
    })
    .catch((error) => {
      //   if (error.response && error.response.status === 400) {
      //     dispatch({
      //       type: ERROR_RECIPIENT_DELETE_FROM_REGISTER,
      //       payload: error.response.data,
      //     });
      //   } else {
      //     dispatch({
      //       type: ERROR_RECIPIENT_DELETE_FROM_REGISTER,
      //       payload: 'Помилка серевера',
      //     });
      //   }
    });
};

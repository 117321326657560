import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { makeStyles, withStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

const TableReportDocs = ({ docsSelect }) => {
  const classes = useStyles();

  return (
    <Table size="small" className={classes.table}>
      <TableHead>
        <TableRow>
          <StyledTableCell>Тип документу</StyledTableCell>
          <StyledTableCell>Дата завантаження</StyledTableCell>
          <StyledTableCell>Скан документу</StyledTableCell>
          <StyledTableCell>Детальний опис документу</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {docsSelect.map((row) => {
          const { c_docname, c_filename, doc_type_txt, d_ins } = row;
          return (
            <TableRow>
              <StyledTableCell width="25%">{doc_type_txt}</StyledTableCell>
              <StyledTableCell width="25%">{d_ins}</StyledTableCell>
              <StyledTableCell width="25%">
                <a href={`/aid/file/${c_filename}`} rel="noopener noreferrer" target="_blank">
                  {c_filename}
                </a>
              </StyledTableCell>
              <StyledTableCell width="25%">{c_docname}</StyledTableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default TableReportDocs;

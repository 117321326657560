import React from 'react';
import { Grid } from '@material-ui/core';
import CompetencyPanel from './CompetencyPanel';
import CompetencyTable from './CompetencyTable';

const Competency = () => {
  const [table, setTable] = React.useState({ cursor: [], pn_page: 1, pn_rows: 10, total_rows: 0 });
  const [newSubmit, setNewSubmit] = React.useState(false);

  const [pn_page, setPnPage] = React.useState(1);
  const [pn_rows, setPnRows] = React.useState(10);

  const handleChangePage = async (event, newPage) => {
    const page = newPage + 1;
    setPnPage(page);
  };

  const handleChangeRowsPerPage = async (event) => {
    setPnRows(+event.target.value);
    setPnPage(1);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <CompetencyPanel setTable={setTable} setNewSubmit={setNewSubmit} pn_page={pn_page} pn_rows={pn_rows} />
        </Grid>
        <Grid item sm={12}>
          <CompetencyTable
            tableData={table}
            newSubmit={newSubmit}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Competency;

import { FETCH_ORDER_ROWS_FALIURE, FETCH_ORDER_ROWS_SUCCESS, FETCH_ORDER_ROWS_START } from '../actions/action-types';

const initialState = {
  rows: [],
  isLoading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDER_ROWS_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_ORDER_ROWS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        rows: [...action.payload],
      };
    case FETCH_ORDER_ROWS_FALIURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

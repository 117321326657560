import React from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import format from 'date-fns/format';
import 'react-datepicker/dist/react-datepicker.css';
import { withRouter } from 'react-router';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getReportData } from '../../../../api/api';
import {
  setBalanceArray,
  setDistributionArray,
  setReportCargoList,
  setReportParams,
  setReportAidtype,
  setReportAcquirer,
} from '../../../../redux/actions/report.action';
import { toggleAction } from '../../../../redux/actions/toggle.action';
import { cargoCurrentDate } from '../../../../redux/actions/cargo.action';
import Table1 from './Table1';
import { NextIcon } from './reportHelpers/CustomIcon';
import { transformBalance, transformDistribution } from './reportHelpers/helpers';
import ReportExcel from './ReportExcel';

const useStyles = makeStyles((theme) => ({
  button_img: {
    marginLeft: '15px',
  },
  button_sign: {
    marginRight: '30px',
    alignItems: 'center',
    padding: '20px 70px',
    gap: '4px',
    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',

    fontFamily: 'e-Ukraine Head',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#FFFFFF',
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      width: '260px',
    },
  },
  moveBtn: {
    padding: '12px 16px',
    gap: '8px',
    borderRadius: '30px',
    border: '2px solid #141414',
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    textAlign: 'center',
  },
}));

const ReportTables = ({ toggle, currentDate }) => {
  const region = useSelector((state) => state.catalogs.region);
  const i_recip = useSelector((state) => state.report.params.i_recip);
  const curr_date = useSelector((state) => state.report.params.curr_date);
  const dispatch = useDispatch();
  const classes = useStyles();

  const updateBothTables = () => {
    getReportData({ pd_rep: curr_date, pi_recip: i_recip })
      .then((res) => {
        // eslint-disable-next-line no-shadow
        const { balance, distrib, cargo: newCargo, aidtype, acquirer, general } = res.data;
        const { i_recip: Irecip, curr_date: currDate, min_date: minDateG, i_report } = general[0];
        dispatch(setReportParams({ i_recip: Irecip, curr_date: currDate, min_date: minDateG, i_report }));
        dispatch(setReportCargoList(newCargo));
        dispatch(setReportAidtype(aidtype));
        dispatch(setReportAcquirer(acquirer));
        dispatch(setBalanceArray(transformBalance(balance, newCargo)));
        dispatch(setDistributionArray(transformDistribution(distrib, acquirer, region)));
      })
      // eslint-disable-next-line no-unused-vars
      .catch((err) => {});
  };

  React.useEffect(() => {
    if (curr_date && i_recip) {
      updateBothTables();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curr_date]);

  async function NextFunc() {
    dispatch(cargoCurrentDate(format(currentDate, 'dd.MM.yyyy')));
    toggle(1);
  }

  return (
    <>
      {currentDate && (
        <div>
          <Table1 updateBothTables={updateBothTables} currentDate={currentDate} />
        </div>
      )}
      <div className="row" style={{ margin: '50px 0 0 0' }}>
        <div className="col-12 mt-3 mb-2 text-center">
          <Button
            type="button"
            className={classes.moveBtn}
            onClick={() => NextFunc()}
            disabled={!currentDate}
            variant="outlined"
            endIcon={<NextIcon />}
          >
            Перейти до розподілу
          </Button>
        </div>
      </div>
    </>
  );
};

export default withRouter(connect(null, { toggleAction, cargoCurrentDate })(ReportTables));

import { 
  SELECT_APPLICATIONS,
  SUCCESS_APPLICATIONS,
  CHECK_APPLICATION,
  TABLE_CHANGE,
  SELECT_CLEAR,
  COMMISSION_SET,
  COMMISSION_CLEAR,
  REQUEST_APPLICATIONS,
  ERROR_APPLICATIONS,
  SUCCESS_CARGO_APPLICATIONS,
  COMMISION_ID,
  SET_MEETING,
  TYPE_MEETING
} from './action-types';

import{getApplicationForCommission} from '../../api/api'
import Swal from 'sweetalert2';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

export const typeMeeting = (type) => ({
  type: TYPE_MEETING,
  payload: type
})

export const select = (data) => {  // put checked id into array
  return {
    type: SELECT_APPLICATIONS,
    payload:data
  }
}

export const clearSelect = (Applications) => {
  return {
    type: SELECT_CLEAR,
    payload:Applications
  }
}

export const check = (isSelected) => { // change redux-flag on true when all checkboxes is selected
  return {
    type: CHECK_APPLICATION,
    payload:isSelected
  }
}

export const tableChange = (data) => {  //renew data in table after cheking
  return {
    type: TABLE_CHANGE,
    payload:data
  }
}

export const commisionID = id => ({
  type: COMMISION_ID,
  payload: id
})

export const setMeetingAction = data => ({
  type: SET_MEETING,
  payload: data
})

//current commission

export const setCurrentCommission = (data) => { 
  return {
    type: COMMISSION_SET,
    payload:data
  }
}
export const clearCurrentCommission = () => {  //clear data about current commision from redux
  return {
    type: COMMISSION_CLEAR,
  }
}


//get data for COMMISSION open

export const getApplicationInfo = (commission_id) => dispatch => {
  dispatch({type: REQUEST_APPLICATIONS})
  getApplicationForCommission(commission_id)
  .then(response => {
   
    if(response.data.length ===0){
      swalWithBootstrapButtons.fire({
        title: 'Відсутні заяви для розгляду!',
        type: 'error',
      })};

     const selected = []
      
     response.data.forEach(item => { if (item.checked === 1) {
         selected.push(item.apl_id)
       }
     })
     
     dispatch(select(selected)) //add selected id to array

     const AllApplicationsChecked = response.data.every(item => item.checked === 1);
     
     dispatch(check(AllApplicationsChecked)) 
   
     //change flag true/false when all is checked
     dispatch(getApplicationsForCommissionAction(response.data))
      
  })
  .catch(error => {
    dispatch({
      type: ERROR_APPLICATIONS,
      payload: error.response.data
  })
      // if (error.response && error.response.status === 400) {
      //     dispatch({
      //         type: ERROR_APPLICATIONS,
      //         payload: error.response.data
      //     })
      // } else {
      //     dispatch({
      //         type: ERROR_APPLICATIONS,
      //         payload: "Помилка серевера"
      //     })
      // }
  })
}

export const getApplicationsForCommissionAction = data => {
  return {
    type: SUCCESS_CARGO_APPLICATIONS,
    payload: data.sort((a, b) => a.apl_numb - b.apl_numb)
  };
};


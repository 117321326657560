import Swal from 'sweetalert2';

export const ToastSuccess = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
});

export const ToastError = Swal.mixin({
  toast: true,
  position: 'top',
  showConfirmButton: true,
});

// ToastError.fire({
//   type: 'error',
//   title: 'Помилка!',
// });

import jwt_decode from 'jwt-decode';
import setAuthToken from '../../vibe/components/utilities/MainUtils/setAuthToken';
import { history } from '../../vibe/components/utilities/MainUtils/hist';
import { getCatalogs } from './catalog.action';

import {
  SET_CURRENT_USER,
  REQUEST_USER,
  SUCCESS_USER,
  ERROR_USER,
  CHANGE_POSITION_PROFILE,
  CHANGE_EMAIL_PROFILE,
} from './action-types';
import { SendToServer } from '../../api/api';

import { getRandNumb, getErrMsg } from './utils';

export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded.sub,
    userMenu: decoded.menu,
  };
};

export const loginUser = (token) => (dispatch) => {
  setAuthToken(token);

  const decoded = jwt_decode(token);
  const userInfo = {
    sub: JSON.parse(decoded.sub),
    menu: JSON.parse(decoded.menu),
  };
  // user info
  const { sub, menu } = userInfo;

  dispatch(setCurrentUser(userInfo));
  dispatch(getCatalogs());
  localStorage.setItem('jwtToken', token);
};

let websoket;

export const cryptoLoginUser = (login, password) => (dispatch) => {
  websoket = new WebSocket('ws://localhost:11111');
  websoket.onopen = () => {
    const cmd = {
      id: 2,
      command: 'sign',
      data: window.btoa(unescape(encodeURIComponent(getRandNumb(8)))),
      // command:"sign", data: window.btoa(unescape(encodeURIComponent(data.data.randnumb))),

      pin: '3882',
      storeContent: true,
      includeCert: true,
      useStamp: false,
    };
    websoket.send(JSON.stringify(cmd));
  };
  websoket.onmessage = (event) => {
    const resp = JSON.parse(event.data);
    if (resp.result === 0) {
      const sign = resp.data.data;
      // dispatch(websocketMessage(sign))
      dispatch({ type: REQUEST_USER });
      SendToServer(login, password, sign)
        .then((response) => {
          // Save to localStorage
          const { token } = response.data;
          // Set token to ls
          localStorage.setItem('jwtToken', token);
          // Set token to Auth header
          setAuthToken(token);
          // Decode token to get user data
          const decoded = jwt_decode(token);
          dispatch({ type: SUCCESS_USER });

          const userInfo = {
            sub: JSON.parse(decoded.sub),
            menu: JSON.parse(decoded.menu),
          };
          // user info
          const { sub, menu } = userInfo;

          dispatch(setCurrentUser(userInfo));
          dispatch(getCatalogs());
          history.push('/home');
        })
        .catch((err) => {
          //   dispatch(failure(err))
          //   dispatch(websocketDisconnected())
          //   dispatch(websocketErrorType('warning'))
          // dispatch(websocketError('Якась помилка з сервера'))

          if (err.response.status === 400) {
            dispatch({
              type: ERROR_USER,
              payload: err.response.data,
            });
            // swalWithBootstrapButtons.fire({
            //   title: err.response.data,
            //   type: 'error'
            // })
          } else {
            // swalWithBootstrapButtons.fire({
            //   title: 'Помилка сервера.',
            //   type: 'error'
            // })
            dispatch({
              type: ERROR_USER,
              payload: 'Помилка сервера.',
            });
          }
          setAuthToken(false);
        });

    } else {
      setTimeout(() => {
        //   dispatch(websocketDisconnected())
        //   dispatch(websocketErrorType('error'))
        //   dispatch(websocketError(getErrMsg(resp.result)))
        //   swalWithBootstrapButtons.fire({
        //     title: getErrMsg(resp.result),
        //     type: 'error'
        //   })
        dispatch({
          type: ERROR_USER,
          payload: getErrMsg(resp.result),
        });
      }, 1000);
    }
  };
  websoket.onclose = (event) => {
    // localStorage.removeItem['redux-store']
    setTimeout(() => {
      if (event.wasClean) {
        // dispatch(websocketError('Програма криптоавтограф закрита'))
        dispatch({
          type: ERROR_USER,
          payload: 'Програма криптоавтограф закрита',
        });
        // swalWithBootstrapButtons.fire({
        //   title: 'Програма криптоавтограф закрита',
        //   type: 'error'
        // })
        // dispatch(websocketClear())
        // dispatch(logout())
        // dispatch(websocketDisconnected())
      } else {
        // dispatch(websocketError('Програма криптоавтограф не підключена'))

        // dispatch(websocketDisconnected())
        // dispatch(websocketErrorType('danger'))

        // swalWithBootstrapButtons.fire({
        //   title: 'Програма криптоавтограф не підключена',
        //   type: 'error'
        // })

        dispatch({
          type: ERROR_USER,
          payload: 'Програма криптоавтограф не підключена',
        });
      }
    }, 1000);
  };
};

export const changePositionProfile = (value) => ({
  type: CHANGE_POSITION_PROFILE,
  payload: value,
});

export const changeEmailProfile = (value) => ({
  type: CHANGE_EMAIL_PROFILE,
  payload: value,
});

// export const setCurrentMenu = (decoded) => {
//   return {
//     type: SET_CURRENT_USER,
//     payload: decoded,
//     //userMenu: decoded.menu
//   };
// };

export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('catalogs');
  localStorage.removeItem('redux-store');
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));

  dispatch({ type: 'LOGOUT_USER' });
};

import Dashboard from './pages/Dashboard';
import Buttons from './elements/Buttons';
import Alerts from './elements/Alerts';
import Grid from './elements/Grid';
import Typography from './elements/Typography';
import Cards from './elements/Cards';
import Tabs from './elements/Tabs';
import Tables from './elements/Tables';
import Breadcrumbs from './elements/Breadcrumbs';
import Forms from './elements/Forms';
import Loaders from './elements/Loaders';
import Avatars from './elements/Avatars';
import Invoice from './pages/Invoice';
import CmsPage from './pages/Cms';
import Widgets from './pages/Widgets';
import Modals from './elements/Modals';
import ProgressBars from './elements/ProgressBars';
import PaginationPage from './elements/Pagination';
import ErrorPage from './pages/404';

// Main register
import Register from './pages/RegisterMain/Register';
// import CargoDetailView from './pages/RegisterMain/CargoDetailView/CargoDetailView';
import СhangeRecipient from './pages/RegisterMain/ChangeRecipient/ChangeRecipient';
// Add humanitarian help
import Delivery from './pages/RegisterMain/Delivery';
import AddDelivery from './pages/RegisterMain/AddDelivery';
import ViewDelivery from './pages/RegisterMain/ViewDelivery';
import ReportView from './pages/RegisterMain/ReportView';
import ViewAllDocumentsCargo from './pages/RegisterMain/ViewAllDocumetsCargo';
import InventoryDescription from './pages/RegisterMain/InventoryDescription';
import ReportDescription from './pages/RegisterMain/ReportDescription';
import CustomsDate from './pages/RegisterMain/CustomsDate';
// Register recipients
import RegisterRecipients from './pages/RegisterRecipients/RegisterRecipients';
import ViewRegisterRecipients from './pages/RegisterRecipients/ViewRegisterRecipients';
import RegisterRecipientsPersonal from './pages/RegisterRecipients/RegisterRecipientsPersonal';
import AddRecipient from './pages/RegisterRecipients/AddRecipient';
import ReceivedApplicationsForCheck from './pages/RegisterRecipients/ReceivedApplicationsForCheck';
import ApplicationHistory from './pages/RegisterRecipients/View/ApplicationHistory';
import DeleteRecipient from './pages/RegisterRecipients/View/DeleteRecipient';
import ViewAllDocumentsRecipient from './pages/RegisterRecipients/View/ViewAllDocumetsRecipient';
import Inspector from './pages/RegisterRecipients/Inspector';
// Commission
import Commission from './pages/Commission/Commission';
import CommissionMeetingPersonal from './pages/Commission/RegisterCommissionPersonal';
import CommissionView from './pages/Commission/СommissionView/CommissionView';
import CommissionResults from './pages/Commission/СommissionView/CommissionResults';
import CreateCommissionMeeting from './pages/Commission/CreateCommissionMeeting';
import ViewAllDocuments from './pages/Commission/ViewAllDocuments';
import Vouting from './pages/Commission/Vouting/Vouting';
import DetailInfoView from './pages/Commission/DetailInfoView/DetailInfoView';

import RecipientsOrder from './pages/RegisterRecipients/Ordersprotocols/RecipientsOrder';
import RecipientsProtokol from './pages/RegisterRecipients/Ordersprotocols/RecipientsProtokol';
import CargoProtokol from './pages/RegisterMain/Ordersprotocols/CargoProtokol';
import CargoOrder from './pages/RegisterMain/Ordersprotocols/CargoOrder';
import CommissionOrder from './pages/Commission/СommissionView/CommissionOrder';
import CommissionProtokol from './pages/Commission/СommissionView/CommissionProtokol';
// import Profile from '../../src/layouts/Profile/Profile';
import Profile from '../layouts/Profile/Profile';
import Manual from './pages/Manual/Manual';
// search for orders
import OrderSearch from './pages/RegisterRecipients/Ordersprotocols/OrderSearch';

// order upload
import OrderUpload from './pages/Commission/СommissionView/OrderUpload';

// uktzed
import UktzedUpdate from './pages/RegisterRecipients/References/UktzedUpdate';

// report
import CreateReport from './pages/RegisterMain/CreateReport';
import ReportDistribution from './pages/RegisterMain/report/ReportDistribution';
// inventory
import InventoryTabs from './pages/RegisterMain/InventoryTabs';
import DepartmentalRegistration from './pages/DepartmentalRegistration';
import VehicleRegistrationDetails from './pages/DepartmentalRegistration/VehicleRegistrationDetails';

// competency
import Competency from './pages/Competency/Competency';
import CompetencyRequest from './pages/Competency/CompetencyRequest';
import CompetencyView from './pages/Competency/CompetencyView';
import Acts from './pages/Acts/Acts';
import EditAct from './pages/Acts/EditAct/EditAct';
import CreateAct from './pages/Acts/CreateAct/CreateAct';
import Act from './pages/Acts/Act/Act';

const pageList = [
  {
    name: 'Автоматизована система реєстрації гуманітарної допомоги',
    path: '/home',
    component: Dashboard,
  },
  {
    name: 'Buttons',
    path: '/elements/buttons',
    component: Buttons,
  },
  {
    name: 'Реєстр отримувачів гуманітарної допомоги',
    path: '/register-recipients',
    component: RegisterRecipients,
  },
  {
    name: 'Кабінет контролю',
    path: '/inspector',
    component: Inspector,
  },
  {
    name: 'Запит повноважень',
    path: '/competency',
    component: Competency,
  },
  {
    name: 'Запит повноважень',
    path: '/competency-request',
    component: CompetencyRequest,
  },
  {
    name: 'Повноваження',
    path: '/competency-view',
    component: CompetencyView,
  },
  {
    name: 'Мої записи',
    path: '/register-recipients-personal',
    component: RegisterRecipientsPersonal,
  },
  {
    name: 'Реєстрація отримувача та гуманітарної допомоги',
    path: '/create-recipient/save',
    component: AddRecipient,
  },
  {
    name: 'Редагування',
    path: '/create-recipient/edit',
    component: AddRecipient,
  },
  {
    name: 'Внести зміни',
    path: '/edit-recipient',
    component: AddRecipient,
  },
  {
    name: 'Додати',
    path: '/save-recipient-mspu',
    component: AddRecipient,
  },
  {
    name: 'Редагувати',
    path: '/edit-recipient-mspu',
    component: AddRecipient,
  },
  {
    name: 'Отримані',
    path: '/received-mspu',
    component: ReceivedApplicationsForCheck,
  },
  {
    name: 'Звіт',
    path: '/report/view-report',
    component: ReportView,
  },
  {
    name: 'Звіт',
    path: '/create-report',
    component: CreateReport,
  },
  {
    name: 'Звіт',
    path: '/report-distribution',
    component: ReportDistribution,
  },
  {
    name: 'Інвентарна відомість',
    path: '/inventory',
    component: InventoryTabs,
  },
  {
    name: 'Документи по отримувачу',
    path: '/view-all-documents-recipient',
    component: ViewAllDocumentsRecipient,
  },
  {
    name: 'Накази',
    path: '/recipients-order',
    component: RecipientsOrder,
  },
  {
    name: 'Накази і Протоколи',
    path: '/recipients-protokol',
    component: RecipientsProtokol,
  },
  {
    name: 'Накази і Протоколи',
    path: '/cargo-protokol',
    component: CargoProtokol,
  },
  {
    name: 'Наказ по гуманітарній допомозі',
    path: '/cargo-order',
    component: CargoOrder,
  },
  {
    name: 'Наказ ?????',
    path: '/commission-order',
    component: CommissionOrder,
  },
  {
    name: 'Пошук наказів',
    path: '/order-search',
    component: OrderSearch,
  },
  {
    name: 'Довідник УКТЗЕД',
    path: '/uktzed',
    component: UktzedUpdate,
  },
  {
    name: 'Протокол ?????',
    path: '/commission-protokol',
    component: CommissionProtokol,
  },
  {
    name: 'Профіль',
    path: '/user-profile',
    component: Profile,
  },
  // {
  //   name: 'Реєстр отримувачів гуманітарної допомоги',
  //   path: '/register-recipients-public',
  //   component: RegisterRecipientsPublic
  // },`/report/${''}/view-report`

  {
    name: 'Alerts',
    path: '/elements/alerts',
    component: Alerts,
  },
  {
    name: 'Grid',
    path: '/elements/grid',
    component: Grid,
  },
  {
    name: 'Typography',
    path: '/elements/typography',
    component: Typography,
  },
  {
    name: 'Cards',
    path: '/elements/cards',
    component: Cards,
  },
  {
    name: 'Tabs',
    path: '/elements/tabs',
    component: Tabs,
  },
  {
    name: 'Tables',
    path: '/elements/tables',
    component: Tables,
  },
  {
    name: 'Progress Bars',
    path: '/elements/progressbars',
    component: ProgressBars,
  },
  {
    name: 'Pagination',
    path: '/elements/pagination',
    component: PaginationPage,
  },
  {
    name: 'Modals',
    path: '/elements/modals',
    component: Modals,
  },
  {
    name: 'Breadcrumbs',
    path: '/elements/breadcrumbs',
    component: Breadcrumbs,
  },
  {
    name: 'Forms',
    path: '/elements/forms',
    component: Forms,
  },
  {
    name: 'Loaders',
    path: '/elements/loaders',
    component: Loaders,
  },
  {
    name: 'Avatars',
    path: '/elements/avatars',
    component: Avatars,
  },
  {
    name: 'Реєстр гуманітарної допомоги',
    path: '/register',
    component: Register,
  },
  // {
  //   name: 'Реєстр гуманітарної допомоги',
  //   path: '/register-public',
  //   component: RegisterPublic
  // },
  {
    name: 'Гуманітарна допомога',
    path: '/pages/delivery',
    component: Delivery,
  },
  {
    name: 'Інвентаризаційний опис',
    path: '/inventory-description',
    component: InventoryDescription,
  },
  {
    name: 'Звіт',
    path: '/report_description',
    component: ReportDescription,
  },
  {
    name: 'Реєстрація надходження',
    path: '/create-delivery/save',
    component: AddDelivery,
  },
  {
    name: 'Редагування надходження',
    path: '/create-delivery/edit',
    component: AddDelivery,
  },
  {
    name: 'Редагування надходження',
    path: '/edit-cargo',
    component: AddDelivery,
  },
  {
    name: 'Інформація про гуманітарну допомогу',
    path: '/view-delivery',
    component: ViewDelivery,
  },
  {
    name: 'Завантаження декларації',
    path: '/customs-date',
    component: CustomsDate,
  },
  {
    name: 'Документи по гуманітарній допомозі',
    path: '/view-all-documents-cargo',
    component: ViewAllDocumentsCargo,
  },
  {
    name: 'Інформація про отримувача',
    path: '/view-recipient',
    component: ViewRegisterRecipients,
  },

  {
    name: 'Історія заяв',
    path: '/application-history/:id',
    component: ApplicationHistory,
  },
  {
    name: 'Заява на зміну отримувача з Реестру',
    path: '/сhange-recipient',
    component: СhangeRecipient,
  },

  {
    name: 'Заява на виключення з Реестру',
    path: '/delete-recipient',
    component: DeleteRecipient,
  },
  // {
  //   name: 'Sub Navigation',
  //   path: '/pages/mes',
  //   component: Modals
  // },
  {
    name: '404',
    path: '/pages/404',
    component: ErrorPage,
  },
  {
    name: 'Мої засідання',
    path: '/commission-meeting-personal',
    component: CommissionMeetingPersonal,
  },
  {
    name: 'Засідання робочої групи',
    path: '/commission-meeting',
    component: Commission,
  },
  {
    name: 'Голосування',
    path: '/vouting',
    component: Vouting,
  },
  {
    name: 'Перегляд детальної інформації по заявах',
    path: '/detail-info-view',
    component: DetailInfoView,
  },
  {
    name: 'Перегляд детальної інформації',
    path: '/view-commission',
    component: CommissionView,
  },
  {
    name: 'Завантаження відсканованого наказу',
    path: '/order-upload',
    component: OrderUpload,
  },
  {
    name: 'Результати голосування',
    path: '/commission-results',
    component: CommissionResults,
  },

  {
    name: 'Додати засідання',
    path: '/create-сommission-meeting',
    component: CreateCommissionMeeting,
  },
  {
    name: 'Додати засідання',
    path: '/edit-сommission-meeting',
    component: CreateCommissionMeeting,
  },
  {
    name: 'Формування протоколу',
    path: '/view-all-documents/:id',
    component: ViewAllDocuments,
  },
  {
    name: 'Відомча реєстрація транспортного засобу',
    path: '/register-transport',
    component: DepartmentalRegistration,
  },
  {
    name: 'Відомча реєстрація транспортного засобу',
    path: '/register-vehicle/:id',
    component: VehicleRegistrationDetails,
  },
  {
    name: 'Invoice',
    path: '/apps/invoice',
    component: Invoice,
  },
  {
    name: 'CMS',
    path: '/apps/cms',
    component: CmsPage,
  },
  {
    name: 'Widgets',
    path: '/widgets',
    component: Widgets,
  },
  {
    name: 'Інструкціі',
    path: '/manual',
    component: Manual,
  },
  {
    name: 'Акт приймання-передачі ТЗ',
    path: '/act',
    component: Acts,
  },
  {
    name: 'Акт приймання-передачі ТЗ',
    path: '/act-view',
    component: Act,
  },
  {
    name: 'Акт приймання-передачі ТЗ',
    path: '/act-create',
    component: CreateAct,
  },
  {
    name: 'Акт приймання-передачі ТЗ',
    path: '/act-edit',
    component: EditAct,
  },
];

export default pageList;

import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  mainText: {
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: '24px',
    textAlign: 'left',
  },
}));

const ReportSignatureList = () => {
  const classes = useStyles();
  return (
    <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <Typography component="p" className={classes.mainText}>
        Не відображати дані у публічному доступі згідно чинного законодавства*
      </Typography>
    </Box>
  );
};

export default ReportSignatureList;

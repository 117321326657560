import { SUCCESS_CATALOG, SUCCESS_LOCALITY, FETCH_UKTZED_SUCCESS } from '../actions/action-types';

const initialState = {
  region: [],
  locality: [],
  applicationState: [],
  applicationType: [],
  acquirerType: [],
  aidCondition: [],
  aidForm: [],
  aidType: [],
  country: [],
  meetingState: [],
  organization: [],
  reciver: [],
  userState: [],
  recipientTypes: [],
  uktzed: [],
  uktzedFull: [],
  decisonType: [],
  documentTypes1: [],
  documentTypes2: [],
  conReason: [],
  docTypesReport: [],
  runit: [],
  requestType: [],
  requestStatus: [],
  fuelTypes: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SUCCESS_CATALOG:
      return Object.assign({}, state, {
        ...action.payload,
      });
    case SUCCESS_LOCALITY:
      return {
        ...state,
        locality: action.payload,
      };
    case FETCH_UKTZED_SUCCESS:
      return Object.assign({}, state, {
        // ...state,
        ...action.payload,
      });
    default:
      return state;
  }
}

import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { textAlign } from '@material-ui/system';

const useStyles = makeStyles((theme) => ({
  list: {
    listStylePosition: 'inside',
    listStyleType: 'disc',
    padding: 0,
  },
  listHead: {
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '16px',
    textAlign: 'left',
  },
  listItem: {
    display: 'list-item',
    paddingTop: 0,
    paddingBottom: 0,
    lineHeight: '16px',
  },
  listText: {
    display: 'inline',
  },
  listTextPrime: {
    color: '#141414',
    fontFamily: 'e-Ukraine',
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '16px',
    textAlign: 'left',
    wordWrap: 'wrap',
    display: 'inline',
  },
  zirk: {
    fontFamily: 'e-Ukraine',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    color: '#141414',
    margin: 0,
    padding: 0,
  },
}));

const ReportSignatureNote = () => {
  const classes = useStyles();
  return (
    <Box style={{ display: 'flex', marginLeft: 32, marginTop: 8 }}>
      <p className={classes.zirk}>*</p>
      <List className={classes.list} component="ul">
        <ListSubheader className={classes.listHead}>
          Отримувач або набувачі допомоги є органами, згідно чинного законодавства дані не мають відображуватися у
          публічному доступі:
        </ListSubheader>
        <ListItem className={classes.listItem}>
          <ListItemText
            className={classes.listText}
            classes={{ primary: classes.listTextPrime }}
            primary="Органи військового управління,"
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            className={classes.listText}
            classes={{ primary: classes.listTextPrime }}
            primary="Правоохоронні органи,"
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            className={classes.listText}
            classes={{ primary: classes.listTextPrime }}
            primary="Інші військові формування,"
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            className={classes.listText}
            classes={{ primary: classes.listTextPrime }}
            primary="Адміністрація Держспецзв'язку чи підпорядкований підрозділ, визначений нею,"
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            className={classes.listText}
            classes={{ primary: classes.listTextPrime }}
            primary="Військові адміністрації,"
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            className={classes.listText}
            classes={{ primary: classes.listTextPrime }}
            primary="Суб'єкти, які безпосередньо здійснюють боротьбу з тероризмом,"
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            className={classes.listText}
            classes={{ primary: classes.listTextPrime }}
            primary="Суб'єкти, які залучаються до боротьби з тероризмом."
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default ReportSignatureNote;

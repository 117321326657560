import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import clsx from 'clsx';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import * as Feather from 'react-feather';
import { UncontrolledTooltip } from 'reactstrap';
import NumberFormat from 'react-number-format';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  // highlight:
  //   theme.palette.type === 'light'
  //     ? {
  //         color: theme.palette.secondary.main,
  //         backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  //       }
  //     : {
  //         color: theme.palette.row.primary,
  //         backgroundColor: theme.palette.secondary.dark,
  //       },
  spacer: {
    flex: '1 1 100%',
  },
  // actions: {
  //   color: theme.palette.row.secondary,
  // },
  title: {
    flex: '0 0 auto',
  },
}));

const EnhancedTableToolbar = ({ item, index }) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar className={classes.root} dense="true" key={item.plan_id}>
      <ListItemText primary={item.aid_type} />
      <ListItemText primary={item.c_description} />
      <ListItemText primary={item.c_unit} />
      <ListItemText
        primary={
          <Typography id={`all${index}`} component="span">
            {item.count_all}
          </Typography>
        }
        secondary="Всього"
      />
      <UncontrolledTooltip placement="top" target={`all${index}`}>
        Всього
      </UncontrolledTooltip>

      <ListItemText
        primary={
          <Typography id={`distrib${index}`} component="span">
            {item.distrib_count}
          </Typography>
        }
        secondary="Розподілено"
      />
      <UncontrolledTooltip placement="top" target={`distrib${index}`}>
        Розподілено
      </UncontrolledTooltip>

      <ListItemText
        primary={
          <Typography id={`ramain${index}`} component="span">
            {item.difference}
          </Typography>
        }
        secondary="Залишилось"
      />
      <UncontrolledTooltip placement="top" target={`ramain${index}`}>
        Залишилось
      </UncontrolledTooltip>
      <ListItemSecondaryAction />
    </Toolbar>
  );
};

export default function TableModalReportCreate({ distributions, handleChange, handleSaveDitribution }) {
  const classes = useStyles();

  return (
    <List>
      {distributions.map((item, index) => (
        <Paper className={classes.root}>
          <EnhancedTableToolbar item={item} index={index} />
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <StyledTableCell>ПІБ</StyledTableCell>
                <StyledTableCell>Назва орг</StyledTableCell>
                <StyledTableCell>Адреса</StyledTableCell>
                <StyledTableCell>Розподіл</StyledTableCell>
                <StyledTableCell>Зберегти</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {item.plans.map((row, indexPlans) => (
                <TableRow>
                  <StyledTableCell width="20%">{row.acq_name}</StyledTableCell>
                  <StyledTableCell width="20%">{row.c_orgname}</StyledTableCell>
                  <StyledTableCell width="30%">{row.c_adress}</StyledTableCell>
                  <StyledTableCell width="15%">
                    <NumberFormat
                      value={row.distrib_p}
                      decimalSeparator="."
                      decimalScale={2}
                      onValueChange={(values) => {
                        const { formattedValue } = values;
                        handleChange(formattedValue, index, indexPlans);
                        // this.props.handleAgreemant()
                        // this.props.handleNumbers(formattedValue, 'n_cost')
                      }}
                      // onChange={e => handleChange(e, index, indexPlans)}
                    />
                  </StyledTableCell>
                  <StyledTableCell width="15%">
                    <IconButton id={`save${index}`} size="small" onClick={(e) => handleSaveDitribution(e, row)}>
                      <Feather.Check />
                    </IconButton>
                    <UncontrolledTooltip placement="top" target={`save${index}`}>
                      Зберегти
                    </UncontrolledTooltip>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      ))}
    </List>
  );
}

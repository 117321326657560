//к
import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import 'react-table/react-table.css';

//Components
import RegisterHistoryFullScreen from './RegisterHistoryFullScreen'
import RegisterHistoryMobile from './RegisterHistoryMobile'
import { withRouter} from "react-router-dom";
import BootstrapTooltip from '../../../elements/MaterialBootstrap'
import IconButton from '@material-ui/core/IconButton';
import * as Feather from 'react-feather';
class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [{text: "Hello"}],
    };
  }

  render() {

    return (
      <div className="mainRegister">
        <section className="searchMainRegister">
          {/* <div className="row">
       
           
            <div className="col-12 col-sm-6  col-md-4 col-xl-3 mt-2">
              <label htmlFor="" >Період з:</label>

              <DatePicker
                selected={this.state.startDate}
                onChange={this.handleChange}
                dateFormat="dd.MM.yyyy"
                maxDate={new Date()}
                locale={uk}
                className="datePicker"
                icon={<i className="cal-icon"/>} 
              />
            </div>

            <div className="col-12 col-sm-6  col-md-4 col-xl-3 mt-2">
            <label htmlFor="" >по:</label>

              <DatePicker
                selected={this.state.startDate}
                onChange={this.handleChange}
                dateFormat="dd.MM.yyyy"
                maxDate={new Date()}
                className="datePicker"
                locale={uk}
              />
            </div>

            <div className="col-12 mt-3 mb-4 text-center">
                <button className="btn btn-sm btn-primary">Пошук</button>
            </div>
          </div> */}
        </section>
        <section className="applicationHistoryFullScreen">
          <div>
          {this.props.isMobile ? <RegisterHistoryMobile data={this.state.data}/> : <RegisterHistoryFullScreen data={this.state.data} rescipientAppHistory={this.props.rescipientAppHistory}/>}
          
            <br />
          </div>
        </section>
        <BootstrapTooltip title="вихід">
                <IconButton onClick={this.props.handleClose}>
                  <Feather.LogOut style={{transform: 'scaleX(-1)'}} size='25' />
                </IconButton>
                </BootstrapTooltip>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isMobile: state.isMobile
  }
}

Register.propTypes = {
  isMobile: PropTypes.bool.isRequired
}

export default withRouter(connect(mapStateToProps, null)(Register));

import {
  ADD_INVENTORY_ITEM,
  SET_INVENTORY_INPUT,
  SET_INVENTORY_ARRAY,
  CLEAR_INVENTORY_CURRENT,
  REMOVE_INVENTORY_ITEM,
  SET_INVENTORY_CURRENT,
} from './action-types';

export const setInventoryInput = (name, value) => ({
  type: SET_INVENTORY_INPUT,
  payload: { name, value },
});

export const addInventoryItem = () => ({
  type: ADD_INVENTORY_ITEM,
});

export const setInventoryArray = (data) => ({
  type: SET_INVENTORY_ARRAY,
  payload: data,
});

export const clearCurrent = () => ({
  type: CLEAR_INVENTORY_CURRENT,
});

export const removeItem = (index) => ({
  type: REMOVE_INVENTORY_ITEM,
  payload: index,
});

export const setCurrent = (item) => ({
  type: SET_INVENTORY_CURRENT,
  payload: item,
});

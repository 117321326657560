import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import * as Feather from 'react-feather';
import IconButton from '@material-ui/core/IconButton';
import BootstrapTooltip from '../../../elements/MaterialBootstrap';
import DialogReportContent from './DialogReportContent';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '90%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
  },
}));

function DialogReport({ open, handleClose, id }) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  return (
    <Modal open={open} onClose={handleClose}>
      <div style={modalStyle} className={classes.paper}>
        <DialogReportContent id={id} />
        <BootstrapTooltip title="вихід">
          <IconButton onClick={handleClose}>
            <Feather.LogOut style={{ transform: 'scaleX(-1)' }} size="25" />
          </IconButton>
        </BootstrapTooltip>
      </div>
    </Modal>
  );
}

DialogReport.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default DialogReport;

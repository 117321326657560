import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import uk from 'date-fns/locale/uk';
import 'react-datepicker/dist/react-datepicker.css';
import format from 'date-fns/format';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { Button, Icon, TextField, Typography, Grid } from '@material-ui/core';
import Swal from 'sweetalert2';
import { makeStyles } from '@material-ui/core/styles';
import { history } from '../../../../vibe/components/utilities/MainUtils/hist';
import rules from '../../../../vibe/components/utilities/MainUtils/rules';

import print from '../../../../assets/images/print.png';
import sign from '../../../../assets/images/sign.png';
import add from '../../../../assets/images/add.png';
import save from '../../../../assets/images/save.png';
import next from '../../../../assets/images/next.png';

import {
  addInventoryItem,
  setInventoryInput,
  setInventoryArray,
  clearCurrent,
  removeItem,
  setCurrent,
} from '../../../../redux/actions/inventory.action';

import {
  saveInventoryAid,
  deleteInventoryAid,
  getInventByCargo,
  saveInventoryDates,
  getInventoryPDF,
  signInventory,
} from '../../../../api/api';

import { StyledTableCell } from './StyledTableCell';
import InventoryRow from './InventoryRow';
import InventoryExcel from './InventoryExcel';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  footerCell: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 400,
  },
  button_img: {
    marginLeft: '15px',
  },
  button_sign: {
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    marginRight: '30px',
    alignItems: 'center',
    padding: '20px 70px',
    gap: '4px',
    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',

    fontFamily: 'e-Ukraine Head',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    // display: 'flex',
    // alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px',
      width: '260px',
    },
  },

  button_add: {
    margin: '10px 30px',
    alignItems: 'center',
    padding: '20px 20px',
    gap: '4px',
    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',

    fontFamily: 'e-Ukraine Head',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    // display: 'flex',
    // alignItems: 'center',
    textAlign: 'center',
    color: '#FFFFFF',
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px',
    },
  },

  button_img: {
    marginLeft: '15px',
  },
  button_sign: {
    marginRight: '30px',
    alignItems: 'center',
    padding: '20px 70px',
    gap: '4px',
    width: '300px',
    height: '66px',
    background: '#141414',
    borderRadius: '40px',

    fontFamily: 'e-Ukraine Head',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#FFFFFF',
    '&:hover': {
      background: ' radial-gradient(152.02% 284.16% at 0% 0%, #87AFD2 0%, #D4ACD1 56.04%, #DBBDBF 100%)',
      color: '#141414',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px',
      width: '260px',
    },
  },

  root_section: {
    // width: '90%',
    // margin: ' 50px auto',
    // padding: '20px 0px',
    background: '#FFFFFF',
  },
}));

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-primary ',
    cancelButton: 'btn btn-danger ml-3',
  },
  buttonsStyling: false,
});

const InventoryTables = ({
  disableChanges,
  setDisableChanges,
  toggle,
  privat,
  setPrivat,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const [currentIndex, setCurrentIndex] = React.useState(-1);
  const classes = useStyles();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [disableSort, setDisableSort] = useState(false);
  const dispatch = useDispatch();

  const {
    catalogs: { aidType, runit },
    cargo: {
      cargoApplication: { i_cargo },
    },
    inventory: { array, current },
  } = useSelector((state) => state);

  const handleSort = (key) => {
    if (disableSort === false) {
      let direction = 'ascending';
      if (sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    }
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? <span>&uarr;</span> : <span>&darr;</span>;
    }
    return null;
  };

  const getValue = (obj, path) => {
    const keys = path.split('.');
    let value = obj;
    for (const key of keys) {
      value = value[key];
    }
    return value;
  };

  const sortedArray = useMemo(() => {
    if (sortConfig.key !== null) {
      return array.slice().sort((a, b) => {
        const valueA = getValue(a, sortConfig.key);
        const valueB = getValue(b, sortConfig.key);

        if (valueA < valueB) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return array;
  }, [array, sortConfig]);

  const handleInput = (event) => {
    const { value, name } = event.target;
    dispatch(setInventoryInput(name, value));
    // switch (name) {
    //   case 'weight':
    //   case 'count':
    //   case 'price':
    //   case 'value':
    //     (rules.numberInv.test(value) && dispatch(setInventoryInput(name, value))) ||
    //       (value === '' && dispatch(setInventoryInput(name, value)));
    //     break;
    //   default:
    //     dispatch(setInventoryInput(name, value));
    // }
  };

  const handleSelect = (name) => (option) => {
    if (option) {
      dispatch(setInventoryInput(name, option));
    }
  };

  const handleSelectUnit = (option) => {
    if (option) {
      dispatch(setInventoryInput('runitObj', option));
    }
  };

  const addRow = () => {
    setSortConfig({ key: null, direction: 'ascending' });
    setDisableSort(true);
    dispatch(addInventoryItem());
    dispatch(clearCurrent());
    setCurrentIndex(array.length);
  };

  const dateToObject = (strDate) => {
    if (strDate) {
      const [day, month, year] = strDate.split('.');
      return new Date(+year, +month - 1, +day);
    }

    return null;
  };

  const saveDates = (data) => {
    saveInventoryDates(data)
      .then(() => {
        Toast.fire({
          type: 'success',
          title: 'Дати інвентаризації збережено!',
        });
      })
      .catch((err) => {});
  };

  // datapicker
  const handleChangeDate = (name) => (dateObj) => {
    if (name === 'start') {
      setStartDate(dateObj);
    } else if (name === 'end') {
      setEndDate(dateObj);
      const data = {
        start_date: startDate ? format(startDate, 'dd.MM.yyyy') : '',
        end_date: dateObj ? format(dateObj, 'dd.MM.yyyy') : '',
        idCargo: i_cargo,
        // eslint-disable-next-line no-nested-ternary
        pl_private: privat === 'yes' ? 1 : privat === 'no' ? 0 : '',
      };
      saveDates(data);
    }
  };

  const getFullInventoryTable = () => {
    getInventByCargo(i_cargo)
      .then((res) => {
        const { assistances } = res.data;
        const table = [...assistances];
        const trueTable = table.map((item) => {
          const { id, category_id, description, unit_id, weight, count, price, first_price } = item;
          return {
            pi_id: id,
            aidTypeObj: aidType.find(({ value }) => value === category_id.toString()),
            description,
            runitObj: runit.find(({ value }) => (unit_id ? value === unit_id.toString() : null)),
            weight,
            count,
            price,
            value: first_price,
          };
        });
        dispatch(setInventoryArray(trueTable));
        const { dates } = res.data;
        const { end_date, start_date, disable_changes, l_private } = dates[0];
        // eslint-disable-next-line no-nested-ternary
        setPrivat(l_private === 1 ? 'yes' : l_private === 0 ? 'no' : '');
        setStartDate(dateToObject(start_date));
        setEndDate(dateToObject(end_date));
        setDisableChanges(disable_changes === 1);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getFullInventoryTable();
  }, [i_cargo, aidType, runit, dispatch]);

  async function orederView() {
    try {
      const response = await getInventoryPDF(i_cargo);
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      // link.setAttribute('download', `${data[index].c_name}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {}
  }

  async function sing() {
    swalWithBootstrapButtons
      .fire({
        text: 'Перевірте дані! Після підписання дані неможливо буде корегувати!',
        showCancelButton: true,
        confirmButtonText: 'Підписати',
        cancelButtonText: 'Відмовитися',
      })
      .then(async (result) => {
        if (result.value) {
          try {
            const resp = await signInventory(i_cargo);

            swalWithBootstrapButtons
              .fire({
                type: 'success',
                text: 'Інвентарізаційна відомість підписана!',
              })
              .then(async (res) => {
                if (res.value) {
                  try {
                    const response = await getInventoryPDF(i_cargo);
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('target', '_blank');
                    // link.setAttribute('download', `${data[index].c_name}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    history.push(`/view-delivery`);
                  } catch (error) {}
                }
              });
          } catch (error) {
            swalWithBootstrapButtons.fire({
              type: 'error',
              text: error.response.data,
            });
          }
        }
      });
  }

  const saveInventoryRow = () => {
    const { pi_id, aidTypeObj, description, runitObj, weight, count, price, value } = current;

    const data = {
      pi_id,
      id_Cargo: i_cargo,
      id_Aid: null,
      aidType: aidTypeObj?.value || '',
      description,
      pi_unit: runitObj?.value || '',
      weight,
      count,
      price,
      value,
    };

    saveInventoryAid(data)
      .then((res) => {
        Toast.fire({
          type: 'success',
          title: 'Дані збережено!',
        });
        const { id_return } = res.data;
        dispatch(setInventoryInput('pi_id', id_return));
        getFullInventoryTable();
        dispatch(clearCurrent());
        setCurrentIndex(-1);
        setDisableSort(false);
      })
      .catch((err) => {
        setDisableSort(false);
      });
  };

  const deleteInventoryRow = (id) => {
    if (id) {
      deleteInventoryAid(id)
        .then(() => {
          getFullInventoryTable();
          setDisableSort(false);
        })
        .catch((err) => {
          setDisableSort(false);
        });
    } else {
      dispatch(removeItem(currentIndex));
      setDisableSort(false);
    }

    dispatch(clearCurrent());
    setCurrentIndex(-1);
  };

  const editInventoryRow = (ind, id) => {
    dispatch(setCurrent(array.find((element) => element.pi_id === id)));
    setCurrentIndex(ind);
  };

  const sumValue = array
    .reduce((accumulator, item) => {
      const { value } = item;
      const currentValue = value ? Number(value) : 0;

      return accumulator + currentValue;
    }, 0)
    .toFixed(4);

  const sumWeight = array
    .reduce((accumulator, item) => {
      const { weight } = item;
      const currentValue = weight ? Number(weight) : 0;

      return accumulator + currentValue;
    }, 0)
    .toFixed(3);

  const sumCount = array
    .reduce((accumulator, item) => {
      const { count } = item;
      const currentValue = count ? Number(count) : 0;

      return accumulator + currentValue;
    }, 0)
    .toFixed(2);

  return (
    <>
      <div className={classes.root_section}>
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12  col-md-4 col-xl-3">
            <div>
              {/* <label htmlFor="">Інвентаризація розпочата:</label> */}
              <DatePicker
                selected={startDate}
                onChange={handleChangeDate('start')}
                dateFormat="dd.MM.yyyy"
                // maxDate={this.state.date_till}
                selectsStart
                // startDate={this.state.date_from}
                // endDate={this.state.date_till}
                locale={uk}
                className="datePicker"
                icon={<i className="cal-icon" />}
                isClearable
                customInput={<TextField label="Інвентаризація розпочата:" />}
                disabled={disableChanges}
              />
            </div>
          </div>
          <div className="col-12 col-sm-12  col-md-4 col-xl-3">
            <div>
              {/* <label htmlFor="">Інвентаризація закінчена:</label> */}
              <DatePicker
                selected={endDate}
                onChange={handleChangeDate('end')}
                dateFormat="dd.MM.yyyy"
                // maxDate={this.state.date_till}
                selectsEnd
                // startDate={this.state.date_from}
                // endDate={this.state.date_till}
                locale={uk}
                className="datePicker"
                icon={<i className="cal-icon" />}
                isClearable
                customInput={<TextField label="Інвентаризація закінчена:" />}
                disabled={disableChanges}
              />
            </div>
          </div>
          {/* <div className="col-12 col-sm-12 col-md-4 col-xl-3"> */}
          {/* <Button type="button" className={classes.button_sign} onClick={saveDates}>
            Зберегти дати
            <img className={classes.button_img} src={save} />
          </Button> */}
          {/* </div> */}
        </div>
        <div>
          <div className="row" style={{ margin: '50px 0 0 0' }}>
            <div className="col-12 pl-0 mb-2 pl-0 text-right">
              <InventoryExcel />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <Table size="small" className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell onClick={() => handleSort('aidTypeObj.label')}>
                        Категорія товару {getSortIcon('aidTypeObj.label')}
                      </StyledTableCell>
                      <StyledTableCell onClick={() => handleSort('description')}>
                        Найменування товарів, опис вантажу {getSortIcon('description')}
                      </StyledTableCell>
                      <StyledTableCell onClick={() => handleSort('runitObj.label')}>
                        Одиниця виміру {getSortIcon('runitObj.label')}
                      </StyledTableCell>
                      <StyledTableCell onClick={() => handleSort('weight')}>
                        Вага, кг/об’єм, л {getSortIcon('weight')}
                      </StyledTableCell>
                      <StyledTableCell onClick={() => handleSort('count')}>
                        Кількість, шт. {getSortIcon('count')}
                      </StyledTableCell>
                      <StyledTableCell onClick={() => handleSort('price')}>
                        Ціна за одиницю, грн. {getSortIcon('price')}
                      </StyledTableCell>
                      <StyledTableCell onClick={() => handleSort('value')}>
                        Первісна (справедлива) вартість, грн. {getSortIcon('value')}
                      </StyledTableCell>
                      <StyledTableCell>Функції</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedArray.map((item, index) => (
                      <InventoryRow
                        key={index}
                        index={index}
                        currentIndex={currentIndex}
                        item={item}
                        handleInput={handleInput}
                        handleSelect={handleSelect}
                        saveInventoryRow={saveInventoryRow}
                        deleteInventoryRow={deleteInventoryRow}
                        editInventoryRow={editInventoryRow}
                        handleSelectUnit={handleSelectUnit}
                        disableChanges={disableChanges}
                      />
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <StyledTableCell className={classes.footerCell}>
                        <Typography style={{ fontSize: 16, fontWeight: 400 }}>ВСЬОГО</Typography>
                      </StyledTableCell>
                      <StyledTableCell className={classes.footerCell}>—</StyledTableCell>
                      <StyledTableCell className={classes.footerCell}>—</StyledTableCell>
                      <StyledTableCell className={classes.footerCell}>
                        {/* <span style={{ fontSize: 14 }} /> */}
                        <strong>{sumWeight}</strong>
                      </StyledTableCell>
                      <StyledTableCell className={classes.footerCell}>
                        <strong>{sumCount}</strong>
                      </StyledTableCell>
                      <StyledTableCell className={classes.footerCell}>—</StyledTableCell>
                      <StyledTableCell className={classes.footerCell}>
                        <strong>{sumValue}</strong>
                      </StyledTableCell>
                      <StyledTableCell className={classes.footerCell} />
                    </TableRow>
                  </TableFooter>
                </Table>
              </div>
            </div>
          </div>
          <div className="row" style={{ margin: '20px 0 0 0' }}>
            <div className="col-4 pl-0 mb-2 pl-0 text-left">
              <Button
                type="button"
                className={classes.button_add}
                onClick={addRow}
                disabled={currentIndex > -1 || disableChanges}
              >
                Додати запис
                <img className={classes.button_img} src={add} alt="Add record plus" />
              </Button>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: '50px 0 0 0' }}>
          <p>
            Ви можете додавати дані до Інвентаризаційного опису та редагувати їх до того, як підпишете Інвентаризаційний
            опис. Після підписання вносити зміни до Інвентаризаційного опису буде неможливо
          </p>
          <div className="col-12 mt-3 mb-2 text-center">
            <Button
              type="button"
              className={classes.button_sign}
              onClick={() => toggle('2')}
              // color="primary"
              variant="outlined"
            >
              Далі
              <img className={classes.button_img} src={next} />
            </Button>

            {/* <Button
              type="button"
              className={classes.button_sign}
              onClick={orederView}
              // color="primary"
              variant="outlined"
            >
              Друк
              <img className={classes.button_img} src={print} />
            </Button>

            <Button
              disabled={disableChanges}
              type="button"
              className={classes.button_sign}
              onClick={sing}
              variant="outlined"
            >
              Підпис
              <img className={classes.button_img} src={sign} />
            </Button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryTables;

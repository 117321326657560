//packages
import React from 'react';
import { withRouter } from 'react-router';
//reactstrap
import { UncontrolledTooltip } from 'reactstrap';
//MUI
import IconButton from '@material-ui/core/IconButton';
import RecipientIcon from '@material-ui/icons/AssignmentInd';
import OrderIcon from '@material-ui/icons/Assignment';
import ReportIcon from '@material-ui/icons/ListAlt';
import HistoryIcon from '@material-ui/icons/AssignmentTurnedIn';
import DocIcon from '@material-ui/icons/Assignment';
import DocIconRec from '@material-ui/icons/AssignmentInd';
import LawIcon from '@material-ui/icons/AccountBalance';
import ModalDocument from './ModalDocument';
import { withStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';
import { recDoc, cargDoc } from '../../../../api/api';

const styles = {
  root: {
    fontSize: '1rem',
    borderRadius: '0px',
  },
};

const ControlsDetailInfoView = ({ oldDataRecipient, oldDataCargo, type, viewOrder, viewProtocol, fetchReports }) => {
  const [modal, openModal] = React.useState(false);
  const [recipientView, getRecipView] = React.useState(false);
  const [cargoView, getCargoView] = React.useState(false);
  const [docsR, getdocsR] = React.useState([]);
  const [docsC, getdocsC] = React.useState([]);

  async function recipientDocs(params) {
    openModal((modal) => !modal);
    getCargoView(false);
    getRecipView(true);
    try {
      const response = await recDoc(oldDataRecipient.p_application_id);
      getdocsR(response.data);
    } catch (error) {}
  }

  async function cargoDocs(params) {
    openModal((modal) => !modal);
    getCargoView(true);
    getRecipView(false);
    try {
      const response = await cargDoc((oldDataCargo.scargo || {}).old_i_cargo_app);
      getdocsC(response.data);
    } catch (error) {}
  }

  const {
    scargo: { old_i_cargo_app },
  } = oldDataCargo;

  return (
    <div>
      <div>Перегляд детальної інформації:</div>

      <UncontrolledTooltip placement="top" target="documentsResipientCardCommission">
        Документи по отримувачу
      </UncontrolledTooltip>

      <IconButton
        id="documentsResipientCardCommission"
        aria-label="documentsResipientCardCommission"
        color="primary"
        onClick={recipientDocs}
        disabled={type === 3}
      >
        <DocIconRec />
      </IconButton>

      <UncontrolledTooltip placement="top" target="documentsCargoCardCommission">
        Документи по гуманітарній допомозі
      </UncontrolledTooltip>

      <IconButton
        id="documentsCargoCardCommission"
        aria-label="document"
        color="primary"
        onClick={cargoDocs}
        disabled={type === 2 || !old_i_cargo_app}
      >
        <DocIcon />
      </IconButton>

      <ModalDocument
        docsR={docsR}
        docsC={docsC}
        openModal={openModal}
        modal={modal}
        cargoView={cargoView}
        recipientView={recipientView}
      />
    </div>
  );
};

export default withRouter(withStyles(styles)(ControlsDetailInfoView));

import React from 'react';
import classnames from 'classnames';

import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Row, Col } from 'reactstrap';

import InventoryTables from './inventory/InventoryTables';
import InventoryUpload from './inventory/InventoryUpload';

const InventoryTabs = () => {
  const [activeTab, setActiveTab] = React.useState('1');
  const [disableChanges, setDisableChanges] = React.useState(false);
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const [privat, setPrivat] = React.useState('');

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <>
      <Card body>
        <div className="full-bleed">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  toggle('1');
                }}
              >
                Внести
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  toggle('2');
                }}
              >
                Завантажити і підписати
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent
            activeTab={activeTab}
            // style={{ background: '#afc6e6' }}
          >
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <InventoryTables
                    disableChanges={disableChanges}
                    setDisableChanges={setDisableChanges}
                    toggle={toggle}
                    setPrivat={setPrivat}
                    privat={privat}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <InventoryUpload
                    disableChanges={disableChanges}
                    privat={privat}
                    setPrivat={setPrivat}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </Card>
    </>
  );
};

export default InventoryTabs;

import React from 'react';
import { Paper, Grid, ListItem, ListItemText, Typography } from '@material-ui/core';

const TransportData = ({ transport }) => (
  <Grid container direction="column">
    <Grid item>
      <Typography variant="subtitle2" component="h2" gutterBottom>
        Дані транспортного засобу
      </Typography>
    </Grid>
    <Grid container item>
      {transport.map((vehicle, index) => (
        <Grid container item direction="column" spacing={1}>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              Марка
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {vehicle.brand || '-'}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body2" color="textSecondary">
              Модель
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {vehicle.model || '-'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              VIN-код
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {vehicle.vin_code || '-'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              Вартість за 1 шт., грн
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {vehicle.price || '-'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              Загальна вартість, грн
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {vehicle.total_price || '-'}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  </Grid>
);

export default TransportData;

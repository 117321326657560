import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AttachMoney from '@material-ui/icons/AttachMoney';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItemText, ListItemSecondaryAction, CardHeader } from '@material-ui/core';
import * as Feather from 'react-feather';
import BootstrapTooltip from '../../../elements/MaterialBootstrap';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  // highlight:
  //   theme.palette.type === 'light'
  //     ? {
  //         color: theme.palette.secondary.main,
  //         backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  //       }
  //     : {
  //         color: theme.palette.row.primary,
  //         backgroundColor: theme.palette.secondary.dark,
  //       },
  spacer: {
    flex: '1 1 100%',
  },
  // actions: {
  //   color: theme.palette.row.secondary,
  // },
  title: {
    flex: '0 0 auto',
  },
  centered: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' },
}));

const EnhancedTableToolbar = ({ item, index, uktzed }) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar className={classes.root} dense="true" key={item.plan_id}>
      <div className="container">
        <div className="row">
          {/* icons */}
          <div className="col-sm-1">
            {parseInt(item.old_i_aidform) === 1 && (
              <div className={classes.centered}>
                <BootstrapTooltip title={`${item.old_cargo_form}`}>
                  <ListItemIcon>
                    <Feather.Truck id={`tooltipIcon${index}`} />
                  </ListItemIcon>
                </BootstrapTooltip>
              </div>
            )}
            {parseInt(item.new_i_aidform) === 1 && (
              <div className={classes.centered}>
                <BootstrapTooltip
                  title={`${item.new_cargo_form}`}
                  //title={directoryParse(catalog.aidForm, item.i_aidform)}
                >
                  <ListItemIcon style={{ color: item.new_i_aidform ? 'green' : '' }}>
                    <Feather.Truck id={`tooltipIcon${index}`} />
                  </ListItemIcon>
                </BootstrapTooltip>
              </div>
            )}
            {parseInt(item.old_i_aidform) === 3 && (
              <div className={classes.centered}>
                <BootstrapTooltip
                  title={`${item.old_cargo_form}`}
                  //title={directoryParse(catalog.aidForm, item.i_aidform)}
                >
                  <ListItemIcon>
                    {/* <i class="fas fa-users-cog" style={{fontSize: '22px'}} id={`tooltipIcon${index}`}></i> */}
                    <SupervisorAccount />
                  </ListItemIcon>
                </BootstrapTooltip>
              </div>
            )}
            {parseInt(item.new_i_aidform) === 3 && (
              <div className={classes.centered}>
                <BootstrapTooltip
                  title={`${item.new_cargo_form}`}
                  //title={directoryParse(catalog.aidForm, item.i_aidform)}
                >
                  <ListItemIcon style={{ color: item.new_i_aidform ? 'green' : '' }}>
                    {/* <i class="fas fa-users-cog" style={{fontSize: '22px'}} id={`tooltipIcon${index}`}></i> */}
                    <SupervisorAccount />
                  </ListItemIcon>
                </BootstrapTooltip>
              </div>
            )}
            {parseInt(item.old_i_aidform) === 2 && (
              <div className={classes.centered}>
                <BootstrapTooltip
                  title={`${item.old_cargo_form}`}
                  //title={directoryParse(catalog.aidForm, item.i_aidform)}
                >
                  <ListItemIcon>
                    <AttachMoney id={`tooltipIcon${index}`} />
                  </ListItemIcon>
                </BootstrapTooltip>
              </div>
            )}
            {parseInt(item.new_i_aidform) === 2 && (
              <div className={classes.centered}>
                <BootstrapTooltip
                  title={`${item.new_cargo_form}`}
                  //title={directoryParse(catalog.aidForm, item.i_aidform)}
                >
                  <ListItemIcon style={{ color: item.new_i_aidform ? 'green' : '' }}>
                    <AttachMoney id={`tooltipIcon${index}`} />
                  </ListItemIcon>
                </BootstrapTooltip>
              </div>
            )}
            {parseInt(item.old_i_aidform) === 4 && (
              <div className={classes.centered}>
                <BootstrapTooltip title={`${item.old_cargo_form}`}>
                  <ListItemIcon>
                    <i className="fas fa-car" style={{ fontSize: '22px' }} id={`tooltipIcon${index}`}></i>
                  </ListItemIcon>
                </BootstrapTooltip>
              </div>
            )}
            {parseInt(item.new_i_aidform) === 4 && (
              <div className={classes.centered}>
                <BootstrapTooltip title={`${item.new_cargo_form}`}>
                  <ListItemIcon>
                    <i className="fas fa-car" style={{ fontSize: '22px' }} id={`tooltipIcon${index}`}></i>
                  </ListItemIcon>
                </BootstrapTooltip>
              </div>
            )}
          </div>
          {/* fields */}
          {/* <ListItemText
            primary={item.old_ &&
              <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <label htmlFor="">
                  
                </label>
                <span>{item.old_}</span>
              </span>
            }
            secondary={item.new_ &&
              <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <label htmlFor="" style={{ color: item.new_ ? 'green' : '' }}>
                  
                </label>
                <span style={{ color: item.new_ ? 'green' : '' }}>
                  {item.new_}</span>
              </span>
            }
          />     */}

          <div className="col-sm-11">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="container">
                    {(item.old_i_aidform === 1 || item.new_i_aidform === 1) && (
                      <div className="row">
                        <div className="col-sm-2">
                          <ListItemText
                            primary={
                              item.old_i_uktzed && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Код УКТ ЗЕД:</label>
                                  <span>{item.old_i_uktzed}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_i_uktzed && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_i_uktzed ? 'green' : '' }}>
                                    Код УКТ ЗЕД:
                                  </label>
                                  <span style={{ color: item.new_i_uktzed ? 'green' : '' }}>{item.new_i_uktzed}</span>
                                </span>
                              )
                            }
                          />
                        </div>

                        <div className="col-sm-4">
                          <ListItemText
                            primary={
                              item.old_i_uktzed && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Опис (вантаж) з довідника УКТ ЗЕД:</label>
                                  <span>
                                    {uktzed.find(({ value }) => Number(value) === Number(item.old_i_uktzed))?.label}
                                  </span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_i_uktzed && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_i_uktzed ? 'green' : '' }}>
                                    Опис (вантаж) з довідника УКТ ЗЕД:
                                  </label>
                                  <span style={{ color: item.new_i_uktzed ? 'green' : '' }}>
                                    {uktzed.find(({ value }) => Number(value) === Number(item.new_i_uktzed))?.label}
                                  </span>
                                </span>
                              )
                            }
                          />
                        </div>

                        <div className="col-sm-4">
                          <ListItemText
                            primary={
                              item.old_c_description && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Опис вантажу:</label>
                                  <span>{item.old_c_description}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_c_description && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_c_description ? 'green' : '' }}>
                                    Опис вантажу:
                                  </label>
                                  <span style={{ color: item.new_c_description ? 'green' : '' }}>
                                    {item.new_c_description}
                                  </span>
                                </span>
                              )
                            }
                          />
                        </div>
                      </div>
                    )}

                    {(item.old_i_aidform === 1 || item.new_i_aidform === 1) && (
                      <div className="row">
                        <div className="col-sm-2">
                          <ListItemText
                            primary={
                              item.old_n_count && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Кількість:</label>
                                  <span>{item.old_n_count}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_n_count && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_n_count ? 'green' : '' }}>
                                    Кількість:
                                  </label>
                                  <span style={{ color: item.new_n_count ? 'green' : '' }}>{item.new_n_count}</span>
                                </span>
                              )
                            }
                          />
                        </div>

                        <div className="col-sm-2">
                          <ListItemText
                            primary={
                              item.old_c_unit && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Одиниця виміру:</label>
                                  <span>{item.old_c_unit}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_c_unit && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_c_unit ? 'green' : '' }}>
                                    Одиниця виміру:
                                  </label>
                                  <span style={{ color: item.new_c_unit ? 'green' : '' }}>{item.new_c_unit}</span>
                                </span>
                              )
                            }
                          />
                        </div>

                        <div className="col-sm-2">
                          <ListItemText
                            primary={
                              item.old_aid_condition && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Стан:</label>
                                  <span>{item.old_aid_condition}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_aid_condition && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_aid_condition ? 'green' : '' }}>
                                    Стан:
                                  </label>
                                  <span style={{ color: item.new_aid_condition ? 'green' : '' }}>
                                    {item.new_aid_condition}
                                  </span>
                                </span>
                              )
                            }
                          />
                        </div>
                      </div>
                    )}

                    {(item.old_i_aidform === 1 || item.new_i_aidform === 1) && (
                      <div className="row">
                        <div className="col-sm-2">
                          <ListItemText
                            primary={
                              item.old_c_category && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Відсоток зносу:</label>
                                  <span>{item.old_c_category}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_c_category && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_c_category ? 'green' : '' }}>
                                    Відсоток зносу:
                                  </label>
                                  <span style={{ color: item.new_c_category ? 'green' : '' }}>
                                    {item.new_c_category}
                                  </span>
                                </span>
                              )
                            }
                          />
                        </div>
                        <div className="col-sm-2">
                          <ListItemText
                            primary={
                              item.old_n_weight && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Вага, кг:</label>
                                  <span>{item.old_n_weight}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_n_weight && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_n_weight ? 'green' : '' }}>
                                    Вага, кг:
                                  </label>
                                  <span style={{ color: item.new_n_weight ? 'green' : '' }}>{item.new_n_weight}</span>
                                </span>
                              )
                            }
                          />
                        </div>
                        <div className="col-sm-2">
                          <ListItemText
                            primary={
                              item.old_c_service_life && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Строк придатності:</label>
                                  <span>{item.old_c_service_life}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_c_service_life && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_c_service_life ? 'green' : '' }}>
                                    Строк придатності:
                                  </label>
                                  <span style={{ color: item.new_c_service_life ? 'green' : '' }}>
                                    {item.new_c_service_life}
                                  </span>
                                </span>
                              )
                            }
                          />
                        </div>
                        <div className="col-sm-2">
                          <ListItemText
                            primary={
                              item.old_c_expiration_date && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Строк експлуатації:</label>
                                  <span>{item.old_c_expiration_date}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_c_expiration_date && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_c_expiration_date ? 'green' : '' }}>
                                    Строк експлуатації:
                                  </label>
                                  <span style={{ color: item.new_c_expiration_date ? 'green' : '' }}>
                                    {item.new_c_expiration_date}
                                  </span>
                                </span>
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                    {(item.old_i_aidform === 2 ||
                      item.new_i_aidform === 2 ||
                      item.old_i_aidform === 3 ||
                      item.new_i_aidform === 3) && (
                      <div className="row">
                        <div className="col-sm-3">
                          <ListItemText
                            primary={
                              item.old_cargo_form && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Тип допомоги:</label>
                                  <span>{item.old_cargo_form}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_cargo_form && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_cargo_form ? 'green' : '' }}>
                                    Тип допомоги:
                                  </label>
                                  <span style={{ color: item.new_cargo_form ? 'green' : '' }}>
                                    {item.new_cargo_form}
                                  </span>
                                </span>
                              )
                            }
                          />
                        </div>

                        <div className="col-sm-3">
                          <ListItemText
                            primary={
                              item.old_c_description && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Опис:</label>
                                  <span>{item.old_c_description}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_c_description && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_c_description ? 'green' : '' }}>
                                    Опис:
                                  </label>
                                  <span style={{ color: item.new_c_description ? 'green' : '' }}>
                                    {item.new_c_description}
                                  </span>
                                </span>
                              )
                            }
                          />
                        </div>

                        <div className="col-sm-3">
                          <ListItemText
                            primary={
                              item.old_n_count && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Кількість:</label>
                                  <span>{item.old_n_count}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_n_count && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_n_count ? 'green' : '' }}>
                                    Кількість:
                                  </label>
                                  <span style={{ color: item.new_n_count ? 'green' : '' }}>{item.new_n_count}</span>
                                </span>
                              )
                            }
                          />
                        </div>

                        <div className="col-sm-3">
                          <ListItemText
                            primary={
                              item.old_c_unit && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Одиниця виміру:</label>
                                  <span>{item.old_c_unit}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_c_unit && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_c_unit ? 'green' : '' }}>
                                    Одиниця виміру:
                                  </label>
                                  <span style={{ color: item.new_c_unit ? 'green' : '' }}>{item.new_c_unit}</span>
                                </span>
                              )
                            }
                          />
                        </div>
                      </div>
                    )}

                    {(item.old_i_aidform === 4 || item.new_i_aidform === 4) && (
                      <div className="row">
                        <div className="col-sm-2">
                          <ListItemText
                            primary={
                              item.old_aid_condition && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Стан:</label>
                                  <span>{item.old_aid_condition}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_aid_condition && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_aid_condition ? 'green' : '' }}>
                                    Стан:
                                  </label>
                                  <span style={{ color: item.new_aid_condition ? 'green' : '' }}>
                                    {item.new_aid_condition}
                                  </span>
                                </span>
                              )
                            }
                          />
                        </div>
                        <div className="col-sm-2">
                          <ListItemText
                            primary={
                              item.old_n_reg_year && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Рік виробництва:</label>
                                  <span>{item.old_n_reg_year}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_n_reg_year && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_n_reg_year ? 'green' : '' }}>
                                    Рік виробництва:
                                  </label>
                                  <span style={{ color: item.new_n_reg_year ? 'green' : '' }}>
                                    {item.new_n_reg_year}
                                  </span>
                                </span>
                              )
                            }
                          />
                        </div>
                        <div className="col-sm-2">
                          <ListItemText
                            primary={
                              item.old_n_comm_year && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Рік введення в експлуатацію:</label>
                                  <span>{item.old_n_comm_year}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_n_comm_year && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_n_comm_year ? 'green' : '' }}>
                                    Рік введення в експлуатацію:
                                  </label>
                                  <span style={{ color: item.new_n_comm_year ? 'green' : '' }}>
                                    {item.new_n_comm_year}
                                  </span>
                                </span>
                              )
                            }
                          />
                        </div>
                        <div className="col-sm-2">
                          <ListItemText
                            primary={
                              item.old_c_vehicle_type && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Вид (за призначенням):</label>
                                  <span>{item.old_c_vehicle_type}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_c_vehicle_type && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_c_vehicle_type ? 'green' : '' }}>
                                    Вид (за призначенням):
                                  </label>
                                  <span style={{ color: item.new_c_vehicle_type ? 'green' : '' }}>
                                    {item.new_c_vehicle_type}
                                  </span>
                                </span>
                              )
                            }
                          />
                        </div>
                        <div className="col-sm-2">
                          <ListItemText
                            primary={
                              item.old_c_category && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Категорія за класом:</label>
                                  <span>{item.old_c_category}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_c_category && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_c_category ? 'green' : '' }}>
                                    Категорія за класом:
                                  </label>
                                  <span style={{ color: item.new_c_category ? 'green' : '' }}>
                                    {item.new_c_category}
                                  </span>
                                </span>
                              )
                            }
                          />
                        </div>
                      </div>
                    )}

                    {(item.old_i_aidform === 4 || item.new_i_aidform === 4) && (
                      <div className="row">
                        <div className="col-sm-2">
                          <ListItemText
                            primary={
                              item.old_c_brand && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Марка:</label>
                                  <span>{item.old_c_brand}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_c_brand && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_c_brand ? 'green' : '' }}>
                                    Марка:
                                  </label>
                                  <span style={{ color: item.new_c_brand ? 'green' : '' }}>{item.new_c_brand}</span>
                                </span>
                              )
                            }
                          />
                        </div>
                        <div className="col-sm-2">
                          <ListItemText
                            primary={
                              item.old_c_model && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Модель:</label>
                                  <span>{item.old_c_model}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_c_model && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_c_model ? 'green' : '' }}>
                                    Модель:
                                  </label>
                                  <span style={{ color: item.new_c_model ? 'green' : '' }}>{item.new_c_model}</span>
                                </span>
                              )
                            }
                          />
                        </div>
                        <div className="col-sm-2">
                          <ListItemText
                            primary={
                              item.old_c_reg && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Номерний знак:</label>
                                  <span>{item.old_c_reg}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_c_reg && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_c_reg ? 'green' : '' }}>
                                    Номерний знак:
                                  </label>
                                  <span style={{ color: item.new_c_reg ? 'green' : '' }}>{item.new_c_reg}</span>
                                </span>
                              )
                            }
                          />
                        </div>
                        <div className="col-sm-2">
                          <ListItemText
                            primary={
                              item.old_c_engine && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Номер двигуна:</label>
                                  <span>{item.old_c_engine}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_c_engine && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_c_engine ? 'green' : '' }}>
                                    Номер двигуна:
                                  </label>
                                  <span style={{ color: item.new_c_engine ? 'green' : '' }}>{item.new_c_engine}</span>
                                </span>
                              )
                            }
                          />
                        </div>
                        <div className="col-sm-2">
                          <ListItemText
                            primary={
                              item.old_c_vin && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">VIN-код:</label>
                                  <span>{item.old_c_vin}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_ && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_c_vin ? 'green' : '' }}>
                                    VIN-код:
                                  </label>
                                  <span style={{ color: item.new_c_vin ? 'green' : '' }}>{item.new_c_vin}</span>
                                </span>
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                    {(item.old_i_aidform === 4 || item.new_i_aidform === 4) && (
                      <div className="row">
                        <div className="col-sm-2">
                          <ListItemText
                            primary={
                              item.old_c_body_numb && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Номер кузова:</label>
                                  <span>{item.old_c_body_numb}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_c_body_numb && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_c_body_numb ? 'green' : '' }}>
                                    Номер кузова:
                                  </label>
                                  <span style={{ color: item.new_c_body_numb ? 'green' : '' }}>{item.new_}</span>
                                </span>
                              )
                            }
                          />
                        </div>
                        <div className="col-sm-2">
                          <ListItemText
                            primary={
                              item.old_c_ch && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Номер шасі (рами):</label>
                                  <span>{item.old_c_ch}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_ && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_c_ch ? 'green' : '' }}>
                                    Номер шасі (рами):
                                  </label>
                                  <span style={{ color: item.new_c_ch ? 'green' : '' }}>{item.new_c_ch}</span>
                                </span>
                              )
                            }
                          />
                        </div>

                        <div className="col-sm-2">
                          <ListItemText
                            primary={
                              item.old_n_sum && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Вартість:</label>
                                  <span>{item.old_n_sum}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_n_sum && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_n_sum ? 'green' : '' }}>
                                    Вартість:
                                  </label>
                                  <span style={{ color: item.new_n_sum ? 'green' : '' }}>{item.new_n_sum}</span>
                                </span>
                              )
                            }
                          />
                        </div>
                        <div className="col-sm-4">
                          <ListItemText
                            primary={
                              item.old_c_owners && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="">Власник (власники):</label>
                                  <span>{item.old_c_owners}</span>
                                </span>
                              )
                            }
                            secondary={
                              item.new_c_owners && (
                                <span
                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                                >
                                  <label htmlFor="" style={{ color: item.new_c_owners ? 'green' : '' }}>
                                    Власник (власники):
                                  </label>
                                  <span style={{ color: item.new_c_owners ? 'green' : '' }}>{item.new_c_owners}</span>
                                </span>
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* distribution */}
          {/* <div className="col-sm-3">
            <div className="container">
              <div className="row">
                <div className="col-sm-4">
                  <BootstrapTooltip title="Всього">
                    <ListItemText
                      primary={<Typography id={`all${index}`} component="span">{item.old_n_countplan}
                      </Typography>}
                      secondary={<span style={{ color: item.new_n_countplan ? 'green' : '' }}>{item.new_n_countplan}</span>}
                    />
                  </BootstrapTooltip>
                </div>
                <div className="col-sm-4">
                  <BootstrapTooltip title="Залишилось">
                    <ListItemText
                      primary={
                        <Typography component="span">{item.old_n_count}</Typography>}
                      secondary={<span style={{ color: item.new_n_count ? 'green' : '' }}>{item.new_n_count}</span>}
                    />
                  </BootstrapTooltip>
                </div>
                <div className="col-sm-4">
                  <BootstrapTooltip title="Розподілено">
                    <ListItemText
                      primary={<Typography component="span">{item.difference}</Typography>}
                    />
                  </BootstrapTooltip>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* <ListItemSecondaryAction></ListItemSecondaryAction> */}
    </Toolbar>
  );
};

export default function OldDataAid({ oldDataCargo, uktzed }) {
  const classes = useStyles();

  return (
    <List>
      <Card>
        <CardHeader title="Донор" />
        <CardContent>
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="">Найменування отримувача:</label>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {(oldDataCargo.scargo || {}).old_orgname}
              </Typography>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                style={{ color: (oldDataCargo.scargo || {}).new_orgname ? 'green' : '' }}
              >
                {(oldDataCargo.scargo || {}).new_orgname}
              </Typography>
            </div>
            <div className="col-md-4">
              <label htmlFor="">Найменування донора:</label>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {(oldDataCargo.scargo || {}).old_donor_name}
              </Typography>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                style={{ color: (oldDataCargo.scargo || {}).new_donor_name ? 'green' : '' }}
              >
                {(oldDataCargo.scargo || {}).new_donor_name}
              </Typography>
            </div>
            <div className="col-md-4">
              <label htmlFor="">Реквізити донора:</label>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {(oldDataCargo.scargo || {}).old_c_details}
              </Typography>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                style={{ color: (oldDataCargo.scargo || {}).new_c_details ? 'green' : '' }}
              >
                {(oldDataCargo.scargo || {}).new_c_details}
              </Typography>
            </div>

            <div className="col-md-4">
              <label htmlFor="">Країна донора:</label>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {(oldDataCargo.scargo || {}).old_donor_cnt_name}
              </Typography>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                style={{ color: (oldDataCargo.scargo || {}).new_donor_cnt_name ? 'green' : '' }}
              >
                {(oldDataCargo.scargo || {}).new_donor_cnt_name}
              </Typography>
            </div>
            <div className="col-md-4">
              <label htmlFor="">Країна відправлення:</label>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {(oldDataCargo.scargo || {}).old_country_name}
              </Typography>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                style={{ color: (oldDataCargo.scargo || {}).new_country_name ? 'green' : '' }}
              >
                {(oldDataCargo.scargo || {}).new_country_name}
              </Typography>
            </div>
            <div className="col-md-4">
              <label htmlFor="">Тип допомоги:</label>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                {(oldDataCargo.scargo || {}).old_aid_form}
              </Typography>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                style={{ color: (oldDataCargo.scargo || {}).new_aid_form ? 'green' : '' }}
              >
                {(oldDataCargo.scargo || {}).new_aid_form}
              </Typography>
            </div>
          </div>
        </CardContent>
      </Card>
      {(oldDataCargo.aid || []).map((item, index) => (
        <Paper className={classes.root} key={item.i_aid}>
          <EnhancedTableToolbar item={item} index={index} uktzed={uktzed} />

          {item.acquirer.map((row, indexPlans) => (
            <Card key={row.i_acquire}>
              <CardContent>
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor=""> Тип набувача:</label>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      {row.old_acquirer_type}
                    </Typography>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                      style={{ color: row.new_acquirer_type ? 'green' : '' }}
                    >
                      {row.new_acquirer_type}
                    </Typography>
                  </div>
                  {/* row.old_i_acquirertype === 2 */}
                  {(row.old_i_acquirertype === 2 || row.new_i_acquirertype === 2) && (
                    <div className="col-md-4">
                      <label htmlFor=""> Кількість набувачів:</label>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {row.old_n_acqcnt}
                      </Typography>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                        style={{ color: row.new_n_acqcnt ? 'green' : '' }}
                      >
                        {row.new_n_acqcnt}
                      </Typography>
                    </div>
                  )}

                  {(row.old_i_acquirertype === 1 || row.new_i_acquirertype === 1) && (
                    <div className="col-md-4">
                      <label htmlFor=""> Найменування набувача:</label>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {row.old_c_orgname}
                      </Typography>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                        style={{ color: row.new_c_orgname ? 'green' : '' }}
                      >
                        {row.new_c_orgname}
                      </Typography>
                    </div>
                  )}
                  {(row.old_i_acquirertype === 1 || row.new_i_acquirertype === 1) && (
                    <div className="col-md-4">
                      <label htmlFor=""> ПІБ керівника набувача:</label>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {row.old_c_surname} {row.old_c_name} {row.old_c_patronymic}
                      </Typography>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                        style={{ color: row.new_c_patronymic || row.new_c_name || row.new_c_surname ? 'green' : '' }}
                      >
                        {row.new_c_surname} {row.new_c_name} {row.new_c_patronymic}
                      </Typography>
                    </div>
                  )}
                </div>

                <div className="row">
                  {(row.old_i_acquirertype === 1 ||
                    row.new_i_acquirertype === 1 ||
                    row.old_i_acquirertype === 2 ||
                    row.new_i_acquirertype === 2) && (
                    <div className="col-md-4">
                      <label htmlFor=""> Область: </label>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {row.old_region_name}
                      </Typography>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                        style={{ color: row.new_region_name ? 'green' : '' }}
                      >
                        {row.new_region_name}
                      </Typography>
                    </div>
                  )}
                  {(row.old_i_acquirertype === 1 ||
                    row.new_i_acquirertype === 1 ||
                    row.old_i_acquirertype === 2 ||
                    row.new_i_acquirertype === 2) && (
                    <div className="col-md-4">
                      <label htmlFor=""> Населений пункт:</label>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {row.old_locality_name}
                      </Typography>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                        style={{ color: row.new_locality_name ? 'green' : '' }}
                      >
                        {row.new_locality_name}
                      </Typography>
                    </div>
                  )}
                  {(row.old_i_acquirertype === 1 || row.new_i_acquirertype === 1) && (
                    <div className="col-md-4">
                      <label htmlFor=""> Місцезнаходження набувача:</label>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {row.old_c_adress}
                      </Typography>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                        style={{ color: row.new_c_adress ? 'green' : '' }}
                      >
                        {row.new_c_adress}
                      </Typography>
                    </div>
                  )}
                  <div className="col-md-4">
                    <label htmlFor=""> Кількість допомоги:</label>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      {row.old_n_countplan}
                    </Typography>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                      style={{ color: row.new_n_countplan ? 'green' : '' }}
                    >
                      {row.new_n_countplan}
                    </Typography>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor=""> Обґрунтування:</label>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      {row.old_c_reason}
                    </Typography>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                      style={{ color: row.new_c_reason ? 'green' : '' }}
                    >
                      {row.new_c_reason}
                    </Typography>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor=""> Вага допомоги, кг:</label>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      {row.old_n_weight}
                    </Typography>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                      style={{ color: row.new_c_reason ? 'green' : '' }}
                    >
                      {row.new_n_weight}
                    </Typography>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
        </Paper>
      ))}
    </List>
  );
}

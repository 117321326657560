import {
  FETCH_ACCESS_START,
  FETCH_ACCESS_SUCCESS,
  FETCH_ACCESS_FAILURE,
  FETCH_SWITCH_ACCESS_START,
  FETCH_SWITCH_ACCESS_SUCCESS,
  FETCH_SWITCH_ACCESS_FAILURE,
} from './action-types';

import { accessState, switchAccess } from '../../api/api';

const fetchAccessStart = () => ({
  type: FETCH_ACCESS_START,
});

const fetchAccessSussess = (value) => ({
  type: FETCH_ACCESS_SUCCESS,
  payload: value,
});

const fetchAccessFailure = (error) => ({
  type: FETCH_ACCESS_FAILURE,
  payload: error,
});

export const fetchAccess = () => async (dispatch) => {
  dispatch(fetchAccessStart());
  try {
    const response = await accessState();
    dispatch(fetchAccessSussess(response.data));
  } catch (error) {
    dispatch(fetchAccessFailure(error));
  }
};

const fetchSwitchAccessStart = () => ({
  type: FETCH_SWITCH_ACCESS_START,
});

const fetchSwitchAccessSussess = () => ({
  type: FETCH_SWITCH_ACCESS_SUCCESS,
});

const fetchSwitchAccessFailure = (error) => ({
  type: FETCH_SWITCH_ACCESS_FAILURE,
  payload: error,
});

export const fetchSwitchAccess = () => async (dispatch) => {
  dispatch(fetchSwitchAccessStart());
  try {
    await switchAccess();
    dispatch(fetchSwitchAccessSussess());
  } catch (error) {
    dispatch(fetchSwitchAccessFailure(error));
  }
};
